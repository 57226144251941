import { DirectoryUserStats, UserAction } from '@counsel-project/counsel-transcribe-api'
import { MetricAction } from '@counsel-project/counsel-transcribe-api/dist/common/database/RMetric'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { transcribeRequest } from '../../util/api/transcribe-api'
import checkToken from '../../util/auth/checkToken'
import handleError from '../../util/handleError'
import ActivityLog from '../../components/metrics/ActivityLog'
import { METRIC_NAME_MAPPINGS } from '../../components/metrics/lib'
import MetricOptions from '../../components/metrics/MetricOptions'
import StatCard from '../../components/metrics/StatCard'
import OrganizationStatsTable, { MappedMetric } from './OrganizationStatsTable'
import MetricsSavingsCalculator from '../../components/metrics/MetricsSavingsCalculator'
import DropdownSection from '../../components/layout/DropdownSection'

type OrganizationStatsProps = {
  directoryId: string
}

const OrganizationStats = ({ directoryId }: OrganizationStatsProps) => {
  const [userStats, setUserStats] = useState<DirectoryUserStats[]>([])
  const [loading, setLoading] = useState(false)
  const [userStatsPage, setUserStatsPage] = useState(0)
  const [startDate, setStartDate] = useState<Date | null>(null)
  const [endDate, setEndDate] = useState<Date | null>(null)
  const [search, setSearch] = useState('')
  const [userStatsSort, setUserStatsSort] = useState<MetricAction | 'email'>('email')
  const [userStatsDirection, setUserStatsDirection] = useState<'asc' | 'desc'>('asc')
  const [actions, setActions] = useState<MetricAction[]>([
    'live-session',
    'dictation',
    'generate-document',
    'any-edit-document',
  ])

  const allStats: UserAction[] = useMemo(() => {
    return userStats
      .filter((u) => u.email.toLowerCase().includes(search.toLowerCase()))
      .flatMap((u) => u.actions)
      .reduce((acc, action) => {
        const existing = acc.find((a) => a.action === action.action)
        if (existing) {
          existing.count += action.count
          existing.aggregate += action.aggregate
        } else {
          acc.push({ ...action })
        }
        return acc
      }, [] as UserAction[])
  }, [userStats, search])

  const navigate = useNavigate()

  const shownUserStats = useMemo(() => {
    const start = userStatsPage * 10
    return userStats
      .filter((u) => u.email.toLowerCase().includes(search.toLowerCase()))
      .sort((a, b) => {
        const aValue =
          userStatsSort === 'email'
            ? a.email
            : a.actions.find((a) => a.action === userStatsSort)?.count || 0
        const bValue =
          userStatsSort === 'email'
            ? b.email
            : b.actions.find((a) => a.action === userStatsSort)?.count || 0
        if (userStatsDirection === 'asc') {
          return aValue < bValue ? -1 : aValue > bValue ? 1 : 0
        } else {
          return aValue > bValue ? -1 : aValue < bValue ? 1 : 0
        }
      })
      .slice(start, start + 10)
  }, [userStats, userStatsPage, search, userStatsSort, userStatsDirection])

  const populateStats = useCallback(async () => {
    try {
      setLoading(true)

      await checkToken()

      const { results } = await transcribeRequest.metrics.directory.count({
        token: '',
        directoryId,
        startDate: startDate?.toISOString(),
        endDate: endDate?.toISOString(),
        actions,
      })

      setUserStats(results)
    } catch (err) {
      handleError(err)
    } finally {
      setLoading(false)
    }
  }, [directoryId, actions, startDate, endDate])

  useEffect(() => {
    const timeout = setTimeout(() => {
      populateStats()
    }, 10)
    return () => clearTimeout(timeout)
  }, [populateStats])

  const handleCsvExport = useCallback(() => {
    const csvContent = `data:text/csv;charset=utf-8,Email,${userStats[0].actions
      .map((a) => METRIC_NAME_MAPPINGS[a.action])
      .join(',')}\n${userStats
      .map((u) => `${u.email},${u.actions.map((a) => a.count).join(',')}`)
      .join('\n')}`

    const encodedUri = encodeURI(csvContent)
    const link = document.createElement('a')
    link.setAttribute('href', encodedUri)
    link.setAttribute('download', 'organization-stats.csv')
    document.body.appendChild(link)
    link.click()
  }, [userStats])

  const handleClickRow = useCallback(
    (row: MappedMetric) => {
      navigate(`/sessions?userId=${row.userId}`)
    },
    [navigate]
  )

  const handleOptionsChange = useCallback(
    (actions: MetricAction[], startDate: Date | null, endDate: Date | null, search: string) => {
      setActions(actions)
      setStartDate(startDate)
      setEndDate(endDate)
      setSearch(search)
    },
    []
  )

  if (userStats.length === 0) {
    return <Typography>Loading...</Typography>
  }

  return (
    <>
      <Typography
        variant="body1"
        fontStyle="italic"
        color="text.secondary"
        textAlign="center"
        sx={{ mb: 2, mt: 1 }}
      >
        Disclaimer: Data before Oct 26, 2024 is not available
      </Typography>
      <DropdownSection title="Date/Search Filters" subtitle="Filter and sort the data">
        <MetricOptions search onOptionsChange={handleOptionsChange} />
      </DropdownSection>
      <DropdownSection
        title="Savings Calculator"
        subtitle="Calculate the savings for the organization"
        sx={{ mt: 2 }}
        defaultOpen
      >
        <MetricsSavingsCalculator
          directoryId={directoryId}
          startDate={startDate}
          endDate={endDate}
        />
      </DropdownSection>
      <Grid
        container
        spacing={2}
        paddingTop={2}
        alignItems="stretch"
        justifyContent="stretch"
        height="100%"
      >
        <Grid item container spacing={2} alignItems="stretch" justifyContent="center">
          {allStats.map((stat) => (
            <Grid item xs={6} sm={4} md={3} key={stat.action}>
              <StatCard
                title={'Total ' + METRIC_NAME_MAPPINGS[stat.action]}
                count={stat.count}
                aggregate={stat.aggregate}
              />
            </Grid>
          ))}
        </Grid>
        <Grid item xs={12}>
          <Paper elevation={0} sx={{ p: 1 }}>
            <OrganizationStatsTable
              rows={shownUserStats}
              page={userStatsPage}
              total={userStats.length}
              loaded={!loading}
              sort={userStatsSort}
              direction={userStatsDirection}
              rowsPerPage={10}
              rowsPerPageOptions={[10]}
              onChangePage={setUserStatsPage}
              onClickRow={handleClickRow}
              onChangeSort={(sort, direction) => {
                setUserStatsSort(sort)
                setUserStatsDirection(direction)
              }}
            />
            <Button onClick={handleCsvExport} sx={{ m: 1 }}>
              Export CSV
            </Button>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <DropdownSection title="Activity Log" subtitle="Activity log for the organization">
            <Paper elevation={0} sx={{ p: 1 }}>
              <ActivityLog directoryId={directoryId} />
            </Paper>
          </DropdownSection>
        </Grid>
      </Grid>
    </>
  )
}

export default OrganizationStats
