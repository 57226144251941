import NextIcon from '@mui/icons-material/ArrowForwardIosRounded'
import CloseIcon from '@mui/icons-material/CloseRounded'
import ChevronDown from '@mui/icons-material/KeyboardArrowDownRounded'
import Button from '@mui/material/Button'
import Collapse from '@mui/material/Collapse'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Divider from '@mui/material/Divider'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { styled } from '@mui/material/styles'
import { capitalize } from '@counsel-project/client-utils'
import { useState } from 'react'
import { usePatientNomenclature } from '../util'
import { useSearchParams } from 'react-router-dom'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'

const ConsentScript = () => {
  return (
    <Typography variant="body1" sx={{ mt: 2 }} id="consent-script">
      Hello, I wanted to get your consent to use a tool from Clinical Notes AI during our session
      today to assist me in summarizing and documenting the session.
      <br />
      <br />
      This technology helps me capture and analyze our conversation to create accurate clinical
      notes, allowing me to focus more on your care and treatment planning.
      <br />
      <br />
      The information is securely stored in accordance with HIPAA and will not be shared with a
      third party.
      <br />
      <br />
      This innovative approach helps streamline documentation, supporting better healthcare
      outcomes.
      <br />
      <br />
      Do you consent to the use of this technology during our session?
      <br />
      <br />
    </Typography>
  )
}

type FlippingChevronProps = {
  open: boolean
}

const FlippingChevron = styled(ChevronDown, {
  shouldForwardProp: (prop) => prop !== 'open',
})<FlippingChevronProps>(({ theme, open }) => ({
  transition: 'transform 0.2s ease-in-out',
  transform: !open ? 'rotate(180deg)' : undefined,
}))

export type PatientConsentDialogProps = {
  open: boolean
  onClose: () => void
  onConfirm: () => void
  onClickNeverShow?: () => void
}

const PatientConsentDialog = ({
  open,
  onClose,
  onConfirm,
  onClickNeverShow,
}: PatientConsentDialogProps) => {
  const [searchParams] = useSearchParams()

  const tutorialMode = searchParams.get('tutorial') === 'true'

  const [scriptOpen, setScriptOpen] = useState(false)
  const [neverShow, setNeverShow] = useState(false)

  const handleChangeNeverShow = () => {
    setNeverShow((prev) => !prev)
  }

  const handleConfirm = () => {
    onConfirm()
    if (neverShow) {
      onClickNeverShow?.()
    }
  }

  const patient = usePatientNomenclature()

  const handleToggleScript = () => {
    setScriptOpen((prev) => !prev)
  }

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>{capitalize(patient)} Consent</DialogTitle>
      <DialogContent>
        <Typography variant="subtitle1" fontWeight={500} sx={{ mb: 2 }} textAlign="center">
          Please confirm your {patient}'s consent to use Clinical Notes AI
        </Typography>
        <Divider>
          <Button
            variant="text"
            color="success"
            endIcon={<FlippingChevron open={scriptOpen} />}
            onClick={handleToggleScript}
            sx={{ color: 'text.secondary' }}
            id="see-consent-script-button"
            aria-expanded={scriptOpen}
          >
            See Consent Script
          </Button>
        </Divider>
        {tutorialMode && scriptOpen ? (
          <ConsentScript />
        ) : (
          <Collapse in={scriptOpen}>
            <ConsentScript />
          </Collapse>
        )}
      </DialogContent>
      <Grid container padding={2} spacing={2}>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                id="never-show-consent"
                onChange={handleChangeNeverShow}
                checked={neverShow}
              />
            }
            label="Never show this again"
          />
        </Grid>
        <Grid item xs={12}>
          <Button
            onClick={handleConfirm}
            endIcon={<NextIcon />}
            fullWidth
            id="confirm-consent-button"
          >
            {capitalize(patient)} Consented
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Button
            variant="text"
            color="info"
            onClick={onClose}
            endIcon={<CloseIcon />}
            fullWidth
            id="deny-consent-button"
          >
            {capitalize(patient)} Did Not Consent
          </Button>
        </Grid>
      </Grid>
    </Dialog>
  )
}

export default PatientConsentDialog
