import { RRole } from '@counsel-project/counsel-auth-api'
import Box from '@mui/material/Box'
import Chip from '@mui/material/Chip'
import Typography from '@mui/material/Typography'
import { useCallback } from 'react'
import AddSuperviseeField from './AddSuperviseeField'
import Grid from '@mui/material/Grid'

type AddSuperviseeSectionProps = {
  loading?: boolean
  disabled?: boolean
  directoryId: string
  role: RRole
  onChangeRole: (role: RRole) => void
  canEdit?: boolean
}

const AddSuperviseeSection = ({
  loading,
  disabled,
  directoryId,
  role,
  onChangeRole,
  canEdit = true,
}: AddSuperviseeSectionProps) => {
  const handleAddSupervisee = useCallback(
    (email: string) => {
      onChangeRole({
        ...role,
        children: [...(role.children || []), email],
      })
    },
    [role, onChangeRole]
  )

  const handleRemoveSupervisee = useCallback(
    (email: string) => {
      onChangeRole({
        ...role,
        children: role.children?.filter((e) => e !== email),
      })
    },
    [role, onChangeRole]
  )

  if (!role.email) {
    return null
  }

  return (
    <Box>
      <Typography variant="body1" fontWeight={500} fontSize={18}>
        Documentation Sharing
      </Typography>
      <Typography variant="body1" color="text.secondary">
        This user will be able to view documentation of sub-users defined here.
      </Typography>
      <Grid container alignItems="center" spacing={1} sx={{ mb: 2, mt: 0 }}>
        {!role?.children || role.children.length === 0 ? (
          <Grid item>
            <Chip label="No supervisees" sx={{ color: 'text.secondary', fontStyle: 'italic' }} />
          </Grid>
        ) : null}
        {role?.children?.map((supervisee) => (
          <Grid item>
            <Chip
              key={supervisee}
              label={supervisee}
              onDelete={canEdit ? () => handleRemoveSupervisee(supervisee) : undefined}
            />
          </Grid>
        ))}
      </Grid>
      {canEdit && (
        <AddSuperviseeField
          disabled={disabled}
          loading={loading}
          directoryId={directoryId}
          role={role}
          onSubmit={handleAddSupervisee}
        />
      )}
    </Box>
  )
}

export default AddSuperviseeSection
