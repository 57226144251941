import { RIntegrationPatient, RIntegrationTemplate } from '@counsel-project/counsel-ehr-api'
import { InputField, OutputField } from '@counsel-project/counsel-generation-api'
import { capitalize } from '@counsel-project/client-utils'
import Button from '@mui/material/Button'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import Typography from '@mui/material/Typography'
import { useCallback, useState } from 'react'
import toast from 'react-hot-toast'
import { usePatientNomenclature } from '../../util'
import { ehrRequest } from '../../util/api/ehr-api'
import checkToken from '../../util/auth/checkToken'
import handleError from '../../util/handleError'
import ClosableDialog from '../ClosableDialog'
import TemplateFields from './form/TemplateFields'

type ExportEHRPreviewProps = {
  inputFields: InputField[]
  outputFields: OutputField[]
  onChangeOutputFields: (func: (prev: OutputField[]) => OutputField[]) => void
  patient: RIntegrationPatient
  template: RIntegrationTemplate
  startedAtString: string
  endedAtString: string
  open: boolean
  onClose: () => void
  onExport: () => void
}

const ExportEHRPreview = ({
  inputFields,
  outputFields,
  onChangeOutputFields,
  patient,
  template,
  startedAtString,
  endedAtString,
  open,
  onClose,
  onExport,
}: ExportEHRPreviewProps) => {
  const [loading, setLoading] = useState(false)

  const patientNomenclature = usePatientNomenclature()

  const handlePushEhrValues = useCallback(async () => {
    try {
      if (outputFields.length === 0) {
        toast.error('No fields to export')
        return
      }

      setLoading(true)

      await checkToken()

      const { fieldCount } = await ehrRequest.integrations.templates.use({
        token: '',
        templateId: template._id,
        patientId: patient._id,
        outputs: outputFields,
        startDate: startedAtString,
        endDate: endedAtString,
      })

      if (fieldCount === 0) {
        toast.error('No fields were exported')
      } else {
        toast.success(`Successfully exported ${fieldCount} fields`)
        onExport()
      }
    } catch (err) {
      handleError(err)
    } finally {
      setLoading(false)
    }
  }, [outputFields, patient, template, startedAtString, endedAtString, onExport])

  return (
    <ClosableDialog open={open} onClose={onClose} titleText="Export EHR Preview">
      <DialogContent>
        <Typography variant="body1" fontWeight={500} sx={{ mb: 2 }}>
          You are about to export this document for {capitalize(patient.name)} using the{' '}
          {template.name} template.
        </Typography>
        <Typography variant="body1" color="text.secondary" sx={{ mb: 2 }}>
          The following fields will be exported:
        </Typography>
        <TemplateFields
          inputFields={inputFields}
          outputFields={outputFields}
          onChange={onChangeOutputFields}
        />
        <Typography variant="body1" sx={{ mt: 2 }}>
          {capitalize(patientNomenclature)}: <strong>{capitalize(patient.name)}</strong>
        </Typography>
        <Typography variant="body1" sx={{ mt: 2 }}>
          Template: <strong>{capitalize(template.name)}</strong>
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} disabled={loading}>
          Cancel
        </Button>
        <Button
          onClick={handlePushEhrValues}
          variant="contained"
          color="primary"
          disabled={loading}
        >
          Complete Export
        </Button>
      </DialogActions>
    </ClosableDialog>
  )
}

export default ExportEHRPreview
