import LockIcon from '@mui/icons-material/LockRounded'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import useUser from '../../util/auth/useUser'
import { useCallback, useMemo, useState } from 'react'
import handleError from '../../util/handleError'
import checkToken from '../../util/auth/checkToken'
import { authRequest } from '../../util/api/auth-api'
import toast from 'react-hot-toast'
import TextField from '@mui/material/TextField'

const MFASection = () => {
  const [user, setUser] = useUser()

  const [password, setPassword] = useState('')
  const [loading, setLoading] = useState(false)

  const subtext = useMemo(() => {
    if (user?.twoFactorAuth) {
      return 'Multi-factor authentication is enabled via SMS verification.'
    } else if (!user?.phone) {
      return 'Add a phone number to set up multi-factor authentication.'
    }
    return 'Set up multi-factor authentication to help secure your account using SMS verification.'
  }, [user])

  const handleEnableMFA = useCallback(async () => {
    try {
      setLoading(true)

      await checkToken()

      const res = await authRequest.user.account.setup2FAPhone({
        token: '',
      })

      setUser(res.user)

      toast.success('Multi-factor authentication has been enabled.')
    } catch (err) {
      handleError(err)
    } finally {
      setLoading(false)
    }
  }, [setUser])

  const handleDisableMFA = useCallback(async () => {
    try {
      setLoading(true)

      await checkToken()

      const res = await authRequest.user.account.remove2FA({
        token: '',
        password,
      })

      setUser(res.user)

      toast.success('Multi-factor authentication has been disabled.')
    } catch (err) {
      handleError(err)
    } finally {
      setLoading(false)
    }
  }, [password, setUser])

  const actionComponent = useMemo(() => {
    if (!user?.phone) {
      return (
        <Grid container spacing={2}>
          <Grid item>
            <Typography variant="body2" color="error">
              Add a phone number to set up multi-factor authentication.
            </Typography>
          </Grid>
        </Grid>
      )
    }
    if (user?.twoFactorAuth) {
      return (
        <Grid container spacing={2}>
          <Grid item>
            <TextField
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              label="Password"
              type="password"
              sx={{ mt: 2 }}
            />
          </Grid>
          <Grid item>
            <Button
              sx={{ mt: 2 }}
              disabled={!password || password.length < 6 || loading}
              onClick={handleDisableMFA}
            >
              Disable MFA
            </Button>
          </Grid>
        </Grid>
      )
    }
    return (
      <Button sx={{ mt: 2 }} onClick={handleEnableMFA} disabled={loading}>
        Set Up MFA
      </Button>
    )
  }, [user, password, handleDisableMFA, handleEnableMFA, loading])

  if (!user?.password) {
    return null
  }

  return (
    <Paper elevation={0} sx={{ p: 2 }} id="referral-program">
      <Grid container spacing={1} alignItems="center">
        <Grid item>
          <LockIcon />
        </Grid>
        <Grid item>
          <Typography variant="h5">Multi-Factor Authentication</Typography>
        </Grid>
      </Grid>
      <Box>
        <Typography sx={{ mt: 2 }}>{subtext}</Typography>
        <Typography color="text.secondary">SMS Message and data rates may apply</Typography>
        {actionComponent}
      </Box>
    </Paper>
  )
}

export default MFASection
