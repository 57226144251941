import Paper from '@mui/material/Paper'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import useUser from '../../util/auth/useUser'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import { useNavigate } from 'react-router-dom'
import logout from '../../util/auth/logout'
import { useCallback, useState } from 'react'
import { authRequest } from '../../util/api/auth-api'
import toast from 'react-hot-toast'
import handleError from '../../util/handleError'
import checkToken from '../../util/auth/checkToken'
import ChangeEmailDialog from './ChangeEmailDialog'

const ProfileHeader = () => {
  const [user, setUser] = useUser()

  const navigate = useNavigate()

  const [newsletterLoading, setNewsletterLoading] = useState(false)
  const [changeEmailOpen, setChangeEmailOpen] = useState(false)

  const name = user?.firstName ? `${user?.firstName} ${user?.lastName}` : 'Your Profile'

  const handleLogout = () => {
    logout()
    navigate('/')
  }

  const handleToggleNewsletter = useCallback(async () => {
    try {
      await checkToken()

      setNewsletterLoading(true)

      const data = await authRequest.user.account.update({
        token: '',
        newsletter: !user?.newsletter,
        firstName: user?.firstName || undefined,
        lastName: user?.lastName || undefined,
        organization: user?.organization || undefined,
        country: user?.country || undefined,
        state: user?.state || undefined,
        profession: user?.profession || undefined,
      })

      setUser(data.user)

      toast.success('Successfully updated preferences.', { id: 'emails' })
    } catch (err) {
      handleError(err)
    } finally {
      setNewsletterLoading(false)
    }
  }, [user, setUser])

  return (
    <>
      <Paper elevation={0} sx={{ height: '100%', px: 3, py: 2 }}>
        <Grid container justifyContent="space-between" alignItems="center" spacing={2}>
          <Grid item xs>
            <Typography variant="h5">{name}</Typography>
            <Typography variant="body1" color="text.secondary">
              {user?.email}
            </Typography>
          </Grid>
          <Grid item xs={12} sm="auto">
            <Button fullWidth sx={{ p: 0.5, px: 1.5 }} onClick={() => setChangeEmailOpen(true)}>
              Change Email
            </Button>
          </Grid>
          <Grid item xs={12} sm="auto">
            <Button onClick={handleLogout} fullWidth sx={{ p: 0.5, px: 1.5 }}>
              Logout
            </Button>
          </Grid>
        </Grid>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item xs={12}>
            <FormControlLabel
              sx={{
                '& .MuiFormControlLabel-label': {
                  color: 'text.secondary',
                },
                mt: 2,
              }}
              control={
                <Checkbox
                  checked={user?.newsletter === true}
                  onChange={handleToggleNewsletter}
                  name="newsletter"
                  color="primary"
                  disabled={newsletterLoading ?? true}
                />
              }
              label="Agree to receive reminders, insights, and communication about Clinical Notes AI"
            />
          </Grid>
        </Grid>
      </Paper>
      <ChangeEmailDialog open={changeEmailOpen} onClose={() => setChangeEmailOpen(false)} />
    </>
  )
}

export default ProfileHeader
