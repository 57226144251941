import { RIntegration } from '@counsel-project/counsel-ehr-api'
import CircularProgress from '@mui/material/CircularProgress'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import { useCallback, useEffect, useMemo } from 'react'
import { ehrRequest } from '../../../util/api/ehr-api'
import checkToken from '../../../util/auth/checkToken'

// Div with dot in the middle

type DotProps = {
  color: 'primary' | 'secondary' | 'error' | 'warning' | 'info' | 'success'
}

const Dot = styled('div', { shouldForwardProp: (prop) => prop !== 'color' })<DotProps>(
  ({ theme, color }) => ({
    height: 4,
    width: 4,
    borderRadius: '50%',
    backgroundColor: theme.palette[color].main,
  })
)

const DotContainer = styled('div')({
  position: 'relative',
  height: 16,
  width: 16,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
})

const SyncedIcon = () => {
  return (
    <DotContainer>
      <Dot color="success" />
    </DotContainer>
  )
}

const SyncingIcon = () => {
  return (
    <DotContainer>
      <Dot color="warning" />
      <CircularProgress
        size={16}
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          zIndex: 1,
        }}
        color="warning"
      />
    </DotContainer>
  )
}

const ErroredIcon = () => {
  return (
    <DotContainer>
      <Dot color="error" />
    </DotContainer>
  )
}

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  '&:hover': {
    cursor: 'pointer',
    boxShadow: theme.shadows[4],
  },
  width: 140,
}))

const StyledImg = styled('img')({
  height: 120,
  width: 120,
  objectFit: 'contain',
})

type IntegrationItemProps = {
  integration: RIntegration
  onClick: () => void
  onUpdateIntegration: (integration: RIntegration) => void
}

const IntegrationItem = ({ integration, onClick, onUpdateIntegration }: IntegrationItemProps) => {
  const src = useMemo(() => {
    if (integration.type === 'kipu') {
      return '/integrations/kipu_integration.svg'
    }
    if (integration.type === 'advancedmd') {
      return '/integrations/advancedmd_integration.svg'
    }
    return ''
  }, [integration.type])

  const icon = useMemo(() => {
    if (integration.state === 'synced') {
      return <SyncedIcon />
    }
    if (integration.state === 'syncing') {
      return <SyncingIcon />
    }
    if (integration.state === 'errored') {
      return <ErroredIcon />
    }
    return null
  }, [integration.state])

  const text = useMemo(() => {
    if (integration.state === 'synced') {
      return 'Synced'
    }
    if (integration.state === 'syncing') {
      return 'Syncing...'
    }
    if (integration.state === 'errored') {
      return 'Errored'
    }
    return ''
  }, [integration.state])

  const populateSync = useCallback(async () => {
    try {
      if (integration.state !== 'syncing') return

      await checkToken()

      const { result } = await ehrRequest.integrations.get({
        token: '',
        integrationId: integration._id,
      })

      if (result.state !== 'syncing') {
        onUpdateIntegration(result)
      }
    } catch (err) {
      console.error(err)
    }
  }, [integration, onUpdateIntegration])

  useEffect(() => {
    if (integration.state !== 'syncing') return
    const interval = setInterval(() => {
      populateSync()
    }, 5000)

    return () => clearInterval(interval)
  }, [integration.state, populateSync])

  return (
    <StyledPaper elevation={0} variant="outlined" onClick={onClick}>
      <Grid container alignItems="center" justifyContent="center">
        <Grid item>
          <StyledImg src={src} alt={integration.type} />
        </Grid>
      </Grid>
      <Grid container alignItems="center" justifyContent="center">
        <Grid item>{icon}</Grid>
        <Grid item>
          <Typography variant="body2" color="text.secondary" component="span" sx={{ ml: 1 }}>
            {text}
          </Typography>
        </Grid>
      </Grid>
    </StyledPaper>
  )
}

export default IntegrationItem
