import { MetricAction } from '@counsel-project/counsel-transcribe-api/dist/common/database/RMetric'

export const METRIC_NAME_MAPPINGS: Record<MetricAction, string> = {
  'create-document': 'Documents Uploaded',
  'create-layout': 'Templates Created',
  'delete-dictation': 'Dictations Deleted',
  'delete-document': 'Documents Deleted',
  'delete-layout': 'Templates Deleted',
  dictation: 'Dictations',
  'edit-document': 'Documents Manually Edited',
  'edit-document-with-ai': 'Documents Edited With AI',
  'generate-document': 'Documents Generated',
  'live-session': 'Live Sessions',
  'edit-layout': 'Templates Edited',
  feedback: 'Feedback Given',
  'delete-live-session': 'Live Sessions Deleted',
  'any-edit-document': 'Documents Edited',
}

export const METRIC_NAME_MAPPINGS_SINGULAR: Record<MetricAction, string> = {
  'create-document': 'Document Uploaded',
  'create-layout': 'Template Created',
  dictation: 'Dictation',
  'delete-dictation': 'Dictation Deleted',
  'delete-document': 'Document Deleted',
  'delete-layout': 'Template Deleted',
  'live-session': 'Live Session',
  'delete-live-session': 'Live Session Deleted',
  feedback: 'Feedback Given',
  'generate-document': 'Document Generated',
  'edit-layout': 'Template Edited',
  'edit-document': 'Document Manually Edited',
  'edit-document-with-ai': 'Document Edited With AI',
  'any-edit-document': 'Document Edited',
}
