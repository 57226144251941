import { IconCurlyBrackets } from '@codexteam/icons'
import { EditorConfig, BlockAPI } from '@editorjs/editorjs'
import toast from 'react-hot-toast'

const rowWrapperStyles = `
display: flex;
justify-content: end;
align-items: center;
flex-direction: row;
`
  .trim()
  .split('\n')
  .join(' ')

export const copyButtonIconSrc = '/icons/copy.svg'

export const emailButtonIconSrc = '/icons/forward-1.svg'

export const copyButtonIconStyles = `
object-fit: contain;
width: 100%;
`
  .trim()
  .split('\n')
  .join(' ')

export const emailButtonIconStyles = `
object-fit: contain;
width: 100%;
`
  .trim()
  .split('\n')
  .join(' ')

const containerStyles = `
display: flex;
justify-content: stretch;
flex-direction: column;
`
  .trim()
  .split('\n')
  .join(' ')

const inputStyles = `
width: 100%;
min-height: 80px;
padding: 12px;
border: 1px solid #ccc;
border-radius: 12px;
margin-top: 8px;
font-family: 'Roboto', sans-serif;
font-size: 16px;
overflow: hidden;
`
  .trim()
  .split('\n')
  .join(' ')

export const iconButtonStyles = `
cursor: pointer;
border: none;
background: none;
outline: none;
padding: 6px;
margin: 0;
border-radius: 12px;
margin-left: 4px;
background-color: #f0f0f0;
width: 32px;
height: 32px;
margin-top: 8px;
`
  .trim()
  .split('\n')
  .join(' ')

type EditorJSData = {
  content: string
}

type ConstructorOptions = {
  data: EditorJSData
  api: BlockAPI
  config: EditorConfig & {
    onEmail: (text: string) => void
    onCreateNewNote: (text: string) => void
  }
  readOnly: boolean
}

class ExportBox {
  data: EditorJSData
  container: HTMLElement | null
  input: HTMLTextAreaElement | null
  config:
    | (EditorConfig & { onEmail: (text: string) => void; onCreateNewNote: (text: string) => void })
    | null
  readOnly: boolean

  constructor({ data, api, config, readOnly }: ConstructorOptions) {
    this.data = data || { content: '' }
    this.config = config || null
    this.container = null
    this.input = null
    this.readOnly = readOnly
  }

  static get isReadOnlySupported() {
    return true
  }

  static get toolbox() {
    return {
      title: 'Exportable Box',
      icon: IconCurlyBrackets,
    }
  }

  onKeyDown(e: KeyboardEvent) {
    if (e.code === 'Enter') {
      e.stopPropagation()
    }
  }

  save(blockContent: HTMLElement) {
    return {
      content: blockContent.querySelector('textarea')?.value || '',
    }
  }

  autoGrow(element: HTMLTextAreaElement) {
    element.style.height = '5px'
    element.style.height = element.scrollHeight + 'px'
  }

  render() {
    const input = document.createElement('textarea')
    const container = document.createElement('div')
    const rowWrapper = document.createElement('div')
    const copyButton = document.createElement('button')
    const emailButton = document.createElement('button')
    const copyButtonIcon = document.createElement('img')
    const emailButtonIcon = document.createElement('img')

    copyButton.appendChild(copyButtonIcon).setAttribute('src', copyButtonIconSrc)
    copyButtonIcon.setAttribute('style', copyButtonIconStyles)
    copyButton.setAttribute('style', iconButtonStyles)
    copyButton.addEventListener('click', () => {
      input.select()
      document.execCommand('copy')
      toast.success('Copied to clipboard')
    })

    emailButton.appendChild(emailButtonIcon).setAttribute('src', emailButtonIconSrc)
    emailButtonIcon.setAttribute('style', emailButtonIconStyles)
    emailButton.setAttribute('style', iconButtonStyles)
    emailButton.addEventListener('click', () => {
      this.config?.onEmail(input.value)
    })

    input.setAttribute('class', 'no-input-focus')
    input.setAttribute('style', inputStyles)
    input.setAttribute('placeholder', 'Enter your content here...')

    // Auto grow textarea
    input.addEventListener('input', () => this.autoGrow(input))
    setTimeout(() => {
      this.autoGrow(input)
    }, 100)

    // Remove textarea resize
    input.style.resize = 'none'
    container.setAttribute('style', containerStyles)

    rowWrapper.setAttribute('style', rowWrapperStyles)
    rowWrapper.appendChild(copyButton)
    rowWrapper.appendChild(emailButton)

    container.appendChild(input)
    container.appendChild(rowWrapper)

    this.container = container
    this.input = input

    if (this.data?.content) {
      this.input.value = this.data.content.trim()
    }

    this.input.addEventListener('keydown', (e) => this.onKeyDown(e))

    return container
  }
}

export default ExportBox
