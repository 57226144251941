import { RRole } from '@counsel-project/counsel-auth-api'
import { RPatient } from '@counsel-project/counsel-transcribe-api'
import Button from '@mui/material/Button'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import Typography from '@mui/material/Typography'
import { capitalize } from '@counsel-project/client-utils'
import { useCallback, useEffect, useState } from 'react'
import ClosableDialog from '../../components/ClosableDialog'
import RoleSelector from '../../components/selectors/RoleSelector'
import { usePatientNomenclature } from '../../util'
import { transcribeRequest } from '../../util/api/transcribe-api'
import checkToken from '../../util/auth/checkToken'
import handleError from '../../util/handleError'
import { refreshPatientsCache } from '../../util/api/transcribe-api-cached'

type PatientReassignDialogProps = {
  patient: RPatient
  open: boolean
  onClose: () => void
  onChangePatient: (patient: RPatient) => void
}

const PatientReassignDialog = ({
  patient,
  open,
  onClose,
  onChangePatient,
}: PatientReassignDialogProps) => {
  const [role, setRole] = useState<RRole | null>(null)
  const [loading, setLoading] = useState(false)

  const patientNomenclature = usePatientNomenclature()

  const handleReassign = useCallback(async () => {
    try {
      if (!role) return
      if (!role.email) return

      setLoading(true)

      await checkToken()

      const { result } = await transcribeRequest.patients.reassign({
        token: '',
        patientId: patient._id,
        email: role.email,
      })

      refreshPatientsCache()

      onChangePatient(result)
      onClose()
    } catch (err) {
      handleError(err)
    } finally {
      setLoading(false)
    }
  }, [role, patient, onChangePatient, onClose])

  useEffect(() => {
    if (!open) {
      setRole(null)
    }
  }, [open])

  return (
    <ClosableDialog
      open={open}
      onClose={onClose}
      titleText={`Reassign ${capitalize(patientNomenclature)}`}
      maxWidth="sm"
    >
      <DialogContent>
        <Typography variant="body1" sx={{ mb: 2 }}>
          Reassign this {patientNomenclature} to a new user in your organization.
        </Typography>
        <RoleSelector
          value={role}
          onChange={setRole}
          disabled={loading}
          hideEmails={[patient.email]}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleReassign} disabled={!role || loading}>
          Reassign This {capitalize(patientNomenclature)}
        </Button>
      </DialogActions>
    </ClosableDialog>
  )
}

export default PatientReassignDialog
