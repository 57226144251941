import { useState, useEffect } from 'react'
import ClosableDialog from '../../components/ClosableDialog'
import DialogContent from '@mui/material/DialogContent'
import PatientInfoEditor, { PatientInfoEditorProps } from './PatientInfoEditor'
import { usePatientNomenclature } from '../../util'
import { capitalize } from '@counsel-project/client-utils'

type PatientInfoDialogProps = {
  open: boolean
  onClose: () => void
} & PatientInfoEditorProps

const PatientInfoDialog = ({ open, onClose, ...props }: PatientInfoDialogProps) => {
  const [patient, setPatient] = useState(props.patient)

  useEffect(() => {
    setPatient(props.patient)
  }, [props.patient])

  const patientNomenclature = usePatientNomenclature()

  return (
    <ClosableDialog
      open={open}
      onClose={onClose}
      titleText={`${capitalize(patientNomenclature)} Information`}
      maxWidth="md"
    >
      <DialogContent>
        <PatientInfoEditor {...props} patient={patient} onSaved={setPatient} />
      </DialogContent>
    </ClosableDialog>
  )
}

export default PatientInfoDialog
