import { MetricAction, METRIC_ACTIONS } from '@counsel-project/counsel-transcribe-api'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import { METRIC_NAME_MAPPINGS_SINGULAR } from './lib'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import Chip from '@mui/material/Chip'

type MetricActionSelectorProps = {
  value: MetricAction[]
  onChange: (value: MetricAction[]) => void
}

const MetricActionSelector = ({ value, onChange }: MetricActionSelectorProps) => {
  return (
    <Autocomplete
      multiple
      value={value}
      onChange={(_, value) => onChange(value as MetricAction[])}
      options={METRIC_ACTIONS}
      renderInput={(params) => <TextField {...params} />}
      renderOption={(props, option) => (
        <ListItem {...props}>
          <ListItemText>{METRIC_NAME_MAPPINGS_SINGULAR[option]}</ListItemText>
        </ListItem>
      )}
      renderTags={(value) =>
        value.map((option) => (
          <Chip
            key={option}
            label={METRIC_NAME_MAPPINGS_SINGULAR[option]}
            size="small"
            sx={{ mr: 0.5 }}
          />
        ))
      }
    />
  )
}

export default MetricActionSelector
