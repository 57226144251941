import { IconCurlyBrackets } from '@codexteam/icons'
import { EditorConfig, BlockAPI } from '@editorjs/editorjs'
import toast from 'react-hot-toast'
import {
  copyButtonIconSrc,
  copyButtonIconStyles,
  emailButtonIconSrc,
  emailButtonIconStyles,
  iconButtonStyles,
} from '../ExportBox'

const imgSrc = '/icons/auto-awesome.svg'

const imgStyles = `
width: 24px;
height: 24px;
margin-right: 12px;
margin-top: 12px;
`
  .trim()
  .split('\n')
  .join(' ')

const textStyles = `
font-family: 'Roboto', monospace;
font-size: 14px;
margin-top: 12px;
color: #757575;
`

const rowWrapperStyles = `
display: flex;
justify-content: end;
align-items: center;
flex-direction: row;
`
  .trim()
  .split('\n')
  .join(' ')

const containerStyles = `
display: flex;
justify-content: stretch;
flex-direction: column;
padding: 12px;
background-color: #f0f0f0;
border-radius: 12px;
margin-bottom: 6px;
margin-top: 6px;
`
  .trim()
  .split('\n')
  .join(' ')

const inputStyles = `
width: 100%;
min-height: 80px;
padding: 12px;
border: none;
border-radius: 12px;
font-family: 'Roboto', monospace;
font-size: 16px;
overflow: hidden;
`
  .trim()
  .split('\n')
  .join(' ')

type EditorJSData = {
  content: string
}

type ConstructorOptions = {
  data: EditorJSData
  api: BlockAPI
  config: EditorConfig & {
    onEmail: (text: string) => void
    onCreateNewNote: (text: string) => void
  }
  readOnly: boolean
}

class AIExportBox {
  data: EditorJSData
  container: HTMLElement | null
  input: HTMLTextAreaElement | null
  config:
    | (EditorConfig & { onEmail: (text: string) => void; onCreateNewNote: (text: string) => void })
    | null
  readOnly: boolean

  constructor({ data, config, readOnly }: ConstructorOptions) {
    this.data = data || { content: '' }
    this.config = config || null
    this.container = null
    this.input = null
    this.readOnly = readOnly
  }

  static get toolbox() {
    return {
      title: 'AI Fill - Exportable',
      icon: IconCurlyBrackets,
    }
  }

  static get isReadOnlySupported() {
    return true
  }

  onKeyDown(e: KeyboardEvent) {
    if (e.code === 'Enter') {
      e.stopPropagation()
    }
  }

  save(blockContent: HTMLElement) {
    return {
      content: blockContent.querySelector('textarea')?.value || '',
    }
  }

  autoGrow(element: HTMLTextAreaElement) {
    element.style.height = '5px'
    element.style.height = element.scrollHeight + 'px'
  }

  render() {
    const input = document.createElement('textarea')
    const container = document.createElement('div')
    const rowWrapper = document.createElement('div')
    const text = document.createElement('span')
    const img = document.createElement('img')
    const copyButton = document.createElement('button')
    const emailButton = document.createElement('button')
    const copyButtonIcon = document.createElement('img')
    const emailButtonIcon = document.createElement('img')

    copyButton.appendChild(copyButtonIcon).setAttribute('src', copyButtonIconSrc)
    copyButtonIcon.setAttribute('style', copyButtonIconStyles)
    copyButton.setAttribute('style', iconButtonStyles)
    copyButton.addEventListener('click', () => {
      input.select()
      document.execCommand('copy')
      toast.success('Copied to clipboard')
    })

    emailButton.appendChild(emailButtonIcon).setAttribute('src', emailButtonIconSrc)
    emailButtonIcon.setAttribute('style', emailButtonIconStyles)
    emailButton.setAttribute('style', iconButtonStyles)
    emailButton.addEventListener('click', () => {
      this.config?.onEmail(input.value)
    })

    input.setAttribute('style', inputStyles)
    // Set input focus styles
    input.setAttribute('class', 'no-input-focus')
    input.setAttribute(
      'placeholder',
      'Enter a description of what you want AI to automatically generate here...'
    )
    if (this.readOnly) {
      input.setAttribute('disabled', 'true')
    }

    // Auto grow textarea
    input.addEventListener('input', () => this.autoGrow(input))
    setTimeout(() => {
      this.autoGrow(input)
    }, 100)

    // Remove textarea resize
    input.style.resize = 'none'
    container.setAttribute('style', containerStyles)
    rowWrapper.setAttribute('style', rowWrapperStyles)
    text.setAttribute('style', textStyles)
    text.textContent = 'AI auto-fill'
    img.setAttribute('src', imgSrc)
    img.setAttribute('style', imgStyles)
    rowWrapper.appendChild(copyButton)
    rowWrapper.appendChild(emailButton)
    rowWrapper.appendChild(img)
    rowWrapper.appendChild(text)

    container.appendChild(input)
    container.appendChild(rowWrapper)

    this.container = container
    this.input = input

    if (this.data?.content) {
      this.input.value = this.data.content.trim()
    }

    this.input.addEventListener('keydown', (e) => this.onKeyDown(e))

    return container
  }
}

export default AIExportBox
