import { useCallback, useEffect, useState } from 'react'
import ClosableDialog from '../../components/ClosableDialog'
import { RSiteBanner } from '@counsel-project/counsel-auth-api/dist/common/database/SiteBanner'
import { authRequest } from '../../util/api/auth-api'
import checkToken from '../../util/auth/checkToken'
import handleError from '../../util/handleError'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import DatePeriodSelector from '../../components/DatePeriodSelector'
import Box from '@mui/material/Box'
import Divider from '@mui/material/Divider'
import Paper from '@mui/material/Paper'
import { getRelativeTime } from '../../util'

type AddBannerDialogProps = {
  open: boolean
  onClose: () => void
  onAdded?: (banner: RSiteBanner) => void
}

const AddBannerDialog = ({ open, onClose, onAdded }: AddBannerDialogProps) => {
  const [text, setText] = useState('')
  const [datePeriod, setDatePeriod] = useState<number>(1000 * 60 * 60)
  const [banners, setBanners] = useState<RSiteBanner[]>([])
  const [loading, setLoading] = useState(false)

  const populateBanners = useCallback(async () => {
    try {
      const loggedIn = await checkToken()
      if (!loggedIn) return

      const { results } = await authRequest.user.banners.list({ token: '' })
      setBanners(results)
    } catch (err) {
      handleError(err)
    }
  }, [])

  useEffect(() => {
    populateBanners()
  }, [populateBanners])

  const handleCreateBanner = useCallback(async () => {
    try {
      setLoading(true)

      const loggedIn = await checkToken()
      if (!loggedIn) return

      const expiresAt = new Date(Date.now() + datePeriod)

      const { result } = await authRequest.admin.banners.create({
        token: '',
        text,
        expiresAt: expiresAt.toString(),
      })

      onAdded?.(result)

      populateBanners()
    } catch (err) {
      handleError(err)
    } finally {
      setLoading(false)
    }
  }, [datePeriod, text, onAdded, populateBanners])

  const handleDeleteBanner = useCallback(
    async (bannerId: string) => {
      try {
        const loggedIn = await checkToken()
        if (!loggedIn) return

        await authRequest.admin.banners.delete({ token: '', bannerId })

        populateBanners()
      } catch (err) {
        handleError(err)
      }
    },
    [populateBanners]
  )

  return (
    <ClosableDialog open={open} onClose={onClose} titleText="Add Banner">
      <DialogContent>
        <Typography variant="body1" fontWeight={500} gutterBottom>
          Existing Banners
        </Typography>
        {banners.map((banner) => (
          <Paper variant="outlined" sx={{ mb: 2, p: 1 }}>
            <Typography variant="h6" sx={{ mb: 1 }}>
              {banner.text}
            </Typography>
            <Typography variant="subtitle1">
              Deletes in{' '}
              {getRelativeTime((new Date(banner.expiresAt).getTime() - Date.now()) / 1000)}
            </Typography>
            <Button onClick={() => handleDeleteBanner(banner._id)}>Delete</Button>
          </Paper>
        ))}

        <Typography variant="body1" fontWeight={500} gutterBottom>
          Banner Text
        </Typography>
        <TextField
          placeholder="Banner Text"
          value={text}
          onChange={(e) => setText(e.target.value)}
          fullWidth
        />
        <Typography variant="body1" fontWeight={500} gutterBottom sx={{ mt: 1 }}>
          Remove this banner in
        </Typography>
        <DatePeriodSelector
          value={datePeriod}
          onChange={(value) => setDatePeriod(value ?? 1000 * 60 * 60)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} disabled={loading}>
          Cancel
        </Button>
        <Button onClick={handleCreateBanner} disabled={loading}>
          Create
        </Button>
      </DialogActions>
    </ClosableDialog>
  )
}

export default AddBannerDialog
