import { PermissionType } from '@counsel-project/counsel-auth-api'
import DoDisturbAltRoundedIcon from '@mui/icons-material/DoDisturbAltRounded'
import LocalPoliceRoundedIcon from '@mui/icons-material/LocalPoliceRounded'
import PeopleAltRoundedIcon from '@mui/icons-material/PeopleAltRounded'
import SecurityRoundedIcon from '@mui/icons-material/SecurityRounded'
import ShieldIcon from '@mui/icons-material/ShieldRounded'
import { SvgIconProps } from '@mui/material/SvgIcon'
import VisibilityIcon from '@mui/icons-material/VisibilityRounded'

export type PermissionIconProps = SvgIconProps & {
  permission: PermissionType
  priority?: number
}

const PermissionIcon = ({ permission, priority = 11, ...props }: PermissionIconProps) => {
  if (permission === 'administrator' && priority > 11) {
    return <LocalPoliceRoundedIcon {...props} />
  }

  switch (permission) {
    case 'administrator':
      return <ShieldIcon {...props} />
    case 'master-counselor':
      return <SecurityRoundedIcon {...props} />
    case 'counselor':
      return <PeopleAltRoundedIcon {...props} />
    case 'supervisor':
      return <VisibilityIcon {...props} />
    default:
      return <DoDisturbAltRoundedIcon {...props} />
  }
}

export default PermissionIcon
