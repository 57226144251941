import LoadingButton from '@mui/lab/LoadingButton'
import Alert from '@mui/material/Alert'
import Collapse from '@mui/material/Collapse'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import { useCallback, useEffect, useState } from 'react'
import { authRequest } from '../util/api/auth-api'
import checkToken from '../util/auth/checkToken'
import useUser from '../util/auth/useUser'
import handleError from '../util/handleError'
import ClosableDialog from './ClosableDialog'

export type VerifyPhoneDialogProps = {
  userId: string
  open: boolean
  onClose: () => void
  onVerify?: () => void
}

const VerifyPhoneDialog = ({ userId, open, onClose, onVerify }: VerifyPhoneDialogProps) => {
  const [user, setUser] = useUser()

  const [code, setCode] = useState('')
  const [error, setError] = useState('')
  const [loaded, setLoaded] = useState(true)

  const handleVerifyPhone = useCallback(async () => {
    try {
      if (!user) return
      if (!userId) return
      if (!code) {
        setError('Please enter the code we sent you.')
        return
      }

      await checkToken()

      setLoaded(false)

      const res = await authRequest.user.account.verifyPhone({
        token: '',
        code,
        userId,
      })

      setUser(res.user)

      onClose()
      onVerify?.()
    } catch (err) {
      handleError(err)
    } finally {
      setLoaded(true)
    }
  }, [onClose, code, onVerify, user, userId, setUser])

  useEffect(() => {
    if (!code) return
    if (code.length !== 6) return
    const timeout = setTimeout(handleVerifyPhone, 100)
    return () => clearTimeout(timeout)
  }, [code, handleVerifyPhone])

  return (
    <ClosableDialog open={open} onClose={onClose} titleText="Verify Phone">
      <Grid
        container
        justifyContent="center"
        direction="column"
        paddingX={2}
        paddingBottom={2}
        spacing={2}
      >
        <Grid item xs={12}>
          <Collapse in={Boolean(error)}>
            <Alert severity="error">{error}</Alert>
          </Collapse>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1" color="text.secondary" sx={{ mb: 2, mx: 1 }}>
            Please verify your phone number by entering the code we sent you.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField
            placeholder="Code"
            type="text"
            aria-label="code"
            autoComplete="one-time-code"
            fullWidth
            value={code}
            onChange={(e) => setCode(e.target.value)}
            autoFocus
          />
        </Grid>
        <Grid item>
          <LoadingButton
            loading={!loaded}
            fullWidth
            onClick={handleVerifyPhone}
            disabled={!code || code.length !== 6}
          >
            Verify Phone
          </LoadingButton>
        </Grid>
      </Grid>
    </ClosableDialog>
  )
}

export default VerifyPhoneDialog
