import {
  PatientSessionsListPatientOptions,
  RPatient,
  RPatientSession,
} from '@counsel-project/counsel-transcribe-api'
import AddIcon from '@mui/icons-material/AddRounded'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import toast from 'react-hot-toast'
import { useEffectOnce } from 'react-use'
import { transcribeRequest } from '../../util/api/transcribe-api'
import checkToken from '../../util/auth/checkToken'
import useLayouts from '../../util/auth/useLayouts'
import ClosableDialog from '../ClosableDialog'
import SearchBar from '../SearchBar'
import ContextOption from './ContextOption'
import SimplePagination from './SimplePagination'
import { LayoutType } from '@counsel-project/counsel-transcribe-api/dist/common/database/RLayout'
import handleError from '../../util/handleError'

type SelectPatientContextDialogProps = {
  open: boolean
  onClose: () => void
  patient: RPatient
  onAddSessions: (sessions: RPatientSession[]) => void
  mainSession?: RPatientSession | null
  whitelistedLayouts?: string[]
  whitelistedTypes?: LayoutType[]
  max: number
}

const limit = 6

const SelectPatientContextDialog = ({
  open,
  onClose,
  patient,
  onAddSessions,
  mainSession,
  whitelistedLayouts,
  whitelistedTypes,
  max,
}: SelectPatientContextDialogProps) => {
  const [layouts, , populateLayouts] = useLayouts()

  useEffectOnce(() => {
    populateLayouts()
  })

  const [offset, setOffset] = useState(0)
  const [sessions, setSessions] = useState<RPatientSession[]>([])
  const [total, setTotal] = useState(0)
  const [loading, setLoading] = useState(false)
  const [search, setSearch] = useState('')
  const [submittedSearch, setSubmittedSearch] = useState('')
  const [selectedSessions, setSelectedSessions] = useState<RPatientSession[]>([])

  const hideDocuments = useMemo(
    () => whitelistedTypes && !whitelistedTypes.includes('document'),
    [whitelistedTypes]
  )
  const hideNotes = useMemo(
    () => whitelistedTypes && !whitelistedTypes.includes('note'),
    [whitelistedTypes]
  )
  const multiple = useMemo(() => max > 1, [max])

  useEffect(() => {
    if (!open) return
    setOffset(0)
    setSubmittedSearch('')
    setSearch('')
  }, [open])

  const populateSessions = useCallback(async () => {
    try {
      if (!patient._id) return

      setLoading(true)

      await checkToken()

      const search: PatientSessionsListPatientOptions['search'] = {}

      if (hideNotes) {
        search.and = [
          { type: 'note', $not: true },
          { type: null, $not: true },
        ]
      }

      if (hideDocuments) {
        search.and = search.and || []
        search.and.push({ type: 'document', $not: true })
      }

      if (submittedSearch) {
        search.or = [
          { summary: submittedSearch, $fuzzy: true },
          { type: submittedSearch, $fuzzy: true },
          {
            layout: submittedSearch,
            $fuzzy: true,
          },
        ]
      }

      if (whitelistedLayouts) {
        search.or = search.or || []
        search.or.push(...whitelistedLayouts.map((l) => ({ layout: l })))
      }

      if (whitelistedTypes) {
        search.or = search.or || []
        search.or.push(...whitelistedTypes.map((t) => ({ type: t })))
      }

      const { results, total } = await transcribeRequest.sessions.list.patient({
        patientId: patient._id,
        token: '',
        sort: 'createdAt',
        direction: 'desc',
        limit,
        offset,
        search: Object.keys(search).length > 0 ? search : undefined,
      })

      setSessions(results.filter((s) => s._id !== mainSession?._id))
      setTotal(total)
    } catch (err) {
      handleError(err)
    } finally {
      setLoading(false)
    }
  }, [
    offset,
    submittedSearch,
    patient,
    mainSession,
    whitelistedLayouts,
    hideDocuments,
    hideNotes,
    whitelistedTypes,
  ])

  useEffect(() => {
    populateSessions()
  }, [populateSessions])

  const handleSubmit = useCallback(() => {
    setSubmittedSearch(search)
    setOffset(0)
  }, [search])

  const handleChangeSearch = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value)
    if (!e.target.value) {
      setSubmittedSearch('')
      setOffset(0)
    }
  }, [])

  const sessionAddHandler = useCallback(
    (session: RPatientSession) => () => {
      if (!multiple) {
        onAddSessions([session])
        onClose()
        return
      }

      if (selectedSessions.some((s) => s._id === session._id)) {
        setSelectedSessions(selectedSessions.filter((s) => s._id !== session._id))
      } else {
        if (selectedSessions.length >= max) {
          toast.error(`You may only add ${max} of these`, { id: 'max-docs' })
          return
        }
        setSelectedSessions([...selectedSessions, session])
      }
    },
    [selectedSessions, max, multiple, onAddSessions, onClose]
  )

  const handleAddSessions = useCallback(() => {
    onClose()
    onAddSessions(selectedSessions)
    setSelectedSessions([])
  }, [onAddSessions, selectedSessions, onClose])

  return (
    <ClosableDialog open={open} onClose={onClose} titleText={'Documentation Context'} maxWidth="md">
      <DialogContent>
        <Grid container spacing={2} alignItems="stretch">
          <Grid item xs={12}>
            <SearchBar
              disabled={loading}
              value={search}
              onChange={handleChangeSearch}
              onSubmit={handleSubmit}
            />
          </Grid>
          {sessions.length === 0 && !loading && (
            <Grid item xs={12}>
              <Typography variant="body1" color="text.secondary" textAlign="center" sx={{ py: 17 }}>
                No files found
              </Typography>
            </Grid>
          )}
          {loading && (
            <Grid item xs={12}>
              <Box textAlign="center" sx={{ minHeight: 120, my: 17 }}>
                <CircularProgress />
              </Box>
            </Grid>
          )}
          {!loading &&
            sessions.map((session) => (
              <Grid item key={session._id} xs={12} sm={6}>
                <ContextOption
                  layouts={layouts}
                  session={session}
                  onClick={sessionAddHandler(session)}
                  selected={selectedSessions.some((s) => s._id === session._id)}
                  disabled={loading}
                />
              </Grid>
            ))}
          <Grid item xs={12}>
            <SimplePagination
              total={total}
              offset={offset}
              limit={limit}
              onClickPrevious={() => {
                setOffset(offset - limit)
              }}
              onClickNext={() => {
                setOffset(offset + limit)
              }}
            />
          </Grid>
        </Grid>
      </DialogContent>
      {multiple && (
        <DialogActions>
          <Grid container>
            <Grid item xs>
              <Typography variant="body1" color="text.secondary">
                {selectedSessions.length} files selected
              </Typography>
            </Grid>
            <Grid item>
              <Button
                startIcon={<AddIcon />}
                disabled={selectedSessions.length === 0 || loading}
                onClick={handleAddSessions}
              >
                Add These Files
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      )}
    </ClosableDialog>
  )
}

export default SelectPatientContextDialog
