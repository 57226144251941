import {
  IntegrationPatientSortableFields,
  RIntegrationPatient,
} from '@counsel-project/counsel-ehr-api'
import { useCallback, useEffect, useState } from 'react'
import IntegrationPatientTable from '../../../components/tables/IntegrationPatientTable'
import { ehrRequest } from '../../../util/api/ehr-api'
import checkToken from '../../../util/auth/checkToken'
import handleError from '../../../util/handleError'

type IntegrationPatientsDisplayProps = {
  integrationId: string
}

const IntegrationPatientsDisplay = ({ integrationId }: IntegrationPatientsDisplayProps) => {
  const [page, setPage] = useState(0)
  const [limit, setLimit] = useState(10)
  const [search, setSearch] = useState('')
  const [sort, setSort] = useState<IntegrationPatientSortableFields>('name')
  const [direction, setDirection] = useState<'asc' | 'desc'>('asc')
  const [loading, setLoading] = useState(false)
  const [total, setTotal] = useState(0)
  const [templates, setTemplates] = useState<RIntegrationPatient[]>([])

  const populateTemplates = useCallback(async () => {
    try {
      setLoading(true)

      await checkToken()

      const { results, total } = await ehrRequest.integrations.patients.listByIntegration({
        token: '',
        integrationId,
        offset: page * limit,
        limit,
        search: search
          ? {
              and: [{ name: search, $fuzzy: true }],
            }
          : undefined,
        sort,
        direction,
      })

      setTemplates(results)
      setTotal(total)
    } catch (err) {
      handleError(err)
    } finally {
      setLoading(false)
    }
  }, [integrationId, page, limit, search, sort, direction])

  useEffect(() => {
    const timeout = setTimeout(() => {
      populateTemplates()
    }, 10)
    return () => clearTimeout(timeout)
  }, [populateTemplates])

  return (
    <IntegrationPatientTable
      rows={templates}
      page={page}
      rowsPerPage={limit}
      total={total}
      loaded={!loading}
      sort={sort}
      direction={direction}
      onChangeSort={(sort, dir) => {
        setSort(sort)
        setDirection(dir)
      }}
      onChangePage={setPage}
      onChangeRowsPerPage={setLimit}
      onSubmitSearch={setSearch}
      onClickRow={() => {}}
      rowsPerPageOptions={[]}
    />
  )
}

export default IntegrationPatientsDisplay
