import React from 'react'
import Skeleton from '@mui/material/Skeleton'
import PageContainer from '../layout/PageContainer'
import Grid from '@mui/material/Grid'

type PagePaperLoaderProps = {
  children?: React.ReactNode
  sections: number[]
}

const PagePaperLoader = ({ children, sections }: PagePaperLoaderProps) => {
  return (
    <PageContainer>
      <Grid container spacing={2}>
        {sections.map((s, i) => (
          <Grid item xs={12} key={i}>
            <Skeleton
              variant="rectangular"
              height={s}
              width="100%"
              sx={{ backgroundColor: 'background.paper' }}
            />
          </Grid>
        ))}
      </Grid>
      {children}
    </PageContainer>
  )
}

export default PagePaperLoader
