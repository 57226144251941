import {
  PatientSessionsListMineOptions,
  PatientSessionsListMineResponse,
  PatientSessionsListUserOptions,
  PatientSessionsListUserResponse,
  PatientSessionsListAllOptions,
  PatientSessionsListAllResponse,
  PatientsListAllOptions,
  PatientsListAllResponse,
  PatientsListUserOptions,
  PatientsListUserResponse,
  PatientsListMineOptions,
  PatientsListMineResponse,
  PatientSessionsListPatientResponse,
  PatientSessionsListPatientOptions,
} from '@counsel-project/counsel-transcribe-api'
import { transcribeRequest } from './transcribe-api'

const cacheLimit = 20

// sessions.list.mine

type MySessionCacheItem = {
  optionsId: string
  response: PatientSessionsListMineResponse
}

let mySessionsCache: MySessionCacheItem[] = []

type ListSessionsCachedOptions = {
  refresh?: boolean
} & PatientSessionsListMineOptions

export const listMySessionsCached = async (
  options: ListSessionsCachedOptions
): Promise<PatientSessionsListMineResponse> => {
  const { refresh, ...otherOptions } = options

  const optionsId = JSON.stringify(otherOptions)

  let res: PatientSessionsListMineResponse
  if (refresh) {
    mySessionsCache = mySessionsCache.filter((item) => item.optionsId !== optionsId)
    res = await transcribeRequest.sessions.list.mine(otherOptions)
    mySessionsCache.push({ optionsId, response: res })
  } else {
    const cachedItem = mySessionsCache.find((item) => item.optionsId === optionsId)
    if (cachedItem) {
      res = cachedItem.response
    } else {
      res = await transcribeRequest.sessions.list.mine(otherOptions)
      mySessionsCache.push({ optionsId, response: res })
    }
  }

  if (mySessionsCache.length > cacheLimit) {
    mySessionsCache.shift()
  }
  return res
}

export const refreshMySessionsCache = () => {
  mySessionsCache = []
}

// sessions.list.user

type UserSessionCacheItem = {
  optionsId: string
  response: PatientSessionsListUserResponse
}

let userSessionsCache: UserSessionCacheItem[] = []

type ListUserSessionsCachedOptions = {
  refresh?: boolean
} & PatientSessionsListUserOptions

export const listUserSessionsCached = async (
  options: ListUserSessionsCachedOptions
): Promise<PatientSessionsListUserResponse> => {
  const { refresh, ...otherOptions } = options

  const optionsId = JSON.stringify(otherOptions)

  let res: PatientSessionsListUserResponse
  if (refresh) {
    userSessionsCache = userSessionsCache.filter((item) => item.optionsId !== optionsId)
    res = await transcribeRequest.sessions.list.user(otherOptions)
    userSessionsCache.push({ optionsId, response: res })
  } else {
    const cachedItem = userSessionsCache.find((item) => item.optionsId === optionsId)
    if (cachedItem) {
      res = cachedItem.response
    } else {
      res = await transcribeRequest.sessions.list.user(otherOptions)
      userSessionsCache.push({ optionsId, response: res })
    }
  }

  if (userSessionsCache.length > cacheLimit) {
    userSessionsCache.shift()
  }

  return res
}

export const refreshUserSessionsCache = () => {
  userSessionsCache = []
}

// sessions.list.all

type AllSessionCacheItem = {
  optionsId: string
  response: PatientSessionsListAllResponse
}

let allSessionsCache: AllSessionCacheItem[] = []

type ListAllSessionsCachedOptions = {
  refresh?: boolean
} & PatientSessionsListAllOptions

export const listAllSessionsCached = async (
  options: ListAllSessionsCachedOptions
): Promise<PatientSessionsListAllResponse> => {
  const { refresh, ...otherOptions } = options

  const optionsId = JSON.stringify(otherOptions)

  let res: PatientSessionsListAllResponse
  if (refresh) {
    allSessionsCache = allSessionsCache.filter((item) => item.optionsId !== optionsId)
    res = await transcribeRequest.sessions.list.all(otherOptions)
    allSessionsCache.push({ optionsId, response: res })
  } else {
    const cachedItem = allSessionsCache.find((item) => item.optionsId === optionsId)
    if (cachedItem) {
      res = cachedItem.response
    } else {
      res = await transcribeRequest.sessions.list.all(otherOptions)
      allSessionsCache.push({ optionsId, response: res })
    }
  }

  if (allSessionsCache.length > cacheLimit) {
    allSessionsCache.shift()
  }

  return res
}

export const refreshAllSessionsCache = () => {
  allSessionsCache = []
}

// sessions.list.patient

type PatientSessionsCacheItem = {
  optionsId: string
  response: PatientSessionsListPatientResponse
}

let patientSessionsCache: PatientSessionsCacheItem[] = []

type ListPatientSessionsCachedOptions = {
  refresh?: boolean
} & PatientSessionsListPatientOptions

export const listPatientSessionsCached = async (
  options: ListPatientSessionsCachedOptions
): Promise<PatientSessionsListPatientResponse> => {
  const { refresh, ...otherOptions } = options

  const optionsId = JSON.stringify(otherOptions)

  let res: PatientSessionsListPatientResponse
  if (refresh) {
    patientSessionsCache = patientSessionsCache.filter((item) => item.optionsId !== optionsId)
    res = await transcribeRequest.sessions.list.patient(otherOptions)
    patientSessionsCache.push({ optionsId, response: res })
  } else {
    const cachedItem = patientSessionsCache.find((item) => item.optionsId === optionsId)
    if (cachedItem) {
      res = cachedItem.response
    } else {
      res = await transcribeRequest.sessions.list.patient(otherOptions)
      patientSessionsCache.push({ optionsId, response: res })
    }
  }

  if (patientSessionsCache.length > cacheLimit) {
    patientSessionsCache.shift()
  }

  return res
}

export const refreshPatientSessionsCache = () => {
  patientSessionsCache = []
}

// patients.list.mine

type MyPatientsCacheItem = {
  optionsId: string
  response: PatientsListMineResponse
}

let myPatientsCache: MyPatientsCacheItem[] = []

type ListPatientsCachedOptions = {
  refresh?: boolean
} & PatientsListMineOptions

export const listMyPatientsCached = async (
  options: ListPatientsCachedOptions
): Promise<PatientsListMineResponse> => {
  const { refresh, ...otherOptions } = options

  const optionsId = JSON.stringify(otherOptions)

  let res: PatientsListMineResponse
  if (refresh) {
    myPatientsCache = myPatientsCache.filter((item) => item.optionsId !== optionsId)
    res = await transcribeRequest.patients.list.mine(otherOptions)
    myPatientsCache.push({ optionsId, response: res })
  } else {
    const cachedItem = myPatientsCache.find((item) => item.optionsId === optionsId)
    if (cachedItem) {
      res = cachedItem.response
    } else {
      res = await transcribeRequest.patients.list.mine(otherOptions)
      myPatientsCache.push({ optionsId, response: res })
    }
  }

  if (myPatientsCache.length > cacheLimit) {
    myPatientsCache.shift()
  }

  return res
}

export const refreshMyPatientsCache = () => {
  myPatientsCache = []
}

// patients.list.user

type UserPatientsCacheItem = {
  optionsId: string
  response: PatientsListUserResponse
}

let userPatientsCache: UserPatientsCacheItem[] = []

type ListUserPatientsCachedOptions = {
  refresh?: boolean
} & PatientsListUserOptions

export const listUserPatientsCached = async (
  options: ListUserPatientsCachedOptions
): Promise<PatientsListUserResponse> => {
  const { refresh, ...otherOptions } = options

  const optionsId = JSON.stringify(otherOptions)

  let res: PatientsListUserResponse
  if (refresh) {
    userPatientsCache = userPatientsCache.filter((item) => item.optionsId !== optionsId)
    res = await transcribeRequest.patients.list.user(otherOptions)
    userPatientsCache.push({ optionsId, response: res })
  } else {
    const cachedItem = userPatientsCache.find((item) => item.optionsId === optionsId)
    if (cachedItem) {
      res = cachedItem.response
    } else {
      res = await transcribeRequest.patients.list.user(otherOptions)
      userPatientsCache.push({ optionsId, response: res })
    }
  }

  if (userPatientsCache.length > cacheLimit) {
    userPatientsCache.shift()
  }

  return res
}

export const refreshUserPatientsCache = () => {
  userPatientsCache = []
}

// patients.list.all

type AllPatientsCacheItem = {
  optionsId: string
  response: PatientsListAllResponse
}

let allPatientsCache: AllPatientsCacheItem[] = []

type ListAllPatientsCachedOptions = {
  refresh?: boolean
} & PatientsListAllOptions

export const listAllPatientsCached = async (
  options: ListAllPatientsCachedOptions
): Promise<PatientsListAllResponse> => {
  const { refresh, ...otherOptions } = options

  const optionsId = JSON.stringify(otherOptions)

  let res: PatientsListAllResponse
  if (refresh) {
    allPatientsCache = allPatientsCache.filter((item) => item.optionsId !== optionsId)
    res = await transcribeRequest.patients.list.all(otherOptions)
    allPatientsCache.push({ optionsId, response: res })
  } else {
    const cachedItem = allPatientsCache.find((item) => item.optionsId === optionsId)
    if (cachedItem) {
      res = cachedItem.response
    } else {
      res = await transcribeRequest.patients.list.all(otherOptions)
      allPatientsCache.push({ optionsId, response: res })
    }
  }

  if (allPatientsCache.length > cacheLimit) {
    allPatientsCache.shift()
  }

  return res
}

export const refreshAllPatientsCache = () => {
  allPatientsCache = []
}

// Refresh all caches

export const refreshPatientsCache = () => {
  refreshMyPatientsCache()
  refreshUserPatientsCache()
  refreshAllPatientsCache()
}

export const refreshSessionsCache = () => {
  refreshMySessionsCache()
  refreshUserSessionsCache()
  refreshAllSessionsCache()
  refreshPatientSessionsCache()
}
