import { RLayout } from '@counsel-project/counsel-transcribe-api'
import CloseIcon from '@mui/icons-material/CloseRounded'
import Box from '@mui/material/Box'
import Chip from '@mui/material/Chip'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import { useMemo } from 'react'
import { markdownToHtml, removeMaliciousHTML } from '../../util/markdown'

type LayoutPreviewDialogProps = {
  open: boolean
  layout: RLayout
  children?: React.ReactNode
  onClose: () => void
}

const LayoutPreviewDialog = ({ open, layout, children, onClose }: LayoutPreviewDialogProps) => {
  const exampleHtml = useMemo(() => markdownToHtml(layout.example), [layout.example])

  const options = layout.config.options.map((c) => c.options).reduce((a, b) => a.concat(b), [])

  const shownOptions = options.slice(0, 10)
  const remainingOptionsCount = options.length - shownOptions.length

  return (
    <Dialog open={open} onClose={onClose}>
      <Box
        sx={{
          backgroundColor: layout.example ? 'background.default' : 'background.paper',
          px: 2,
          pt: 2,
          pb: 1,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography variant="h6" gutterBottom>
          Example
        </Typography>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Box>
      {layout.example && (
        <Box
          sx={{
            maxHeight: 600,
            overflowY: 'scroll',
            overflowX: 'hidden',
            backgroundColor: 'background.default',
            px: 2,
            pb: 2,
            fontSize: 12,
            // Add shadow fade to the bottom
            boxShadow: 'inset 0 -65px 50px -65px #5c5c5c',
            width: '100%',
          }}
        >
          <div
            style={{
              // Allow text to wrap
              whiteSpace: 'pre-wrap',
              // Allow text to break
              wordWrap: 'break-word',
            }}
            dangerouslySetInnerHTML={{
              __html: removeMaliciousHTML(exampleHtml),
            }}
          />
        </Box>
      )}
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h6" gutterBottom>
              Options
            </Typography>
          </Grid>
          {layout.config.options.length !== 0 && (
            <Grid item container spacing={1} xs={12}>
              {shownOptions.map((option) => (
                <Grid item key={option.label}>
                  <Chip label={option.label} />
                </Grid>
              ))}
              {remainingOptionsCount > 1 && (
                <Grid item>
                  <Chip label={`+${remainingOptionsCount}`} />
                </Grid>
              )}
            </Grid>
          )}
        </Grid>
      </DialogContent>
      <DialogActions sx={{ flexDirection: 'column' }}>{children}</DialogActions>
    </Dialog>
  )
}

export default LayoutPreviewDialog
