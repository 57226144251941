import { authRequest } from './api/auth-api'
import getUser from './auth/getUser'
import { markdownToHtml } from './markdown'

type DocumentClientEmail = {
  email?: never
  clientEmail: string
}

type DocumentEmail = {
  email: string
  clientEmail?: never
}

type DocumentEmailBaseOptions = {
  token: string
  patientLabel?: string
  documentType: string
  isValidated: boolean
  text: string
  startedAtString: string
  endedAtString?: string
}

type DocumentEmailOptions = DocumentEmailBaseOptions & DocumentEmail

type DocumentClientEmailOptions = DocumentEmailBaseOptions & DocumentClientEmail

const sendDocumentEmail = async (options: DocumentEmailOptions | DocumentClientEmailOptions) => {
  const { startedAtString, endedAtString, token, patientLabel, documentType, isValidated, text } =
    options

  const user = getUser()

  if (!user) {
    throw new Error('User not found')
  }

  const name = user?.firstName ? `${user.firstName} ${user.lastName}` : user?.email

  const firstLine = patientLabel
    ? `${documentType} for ${patientLabel} created on ${startedAtString}:\n\n`
    : `${documentType} created on ${startedAtString}:\n\n`

  const secondLine = `<br/><br/>This email was sent by Clinical Notes AI on behalf of ${name} (${user.email}).\n`

  let lastLine = isValidated
    ? `<br/><br/>Prior to this email being sent, the clinician verified that this ${documentType} has been reviewed by them directly.`
    : '<br/><br/>All contents of this email should be verified by the practitioner before being used for any purpose.'

  const separator = '<hr style="border: 1px solid #ddd; margin: 1rem 0;" />'

  if (startedAtString && endedAtString && !options.clientEmail) {
    lastLine += `<br/><br/>Session lasted from ${startedAtString} to ${endedAtString}.`
  }

  const subject =
    `${documentType} From ${name} ${startedAtString}` + (patientLabel ? ` for ${patientLabel}` : '')

  const innerContent =
    firstLine + separator + markdownToHtml(text) + separator + secondLine + lastLine

  const html = `
  <html>
    <head>
      <style>
        body {
          margin: 0;
          padding: 0;
        }

        table {
          width: 100%;
          border-collapse: collapse;
        }

        th,
        td {
          border: 1px solid #ddd;
          padding: 8px;
          text-align: left;
        }

        th {
          background-color: #f2f2f2;
        }
      </style>  
    </head>
    <body>
      ${innerContent}
    </body>
  </html>
`

  if (options.clientEmail) {
    await authRequest.user.email.sendAny({
      token,
      to: options.clientEmail,
      subject,
      html,
    })
  } else {
    await authRequest.user.email.send({
      token,
      to: options.email,
      subject,
      html,
    })
  }
}

export default sendDocumentEmail
