import { RMetric } from '@counsel-project/counsel-transcribe-api/dist/common/database/RMetric'
import { useCallback, useEffect, useState } from 'react'
import StyledTableCell from '../tables/StyledTableCell'
import StyledTableRow from '../tables/StyledTableRow'
import TableBase from '../tables/TableBase'
import { formatDuration, getRelativeTime } from '../../util'
import { transcribeRequest } from '../../util/api/transcribe-api'
import checkToken from '../../util/auth/checkToken'
import handleError from '../../util/handleError'
import { METRIC_NAME_MAPPINGS_SINGULAR } from './lib'

type ActivityLogRowProps = {
  directoryId?: string
  row: RMetric
}

const ActivityLogRow = ({ directoryId, row }: ActivityLogRowProps) => {
  const { email, action, value, createdAt } = row

  return (
    <StyledTableRow>
      {directoryId && <StyledTableCell>{email}</StyledTableCell>}
      <StyledTableCell align={directoryId ? 'right' : 'left'}>
        {value !== 1 ? formatDuration(value) + ' ' : ''}
        {METRIC_NAME_MAPPINGS_SINGULAR[action]}
      </StyledTableCell>
      <StyledTableCell align="right">
        {getRelativeTime((new Date().getTime() - new Date(createdAt).getTime()) / 1000)} ago
      </StyledTableCell>
    </StyledTableRow>
  )
}

type ActivityLogProps = {
  directoryId?: string
}

const ActivityLog = ({ directoryId }: ActivityLogProps) => {
  const [rows, setRows] = useState<RMetric[]>([])
  const [offset, setOffset] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [loading, setLoading] = useState(false)

  const populateMetrics = useCallback(async () => {
    try {
      setLoading(true)
      await checkToken()

      let results: RMetric[] = []
      if (directoryId) {
        const data = await transcribeRequest.metrics.directory.list({
          token: '',
          directoryId,
          offset,
          limit: rowsPerPage,
        })
        results = data.results
      } else {
        const data = await transcribeRequest.metrics.user.list({
          token: '',
          offset,
          limit: rowsPerPage,
        })
        results = data.results
      }
      setRows(results)
    } catch (err) {
      handleError(err)
    } finally {
      setLoading(false)
    }
  }, [directoryId, offset, rowsPerPage])

  useEffect(() => {
    const timeout = setTimeout(populateMetrics, 10)
    return () => clearTimeout(timeout)
  }, [populateMetrics])

  return (
    <TableBase
      columns={[
        ...(directoryId
          ? [
              {
                field: 'email' as keyof RMetric,
                label: 'Email',
                sortable: true,
                align: 'left' as const,
              },
            ]
          : []),
        {
          field: 'action',
          label: 'Action',
          sortable: true,
          align: directoryId ? 'right' : 'left',
        },
        {
          field: 'createdAt',
          label: 'Date',
          sortable: true,
          align: 'right',
        },
      ]}
      rows={rows}
      renderRow={(row) => <ActivityLogRow directoryId={directoryId} row={row} />}
      page={offset}
      rowsPerPage={rowsPerPage}
      total={rows.length}
      loaded={!loading}
      rowsPerPageOptions={[]}
    />
  )
}

export default ActivityLog
