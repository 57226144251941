import React, { useMemo } from 'react'
import Chip, { ChipProps } from '@mui/material/Chip'
import { PermissionType } from '@counsel-project/counsel-auth-api'
import PermissionIcon from '../icons/PermissionIcon'

export const getPermissionLabel = (permission: PermissionType, priority = 11): string => {
  if (permission === 'administrator' && priority > 11) {
    return 'Admin'
  }

  switch (permission) {
    case 'administrator':
      return 'Admin'
    case 'master-counselor':
      return 'Manager'
    case 'counselor':
      return 'Member'
    case 'supervisor':
      return 'Supervisor'
    default:
      return 'No Permission'
  }
}

export type PermissionChipProps = {
  permission: PermissionType
  priority?: number
} & ChipProps

const PermissionChip = ({ permission, priority = 11, ...props }: PermissionChipProps) => {
  const label = useMemo(() => getPermissionLabel(permission, priority), [permission, priority])

  const color = useMemo(() => {
    switch (permission) {
      case 'administrator':
        return 'error'
      case 'master-counselor':
        return 'warning'
      case 'counselor':
        return 'primary'
      case 'supervisor':
        return 'secondary'
      default:
        return 'default'
    }
  }, [permission])

  return (
    <Chip
      label={label}
      color={color}
      icon={<PermissionIcon permission={permission} priority={priority} />}
      {...props}
    />
  )
}

export default PermissionChip
