import React from 'react'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'

type MicrophonePermissionDialogProps = {
  open: boolean
  onClose?: () => void
}

const MicrophonePermissionDialog = ({ open, onClose }: MicrophonePermissionDialogProps) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Microphone Permission</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Please allow access to your microphone to use this feature.
          <br />
          <br />
          This feature will not work until microphone access is granted and the page is refreshed.
        </DialogContentText>
      </DialogContent>
    </Dialog>
  )
}

export default MicrophonePermissionDialog
