import { UserAlternateEmail } from '@counsel-project/counsel-auth-api/dist/common/database/User'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Checkbox from '@mui/material/Checkbox'
import DialogContent from '@mui/material/DialogContent'
import FormControlLabel from '@mui/material/FormControlLabel'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { toast } from 'react-hot-toast'
import checkToken from '../util/auth/checkToken'
import useUser from '../util/auth/useUser'
import sendDocumentEmail from '../util/email'
import handleError from '../util/handleError'
import ClosableDialog from './ClosableDialog'
import { removeMarkdown } from '../util/copy'

type VerifyInfoCorrectCheckboxProps = {
  onChange: (value: boolean) => void
}

const VerifyInfoCorrectCheckbox = ({ onChange }: VerifyInfoCorrectCheckboxProps) => {
  const [checked, setChecked] = useState(false)

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setChecked(event.target.checked)
      onChange(event.target.checked)
    },
    [onChange]
  )

  return (
    <FormControlLabel
      control={<Checkbox checked={checked} onChange={handleChange} />}
      label="I have verified that the information in this note is correct"
    />
  )
}

type EmailNoteDialogProps = {
  patientLabel?: string
  text: string
  open: boolean
  startedAtString: string
  endedAtString?: string
  onSetLoading?: (loading: boolean) => void
  onClose: () => void
}

const EmailNoteDialog = ({
  text,
  patientLabel,
  onSetLoading,
  open,
  startedAtString,
  endedAtString,
  onClose,
}: EmailNoteDialogProps) => {
  const [user] = useUser()

  const [loading, setLoading] = useState(false)
  const [isValid, setIsValid] = useState(false)

  const allPossibleEmails: UserAlternateEmail[] = useMemo(
    () => [
      {
        label: 'Primary Email',
        email: user?.email ?? '',
      },
      ...(user?.alternativeEmails ?? []),
    ],
    [user]
  )

  const createChooseEmailHandler = useCallback(
    (email: UserAlternateEmail) => async () => {
      try {
        setLoading(true)

        await checkToken()

        await sendDocumentEmail({
          token: '',
          email: email.email,
          text: removeMarkdown(text),
          documentType: 'Note',
          patientLabel: patientLabel ?? '',
          startedAtString,
          endedAtString,
          isValidated: isValid,
        })

        toast.success('Email sent successfully.')
      } catch (err) {
        handleError(err)
      } finally {
        setLoading(false)
        onClose()
      }
    },
    [onClose, text, patientLabel, isValid, startedAtString, endedAtString]
  )

  useEffect(() => {
    if (onSetLoading) {
      onSetLoading(loading)
    }
  }, [loading, onSetLoading])

  useEffect(() => {
    setIsValid(false)
  }, [open])

  return (
    <ClosableDialog titleText="Choose an Email" open={open} onClose={onClose}>
      <DialogContent>
        <Typography variant="body1" sx={{ mb: 2 }}>
          Please choose an email to send the note to.
        </Typography>
        <Box sx={{ mb: 2 }}>
          <VerifyInfoCorrectCheckbox onChange={setIsValid} />
        </Box>
        <Grid container spacing={2}>
          {allPossibleEmails.map((email) => (
            <Grid item xs={12} key={email.email}>
              <Button
                fullWidth
                onClick={createChooseEmailHandler(email)}
                color="primary"
                disabled={loading || !isValid}
              >
                <Typography component="span" variant="body1" fontWeight={600}>
                  {email.label}
                </Typography>
                <br />
                <Typography component="span" variant="caption" sx={{ ml: 1 }}>
                  {email.email}
                </Typography>
              </Button>
            </Grid>
          ))}
        </Grid>
      </DialogContent>
    </ClosableDialog>
  )
}

export default EmailNoteDialog
