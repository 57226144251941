import { RPatient, RPatientSession } from '@counsel-project/counsel-transcribe-api'
import { useCallback, useEffect, useState } from 'react'
import FileUpload from '../../components/forms/FileUpload'
import { transcribeRequest } from '../../util/api/transcribe-api'
import { visionRequest } from '../../util/api/vision-api'
import checkToken from '../../util/auth/checkToken'
import toast from 'react-hot-toast'
import { visionSessionsPromise } from '../../util/processPromise'
import { useMountedState } from 'react-use'

type UploadDocumentSectionProps = {
  patient: RPatient
  onAddSession: (sessionId: string) => void
}

const UploadDocumentSection = ({ patient, onAddSession }: UploadDocumentSectionProps) => {
  const [isLoading, setIsLoading] = useState(false)
  const [promise, setPromise] = useState<Promise<string> | null>(null)
  const isMounted = useMountedState()

  const handleConvertFile = useCallback(
    async (file: File) => {
      try {
        setIsLoading(true)

        await checkToken()

        const formData = new FormData()
        formData.append('file', file)

        const { processId } = await visionRequest.sessions.generate({
          token: '',
          formData,
          patientId: patient._id,
        })

        const processPromise = visionSessionsPromise({
          processId,
        })

        toast.promise(processPromise, {
          loading: 'Processing document...',
          success: 'Document successfully processed',
          error: 'Failed to process document',
        })

        setPromise(processPromise)
      } catch (err) {
        console.error(err)
      } finally {
        setIsLoading(false)
      }
    },
    [patient]
  )

  useEffect(() => {
    if (!promise) return
    promise.then((s) => {
      if (!isMounted()) return
      onAddSession(s)
    })
  }, [promise, onAddSession, isMounted])

  return (
    <FileUpload
      disabled={isLoading}
      file={null}
      title="Upload Document"
      onFileChange={handleConvertFile}
      accept=".docx,.pdf"
    />
  )
}

export default UploadDocumentSection
