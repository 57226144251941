import { textToHTML } from '../../util/textToHTML'
import Typography, { TypographyProps } from '@mui/material/Typography'
import { useEffect, useState } from 'react'
import { markdownToHtml } from '../../util/markdown'

export type TermsOfServiceProps = TypographyProps

const TermsOfService = (props: TermsOfServiceProps) => {
  const [text, setText] = useState('')
  const [isClient, setIsClient] = useState(false)

  // After the component mounts, set isClient to true
  useEffect(() => {
    setIsClient(true)
  }, [])

  useEffect(() => {
    window.location.href = 'https://www.clinicalnotes.ai/terms-of-service.html'
  }, [])

  return (
    <Typography variant="body1" {...props}>
      {isClient && <div dangerouslySetInnerHTML={{ __html: markdownToHtml(text) }} />}
    </Typography>
  )
}

export default TermsOfService
