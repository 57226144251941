import {
  LayoutOption,
  LayoutOptionSelection,
} from '@counsel-project/counsel-transcribe-api/dist/common/database/RLayout'
import Box from '@mui/material/Box'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import Grid, { GridProps } from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { useCallback, useEffect, useMemo, useState } from 'react'
import toast from 'react-hot-toast'
import SelectableTab from '../SelectableTab'
import usePreferencesStore, { getPreferencesStore } from '../../util/store/preferences'
import { OptionPreferences } from '../../util/store/slices/preferences'
import Button from '@mui/material/Button'

const blacklistedPreferenceOptionIds = ['refer-he-him', 'refer-she-her', 'refer-they-them']

const autoPopulateOptionPreferences = (
  options: LayoutOption[],
  preferences: OptionPreferences
): string[] => {
  return options.reduce((acc, category) => {
    const selectedOptions = preferences[category.categoryLabel] || []
    const newOptions = category.options.reduce((acc, option) => {
      if (selectedOptions.includes(option.id)) {
        return [...acc, option.id]
      }
      return acc
    }, [] as string[])
    return [...acc, ...newOptions]
  }, [] as string[])
}

type CheckboxOptionProps = {
  label: string
  checked: boolean
  onChange: (checked: boolean) => void
}

const CheckboxOption = ({ label, checked, onChange }: CheckboxOptionProps) => {
  return (
    <FormControlLabel
      control={<Checkbox checked={checked} onChange={(e) => onChange(e.target.checked)} />}
      label={label}
    />
  )
}

type CategoryOptionsProps = {
  isPreselected?: boolean
  value: string[]
  category: LayoutOption
  onClick: (id: string) => void
} & Omit<GridProps, 'onClick'>

const CategoryOptions = ({
  isPreselected,
  value,
  category,
  onClick,
  ...props
}: CategoryOptionsProps) => {
  const [showOptions, setShowOptions] = useState(!isPreselected)

  const filteredOptions = useMemo(
    () =>
      showOptions || value.filter((v) => category.options.some((o) => o.id === v)).length === 0
        ? category.options
        : category.options.filter((o) => value.includes(o.id)),
    [category.options, showOptions, value]
  )

  return (
    <Grid container spacing={2} paddingTop={2} {...props}>
      {category.type === 'button'
        ? filteredOptions.map((option) => (
            <Grid item key={option.id}>
              <SelectableTab
                small
                active={value.includes(option.id) || false}
                onClick={() => onClick(option.id)}
                aria-checked={value.includes(option.id)}
              >
                {option.label}
              </SelectableTab>
            </Grid>
          ))
        : filteredOptions.map((option) => (
            <Grid item key={option.id}>
              <CheckboxOption
                label={option.label}
                checked={value.includes(option.id) || false}
                onChange={() => onClick(option.id)}
              />
            </Grid>
          ))}
      {!showOptions &&
        value.filter((v) => category.options.some((o) => o.id === v)).length !== 0 && (
          <Grid item>
            <Button onClick={() => setShowOptions(true)} variant="outlined">
              Show More
            </Button>
          </Grid>
        )}
    </Grid>
  )
}

type LayoutOptionsSelectorProps = {
  options: LayoutOption[]
  value: string[]
  onChange: (value: string[]) => void
}

const LayoutOptionsSelector = ({ options, value, onChange }: LayoutOptionsSelectorProps) => {
  const setOptions = usePreferencesStore((state) => state.setOptions)
  const preferences = useMemo(() => getPreferencesStore().getAllOptions(), [])
  const autoPopulatedOptions = useMemo(
    () => autoPopulateOptionPreferences(options, preferences),
    [options, preferences]
  )

  const isPreselected = useCallback(
    (category: LayoutOption) => {
      return (
        preferences[category.categoryLabel] &&
        preferences[category.categoryLabel].length > 0 &&
        category.options.some((o) => preferences[category.categoryLabel].includes(o.id)) &&
        category.options.length > 1
      )
    },
    [preferences]
  )

  const toggleOptionHandler = (category: LayoutOption) => (id: string) => {
    let changeValue: string[] = []

    if (value.includes(id)) {
      // If the category is required and there is only one option selected, don't allow it to be removed
      if (
        category.required &&
        value.filter((s) => category.options.some((o) => o.id === s)).length === 1
      ) {
        return
      }
      changeValue = value.filter((a) => a !== id)
    } else {
      if (!category.allowMultiple && value.some((s) => category.options.some((o) => o.id === s))) {
        // If the category does not allow multiple options and there is already an option selected, remove it
        changeValue = [...value.filter((s) => !category.options.some((o) => o.id === s)), id]
      } else {
        // Otherwise, add the new selection
        changeValue = [...value, id]
      }
    }

    if (!blacklistedPreferenceOptionIds.includes(id)) {
      setOptions(
        category.categoryLabel,
        changeValue.filter((o) => category.options.some((oo) => oo.id === o))
      )
    }
    onChange(changeValue)
  }

  // Find required categories
  // Auto-select first option for each required category if not already selected
  useEffect(() => {
    const requiredCategories = options.filter((o) => o.required)
    const missingCategories = requiredCategories.filter(
      (c) => !autoPopulatedOptions.some((o) => c.options.some((oo) => oo.id === o))
    )

    if (missingCategories.length > 0) {
      const newOptions = missingCategories.reduce(
        (acc, category) => [
          ...acc,
          category.options[0].id,
          ...autoPopulatedOptions.filter((o) => category.options.some((oo) => oo.id === o)),
        ],
        [] as string[]
      )
      onChange([...newOptions, ...autoPopulatedOptions])
    } else {
      onChange(autoPopulatedOptions)
    }
  }, [autoPopulatedOptions, options, onChange])

  if (options.length === 0) return null

  return (
    <Box id="layout-option-container">
      {options.map((category, i) => (
        <Box id={`layout-option-category-${i}`} sx={{ mt: 2 }} key={category.categoryLabel}>
          <Grid container spacing={1}>
            <Grid item>
              <Typography fontWeight={500} fontSize={18} variant="body1">
                {category.categoryLabel}
              </Typography>
            </Grid>
            {category.required && (
              <Grid item>
                <Typography fontWeight={500} fontSize={18} variant="body1" color="error">
                  *
                </Typography>
              </Grid>
            )}
          </Grid>
          <CategoryOptions
            isPreselected={isPreselected(category)}
            id={`layout-options-category-${i}`}
            value={value}
            category={category}
            onClick={toggleOptionHandler(category)}
          />
        </Box>
      ))}
    </Box>
  )
}

export default LayoutOptionsSelector
