import Box from '@mui/material/Box'
import { alpha, styled } from '@mui/material/styles'
import { useEffect, useState } from 'react'

type ContextBoxProps = {
  active: boolean
}

const ContextBox = styled(Box, { shouldForwardProp: (prop) => prop !== 'active' })<ContextBoxProps>(
  ({ theme, active }) => ({
    position: 'relative',
    padding: theme.spacing(2),
    transition: 'background-color 0.3s',
    backgroundColor: active
      ? alpha(theme.palette.primary.dark, 0.3)
      : theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
    border: `2px dashed ${theme.palette.primary.main}`,
  })
)

type FullwidthInvisibleInputProps = {
  active: boolean
}

const FullwidthInvisibleInput = styled('input', {
  shouldForwardProp: (prop) => prop !== 'active',
})<FullwidthInvisibleInputProps>(({ theme, active }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  opacity: 0,
  backgroundColor: 'red',
  cursor: 'default',
  pointerEvents: active ? 'all' : 'none',
}))

export type ContextOptionsProps = {
  children?: React.ReactNode
  disableDrag?: boolean
  onFileChange: (file: File) => void
}

const ContextBoxContainer = ({ children, disableDrag, onFileChange }: ContextOptionsProps) => {
  const [dragging, setDragging] = useState(false)

  useEffect(() => {
    const handleDragEnter = () => {
      if (disableDrag) return
      setDragging(true)
    }

    const handleDragLeave = () => {
      setDragging(false)
    }
    document.addEventListener('dragenter', handleDragEnter)
    document.addEventListener('dragend', handleDragLeave)
    document.addEventListener('mouseout', handleDragLeave)

    return () => {
      document.removeEventListener('dragenter', handleDragEnter)
      document.removeEventListener('dragend', handleDragLeave)
      document.removeEventListener('mouseout', handleDragLeave)
    }
  }, [disableDrag])

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) return
    onFileChange(e.target.files[0])
  }

  return (
    <ContextBox active={dragging}>
      {children}
      <FullwidthInvisibleInput
        onChange={handleFileChange}
        type="file"
        active={dragging}
        accept="application/pdf, application/msword, text/plain, .doc, .docx, .pdf"
      />
    </ContextBox>
  )
}

export default ContextBoxContainer
