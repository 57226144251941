import log from './logging'

export function onBeforeUnload() {
  return undefined
}

export function onBeforeUnloadBlocked() {
  // Log browser tab close
  log.info('Attempted to block the browser tab close')
  return true
}
