import { capitalize } from '@counsel-project/client-utils'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Checkbox from '@mui/material/Checkbox'
import DialogContent from '@mui/material/DialogContent'
import FormControlLabel from '@mui/material/FormControlLabel'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import React, { useCallback, useEffect, useState } from 'react'
import { toast } from 'react-hot-toast'
import { usePatientNomenclature } from '../util'
import checkToken from '../util/auth/checkToken'
import sendDocumentEmail from '../util/email'
import handleError from '../util/handleError'
import ClosableDialog from './ClosableDialog'

type VerifyInfoCorrectCheckboxProps = {
  onChange: (value: boolean) => void
}

const VerifyInfoCorrectCheckbox = ({ onChange }: VerifyInfoCorrectCheckboxProps) => {
  const [checked, setChecked] = useState(false)

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setChecked(event.target.checked)
      onChange(event.target.checked)
    },
    [onChange]
  )

  return (
    <FormControlLabel
      control={<Checkbox checked={checked} onChange={handleChange} />}
      label="I have verified that the information in this note is correct"
    />
  )
}

type EmailClientDialogProps = {
  patientLabel?: string
  text: string
  open: boolean
  startedAtString: string
  endedAtString: string
  onSetLoading?: (loading: boolean) => void
  onClose: () => void
}

const EmailClientDialog = ({
  text,
  patientLabel,
  onSetLoading,
  open,
  startedAtString,
  endedAtString,
  onClose,
}: EmailClientDialogProps) => {
  const [loading, setLoading] = useState(false)
  const [isValid, setIsValid] = useState(false)
  const [clientEmail, setClientEmail] = useState<string>('')

  const handleEmailInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setClientEmail(event.target.value)
  }

  const clientEmailHandler = useCallback(
    (clientEmail: string) => async () => {
      try {
        const lcaseEmail = clientEmail.toLowerCase()
        const reg =
          /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

        if (!reg.test(lcaseEmail)) {
          toast.error('Oops, it looks like you did not provide a proper email address')
          return
        }

        setLoading(true)

        await checkToken()

        await sendDocumentEmail({
          token: '',
          clientEmail,
          text,
          documentType: 'Note',
          patientLabel,
          startedAtString,
          endedAtString,
          isValidated: isValid,
        })

        toast.success('Email sent successfully.')
        onClose()
      } catch (err) {
        handleError(err)
      } finally {
        setLoading(false)
      }
    },
    [onClose, text, patientLabel, isValid, startedAtString, endedAtString]
  )

  useEffect(() => {
    if (onSetLoading) {
      onSetLoading(loading)
    }
  }, [loading, onSetLoading])

  useEffect(() => {
    setIsValid(false)
  }, [open])

  const patientNomenclature = usePatientNomenclature()

  return (
    <ClosableDialog titleText="Choose an Email" open={open} onClose={onClose}>
      <DialogContent>
        <Typography variant="body1" sx={{ mb: 2 }}>
          Please enter your {patientNomenclature}'s email
        </Typography>
        <Box sx={{ mb: 2 }}>
          <VerifyInfoCorrectCheckbox onChange={setIsValid} />
        </Box>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              label={`${capitalize(patientNomenclature)} Email Here...`}
              variant="outlined"
              value={clientEmail}
              onChange={handleEmailInputChange}
              fullWidth
            />
            <Button
              onClick={clientEmailHandler(clientEmail)}
              sx={{ mt: 2 }}
              disabled={!isValid || loading}
            >
              Send Email
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </ClosableDialog>
  )
}

export default EmailClientDialog
