import { useEffect, useState } from 'react'
import usePermissionState from './usePermissionState'
import log from '../logging'

const useAvailableAudioDevices = () => {
  const [devices, setDevices] = useState<MediaDeviceInfo[]>([])
  const { permissionState, requestPermission } = usePermissionState()

  useEffect(() => {
    if (permissionState !== 'granted') {
      return
    }

    const onDeviceChange = () => {
      navigator.mediaDevices
        .enumerateDevices()
        .then((devices) => {
          const audioDevices = devices.filter((device) => device.kind === 'audioinput')
          setDevices(audioDevices)
        })
        .catch(log.warn)
    }

    onDeviceChange()

    navigator.mediaDevices.addEventListener('devicechange', onDeviceChange)

    return () => {
      navigator.mediaDevices.removeEventListener('devicechange', onDeviceChange)
    }
  }, [permissionState])

  return { devices, permissionState, requestPermission }
}

export default useAvailableAudioDevices
