import { RUser } from '@counsel-project/counsel-auth-api/dist/common/database/User'
import Cookies from 'js-cookie'
import { useMemo } from 'react'

export const getRelativeTime = (seconds: number): string => {
  const minutes = Math.floor(seconds / 60)
  const hours = Math.floor(minutes / 60)
  const days = Math.floor(hours / 24)
  const months = Math.floor(days / 30)
  const years = Math.floor(months / 12)

  if (seconds <= 0) {
    return 'now'
  } else if (seconds < 60) {
    return `${Math.floor(seconds)} second${seconds === 1 ? '' : 's'}`
  } else if (minutes < 60) {
    return `${minutes} minute${minutes === 1 ? '' : 's'}`
  } else if (hours < 24) {
    return `${hours} hour${hours === 1 ? '' : 's'}`
  } else if (days < 30) {
    return `${days} day${days === 1 ? '' : 's'}`
  } else if (months < 12) {
    return `${months} month${months === 1 ? '' : 's'}`
  } else if (years < 4) {
    return `${years} year${years === 1 ? '' : 's'}`
  } else {
    return 'never'
  }
}

export const formatDuration = (seconds: number): string => {
  if (seconds < 60) {
    return `${seconds}s`
  } else if (seconds < 3600) {
    return `${Math.round(seconds / 60)}m`
  } else if (seconds < 86400) {
    return `${Math.round(seconds / 3600)}hr`
  } else {
    return `${Math.round(seconds / 86400)}d`
  }
}

export const extractUserFromCookies = (): null | RUser => {
  try {
    const appState = Cookies.get('_state_app-storage')

    if (!appState) {
      return null
    }

    const state = JSON.parse(appState)?.state

    if (!state?.user) {
      return null
    }

    return state.user
  } catch (err) {
    return null
  }
}

const clientProfessions = [
  'therapist',
  'psychologist',
  'school counselor',
  'social worker',
  'substance abuse counselor',
  'peer recovery coach',
]

export const usePatientNomenclature = () => {
  const name = useMemo(() => {
    const user = extractUserFromCookies()
    if (!user) return 'patient'
    return !clientProfessions.includes(user.profession ?? '') ? 'patient' : 'client'
  }, [])

  return name
}
