import { RPatientSession } from '@counsel-project/counsel-transcribe-api'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import SessionItem from './SessionItem'
import { transcribeRequest } from '../../util/api/transcribe-api'
import checkToken from '../../util/auth/checkToken'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { createSearchParams } from '../builder/common'

type OriginalSessionSectionProps = {
  session?: RPatientSession
  setCanRegenerate?: (canRegenerate: boolean) => void
}

const OriginalSessionSection = ({ session, setCanRegenerate }: OriginalSessionSectionProps) => {
  const navigate = useNavigate()
  const location = useLocation()

  const [originalSession, setOriginalSession] = useState<RPatientSession | null>(null)

  const [searchParams] = useSearchParams()

  // If webView query parameter is present, set webView
  const webView = searchParams.get('webView') === 'true'
  const userId = searchParams.get('userId')
  const showAll = searchParams.get('showAll') === 'true'

  const populateOriginalSession = useCallback(async () => {
    try {
      if (!session || !session.createdFrom) {
        setOriginalSession(null)
        setCanRegenerate?.(false)
        return
      }

      await checkToken()

      const { result } = await transcribeRequest.sessions.get({
        token: '',
        sessionId: session.createdFrom,
      })

      setOriginalSession(result)
      setCanRegenerate?.(true)
    } catch (err) {
      console.error(err)
    }
  }, [session, setCanRegenerate])

  useEffect(() => {
    const timeout = setTimeout(() => {
      populateOriginalSession()
    }, 10)
    return () => clearTimeout(timeout)
  }, [populateOriginalSession])

  const handleNavSession = (e: React.MouseEvent) => {
    e.preventDefault()

    if (!originalSession) return

    const queryText = createSearchParams({
      ref: location.pathname + location.search,
      userId: userId || undefined,
      showAll,
      webView,
    })

    navigate(`/sessions/${originalSession._id}${queryText}`)
  }

  if (!originalSession) return null

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography variant="h6">Created From</Typography>
      </Grid>
      <Grid item xs={12}>
        <SessionItem
          session={originalSession}
          onClick={handleNavSession}
          href={`/sessions/${originalSession?._id}`}
        />
      </Grid>
    </Grid>
  )
}

export default OriginalSessionSection
