import { RDirectory } from '@counsel-project/counsel-auth-api'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import React from 'react'

interface OrganizationMetricCardProps {
  directory: RDirectory
  onViewMetrics: (dir: RDirectory) => void
}

const OrganizationMetricCard: React.FC<OrganizationMetricCardProps> = ({
  directory,
  onViewMetrics,
}) => {
  return (
    <Paper
      elevation={0}
      sx={{
        p: 2,
        height: '100%',
      }}
    >
      <Grid
        container
        alignItems="space-between"
        justifyContent="space-between"
        direction="column"
        height="100%"
      >
        <Grid xs>
          <Typography variant="h6" textAlign="center" sx={{ mb: 2 }}>
            {directory.name}
          </Typography>
        </Grid>
        <Grid item>
          <Button
            onClick={() => onViewMetrics(directory)}
            fullWidth
            variant="outlined"
            color="primary"
          >
            View Metrics
          </Button>
        </Grid>
      </Grid>
    </Paper>
  )
}

export default OrganizationMetricCard
