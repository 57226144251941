import log from './logging'
import getSupportedMimeType from './recording/getSupportedMimetype'
import toast from 'react-hot-toast'

let mediaRecorder: MediaRecorder | null = null
let audioContext: AudioContext | null = null
let audioChunks: Blob[] = []
let audioStream: MediaStream | null = null
let speakerStream: MediaStream | null = null
let mimeType: string | null = null
let device: MediaDeviceInfo | null = null
let audioDetectionInterval: NodeJS.Timeout | null = null
let devices: MediaDeviceInfo[] = []

const audioContextSupported = 'AudioContext' in window

const isMediaRecorderSupported = (): boolean => {
  return 'MediaRecorder' in window
}

export const getIsRecording = (): boolean => {
  return mediaRecorder?.state === 'recording' || mediaRecorder?.state === 'paused'
}

export const getIsPaused = (): boolean => {
  return mediaRecorder?.state === 'paused'
}

type RecordingOptions = {
  deviceId?: string
  onDataAvailableInterval?: number
  onDataAvailable?: (blob: Blob) => void
  onAudioDetected?: () => void
  browserAudio?: boolean
}

type RecordingResult = {
  mimeType: string
  device: MediaDeviceInfo | null
  mediaRecorder: MediaRecorder | null
}

export const startRecording = async ({
  deviceId,
  onDataAvailable,
  onDataAvailableInterval,
  onAudioDetected,
  browserAudio,
}: RecordingOptions): Promise<RecordingResult> => {
  if (!isMediaRecorderSupported()) {
    throw new Error('MediaRecorder is not supported')
  }

  if (audioChunks.length !== 0) {
    log.error('Recording started while audioChunks is not empty')
  }

  if (mediaRecorder) {
    log.error('Recording started while mediaRecorder is not null')
    const stopPromise: Promise<void> = new Promise((resolve) => {
      if (
        mediaRecorder &&
        (mediaRecorder.state === 'recording' || mediaRecorder.state === 'paused')
      ) {
        mediaRecorder.onstop = () => {
          resolve()
        }
        mediaRecorder.onerror = () => {
          resolve()
        }
        mediaRecorder.stop()
      } else {
        resolve()
      }
    })
    await stopPromise
  }

  if (audioStream) {
    log.error('Recording started while audioStream is not null')
    audioStream.getTracks().forEach((track) => {
      track.stop()
    })
    audioStream = null
  }

  mediaRecorder = null

  audioChunks = []

  audioStream = await navigator.mediaDevices.getUserMedia({ audio: deviceId ? { deviceId } : true })

  mimeType = getSupportedMimeType()

  if (audioContextSupported) {
    if (browserAudio) {
      try {
        speakerStream = await navigator.mediaDevices.getDisplayMedia({
          audio: true,
          video: {
            frameRate: 1,
            width: 320,
          },
        })
      } catch (err) {
        log.error(err)
        toast.error('Failed to get browser audio')
      }
    }

    try {
      // Create audio analyzer to detect audio
      audioContext = new AudioContext()
      const source = audioContext.createMediaStreamSource(audioStream)
      if (speakerStream) {
        const speakerSource = audioContext.createMediaStreamSource(speakerStream)

        // Set destination
        const destination = audioContext.createMediaStreamDestination()
        source.connect(destination)
        if (speakerSource) {
          speakerSource.connect(destination)
        }

        mediaRecorder = new MediaRecorder(destination.stream, { mimeType })
      } else {
        mediaRecorder = new MediaRecorder(audioStream, { mimeType })
      }

      const analyser = audioContext.createAnalyser()
      source.connect(analyser)

      const dataArray = new Uint8Array(analyser.frequencyBinCount)

      const detectAudio = () => {
        analyser.getByteFrequencyData(dataArray)
        const average = dataArray.reduce((acc, value) => acc + value, 0) / dataArray.length
        if (average > 0) {
          onAudioDetected?.()
          if (audioDetectionInterval) {
            clearInterval(audioDetectionInterval)
            analyser.disconnect()
          }
        }
      }

      audioDetectionInterval = setInterval(detectAudio, 1000)
    } catch (err) {
      log.error('Failed to capture desktop audio', err)
      toast.error('Failed to capture desktop audio')
      onAudioDetected?.()
    }
  } else {
    log.error('AudioContext is not supported')
    onAudioDetected?.()

    mediaRecorder = new MediaRecorder(audioStream, { mimeType })
  }

  if (!mediaRecorder) {
    log.error('MediaRecorder is not initialized - startRecording')
    mediaRecorder = new MediaRecorder(audioStream, { mimeType })
  }

  mediaRecorder.ondataavailable = (e) => {
    audioChunks.push(e.data)
    if (audioChunks.length % (onDataAvailableInterval || 1) === 0) {
      if (!mimeType) {
        log.error('MIME type is not set')
        return
      }
      onDataAvailable?.(e.data)
    }
  }

  mediaRecorder.onstop = () => {
    log.error('recorder stopped unexpectedly')
    audioStream?.getTracks().forEach((track) => {
      track.stop()
    })
    audioStream = null
    if (speakerStream) {
      speakerStream.getTracks().forEach((track) => {
        track.stop()
      })
      speakerStream = null
    }

    if (audioContext) {
      audioContext
        .close()
        .then(() => {
          audioContext = null
        })
        .catch((err) => {
          log.error('Failed to close audio context', err)
        })
    }
  }

  mediaRecorder.onerror = (e) => {
    log.error('recorder error:', e)
  }

  mediaRecorder.start(1000)

  mimeType = mediaRecorder.mimeType

  const activeDeviceId = audioStream.getAudioTracks()[0].getSettings().deviceId
  devices = (await navigator.mediaDevices.enumerateDevices()).filter(
    (device) => device.kind === 'audioinput'
  )
  device = devices.find((device) => device.deviceId === activeDeviceId) || null

  const allInfo = `recorder start: Using device ${device?.label}
  Using mime type ${mimeType}
  Using browser ${navigator.userAgent}
  Using platform ${navigator.platform}`
  log.info(allInfo)

  return { mimeType, device, mediaRecorder }
}

export const stopRecording = async (): Promise<Blob> => {
  if (audioDetectionInterval) {
    clearInterval(audioDetectionInterval)
  }

  const audioBlob: Promise<Blob> = new Promise((resolve, reject) => {
    if (!mediaRecorder) {
      log.error('MediaRecorder is not initialized - stopRecording')
      if (!mimeType) {
        return reject(new Error('MIME type is not set'))
      }
      const audioBlob = new Blob(audioChunks, { type: mimeType })
      log.info(`recorder stop, ${mimeType}, ${device?.label}, ${audioBlob.size / 1024 / 1024} MB`)
      return resolve(audioBlob)
    }

    if (mediaRecorder.state === 'inactive') {
      if (!mimeType) {
        return reject(new Error('MIME type is not set'))
      }
      const audioBlob = new Blob(audioChunks, { type: mimeType })
      log.info(`recorder stop, ${mimeType}, ${device?.label}, ${audioBlob.size / 1024 / 1024} MB`)
      return resolve(audioBlob)
    }

    mediaRecorder.onstop = () => {
      if (!mimeType) {
        return reject(new Error('MIME type is not set'))
      }
      const audioBlob = new Blob(audioChunks, { type: mimeType })
      log.info(`recorder stop, ${mimeType}, ${device?.label}, ${audioBlob.size / 1024 / 1024} MB`)
      resolve(audioBlob)
    }

    mediaRecorder.stop()
  })

  audioStream?.getTracks().forEach((track) => {
    track.stop()
  })

  if (speakerStream) {
    speakerStream.getTracks().forEach((track) => {
      track.stop()
    })
  }

  return audioBlob
}

export const pauseRecording = (): void => {
  if (!mediaRecorder) {
    log.error('MediaRecorder is not initialized - pauseRecording')
    throw new Error('MediaRecorder is not initialized')
  }

  log.info('recorder pause')

  mediaRecorder.pause()
}

export const resumeRecording = (): void => {
  if (!mediaRecorder) {
    log.error('MediaRecorder is not initialized - resumeRecording')
    throw new Error('MediaRecorder is not initialized')
  }

  log.info('recorder resume')

  mediaRecorder.resume()
}

export const clearRecording = async (): Promise<void> => {
  if (audioDetectionInterval) {
    clearInterval(audioDetectionInterval)
  }

  const stopPromise: Promise<void> = new Promise((resolve) => {
    if (
      mediaRecorder &&
      (mediaRecorder.state === 'recording' || mediaRecorder.state === 'paused')
    ) {
      mediaRecorder.onstop = () => {
        resolve()
      }
      mediaRecorder.onerror = () => {
        resolve()
      }
      mediaRecorder.stop()
    } else {
      resolve()
    }
  })
  await stopPromise

  if (speakerStream) {
    speakerStream.getTracks().forEach((track) => {
      track.stop()
    })
  }
  if (audioStream) {
    audioStream.getTracks().forEach((track) => {
      track.stop()
    })
  }
  speakerStream = null
  audioStream = null
  audioChunks = []
  mediaRecorder = null
}

export const getDevices = (): MediaDeviceInfo[] => {
  return devices
}
