import { UserAlternateEmail } from '@counsel-project/counsel-auth-api/dist/common/database/User'
import DeleteIcon from '@mui/icons-material/DeleteRounded'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import DialogContent from '@mui/material/DialogContent'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import { useCallback, useState } from 'react'
import { toast } from 'react-hot-toast'
import { authRequest } from '../util/api/auth-api'
import checkToken from '../util/auth/checkToken'
import handleError from '../util/handleError'
import ClosableDialog from './ClosableDialog'
import ConfirmDialog from './ConfirmDialog'

type AlternativeEmailListProps = {
  nothingFoundText?: string
  emails: UserAlternateEmail[]
  onRemove: (email: UserAlternateEmail) => void
  onAdd: (email: UserAlternateEmail) => void
}

const AlternativeEmailList = ({
  nothingFoundText = 'No alternative emails found.',
  emails,
  onRemove,
  onAdd,
}: AlternativeEmailListProps) => {
  const [stagedEmail, setStagedEmail] = useState('')
  const [loading, setLoading] = useState(false)
  const [userId, setUserId] = useState('')
  const [verificationCode, setVerificationCode] = useState('')
  const [stagedLabel, setStagedLabel] = useState('')
  const [addEmailOpen, setAddEmailOpen] = useState(false)
  const [stagedDelete, setStagedDelete] = useState<null | UserAlternateEmail>(null)

  const handleRemoveStaged = useCallback(async () => {
    try {
      if (!stagedDelete) return

      setLoading(true)

      await checkToken()

      await authRequest.user.account.removeAlternativeEmail({
        token: '',
        email: stagedDelete.email,
      })

      onRemove(stagedDelete)
      setStagedDelete(null)
    } catch (err) {
      handleError(err)
    } finally {
      setLoading(false)
    }
  }, [stagedDelete, onRemove])

  const createStagedDeleteHandler = (email: UserAlternateEmail) => () => setStagedDelete(email)

  const handleAddEmail = useCallback(async () => {
    try {
      if (!stagedEmail) return

      setLoading(true)

      await checkToken()

      const data = await authRequest.user.account.registerAlternativeEmail({
        token: '',
        email: stagedEmail,
      })

      setUserId(data.userId)
      setAddEmailOpen(false)
    } catch (err) {
      handleError(err)
    } finally {
      setLoading(false)
    }
  }, [stagedEmail])

  const handleVerifyEmail = useCallback(async () => {
    try {
      if (!verificationCode) return
      if (!userId) return

      setLoading(true)

      await checkToken()

      await authRequest.user.account.verifyAlternativeEmail({
        token: '',
        userId,
        code: verificationCode,
        label: stagedLabel,
      })

      toast.success('Successfully verified your email.')
      setUserId('')

      onAdd({
        label: stagedLabel,
        email: stagedEmail,
      })

      setStagedEmail('')
      setStagedLabel('')
      setVerificationCode('')
    } catch (err) {
      handleError(err)
    } finally {
      setLoading(false)
    }
  }, [stagedLabel, verificationCode, userId, onAdd, stagedEmail])

  return (
    <Box>
      <Box sx={{ mt: 2, p: 0, borderRadius: 1 }}>
        {emails.length === 0 ? (
          <Typography variant="body2" color="text.secondary">
            {nothingFoundText}
          </Typography>
        ) : (
          <Grid container spacing={2}>
            {emails.map((email) => (
              <Grid item key={email.email} xs={12}>
                <Box
                  sx={{
                    backgroundColor: 'background.default',
                    p: 1,
                    px: 2,
                    borderRadius: 1,
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <Typography>
                    {email.email} ({email.label})
                  </Typography>
                  <IconButton onClick={createStagedDeleteHandler(email)}>
                    <DeleteIcon />
                  </IconButton>
                </Box>
              </Grid>
            ))}
          </Grid>
        )}
      </Box>
      <Button onClick={() => setAddEmailOpen(true)} sx={{ mt: 2 }}>
        Add Email
      </Button>
      <ClosableDialog open={!!userId} onClose={() => setUserId('')} titleText="Verify Your Email">
        <DialogContent>
          <Typography variant="body1" color="text.secondary">
            We sent a message to your inbox. Type the code we provided here to verify your email.
          </Typography>
          <Grid container justifyContent="start" alignItems="start" direction="column">
            <Grid item>
              <TextField
                sx={{ mt: 2 }}
                placeholder="Verification Code..."
                type="text"
                autoComplete="none"
                aria-label="verification-code"
                value={verificationCode}
                onChange={(e) => setVerificationCode(e.target.value)}
                disabled={loading}
              />
            </Grid>
            <Grid item>
              <Button
                disabled={!verificationCode || loading}
                onClick={handleVerifyEmail}
                sx={{ mt: 2 }}
              >
                Verify My Email
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </ClosableDialog>
      <ClosableDialog
        open={addEmailOpen}
        onClose={() => setAddEmailOpen(false)}
        titleText="Add Email"
      >
        <DialogContent>
          <Typography variant="body2" color="text.secondary">
            Email
          </Typography>
          <TextField
            sx={{ mt: 1 }}
            fullWidth
            placeholder="Type an email..."
            type="text"
            autoComplete="none"
            aria-label="add-email"
            value={stagedEmail}
            onChange={(e) => setStagedEmail(e.target.value)}
            disabled={loading}
          />
          <Typography variant="body2" color="text.secondary" sx={{ mt: 2 }}>
            Label
          </Typography>
          <TextField
            sx={{ mt: 1 }}
            fullWidth
            placeholder="Type a label..."
            type="text"
            autoComplete="none"
            aria-label="add-label"
            value={stagedLabel}
            onChange={(e) => setStagedLabel(e.target.value)}
            disabled={loading}
          />
          <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
            Eg. Work, Personal etc...
          </Typography>
          <Button
            disabled={!stagedEmail || loading || !stagedLabel}
            onClick={handleAddEmail}
            sx={{ mt: 2 }}
          >
            Add Email
          </Button>
        </DialogContent>
      </ClosableDialog>
      <ConfirmDialog
        titleText="Remove this Email?"
        text="Are you sure you want to remove this email?"
        buttonText="Confirm Delete"
        open={!!stagedDelete}
        onClose={() => setStagedDelete(null)}
        onConfirm={handleRemoveStaged}
        loading={loading}
      />
    </Box>
  )
}

export default AlternativeEmailList
