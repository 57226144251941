import { isApiError } from '@counsel-project/client-utils'
import { ButtonProps } from '@mui/material/Button'
import Cookies from 'js-cookie'
import React, { useCallback, useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { authRequest } from '../../util/api/auth-api'
import detectInAppBrowser from '../../util/detectInAppBrowser'
import log from '../../util/logging'
import AppleButton from './AppleButton'

type AppleLoginButtonProps = Omit<ButtonProps, 'onError'> & {
  redirectPage: string
  onError?: (err: string) => void
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void
  onBrowserError: () => void
}

const AppleLoginButton = ({
  redirectPage,
  onError,
  onClick,
  onBrowserError,
  ...props
}: AppleLoginButtonProps) => {
  const [authUrl, setAuthUrl] = useState('')
  const [searchParams] = useSearchParams()

  const autoLogin = searchParams.get('autoLogin') === 'apple'

  const populateAuthUrl = useCallback(async () => {
    try {
      const state = Math.random().toString(36).substring(7)
      Cookies.set('appleState', state, { secure: process.env.NODE_ENV !== 'development' })
      if (redirectPage) {
        Cookies.set('redirectPage', redirectPage, {
          secure: process.env.NODE_ENV !== 'development',
        })
      }

      const { url } = await authRequest.user.apple.getAuthUrl({
        redirectUri: window.location.origin + '/api/apple-login',
        responseMode: 'form_post',
        state,
        scope: 'name email',
      })

      setAuthUrl(url)

      if (autoLogin) {
        window.location.href = url
      }
    } catch (err) {
      log.error(err)
      if (isApiError(err)) {
        onError?.(err.msg || err.message)
      }
    }
  }, [onError, autoLogin, redirectPage])

  useEffect(() => {
    populateAuthUrl()
  }, [populateAuthUrl])

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (detectInAppBrowser() !== 'other') {
      e.preventDefault()
      onBrowserError()
      return
    }

    onClick?.(e)
  }

  return (
    <AppleButton href={authUrl} disabled={!authUrl || autoLogin} onClick={handleClick} {...props} />
  )
}

export default AppleLoginButton
