import { useNavigate } from 'react-router-dom'
import { useEffect } from 'react'

const RedirectToBuilderPage = () => {
  const navigate = useNavigate()

  useEffect(() => {
    navigate('/builder')
  }, [navigate])

  return null
}

export default RedirectToBuilderPage
