import Box from '@mui/material/Box'
import LinearProgress from '@mui/material/LinearProgress'
import Paper, { PaperProps } from '@mui/material/Paper'
import { useTheme } from '@mui/material/styles'
import React, { useMemo } from 'react'
import IconButton from '@mui/material/IconButton'
import WarningIcon from '@mui/icons-material/WarningRounded'
import { styled, alpha } from '@mui/material/styles'
import Tooltip from '@mui/material/Tooltip'
import { RPatientSession } from '@counsel-project/counsel-transcribe-api'
import { getRelativeTime } from '../../util'
import Typography from '@mui/material/Typography'
import MicOffButton from '@mui/icons-material/MicOffRounded'

const WarningButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  top: theme.spacing(1),
  right: theme.spacing(1),
  color: theme.palette.warning.main,
  backgroundColor: alpha(theme.palette.warning.main, 0.2),
  '&:hover': {
    backgroundColor: alpha(theme.palette.warning.main, 0.4),
  },
}))

export type SessionContainerProps = {
  session: RPatientSession
  generating?: boolean
  children?: React.ReactNode
  href?: string
  onClick: (e: React.MouseEvent) => void
} & Omit<PaperProps, 'children' | 'href' | 'onClick'>

const SessionContainer = ({
  session,
  generating,
  children,
  href,
  onClick,
  ...props
}: SessionContainerProps) => {
  const theme = useTheme()

  const isRecording = useMemo(() => {
    return session.state === 'recording'
  }, [session])

  const willAutoDelete = useMemo(() => {
    return !!session.expiresAt
  }, [session])

  return (
    <Paper
      onClick={onClick}
      sx={{
        cursor: 'pointer',
        '&:hover': {
          backgroundColor: theme.palette.action.hover,
        },
        transition: 'background-color 0.2s ease',
        width: '100%',
        textAlign: 'left',
        display: 'block',
        height: '100%',
        textDecoration: 'none',
        outline:
          !session.note && !session.documentType
            ? `2px dashed ${theme.palette.text.secondary}`
            : 'none',
        border: 'none',
      }}
      elevation={4}
      aria-label="Session"
      aria-roledescription="button"
      {...props}
    >
      <Box
        sx={{
          position: 'relative',
          width: '100%',
          borderRadius: theme.shape.borderRadius + 'px ' + theme.shape.borderRadius + 'px 0 0',
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            width: '100%',
            height: 12,
            borderRadius: theme.shape.borderRadius + 'px ' + theme.shape.borderRadius + 'px 0 0',
            overflow: 'hidden',
          }}
        >
          {generating && (
            <LinearProgress
              sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
              }}
            />
          )}
        </Box>
        {willAutoDelete && !isRecording && (
          <Tooltip
            title={
              <Typography variant="body1" fontWeight={500}>
                Will auto-delete in{' '}
                {getRelativeTime((new Date(session.expiresAt || '').getTime() - Date.now()) / 1000)}{' '}
              </Typography>
            }
            color="warning"
          >
            <WarningButton>
              <WarningIcon />
            </WarningButton>
          </Tooltip>
        )}
        {isRecording && (
          <Tooltip
            title={
              <Typography variant="body1" fontWeight={500}>
                Audio was not captured
              </Typography>
            }
            color="warning"
          >
            <WarningButton>
              <MicOffButton />
            </WarningButton>
          </Tooltip>
        )}
      </Box>
      {children}
    </Paper>
  )
}

export default SessionContainer
