import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Checkbox from '@mui/material/Checkbox'
import DialogContent from '@mui/material/DialogContent'
import FormControlLabel from '@mui/material/FormControlLabel'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import { useCallback, useState } from 'react'
import ClosableDialog from '../../components/ClosableDialog'
import { deidentifierRequest } from '../../util/api/deidentifier-api'
import checkToken from '../../util/auth/checkToken'
import handleError from '../../util/handleError'

type ExportTranscriptDialogProps = {
  open: boolean
  onClose: () => void
}

const ExportTranscriptDialog = ({ open, onClose }: ExportTranscriptDialogProps) => {
  const [allowLiveSessions, setAllowLiveSessions] = useState(false)
  const [allowDictations, setAllowDictations] = useState(false)
  const [allowMissingPatients, setAllowMissingPatients] = useState(false)
  const [gteDuration, setGteDuration] = useState(0)
  const [minimumVersion, setMinimumVersion] = useState(0)

  const [loading, setLoading] = useState(false)

  const handleExport = useCallback(async () => {
    try {
      setLoading(true)

      await checkToken()

      const totalTranscripts: {
        transcript: string
        user: string
        duration: number
        patient?: string
        patientIncrement: number
      }[] = []

      for (let i = 0; i < 99999; i++) {
        const data = await deidentifierRequest.admin.list({
          token: '',
          limit: 1000,
          offset: i * 1000,
        })

        if (data.results.length === 0) break

        const filteredTranscripts = data.results.filter((r) => {
          if (r.duration < gteDuration) return false
          if (!allowLiveSessions && !r.dictation) return false
          if (!allowDictations && r.dictation) return false
          if (!r.transcript) return false
          if (!allowMissingPatients && !r.patient) return false
          if (!r.deidentifierVersion || r.deidentifierVersion < minimumVersion) return false
          return true
        })

        totalTranscripts.push(
          ...filteredTranscripts.map((r) => ({
            transcript: r.transcript || '',
            user: r.user,
            duration: r.duration,
            patient: r.patient,
            patientIncrement: r.patientIncrement,
          }))
        )
      }

      // create csv file with proper excaping & headers
      const escapeCommas = (str: string) => {
        return str.replace(/"/g, '""')
      }

      const escapeNewlines = (str: string) => {
        return str.replace(/\n/g, '\\n')
      }

      const headers = '"Transcript","User","Duration","Patient","Patient Increment"\n'

      const csv =
        headers +
        totalTranscripts
          .map((r) => {
            return `"${escapeNewlines(escapeCommas(r.transcript))}","${r.user}","${
              r.duration || 0
            }","${r.patient || ''}","${r.patientIncrement || 0}"`
          })
          .join('\n')

      const blob = new Blob([csv], { type: 'text/csv' })
      const url = URL.createObjectURL(blob)
      const a = document.createElement('a')
      a.href = url
      a.download = 'transcripts.csv'
      a.click()
    } catch (err) {
      handleError(err)
    } finally {
      setLoading(false)
    }
  }, [allowLiveSessions, allowDictations, gteDuration, allowMissingPatients, minimumVersion])

  return (
    <ClosableDialog titleText="Export Transcripts" open={open} onClose={onClose}>
      <DialogContent>
        <Box sx={{ mt: 4 }}>
          <Typography>Export transcripts to a CSV file.</Typography>
          <TextField
            label="Minimum Duration ( Seconds )"
            value={gteDuration}
            onChange={(e) => setGteDuration(Number(e.target.value))}
            type="number"
            fullWidth
            sx={{ mt: 2 }}
          />
          <TextField
            label="Minimum Deidentifier Version"
            value={minimumVersion}
            onChange={(e) => setMinimumVersion(Number(e.target.value))}
            type="number"
            fullWidth
            sx={{ mt: 2 }}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={allowLiveSessions}
                onChange={(e) => setAllowLiveSessions(e.target.checked)}
              />
            }
            label="Allow Live Sessions"
            sx={{ mt: 2 }}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={allowDictations}
                onChange={(e) => setAllowDictations(e.target.checked)}
              />
            }
            label="Allow Dictations"
            sx={{ mt: 2 }}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={allowMissingPatients}
                onChange={(e) => setAllowMissingPatients(e.target.checked)}
              />
            }
            label="Allow Missing Patients"
            sx={{ mt: 2 }}
          />
          <Box sx={{ mt: 2 }}>
            <Button variant="contained" color="primary" onClick={handleExport} disabled={loading}>
              Export
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </ClosableDialog>
  )
}

export default ExportTranscriptDialog
