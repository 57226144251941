import { RPatientSession } from '@counsel-project/counsel-transcribe-api'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { alpha, styled } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import React, { useMemo } from 'react'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import Grid from '@mui/material/Grid'
import CheckmarkIcon from '@mui/icons-material/CheckRounded'
import getSessionIcon from './func/getSessionIcon'
import { RLayout } from '@counsel-project/counsel-transcribe-api/dist/common/database/RLayout'

const SelectedOverlay = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: alpha(theme.palette.primary.contrastText, 0.5),
  borderRadius: theme.spacing(1),
  zIndex: 3,
}))

type BlockOptionProps = {
  selected?: boolean
}

const BlockOption = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'selected',
})<BlockOptionProps>(({ theme, selected }) => ({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  padding: theme.spacing(2),
  backgroundColor: theme.palette.background.paper,
  border: selected
    ? `2px solid ${theme.palette.primary.main}`
    : `2px dashed ${theme.palette.action.selected}`,
  borderRadius: theme.spacing(1),
  cursor: 'pointer',
  transition: 'background-color 0.3s',
  height: '100%',
  minHeight: 140,
}))

type ContextOptionProps = {
  layouts: RLayout[]
  session: RPatientSession
  onClick?: () => void
  onDelete?: () => void
  selected?: boolean
  disabled?: boolean
}

const ContextOption = ({
  disabled,
  selected,
  layouts,
  session,
  onClick,
  onDelete,
}: ContextOptionProps) => {
  const xs = useMediaQuery('(max-width: 600px)')

  const layout = layouts.find((l) => l.identifier === session.layout)

  const text = session?.note
    ? session.note.slice(0, 100) + '...'
    : session?.transcript
      ? session?.transcript.slice(0, 100) + '...'
      : ''

  const handleDelete = (e: React.MouseEvent) => {
    e.stopPropagation()
    onDelete?.()
  }

  const layoutLabel =
    session.dictation && !session?.layout
      ? 'Context Information'
      : session.summary || layout?.name || 'Note'

  const imgSrc = useMemo(() => {
    return getSessionIcon(session)
  }, [session])

  return (
    <BlockOption
      onClick={onClick}
      selected={selected}
      sx={
        disabled
          ? {
              pointerEvents: 'none',
              opacity: 0.5,
            }
          : {}
      }
    >
      {!!onDelete && (
        <IconButton
          sx={(theme) => ({
            position: 'absolute',
            top: -8,
            right: -8,
            borderRadius: 64,
            backgroundColor: 'background.paper',
            border: `2px dashed ${theme.palette.action.selected}`,
            ':hover': {
              backgroundColor: 'background.paper',
            },
          })}
        >
          <CloseIcon onClick={handleDelete} />
        </IconButton>
      )}
      <Box sx={{ mb: 2, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <img src={imgSrc} alt="icon" width={xs ? 38 : 50} style={{ userSelect: 'none' }} />
      </Box>
      <Typography variant="body1" fontWeight={500} color="text.primary" textAlign="center">
        {layoutLabel}
      </Typography>
      <Box sx={{ maxWidth: '100%' }}>
        <Typography
          variant="body2"
          fontWeight={500}
          color="text.secondary"
          textAlign="center"
          textOverflow="ellipsis"
        >
          {text.split('\n').join(' ').split('*').join('')}
        </Typography>
        <Typography
          variant="body2"
          fontWeight={500}
          color="text.secondary"
          textAlign="center"
          textOverflow="ellipsis"
        >
          {new Date(session.createdAt).toLocaleDateString()}
        </Typography>
      </Box>
      {selected && (
        <SelectedOverlay>
          <Grid container justifyContent="center" alignItems="center" sx={{ height: '100%' }}>
            <Grid item>
              <CheckmarkIcon fontSize="large" sx={{ color: 'primary.main', fontSize: 80 }} />
            </Grid>
          </Grid>
        </SelectedOverlay>
      )}
    </BlockOption>
  )
}

export default ContextOption
