import Grid from '@mui/material/Grid'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import LabeledTextField from '../../components/layout/LabeledTextField'
import SearchField from '../../components/SearchField'

export type AdminSearchParams = {
  search: string | null
  startDate: string | null
  endDate: string | null
  minDuration: number | null
  maxDuration: number | null
}

type AdminSearchControlsProps = {
  onSubmit?: (params: AdminSearchParams) => void
}

const AdminSearchControls = ({ onSubmit }: AdminSearchControlsProps) => {
  const [searchParams, setSearchParams] = useSearchParams()

  const submittedSearch = searchParams.get('search') || null
  const submittedStartDate = searchParams.get('startDate') || null
  const submittedEndDate = searchParams.get('endDate') || null
  const submittedMinDuration = searchParams.get('minDuration') || null
  const submittedMaxDuration = searchParams.get('maxDuration') || null

  const [search, setSearch] = useState(submittedSearch || '')
  const [startDate, setStartDate] = useState(submittedStartDate || null)
  const [endDate, setEndDate] = useState(submittedEndDate || null)
  const [minDuration, setMinDuration] = useState(submittedMinDuration || null)
  const [maxDuration, setMaxDuration] = useState(submittedMaxDuration || null)

  const handleChangeSearch = useCallback((value: string) => {
    setSearch(value)
  }, [])

  const handleChangeStartDate = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setStartDate(e.target.value)
  }, [])

  const handleChangeEndDate = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setEndDate(e.target.value)
  }, [])

  const handleChangeMinDuration = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setMinDuration(e.target.value)
  }, [])

  const handleChangeMaxDuration = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setMaxDuration(e.target.value)
  }, [])

  useEffect(() => {
    const timeout = setTimeout(() => {
      setSearchParams((prev) => {
        prev.set('search', search)
        prev.set('startDate', startDate || '')
        prev.set('endDate', endDate || '')
        prev.set('minDuration', minDuration || '')
        prev.set('maxDuration', maxDuration || '')
        return prev
      })

      console.log('search', search)

      const minDurationInt = minDuration ? parseInt(minDuration) : null
      const maxDurationInt = maxDuration ? parseInt(maxDuration) : null

      onSubmit?.({
        search,
        startDate,
        endDate,
        minDuration: minDurationInt,
        maxDuration: maxDurationInt,
      })
    }, 1000)
    return () => clearTimeout(timeout)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, startDate, endDate, minDuration, maxDuration, onSubmit])

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={12}>
        <SearchField value={search || ''} onChange={handleChangeSearch} />
      </Grid>
      <Grid item xs={12} md={6}>
        <LabeledTextField
          label="From"
          type="date"
          value={startDate || ''}
          onChange={handleChangeStartDate}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <LabeledTextField
          label="To"
          type="date"
          value={endDate || ''}
          onChange={handleChangeEndDate}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <LabeledTextField
          label="Min Duration"
          type="number"
          value={minDuration || ''}
          onChange={handleChangeMinDuration}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <LabeledTextField
          label="Max Duration"
          type="number"
          value={maxDuration || ''}
          onChange={handleChangeMaxDuration}
        />
      </Grid>
    </Grid>
  )
}

export default AdminSearchControls
