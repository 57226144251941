import { RLayout } from '@counsel-project/counsel-transcribe-api'
import NextIcon from '@mui/icons-material/ArrowForward'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import DialogContent from '@mui/material/DialogContent'
import Grid from '@mui/material/Grid'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { markdownToHtml } from '../../util/markdown'
import ClosableDialog from '../ClosableDialog'
import DictationTextField from './DictationTextField'
import CloseIconRounded from '@mui/icons-material/CloseRounded'
import Typography from '@mui/material/Typography'

type ClinicalInfoDialogProps = {
  value?: string
  loading?: boolean
  title: string
  buttonText: string
  placeholder: string
  layout: RLayout
  open: boolean
  onClose: () => void
  onSubmit: (value: string) => void
}

const ClinicalInfoDialog = ({
  value,
  loading,
  title,
  buttonText,
  placeholder,
  open,
  layout,
  onClose,
  onSubmit,
}: ClinicalInfoDialogProps) => {
  const [text, setText] = useState<string>(value || '')

  useEffect(() => {
    setText(value || '')
  }, [value, open])

  const handleClose = useCallback(() => {
    if (loading) return
    onClose()
  }, [loading, onClose])

  const handleContinue = useCallback(() => {
    onSubmit(text)
  }, [text, onSubmit])

  const html = useMemo(
    () => (layout.dictationDescription ? markdownToHtml(layout.dictationDescription) : null),
    [layout.dictationDescription]
  )

  const handleClear = useCallback(() => {
    setText('')
    onSubmit('')
  }, [onSubmit])

  return (
    <ClosableDialog open={open} onClose={handleClose} titleText={title}>
      <DialogContent>
        {html && (
          <Box sx={{ mb: 1 }}>
            <div dangerouslySetInnerHTML={{ __html: html }} />
          </Box>
        )}
        <DictationTextField placeholder={placeholder} value={text} onChange={setText} />
        <Typography variant="body1" fontWeight={500} sx={{ mt: 1 }} color="text.secondary">
          Speak to the AI in natural language like your personal scribe.
        </Typography>
        <Grid container spacing={2} justifyContent="end" sx={{ mt: 1 }}>
          {!!value && (
            <Grid item xs>
              <Button
                color="secondary"
                disabled={loading}
                onClick={handleClear}
                startIcon={<CloseIconRounded />}
              >
                Clear
              </Button>
            </Grid>
          )}
          <Grid item>
            <Button disabled={loading} onClick={handleContinue} endIcon={<NextIcon />}>
              {buttonText}
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </ClosableDialog>
  )
}

export default ClinicalInfoDialog
