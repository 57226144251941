import { useEffect, useRef } from 'react'
import { logPixelCustomEvent, logPixelEvent } from './meta-pixel'
import { googleSetUserData, logGoogleEvent, logGooglePageView } from './google-analytics'
import { useLocation } from 'react-router-dom'
import { logPosthogEvent } from './posthog'
import useUser from '../auth/useUser'

export const useViewContent = (title: string) => {
  useEffect(() => {
    logPixelEvent('ViewContent', {
      content_name: title,
    })
  }, [title])
}

export const useTracking = () => {
  const location = useLocation()
  const loggedPath = location.pathname + location.search
  const lastLoggedLocation = useRef('')

  const [user] = useUser()

  useEffect(() => {
    if (!user?.email) return
    googleSetUserData({
      email: user.email,
    })
  }, [user?.email])

  useEffect(() => {
    if (lastLoggedLocation.current === loggedPath) return
    logGooglePageView(loggedPath)
    lastLoggedLocation.current = loggedPath
  }, [loggedPath])
}

type LogInitiateCheckoutOptions = {
  productName: string
  productPrice: number
  quantity: number
  amountTotal: number
}

export const logInitiateCheckout = ({
  productName,
  productPrice,
  quantity,
  amountTotal,
}: LogInitiateCheckoutOptions) => {
  logPixelEvent('InitiateCheckout', {
    content_name: 'Pricing',
    content_category: 'Pricing',
    content_ids: [productName],
    content_type: 'product',
    value: amountTotal,
    currency: 'USD',
    num_items: quantity,
  })
  logGoogleEvent('begin_checkout', {
    value: amountTotal,
    currency: 'USD',
    items: [
      {
        item_id: productName,
        item_name: productName,
        quantity,
        price: productPrice,
      },
    ],
  })
  logPosthogEvent('initiate_checkout', {
    productName,
    productPrice,
    quantity,
    amountTotal,
  })
}

export const logScheduleDemo = () => {
  logPixelEvent('Lead', {
    content_name: 'Schedule Demo',
    content_category: 'Schedule Demo',
    value: 0,
    currency: 'USD',
  })
  logGoogleEvent('generate_lead', {
    value: 0,
    currency: 'USD',
  })
  logPosthogEvent('schedule_demo', {})
}

export const logClickStartFreeTrial = () => {
  logPixelEvent('Lead', {
    content_name: 'Start Free Trial',
    content_category: 'Start Free Trial',
    value: 0,
    currency: 'USD',
  })
  logGoogleEvent('generate_lead', {
    value: 0,
    currency: 'USD',
  })
}

export const logLogin = (method: string) => {
  logPixelCustomEvent('Login', {
    content_name: 'Login',
    content_category: 'Login',
  })
  logGoogleEvent('login', {
    method,
  })
  logPosthogEvent('login', {
    method,
  })
}

export const logSignUp = (method: string) => {
  logPixelEvent('CompleteRegistration', {
    content_name: 'Sign Up',
    content_category: 'Sign Up',
  })
  logGoogleEvent('sign_up', {
    method,
  })
  logPosthogEvent('sign_up', {
    method,
  })
}

type LogStartFreeTrialOptions = {
  productName: string
  productPrice: number
  quantity: number
  amountTotal: number
}

export const logStartFreeTrial = ({
  productName,
  productPrice,
  quantity,
  amountTotal,
}: LogStartFreeTrialOptions) => {
  logPixelEvent('StartTrial', {
    content_name: 'Free Trial',
    content_category: 'Free Trial',
    content_ids: [productName],
    content_type: 'product',
    value: amountTotal,
    currency: 'USD',
    num_items: quantity,
  })
  logGoogleEvent('start_trial', {
    value: amountTotal,
    currency: 'USD',
    items: [
      {
        item_id: productName,
        item_name: productName,
        quantity,
        price: productPrice,
      },
    ],
  })
  logPosthogEvent('start_trial', {
    productName,
    productPrice,
    quantity,
    amountTotal,
  })
}

type LogCancelFreeTrialOptions = {
  productName: string
  productPrice: number
  quantity: number
  amountTotal: number
}

export const logCancelFreeTrial = ({
  productName,
  productPrice,
  quantity,
  amountTotal,
}: LogCancelFreeTrialOptions) => {
  logPixelEvent('CancelTrial', {
    content_name: 'Cancel Free Trial',
    content_category: 'Cancel Free Trial',
    content_ids: [productName],
    content_type: 'product',
    value: amountTotal,
    currency: 'USD',
    num_items: quantity,
  })
  logGoogleEvent('cancel_trial', {
    value: amountTotal,
    currency: 'USD',
    items: [
      {
        item_id: productName,
        item_name: productName,
        quantity,
        price: productPrice,
      },
    ],
  })
  logPosthogEvent('cancel_trial', {
    productName,
    productPrice,
    quantity,
    amountTotal,
  })
}

type LogCancelSubscriptionOptions = {
  productName: string
  productPrice: number
  quantity: number
  amountTotal: number
}

export const logCancelSubscription = ({
  productName,
  productPrice,
  quantity,
  amountTotal,
}: LogCancelSubscriptionOptions) => {
  logPixelEvent('CancelSubscription', {
    content_name: 'Cancel Subscription',
    content_category: 'Cancel Subscription',
    content_ids: [productName],
    content_type: 'product',
    value: amountTotal,
    currency: 'USD',
    num_items: quantity,
  })
  logGoogleEvent('cancel_subscription', {
    value: amountTotal,
    currency: 'USD',
    items: [
      {
        item_id: productName,
        item_name: productName,
        quantity,
        price: productPrice,
      },
    ],
  })
  logPosthogEvent('cancel_subscription', {
    productName,
    productPrice,
    quantity,
    amountTotal,
  })
}

type LogAddToCartOptions = {
  productName: string
  productPrice: number
  quantity: number
  amountTotal: number
}

export const logAddToCart = ({
  productName,
  productPrice,
  quantity,
  amountTotal,
}: LogAddToCartOptions) => {
  logPixelEvent('AddToCart', {
    content_name: 'Start Trial',
    content_category: 'Start Trial',
    content_ids: [productName],
    content_type: 'product',
    value: amountTotal,
    currency: 'USD',
    num_items: quantity,
  })
  logGoogleEvent('add_to_cart', {
    value: amountTotal,
    currency: 'USD',
    items: [
      {
        item_id: productName,
        item_name: productName,
        quantity,
        price: productPrice,
      },
    ],
  })
  logPosthogEvent('add_to_cart', {
    productName,
    productPrice,
    quantity,
    amountTotal,
  })
}

type LogPurchaseOptions = {
  productName: string
  productPrice: number
  quantity: number
  amountTotal: number
  transactionId: string
}

export const logPurchase = ({
  productName,
  productPrice,
  quantity,
  amountTotal,
  transactionId,
}: LogPurchaseOptions) => {
  logPixelEvent('Purchase', {
    content_name: 'Purchase',
    content_category: 'Purchase',
    content_ids: [productName],
    content_type: 'product',
    value: amountTotal,
    currency: 'USD',
    num_items: quantity,
  })
  logPixelEvent(`Purchase${productName}`, {
    content_name: `Purchase ${productName}`,
    content_category: `Purchase${productName}`,
    content_ids: [productName],
    content_type: 'product',
    value: amountTotal,
    currency: 'USD',
    num_items: quantity,
  })
  logGoogleEvent('purchase', {
    value: amountTotal,
    currency: 'USD',
    transaction_id: transactionId,
    items: [
      {
        item_id: productName,
        item_name: productName,
        quantity,
        price: productPrice,
      },
    ],
  })
  logPosthogEvent('purchase', {
    productName,
    productPrice,
    quantity,
    amountTotal,
    transactionId,
  })
}

type LogLiveSessionOptions = {
  duration: number
  template?: string
}

export const logLiveSession = ({ duration, template }: LogLiveSessionOptions) => {
  logPixelEvent('LiveSession', {
    content_name: 'Live Session',
    content_category: 'Live Session',
    value: duration,
    template,
  })
  logGoogleEvent('live_session', {
    value: duration,
    template,
  })
  logPosthogEvent('live_session', {
    duration,
    template,
  })
}

type LogDictationOptions = {
  duration: number
  template?: string
}

export const logDictation = ({ duration, template }: LogDictationOptions) => {
  logPixelEvent('Dictation', {
    content_name: 'Dictation',
    content_category: 'Dictation',
    value: duration,
    template,
  })
  logGoogleEvent('dictation', {
    value: duration,
    template,
  })
  logPosthogEvent('dictation', {
    duration,
    template,
  })
}

type LogAIEditOptions = {
  template: string
  name: string
}

export const logAIEdit = ({ template, name }: LogAIEditOptions) => {
  logPixelEvent('AIEdit', {
    content_name: 'AI Edit',
    content_category: 'AI Edit',
    template,
    name,
  })
  logGoogleEvent('ai_edit', {
    template,
    name,
  })
  logPosthogEvent('ai_edit', {
    template,
    name,
  })
}

type LogDocumentOptions = {
  template: string
  name: string
}

export const logDocument = ({ template, name }: LogDocumentOptions) => {
  logPixelEvent('Document Generation', {
    content_name: 'Document Generation',
    content_category: 'Document Generation',
    template,
    name,
  })
  logGoogleEvent('document_gen', {
    template,
    name,
  })
  logPosthogEvent('document_gen', {
    template,
    name,
  })
}

type LogCreateTemplateOptions = {
  id: string
  name: string
}

export const logCreateTemplate = ({ id, name }: LogCreateTemplateOptions) => {
  logPixelEvent('CreateTemplate', {
    content_name: 'Create Template',
    content_category: 'Create Template',
    id,
    name,
  })
  logGoogleEvent('create_template', {
    id,
    name,
  })
  logPosthogEvent('create_template', {
    id,
    name,
  })
}
