import {
  PatientSessionsListAllOptions,
  RPatientSession,
} from '@counsel-project/counsel-transcribe-api'
import {
  DocumentType,
  LayoutType,
} from '@counsel-project/counsel-transcribe-api/dist/common/database/RLayout'
import CircularProgress from '@mui/material/CircularProgress'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { useCallback, useEffect, useState } from 'react'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import SimplePagination from '../../components/forms/SimplePagination'
import { listPatientSessionsCached } from '../../util/api/transcribe-api-cached'
import checkToken from '../../util/auth/checkToken'
import handleError from '../../util/handleError'
import { createSearchParams } from '../builder/common'
import SessionItem from '../sessions/SessionItem'

type SessionsSectionProps = {
  patientId: string
  title: string
  search?: string
  documentType?: DocumentType | 'nn'
  type?: LayoutType | 'ne'
}

const SessionsSection = ({
  patientId,
  title,
  search,
  documentType,
  type,
}: SessionsSectionProps) => {
  const limit = 9
  const [sessions, setSessions] = useState<RPatientSession[]>([])
  const [offset, setOffset] = useState(0)
  const [total, setTotal] = useState(0)
  const [isLoading, setIsLoading] = useState(false)

  const navigate = useNavigate()
  const location = useLocation()

  const [searchParams] = useSearchParams()

  const userId = searchParams.get('userId')
  const showAll = searchParams.get('showAll') === 'true'

  const populateSessions = useCallback(async () => {
    try {
      setIsLoading(true)

      await checkToken()

      const searches: PatientSessionsListAllOptions['search'] = {
        and: [],
      }

      if (search) {
        searches.or = [
          {
            summary: search,
            $fuzzy: true,
          },
        ]
        if (!patientId) {
          searches.or.push({
            patientLabel: search,
            $fuzzy: true,
          })
        }
      }

      if (patientId) {
        searches?.and?.push({ patientId })
      }

      if (documentType) {
        if (documentType === 'nn') {
          searches?.and?.push({ documentType: null, $not: true })
        } else {
          searches?.and?.push({ documentType })
        }
      }

      if (type) {
        if (type === 'ne') {
          searches?.and?.push({ type: null })
        } else {
          searches?.and?.push({ type })
        }
      }

      const { results, total } = await listPatientSessionsCached({
        patientId,
        token: '',
        limit,
        offset,
        sort: 'createdAt',
        direction: 'desc',
        search: searches,
      })

      setSessions(results)
      setTotal(total)
    } catch (err) {
      handleError(err)
    } finally {
      setIsLoading(false)
    }
  }, [offset, patientId, search, documentType, type])

  useEffect(() => {
    const timeout = setTimeout(populateSessions, 100)
    return () => clearTimeout(timeout)
  }, [populateSessions])

  const sessionClickHandler = (session: RPatientSession) => (e: React.MouseEvent) => {
    e.preventDefault()

    const queryText = createSearchParams({
      ref: location.pathname + location.search,
      userId,
      showAll,
    })

    navigate(`/sessions/${session._id}${queryText}`)
  }

  useEffect(() => {
    setOffset(0)
  }, [patientId, documentType, type])

  return (
    <>
      <Typography variant="h5" component="h2" sx={{ fontWeight: 500, mt: 2, mb: 2 }}>
        {title}
      </Typography>
      <Grid container spacing={2} paddingTop={2}>
        {sessions.map((session) => (
          <Grid item key={session._id} xs={12} sm={6} md={4}>
            <SessionItem
              hidePatientLabel
              session={session}
              onClick={sessionClickHandler(session)}
            />
          </Grid>
        ))}
        {!sessions.length && !isLoading && (
          <Grid item xs={12}>
            <Typography variant="body1" color="text.secondary">
              Nothing found
            </Typography>
          </Grid>
        )}
        {!sessions.length && isLoading && (
          <Grid item>
            <CircularProgress />
          </Grid>
        )}
        {!!sessions.length && (
          <Grid item xs={12}>
            <SimplePagination
              loading={isLoading}
              offset={offset}
              total={total}
              limit={limit}
              onClickNext={() => setOffset(offset + limit)}
              onClickPrevious={() => setOffset(offset - limit)}
            />
          </Grid>
        )}
      </Grid>
    </>
  )
}

export default SessionsSection
