import posthog from 'posthog-js'

posthog.init('phc_VH5Pvz11pxeQrDkuzjWwDJH64eZhZA6gsgv9If4fS5', {
  api_host: 'https://us.i.posthog.com',
  person_profiles: 'always', // or 'always' to create profiles for anonymous users as well
  autocapture: false,
})

// Sets the user's email and name
export const posthogIdentify = (userId: string, email: string, name: string) => {
  try {
    posthog.alias(userId, email) // Merging the old email profile with the new user ID profile
    posthog.identify(email, {
      email,
      name,
    })
  } catch (err) {
    console.error('Error identifying user in Posthog', err)
  }
}

export const logPosthogEvent = (eventName: string, options?: any) => {
  try {
    posthog.capture(eventName, options)
  } catch (err) {
    console.error(err)
  }
}
