import useStore from '../store/auth'

const useLayouts = () => {
  const layouts = useStore((state) => state.layouts)
  const setLayouts = useStore((state) => state.setLayouts)
  const populateLayouts = useStore((state) => state.populateLayouts)

  return [layouts, setLayouts, populateLayouts] as const
}

export default useLayouts
