import { RPatient } from '@counsel-project/counsel-transcribe-api'
import { capitalize } from '@counsel-project/client-utils'
import Button from '@mui/material/Button'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import Typography from '@mui/material/Typography'
import { useCallback, useState } from 'react'
import { usePatientNomenclature } from '../../util'
import { transcribeRequest } from '../../util/api/transcribe-api'
import { refreshPatientsCache, refreshSessionsCache } from '../../util/api/transcribe-api-cached'
import checkToken from '../../util/auth/checkToken'
import handleError from '../../util/handleError'
import ClosableDialog from '../ClosableDialog'
import PatientSelector from '../PatientSelector'

type SaveForLaterDialogProps = {
  sessionId: string
  open: boolean
  onClose: () => void
  onSave?: (patientLabel: string) => void
}

const SaveForLaterDialog = ({ sessionId, open, onClose, onSave }: SaveForLaterDialogProps) => {
  const [selectedPatient, setSelectedPatient] = useState<RPatient | null>(null)
  const [loading, setLoading] = useState(false)

  const patientNomenclature = usePatientNomenclature()

  const handleSaveSession = useCallback(async () => {
    try {
      if (!selectedPatient) return

      setLoading(true)

      await checkToken()

      let patient = selectedPatient
      if (!selectedPatient._id) {
        // Create new patient
        const { result } = await transcribeRequest.patients.create({
          token: '',
          label: selectedPatient.label,
        })
        patient = result
        refreshPatientsCache()
      }

      await transcribeRequest.sessions.update({
        token: '',
        sessionId,
        patientId: patient._id,
      })

      refreshSessionsCache()
      onSave?.(selectedPatient.label)
    } catch (err) {
      handleError(err)
    } finally {
      setLoading(false)
    }
  }, [selectedPatient, sessionId, onSave])

  return (
    <ClosableDialog open={open} onClose={onClose} titleText="Save For Later">
      <DialogContent>
        <Typography variant="body1" fontWeight={500} sx={{ mb: 1 }}>
          Select {capitalize(patientNomenclature)}
        </Typography>
        <PatientSelector value={selectedPatient} onChange={setSelectedPatient} />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} disabled={loading}>
          Cancel
        </Button>
        <Button onClick={handleSaveSession} disabled={!selectedPatient || loading}>
          Save
        </Button>
      </DialogActions>
    </ClosableDialog>
  )
}

export default SaveForLaterDialog
