import React from 'react'
import Button, { ButtonProps } from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import ArrowRightIcon from '@mui/icons-material/ArrowForwardRounded'

type DirectionButtonProps = {
  title: string
  subtitle: string
} & Omit<ButtonProps, 'endIcon' | 'children'>

const DirectionButton = ({ title, subtitle, ...props }: DirectionButtonProps) => {
  return (
    <Button endIcon={<ArrowRightIcon />} fullWidth {...props}>
      <Grid container justifyContent="start">
        <Grid item xs={12}>
          <Typography variant="body1" fontSize={18} fontWeight={500} textAlign="start">
            {title}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Box>
            <Typography variant="body1" textAlign="start" sx={{ opacity: 0.8 }}>
              {subtitle}
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Button>
  )
}

export default DirectionButton
