import StarIcon from '@mui/icons-material/StarRounded'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'

const promoteKit = 'https://clinicalnotesai.promotekit.com'

const ReferralCodeSection = () => {
  return (
    <Paper elevation={0} sx={{ p: 2 }} id="referral-program">
      <Grid container spacing={1} alignItems="center">
        <Grid item>
          <StarIcon color="secondary" />
        </Grid>
        <Grid item>
          <Typography variant="h5">Referral Program</Typography>
        </Grid>
      </Grid>
      <Box>
        <Typography sx={{ mt: 2 }}>
          Earn up to <strong>$5</strong> towards your subscription per referral.
        </Typography>
        <Button
          sx={{ mt: 2 }}
          href={promoteKit}
          target="_blank"
          rel="noreferrer noopener"
          variant="contained"
        >
          Get My Affiliate Link
        </Button>
      </Box>
    </Paper>
  )
}

export default ReferralCodeSection
