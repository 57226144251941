import ClosableDialog from '../../../components/ClosableDialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import { useNavigate } from 'react-router-dom'

const isLiveAudioSupported = () => {
  if (!('AudioContext' in window) && !('webkitAudioContext' in window)) {
    return false
  }

  return true
}

const CheckAudioSupportDialog = () => {
  const navigate = useNavigate()

  if (isLiveAudioSupported()) {
    return null
  }

  const handleGoBack = () => {
    navigate(-1)
  }

  return (
    <ClosableDialog open onClose={() => {}} titleText="Unsupported Browser" maxWidth="sm">
      <DialogContent>
        <Typography variant="body1">
          Your browser does not support the necessary audio features to use this application. Please
          try using a different browser. We recommend using the latest version of Google Chrome.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleGoBack} color="primary">
          Go Back
        </Button>
      </DialogActions>
    </ClosableDialog>
  )
}

export default CheckAudioSupportDialog
