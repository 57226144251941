import { RPatient, RPatientSession } from '@counsel-project/counsel-transcribe-api'
import { RLayout } from '@counsel-project/counsel-transcribe-api/dist/common/database/RLayout'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { useCallback, useMemo, useState } from 'react'
import toast from 'react-hot-toast'
import { useMountedState } from 'react-use'
import { usePatientNomenclature } from '../../util'
import { transcribeRequest } from '../../util/api/transcribe-api'
import { refreshSessionsCache } from '../../util/api/transcribe-api-cached'
import { visionRequest } from '../../util/api/vision-api'
import checkToken from '../../util/auth/checkToken'
import handleError from '../../util/handleError'
import log from '../../util/logging'
import { visionSessionsPromise } from '../../util/processPromise'
import AddObservationsDialog from './AddObservationsDialog'
import BlockyOption from './BlockyOption'
import ContextBoxContainer from './ContextBoxContainer'
import ContextOption from './ContextOption'
import ContextUploadDialog from './ContextUploadDialog'
import DictateDocumentDialog from './DictateDocumentDialog'
import SelectPatientContextDialog from './SelectPatientContextDialog'

export type ContextOptionsProps = {
  title?: string
  mainSession?: RPatientSession | null
  onChangeMainSession?: (session: RPatientSession | null) => void
  patient?: RPatient | null
  layout: RLayout
  layouts: RLayout[]
  contextSessions: RPatientSession[]
  onChangeContextSessions: (sessions: RPatientSession[]) => void
  context: string
  onChangeContext: (context: string) => void
}

const ContextOptions = ({
  title,
  mainSession,
  onChangeMainSession,
  patient,
  layout,
  layouts,
  contextSessions,
  onChangeContextSessions,
  context,
  onChangeContext,
}: ContextOptionsProps) => {
  const [uploadFileOpen, setUploadFileOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [addObservationsOpen, setAddObservationsOpen] = useState(false)
  const [whitelistedLayouts, setWhitelistedLayouts] = useState<string[] | undefined>(undefined)
  const [dictateOpen, setDictateOpen] = useState(false)
  const [noteContextOpen, setNoteContextOpen] = useState(false)
  const [documentContextOpen, setDocumentContextOpen] = useState(false)
  const isMounted = useMountedState()

  const { allowDictation, maxDocuments, maxNotes } = layout.config.context

  const remainingDocuments = useMemo(
    () => maxDocuments - contextSessions.filter((s) => s.type === 'document').length,
    [maxDocuments, contextSessions]
  )
  const remainingNotes = useMemo(
    () => maxNotes - contextSessions.filter((s) => !s.type || s.type === 'note').length,
    [maxNotes, contextSessions]
  )

  const contextClickHandler = useCallback(
    (session: RPatientSession) => () => {
      if (!mainSession) return
      if (session.dictation && session._id === mainSession._id) {
        setDictateOpen(true)
      }
    },
    [mainSession]
  )

  const contextDeleteHandler = useCallback(
    (session: RPatientSession) => async () => {
      if (session._id === mainSession?._id && !session.note) {
        onChangeMainSession?.(null)
        try {
          await checkToken()

          await transcribeRequest.sessions.delete({
            token: '',
            sessionId: mainSession._id,
          })
          refreshSessionsCache()
        } catch (err) {
          log.error(err)
        }
      } else if (session._id !== mainSession?._id) {
        onChangeContextSessions?.(contextSessions.filter((s) => s._id !== session._id))
      } else {
        toast.error('Cannot clear this context', { id: 'cannot-delete' })
      }
    },
    [onChangeContextSessions, contextSessions, onChangeMainSession, mainSession]
  )

  const handleAddFile = useCallback(
    async (file: File) => {
      try {
        setLoading(true)

        await checkToken()

        const formData = new FormData()
        formData.append('file', file)

        const { processId } = await visionRequest.sessions.generate({
          token: '',
          formData,
          patientId: patient?._id || undefined,
        })

        toast.loading('Processing document...', { id: 'process-document', duration: 999999 })

        const sessionId = await visionSessionsPromise({
          processId,
        })

        toast.success('Document processed', { id: 'process-document', duration: 5000 })
        if (!isMounted()) return

        try {
          setLoading(true)

          const { result } = await transcribeRequest.sessions.get({
            token: '',
            sessionId,
          })

          onChangeContextSessions?.([...contextSessions, result])
          setUploadFileOpen(false)
        } catch (err) {
          handleError(err)
        } finally {
          setLoading(false)
        }
      } catch (err) {
        handleError(err)
      }
    },
    [onChangeContextSessions, contextSessions, patient, isMounted]
  )

  const handleAddSessions = useCallback(
    (sessions: RPatientSession[]) => {
      onChangeContextSessions([
        ...contextSessions.filter((c) => !sessions.some((s) => c._id === s._id)),
        ...sessions,
      ])
      setDocumentContextOpen(false)
      setNoteContextOpen(false)
    },
    [onChangeContextSessions, contextSessions]
  )

  const patientNomenclature = usePatientNomenclature()

  const handleOpenDocumentContext = () => {
    if (!patient) return
    setWhitelistedLayouts(undefined)
    setDocumentContextOpen(true)
  }

  const handleOpenNoteContext = () => {
    if (!patient) return
    setWhitelistedLayouts(undefined)
    setNoteContextOpen(true)
  }

  const handleChangeSession = useCallback(
    async (session: RPatientSession | null) => {
      try {
        setLoading(true)

        await checkToken()

        if (session && mainSession && mainSession._id !== session._id) {
          await transcribeRequest.sessions.delete({
            token: '',
            sessionId: mainSession._id,
          })
          refreshSessionsCache()
        }

        setDictateOpen(false)
        onChangeMainSession?.(session)
      } catch (err) {
        log.error(err)
      } finally {
        setLoading(false)
      }
    },
    [mainSession, onChangeMainSession]
  )

  return (
    <ContextBoxContainer disableDrag={uploadFileOpen} onFileChange={handleAddFile}>
      {title && (
        <Typography variant="body1" fontWeight={500} fontSize={18} sx={{ mb: 2 }}>
          {title}
        </Typography>
      )}
      <Grid container spacing={2} alignItems="stretch">
        {maxDocuments > 0 && (
          <Grid item xs={12} sm={6}>
            <BlockyOption
              src="/icons/book-2-plus.svg"
              onClick={handleOpenDocumentContext}
              title={`Add Document${maxDocuments === 1 ? '' : 's'}`}
              subtitle={
                !patient
                  ? `Select a ${patientNomenclature} to add documents from their profile`
                  : `Add${maxDocuments === 1 ? ' a' : ''} previously saved document${
                      maxDocuments === 1 ? '' : 's'
                    } for additional context`
              }
              subtitleColor={!patient ? 'primary' : 'text.secondary'}
              disabled={!patient || remainingDocuments === 0}
            />
          </Grid>
        )}
        {maxNotes > 0 && (
          <Grid item xs={12} sm={6}>
            <BlockyOption
              src="/icons/notes-plus.svg"
              onClick={handleOpenNoteContext}
              title={`Add Note${maxNotes === 1 ? '' : 's'}`}
              subtitle={
                !patient
                  ? `Select a ${patientNomenclature} to add notes from their profile`
                  : `Add${maxNotes === 1 ? ' a' : ''} previously saved note${
                      maxNotes === 1 ? '' : 's'
                    } for additional context`
              }
              subtitleColor={!patient ? 'primary' : 'text.secondary'}
              disabled={!patient || remainingNotes === 0}
            />
          </Grid>
        )}
        {!mainSession && allowDictation && (
          <Grid item xs={12} md={6}>
            <BlockyOption
              src="/icons/user-speak.svg"
              onClick={() => setDictateOpen(true)}
              title="Add Dictation"
              subtitle={`Provide the details needed to generate this ${layout.type}`}
            />
          </Grid>
        )}
        {mainSession && !mainSession.dictation && (
          <Grid item xs={12} sm={6}>
            <BlockyOption
              src="/icons/eye-scan.svg"
              onClick={() => setAddObservationsOpen(true)}
              title={context ? 'Adjust Clinician Context' : 'Add Clinician Context'}
              subtitle={
                context
                  ? context.slice(0, 30) + '...'
                  : 'Dictate or type out your observations to provide additional context'
              }
            />
          </Grid>
        )}
        <Grid item xs={12} sm={6}>
          <BlockyOption
            src="/icons/upload-minimalistic.svg"
            onClick={() => setUploadFileOpen(true)}
            title="Upload A Document"
            subtitle="Use an existing document for additional context"
            disabled={remainingDocuments === 0 && remainingNotes === 0}
          />
        </Grid>
        {mainSession && mainSession.dictation && (
          <Grid item xs={12} md={6}>
            <ContextOption
              layouts={layouts}
              session={mainSession}
              onClick={contextClickHandler(mainSession)}
              onDelete={contextDeleteHandler(mainSession)}
            />
          </Grid>
        )}
        {contextSessions?.map((session) => (
          <Grid item key={session._id} xs={12} md={6}>
            <ContextOption
              layouts={layouts}
              session={session}
              onClick={contextClickHandler(session)}
              onDelete={contextDeleteHandler(session)}
            />
          </Grid>
        ))}
      </Grid>
      {(remainingDocuments !== 0 || remainingNotes !== 0) && (
        <ContextUploadDialog
          loading={loading}
          open={uploadFileOpen}
          onClose={() => setUploadFileOpen(false)}
          onChangeFile={handleAddFile}
        />
      )}
      {!!patient && (
        <SelectPatientContextDialog
          open={documentContextOpen || noteContextOpen}
          onClose={() => {
            setDocumentContextOpen(false)
            setNoteContextOpen(false)
          }}
          patient={patient}
          onAddSessions={handleAddSessions}
          mainSession={mainSession}
          whitelistedLayouts={whitelistedLayouts}
          whitelistedTypes={documentContextOpen ? ['document'] : ['note']}
          max={documentContextOpen ? remainingDocuments : remainingNotes}
        />
      )}
      <DictateDocumentDialog
        mainSession={mainSession}
        open={dictateOpen}
        onClose={() => setDictateOpen(false)}
        layout={layout}
        onFinish={handleChangeSession}
      />
      <AddObservationsDialog
        value={context}
        layout={layout}
        open={addObservationsOpen}
        onClose={() => setAddObservationsOpen(false)}
        onSubmit={onChangeContext}
      />
    </ContextBoxContainer>
  )
}

export default ContextOptions
