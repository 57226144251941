import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Checkbox from '@mui/material/Checkbox'
import Collapse from '@mui/material/Collapse'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import FormControlLabel from '@mui/material/FormControlLabel'
import Typography from '@mui/material/Typography'
import { useCallback, useEffect, useMemo, useState } from 'react'
import getIsMobile from '../../util/getIsMobile'
import useAvailableAudioDevices from '../../util/recording/useAvailableAudioDevices'
import ClosableDialog from '../ClosableDialog'
import MicrophonePermissionDialog from './MicrophonePermissionDialog'

const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent)

export type DeviceOptions = {
  deviceId?: string
  label?: string
  browserAudio?: boolean
}

type SelectAudioDeviceDialogProps = {
  open: boolean
  disabled?: boolean
  dictation?: boolean
  onClose: () => void
  onDeviceSelected: (device?: DeviceOptions) => void
}

const SelectAudioDeviceDialog = ({
  open,
  disabled,
  dictation,
  onClose,
  onDeviceSelected,
}: SelectAudioDeviceDialogProps) => {
  const { devices, permissionState, requestPermission } = useAvailableAudioDevices()
  const [selectedDevice, setSelectedDevice] = useState<MediaDeviceInfo | null>(null)
  const [useDesktopAudio, setUseDesktopAudio] = useState(false)
  const isMobile = useMemo(() => getIsMobile(), [])

  const handleSubmit = useCallback(async () => {
    onDeviceSelected({
      deviceId: selectedDevice?.deviceId,
      label: selectedDevice?.label,
      browserAudio: useDesktopAudio,
    })
  }, [selectedDevice, onDeviceSelected, useDesktopAudio])

  useEffect(() => {
    if (!open) return
    if (isMobile) return
    requestPermission()
  }, [open, requestPermission, isMobile])

  if (open && permissionState === 'denied') {
    return <MicrophonePermissionDialog open={true} onClose={onClose} />
  }

  return (
    <ClosableDialog open={open} onClose={onClose} titleText="Confirm Start Session">
      <DialogContent>
        {!isMobile && !dictation && (
          <Box sx={{ mb: 2 }}>
            <FormControlLabel
              sx={{ mt: 2 }}
              control={
                <Checkbox
                  checked={useDesktopAudio}
                  onChange={(e) => setUseDesktopAudio(e.target.checked)}
                />
              }
              label={<Typography variant="body2">This is a Telehealth session</Typography>}
            />
            {isSafari ? (
              <Typography variant="body2" color="text.secondary">
                Safari does not support sharing your computer's audio. If you would like to use
                audio from a telehealth session, please use a different browser like chrome.
              </Typography>
            ) : (
              <Typography variant="body2" color="text.secondary">
                You will be prompted to share the tab or screen that your Telehealth session is in.
              </Typography>
            )}
            {!isSafari && (
              <Collapse in={useDesktopAudio}>
                <Box>
                  <Typography variant="body2" color="text.primary" fontWeight={500} sx={{ mt: 2 }}>
                    Ensure that the share audio option is available and checked at the bottom right.
                    <br />
                    <br />
                    If you share a window the session will not capture.
                  </Typography>
                  <img
                    src="/share-tab-screenshot.png"
                    alt="Share Tab Screenshot"
                    style={{
                      height: '300px',
                      marginTop: 16,
                      borderRadius: 4,
                      boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.2)',
                    }}
                  />
                </Box>
              </Collapse>
            )}
          </Box>
        )}
        {/* 
        {devices.length !== 0 && (
          <TextAccordion
            id="select-audio-device"
            title={!selectedDevice ? `Use Default Audio` : 'Select Audio Device'}
          >
            <Grid container spacing={2}>
              {devices.some((d) => !!d.label) &&
                devices.map((device) => (
                  <Grid item key={device.deviceId} xs={12}>
                    <Button
                      variant={selectedDevice?.deviceId === device.deviceId ? 'contained' : 'text'}
                      color="primary"
                      onClick={() => setSelectedDevice(device)}
                      fullWidth
                    >
                      {device.label}
                    </Button>
                  </Grid>
                ))}
            </Grid>
          </TextAccordion>
        )} */}
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="primary" onClick={handleSubmit} disabled={disabled}>
          Start Session
        </Button>
      </DialogActions>
    </ClosableDialog>
  )
}

export default SelectAudioDeviceDialog
