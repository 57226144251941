import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import Divider from '@mui/material/Divider'
import Typography from '@mui/material/Typography'
import { alpha, useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'

type PricingCardProps = {
  children?: React.ReactNode
  title: string
  price?: number
  period?: 'mo' | 'yr'
  priceText?: string
  description: string
  features: string[]
  buttonText?: string
  buttonVariant?: 'text' | 'outlined' | 'contained'
  buttonColor?: 'primary' | 'secondary'
  headerText?: string
  headerColor?: 'primary' | 'secondary'
  onClick?: () => void
  active?: boolean
  disabled?: boolean
  elevation?: number
}

const PricingCard = ({
  children,
  title,
  price,
  period,
  priceText,
  description,
  features,
  buttonText,
  buttonVariant,
  buttonColor = 'primary',
  headerText,
  headerColor = 'secondary',
  onClick,
  active,
  disabled,
  elevation = 4,
}: PricingCardProps) => {
  const theme = useTheme()
  const xs = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        height: '100%',
      }}
    >
      {(!xs || headerText) && <Box sx={{ height: 40 }} />}
      <Card
        sx={{
          p: 2,
          flexGrow: 1,
          position: 'relative',
          overflow: 'visible',
          outline: active
            ? alpha(theme.palette.secondary.main, 0.4)
            : elevation
              ? 'none'
              : alpha(theme.palette.action.disabled, 0.1),
          boxShadow: active ? `0 0 0 4px ${alpha(theme.palette.secondary.main, 0.2)}` : elevation,
          outlineWidth: 2,
          outlineOffset: -2,
          outlineStyle: active || !elevation ? 'solid' : 'none',
          cursor: onClick && !buttonText ? 'pointer' : 'default',
        }}
        elevation={elevation}
        onClick={!buttonText ? onClick : undefined}
      >
        {headerText && (
          <Box
            sx={{
              position: 'absolute',
              top: -40,
              left: '10%',
              width: '80%',
              height: 40,
              backgroundColor: `${headerColor}.main`,
              color: 'common.white',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              borderTopLeftRadius: theme.shape.borderRadius,
              borderTopRightRadius: theme.shape.borderRadius,
            }}
          >
            <Typography variant="body1" fontWeight={700} textAlign="center">
              {headerText}
            </Typography>
          </Box>
        )}
        <Typography
          variant="body1"
          sx={{ fontSize: 20 }}
          fontWeight={700}
          color="text.primary"
          gutterBottom
        >
          {title}
        </Typography>
        {price !== undefined && (
          <Box sx={{ height: 30 }}>
            {price !== undefined && period && (
              <Typography component="span" variant="h4" fontWeight={600}>
                ${price}
                <Typography component="span" variant="body1">
                  /{period}
                </Typography>
              </Typography>
            )}
            {priceText && (
              <Typography component="span" variant="body2" color="text.secondary">
                {priceText}
              </Typography>
            )}
          </Box>
        )}
        <Divider sx={{ my: 2 }} />
        <Typography sx={{ mb: 1, height: 60 }} color="text.secondary">
          {description.split('\n').map((item, index) => (
            <Typography component="span" key={index} variant="body1">
              {item}
              <br />
            </Typography>
          ))}
        </Typography>

        {buttonText && buttonVariant ? (
          <Button
            fullWidth
            variant={buttonVariant}
            onClick={onClick}
            color={buttonColor}
            disabled={disabled}
            sx={{ opacity: disabled ? 0.5 : 1 }}
          >
            {buttonText}
          </Button>
        ) : (
          <></>
        )}
        {children}
        <Typography
          sx={{ mt: 3, fontWeight: 500, fontSize: 18 }}
          color="text.primary"
          variant="body1"
        >
          Features included
        </Typography>
        <Typography component="ul" variant="body1">
          {features.map((item, index) => (
            <Typography component="li" variant="body1" key={index} sx={{ mt: 1, ml: -2 }}>
              {item}
            </Typography>
          ))}
        </Typography>
      </Card>
    </Box>
  )
}

export default PricingCard
