import { RUser, UserSortableFields } from '@counsel-project/counsel-auth-api'
import useMediaQuery from '@mui/material/useMediaQuery'
import TableBase from './TableBase'
import UserRow from './UserRow'

export type UserTableProps = {
  searchDisabled?: boolean
  rows: RUser[]
  page: number
  rowsPerPage: number
  rowsPerPageOptions: number[]
  total: number
  loaded: boolean
  sort: UserSortableFields
  direction: 'asc' | 'desc'
  onChangeSort?: (sort: UserSortableFields, direction: 'asc' | 'desc') => void
  onChangePage?: (page: number) => void
  onChangeRowsPerPage?: (rowsPerPage: number) => void
  onClickRow?: (row: RUser) => void
  onSubmitSearch?: (search: string) => void
}

const UserTable = ({
  searchDisabled,
  rows,
  page,
  rowsPerPage,
  rowsPerPageOptions,
  total,
  loaded,
  sort,
  direction,
  onChangeSort,
  onChangePage,
  onChangeRowsPerPage,
  onClickRow,
  onSubmitSearch,
}: UserTableProps) => {
  const isMobile = useMediaQuery('(max-width: 600px)')

  return (
    <TableBase
      searchDisabled={searchDisabled}
      columns={[
        { field: 'email', label: 'Email', sortable: true, align: 'left' },
        {
          field: 'lastLoggedIn',
          label: 'Last Logged In',
          sortable: true,
          align: 'right',
          hidden: isMobile,
        },
        {
          field: '_id',
          label: 'ID',
          sortable: true,
          align: 'right',
          hidden: isMobile,
        },
      ]}
      sort={sort}
      direction={direction}
      onChangeSort={onChangeSort}
      rows={rows}
      renderRow={(row: RUser) => (
        <UserRow key={row._id} isMobile={isMobile} data={row} onClick={onClickRow} />
      )}
      page={page}
      rowsPerPage={rowsPerPage}
      rowsPerPageOptions={rowsPerPageOptions}
      total={total}
      loaded={loaded}
      onChangePage={onChangePage}
      onChangeRowsPerPage={onChangeRowsPerPage}
      onSubmitSearch={onSubmitSearch}
    />
  )
}

export default UserTable
