import { RIntegrationPatient } from '@counsel-project/counsel-ehr-api'
import Typography from '@mui/material/Typography'
import StyledTableCell from './StyledTableCell'
import StyledTableRow from './StyledTableRow'

export type IntegrationPatientRowProps = {
  isMobile?: boolean
  data: RIntegrationPatient
  onClick?: (data: RIntegrationPatient) => void
}

const IntegrationPatientRow = ({ isMobile, data, onClick }: IntegrationPatientRowProps) => {
  const { name, metadata } = data

  return (
    <StyledTableRow onClick={() => onClick?.(data)}>
      <StyledTableCell
        sx={{
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          maxWidth: 400,
        }}
      >
        <Typography
          variant="subtitle2"
          textOverflow="ellipsis"
          overflow="hidden"
          whiteSpace="nowrap"
        >
          {name}
        </Typography>
      </StyledTableCell>
      {!isMobile && (
        <StyledTableCell align="right">
          <Typography
            variant="subtitle2"
            textOverflow="ellipsis"
            overflow="hidden"
            whiteSpace="nowrap"
          >
            {typeof metadata?.mrNumber === 'string' ? metadata?.mrNumber : '-'}
          </Typography>
        </StyledTableCell>
      )}
    </StyledTableRow>
  )
}

export default IntegrationPatientRow
