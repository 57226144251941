import { RLicense, RUser } from '@counsel-project/counsel-auth-api'
import { RLayout } from '@counsel-project/counsel-transcribe-api/dist/common/database/RLayout'
import { isApiError } from '@counsel-project/client-utils'
import { StateCreator } from 'zustand'
import { authRequest } from '../../api/auth-api'
import { transcribeRequest } from '../../api/transcribe-api'
import checkToken from '../../auth/checkToken'
import log from '../../logging'

export type AuthStoreValues = {
  user: RUser | null
  activeLicense: RLicense | null
  layouts: RLayout[]
  cookiesConsent: boolean
}

export type AuthStore = AuthStoreValues & {
  getIsLoggedIn: () => boolean
  setUser: (user: RUser | null) => void
  populateActiveLicense: () => Promise<void>
  setActiveLicense: (license: RLicense | null) => void
  setLayouts: (layouts: RLayout[]) => void
  populateLayouts: (force?: boolean) => Promise<void>
  setCookiesConsent: (consent: boolean) => void
}

const createAuthSlice: StateCreator<AuthStore> = (set, get) => ({
  user: null,
  getIsLoggedIn: () => {
    const { user } = get()
    if (!user) return false
    return true
  },
  setUser: (user: RUser | null) => set({ user }),
  activeLicense: null,
  setActiveLicense: (license: RLicense | null) => set({ activeLicense: license }),
  populateActiveLicense: async () => {
    try {
      const loggedIn = await checkToken(true)
      if (!loggedIn) return

      const res = await authRequest.user.licenses.getActive({ token: '' })
      set({ activeLicense: res.license })
    } catch (err) {
      log.error(err)
      if (isApiError(err)) {
        if (err.msg === 'No active license found') {
          set({ activeLicense: null })
        }
      }
    }
  },
  layouts: [],
  instructions: [],
  cookiesConsent: false,
  setLayouts: (layouts: RLayout[]) => set({ layouts }),
  populateLayouts: async (force?: boolean) => {
    try {
      if (get().layouts.length !== 0 && !force) return

      const loggedIn = await checkToken(true)
      console.log('loggedIn', loggedIn)
      if (!loggedIn) return

      const { results } = await transcribeRequest.sessions.generate.getOptions({ token: '' })
      set({ layouts: results })
    } catch (err) {
      log.error(err)
    }
  },
  setCookiesConsent: (consent: boolean) => set({ cookiesConsent: consent }),
})

export default createAuthSlice
