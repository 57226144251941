import { InputField } from '@counsel-project/counsel-generation-api'
import Grid from '@mui/material/Grid'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import Typography from '@mui/material/Typography'
import { useEffect, useMemo, useState } from 'react'
import CopyButton from './CopyButton'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import InfoIcon from '@mui/icons-material/Info'

type SingleChoiceTemplateFieldProps = {
  inputField: InputField
  value: string[]
  onChange?: ({ id, value }: { id: string; value: string[] }) => void
}

const SingleChoiceTemplateField = ({
  inputField,
  value,
  onChange,
}: SingleChoiceTemplateFieldProps) => {
  const { id, name, type, instructions, options: inputFieldOptions, condition } = inputField

  const [answer, setAnswer] = useState<string>('')
  const [options, setOptions] = useState<string[]>([])

  useEffect(() => {
    if (type === 'single_choice' && inputFieldOptions.length > 0) {
      setOptions(inputFieldOptions)
    }
  }, [type, inputFieldOptions])

  useEffect(() => {
    if (value[0]) {
      setAnswer(value[0])
    }
  }, [value])

  const conditionText = useMemo(() => {
    if (condition?.contains) return `Only present if ${condition.id} contains ${condition.contains}`
    if (condition?.equals) return `Only present if ${condition.id} equals ${condition.equals}`
    return null
  }, [condition])

  return (
    <>
      <Grid container sx={{ mb: 1 }}>
        <Grid item xs>
          <Typography variant="body1" fontWeight={500}>
            {name}
          </Typography>
        </Grid>
        {condition && (
          <Grid item>
            <Tooltip title={conditionText}>
              <IconButton size="small" color="secondary">
                <InfoIcon />
              </IconButton>
            </Tooltip>
          </Grid>
        )}
        <Grid item>
          <CopyButton text={answer} />
        </Grid>
      </Grid>
      <Select
        fullWidth
        placeholder={instructions?.[0] || name}
        value={answer.toString()}
        onChange={(e) => {
          const option = options.find((option) => option.toString() === e.target.value)
          if (!option) return
          setAnswer(option)
          onChange?.({ id, value: [option] })
        }}
      >
        {options.map((option, index) => (
          <MenuItem key={`${id}-${index}`} value={option.toString()}>
            {option.toString()}
          </MenuItem>
        ))}
      </Select>
    </>
  )
}

export default SingleChoiceTemplateField
