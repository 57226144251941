import Typography from '@mui/material/Typography'
import StyledTableCell from '../../components/tables/StyledTableCell'
import StyledTableRow from '../../components/tables/StyledTableRow'
import { MappedMetric } from './OrganizationStatsTable'
import { formatDuration } from '../../util'

export type OrganizationStatsRowProps = {
  isMobile?: boolean
  data: MappedMetric
  onClick?: (data: MappedMetric) => void
}

const OrganizationStatsRow = ({ isMobile, data, onClick }: OrganizationStatsRowProps) => {
  const { email, userId, ...options } = data

  return (
    <StyledTableRow onClick={() => onClick?.(data)}>
      <StyledTableCell
        sx={{
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          maxWidth: 400,
        }}
      >
        <Typography
          variant="subtitle2"
          textOverflow="ellipsis"
          overflow="hidden"
          whiteSpace="nowrap"
          color="primary.main"
        >
          {email}
        </Typography>
      </StyledTableCell>
      {Object.entries(options).map(([key, [value, aggregate]]) => (
        <StyledTableCell align="right" key={key}>
          <Typography
            variant="subtitle2"
            textOverflow="ellipsis"
            overflow="hidden"
            whiteSpace="nowrap"
            color="inherit"
          >
            {value}
            {aggregate !== value ? ` (${formatDuration(Number(aggregate))})` : ''}
          </Typography>
        </StyledTableCell>
      ))}
    </StyledTableRow>
  )
}

export default OrganizationStatsRow
