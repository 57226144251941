import { RTranscriptLog } from '@counsel-project/counsel-transcribe-api'
import Typography from '@mui/material/Typography'
import StyledTableCell from './StyledTableCell'
import StyledTableRow from './StyledTableRow'

export type TranscriptLogRowProps = {
  isMobile?: boolean
  data: Omit<RTranscriptLog, 'transcript'>
  onClick?: (data: Omit<RTranscriptLog, 'transcript'>) => void
}

const TranscriptLogRow = ({ isMobile, data, onClick }: TranscriptLogRowProps) => {
  const { email, type, duration } = data

  return (
    <StyledTableRow onClick={() => onClick?.(data)}>
      <StyledTableCell
        sx={{
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          maxWidth: 400,
        }}
      >
        <Typography
          variant="subtitle2"
          textOverflow="ellipsis"
          overflow="hidden"
          whiteSpace="nowrap"
        >
          {email}
        </Typography>
      </StyledTableCell>
      <StyledTableCell
        align="right"
        sx={{
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          maxWidth: 400,
        }}
      >
        <Typography
          variant="subtitle2"
          textOverflow="ellipsis"
          overflow="hidden"
          whiteSpace="nowrap"
        >
          {type}
        </Typography>
      </StyledTableCell>
      {!isMobile && (
        <StyledTableCell
          align="right"
          sx={{
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            maxWidth: 400,
          }}
        >
          <Typography
            variant="subtitle2"
            textOverflow="ellipsis"
            overflow="hidden"
            whiteSpace="nowrap"
          >
            {duration}
          </Typography>
        </StyledTableCell>
      )}
    </StyledTableRow>
  )
}

export default TranscriptLogRow
