import EditorJS from '@editorjs/editorjs'
import Box from '@mui/material/Box'
import { useEffect, useState } from 'react'
import OverlayLoader from '../../routes/builder/OverlayLoader'
import Bubbles from '../loaders/Bubbles'
import { getMinimalTools } from './minimal-tools'
import blocksToMarkdown from './parser/blocksToMarkdown'
import markdownToBlocks from './parser/markdownToBlocks'
import { getTemplateEditorTools } from './template-tools'
import { getEditorTools } from './tools'
import './tools.css'

type BlockEditorProps = {
  id: string
  defaultValue: string // Markdown string
  includeTemplateOptions?: boolean
  minimal?: boolean
  readOnly?: boolean
  placeholder?: string
  loading?: boolean
  onEmail: (text: string) => void
  onCreateNewNote: (text: string) => void
  onSave: (markdown: string) => void // Markdown string
  onReady?: () => void
  onSaving?: (saving: boolean) => void
}

const BlockEditorBase = ({
  id,
  defaultValue,
  includeTemplateOptions,
  minimal,
  readOnly,
  placeholder,
  onSave,
  onReady,
  onSaving,
  onEmail,
  onCreateNewNote,
}: BlockEditorProps) => {
  const [savingVersion, setSavingVersion] = useState(0)
  const [editor, setEditor] = useState<EditorJS | null>(null)

  useEffect(() => {
    const idElement = document.getElementById(id)
    if (!idElement) return

    const timeout = setTimeout(() => {
      const blocks = markdownToBlocks(defaultValue, includeTemplateOptions)

      const newEditor = new EditorJS({
        defaultBlock: 'paragraph',
        readOnly,
        holder: id,
        tools: !includeTemplateOptions
          ? minimal
            ? getMinimalTools()
            : getEditorTools({
                onEmail,
                onCreateNewNote,
              })
          : getTemplateEditorTools({
              onEmail,
              onCreateNewNote,
            }),
        data: {
          blocks: [...blocks],
        },
        placeholder: placeholder || 'Start writing...',
        onChange: async (api, event) => {
          try {
            onSaving?.(true)

            const output = await api.saver.save()

            const markdown = blocksToMarkdown(output.blocks, includeTemplateOptions)

            onSave?.(markdown)
          } catch (err) {
            console.error(err)
          } finally {
            onSaving?.(false)
          }
        },
        onReady: () => {
          onReady?.()
        },
      })

      newEditor.isReady.then(() => {
        setEditor(newEditor)
      })
    }, 10)

    return () => clearTimeout(timeout)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (!editor) return

    return () => {
      if (!('isReady' in editor)) return
      editor.isReady.then(() => {
        editor.destroy()
        setEditor(null)
      })
    }
  }, [editor])

  useEffect(() => {
    const idElement = document.getElementById(id)
    if (!idElement) return

    const onKeyDown = (e: KeyboardEvent) => {
      // If arrow keys, don't trigger saving
      if (e.key.startsWith('Arrow')) return
      // If control key, don't trigger saving
      if (e.ctrlKey || e.metaKey) return
      // If shift key, don't trigger saving
      if (e.shiftKey) return
      // If alt key, don't trigger saving
      if (e.altKey) return
      // If escape key, don't trigger saving
      if (e.key === 'Escape') return
      onSaving?.(true)
      setSavingVersion((v) => v + 1)
    }

    idElement.addEventListener('keydown', onKeyDown)

    return () => {
      idElement.removeEventListener('keydown', onKeyDown)
    }
  }, [id, onSaving])

  useEffect(() => {
    const timeout = setTimeout(() => {
      onSaving?.(false)
    }, 3000)
    return () => clearTimeout(timeout)
  }, [savingVersion, onSaving])

  return <div id={id} />
}

const BlockEditor = ({
  id,
  defaultValue,
  includeTemplateOptions,
  minimal,
  readOnly,
  placeholder,
  loading,
  onSave,
  onReady,
  onSaving,
  onEmail,
  onCreateNewNote,
}: BlockEditorProps) => {
  const [render, setRender] = useState(false)
  const [stagedId, setStagedId] = useState(id)
  const [stagedDefaultValue, setStagedDefaultValue] = useState(defaultValue)
  const [stagedIncludeTemplateOptions, setStagedIncludeTemplateOptions] =
    useState(includeTemplateOptions)
  const [stagedMinimal, setStagedMinimal] = useState(minimal)
  const [stagedReadOnly, setStagedReadOnly] = useState(readOnly)
  const [stagedPlaceholder, setStagedPlaceholder] = useState(placeholder)

  useEffect(() => {
    setRender(false)
    const timeout = setTimeout(() => {
      setStagedId(id)
      setStagedDefaultValue(defaultValue)
      setStagedIncludeTemplateOptions(includeTemplateOptions)
      setStagedMinimal(minimal)
      setStagedReadOnly(readOnly)
      setStagedPlaceholder(placeholder)
      setRender(true)
    }, 200)
    return () => clearTimeout(timeout)
  }, [id, defaultValue, includeTemplateOptions, minimal, readOnly, placeholder])

  return (
    <Box
      sx={{
        width: '100%',
        minHeight: '200px',
        px: { xs: 2, sm: 4 },
        py: { xs: 1, sm: 2 },
        position: 'relative',
      }}
    >
      {(!render || loading) && (
        <OverlayLoader absolute loaded>
          <Bubbles variant="draft" />
        </OverlayLoader>
      )}
      {render && (
        <BlockEditorBase
          id={stagedId}
          defaultValue={stagedDefaultValue}
          includeTemplateOptions={stagedIncludeTemplateOptions}
          minimal={stagedMinimal}
          readOnly={stagedReadOnly}
          placeholder={stagedPlaceholder}
          onSave={onSave}
          onReady={onReady}
          onSaving={onSaving}
          onEmail={onEmail}
          onCreateNewNote={onCreateNewNote}
        />
      )}
    </Box>
  )
}

export default BlockEditor
