import RedoIcon from '@mui/icons-material/RedoRounded'
import UndoIcon from '@mui/icons-material/UndoRounded'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import { useEffect, useState } from 'react'

export type RevisionSectionProps = {
  noteVersions?: string[]
  note: string
  savedNote: string
  onChangeNote: (note: string) => void
  onSave: () => void
  disabled?: boolean
}

const RevisionSection = ({
  noteVersions,
  note,
  savedNote,
  onChangeNote,
  onSave,
  disabled,
}: RevisionSectionProps) => {
  const [newNoteVersions, setNewNoteVersions] = useState<string[]>([])

  useEffect(() => {
    if (!noteVersions || !savedNote) {
      setNewNoteVersions([])
      return
    }

    setNewNoteVersions((prev) => [
      ...prev.filter((p) => p !== savedNote && !noteVersions.includes(p)),
      ...noteVersions.filter((p) => p !== savedNote),
      savedNote,
    ])
  }, [savedNote, noteVersions])

  const currentNoteIndex = newNoteVersions.indexOf(note)

  const handleUndo = () => {
    if (currentNoteIndex === -1) return
    if (currentNoteIndex === 0) return
    const newNote = newNoteVersions[currentNoteIndex - 1]
    if (!newNote || newNote === note) return
    onChangeNote(newNote)
  }

  const handleRedo = () => {
    if (currentNoteIndex === -1) return
    if (currentNoteIndex === newNoteVersions.length - 1) return
    const newNote = newNoteVersions[currentNoteIndex + 1]
    if (!newNote || newNote === note) return
    onChangeNote(newNote)
  }

  if (currentNoteIndex === -1) return null

  return (
    <Grid container spacing={2}>
      <Grid item>
        <Button
          startIcon={<UndoIcon />}
          disabled={currentNoteIndex === 0 || disabled}
          onClick={handleUndo}
        >
          Undo
        </Button>
      </Grid>
      <Grid item onClick={handleRedo}>
        <Button
          endIcon={<RedoIcon />}
          disabled={currentNoteIndex === newNoteVersions.length - 1 || disabled}
        >
          Redo
        </Button>
      </Grid>
      <Grid item>
        <Button color="secondary" onClick={onSave} disabled={disabled}>
          Save
        </Button>
      </Grid>
    </Grid>
  )
}

export default RevisionSection
