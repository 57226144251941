import React, { useState, useEffect, useCallback } from 'react'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import useUser from '../../util/auth/useUser'
import handleError from '../../util/handleError'
import checkToken from '../../util/auth/checkToken'
import { authRequest } from '../../util/api/auth-api'
import toast from 'react-hot-toast'
import Grid from '@mui/material/Grid'

const sources = [
  {
    name: 'My Company',
    id: 'company',
  },
  {
    name: 'Friend/Colleague',
    id: 'friend_colleague',
  },
  {
    name: 'Facebook Group',
    id: 'facebook_group',
  },
  {
    name: 'LinkedIn',
    id: 'linkedin',
  },
  {
    name: 'Facebook Ad',
    id: 'facebook_ad',
  },
  {
    name: 'Instagram Ad',
    id: 'instagram_ad',
  },
  {
    name: 'Youtube',
    id: 'youtube',
  },
  {
    name: 'Google',
    id: 'google',
  },
  {
    name: 'Conference',
    id: 'conference',
  },
  {
    name: 'Other',
    id: 'other',
  },
]

const AcquisitionSourceDialog = () => {
  const [user, setUser] = useUser()

  const [loading, setLoading] = useState(false)
  const [open, setOpen] = useState(!user?.acquisitionSource && !!user)

  const handleSetUserAcquisitionSource = useCallback(
    async (acquisitionSource: string) => {
      try {
        setLoading(true)

        await checkToken()

        const res = await authRequest.user.account.update({
          token: '',
          acquisitionSource,
        })

        setUser(res.user)
        setOpen(false)
        toast.success('Thanks for your feedback!')
      } catch (err) {
        handleError(err)
      } finally {
        setLoading(false)
      }
    },
    [setUser]
  )

  return (
    <Dialog open={open && !!user} onClose={() => setOpen(false)}>
      <DialogTitle>
        <Typography variant="h6" sx={{ mt: 1 }} textAlign="center">
          How did you hear about us?
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Typography variant="body1" sx={{ mt: 1, mb: 2 }} textAlign="center" color="text.secondary">
          I heard about Clinical Notes AI from:
        </Typography>
        <Grid container spacing={1}>
          {sources.map((source) => (
            <Grid item key={source.id} xs={source.id === 'other' ? 12 : 6}>
              <Button
                onClick={() => handleSetUserAcquisitionSource(source.id)}
                disabled={loading}
                fullWidth
              >
                {source.name}
              </Button>
            </Grid>
          ))}
        </Grid>
      </DialogContent>
    </Dialog>
  )
}

export default AcquisitionSourceDialog
