import { useCallback, useMemo } from 'react'
import Breadcrumbs from '../../../components/Breadcrumbs'
import PageContainer from '../../../components/layout/PageContainer'
import { createSearchParams } from '../common'
import { useSearchParams } from 'react-router-dom'
import Typography from '@mui/material/Typography'

type LayoutPageContainerProps = {
  title: string
  children: React.ReactNode
}

const LayoutPageContainer = ({ title, children }: LayoutPageContainerProps) => {
  const [searchParams] = useSearchParams()

  const getBuilderBackPath = useCallback(() => {
    const sessionId = searchParams.get('sessionId')
    const shownSessionIds = searchParams.get('shownSessionIds')
    const tutorial = searchParams.get('tutorial')

    const queryText = createSearchParams({
      sessionId,
      shownSessionIds,
      tutorial,
    })

    return `/builder${queryText}`
  }, [searchParams])

  const breadcrumbs = useMemo(() => {
    return [{ name: 'Builder', path: getBuilderBackPath() }, { name: 'Generate' }]
  }, [getBuilderBackPath])

  return (
    <PageContainer>
      <Breadcrumbs paths={breadcrumbs} />
      <Typography variant="h6" fontWeight={500} sx={{ mb: 2 }}>
        {title}
      </Typography>
      {children}
    </PageContainer>
  )
}

export default LayoutPageContainer
