import {
  InputField,
  OtherInputField,
  OutputField,
  TableInputField,
} from '@counsel-project/counsel-generation-api'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import MultiChoiceTemplateField from './MultiChoiceTemplateField'
import SingleChoiceTemplateField from './SingleChoiceTemplateField'
import StringTemplateField from './StringTemplateField'
import TableTemplateField from './TableTemplateField'
import Pagination from '@mui/material/Pagination'
import Typography from '@mui/material/Typography'
import BooleanTemplateField from './BooleanTemplateField'

type TemplateFieldProps = {
  inputField: InputField
  outputFields: OutputField[]
  onChange?: ({ id, value }: { id: string; value: string[] }) => void
}

const TemplateField = ({ inputField, outputFields, onChange }: TemplateFieldProps) => {
  const ref = useRef<HTMLDivElement>(null)

  const [render, setRender] = useState(false)

  const [stagedValue, setStagedValue] = useState<string[]>(
    outputFields.find((of) => of.id === inputField.id)?.value || []
  )

  useEffect(() => {
    if (render) return
    if (!ref.current) return

    const callback = (entries: IntersectionObserverEntry[]) => {
      setRender((prev) => (prev ? prev : entries[0].isIntersecting))
    }

    const observer = new IntersectionObserver(callback)
    observer.observe(ref.current)

    return () => {
      observer?.disconnect()
    }
  }, [render])

  useEffect(() => {
    const timeout = setTimeout(() => {
      onChange?.({
        id: inputField.id,
        value: stagedValue,
      })
    }, 500)
    return () => clearTimeout(timeout)
  }, [stagedValue, onChange, inputField.id])

  const handleSetStagedValue = useCallback(({ value }: { value: string[] }) => {
    setStagedValue(value)
  }, [])

  const { type, condition } = inputField

  if (condition && condition.id) {
    const foundOutputField = outputFields.find((of) => of.id === condition.id)

    if (!foundOutputField) {
      return null
    }

    if (condition.contains) {
      if (!foundOutputField.value.includes(condition.contains)) {
        return null
      }
    } else if (condition.equals) {
      if (foundOutputField.value?.[0] !== condition.equals) {
        return null
      }
    }
  }

  if (!render) return <Box ref={ref} sx={{ width: '100%', height: 340 }}></Box>

  if (type === 'single_choice') {
    // Select
    return (
      <SingleChoiceTemplateField
        inputField={inputField}
        value={stagedValue}
        onChange={handleSetStagedValue}
      />
    )
  } else if (type === 'multiple_choice') {
    // MultiSelect
    return (
      <MultiChoiceTemplateField
        inputField={inputField}
        value={stagedValue}
        onChange={handleSetStagedValue}
      />
    )
  } else if (type === 'string') {
    // Text
    return (
      <StringTemplateField
        inputField={inputField}
        value={stagedValue}
        onChange={handleSetStagedValue}
      />
    )
  } else if (type === 'table') {
    // Table
    return (
      <TableTemplateField
        inputField={inputField as TableInputField}
        value={stagedValue}
        onChange={handleSetStagedValue}
      />
    )
  } else if (type === 'boolean') {
    // Boolean field
    return (
      <BooleanTemplateField
        inputField={inputField as OtherInputField}
        value={stagedValue}
        onChange={handleSetStagedValue}
      />
    )
  }

  return (
    <Typography variant="body1" color="error">
      Invalid field type
    </Typography>
  )
}

const conditionFilter = (inputField: InputField, outputFields: OutputField[]) => {
  const { condition } = inputField

  if (condition && condition.id) {
    const foundOutputField = outputFields.find((of) => of.id === condition.id)

    if (!foundOutputField) {
      return false
    }

    if (condition.contains) {
      if (!foundOutputField.value.includes(condition.contains)) {
        return false
      }
    } else if (condition.equals) {
      if (foundOutputField.value?.[0] !== condition.equals) {
        return false
      }
    }
  }

  return true
}

type TemplateFieldsProps = {
  inputFields: InputField[]
  outputFields?: OutputField[]
  onChange: (func: (prev: OutputField[]) => OutputField[]) => void
  maxPerPage?: number
}

const TemplateFields = ({
  inputFields,
  outputFields = [],
  onChange,
  maxPerPage = 140,
}: TemplateFieldsProps) => {
  const [page, setPage] = useState(0)

  const shownFields = useMemo(() => {
    if (inputFields.length <= maxPerPage) {
      return inputFields.filter((inputField) => conditionFilter(inputField, outputFields))
    }

    return inputFields
      .slice(page * maxPerPage, (page + 1) * maxPerPage)
      .filter((inputField) => conditionFilter(inputField, outputFields))
  }, [inputFields, page, maxPerPage, outputFields])

  const maxPage = useMemo(
    () => Math.ceil(inputFields.length / maxPerPage),
    [inputFields, maxPerPage]
  )

  const handleChange = useCallback(
    ({ id, value }: { id: string; value: string[] }) => {
      const inputField = inputFields.find((inputField) => inputField.id === id)

      if (!inputField) {
        return
      }

      onChange((prev) => {
        if (!prev) {
          return [
            {
              id,
              name: inputField.name,
              type: inputField.type,
              value,
            },
          ]
        }
        const newOutputFields = [...prev]
        const foundOutputField = newOutputFields.find((of) => of.id === id)
        if (foundOutputField) {
          foundOutputField.value = value
        } else {
          newOutputFields.push({
            id,
            name: inputField.name,
            type: inputField.type,
            value,
          })
        }
        return newOutputFields
      })
    },
    [onChange, inputFields]
  )

  return (
    <Box>
      {inputFields.length > maxPerPage && (
        <Pagination
          sx={{ mb: 1 }}
          count={maxPage}
          page={page + 1}
          onChange={(_, newPage) => setPage(newPage - 1)}
        />
      )}
      <Grid container spacing={2}>
        {shownFields.map((inputField) => (
          <Grid key={inputField.id} item xs={12}>
            <TemplateField
              inputField={inputField}
              outputFields={outputFields}
              onChange={handleChange}
            />
          </Grid>
        ))}
      </Grid>
      {inputFields.length > maxPerPage && (
        <Pagination
          sx={{ mt: 1 }}
          count={maxPage}
          page={page + 1}
          onChange={(_, newPage) => setPage(newPage - 1)}
        />
      )}
    </Box>
  )
}

export default TemplateFields
