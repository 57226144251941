import { InputField } from '@counsel-project/counsel-generation-api'

type ExampleFormLayout = {
  name: string
  description: string
  identifier: string
  fields: InputField[]
}

const EXAMPLE_FORM_LAYOUTS: ExampleFormLayout[] = [
  {
    name: 'Simple Note',
    description: 'Generic note template.',
    identifier: 'generic-example',
    fields: [
      {
        id: 'client_name',
        name: 'Client Name',
        type: 'string',
        instructions: "Populate this with the client's name.",
      },
      {
        id: 'client_age',
        name: 'Client Age',
        type: 'number',
        instructions: "Populate this with the client's age.",
      },
      {
        id: 'insurance_information',
        name: 'Insurance Information',
        type: 'string',
        instructions: "Populate this with the client's insurance information.",
      },
      {
        id: 'overview',
        name: 'Overview',
        type: 'string',
        instructions:
          "Capture an overview of the client's current problems and interventions used during the session. Tie this to the treatment plan if it is provided.",
      },
    ],
  },
  {
    name: 'SOAP Note',
    description: 'Generic soap note template.',
    identifier: 'soap-example',
    fields: [
      {
        id: 'subjective',
        name: 'Subjective',
        type: 'string',
        instructions:
          "Include the client's subjective experience of their symptoms, including the onset, duration, and severity of symptoms, as well as any factors that exacerbate or alleviate symptoms.",
      },
      {
        id: 'objective',
        name: 'Objective',
        type: 'string',
        instructions:
          'Include objective data and symptoms, vital signs, physical exam findings, or lab results if mentioned.',
      },
      {
        id: 'assessment',
        name: 'Assessment',
        type: 'string',
        instructions: 'Include a diagnosis and any differential diagnoses.',
      },
      {
        id: 'plan',
        name: 'Plan',
        type: 'string',
        instructions:
          'Include a treatment plan, including medications, lifestyle modifications, and follow-up.',
      },
    ],
  },
  {
    name: 'DAP Note',
    description: 'Generic dap note template.',
    identifier: 'dap-example',
    fields: [
      {
        id: 'data',
        name: 'Data',
        type: 'string',
        instructions:
          "Include the client's subjective experience of their symptoms, including the onset, duration, and severity of symptoms, as well as any factors that exacerbate or alleviate symptoms.",
      },
      {
        id: 'assessment',
        name: 'Assessment',
        type: 'string',
        instructions: 'Include a diagnosis and any differential diagnoses.',
      },
      {
        id: 'plan',
        name: 'Plan',
        type: 'string',
        instructions:
          'Include a treatment plan, including medications, lifestyle modifications, and follow-up.',
      },
    ],
  },
]

export default EXAMPLE_FORM_LAYOUTS
