import { RPatient } from '@counsel-project/counsel-transcribe-api'
import Button from '@mui/material/Button'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import Typography from '@mui/material/Typography'
import React, { useState } from 'react'
import toast from 'react-hot-toast'
import { usePatientNomenclature } from '../../util'
import { transcribeRequest } from '../../util/api/transcribe-api'
import checkToken from '../../util/auth/checkToken'
import log from '../../util/logging'
import ClosableDialog from '../ClosableDialog'
import PatientSelector from '../PatientSelector'
import { refreshPatientsCache } from '../../util/api/transcribe-api-cached'
import { isApiError } from '@counsel-project/client-utils'
import handleError from '../../util/handleError'

type MoveSessionsDialogProps = {
  open: boolean
  onClose: () => void
  patientId: string
  onMoved?: () => void
}

const MoveSessionsDialog: React.FC<MoveSessionsDialogProps> = ({
  open,
  onClose,
  patientId,
  onMoved,
}) => {
  const [selectedPatient, setSelectedPatient] = useState<RPatient | null>(null)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<string | null>(null)

  const patientNomenclature = usePatientNomenclature()

  const handleMoveChange = async () => {
    try {
      if (!selectedPatient) return

      await checkToken()

      setLoading(true)
      setError(null)

      let patient = selectedPatient
      if (!selectedPatient._id) {
        // Create a new patient with this label
        const { result } = await transcribeRequest.patients.create({
          label: selectedPatient.label,
          token: '',
        })
        patient = result
        refreshPatientsCache()
      }

      await transcribeRequest.sessions.moveSessions({
        token: '',
        patientId,
        newPatientId: patient._id,
      })

      onClose()
      onMoved?.()
    } catch (err) {
      handleError(err)
      if (isApiError(err)) {
        setError(err.msg)
      }
    } finally {
      setLoading(false)
    }
  }

  return (
    <ClosableDialog open={open} onClose={onClose} titleText="Move Documents and Notes">
      <DialogContent>
        <Typography variant="body1">Enter new {patientNomenclature}</Typography>
        <PatientSelector value={selectedPatient} onChange={setSelectedPatient} />
        {error && <Typography color="error">{error}</Typography>}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleMoveChange}
          color="primary"
          variant="text"
          disabled={loading || !selectedPatient}
        >
          Move Documents & Notes
        </Button>
      </DialogActions>
    </ClosableDialog>
  )
}

export default MoveSessionsDialog
