import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { styled } from '@mui/material/styles'
import { useCallback } from 'react'

type FileInputContainerProps = {
  uploaded: boolean
  disabled?: boolean
}

const FileInputContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'uploaded' && prop !== 'disabled',
})<FileInputContainerProps>(({ theme, uploaded, disabled }) => ({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  padding: theme.spacing(2),
  backgroundColor: 'transparent',
  borderRadius: theme.spacing(1),
  border: !uploaded
    ? `2px dashed ${theme.palette.primary.main}`
    : `2px dashed ${theme.palette.divider}`,
  cursor: 'pointer',
  transition: 'background-color 0.3s',
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  },
  ...(disabled && {
    backgroundColor: theme.palette.action.selected,
    cursor: 'default',
    '&:hover': {
      backgroundColor: theme.palette.action.selected,
    },
  }),
}))

type FileInputProps = {
  disabled?: boolean
}

const FileInput = styled('input')<FileInputProps>(({ disabled }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  opacity: 0,
  cursor: 'pointer',
  ...(disabled && {
    cursor: 'default',
  }),
}))

type FileUploadProps = {
  title?: string
  subtitle?: string
  onFileChange: (file: File) => void
  accept?: string
  file: File | null
  disabled?: boolean
}

const FileUpload = ({
  title = 'Upload a file',
  subtitle = 'Drag and drop a file here, or click to select a file to upload.',
  accept,
  onFileChange,
  file,
  disabled,
}: FileUploadProps) => {
  const handleFileChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const files = event.target.files
      if (files && files.length > 0) {
        onFileChange(files[0])
      }
    },
    [onFileChange]
  )

  return (
    <FileInputContainer uploaded={!!file} disabled={disabled}>
      <Typography variant="h6" gutterBottom color={!!file ? 'text.secondary' : 'text.primary'}>
        {title}
      </Typography>
      <FileInput type="file" onChange={handleFileChange} accept={accept} disabled={disabled} />
      {!file && <Typography variant="body1">{subtitle}</Typography>}
      {!!file && (
        <Grid container justifyContent="center" alignItems="center" spacing={1}>
          <Grid item>
            <Box sx={{ display: 'flex', alignItems: 'center', maxWidth: 180 }}>
              <Typography textOverflow="ellipsis" noWrap color="text.secondary">
                {file.name}
              </Typography>
            </Box>
          </Grid>
          <Grid item>
            <Typography textOverflow="ellipsis" color="text.secondary" noWrap>
              Selected
            </Typography>
          </Grid>
        </Grid>
      )}
    </FileInputContainer>
  )
}

export default FileUpload
