import { Recorder } from '@counsel-project/client-utils'
import { createSocket, TranscriptionSocket } from '@counsel-project/counsel-transcription-api'
import MicOffIcon from '@mui/icons-material/MicOffRounded'
import MicIcon from '@mui/icons-material/MicRounded'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import { alpha, styled } from '@mui/material/styles'
import TextField from '@mui/material/TextField'
import { useCallback, useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import ChangeMicrophoneDialog from '../../routes/builder/ChangeMicrophoneDialog'
import { transcriptionServerUrl } from '../../util/api/url'
import checkToken from '../../util/auth/checkToken'
import handleError from '../../util/handleError'
import log from '../../util/logging'
import { DeviceOptions } from '../audio/SelectAudioDeviceDialog'

type StyledMicButtonProps = {
  disabled?: boolean
  active?: boolean
  hidden?: boolean
}

const StyledMicButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== 'active' && prop !== 'hidden',
})<StyledMicButtonProps>(({ theme, disabled, active, hidden }) => ({
  ...(disabled && {
    backgroundColor: theme.palette.action.disabledBackground,
    color: theme.palette.action.disabled,
    '&:hover': {
      backgroundColor: theme.palette.action.disabledBackground,
    },
  }),
  position: 'absolute',
  right: theme.spacing(1),
  top: theme.spacing(1),
  ...(hidden && {
    opacity: 0.2,
    pointerEvents: 'none',
  }),
  // When active, play glowing animation
  animation: active ? `micGlowAnimation 1s infinite` : 'none',

  // micGlowAnimation
  '@keyframes micGlowAnimation': {
    '0%': {
      boxShadow: `0 0 0 2px ${alpha(theme.palette.secondary.main, 0.6)}`,
    },
    '50%': {
      boxShadow: `0 0 0 8px ${alpha(theme.palette.secondary.main, 0.6)}`,
    },
    '100%': {
      boxShadow: `0 0 0 2px ${alpha(theme.palette.secondary.main, 0.6)}`,
    },
  },
  ...(active && {
    boxShadow: `0 0 0 2px ${alpha(theme.palette.secondary.main, 0.6)}`,
    backgroundColor: alpha(theme.palette.secondary.main, 0.1),
    color: theme.palette.secondary.main,
  }),
}))

const StyledBox = styled(Box)(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
}))

type DictationTextFieldProps = {
  value: string
  onChange: (value: string) => void
  disabled?: boolean
  placeholder?: string
  minRows?: number
  onLoading?: (loading: boolean) => void
  onRecording?: (recording: boolean) => void
}

const DictationTextField = ({
  value,
  onChange,
  disabled,
  placeholder,
  minRows,
  onLoading,
  onRecording,
}: DictationTextFieldProps) => {
  const [isLoading, setIsLoading] = useState(false)
  const [isRecording, setIsRecording] = useState(false)
  const [device, setDevice] = useState<MediaDeviceInfo | null>(null)
  const [changeMicrophoneOpen, setChangeMicrophoneOpen] = useState(false)
  const [transcript, setTranscript] = useState('')
  const [socket, setSocket] = useState<TranscriptionSocket | null>(null)

  useEffect(() => {
    onChange(transcript)
  }, [transcript, onChange])

  useEffect(() => {
    onLoading?.(isLoading)
  }, [isLoading, onLoading])

  useEffect(() => {
    onRecording?.(isRecording)
  }, [isRecording, onRecording])

  const startTranscribe = useCallback(async () => {
    try {
      const alreadyRecording = Recorder.getIsRecording()
      if (alreadyRecording) {
        toast.error('Recording is already in progress elsewhere')
        return
      }

      setIsLoading(true)

      await checkToken()

      const recordingResult = await Recorder.startRecording({
        sampleRate: 16000,
        channels: 1,
        onDebug: log.info,
        onError: log.error,
      })

      const newSocket = createSocket({
        url: transcriptionServerUrl,
        onTranscript: (transcript) => {
          setTranscript(transcript)
        },
        token: '',
        diarize: false,
        language: 'en',
        encoding: 'linear16',
        sampleRate: Recorder.SAMPLE_RATE,
        channels: Recorder.CHANNELS,
        skipSave: true,
        onClose: async (reason) => {
          log.info('Socket closed:', reason)
          await Recorder.stopRecording()
          setIsRecording(false)
          setDevice(null)
          setSocket(null)
        },
        onError: (err) => {
          log.error('LIVE - ', err)
        },
        onDebug: (msg) => {
          log.info('LIVE - debug - ', msg)
        },
      })

      Recorder.setOnDataAvailable((buffer) => {
        newSocket.sendAudio(buffer)
      })

      await newSocket.start()

      setDevice(recordingResult.device)
      setIsRecording(true)
      setSocket(newSocket)
    } catch (err) {
      handleError(err)
      await Recorder.stopRecording()
    } finally {
      setIsLoading(false)
    }
  }, [])

  const finishTranscribe = useCallback(async () => {
    try {
      if (!isRecording) return
      if (!socket) return

      setIsLoading(true)

      await Recorder.stopRecording()

      await checkToken()

      await socket.finish()

      setIsRecording(false)
      setDevice(null)
      setSocket(null)
    } catch (err) {
      handleError(err)
    } finally {
      setIsLoading(false)
    }
  }, [isRecording, socket])

  const handleToggleRecording = useCallback(() => {
    if (isRecording) {
      finishTranscribe()
    } else {
      startTranscribe()
    }
  }, [isRecording, startTranscribe, finishTranscribe])

  const handleSelectDevice = useCallback(
    async (options?: DeviceOptions) => {
      try {
        if (!socket) return

        const { deviceId } = options || {}

        const currentDeviceId = device?.deviceId
        if (currentDeviceId === deviceId) {
          setChangeMicrophoneOpen(false)
          return
        }

        setChangeMicrophoneOpen(false)
        await Recorder.stopRecording()
        const { device: d } = await Recorder.startRecording({
          sampleRate: 16000,
          channels: 1,
          onDataAvailable: (buffer) => {
            socket.sendAudio(buffer)
          },
          onDebug: log.info,
          onError: log.error,
          deviceId,
        })
        setDevice(d)
      } catch (err) {
        handleError(err)
      }
    },
    [device, socket]
  )

  return (
    <>
      <StyledBox>
        <TextField
          value={value}
          onChange={(e) => onChange(e.target.value)}
          disabled={disabled}
          placeholder={placeholder}
          fullWidth
          multiline
          minRows={minRows || 5}
        />
        <StyledMicButton
          disabled={isLoading}
          active={isRecording}
          hidden={!isRecording && value.length > 10}
          onClick={handleToggleRecording}
          color="primary"
          startIcon={isRecording ? <MicOffIcon /> : <MicIcon />}
        >
          Dictate
        </StyledMicButton>
      </StyledBox>
      <ChangeMicrophoneDialog
        open={changeMicrophoneOpen}
        onClose={() => setChangeMicrophoneOpen(false)}
        onSelectDevice={handleSelectDevice}
      />
    </>
  )
}

export default DictationTextField
