import { RLicense } from '@counsel-project/counsel-auth-api'

export const getIntervalString = (interval: number) => {
  if (interval === 1000 * 60 * 60 * 24 * 365) return 'year'
  if (interval === 1000 * 60 * 60 * 24 * 30) return 'month'
  if (interval === 1000 * 60 * 60 * 24 * 14) return '2 weeks'
  if (interval === 1000 * 60 * 60 * 24 * 7) return 'week'
  if (interval === 1000 * 60 * 60 * 24) return 'day'
  if (interval === 1000 * 60 * 60) return 'hour'
  if (interval === 1000 * 60) return 'minute'
  return null
}

export type LimitType = 'sessions' | 'dictates' | 'documents' | 'documentDictates'

export const getLicenseLimit = (
  license: RLicense,
  limit: LimitType
): { remaining: number; max: number } => {
  const maxSessions = license.maxSessions ?? -1
  const currentSessions =
    new Date() < new Date(license.sessionsNextResetDate || 0) ? license.currentSessions || 0 : 0
  const maxDictates = license.maxDictates ?? -1
  const currentDictates =
    new Date() < new Date(license.dictatesNextResetDate || 0) ? license.currentDictates || 0 : 0
  const maxDocuments = license.maxDocuments ?? -1
  const currentDocuments =
    new Date() < new Date(license.documentsNextResetDate || 0) ? license.currentDocuments || 0 : 0
  const maxDocumentDictates = license.maxDocumentDictates ?? -1
  const currentDocumentDictates =
    new Date() < new Date(license.documentDictatesNextResetDate || 0)
      ? license.currentDocumentDictates || 0
      : 0

  if (limit === 'sessions') {
    return {
      remaining: maxSessions === -1 ? 999 : maxSessions - currentSessions,
      max: maxSessions,
    }
  } else if (limit === 'dictates') {
    return {
      remaining: maxDictates === -1 ? 999 : maxDictates - currentDictates,
      max: maxDictates,
    }
  } else if (limit === 'documents') {
    return {
      remaining: maxDocuments === -1 ? 999 : maxDocuments - currentDocuments,
      max: maxDocuments,
    }
  } else if (limit === 'documentDictates') {
    return {
      remaining: maxDocumentDictates === -1 ? 999 : maxDocumentDictates - currentDocumentDictates,
      max: maxDocumentDictates,
    }
  }

  return {
    remaining: 0,
    max: 0,
  }
}
