export function textToHTML(text: string): string {
  const lines = text.split('\n')
  let inList = false

  for (let i = 0; i < lines.length; i++) {
    const indent = lines[i].search(/\S|$/)

    // Convert double spaces to HTML tab
    if (lines[i].startsWith('  ') && !lines[i].trim().startsWith('- ')) {
      lines[i] = "<p style='margin-left:" + indent / 2 + "em;'>" + lines[i].trim() + '</p>'
    } else if (lines[i].trim().startsWith('- ')) {
      lines[i] =
        "<li style='margin-left:" +
        indent / 2 +
        "em;'>" +
        lines[i].substring(indent + 2).trim() +
        '</li>'
      if (!inList) {
        lines[i] = '<ul>' + lines[i]
        inList = true
      }
    } else {
      if (inList) {
        lines[i - 1] = lines[i - 1] + '</ul>'
        inList = false
      }
      lines[i] = '<p>' + lines[i] + '</p>'
    }

    // URLs to anchor tags
    lines[i] = lines[i].replace(/(https?:\/\/[^\s]+)/g, '<a href="$1">$1</a>')

    // Emails to mailto links
    lines[i] = lines[i].replace(
      /([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/gi,
      '<a href="mailto:$1">$1</a>'
    )

    // Text wrapped in ** to bold + bigger text
    lines[i] = lines[i].replace(/\*\*(.+?)\*\*/g, "<b style='font-size: 1.2em;'>$1</b>")

    // Text wrapped in * to bold
    lines[i] = lines[i].replace(/\*(.+?)\*/g, '<b>$1</b>')
  }
  if (inList) {
    lines[lines.length - 1] = lines[lines.length - 1] + '</ul>'
  }
  return lines.join('')
}
