import {
  PatientSessionSearchableFields,
  RPatientSession,
} from '@counsel-project/counsel-transcribe-api'
import { SearchQuery } from '@counsel-project/counsel-transcribe-api/dist/common/query'
import Grid from '@mui/material/Grid'
import Skeleton from '@mui/material/Skeleton'
import Typography from '@mui/material/Typography'
import { useTheme } from '@mui/material/styles'
import React, { useCallback, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import SimplePagination from '../../components/forms/SimplePagination'
import {
  listAllSessionsCached,
  listMySessionsCached,
  listUserSessionsCached,
  refreshPatientsCache,
  refreshSessionsCache,
} from '../../util/api/transcribe-api-cached'
import checkToken from '../../util/auth/checkToken'
import handleError from '../../util/handleError'
import { createSearchParams } from '../builder/common'
import SessionItem from './SessionItem'
import SessionSortOptions from './SessionSortOptions'

type ViewAllSessionsProps = {
  search?: string
  userId?: string | null
  showAll?: boolean
}

const ViewAllSessions = ({ search, userId, showAll }: ViewAllSessionsProps) => {
  const navigate = useNavigate()
  const location = useLocation()

  const theme = useTheme()

  const limit = 9
  const [sessions, setSessions] = useState<Omit<RPatientSession, 'transcript' | 'note'>[]>([])
  const [total, setTotal] = useState(0)
  const [offset, setOffset] = useState(0)
  const [loading, setLoading] = useState(true)
  const [direction, setDirection] = useState<'asc' | 'desc'>('desc')
  const [sort, setSort] = useState<'createdAt' | 'updatedAt'>('createdAt')

  const populateInitial = useCallback(async () => {
    try {
      await checkToken()

      const searchByUser = userId && !showAll

      setLoading(true)

      const searchQuery: SearchQuery<PatientSessionSearchableFields> = {
        and: [
          { type: 'context', $not: true },
          { type: 'document', $not: true },
          { type: 'note', $not: true },
          { type: 'custom', $not: true },
        ],
        ...(search
          ? {
              or: [
                { summary: search, $fuzzy: true },
                {
                  layout: search,
                  $fuzzy: true,
                },
              ],
            }
          : {}),
      }

      let results: RPatientSession[] = []
      let total = 0
      if (searchByUser) {
        const data = await listUserSessionsCached({
          userId,
          token: '',
          limit,
          offset,
          sort,
          direction,
          search: searchQuery,
        })

        results = data.results
        total = data.total
      } else if (showAll) {
        const data = await listAllSessionsCached({
          token: '',
          limit,
          offset,
          sort,
          direction,
          search: searchQuery,
        })

        results = data.results
        total = data.total
      } else {
        const data = await listMySessionsCached({
          token: '',
          limit,
          offset,
          sort,
          direction,
          search: searchQuery,
        })

        results = data.results
        total = data.total
      }

      setSessions(results)
      setTotal(total)
    } catch (err) {
      handleError(err)
    } finally {
      setLoading(false)
    }
  }, [offset, search, sort, direction, userId, showAll])

  useEffect(() => {
    const timeout = setTimeout(() => {
      populateInitial()
    }, 10)
    return () => clearTimeout(timeout)
  }, [populateInitial])

  const createSessionClickHandler = (session: RPatientSession) => (e: React.MouseEvent) => {
    e.preventDefault()

    const queryText = createSearchParams({
      ref: location.pathname + location.search,
      userId: userId || undefined,
      showAll: showAll || undefined,
    })

    navigate(`/sessions/${session._id}${queryText}`)
  }

  useEffect(() => {
    setOffset(0)
  }, [search, userId, showAll])

  const handleRefresh = useCallback(() => {
    refreshSessionsCache()
    refreshPatientsCache()
    populateInitial()
  }, [populateInitial])

  return (
    <>
      <SessionSortOptions
        sort={sort}
        direction={direction}
        onChangeDirection={setDirection}
        onChangeSort={setSort}
        onRefresh={handleRefresh}
        disabled={loading}
      />
      <Grid
        container
        spacing={2}
        sx={{
          mt: 2,
        }}
        alignItems="stretch"
      >
        {sessions.map((session) => (
          <Grid item key={session._id} xs={12} sm={6} md={4}>
            <SessionItem
              key={session._id}
              session={session}
              onClick={createSessionClickHandler(session)}
            />
          </Grid>
        ))}
        {sessions.length === 0 && loading && (
          <>
            {Array.from({ length: limit }).map((_, i) => (
              <Grid item xs={12} sm={6} md={4} key={i}>
                <Skeleton
                  variant="rectangular"
                  height={104}
                  sx={{ backgroundColor: theme.palette.background.paper }}
                />
              </Grid>
            ))}
          </>
        )}
        {sessions.length === 0 && !loading && (
          <Grid item xs={12} sm={6} md={4}>
            <Typography variant="body1" color="text.secondary">
              No files found
            </Typography>
          </Grid>
        )}
      </Grid>
      <SimplePagination
        offset={offset}
        total={total}
        limit={limit}
        onClickNext={() => setOffset((prev) => prev + limit)}
        onClickPrevious={() => setOffset((prev) => prev - limit)}
        loading={loading}
      />
    </>
  )
}

export default ViewAllSessions
