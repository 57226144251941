import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import { alpha } from '@mui/material/styles'
import Link from '@mui/material/Link'
import { RLicense } from '@counsel-project/counsel-auth-api'
import { getRelativeTime } from '../../util'
import Button from '@mui/material/Button'
import { useNavigate } from 'react-router-dom'
import LinearProgress from '@mui/material/LinearProgress'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import { LimitType, getLicenseLimit } from './util'

type BufferingValue = {
  [key in LimitType]?: number
}

export type LicenseLimitsProps = {
  license: RLicense
  shownLimits?: LimitType[]
  hideButtons?: boolean
  bufferValues?: BufferingValue
}

const LicenseLimits = ({
  license,
  shownLimits = ['sessions', 'dictates', 'documents', 'documentDictates'],
  hideButtons = false,
  bufferValues,
}: LicenseLimitsProps) => {
  const navigate = useNavigate()

  const sessionsLimit = getLicenseLimit(license, 'sessions')
  const dictatesLimit = getLicenseLimit(license, 'dictates')
  const documentsLimit = getLicenseLimit(license, 'documents')

  const maxSessions = sessionsLimit.max
  const currentSessions = sessionsLimit.max - sessionsLimit.remaining
  const maxDictates = dictatesLimit.max
  const currentDictates = dictatesLimit.max - dictatesLimit.remaining
  const maxDocuments = documentsLimit.max
  const currentDocuments = documentsLimit.max - documentsLimit.remaining

  const now = new Date().getTime()

  const getRefreshText = (date: Date, max: number) => {
    if (max === -1) return ''

    const relativeTime = getRelativeTime((date.getTime() - now) / 1000)
    return relativeTime === 'now'
      ? 'Refreshed now'
      : relativeTime === 'never'
        ? ''
        : `Refreshes in ${relativeTime}`
  }

  const sessionsNextResetDate = new Date(license.sessionsNextResetDate || 0)
  const sessionsNextResetText = license.sessionsNextResetDate
    ? getRefreshText(sessionsNextResetDate, maxSessions)
    : ''

  const sessionsText =
    maxSessions === -1
      ? 'Unlimited Live Sessions'
      : `${maxSessions - currentSessions} Live Session${
          maxSessions - currentSessions === 1 ? '' : 's'
        } remaining`

  const dictatesNextResetDate = new Date(license.dictatesNextResetDate || 0)
  const dictatesNextResetText = license.dictatesNextResetDate
    ? getRefreshText(dictatesNextResetDate, maxDictates)
    : ''

  const dictatesText =
    maxDictates === -1
      ? 'Unlimited Dictations'
      : `${maxDictates - currentDictates} Dictation${
          maxDictates - currentDictates === 1 ? '' : 's'
        } remaining`

  const documentsNextResetDate = new Date(license.documentsNextResetDate || 0)
  const documentsNextResetText = license.documentsNextResetDate
    ? getRefreshText(documentsNextResetDate, maxDocuments)
    : ''

  const documentsText =
    maxDocuments === -1
      ? 'Unlimited Documents'
      : `${maxDocuments - currentDocuments} Document${
          maxDocuments - currentDocuments === 1 ? '' : 's'
        } remaining`

  type Limit = {
    id: LimitType
    text: string
    subtext: string
    current: number
    max: number
  }

  const limits: Limit[] = [
    {
      id: 'sessions' as LimitType,
      text: sessionsText,
      subtext: sessionsNextResetText,
      current: currentSessions,
      max: maxSessions,
    },
    {
      id: 'dictates' as LimitType,
      text: dictatesText,
      subtext: dictatesNextResetText,
      current: currentDictates,
      max: maxDictates,
    },
    {
      id: 'documents' as LimitType,
      text: documentsText,
      subtext: documentsNextResetText,
      current: currentDocuments,
      max: maxDocuments,
    },
  ].filter((limit) => shownLimits.includes(limit.id as LimitType))

  const getValue = (limit: Limit) => {
    return limit.max === -1 ? 100 : (limit.current / limit.max) * 100
  }

  const getValueBuffer = (limit: Limit) => {
    if (bufferValues && bufferValues[limit.id]) {
      const value = getValue(limit)
      const bufferValue = ((bufferValues?.[limit.id] || 0) / limit.max) * 100
      if (value + bufferValue > 100) {
        return 100
      }
      return value + bufferValue
    }
    return 0
  }

  return (
    <Box>
      {limits.map((limit, index) => {
        if (limit.max === 0) {
          return (
            <Box key={index} sx={{ mb: 1 }}>
              <Typography variant="body1" color="text.secondary">
                {limit.text}
              </Typography>
              <LinearProgress
                variant="determinate"
                value={0}
                aria-disabled={true}
                sx={{ backgroundColor: 'GrayText', opacity: 0.2, borderRadius: 4 }}
              />
            </Box>
          )
        } else if (limit.max !== 0) {
          return (
            <Box key={index}>
              <Grid container>
                <Grid item sm xs={12}>
                  <Typography variant="body1" color="primary.main">
                    {limit.text}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    variant="body1"
                    color="primary.main"
                    sx={{ opacity: 0.8 }}
                    fontStyle="italic"
                  >
                    {limit.subtext}
                  </Typography>
                </Grid>
              </Grid>
              {limit.max !== -1 ? (
                <LinearProgress
                  variant={getValueBuffer(limit) > 0 ? 'buffer' : 'determinate'}
                  value={getValue(limit)}
                  valueBuffer={getValueBuffer(limit)}
                  sx={{
                    mb: 1,
                    borderRadius: 4,
                    '.MuiLinearProgress-bar2Buffer': {
                      backgroundColor: 'secondary.main',
                      opacity: 0.75,
                    },
                    '.MuiLinearProgress-bar1Buffer': {
                      backgroundColor: 'primary.main',
                      opacity: 1,
                    },
                    '.MuiLinearProgress-dashed': {
                      background: 'none',
                      backgroundColor: 'rgb(196, 189, 229)',
                      animation: 'none',
                    },
                  }}
                />
              ) : (
                <LinearProgress
                  sx={{ mb: 1, borderRadius: 4, backgroundColor: 'GrayText', opacity: 0.2 }}
                  variant="determinate"
                  value={0}
                />
              )}
            </Box>
          )
        }
        return <></>
      })}
      {!hideButtons && (
        <Grid container justifyContent="space-between">
          {license.subscriptionId && (
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                onClick={() => navigate('/pricing')}
                sx={{ mt: 2 }}
              >
                Upgrade Membership
              </Button>
            </Grid>
          )}
          {license.priority !== 0 && license.subscriptionId && (
            <Grid item>
              <Button
                variant="text"
                color="primary"
                onClick={() => navigate('/subscriptions')}
                sx={{ mt: 2 }}
              >
                Go to Billing
              </Button>
            </Grid>
          )}
        </Grid>
      )}
    </Box>
  )
}

export type LicenseCardProps = {
  license: RLicense | null
  isOrganization?: boolean
  shownLimits?: LimitType[]
  hideButtons?: boolean
  bufferValues?: BufferingValue
}

const LicenseCard = ({
  license,
  isOrganization,
  shownLimits,
  hideButtons,
  bufferValues,
}: LicenseCardProps) => {
  const navigate = useNavigate()

  const unlicensedTitleText = isOrganization
    ? 'This organization is unlicensed'
    : 'You do not have a license'
  const licensedTitleText = isOrganization
    ? `This organization has a ${license?.type} license`
    : license?.directoryId
      ? `Your organization has a ${license?.type} license`
      : `You have a ${license?.type} membership`

  const isExpired = license?.expiresAt && new Date(license.expiresAt).getTime() < Date.now()

  if (isExpired) {
    return (
      <Paper
        elevation={0}
        sx={{
          p: 2,
          backgroundColor: (theme) => alpha(theme.palette.error.main, 0.05),
          border: (theme) => `1px solid ${theme.palette.error.main}`,
        }}
      >
        <Typography variant="h6" color="error.main" fontWeight={500}>
          {unlicensedTitleText}
        </Typography>
        <Typography variant="body1" color="error.main">
          {`Your license expired ${getRelativeTime(
            (Date.now() - new Date(license.expiresAt).getTime()) / 1000
          )} ago`}
        </Typography>
        {license?.subscriptionId && (
          <Button
            variant="contained"
            color="error"
            onClick={() => navigate('/pricing')}
            sx={{ mt: 2 }}
          >
            Renew License
          </Button>
        )}
      </Paper>
    )
  }

  if (!license)
    return (
      <Paper
        elevation={0}
        sx={{
          p: 2,
          backgroundColor: (theme) => alpha(theme.palette.warning.main, 0.05),
          border: (theme) => `1px solid ${theme.palette.warning.main}`,
        }}
      >
        <Typography variant="h6" color="warning.main" fontWeight={500}>
          {unlicensedTitleText}
        </Typography>
        <Typography variant="body1" color="warning.main">
          <Link component="a" href="/pricing" color="warning.main">
            Get a license here
          </Link>
        </Typography>
      </Paper>
    )

  return (
    <Paper
      elevation={0}
      sx={{
        p: 2,
        backgroundColor: (theme) => alpha(theme.palette.primary.main, 0.05),
        border: (theme) => `1px solid ${theme.palette.primary.main}`,
      }}
    >
      <Typography variant="h6" color="primary.main" fontWeight={500}>
        {licensedTitleText}
      </Typography>
      {!license?.subscriptionId &&
        new Date(license.expiresAt).getTime() <
          new Date().getTime() + 1000 * 60 * 60 * 24 * 365 * 100 && (
          <Typography variant="body1" color="primary.main" sx={{ mb: 1 }}>
            {`Your license expires in ${getRelativeTime(
              (new Date(license.expiresAt).getTime() - Date.now()) / 1000
            )}`}
          </Typography>
        )}
      <LicenseLimits
        license={license}
        shownLimits={shownLimits}
        hideButtons={hideButtons}
        bufferValues={bufferValues}
      />
    </Paper>
  )
}

export default LicenseCard
