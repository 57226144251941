import log from './logging'

var wakeLock: WakeLockSentinel | null = null

const onVisibilityChange = async () => {
  try {
    if (!('wakeLock' in navigator)) {
      log.info('Wakelock API is not supported')
      return
    }

    if (document.visibilityState !== 'visible') return

    if (wakeLock) {
      await wakeLock.release()
    }

    wakeLock = await navigator.wakeLock.request('screen')
    wakeLock.onrelease = () => log.info('Screen lock released')

    log.info('Screen lock acquired')
  } catch (err) {
    log.info(err)
    log.info('Wakelock API is not supported')
  }
}

export const enableWakelock = async () => {
  try {
    if (!('wakeLock' in navigator)) {
      log.info('Wakelock API is not supported')
      return
    }

    if (wakeLock !== null) return

    wakeLock = await navigator.wakeLock.request('screen')
    wakeLock.onrelease = () => log.info('Screen lock released')

    log.info('Screen lock active')
    document.addEventListener('visibilitychange', onVisibilityChange)
  } catch (err) {
    log.info(err)
    log.info('Wakelock API is not supported')
  }
}

export const disableWakelock = async () => {
  try {
    if (!('wakeLock' in navigator)) {
      log.info('Wakelock API is not supported')
      return
    }

    if (!wakeLock) return

    document.removeEventListener('visibilitychange', onVisibilityChange)
    await wakeLock.release()
    wakeLock = null
  } catch (err) {
    log.info(err)
    log.info('Wakelock API is not supported')
  }
}
