import Grid from '@mui/material/Grid'
import { useCallback, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import PageContainer from '../components/layout/PageContainer'
import handleError from '../util/handleError'
import Cookies from 'js-cookie'
import { loginWithApple } from '../util/auth/login'

const AppleLoginPage = () => {
  const navigate = useNavigate()

  const handlePopulateLogin = useCallback(async () => {
    try {
      const codeQuery = new URLSearchParams(window.location.search).get('code')
      const stateQuery = new URLSearchParams(window.location.search).get('state')

      if (!codeQuery) {
        navigate('/login')
        return
      }

      if (!stateQuery || Cookies.get('appleState') !== stateQuery) {
        navigate('/login')
        return
      }

      await loginWithApple({
        code: codeQuery,
        redirectUri: window.location.origin + '/api/apple-login',
      })

      const redirectPage = Cookies.get('redirectPage')

      navigate(redirectPage || '/onboarding')

      Cookies.remove('appleState')
      if (redirectPage) {
        Cookies.remove('redirectPage')
      }
    } catch (err) {
      handleError(err)
      navigate('/login')
    }
  }, [navigate])

  useEffect(() => {
    const timeout = setTimeout(() => {
      handlePopulateLogin()
    }, 10)

    return () => clearTimeout(timeout)
  }, [handlePopulateLogin])

  return (
    <PageContainer>
      <Grid container alignItems="center">
        <Grid item>Logging in...</Grid>
      </Grid>
    </PageContainer>
  )
}

export default AppleLoginPage
