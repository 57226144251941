import Box from '@mui/material/Box'
import { styled } from '@mui/material/styles'

type OutlinedBoxProps = {
  active?: boolean
}

const AddItemOutlinedBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'active',
})<OutlinedBoxProps>(({ theme, active }) => ({
  border: `2px solid ${active ? theme.palette.primary.main : theme.palette.divider}`,
  borderRadius: theme.shape.borderRadius,
  display: 'flex',
  alignItems: 'center',
}))

export default AddItemOutlinedBox
