const detectInAppBrowser = () => {
  const userAgent =
    navigator.userAgent || navigator.vendor || ('opera' in window ? (window.opera as string) : '')

  // Detect Facebook In-App Browser
  if (/FBAN/.test(userAgent) || /FBAV/.test(userAgent)) {
    return 'facebook'
  }

  // Detect Instagram In-App Browser
  if (/Instagram/.test(userAgent)) {
    return 'instragram'
  }

  // If neither, it's a different browser
  return 'other'
}

export default detectInAppBrowser
