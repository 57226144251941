import { RRole } from '@counsel-project/counsel-auth-api'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Checkbox from '@mui/material/Checkbox'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import Divider from '@mui/material/Divider'
import FormControlLabel from '@mui/material/FormControlLabel'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { useCallback, useEffect, useMemo, useState } from 'react'
import ClosableDialog from '../../components/ClosableDialog'
import ConfirmDialog from '../../components/ConfirmDialog'
import SelectableTab from '../../components/SelectableTab'
import { authRequest } from '../../util/api/auth-api'
import checkToken from '../../util/auth/checkToken'
import handleError from '../../util/handleError'
import AddSuperviseeSection from './AddSuperviseeSection'
import { ListRoleParameter } from '@counsel-project/counsel-ehr-api'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import IconButton from '@mui/material/IconButton'
import ClearIcon from '@mui/icons-material/CloseRounded'

type ParameterSelectorProps = {
  metadata: RRole['metadata']
  onChangeMetadata: (metadata: RRole['metadata']) => void
  parameter: ListRoleParameter
}

const ParameterSelector = ({ metadata, onChangeMetadata, parameter }: ParameterSelectorProps) => {
  const value = useMemo(() => {
    return metadata?.[parameter.field] || null
  }, [metadata, parameter.field])

  const handleChange = useCallback(
    (value: string | null) => {
      if (value === null) {
        const newMetadata = { ...metadata }
        delete newMetadata[parameter.field]
        onChangeMetadata(newMetadata)
      } else {
        onChangeMetadata({ ...metadata, [parameter.field]: value })
      }
    },
    [metadata, onChangeMetadata, parameter.field]
  )

  return (
    <FormControl fullWidth>
      <Typography variant="body1" fontWeight={500} gutterBottom>
        {parameter.label}
      </Typography>
      <Grid container spacing={1}>
        <Grid item xs>
          <Select value={value} onChange={(e) => handleChange(e.target.value)} fullWidth>
            {parameter.options?.map((p) => (
              <MenuItem key={p.value} value={p.value}>
                {p.label}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item>
          <IconButton onClick={() => handleChange(null)}>
            <ClearIcon />
          </IconButton>
        </Grid>
      </Grid>
    </FormControl>
  )
}

type RoleDialogProps = {
  directoryId: string
  role: RRole
  open: boolean
  onClose: () => void
  canEdit?: boolean
  canDelete?: boolean
  integrationParams: ListRoleParameter[]
  onChangeRole: (role: RRole) => void
  onDeleteRole: (role: RRole) => void
}

const RoleDialog = ({
  directoryId,
  role,
  open,
  onClose,
  canEdit,
  canDelete,
  integrationParams,
  onChangeRole,
  onDeleteRole,
}: RoleDialogProps) => {
  const [stagedRole, setStagedRole] = useState<RRole>(role)
  const [loading, setLoading] = useState(false)
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false)

  const handleToggleConfirmDelete = useCallback(() => {
    setConfirmDeleteOpen((prev) => !prev)
  }, [])

  useEffect(() => {
    setStagedRole(role)
    setConfirmDeleteOpen(false)
  }, [role])

  const handleSaveRole = useCallback(async () => {
    try {
      setLoading(true)

      await checkToken()

      const { result } = await authRequest.user.directories.roles.update({
        directoryId,
        token: '',
        roleId: role._id,
        permission: stagedRole.priority > 11 ? undefined : stagedRole.permission,
        ignoreLicense: stagedRole.ignoreLicense,
        children: stagedRole.children ? stagedRole.children : undefined,
        metadata: stagedRole.metadata || undefined,
      })

      onChangeRole(result)
    } catch (err) {
      handleError(err)
    } finally {
      setLoading(false)
    }
  }, [stagedRole, role, directoryId, onChangeRole])

  const handleDeleteActiveMember = useCallback(async () => {
    try {
      setLoading(true)

      await checkToken()

      await authRequest.user.directories.roles.delete({
        directoryId,
        token: '',
        roleId: role._id,
      })

      onDeleteRole(role)
    } catch (err) {
      handleError(err)
    } finally {
      setLoading(false)
    }
  }, [role, directoryId, onDeleteRole])

  const canSave = useMemo(() => {
    if (loading) {
      return false
    }
    if (stagedRole?.permission !== role?.permission) {
      return true
    }
    if (stagedRole.ignoreLicense !== role.ignoreLicense) {
      return true
    }
    if (stagedRole.children !== role.children) {
      return true
    }
    if (JSON.stringify(stagedRole.metadata) !== JSON.stringify(role.metadata)) {
      return true
    }
    return false
  }, [stagedRole, role, loading])

  return (
    <>
      <ConfirmDialog
        open={open && confirmDeleteOpen}
        onClose={handleToggleConfirmDelete}
        titleText="Confirm Member Removal"
        text="Are you sure you want to remove this member?"
        buttonText="Remove Member"
        onConfirm={handleDeleteActiveMember}
        loading={loading}
      />
      <ClosableDialog
        titleText={role.email}
        open={open && !confirmDeleteOpen}
        onClose={onClose}
        fullScreenOnMobile
      >
        <DialogContent>
          <Grid container spacing={2}>
            {(canEdit || role.permission === 'administrator') && (
              <Grid item xs>
                <SelectableTab
                  small
                  onClick={() => setStagedRole({ ...stagedRole, permission: 'administrator' })}
                  active={stagedRole.permission === 'administrator'}
                >
                  Administrator
                </SelectableTab>
              </Grid>
            )}
            {(canEdit || stagedRole.permission === 'master-counselor') && (
              <Grid item xs>
                <SelectableTab
                  small
                  onClick={() => setStagedRole({ ...stagedRole, permission: 'master-counselor' })}
                  active={stagedRole.permission === 'master-counselor'}
                  disabled={role?.permission === 'administrator' && role?.priority === 999}
                >
                  Manager
                </SelectableTab>
              </Grid>
            )}
            {(canEdit || stagedRole.permission === 'counselor') && (
              <Grid item xs>
                <SelectableTab
                  small
                  onClick={() => setStagedRole({ ...stagedRole, permission: 'counselor' })}
                  active={stagedRole.permission === 'counselor'}
                  disabled={role?.permission === 'administrator' && role?.priority === 999}
                >
                  Member
                </SelectableTab>
              </Grid>
            )}
            {(canEdit || stagedRole.permission === 'supervisor') && (
              <Grid item xs>
                <SelectableTab
                  small
                  onClick={() => setStagedRole({ ...stagedRole, permission: 'supervisor' })}
                  active={stagedRole.permission === 'supervisor'}
                  disabled={role?.permission === 'administrator' && role?.priority === 999}
                >
                  Supervisor
                </SelectableTab>
              </Grid>
            )}
          </Grid>
          <Typography variant="body1" color="text.secondary" sx={{ mt: 1 }}>
            {stagedRole?.permission === 'administrator'
              ? 'Administrators can remove members and change permissions'
              : stagedRole?.permission === 'master-counselor'
                ? 'Managers can change permissions and add members'
                : stagedRole?.permission === 'counselor'
                  ? "Members can only use the organization's license"
                  : 'Supervisors can view documentation of other members'}
          </Typography>
          {canEdit && (
            <>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={!stagedRole.ignoreLicense}
                    onChange={(e) =>
                      setStagedRole({
                        ...stagedRole,
                        ignoreLicense: !e.target.checked,
                      })
                    }
                  />
                }
                label="Use Organization License"
                sx={{ mt: 1 }}
              />
              <Typography variant="body1" color="text.secondary" sx={{ mt: 1 }}>
                Uncheck this box to prevent this member from using the organization's license and
                free up a seat.
              </Typography>
            </>
          )}
          {stagedRole.email && (
            <Box>
              <Divider sx={{ my: 2 }} />
              <AddSuperviseeSection
                directoryId={directoryId}
                role={stagedRole}
                onChangeRole={setStagedRole}
                canEdit={canEdit}
              />
            </Box>
          )}
          {canEdit && integrationParams.length > 0 && (
            <Box sx={{ width: '100%' }}>
              <Divider sx={{ my: 2 }} />
              {integrationParams.map((p) => (
                <ParameterSelector
                  key={p.field}
                  parameter={p}
                  metadata={stagedRole.metadata}
                  onChangeMetadata={(newMetadata) =>
                    setStagedRole({ ...stagedRole, metadata: newMetadata })
                  }
                />
              ))}
            </Box>
          )}
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'space-between' }}>
          {canDelete && (
            <Button
              onClick={handleToggleConfirmDelete}
              sx={{ mt: 1 }}
              disabled={role?.permission === 'administrator' && role?.priority === 999}
              color="secondary"
            >
              Remove Member
            </Button>
          )}
          {canEdit && (
            <Button onClick={handleSaveRole} sx={{ mt: 1 }} disabled={!canSave} color="primary">
              Save
            </Button>
          )}
        </DialogActions>
      </ClosableDialog>
    </>
  )
}

export default RoleDialog
