import { RNoteLog } from '@counsel-project/counsel-transcribe-api'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import BackIcon from '@mui/icons-material/ArrowBack'
import Box from '@mui/material/Box'
import React from 'react'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { JsonEditor } from 'json-edit-react'

const attemptParse = (value: string) => {
  try {
    return JSON.parse(value)
  } catch (e) {
    return null
  }
}

type NoteDetailsDialogProps = {
  open: boolean
  onClose: () => void
  note: RNoteLog
}

const NoteDetailsDialog = ({ open, onClose, note }: NoteDetailsDialogProps) => {
  return (
    <>
      <Dialog open={open} onClose={onClose} fullScreen>
        <DialogTitle>
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              left: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <BackIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Box sx={{ mt: 4 }}>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography variant="h6" component="p">
                  AI Generated Material
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  {note?.note?.split('\n')?.map((line, i) => (
                    <span key={i} className="data-hj-suppress">
                      {line}
                      <br />
                    </span>
                  ))}
                </Typography>
              </AccordionDetails>
            </Accordion>
          </Box>
          <Typography variant="subtitle2" component="p" sx={{ mt: 2 }}>
            {note.feedbackStars && note.feedbackStars !== 0 ? (
              <span style={{ color: note.feedbackStars > 3 ? 'green' : 'red' }}>
                {note.feedbackStars} Star{note.feedbackStars !== 1 ? 's' : null}
              </span>
            ) : note.feedbackPositive ? (
              <span style={{ color: 'green' }}>Positive Feedback</span>
            ) : note.feedbackPositive === false ? (
              <span style={{ color: 'red' }}>Negative Feedback</span>
            ) : null}
          </Typography>
          <Typography variant="body1" component="p">
            {note?.feedbackComment}
          </Typography>
          {note?.prompt && (
            <Box sx={{ mt: 4 }}>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography variant="h6" component="p">
                    Prompt
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {attemptParse(note?.prompt) ? (
                    <JsonEditor
                      data={attemptParse(note?.prompt)}
                      restrictAdd
                      restrictDelete
                      restrictDrag
                      maxWidth="100%"
                    />
                  ) : (
                    <Typography>
                      {note?.prompt?.split('\n')?.map((line, i) => (
                        <span key={i} className="data-hj-suppress">
                          {line}
                          <br />
                        </span>
                      ))}
                    </Typography>
                  )}
                </AccordionDetails>
              </Accordion>
            </Box>
          )}
          {note?.input && (
            <Box sx={{ mt: 4 }}>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography variant="h6" component="p">
                    Input
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {attemptParse(note?.input) ? (
                    <JsonEditor
                      data={attemptParse(note?.input)}
                      restrictAdd
                      restrictDelete
                      restrictDrag
                      maxWidth="100%"
                    />
                  ) : (
                    <Typography>
                      {note?.input?.split('\n')?.map((line, i) => (
                        <span key={i} className="data-hj-suppress">
                          {line}
                          <br />
                        </span>
                      ))}
                    </Typography>
                  )}
                </AccordionDetails>
              </Accordion>
            </Box>
          )}
          <Box sx={{ mt: 4 }}>
            {note?.instructions?.map((instruction, i) => (
              <Typography key={i} variant="body1" component="p">
                {instruction}
              </Typography>
            ))}
          </Box>
        </DialogContent>
      </Dialog>
    </>
  )
}

export default NoteDetailsDialog
