import { UserAlternateEmail } from '@counsel-project/counsel-auth-api/dist/common/database/User'
import LoadingButton from '@mui/lab/LoadingButton'
import Button from '@mui/material/Button'
import Divider from '@mui/material/Divider'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import Cookies from 'js-cookie'
import { useCallback, useEffect, useState } from 'react'
import { toast } from 'react-hot-toast'
import { useNavigate } from 'react-router-dom'
import { useTitle } from 'react-use'
import AlternativeEmailList from '../../components/AlternativeEmailList'
import ConfirmDialog from '../../components/ConfirmDialog'
import ProfessionSelector, {
  MedicalProfession,
  MentalHealthProfession,
} from '../../components/ProfessionSelector'
import VerifyPhoneDialog from '../../components/VerifyPhoneDialog'
import PageContainer from '../../components/layout/PageContainer'
import LicenseCard from '../../components/licensing/LicenseCard'
import { authRequest } from '../../util/api/auth-api'
import checkToken from '../../util/auth/checkToken'
import logout from '../../util/auth/logout'
import useActiveLicense from '../../util/auth/useActiveLicense'
import useUser from '../../util/auth/useUser'
import handleError from '../../util/handleError'
import log from '../../util/logging'
import MFASection from './MFASection'
import ProfileHeader from './ProfileHeader'
import ReferralCodeSection from './ReferralCodeSection'
import { LabeledTextField, PhoneTextField } from '@counsel-project/components'
import { MuiTelInputInfo } from 'mui-tel-input'

const ProfilePage = () => {
  const [user, setUser] = useUser()
  useTitle('Clinical Notes AI - Profile')

  const navigate = useNavigate()

  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false)
  const handleToggleDeleteOpen = () => {
    setConfirmDeleteOpen(!confirmDeleteOpen)
  }
  const [stagedPhone, setStagedPhone] = useState('')
  const [stagedFirstName, setStagedFirstName] = useState('')
  const [stagedLastName, setStagedLastName] = useState('')
  const [stagedProfession, setStagedProfession] = useState<
    MentalHealthProfession | MedicalProfession | null
  >(null)
  const [verifyPhoneOpen, setVerifyPhoneOpen] = useState(false)
  const [phoneUserId, setPhoneUserId] = useState<null | string>(null)
  const [newsletter, setNewsletter] = useState<boolean | null>(null)
  const [newsletterLoading, setNewsletterLoading] = useState<boolean>(false)
  const [lockPhoneInput, setLockPhoneInput] = useState(false)
  const [showDeleteAccount, setShowDeleteAccount] = useState(false)
  const [loading, setLoading] = useState(false)

  const [activeLicense, , populateActiveLicense] = useActiveLicense()

  useEffect(() => {
    const timeout = setTimeout(() => {
      populateActiveLicense()
    }, 100)
    return () => clearTimeout(timeout)
  }, [populateActiveLicense])

  const handleClickSeeMore = () => {
    setShowDeleteAccount(true)
  }

  const handlePopulateUserData = useCallback(async () => {
    try {
      await checkToken()

      setNewsletterLoading(true)

      const data = await authRequest.user.account.get({
        token: '',
      })

      if (data.user) {
        setNewsletter(data.user.newsletter ?? false)
        setUser?.(data.user)
      }
    } catch (err) {
      handleError(err)
    } finally {
      setNewsletterLoading(false)
    }
  }, [setUser])

  useEffect(() => {
    handlePopulateUserData()
  }, [handlePopulateUserData])

  useEffect(() => {
    if (user?.firstName) {
      setStagedFirstName(user?.firstName)
    }

    if (user?.lastName) {
      setStagedLastName(user?.lastName)
    }

    if (user?.phone) {
      setLockPhoneInput(true)
      setStagedPhone(user?.phone)
    }

    if (user?.profession) {
      setStagedProfession(user.profession as MentalHealthProfession | MedicalProfession)
    }
  }, [user])

  const handleChangePhone = (value: string, info: MuiTelInputInfo) => {
    if (value.length === 11) {
      if (!info.countryCode) {
        setStagedPhone('+1' + value.substring(1))
        return
      }
    }
    setStagedPhone(value)
  }

  const handleDeleteAccount = useCallback(async () => {
    try {
      await checkToken()

      await authRequest.user.account.delete({
        token: '',
      })

      toast.success('Your account data has been cleared and deleted.')
      logout()
      navigate('/builder')
    } catch (err) {
      log.error(err)
      toast.error('Something went wrong. Please try again later or contact support.')
    }
  }, [navigate])

  const handleSaveProfile = useCallback(async () => {
    try {
      setLoading(true)

      await checkToken()

      const data = await authRequest.user.account.update({
        token: '',
        firstName: stagedFirstName || undefined,
        lastName: stagedLastName || undefined,
        organization: user?.organization || undefined,
        country: user?.country || undefined,
        state: user?.state || undefined,
        newsletter: user?.newsletter || undefined,
        profession: stagedProfession || undefined,
      })

      setUser?.(data.user)

      if (stagedPhone !== user?.phone) {
        if (!stagedPhone) {
          await authRequest.user.account.removePhone({
            token: '',
          })
          setUser({
            ...data.user,
            phone: undefined,
          })
        } else {
          const data = await authRequest.user.account.registerPhone({
            token: '',
            phone: stagedPhone,
          })

          setPhoneUserId(data?.userId)
          setVerifyPhoneOpen(true)
        }
      }
      handlePopulateUserData()

      toast.success('Successfully updated profile.')
    } catch (err) {
      handleError(err)
    } finally {
      setLoading(false)
    }
  }, [
    stagedFirstName,
    stagedLastName,
    stagedPhone,
    setUser,
    stagedProfession,
    user,
    handlePopulateUserData,
  ])

  const handleClickChangePhone = () => {
    setLockPhoneInput(false)
    setStagedPhone('')
  }

  const handleOnAddEmail = useCallback(
    (email: UserAlternateEmail) => {
      if (!user) return

      setUser({
        ...user,
        alternativeEmails: [...(user.alternativeEmails || []), email],
      })
    },
    [setUser, user]
  )

  const handleOnRemoveEmail = useCallback(
    (email: UserAlternateEmail) => {
      if (!user) return

      setUser({
        ...user,
        alternativeEmails: (user.alternativeEmails || []).filter((e) => e.email !== email.email),
      })
    },
    [setUser, user]
  )

  return (
    <PageContainer>
      <ConfirmDialog
        titleText="Confirm Deletion"
        text="Are you sure you want to delete all of your account data? This cannot be reversed."
        buttonText="Delete My Account"
        open={confirmDeleteOpen}
        onClose={handleToggleDeleteOpen}
        onConfirm={handleDeleteAccount}
      />
      <Grid container justifyContent="space-between" spacing={3} alignItems="stretch">
        <Grid item xs={12}>
          <ProfileHeader />
        </Grid>

        <Grid item xs={12}>
          <Paper elevation={0} sx={{ height: '100%' }}>
            <Grid container padding={3}>
              <Grid item xs={12}>
                <LabeledTextField
                  label="First Name"
                  value={stagedFirstName}
                  onChange={(e) => setStagedFirstName(e.target.value)}
                  placeholder="Enter your first name"
                  aria-label="First Name"
                  autoComplete="given-name"
                />
              </Grid>
              <Grid item xs={12} sx={{ mt: 2 }}>
                <LabeledTextField
                  label="Last Name"
                  value={stagedLastName}
                  onChange={(e) => setStagedLastName(e.target.value)}
                  placeholder="Enter your last name"
                  aria-label="Last Name"
                  autoComplete="family-name"
                />
              </Grid>
              <Grid item xs={12} sx={{ mt: 2 }}>
                <Grid container alignItems="end" spacing={2}>
                  <Grid item xs>
                    <PhoneTextField
                      label="Phone Number"
                      value={stagedPhone}
                      autoComplete="phone"
                      onChange={handleChangePhone}
                      aria-label="Phone Number"
                      fullWidth
                      placeholder="Enter your phone number"
                      description="SMS Message and data rates may apply"
                      disabled={lockPhoneInput}
                    />
                  </Grid>
                  {lockPhoneInput && (
                    <Grid item>
                      <Button sx={{ py: 2 }} fullWidth onClick={handleClickChangePhone}>
                        Change
                      </Button>
                    </Grid>
                  )}
                </Grid>
              </Grid>
              <Grid item xs={12} sx={{ mt: 2 }}>
                <Typography variant="subtitle1" fontWeight={500} gutterBottom>
                  Profession
                </Typography>
                <ProfessionSelector value={stagedProfession} onChange={setStagedProfession} />
              </Grid>
              <Grid item xs={12} sx={{ mt: 2 }}>
                <LoadingButton
                  onClick={handleSaveProfile}
                  loading={loading}
                  disabled={
                    JSON.stringify({
                      firstName: stagedFirstName,
                      lastName: stagedLastName,
                      phone: stagedPhone,
                      profession: stagedProfession || undefined,
                    }) ===
                      JSON.stringify({
                        firstName: user?.firstName,
                        lastName: user?.lastName,
                        phone: user?.phone,
                        profession: user?.profession,
                      }) || newsletterLoading
                  }
                >
                  Save
                </LoadingButton>
              </Grid>
              <Grid item xs={12} sx={{ mt: 2 }}>
                <Typography variant="subtitle1" fontWeight={500}>
                  Alternative Emails
                </Typography>
                <Typography variant="subtitle2" color="text.secondary">
                  You can export notes to any of your alternative email addresses.
                </Typography>
                <AlternativeEmailList
                  emails={user?.alternativeEmails || []}
                  onAdd={handleOnAddEmail}
                  onRemove={handleOnRemoveEmail}
                />
              </Grid>
              {/* <Grid item xs={12} sx={{ mt: 2 }}>
                <Divider sx={{ mb: 2 }} />
                <Button
                  onClick={() => {
                    Cookies.remove('hasSeenTutorial')
                    Cookies.remove('transcribeId')
                    window.location.href = window.location.origin + '/?tutorial=true'
                  }}
                >
                  Replay Tutorial
                </Button>
              </Grid> */}
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <ReferralCodeSection />
        </Grid>
        <Grid item xs={12}>
          <MFASection />
        </Grid>
        {!!activeLicense && (
          <Grid item xs={12}>
            <LicenseCard
              license={activeLicense}
              shownLimits={['dictates', 'sessions', 'documents']}
            />
          </Grid>
        )}

        <Grid item xs={12}>
          {!showDeleteAccount ? (
            <Divider
              sx={{
                '& .MuiDivider-wrapper': {
                  color: 'text.secondary',
                },
                '& .MuiDivider-root': {
                  borderColor: 'text.secondary',
                },
                cursor: 'pointer',
              }}
              onClick={handleClickSeeMore}
            >
              See More Options
            </Divider>
          ) : (
            <>
              <Paper elevation={0}>
                <Grid container padding={3}>
                  <Grid item xs={12}>
                    <Typography variant="h5" sx={{ mb: 1 }}>
                      Contact Support
                    </Typography>
                    <Typography variant="body1" color="text.secondary" sx={{ mb: 3 }}>
                      We are here for you, contact us for personal support.
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Button color="primary" fullWidth sx={{ mt: 1 }} href="/help">
                      Contact Support
                    </Button>
                  </Grid>
                </Grid>
              </Paper>

              <Paper elevation={0} sx={{ mt: 3 }}>
                <Grid container padding={3}>
                  <Grid item xs={12}>
                    <Typography variant="h5" sx={{ mb: 1 }}>
                      Delete Account
                    </Typography>
                    <Typography variant="body1" color="text.secondary" sx={{ mb: 3 }}>
                      Delete all session and account data and logout.
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Button color="error" fullWidth onClick={handleToggleDeleteOpen}>
                      Delete Account
                    </Button>
                  </Grid>
                </Grid>
              </Paper>
            </>
          )}
        </Grid>

        {/* <Grid item xs={12}>
          <Paper elevation={0} sx={{ height: '100%' }}>
            <Grid container padding={3}>
              <Grid item xs={12}>
                <Typography variant="h5" sx={{ mb: 1 }}>
                  Clear Session Data
                </Typography>
                <Typography variant="body1" color="text.secondary" sx={{ mb: 3 }}>
                  Delete all your de-identified session data. No information is ever stored past 30
                  days without your input.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Button color="warning" fullWidth onClick={handleToggleClearDataOpen}>
                  Clear Data
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </Grid> */}
      </Grid>
      {!!phoneUserId && (
        <VerifyPhoneDialog
          userId={phoneUserId}
          open={verifyPhoneOpen}
          onClose={() => setVerifyPhoneOpen(false)}
          onVerify={handlePopulateUserData}
        />
      )}
    </PageContainer>
  )
}

export default ProfilePage
