import { RDirectory } from '@counsel-project/counsel-auth-api'
import Typography from '@mui/material/Typography'
import { useMemo } from 'react'
import StyledTableCell from './StyledTableCell'
import StyledTableRow from './StyledTableRow'

export type DirectoryRowProps = {
  isMobile?: boolean
  data: RDirectory
  onClick?: (data: RDirectory) => void
}

const DirectoryRow = ({ isMobile, data, onClick }: DirectoryRowProps) => {
  const { name, description, ownerEmail } = data

  const displayName = useMemo(() => {
    return `${name} (${ownerEmail})`
  }, [name, ownerEmail])

  return (
    <StyledTableRow onClick={() => onClick?.(data)}>
      <StyledTableCell
        sx={{
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          maxWidth: 400,
        }}
      >
        <Typography
          variant="subtitle2"
          textOverflow="ellipsis"
          overflow="hidden"
          whiteSpace="nowrap"
        >
          {displayName}
        </Typography>
      </StyledTableCell>
      {!isMobile && (
        <StyledTableCell align="right">
          <Typography
            variant="subtitle2"
            textOverflow="ellipsis"
            overflow="hidden"
            whiteSpace="nowrap"
          >
            {description}
          </Typography>
        </StyledTableCell>
      )}
    </StyledTableRow>
  )
}

export default DirectoryRow
