import { useState, useMemo } from 'react'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import IconButton from '@mui/material/IconButton'
import Slide from '@mui/material/Slide'
import Typography from '@mui/material/Typography'
import useMediaQuery from '@mui/material/useMediaQuery'
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'

const testimonials = [
  {
    text: 'As a psychiatrist, detailed notes are crucial. Clinical Notes AI captures every nuance of patient conversations, allowing me to focus more on therapy and less on paperwork.',
  },
  {
    text: "I was skeptical at first, but Clinical Notes AI has completely transformed our workflow. More time for patients, less time on admin and the platform is extremely easy to use - it's what every physician hopes for.",
  },
  {
    text: 'In our cardiology practice, accuracy is key. Clinical Notes AI provides precise and comprehensive documentation that has enhanced our patient care significantly.',
  },
  {
    text: "Clinical Notes AI's impact on our administrative workload has been phenomenal. It's not just a tool; it's a partner in providing better patient care.",
  },
  {
    text: 'The customizable templates from Clinical Notes AI have been a great help. They fit our specialty needs perfectly, making documentation efficient and accurate.',
  },
  {
    text: "I highly recommend Clinical Notes AI. It's a powerful tool that has streamlined our clinical documentation",
  },
]

const TestimonialCarousel = () => {
  const [currentPage, setCurrentPage] = useState(0)
  const [slideDirection, setSlideDirection] = useState<'right' | 'left'>('right')

  const isDesktop = useMediaQuery('(min-width: 1200px)')
  const isTablet = useMediaQuery('(min-width: 800px) and (max-width: 1200px)')
  const isXs = useMediaQuery('(max-width: 410px)')

  const testimonialsPerPage = useMemo(
    () => (isDesktop ? 3 : isTablet ? 2 : 1),
    [isDesktop, isTablet]
  )

  const shownTestimonials = useMemo(
    () =>
      testimonials.slice(
        currentPage * testimonialsPerPage,
        (currentPage + 1) * testimonialsPerPage
      ),
    [currentPage, testimonialsPerPage]
  )

  const totalPages = Math.ceil(testimonials.length / testimonialsPerPage)

  const handleNext = () => {
    setSlideDirection('left')
    setCurrentPage((prevPage) => (prevPage + 1) % totalPages)
  }

  const handlePrev = () => {
    setSlideDirection('right')
    setCurrentPage((prevPage) => (prevPage - 1 + totalPages) % totalPages)
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-between',
        minHeight: '400px',
        width: '100%',
        padding: isXs ? 2 : 4,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'row',
          overflow: 'hidden',
          pb: 2,
        }}
      >
        {shownTestimonials.map((testimonial, index) => (
          <Slide
            in
            key={testimonial.text}
            direction={slideDirection}
            timeout={{ enter: 500 / (index + 1), exit: 0 }}
          >
            <Card
              sx={{ width: isXs ? '240px' : '300px', height: isXs ? '260px' : '200px', mx: 4 }}
              elevation={4}
            >
              <CardContent>
                <Box sx={{ display: 'flex', justifyContent: 'center', marginBottom: '8px' }}>
                  <Typography>⭐⭐⭐⭐⭐</Typography>
                </Box>
                <Typography
                  textAlign="center"
                  variant="body1"
                  color="text.secondary"
                  fontStyle="italic"
                >
                  "{testimonial.text}"
                </Typography>
              </CardContent>
            </Card>
          </Slide>
        ))}
      </Box>

      <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
        <IconButton onClick={currentPage === 0 ? handleNext : handlePrev} sx={{ mr: 1 }}>
          <NavigateBeforeIcon />
        </IconButton>

        <IconButton
          onClick={currentPage === totalPages - 1 ? handlePrev : handleNext}
          sx={{ ml: 1 }}
        >
          <NavigateNextIcon />
        </IconButton>
      </Box>
    </Box>
  )
}

export default TestimonialCarousel
