import ArrowForwardIcon from '@mui/icons-material/ArrowForwardRounded'
import Button from '@mui/material/Button'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import { useNavigate } from 'react-router-dom'
import { useTitle } from 'react-use'
import PageContainer from '../components/layout/PageContainer'
import PrivacyPolicy from '../components/legal/PrivacyPolicy'
import { useViewContent } from '../util/tracking'

const PrivacyPolicyPage = () => {
  useViewContent('Privacy Policy')
  useTitle('Clinical Notes AI - Privacy Policy')

  const navigate = useNavigate()

  return (
    <PageContainer>
      <Paper elevation={0} sx={{ p: 3 }}>
        <Typography variant="h4" component="h1" sx={{ mb: 4 }}>
          Privacy Policy
        </Typography>
        <PrivacyPolicy />
        <Button
          href="/terms-of-service"
          onClick={(e) => {
            e.preventDefault()
            navigate('/terms-of-service')
          }}
          endIcon={<ArrowForwardIcon />}
        >
          See Our Terms of Service
        </Button>
      </Paper>
    </PageContainer>
  )
}

export default PrivacyPolicyPage
