import { StateCreator } from 'zustand'

export type OptionPreferences = {
  /** What to call this category of options */
  [categoryLabel: string]: string[] // Option ids selected for this category
}

export type PreferencesStoreValues = {
  options: OptionPreferences
}

export type PreferencesStore = PreferencesStoreValues & {
  setOptions: (categoryLabel: string, options: string[]) => void
  getOptions: (categoryLabel: string) => string[] | null
  getAllOptions: () => OptionPreferences
}

const createPreferencesSlice: StateCreator<PreferencesStore> = (set, get) => ({
  options: {},
  setOptions: (categoryLabel, options) => {
    set((state) => {
      const newOptions = { ...state.options }
      newOptions[categoryLabel] = options
      return { options: newOptions }
    })
  },
  getOptions: (categoryLabel) => {
    return get().options?.[categoryLabel] || null
  },
  getAllOptions: () => get().options,
})

export default createPreferencesSlice
