import { RDirectory } from '@counsel-project/counsel-auth-api'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import FormControl from '@mui/material/FormControl'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import { useCallback, useState } from 'react'
import { authRequest } from '../../util/api/auth-api'
import checkToken from '../../util/auth/checkToken'
import handleError from '../../util/handleError'

type CreateDirectoryFormProps = {
  onSubmit?: (directory: RDirectory) => void
}

const CreateDirectoryForm = ({ onSubmit }: CreateDirectoryFormProps) => {
  const [stagedName, setStagedName] = useState('')
  const [stagedDescription, setStagedDescription] = useState('')
  const [createLoading, setCreateLoading] = useState(false)

  const handleCreateDirectory = useCallback(
    async (e: React.FormEvent<HTMLFormElement>) => {
      try {
        e.preventDefault()

        setCreateLoading(true)

        if (!stagedName) return

        await checkToken()

        const data = await authRequest.user.directories.create({
          token: '',
          name: stagedName,
          description: stagedDescription || undefined,
        })

        onSubmit?.(data.result)
      } catch (err) {
        handleError(err)
      } finally {
        setCreateLoading(false)
      }
    },
    [stagedDescription, stagedName, onSubmit]
  )

  return (
    <Box component="form" onSubmit={handleCreateDirectory}>
      <FormControl fullWidth>
        <Typography variant="h4" textAlign="center" sx={{ mb: 1 }}>
          Create an Organization
        </Typography>
        <Typography variant="body1" textAlign="center" color="text.secondary">
          Organizations are used to group users together. You can create an organization for your
          clinic, school, or any other group of users.
        </Typography>
        <TextField
          fullWidth
          label="Organization Name"
          value={stagedName}
          onChange={(e) => setStagedName(e.target.value)}
          sx={{ mt: 2 }}
          required
          type="text"
          aria-label="organization name"
        />
        <TextField
          fullWidth
          label="Organization Description"
          value={stagedDescription}
          onChange={(e) => setStagedDescription(e.target.value)}
          sx={{ mt: 2 }}
          multiline
          rows={3}
          type="text"
          aria-label="organization description"
        />
        <Button fullWidth sx={{ mt: 2 }} disabled={!stagedName || createLoading} type="submit">
          Create Organization
        </Button>
      </FormControl>
    </Box>
  )
}

export default CreateDirectoryForm
