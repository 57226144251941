import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Grid from '@mui/material/Grid'
import Link from '@mui/material/Link'
import Typography from '@mui/material/Typography'
import { capitalize } from '@counsel-project/client-utils'
import { useCallback, useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import ClosableDialog from './ClosableDialog'
import { useWindowSize } from 'react-use'
import Confetti from 'react-confetti'
import theme from '../util/theme'
import Button from '@mui/material/Button'
import { useNavigate } from 'react-router-dom'

export type LicenseCongratsDialogProps = {
  type: string
  free?: boolean
  open: boolean
  onClose: () => void
}

const LicenseCongratsDialog = ({ type, free, open, onClose }: LicenseCongratsDialogProps) => {
  const navigate = useNavigate()
  const [enableConfetti, setEnableConfetti] = useState(true)
  const { width, height } = useWindowSize()

  const handleConfettiComplete = useCallback(() => {
    setEnableConfetti(false)
  }, [])

  const handleClickBack = () => {
    navigate('/pricing-select')
  }

  return (
    <>
      {!free && open && enableConfetti && (
        <Confetti
          width={width}
          height={height}
          numberOfPieces={120}
          colors={[
            theme.palette.primary.main,
            theme.palette.secondary.main,
            theme.palette.success.main,
          ]}
          onConfettiComplete={handleConfettiComplete}
          recycle={false}
        />
      )}
      <Dialog open={open} onClose={onClose}>
        <DialogContent>
          <Grid container direction="column" spacing={2}>
            <Grid item>
              {free ? (
                <Typography variant="body1">
                  You can always create a free account. Take advantage of our 14 day free trial on a
                  paid account to really get a feel for our features. We won't charge you until the
                  trial is over.
                </Typography>
              ) : (
                <>
                  <Typography variant="body1">
                    Congrats on choosing the <strong>{capitalize(type)}</strong> plan. You’re 1 step
                    closer to saving hours on notes and documentation.
                  </Typography>
                  <Typography variant="body1" sx={{ mt: 1 }}>
                    Next, choose how you would like to sign into your account.
                  </Typography>
                </>
              )}
            </Grid>
          </Grid>
          {free ? (
            <Grid container justifyContent="space-between" spacing={2} paddingTop={2}>
              <Grid item xs={12}>
                <Button onClick={handleClickBack} fullWidth>
                  Try A Paid Account
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Button onClick={onClose} fullWidth>
                  I’ll Pass on Premium Features
                </Button>
              </Grid>
            </Grid>
          ) : (
            <Grid container justifyContent="end" spacing={2} paddingTop={2}>
              <Grid item>
                <Button onClick={onClose} fullWidth>
                  Continue
                </Button>
              </Grid>
            </Grid>
          )}
        </DialogContent>
      </Dialog>
    </>
  )
}

export default LicenseCongratsDialog
