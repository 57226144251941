import DialogContent from '@mui/material/DialogContent'
import ClosableDialog from '../ClosableDialog'
import FileUpload from './FileUpload'

type ContextOptionsProps = {
  loading?: boolean
  open: boolean
  onClose: () => void
  onChangeFile: (file: File) => void
}

const ContextUploadDialog = ({ loading, open, onClose, onChangeFile }: ContextOptionsProps) => {
  return (
    <ClosableDialog titleText="Upload a Document" open={open} onClose={onClose}>
      <DialogContent>
        <FileUpload
          disabled={loading}
          title="Upload a file"
          subtitle="Drag and drop a file here, or click to select a file to upload."
          accept=".pdf,.docx"
          onFileChange={onChangeFile}
          file={null}
        />
      </DialogContent>
    </ClosableDialog>
  )
}

export default ContextUploadDialog
