import { RIntegration } from '@counsel-project/counsel-ehr-api'
import Button from '@mui/material/Button'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import Grid from '@mui/material/Grid'
import { capitalize } from '@counsel-project/client-utils'
import React, { useCallback, useState } from 'react'
import toast from 'react-hot-toast'
import ClosableDialog from '../../../components/ClosableDialog'
import SelectableTab from '../../../components/SelectableTab'
import { usePatientNomenclature } from '../../../util'
import { ehrRequest } from '../../../util/api/ehr-api'
import checkToken from '../../../util/auth/checkToken'
import handleError from '../../../util/handleError'
import IntegrationPatientsDisplay from './IntegrationPatientsDisplay'
import IntegrationTemplatesDisplay from './IntegrationTemplatesDisplay'

type IntegrationDialogProps = {
  open: boolean
  onClose: () => void
  integration: RIntegration
  onDelete: () => void
  onUpdateIntegration: (integration: RIntegration) => void
}

const IntegrationDialog: React.FC<IntegrationDialogProps> = ({
  open,
  onClose,
  integration,
  onDelete,
  onUpdateIntegration,
}) => {
  const [loading, setLoading] = useState(false)

  const [tab, setTab] = useState<'patients' | 'templates'>('patients')

  const handleSync = useCallback(async () => {
    try {
      setLoading(true)

      await checkToken()

      const { result } = await ehrRequest.integrations.sync({
        token: '',
        integrationId: integration._id,
      })

      onUpdateIntegration(result)
      onClose()
      toast.success('Integration syncing...')
    } catch (err) {
      handleError(err)
    } finally {
      setLoading(false)
    }
  }, [integration, onUpdateIntegration, onClose])

  const handleDelete = useCallback(async () => {
    try {
      setLoading(true)

      await checkToken()

      await ehrRequest.integrations.delete({
        token: '',
        integrationId: integration._id,
      })

      toast.success('Integration removed')
      onDelete()
    } catch (err) {
      handleError(err)
    } finally {
      setLoading(false)
    }
  }, [onDelete, integration])

  const patientNomenclature = usePatientNomenclature()

  const changeTabHandler = (tab: 'patients' | 'templates') => () => {
    setTab(tab)
  }

  return (
    <ClosableDialog open={open} onClose={onClose} titleText={capitalize(integration.type)}>
      <DialogContent>
        <Grid container spacing={2} sx={{ mb: 2 }}>
          <Grid item xs={6}>
            <SelectableTab active={tab === 'patients'} onClick={changeTabHandler('patients')}>
              {capitalize(patientNomenclature)}s
            </SelectableTab>
          </Grid>
          <Grid item xs={6}>
            <SelectableTab active={tab === 'templates'} onClick={changeTabHandler('templates')}>
              Templates
            </SelectableTab>
          </Grid>
        </Grid>
        {tab === 'patients' && <IntegrationPatientsDisplay integrationId={integration._id} />}
        {tab === 'templates' && <IntegrationTemplatesDisplay integrationId={integration._id} />}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleSync} color="primary" disabled={loading}>
          Sync
        </Button>
        <Button onClick={handleDelete} color="error" disabled={loading}>
          Remove Integration
        </Button>
      </DialogActions>
    </ClosableDialog>
  )
}

export default IntegrationDialog
