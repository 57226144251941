import NorthEastIcon from '@mui/icons-material/NorthEastRounded'
import Button, { ButtonProps } from '@mui/material/Button'

type ClinicalNotesButtonProps = ButtonProps

const ClinicalNotesButton = ({ ...props }: ClinicalNotesButtonProps) => {
  return (
    <Button
      startIcon={<NorthEastIcon />}
      variant="contained"
      color="info"
      sx={{
        display: 'flex',
        justifyContent: 'start',
        fontWeight: 500,
        backgroundColor: 'primary.main',
        color: 'white',
        '&:hover': {
          backgroundColor: 'primary.main',
        },
        p: 2,
        px: 3,
      }}
      {...props}
    >
      {props.children || 'Sign in using credentials'}
    </Button>
  )
}

export default ClinicalNotesButton
