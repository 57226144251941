import { ApiError, isApiError, wait } from '@counsel-project/client-utils'
import Cookies from 'js-cookie'
import { toast } from 'react-hot-toast'
import log from '../logging'
import { setStore } from '../store/auth'
import { loginWithToken } from './login'
import logout from './logout'

const REFRESH_TOKEN_THRESHOLD = 1000 * 60 * 30 // 30 minutes

const checkToken = async (allowMissing?: boolean): Promise<boolean> => {
  const expiresAt = Cookies.get('expiresAt')
  if (!expiresAt) {
    if (allowMissing) return false
    toast.error('You have been logged out due to inactivity')
    await logout(true)
    throw new ApiError('Logged out due to inactivity')
  }

  const expiresAtDate = new Date(parseInt(expiresAt) || 0)
  const now = new Date()

  // If its x minutes before the token expires, refresh it
  if (expiresAtDate.getTime() - now.getTime() < REFRESH_TOKEN_THRESHOLD) {
    console.log('Refreshing token...')
    // Check if it has already expired
    if (expiresAtDate.getTime() < new Date().getTime()) {
      toast.error('You have been logged out due to inactivity')
      await logout(true)
      throw new ApiError('Logged out due to inactivity')
    }

    try {
      const { user } = await loginWithToken()

      setStore({
        user,
      })

      return true
    } catch (err) {
      log.info('Auth login with token failed, retrying...')
      try {
        await wait(500)
        const { user } = await loginWithToken()

        setStore({
          user,
        })

        return true
      } catch (err) {
        log.error(err)
        if (allowMissing) return false
        if (isApiError(err) && (err.status === 401 || err.status === 402 || err.status === 403)) {
          toast.error('You have been logged out due to inactivity')
          await logout(true)
          throw new ApiError('Logged out due to inactivity')
        }
      }
    }
  }

  return true
}

export default checkToken
