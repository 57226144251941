import CircularProgress from '@mui/material/CircularProgress'
import Grid from '@mui/material/Grid'
import { useCallback, useEffect } from 'react'
import toast from 'react-hot-toast'
import { useNavigate, useParams } from 'react-router-dom'
import { useTitle } from 'react-use'
import PageContainer from '../../components/layout/PageContainer'
import { authRequest } from '../../util/api/auth-api'
import checkToken from '../../util/auth/checkToken'
import refreshToken from '../../util/auth/refreshToken'
import handleError from '../../util/handleError'
import Cookies from 'js-cookie'

const InvitationPage = () => {
  useTitle('Clinical Notes AI - Invitation Accepted')
  const navigate = useNavigate()
  const { id } = useParams()

  const populateInvitation = useCallback(async () => {
    try {
      if (!id) return

      const loggedIn = await checkToken(true)

      const expiresAt = Cookies.get('expiresAt')
      if (!expiresAt || !loggedIn) {
        navigate(
          `/register?state=${encodeURIComponent(
            JSON.stringify({
              page: `/invitations/${id}`,
            })
          )}&orgLogin=true`
        )
        return
      }

      await authRequest.user.directories.invitations.accept({ token: '', directoryId: id })

      toast.success('Invitation accepted!')
      navigate(`/onboarding`)
    } catch (err) {
      handleError(err)
      navigate('/invitations')
    }
  }, [id, navigate])

  useEffect(() => {
    const timeout = setTimeout(() => {
      populateInvitation()
    }, 10)
    return () => clearTimeout(timeout)
  }, [populateInvitation])

  return (
    <PageContainer>
      <Grid container justifyContent="center" alignItems="center">
        <CircularProgress />
      </Grid>
    </PageContainer>
  )
}

export default InvitationPage
