import { RUser } from '@counsel-project/counsel-auth-api'
import Autocomplete, { AutocompleteProps } from '@mui/material/Autocomplete'
import CircularProgress from '@mui/material/CircularProgress'
import TextField from '@mui/material/TextField'
import { ApiError } from '@counsel-project/client-utils'
import React, { useCallback, useEffect, useState } from 'react'
import { toast } from 'react-hot-toast'
import { authRequest } from '../../util/api/auth-api'
import checkToken from '../../util/auth/checkToken'
import handleError from '../../util/handleError'

export type AdminUserSelectorProps = {
  value: RUser | null
  onChange: (value: RUser | null) => void
} & Omit<
  AutocompleteProps<RUser, false, false, false>,
  'renderInput' | 'renderOption' | 'onChange' | 'value' | 'options'
>

const AdminUserSelector = (
  { value, onChange, ...props }: AdminUserSelectorProps,
  ref: React.Ref<HTMLDivElement>
) => {
  const [shownOptions, setShownOptions] = useState<RUser[]>([])
  const [inputValue, setInputValue] = useState('')
  const [loading, setLoading] = useState(false)

  const populateOptions = useCallback(async (search: string) => {
    try {
      setLoading(true)

      await checkToken()

      const data = await authRequest.admin.users.list({
        token: '',
        search: search
          ? {
              and: [{ email: search, $fuzzy: true }],
            }
          : undefined,
      })

      setShownOptions(data.results)
    } catch (err) {
      handleError(err)
    } finally {
      setLoading(false)
    }
  }, [])

  useEffect(() => {
    const timeout = setTimeout(() => {
      populateOptions(inputValue)
    }, 300)

    return () => {
      clearTimeout(timeout)
    }
  }, [inputValue, populateOptions])

  return (
    <>
      <Autocomplete
        {...props}
        ref={ref}
        value={value}
        onChange={(e, value) => onChange(value)}
        inputValue={inputValue}
        onInputChange={(e, value) => {
          setInputValue(value)
        }}
        options={shownOptions}
        loading={loading}
        getOptionLabel={(option) => option.email}
        noOptionsText="No users found"
        id="user-selector"
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder="Search..."
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
      />
    </>
  )
}

export default React.forwardRef(AdminUserSelector)
