import Autocomplete, { AutocompleteProps } from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import React from 'react'

const dateOptions = [
  {
    label: '1 Hour',
    value: 1000 * 60 * 60,
  },
  {
    label: '2 Hours',
    value: 1000 * 60 * 60 * 2,
  },
  {
    label: '3 Hours',
    value: 1000 * 60 * 60 * 3,
  },
  {
    label: '4 Hours',
    value: 1000 * 60 * 60 * 4,
  },
  {
    label: '5 Hours',
    value: 1000 * 60 * 60 * 5,
  },
  {
    label: '6 Hours',
    value: 1000 * 60 * 60 * 6,
  },
  {
    label: '1 Day',
    value: 1000 * 60 * 60 * 24,
  },
  {
    label: '1 Week',
    value: 1000 * 60 * 60 * 24 * 7,
  },
  {
    label: '2 Weeks',
    value: 1000 * 60 * 60 * 24 * 7 * 2,
  },
  {
    label: '1 Month',
    value: 1000 * 60 * 60 * 24 * 30,
  },
  {
    label: '2 Months',
    value: 1000 * 60 * 60 * 24 * 30 * 2,
  },
  {
    label: '3 Months',
    value: 1000 * 60 * 60 * 24 * 30 * 3,
  },
  {
    label: '1 Year',
    value: 1000 * 60 * 60 * 24 * 365,
  },
]

const dateOptionsValueMap = dateOptions.map((option) => option.value)

export type DatePeriodSelectorProps = {
  placeholder?: string
  value: number | null
  onChange: (value: number | null) => void
} & Omit<
  AutocompleteProps<number, false, false, false>,
  'renderInput' | 'renderOption' | 'onChange' | 'value' | 'options'
>

const DatePeriodSelector = (
  { placeholder = 'Date Period', value, onChange, ...props }: DatePeriodSelectorProps,
  ref: React.Ref<HTMLDivElement>
) => {
  return (
    <Autocomplete
      {...props}
      ref={ref}
      value={value}
      onChange={(_, value) => onChange(value)}
      options={dateOptionsValueMap}
      getOptionLabel={(option) => {
        const dateOption = dateOptions.find((dateOption) => dateOption.value === option)
        return dateOption?.label ?? option.toString()
      }}
      renderInput={(params) => <TextField {...params} placeholder={placeholder} />}
    />
  )
}

export default React.forwardRef(DatePeriodSelector)
