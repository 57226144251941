import React, { useRef, useEffect } from 'react'
import lottie from 'lottie-web'
import Box, { BoxProps } from '@mui/material/Box'

export interface AnimationProps extends BoxProps {
  animation?: { [key: string]: unknown } | string
  path?: string
  loop?: boolean
  autoplay?: boolean
  renderer?: 'canvas' | 'html' | 'svg'
  speed?: number
  id: string
  initialSegment?: [number, number]
}

const Animation = ({
  animation,
  path,
  loop = false,
  autoplay = false,
  renderer = 'canvas',
  speed = 1,
  id = '',
  initialSegment,
  ...props
}: AnimationProps) => {
  const containerRef = useRef<HTMLDivElement | null>(null)

  useEffect(() => {
    if (!animation) return
    if (!containerRef.current) return
    if (typeof window === 'undefined') return

    const lottieAnimation = lottie.loadAnimation({
      container: containerRef.current,
      animationData: animation,
      path,
      renderer,
      loop,
      autoplay,
      initialSegment,
      rendererSettings: {},
    })
    lottie.setSpeed(speed, lottieAnimation.name)

    return () => {
      lottie.destroy(lottieAnimation.name)
    }
  }, [animation, containerRef, loop, autoplay, renderer, speed, initialSegment, path])

  return <Box {...props} ref={containerRef} id={id}></Box>
}

export default Animation
