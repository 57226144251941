import { RIntegrationTemplate } from '@counsel-project/counsel-ehr-api'
import { RLayout } from '@counsel-project/counsel-transcribe-api'
import SaveIcon from '@mui/icons-material/SaveRounded'
import LoadingButton from '@mui/lab/LoadingButton'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import { useCallback, useEffect, useState } from 'react'
import { authRequest } from '../../util/api/auth-api'
import { ehrRequest } from '../../util/api/ehr-api'
import checkToken from '../../util/auth/checkToken'
import useUser from '../../util/auth/useUser'
import log from '../../util/logging'
import DocumentTypeSelector from './DocumentTypeSelector'
import LayoutCategorySelector from './LayoutCategorySelector'
import LayoutProfessionSelector from './LayoutProfessionsSelector'
import SelectDirectoryDialog from './SelectDirectoryDialog'
import { capitalize } from '@counsel-project/client-utils'
import IntegrationCard from '../organization/IntegrationCard'
import IntegrationBox from '../../components/layout/IntegrationBox'

export type FormLayoutSaveOptions = {
  multiplePeople: boolean
  requirePatientLabel: boolean
  directoryId?: string
  name: string
  description: string
  documentType?: string
  integrationTemplateId?: string
  makePublic?: boolean
  professions: string[]
  tags: string[]
  type: 'note' | 'document'
}

type FormLayoutControlsProps = {
  layout?: RLayout
  loading?: boolean
  disabled?: boolean
  onSave: (options: FormLayoutSaveOptions) => void
}

const FormLayoutControls = ({ layout, loading, disabled, onSave }: FormLayoutControlsProps) => {
  const [multiplePeople, setMultiplePeople] = useState(false)
  const [requirePatientLabel, setRequirePatientLabel] = useState(false)
  const [directoryId, setDirectoryId] = useState<string | undefined>(undefined)
  const [isLoading, setIsLoading] = useState(false)
  const [selectDirectoryOpen, setSelectDirectoryOpen] = useState(false)
  const [name, setName] = useState('')
  const [description, setDescription] = useState('')
  const [documentType, setDocumentType] = useState<string | null>(null)
  const [integrationTemplate, setIntegrationTemplate] = useState<RIntegrationTemplate | null>(null)
  const [makePublic, setMakePublic] = useState<undefined | boolean>(undefined)
  const [professions, setProfessions] = useState<string[]>([])
  const [category, setCategory] = useState<string>('Custom')
  const [type, setType] = useState<'note' | 'document'>('document')

  const [user] = useUser()

  useEffect(() => {
    if (layout) {
      setMultiplePeople(layout.config.multiplePeople)
      setRequirePatientLabel(layout.config.requirePatientLabel)
      setDirectoryId(layout.directoryId)
      setName(layout.name)
      setDescription(layout.description)
      setDocumentType(layout.documentType || null)
      setMakePublic(!layout.userId ? true : undefined)
      setProfessions(layout.config.professions || [])
      setCategory(layout.tags[0])
      setType(layout.type === 'document' ? 'document' : 'note')
    }
  }, [layout])

  const handleSave = () => {
    onSave({
      name,
      description,
      multiplePeople,
      requirePatientLabel,
      directoryId,
      documentType: documentType || undefined,
      integrationTemplateId: makePublic ? undefined : integrationTemplate?._id,
      makePublic,
      professions,
      tags: [category],
      type,
    })
  }

  const handleToggleDirectory = useCallback(async () => {
    if (directoryId) {
      return setDirectoryId('')
    }

    if (user?.admin) {
      return setSelectDirectoryOpen(true)
    }

    try {
      setIsLoading(true)

      await checkToken()

      const directoryRes = await authRequest.user.directories.list({
        token: '',
        limit: 2,
      })

      if (directoryRes.results.length === 1) {
        setDirectoryId(directoryRes.results[0]._id)
      } else {
        setSelectDirectoryOpen(true)
      }
    } catch (err) {
    } finally {
      setIsLoading(false)
    }
  }, [directoryId, user])

  const populateIntegrationTemplate = useCallback(async () => {
    try {
      if (!layout?.integrationTemplateId) return

      await checkToken()

      const { result } = await ehrRequest.integrations.templates.get({
        token: '',
        templateId: layout.integrationTemplateId,
      })

      setIntegrationTemplate(result)
    } catch (err) {
      log.error(err)
    }
  }, [layout?.integrationTemplateId])

  useEffect(() => {
    const timeout = setTimeout(() => {
      populateIntegrationTemplate()
    }, 10)
    return () => clearTimeout(timeout)
  }, [populateIntegrationTemplate])

  return (
    <>
      <Paper
        elevation={0}
        sx={{
          p: 2,
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h6">Template Settings</Typography>
          </Grid>
          {layout?.integrationTemplateId && integrationTemplate && (
            <Grid item xs={12}>
              <Typography variant="body1" fontWeight={500} fontSize={18} sx={{ mb: 1 }}>
                EHR Mapping
              </Typography>
              <IntegrationBox integrationType={integrationTemplate.integrationType} />
            </Grid>
          )}
          {layout?.source === 'user' && (
            <Grid item xs={12}>
              <Typography variant="body1" fontWeight={500} fontSize={18}>
                Document Name
                <Typography
                  component="span"
                  fontWeight={500}
                  fontSize={18}
                  variant="body1"
                  color="error"
                  sx={{ ml: 1 }}
                >
                  *
                </Typography>
              </Typography>
              <TextField
                placeholder="Enter document name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                disabled={isLoading || disabled}
                fullWidth
              />
            </Grid>
          )}
          {layout?.source === 'user' && (
            <Grid item xs={12}>
              <Typography variant="body1" fontWeight={500} fontSize={18}>
                Description
                <Typography
                  component="span"
                  fontWeight={500}
                  fontSize={18}
                  variant="body1"
                  color="error"
                  sx={{ ml: 1 }}
                >
                  *
                </Typography>
              </Typography>
              <TextField
                placeholder="Enter document description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                disabled={isLoading || disabled}
                fullWidth
              />
            </Grid>
          )}
          {layout?.source === 'user' && (
            <Grid item xs={12}>
              <Typography variant="body1" fontWeight={500} fontSize={18}>
                Document Type
              </Typography>
              <DocumentTypeSelector
                value={documentType}
                onChange={setDocumentType}
                disabled={isLoading || disabled}
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <LayoutCategorySelector
              value={category}
              onChange={setCategory}
              disabled={isLoading || disabled}
            />
          </Grid>

          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={multiplePeople}
                  onChange={(e) => setMultiplePeople(e.target.checked)}
                  disabled={isLoading || disabled}
                />
              }
              label="This is for group sessions"
            />
            <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
              Select this if the template is intended for use in group sessions including more than
              2 people.
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={!!directoryId}
                  onChange={handleToggleDirectory}
                  disabled={
                    isLoading ||
                    disabled ||
                    (!!layout?.integrationTemplateId && !!layout?.directoryId)
                  }
                />
              }
              label="Share with organization"
            />
            {layout?.directoryName ? (
              <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
                This template is shared with <strong>{layout.directoryName}</strong>
              </Typography>
            ) : (
              <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
                Share this template with your organization.
              </Typography>
            )}
          </Grid>

          {user?.admin && (
            <Grid item xs={12}>
              <Typography variant="body1" color="text.secondary">
                Admin only - make this template a public template that shows up in the global
                template list
              </Typography>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={!!makePublic}
                    onChange={(e) => setMakePublic(e.target.checked ? true : false)}
                    disabled={isLoading || disabled}
                  />
                }
                label="Make Public"
              />
            </Grid>
          )}
          {user?.admin && (
            <Grid item xs={12}>
              <Typography variant="body1" color="text.secondary">
                Admin only - switch the type of credit this template uses
              </Typography>
              <Grid container spacing={1}>
                <Grid item>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={type === 'document'}
                        onChange={(e) => setType('document')}
                        disabled={isLoading || disabled}
                      />
                    }
                    label="Document"
                  />
                </Grid>
                <Grid item>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={type === 'note'}
                        onChange={(e) => setType('note')}
                        disabled={isLoading || disabled}
                      />
                    }
                    label="Note"
                  />
                </Grid>
              </Grid>
            </Grid>
          )}
          {user?.admin && (makePublic || !layout?.userId) && (
            <Grid item xs={12}>
              <Typography variant="body1" color="text.secondary">
                Admin only - make this template visible to only these professions
              </Typography>
              <LayoutProfessionSelector
                value={professions}
                onChange={setProfessions}
                disabled={isLoading || disabled}
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <LoadingButton
              color="primary"
              onClick={handleSave}
              startIcon={<SaveIcon />}
              loading={loading}
              disabled={disabled || isLoading || !name || !description}
              fullWidth
            >
              Save
            </LoadingButton>
          </Grid>
        </Grid>
      </Paper>
      <SelectDirectoryDialog
        open={selectDirectoryOpen}
        onClose={() => setSelectDirectoryOpen(false)}
        onSelect={(directory) => {
          setDirectoryId(directory._id)
          setSelectDirectoryOpen(false)
        }}
      />
    </>
  )
}

export default FormLayoutControls
