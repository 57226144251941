import { RLayout, RPatientSession } from '@counsel-project/counsel-transcribe-api'
import AutoAwesomeRounded from '@mui/icons-material/AutoAwesomeRounded'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { useCallback, useState } from 'react'
import toast from 'react-hot-toast'
import DictationTextField from '../../components/forms/DictationTextField'
import HelpButton from '../../components/HelpButton'
import { transcribeRequest } from '../../util/api/transcribe-api'
import checkToken from '../../util/auth/checkToken'
import { logAIEdit } from '../../util/tracking'

export type EditWithAISectionProps = {
  sessionId: string
  layout?: RLayout
  onChangeSession: (session: RPatientSession) => void
  disabled?: boolean
}

const EditWithAISection = ({
  sessionId,
  layout,
  disabled,
  onChangeSession,
}: EditWithAISectionProps) => {
  const [modifications, setModifications] = useState<string>('')
  const [loading, setLoading] = useState(false)
  const [recording, setRecording] = useState(false)

  const handleSubmitModifications = useCallback(async () => {
    try {
      setLoading(true)

      await checkToken()

      const { result } = await transcribeRequest.sessions.ai.edit({
        token: '',
        sessionId,
        modifications,
      })

      if (layout) {
        logAIEdit({
          template: layout.identifier,
          name: layout.name,
        })
      }

      setModifications('')

      onChangeSession(result)

      toast.loading('Making changes with AI...')
    } catch (err) {
      console.error(err)
    } finally {
      setLoading(false)
    }
  }, [modifications, sessionId, onChangeSession, layout])

  return (
    <Grid container spacing={2}>
      <Grid item>
        <AutoAwesomeRounded color="secondary" />
      </Grid>
      <Grid item>
        <Typography variant="h6">Talk to AI</Typography>
      </Grid>
      <Grid item xs>
        <HelpButton params="#talkToAi" />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body1" color="text.secondary">
          Describe the changes you would like to make to this document
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <DictationTextField
          value={modifications}
          onChange={setModifications}
          disabled={disabled || loading}
          minRows={3}
          onLoading={setLoading}
          onRecording={setRecording}
        />
      </Grid>
      <Grid item xs={12}>
        <Button
          onClick={handleSubmitModifications}
          disabled={disabled || loading || !modifications || recording}
          color="secondary"
        >
          Make These Changes
        </Button>
      </Grid>
    </Grid>
  )
}

export default EditWithAISection
