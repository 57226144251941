import { RDirectory, DirectorySortableFields } from '@counsel-project/counsel-auth-api'
import DirectoryRow from './DirectoryRow'
import TableBase from './TableBase'
import useMediaQuery from '@mui/material/useMediaQuery'

export type DirectoryTableProps = {
  searchDisabled?: boolean
  rows: RDirectory[]
  page: number
  rowsPerPage: number
  rowsPerPageOptions: number[]
  total: number
  loaded: boolean
  sort: DirectorySortableFields
  direction: 'asc' | 'desc'
  onChangeSort?: (sort: DirectorySortableFields, direction: 'asc' | 'desc') => void
  onChangePage?: (page: number) => void
  onChangeRowsPerPage?: (rowsPerPage: number) => void
  onClickRow?: (row: RDirectory) => void
  onSubmitSearch?: (search: string) => void
}

const DirectoryTable = ({
  searchDisabled,
  rows,
  page,
  rowsPerPage,
  rowsPerPageOptions,
  total,
  loaded,
  sort,
  direction,
  onChangeSort,
  onChangePage,
  onChangeRowsPerPage,
  onClickRow,
  onSubmitSearch,
}: DirectoryTableProps) => {
  const isMobile = useMediaQuery('(max-width: 600px)')

  return (
    <TableBase
      searchDisabled={searchDisabled}
      columns={[
        { field: 'name', label: 'Name / Owner', sortable: true, align: 'left' },
        {
          field: 'description',
          label: 'Description',
          sortable: true,
          align: 'right',
          hidden: isMobile,
        },
      ]}
      rows={rows}
      renderRow={(row: RDirectory) => (
        <DirectoryRow key={row._id} isMobile={isMobile} data={row} onClick={onClickRow} />
      )}
      page={page}
      rowsPerPage={rowsPerPage}
      rowsPerPageOptions={rowsPerPageOptions}
      total={total}
      loaded={loaded}
      sort={sort}
      direction={direction}
      onChangeSort={onChangeSort}
      onChangePage={onChangePage}
      onChangeRowsPerPage={onChangeRowsPerPage}
      onSubmitSearch={onSubmitSearch}
    />
  )
}

export default DirectoryTable
