import { Price, Product, Subscription } from '@counsel-project/counsel-auth-api'
import LoadingButton from '@mui/lab/LoadingButton'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import Typography from '@mui/material/Typography'
import { useCallback, useState } from 'react'
import toast from 'react-hot-toast'
import { useNavigate } from 'react-router-dom'
import { authRequest } from '../../util/api/auth-api'
import checkToken from '../../util/auth/checkToken'
import useActiveLicense from '../../util/auth/useActiveLicense'
import handleError from '../../util/handleError'
import ClosableDialog from '../ClosableDialog'

type ConfirmUpdateSubscriptionDialogProps = {
  directoryId?: string
  activeSubscription: Subscription
  product: Product
  price: Price
  quantity: number
  open: boolean
  onClose: () => void
}

const ConfirmUpdateSubscriptionDialog = ({
  directoryId,
  activeSubscription,
  product,
  price,
  quantity,
  open,
  onClose,
}: ConfirmUpdateSubscriptionDialogProps) => {
  const [loading, setLoading] = useState(false)

  const [, setActiveLicense] = useActiveLicense()

  const navigate = useNavigate()

  const handleConfirm = useCallback(async () => {
    try {
      setLoading(true)

      await checkToken()

      const { license } = await authRequest.user.subscriptions.update({
        token: '',
        directoryId,
        subscriptionId: activeSubscription.id,
        priceId: price.id,
        quantity,
      })

      if (license.subscriptionId === activeSubscription.id) {
        setActiveLicense(license)
      }

      toast.success('Subscription updated')
      if (directoryId) {
        navigate(`/organization/${directoryId}`)
      } else {
        navigate('/builder')
      }
    } catch (err) {
      handleError(err)
    } finally {
      setLoading(false)
      onClose()
    }
  }, [navigate, onClose, price.id, quantity, setActiveLicense, directoryId, activeSubscription])

  return (
    <ClosableDialog open={open} onClose={onClose} titleText="Confirm Update">
      <DialogContent>
        <Typography variant="body1" gutterBottom>
          Are you sure you want to switch to {product.name} for $
          {((price.unit_amount || 0) / 100) * quantity} per {price.recurring?.interval}? Your
          current subscription will be changed to the new plan and your current subscription will be
          prorated.
        </Typography>
        {directoryId && !activeSubscription.metadata.directoryId ? (
          <Typography variant="body1">
            This membership will be switched from your individual account to the organization
            account.
          </Typography>
        ) : !directoryId && !!activeSubscription.metadata.directoryId ? (
          <Typography variant="body1" sx={{ mt: 2 }} fontStyle="italic">
            This membership will be switched from the organization account to your individual
            account.
          </Typography>
        ) : null}
      </DialogContent>
      <DialogActions>
        <LoadingButton onClick={handleConfirm} color="primary" loading={loading}>
          Confirm Update
        </LoadingButton>
      </DialogActions>
    </ClosableDialog>
  )
}

export default ConfirmUpdateSubscriptionDialog
