import { MarkdownTextField } from '@counsel-project/components'
import { InputField } from '@counsel-project/counsel-generation-api'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { useEffect, useMemo, useState } from 'react'
import CopyButton from './CopyButton'
import Tooltip from '@mui/material/Tooltip'
import InfoIcon from '@mui/icons-material/Info'
import IconButton from '@mui/material/IconButton'

type StringTemplateFieldProps = {
  inputField: InputField
  value: string[]
  onChange?: ({ id, value }: { id: string; value: string[] }) => void
}

const StringTemplateField = ({ inputField, value, onChange }: StringTemplateFieldProps) => {
  const { id, name, type, instructions, condition } = inputField

  const [answer, setAnswer] = useState<string>(value?.[0] || '')

  useEffect(() => {
    if (value.length > 0 && type === 'string') {
      setAnswer(value[0])
    }
  }, [value, type])

  const conditionText = useMemo(() => {
    if (condition?.contains) return `Only present if ${condition.id} contains ${condition.contains}`
    if (condition?.equals) return `Only present if ${condition.id} equals ${condition.equals}`
    return null
  }, [condition])

  return (
    <>
      <Grid container sx={{ mb: 1 }} spacing={1}>
        <Grid item xs>
          <Typography variant="body1" fontWeight={500}>
            {name}
          </Typography>
        </Grid>
        {condition && (
          <Grid item>
            <Tooltip title={conditionText}>
              <IconButton size="small" color="secondary">
                <InfoIcon />
              </IconButton>
            </Tooltip>
          </Grid>
        )}
        <Grid item>
          <CopyButton text={answer} />
        </Grid>
      </Grid>

      <MarkdownTextField
        options={['bold', 'italic', 'header']}
        placeholder={instructions || name}
        value={answer}
        onChange={(value) => {
          onChange?.({ id, value: [value] })
        }}
      />
    </>
  )
}

export default StringTemplateField
