import React from 'react'
import { styled } from '@mui/material/styles'
import Chip, { ChipProps } from '@mui/material/Chip'

export const StyledChip = styled(Chip)(({ theme, color }) => ({
  fontWeight: 500,
  padding: theme.spacing(0, 0),
  fontSize: 14,
  backgroundColor: theme.palette[color && color !== 'default' ? color : 'secondary'].main,
  color: theme.palette[color && color !== 'default' ? color : 'secondary'].contrastText,
}))

export type NewChipProps = ChipProps

const NewChip = ({ ...props }: NewChipProps) => {
  return <StyledChip label="NEW" {...props} />
}

export default NewChip
