import { RTranscript } from '@counsel-project/counsel-deidentifier-api/dist/common/database/Transcript'
import Paper from '@mui/material/Paper'
import { useCallback, useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useTitle } from 'react-use'
import PageContainer from '../../components/layout/PageContainer'
import TranscriptTable from '../../components/tables/TranscriptTable'
import { deidentifierRequest } from '../../util/api/deidentifier-api'
import checkToken from '../../util/auth/checkToken'
import useRequireAdmin from '../../util/auth/useRequireAdmin'
import TranscriptDialog from './TranscriptDialog'
import Button from '@mui/material/Button'
import ExportTranscriptDialog from './ExportTranscriptDialog'
import handleError from '../../util/handleError'
import AdminSearchControls, { AdminSearchParams } from './AdminSearchControls'
import Grid from '@mui/material/Grid'
import theme from '../../util/theme'
import useMediaQuery from '@mui/material/useMediaQuery'
import Typography from '@mui/material/Typography'
import TranscriptContent from './TranscriptContent'

const AdminTranscriptsPage = () => {
  useTitle('Clinical Notes AI - Admin Transcripts')
  useRequireAdmin('/')

  const isLarge = useMediaQuery(theme.breakpoints.up('lg'))

  const [loading, setLoading] = useState(true)
  const [transcripts, setTranscripts] = useState<RTranscript[]>([])
  const [limit, setLimit] = useState(10)
  const [page, setPage] = useState(0)
  const [total, setTotal] = useState(0)
  const [transcriptOpen, setTranscriptOpen] = useState(false)
  const [selectedTranscript, setSelectedTranscript] = useState<RTranscript | null>(null)
  const [exportOpen, setExportOpen] = useState(false)

  const [searchOptions, setSearchOptions] = useState<AdminSearchParams>({
    search: null,
    startDate: null,
    endDate: null,
    minDuration: null,
    maxDuration: null,
  })

  const handleCloseExport = () => {
    setExportOpen(false)
  }

  const handleChangePage = (newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (newRowsPerPage: number) => {
    setLimit(newRowsPerPage)
  }

  const populateTranscripts = useCallback(async () => {
    try {
      await checkToken()

      setLoading(true)

      const data = await deidentifierRequest.admin.list({
        token: '',
        limit,
        offset: page * limit,
        search: searchOptions?.search || undefined,
        startDate: searchOptions?.startDate || undefined,
        endDate: searchOptions?.endDate || undefined,
        minDuration: searchOptions?.minDuration || undefined,
        maxDuration: searchOptions?.maxDuration || undefined,
      })

      setTranscripts(data.results)
    } catch (err) {
      handleError(err)
    } finally {
      setLoading(false)
    }
  }, [limit, page, searchOptions])

  useEffect(() => {
    const timeout = setTimeout(() => {
      populateTranscripts()
    }, 10)
    return () => clearTimeout(timeout)
  }, [populateTranscripts])

  const populateCount = useCallback(async () => {
    try {
      await checkToken()

      const data = await deidentifierRequest.admin.count({
        token: '',
        search: searchOptions?.search || undefined,
        startDate: searchOptions?.startDate || undefined,
        endDate: searchOptions?.endDate || undefined,
        minDuration: searchOptions?.minDuration || undefined,
        maxDuration: searchOptions?.maxDuration || undefined,
      })

      setTotal(data.result)
    } catch (err) {
      handleError(err)
    }
  }, [searchOptions])

  useEffect(() => {
    const timeout = setTimeout(() => {
      populateCount()
    }, 10)
    return () => clearTimeout(timeout)
  }, [populateCount])

  const handleClickRow = (row: RTranscript) => {
    setSelectedTranscript(row)
    setTranscriptOpen(true)
  }

  const handleCloseTranscript = () => {
    setTranscriptOpen(false)
  }

  const handleCostAnalysis = async () => {
    try {
      await checkToken()

      const data = await deidentifierRequest.admin.cost({
        token: '',
      })

      alert('$' + data.cost)
    } catch (err) {
      console.error(err)
    }
  }

  const handleSubmitSearch = useCallback((search: AdminSearchParams) => {
    setSearchOptions(search)
  }, [])

  return (
    <PageContainer maxWidth="xl">
      <Grid container spacing={2}>
        <Grid item xs={12} md={12} lg={6}>
          <Paper sx={{ p: 2 }} elevation={0}>
            <AdminSearchControls onSubmit={handleSubmitSearch} />
            <TranscriptTable
              loaded={!loading}
              rows={transcripts}
              page={page}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              onClickRow={handleClickRow}
              rowsPerPage={limit}
              rowsPerPageOptions={[10, 25, 50]}
              total={total}
            />
            <Button onClick={handleCostAnalysis}>Cost Analysis</Button>
            <Button onClick={() => setExportOpen(true)} sx={{ ml: 1 }}>
              Export
            </Button>
          </Paper>
        </Grid>

        {isLarge && selectedTranscript && (
          <Grid item xs={12} md={12} lg={6}>
            <Paper sx={{ p: 2 }} elevation={0}>
              <TranscriptContent transcript={selectedTranscript} />
            </Paper>
          </Grid>
        )}
      </Grid>
      {selectedTranscript && !isLarge && (
        <TranscriptDialog
          open={transcriptOpen}
          onClose={handleCloseTranscript}
          transcript={selectedTranscript}
        />
      )}
      <ExportTranscriptDialog open={exportOpen} onClose={handleCloseExport} />
    </PageContainer>
  )
}

export default AdminTranscriptsPage
