import EditorJS from '@editorjs/editorjs'
import { checklistClassname } from './htmlToBlocks'

const getCheckboxElement = (checked: boolean) => `
<input type="checkbox" disabled style="margin: 0px 0.35em 0.25em -1.6em; vertical-align: middle;" ${
  checked ? 'checked' : ''
}>`

const convertBlockExportBox = (block: EditorJS.OutputData['blocks'][0]) => {
  return `<p>${block.data.content}</p>`
}

const convertBlockParagraph = (block: EditorJS.OutputData['blocks'][0]) => {
  return `<p>${block.data.text}</p>`
}

const convertBlockHeader = (block: EditorJS.OutputData['blocks'][0]) => {
  return `<h${block.data.level}>${block.data.text}</h${block.data.level}>`
}

const convertBlockList = (block: EditorJS.OutputData['blocks'][0]) => {
  switch (block.data.style) {
    case 'unordered':
      return `<ul>${block.data.items.map((item: string) => `<li>${item}</li>`).join('')}</ul>`
    case 'ordered':
      return `<ol>${block.data.items.map((item: string) => `<li>${item}</li>`).join('')}</ol>`
    default:
      return ''
  }
}

const convertBlockTable = (block: EditorJS.OutputData['blocks'][0]) => {
  const headings = block.data.content[0]
  const rows = block.data.content.slice(1)

  return `<table>
<thead>
<tr>
${headings.map((heading: string) => `<th>${heading}</th>`).join('')}
</tr>
</thead>
<tbody>
${rows
  .map((row: string[]) => `<tr>${row.map((cell: string) => `<td>${cell}</td>`).join('')}</tr>`)
  .join('')}
</tbody>
</table>`
}

const convertBlockChecklistTable = (block: EditorJS.OutputData['blocks'][0]) => {
  const checkedMap = block.data.checked // 2D array of booleans or null if no checkbox is present

  const headings = block.data.content[0]
  const rows = block.data.content.slice(1)

  return `<table>
<thead>
<tr>
${headings
  .map(
    (heading: string, i: number) =>
      `<th>${
        checkedMap[0][i] !== null ? getCheckboxElement(checkedMap[0][i]) + heading : heading
      }</th>`
  )
  .join('')}
</tr>
</thead>
<tbody>
${rows
  .map(
    (row: string[], i: number) =>
      `<tr>${row
        .map(
          (cell: string, j: number) =>
            `<td>${
              checkedMap[i + 1][j] !== null ? getCheckboxElement(checkedMap[i + 1][j]) + cell : cell
            }</td>`
        )
        .join('')}</tr>`
  )
  .join('')}
</tbody>
</table>`
}

const convertBlockChecklist = (block: EditorJS.OutputData['blocks'][0]) => {
  return `<ul>${block.data.items
    .map(
      (item: { text: string; checked: boolean }) =>
        `<li class="${checklistClassname}" style="list-style-type: none;"><input type="checkbox" disabled style="margin: 0px 0.35em 0.25em -1.6em; vertical-align: middle;" ${
          item.checked ? 'checked' : ''
        }>${item.text}</li>`
    )
    .join('')}</ul>`
}

const blocksToHTML = (blocks: EditorJS.OutputData['blocks']) => {
  const result = blocks
    .map((block) => {
      switch (block.type) {
        case 'paragraph':
          return convertBlockParagraph(block)
        case 'header':
          return convertBlockHeader(block)
        case 'list':
          return convertBlockList(block)
        case 'table':
          return convertBlockTable(block)
        case 'checklist':
          return convertBlockChecklist(block)
        case 'checklistTable':
          return convertBlockChecklistTable(block)
        case 'exportBox':
          return convertBlockExportBox(block)
        default:
          return ''
      }
    })
    .join('\n')

  return result
}

export default blocksToHTML
