import { RNoteLog, RTranscriptLog } from '@counsel-project/counsel-transcribe-api'
import BackIcon from '@mui/icons-material/ArrowBackRounded'
import NextIcon from '@mui/icons-material/ArrowForwardRounded'
import CalendarViewMonthIcon from '@mui/icons-material/CalendarViewMonth'
import CloseIcon from '@mui/icons-material/CloseRounded'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Divider from '@mui/material/Divider'
import FormControl from '@mui/material/FormControl'
import FormLabel from '@mui/material/FormLabel'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import MenuItem from '@mui/material/MenuItem'
import Paper from '@mui/material/Paper'
import Select from '@mui/material/Select'
import Typography from '@mui/material/Typography'
import { useCallback, useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { useEffectOnce, useTitle } from 'react-use'
import SearchBar from '../../components/SearchBar'
import PageContainer from '../../components/layout/PageContainer'
import { getRelativeTime } from '../../util'
import { transcribeRequest } from '../../util/api/transcribe-api'
import checkToken from '../../util/auth/checkToken'
import useLayouts from '../../util/auth/useLayouts'
import useRequireAdmin from '../../util/auth/useRequireAdmin'
import handleError from '../../util/handleError'
import NoteDetailsDialog from './NoteDetailsDialog'
import SessionsDialog from './SessionsDialog'
import { useNavigate } from 'react-router-dom'

const estNoteGenCost = (inputTokens: number, outputTokens: number) => {
  return inputTokens * (0.0015 / 1000) + outputTokens * (0.002 / 1000)
}

const estCostToDuration = (seconds: number) => {
  const minutes = seconds / 60
  return minutes * 0.0087
}

const Admin = () => {
  useTitle('Clinical Notes AI - Admin')
  useRequireAdmin('/')

  const [transcripts, setTranscripts] = useState<Omit<RTranscriptLog, 'transcript'>[]>([])
  const [transcriptsTotal, setTranscriptsTotal] = useState<number>(0)
  const [notes, setNotes] = useState<Omit<RNoteLog, 'note'>[]>([])

  const [selectedTranscript, setSelectedTranscript] = useState<RTranscriptLog | null>(null)
  const [selectedNote, setSelectedNote] = useState<RNoteLog | null>(null)

  const [limit] = useState(10)
  const [offset, setOffset] = useState(0)
  const [emailSearch, setEmailSearch] = useState('')
  const [emailSubmittedSearch, setEmailSubmittedSearch] = useState('')
  const [dirSearch, setDirSearch] = useState('')
  const [dirSubmittedSearch, setDirSubmittedSearch] = useState('')
  const [idSearch, setIdSearch] = useState('')
  const [idSubmittedSearch, setIdSubmittedSearch] = useState('')
  const [costAssessmentText, setCostAssessmentText] = useState('')
  const [createNoteOpen, setCreateNoteOpen] = useState(false)
  const [notesLoading, setNotesLoading] = useState(false)
  const [transcriptsLoading, setTranscriptsLoading] = useState(false)
  const [sessionsOpen, setSessionsOpen] = useState(false)
  const [sessionsTranscribeId, setSessionsTranscribeId] = useState('')
  const [sessionType, setSessionType] = useState<'live-session' | 'dictation' | 'all'>('all')

  const navigate = useNavigate()

  const [layouts, , populateLayouts] = useLayouts()

  useEffectOnce(() => {
    populateLayouts()
  })

  type PopulateDataOptions = {
    emailSearch: string
    dirSearch: string
    idSearch: string
    sessionType: 'live-session' | 'dictation' | 'all'
    offset: number
    limit: number
  }

  const populateData = useCallback(
    async ({
      emailSearch,
      dirSearch,
      idSearch,
      sessionType,
      offset,
      limit,
    }: PopulateDataOptions) => {
      try {
        setTranscriptsLoading(true)

        await checkToken()

        const searches = []
        if (emailSearch) {
          searches.push({
            email: emailSearch,
            $fuzzy: true,
          })
        }
        if (dirSearch) {
          searches.push({
            directoryName: dirSearch,
            $fuzzy: true,
          })
        }
        if (sessionType !== 'all') {
          searches.push({
            type: sessionType,
          })
        }
        if (idSearch) {
          searches.push({
            _id: idSearch,
          })
        }

        const data = await transcribeRequest.logs.transcript.list({
          token: '',
          limit,
          offset,
          sort: 'createdAt',
          direction: 'desc',
          search:
            searches.length !== 0
              ? {
                  and: searches,
                }
              : undefined,
        })

        if (data.results.length !== 0) {
          const notesData = await transcribeRequest.logs.note.list({
            token: '',
            limit: 300,
            offset: 0,
            search: {
              or: data.results.map((r: any) => ({ transcriptId: r._id })),
            },
          })

          setNotes(notesData.results)
        }

        // Scroll to top of page
        window.scrollTo(0, 0)

        setTranscripts(data.results)

        return data.results
      } catch (err) {
        console.error(err)
      } finally {
        setTranscriptsLoading(false)
      }
    },
    []
  )

  type PopulateTotalOptions = {
    emailSearch: string
    dirSearch: string
    idSearch: string
    sessionType: 'live-session' | 'dictation' | 'all'
  }

  const populateTotal = useCallback(
    async ({ emailSearch, dirSearch, idSearch, sessionType }: PopulateTotalOptions) => {
      try {
        setTranscriptsLoading(true)

        await checkToken()

        const searches = []
        if (emailSearch) {
          searches.push({
            email: emailSearch,
            $fuzzy: true,
          })
        }
        if (dirSearch) {
          searches.push({
            directoryName: dirSearch,
            $fuzzy: true,
          })
        }
        if (sessionType !== 'all') {
          searches.push({
            type: sessionType,
          })
        }
        if (idSearch) {
          searches.push({
            _id: idSearch,
          })
        }

        const data = await transcribeRequest.logs.transcript.count({
          token: '',
          search:
            searches.length !== 0
              ? {
                  and: searches,
                }
              : undefined,
        })

        setTranscriptsTotal(data.total)
      } catch (err) {
        console.error(err)
      } finally {
        setTranscriptsLoading(false)
      }
    },
    []
  )

  useEffect(() => {
    const timeout = setTimeout(
      () =>
        populateData({
          emailSearch: emailSubmittedSearch,
          dirSearch: dirSubmittedSearch,
          sessionType,
          idSearch: idSubmittedSearch,
          offset,
          limit,
        }),
      10
    )
    return () => clearTimeout(timeout)
  }, [
    populateData,
    emailSubmittedSearch,
    dirSubmittedSearch,
    idSubmittedSearch,
    sessionType,
    offset,
    limit,
  ])

  useEffect(() => {
    const timeout = setTimeout(
      () =>
        populateTotal({
          emailSearch,
          dirSearch,
          idSearch,
          sessionType,
        }),
      10
    )
    return () => clearTimeout(timeout)
  }, [populateTotal, emailSearch, dirSearch, idSearch, sessionType])

  const getTimeSince = (date: string) => {
    const now = new Date()
    const then = new Date(date)
    const diff = now.getTime() - then.getTime()
    const seconds = Math.floor(diff / 1000)
    const minutes = Math.floor(seconds / 60)
    const hours = Math.floor(minutes / 60)
    const days = Math.floor(hours / 24)

    if (days > 0) {
      return `${days} days ago`
    }

    if (hours > 0) {
      return `${hours} hours ago`
    }

    if (minutes > 0) {
      return `${minutes} minutes ago`
    }

    if (seconds > 0) {
      return `${seconds} seconds ago`
    }

    return 'just now'
  }

  const handleSelectTranscript = useCallback(async (transcribeId: string) => {
    try {
      await checkToken()

      setNotesLoading(true)

      const data = await transcribeRequest.logs.transcript.get({
        transcriptLogId: transcribeId,
        token: '',
      })

      setSelectedTranscript(data.result)
    } catch (err) {
      console.error(err)
    } finally {
      setNotesLoading(false)
    }
  }, [])

  const handleOpenSessions = useCallback((transcriptId: string) => {
    setSessionsTranscribeId(transcriptId)
    setSessionsOpen(true)
  }, [])

  const handleSelectNote = useCallback(async (noteId: string) => {
    try {
      await checkToken()

      const data = await transcribeRequest.logs.note.get({
        token: '',
        noteLogId: noteId,
      })

      setSelectedNote(data.result)
    } catch (err) {
      console.error(err)
    }
  }, [])

  const handleChangeEmailSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmailSearch(e.target.value)
    if (e.target.value === '') {
      setEmailSubmittedSearch('')
      setOffset(0)
    }
  }

  const handleChangeDirSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDirSearch(e.target.value)
    if (e.target.value === '') {
      setDirSubmittedSearch('')
      setOffset(0)
    }
  }

  const handleChangeIdSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setIdSearch(e.target.value)
    if (e.target.value === '') {
      setIdSubmittedSearch('')
      setOffset(0)
    }
  }

  const handleSubmitSearch = (e: React.FormEvent<HTMLDivElement | HTMLFormElement>) => {
    e.preventDefault()
    setEmailSubmittedSearch(emailSearch)
    setDirSubmittedSearch(dirSearch)
    setIdSubmittedSearch(idSearch)
    setOffset(0)
  }

  const selectedDataTranscriptCost = selectedTranscript
    ? Math.ceil(estCostToDuration(selectedTranscript.duration || 0) * 1000) / 1000
    : 0
  const selectedDataNoteCost = selectedTranscript
    ? notes
        .filter((n) => n.transcriptId === selectedTranscript._id)
        .reduce((acc: number, note: Omit<RNoteLog, 'note'>) => {
          if (note.inputTokens && note.outputTokens) {
            return acc + estNoteGenCost(note.inputTokens, note.outputTokens)
          }
          return acc
        }, 0)
    : 0

  const navDeidentifierHandler = (transcriptLog: RTranscriptLog) => async () => {
    try {
      await checkToken()

      const data = await transcribeRequest.sessions.list.user({
        token: '',
        userId: transcriptLog.userId,
        limit: 1,
        search: {
          and: [{ transcriptId: transcriptLog._id }],
        },
      })

      const session = data.results[0]

      if (!session) {
        toast.error('No session found')
        return
      }

      const path = `/admin/transcripts?search=${encodeURIComponent(session._id)}`

      // Open in new tab

      window.open(path, '_blank')
    } catch (err) {
      handleError(err)
    }
  }

  const handleOpenNextLog = useCallback(async () => {
    if (!selectedTranscript || !transcripts.length) return

    const currentIndex = transcripts.findIndex((t) => t._id === selectedTranscript._id)
    if (currentIndex === -1) return

    // If we're at the end of the current page but not at the total limit
    if (currentIndex === transcripts.length - 1 && offset + limit < transcriptsTotal) {
      // Update offset
      const newOffset = offset + limit

      // Now fetch new data
      const newTranscripts = await populateData({
        emailSearch,
        dirSearch,
        idSearch,
        sessionType,
        offset: newOffset,
        limit,
      })

      // Select the first transcript from the new page
      if (newTranscripts?.[0]) {
        await handleSelectTranscript(newTranscripts[0]._id)
      }
      return
    }

    // If we're not at the end of the current page, just go to the next transcript
    if (currentIndex < transcripts.length - 1) {
      const nextTranscript = transcripts[currentIndex + 1]
      await handleSelectTranscript(nextTranscript._id)
    }
  }, [
    selectedTranscript,
    transcripts,
    handleSelectTranscript,
    offset,
    limit,
    transcriptsTotal,
    populateData,
    emailSearch,
    dirSearch,
    idSearch,
    sessionType,
  ])

  // Add the handleOpenPrevLog function
  const handleOpenPrevLog = useCallback(async () => {
    if (!selectedTranscript || !transcripts.length) return

    const currentIndex = transcripts.findIndex((t) => t._id === selectedTranscript._id)
    if (currentIndex === -1) return

    // If we're at the start of the current page and not at the beginning
    if (currentIndex === 0 && offset > 0) {
      // Update offset
      const newOffset = offset - limit

      // Fetch new data
      const newTranscripts = await populateData({
        emailSearch,
        dirSearch,
        idSearch,
        sessionType,
        offset: newOffset,
        limit,
      })

      // Select the last transcript from the new page
      if (newTranscripts?.length) {
        await handleSelectTranscript(newTranscripts[newTranscripts.length - 1]._id)
      }
      return
    }

    // If we're not at the start of the current page, just go to the previous transcript
    if (currentIndex > 0) {
      const prevTranscript = transcripts[currentIndex - 1]
      await handleSelectTranscript(prevTranscript._id)
    }
  }, [
    selectedTranscript,
    transcripts,
    handleSelectTranscript,
    offset,
    limit,
    populateData,
    emailSearch,
    dirSearch,
    idSearch,
    sessionType,
  ])

  return (
    <PageContainer>
      <Dialog open={createNoteOpen} onClose={() => setCreateNoteOpen(false)} fullScreen>
        <DialogTitle>
          <IconButton
            aria-label="close"
            onClick={() => setCreateNoteOpen(false)}
            sx={{
              position: 'absolute',
              left: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <BackIcon />
          </IconButton>
        </DialogTitle>
      </Dialog>
      <Grid container justifyContent="center" spacing={2}>
        <Grid item xs={12}>
          <Paper elevation={0} sx={{ p: 2 }}>
            <Grid container spacing={1} sx={{ mb: 1 }}>
              <Grid item>
                <Typography variant="h5" component="h1">
                  Admin Panel
                </Typography>
              </Grid>
              <Grid item>{transcriptsLoading && <CircularProgress size={24} />}</Grid>
            </Grid>
            <Grid container justifyContent="end" alignItems="center" spacing={2}>
              <Grid item xs={12} sm>
                <SearchBar
                  placeholder="Search by email..."
                  onChange={handleChangeEmailSearch}
                  value={emailSearch}
                  onSubmit={handleSubmitSearch}
                />
              </Grid>
              <Grid item xs={12} sm>
                <SearchBar
                  placeholder="Search by organization..."
                  onChange={handleChangeDirSearch}
                  value={dirSearch}
                  onSubmit={handleSubmitSearch}
                />
              </Grid>
              <Grid item xs={12} sm>
                <SearchBar
                  placeholder="Search by ID..."
                  onChange={handleChangeIdSearch}
                  value={idSearch}
                  onSubmit={handleSubmitSearch}
                />
              </Grid>
              <Grid item>
                <Button onClick={() => navigate('/admin/transcriptlogs')}>New View</Button>
              </Grid>
            </Grid>
            <Grid container sx={{ mt: 2 }}>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <FormLabel>Session Type</FormLabel>
                  <Select
                    value={sessionType}
                    onChange={(e) => setSessionType(e.target.value as 'live-session' | 'dictation')}
                    fullWidth
                  >
                    <MenuItem value="live-session">Live Session</MenuItem>
                    <MenuItem value="dictation">Dictation</MenuItem>
                    <MenuItem value="all">All</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          {transcripts.map((transcript) => (
            <Paper
              elevation={0}
              sx={{
                p: 2,
                mb: 2,
              }}
              key={transcript._id}
            >
              <Grid container alignItems="center" flexWrap="nowrap">
                <Grid item xs zeroMinWidth>
                  <Typography variant="subtitle1" textOverflow="ellipsis" overflow="hidden">
                    {transcript.email}{' '}
                    <Typography color="text.secondary" component="span" variant="subtitle2">
                      {new Date(transcript.createdAt).toLocaleString()} (
                      {getTimeSince(transcript.createdAt)})
                    </Typography>
                  </Typography>
                  {transcript.directoryName && (
                    <Typography variant="subtitle2" textOverflow="ellipsis" overflow="hidden">
                      Organization: {transcript.directoryName}
                    </Typography>
                  )}
                  {transcript.service && (
                    <Typography
                      color="text.secondary"
                      variant="subtitle2"
                      textOverflow="ellipsis"
                      overflow="hidden"
                    >
                      Service: {transcript.service}
                    </Typography>
                  )}
                  <Typography variant="subtitle2" textOverflow="ellipsis" overflow="hidden">
                    ID: {transcript._id}
                  </Typography>
                  <Typography
                    color="text.secondary"
                    variant="subtitle2"
                    textOverflow="ellipsis"
                    overflow="hidden"
                  >
                    {getRelativeTime(transcript.duration)}
                  </Typography>
                  <Typography variant="subtitle2" textOverflow="ellipsis" overflow="hidden">
                    {notes.filter((note) => note.transcriptId === transcript._id).length} Outputs
                  </Typography>
                </Grid>
                <Grid item>
                  <IconButton onClick={() => handleOpenSessions(transcript._id)}>
                    <CalendarViewMonthIcon />
                  </IconButton>
                  <IconButton onClick={() => handleSelectTranscript(transcript._id)}>
                    <NextIcon />
                  </IconButton>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={12}>
                  {notes
                    .filter((note) => note.transcriptId === transcript._id)
                    .some((n) => n.feedbackPositive) ? (
                    <Typography variant="body1" component="p" sx={{ color: 'green' }}>
                      Positive Feedback
                    </Typography>
                  ) : notes
                      .filter((note) => note.transcriptId === transcript._id)
                      .some((n) => n.feedbackPositive === false) ? (
                    <Typography variant="body1" component="p" sx={{ color: 'red' }}>
                      Negative Feedback
                    </Typography>
                  ) : null}
                  {transcript.type ? (
                    <Typography variant="body2" color="text.secondary">
                      {transcript.type}
                    </Typography>
                  ) : null}
                </Grid>
              </Grid>
            </Paper>
          ))}
        </Grid>
        <Grid item xs={12} container spacing={2}>
          <Grid item xs={6}>
            <Button
              fullWidth
              disabled={offset === 0 || transcriptsLoading}
              onClick={() => {
                setOffset(offset - limit)
              }}
            >
              Previous
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              fullWidth
              disabled={offset + limit >= transcriptsTotal || transcriptsLoading}
              onClick={() => {
                setOffset(offset + limit)
              }}
            >
              Next
            </Button>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Typography
            variant="body1"
            component="p"
            sx={{ textAlign: 'center' }}
            color="text.secondary"
          >
            {transcriptsTotal} Total
          </Typography>
        </Grid>
      </Grid>
      {selectedTranscript && (
        <Dialog open={!!selectedTranscript} onClose={() => setSelectedTranscript(null)} fullScreen>
          <DialogTitle>
            {selectedTranscript.email}
            <IconButton
              aria-label="previous"
              onClick={handleOpenPrevLog}
              sx={{
                position: 'absolute',
                right: 104, // Position it to the left of the next button
                top: 8,
              }}
              color="primary"
              disabled={notesLoading || transcriptsLoading}
            >
              <BackIcon />
            </IconButton>
            <IconButton
              aria-label="next"
              onClick={handleOpenNextLog}
              sx={{
                position: 'absolute',
                right: 56,
                top: 8,
              }}
              color="primary"
              disabled={notesLoading || transcriptsLoading}
            >
              <NextIcon />
            </IconButton>
            <IconButton
              aria-label="close"
              onClick={() => setSelectedTranscript(null)}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <Accordion
              elevation={0}
              sx={(theme) => ({
                backgroundColor: theme.palette.background.default,
                color: theme.palette.text.primary,
                '& .MuiAccordionSummary-content': {
                  margin: 0,
                },
              })}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="h6">Transcript</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography variant="body1" component="p">
                  {selectedTranscript.transcript.split('\n').map((line, i) => (
                    <span key={i} className="data-hj-suppress">
                      {line}
                      <br />
                    </span>
                  ))}
                </Typography>
              </AccordionDetails>
            </Accordion>
            {notes.filter((note) => note.transcriptId === selectedTranscript._id).length !== 0 && (
              <>
                <br />
                <Divider />
                <br />
                <Typography variant="body1" component="p">
                  AI Generated Material:
                </Typography>
              </>
            )}
            {notes
              .filter((note) => note.transcriptId === selectedTranscript._id)
              .map((note: Omit<RNoteLog, 'note'>, i) => (
                <Paper
                  elevation={0}
                  sx={(theme) => ({
                    p: 2,
                    my: 1,
                    backgroundColor: theme.palette.background.default,
                    cursor: 'pointer',
                  })}
                  key={note._id}
                  onClick={() => handleSelectNote(note._id)}
                >
                  <Typography variant="subtitle2">
                    $
                    {Math.round(estNoteGenCost(note.inputTokens, note.outputTokens) * 10000) /
                      10000}{' '}
                    - {getTimeSince(note.createdAt)}
                  </Typography>
                  <Typography variant="body1" component="p">
                    {note.feedbackStars && note.feedbackStars !== 0 ? (
                      <span style={{ color: note.feedbackStars > 3 ? 'green' : 'red' }}>
                        {note.feedbackStars} Star{note.feedbackStars !== 1 ? 's' : null}
                      </span>
                    ) : note.feedbackPositive ? (
                      <span style={{ color: 'green' }}>Positive Feedback</span>
                    ) : note.feedbackPositive === false ? (
                      <span style={{ color: 'red' }}>Negative Feedback</span>
                    ) : null}
                  </Typography>
                  <Typography variant="body2" component="p" color="text.secondary">
                    {layouts.find((l) => l.identifier === note?.layout)?.name}
                    {' - '}
                    {note.label}
                  </Typography>
                  <Typography variant="body2" component="p" color="text.secondary">
                    {note.instructions?.join(', ')}
                  </Typography>
                </Paper>
              ))}
            <br />
            <Divider />
            <br />
            <Typography variant="body1" component="p">
              ID: {selectedTranscript._id}
            </Typography>
            <Typography variant="body1" component="p">
              Request ID: {selectedTranscript.requestId || 'N/A'}
            </Typography>
            <Typography variant="body1" component="p">
              Duration: {getRelativeTime(selectedTranscript.duration)}
            </Typography>
            <Typography variant="body1" component="p">
              Note Gen Cost: {'~'}
              {selectedDataNoteCost}
            </Typography>
            <Typography variant="body1" component="p">
              Transcription Cost: ~$
              {selectedDataTranscriptCost}
            </Typography>
            <Typography variant="body1" component="p">
              Total Cost: {'~'}
              {Math.ceil((selectedDataNoteCost + selectedDataTranscriptCost) * 1000) / 1000}
            </Typography>
            <Typography variant="body1" component="p">
              {/* Created date - duration */}
              Started At:{' '}
              {new Date(
                new Date(selectedTranscript.createdAt).getTime() -
                  selectedTranscript.duration * 1000
              ).toLocaleDateString()}{' '}
              {new Date(
                new Date(selectedTranscript.createdAt).getTime() -
                  selectedTranscript.duration * 1000
              ).toLocaleTimeString()}
            </Typography>
            <Typography variant="body1" component="p">
              Ended At: {new Date(selectedTranscript.createdAt).toLocaleDateString()}{' '}
              {new Date(selectedTranscript.createdAt).toLocaleTimeString()}
            </Typography>
            <Button onClick={navDeidentifierHandler(selectedTranscript)}>View Deidentified</Button>
          </DialogContent>
        </Dialog>
      )}
      {!!selectedNote && (
        <NoteDetailsDialog
          open={!!selectedNote}
          onClose={() => setSelectedNote(null)}
          note={selectedNote}
        />
      )}
      {sessionsTranscribeId && (
        <SessionsDialog
          open={sessionsOpen}
          onClose={() => setSessionsOpen(false)}
          transcribeId={sessionsTranscribeId}
        />
      )}
    </PageContainer>
  )
}

export default Admin
