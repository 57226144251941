import Chip, { ChipProps } from '@mui/material/Chip'
import { styled } from '@mui/material/styles'

export type HighlightChipProps = {
  highlight?: boolean
  href?: string
} & ChipProps

const HighlightChip = styled(Chip, {
  shouldForwardProp: (prop) => prop !== 'highlight',
})<HighlightChipProps>(({ theme, highlight }) => ({
  cursor: 'pointer',
  ...(highlight
    ? {
        backgroundColor: theme.palette.primary.main,
        color: 'white',
        ':hover': {
          backgroundColor: theme.palette.primary.main,
        },
      }
    : {}),
}))

type HighlightTagProps = {
  title: string
  highlight?: boolean
  onClick?: () => void
}

const HighlightTag = ({ title, highlight, onClick }: HighlightTagProps) => {
  const handleClick = (e: React.MouseEvent) => {
    e.preventDefault()
    onClick?.()
  }

  return (
    <HighlightChip
      component="a"
      href={'#' + title}
      label={title}
      highlight={highlight}
      onClick={handleClick}
    />
  )
}

export default HighlightTag
