import { RDirectory } from '@counsel-project/counsel-auth-api'
import Button from '@mui/material/Button'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import { useCallback, useEffect, useMemo, useState } from 'react'
import ClosableDialog from '../../components/ClosableDialog'
import DirectorySelector from '../../components/DirectorySelector'
import useUser from '../../util/auth/useUser'
import { RLayout } from '@counsel-project/counsel-transcribe-api'
import IconButton from '@mui/material/IconButton'
import AddIcon from '@mui/icons-material/AddRounded'
import Chip from '@mui/material/Chip'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import Box from '@mui/material/Box'
import toast from 'react-hot-toast'
import log from '../../util/logging'
import checkToken from '../../util/auth/checkToken'
import { transcribeRequest } from '../../util/api/transcribe-api'
import { isValidEmail } from '@counsel-project/client-utils'
import Typography from '@mui/material/Typography'
import handleError from '../../util/handleError'

type ChangeEditorsDialogProps = {
  open: boolean
  onClose: () => void
  layout: RLayout
  onChangeLayout: (layout: RLayout) => void
}

const ChangeEditorsDialog = ({
  open,
  onClose,
  layout,
  onChangeLayout,
}: ChangeEditorsDialogProps) => {
  const [editors, setEditors] = useState<string[]>(layout.editors || [])
  const [stagedEditor, setStagedEditor] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const isSavable = useMemo(() => {
    return editors.join(',') !== (layout.editors?.join(',') || '')
  }, [editors, layout.editors])

  useEffect(() => {
    if (!open) return
    setEditors(layout.editors || [])
  }, [layout.editors, open])

  const handleSave = useCallback(async () => {
    try {
      setIsLoading(true)

      await checkToken()

      const addedEditors = editors.filter((e) => !layout.editors?.includes(e))
      const removedEditors = layout.editors?.filter((e) => !editors.includes(e)) || []

      let resultLayout: RLayout = layout

      if (addedEditors.length > 0) {
        const { result } = await transcribeRequest.layouts.addEditors({
          token: '',
          layoutId: layout._id,
          emails: addedEditors,
        })

        resultLayout = result
      }

      if (removedEditors.length > 0) {
        const { result } = await transcribeRequest.layouts.removeEditors({
          token: '',
          layoutId: layout._id,
          emails: removedEditors,
        })

        resultLayout = result
      }

      toast.success('Editors updated')

      onChangeLayout(resultLayout)
      onClose()
    } catch (err) {
      handleError(err)
    } finally {
      setIsLoading(false)
    }
  }, [editors, layout, onChangeLayout, onClose])

  const handleAddEditor = () => {
    if (!isValidEmail(stagedEditor)) {
      toast.error('Invalid email')
      return
    }
    if (editors.includes(stagedEditor)) {
      toast.error('Email already added')
      return
    }
    setEditors((editors) => [...editors, stagedEditor])
    setStagedEditor('')
  }

  return (
    <ClosableDialog open={open} onClose={onClose} titleText="Change Editors">
      <DialogContent>
        <Grid container spacing={1}>
          {editors.map((editor) => (
            <Grid item key={editor}>
              <Chip
                label={editor}
                onDelete={() => {
                  setEditors((editors) => editors.filter((e) => e !== editor))
                }}
              />
            </Grid>
          ))}
          {editors.length === 0 && (
            <Grid item>
              <Typography variant="body1" color="text.secondary">
                No editors yet. Add emails here to allow edit access to this template.
              </Typography>
            </Grid>
          )}
          <Grid item xs={12}>
            <Box sx={{ display: 'flex', alignItems: 'center', pt: 1 }}>
              <TextField
                placeholder="Type an email..."
                value={stagedEditor}
                onChange={(e) => setStagedEditor(e.target.value.toLowerCase())}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    setEditors((editors) => [...editors, stagedEditor])
                    setStagedEditor('')
                  }
                }}
                fullWidth
              />
              <IconButton
                onClick={handleAddEditor}
                color="primary"
                sx={{ ml: 1 }}
                disabled={!stagedEditor}
              >
                <AddIcon />
              </IconButton>
            </Box>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleSave} disabled={!isSavable || isLoading}>
          Save
        </Button>
      </DialogActions>
    </ClosableDialog>
  )
}

export default ChangeEditorsDialog
