import React, { useState, useEffect, useCallback, useRef } from 'react'
import { authRequest } from '../../util/api/auth-api'
import checkToken from '../../util/auth/checkToken'
import ClosableDialog from '../ClosableDialog'
import DialogContent from '@mui/material/DialogContent'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import { useEffectOnce } from 'react-use'
import {
  PaymentElement,
  useStripe,
  useElements,
  Elements,
  CardElement,
} from '@stripe/react-stripe-js'
import { loadStripe, Stripe } from '@stripe/stripe-js'
import toast from 'react-hot-toast'
import { Subscription } from '@counsel-project/counsel-auth-api'
import Box from '@mui/material/Box'
import LoadingButton from '@mui/lab/LoadingButton'

const stripePromise: Promise<null | Stripe> = new Promise((resolve) => {
  authRequest.stripe
    .getPublishableKey()
    .then((res) => {
      resolve(loadStripe(res.key))
    })
    .catch((err) => {
      console.error('Error getting stripe key', err)
      resolve(null)
    })
})

type ElementsContentProps = {
  children: React.ReactNode
}

const ElementsContent = ({ children }: ElementsContentProps) => {
  return <Elements stripe={stripePromise}>{children}</Elements>
}

type PaymentUpdateFormProps = {
  subscriptionId: string
  loading: boolean
  onChangeLoading: (loading: boolean) => void
  onChangeSubscription?: (sub: Subscription) => void
}

const PaymentUpdateForm = ({
  subscriptionId,
  loading,
  onChangeLoading,
  onChangeSubscription,
}: PaymentUpdateFormProps) => {
  const stripe = useStripe()
  const elements = useElements()

  const handleSubmit = useCallback(
    async (e: React.FormEvent<HTMLFormElement>) => {
      try {
        e.preventDefault()

        if (!stripe || !elements) return

        await checkToken()

        onChangeLoading(true)

        const { paymentMethod } = await stripe.createPaymentMethod({
          elements,
        })

        if (!paymentMethod) {
          throw new Error('No payment method')
        }

        const paymentMethodId = paymentMethod?.id

        const res = await authRequest.user.subscriptions.updatePaymentMethod({
          subscriptionId,
          paymentMethodId,
          token: '',
        })

        onChangeSubscription?.(res.subscription)
        toast.success('Payment method updated')
      } catch (err) {
        toast.error('Failed to update payment method')
        console.error(err)
      } finally {
        onChangeLoading(false)
      }
    },
    [stripe, elements, subscriptionId, onChangeSubscription, onChangeLoading]
  )

  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      <Box sx={{ p: 2, backgroundColor: 'background.default', borderRadius: 1 }}>
        <CardElement />
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'end' }}>
        <LoadingButton type="submit" color="primary" sx={{ mt: 2 }} loading={loading}>
          Confirm
        </LoadingButton>
      </Box>
    </form>
  )
}

type ChangeStripePaymentDialogProps = {
  subscriptionId: string
  onChangeSubscription?: (sub: Subscription) => void
  open: boolean
  onClose: () => void
}

const ChangeStripePaymentDialog = ({
  subscriptionId,
  onChangeSubscription,
  open,
  onClose,
}: ChangeStripePaymentDialogProps) => {
  const [loading, setLoading] = useState(false)

  const handleSubscriptionUpdate = useCallback(
    (sub: Subscription) => {
      onChangeSubscription?.(sub)
      onClose()
    },
    [onChangeSubscription, onClose]
  )

  const handleClose = useCallback(() => {
    if (!loading) {
      onClose()
    }
  }, [loading, onClose])

  return (
    <ClosableDialog open={open} onClose={handleClose} titleText="Change Payment Method">
      <DialogContent>
        <ElementsContent>
          <PaymentUpdateForm
            loading={loading}
            subscriptionId={subscriptionId}
            onChangeLoading={setLoading}
            onChangeSubscription={handleSubscriptionUpdate}
          />
        </ElementsContent>
      </DialogContent>
    </ClosableDialog>
  )
}

export default ChangeStripePaymentDialog
