import useMediaQuery from '@mui/material/useMediaQuery'
import TableBase from '../../components/tables/TableBase'
import OrganizationStatsRow from './OrganizationStatsRow'
import { MetricAction } from '@counsel-project/counsel-transcribe-api/dist/common/database/RMetric'
import { DirectoryUserStats } from '@counsel-project/counsel-transcribe-api'
import { useMemo } from 'react'
import { METRIC_NAME_MAPPINGS } from '../../components/metrics/lib'

export type MappedMetric = Record<MetricAction, [number, number]> & {
  userId: string
  email: string
}

export type OrganizationStatsTableProps = {
  searchDisabled?: boolean
  rows: DirectoryUserStats[]
  page: number
  rowsPerPage: number
  rowsPerPageOptions: number[]
  total: number
  loaded: boolean
  sort: 'email' | MetricAction
  direction: 'asc' | 'desc'
  onChangeSort?: (sort: 'email' | MetricAction, direction: 'asc' | 'desc') => void
  onChangePage?: (page: number) => void
  onChangeRowsPerPage?: (rowsPerPage: number) => void
  onClickRow?: (row: MappedMetric) => void
  onSubmitSearch?: (search: string) => void
}

const OrganizationStatsTable = ({
  searchDisabled,
  rows,
  page,
  rowsPerPage,
  rowsPerPageOptions,
  total,
  loaded,
  sort,
  direction,
  onChangeSort,
  onChangePage,
  onChangeRowsPerPage,
  onClickRow,
  onSubmitSearch,
}: OrganizationStatsTableProps) => {
  const isMobile = useMediaQuery('(max-width: 600px)')

  const mappedRows: MappedMetric[] = useMemo(() => {
    return rows.map(
      (row): MappedMetric => ({
        userId: row.userId,
        email: row.email,
        ...(Object.fromEntries(
          row.actions.map((metric) => [metric.action, [metric.count, metric.aggregate]])
        ) as Record<MetricAction, [number, number]>),
      })
    )
  }, [rows])

  return (
    <TableBase
      searchDisabled={searchDisabled}
      columns={[
        { field: 'email', label: 'Email', sortable: true, align: 'left' },
        ...(rows?.[0]?.actions.map((action) => ({
          field: action.action,
          label: METRIC_NAME_MAPPINGS[action.action],
          sortable: true,
          align: 'right' as const,
        })) || []),
      ]}
      rows={mappedRows}
      renderRow={(row: MappedMetric) => (
        <OrganizationStatsRow
          key={row.userId}
          isMobile={isMobile}
          data={row}
          onClick={onClickRow}
        />
      )}
      page={page}
      rowsPerPage={rowsPerPage}
      rowsPerPageOptions={rowsPerPageOptions}
      total={total}
      loaded={loaded}
      sort={sort}
      direction={direction}
      onChangeSort={onChangeSort}
      onChangePage={onChangePage}
      onChangeRowsPerPage={onChangeRowsPerPage}
      onSubmitSearch={onSubmitSearch}
    />
  )
}

export default OrganizationStatsTable
