import { Product } from '@counsel-project/counsel-auth-api'

export type GetDisplayPriceResult = {
  monthlyPrice: number
  annualPrice: number
}

export const getDisplayPrice = (product: Product): GetDisplayPriceResult => {
  const price = product.metadata?.priceText || ''

  const monthlyPrice = parseInt(product.metadata?.monthlyPrice || '0')
  const annualPrice = parseInt(product.metadata?.annualPrice || '0')

  return {
    monthlyPrice: monthlyPrice || parseInt(price.split('/')[0].replace('$', '') || '0'),
    annualPrice: annualPrice || parseInt(price.split('/')[0].replace('$', '') || '0'),
  }
}
