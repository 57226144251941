const exampleLayouts = [
  {
    name: 'Simple Note',
    description: 'Generic note template.',
    identifier: 'generic-example',
    template: `## Note
### Client information
Client name: [Client's name here.]
Client age: [Client's age here.]
Insurance information: [Client's insurance information here if mentioned]
### Overview
\`\`\`ai
Capture an overview of the client's current situation, including any recent events or changes.
\`\`\`
`,
  },
  {
    name: 'SOAP Note',
    description: 'Generic soap note template.',
    identifier: 'soap-example',
    template: `## SOAP Note
### Subjective
\`\`\`ai-exportable
Include the client's subjective experience of their symptoms, including the onset, duration, and severity of symptoms, as well as any factors that exacerbate or alleviate symptoms.
\`\`\`
### Objective
\`\`\`ai-exportable
Include objective data and symptoms, vital signs, physical exam findings, or lab results if mentioned.
\`\`\`
### Assessment
\`\`\`ai-exportable
Include a diagnosis and any differential diagnoses.
\`\`\`
### Plan
\`\`\`ai-exportable
Include a treatment plan, including medications, lifestyle modifications, and follow-up.
\`\`\`

`,
  },
  {
    name: 'DAP Note',
    description: 'Generic dap note template.',
    identifier: 'dap-example',
    template: `## DAP Note
**Data:**
[DAP Data here.]

**Assessment:**
[DAP Assessment here.]
[Also include the interventions used during the session by the clinician. Interventions may be one or multiple of the following: Acceptance and Commitment therapy (ACT), Cognitive Challenging, Cognitive Reframing, Cognitive Behavior Therapy (CBT), Communication Skills, Compliance Issues, Dialectical Behavior Therapy (DBT), Exploration of Coping Patterns, Exploration of Emotions, Exploration of Relationship Patterns, Emotionally Focused Therapy (EFT), Family systems, Gottman, Guided Imagery, Humanistic, Interactive Feedback, Interpersonal Resolutions, Mindfulness Training, Narrative Therapy, Preventative Services, Psycho-Education, Relaxation/Deep Breathing, Review of Treatment Plan/Progress, Role-Play/Behavioral Rehearsal, Structured Problem Solving, Supportive Reflection, Symptom Management]

**Plan:**
[DAP Detailed plan for the client here.]
[If a treatment plan is provided, reference it here and discuss the client's progress in relation to the therapist's treatment plan here.]
`,
  },
  {
    name: 'ASAM Intake Assessment',
    description: 'Detailed ASAM intake assessment template.',
    identifier: 'asam-intake-example',
    template: `
# ASAM Intake Assessment

**Client Name:** [Clients name]
**Date of Service:** [Date of service]
**Clinician Name:** [Clinician name]

## Presenting Problem / Substances Currently Used / Mental Status

| Substances                            | Last Used   | Route   | Amount Commonly Used | Duration   | How often   | Age of First Use   | Comment   |
| ------------------------------------- | ----------- | ------- | -------------------- | ---------- | ----------- | ------------------ | --------- |
| Alcohol                               | [Last time used] | [Route (Oral, Nasal etc)] | [Amount commonly used] | [Duration] | [How often] | [Age of first use] | [Comment] |
| Benzodiazepines                       | [Last time used] | [Route (Oral, Nasal etc)] | [Amount commonly used] | [Duration] | [How often] | [Age of first use] | [Comment] |
| Heroin / Fentanyl                     | [Last time used] | [Route (Oral, Nasal etc)] | [Amount commonly used] | [Duration] | [How often] | [Age of first use] | [Comment] |
| Other Opiaites / Analgesics           | [Last time used] | [Route (Oral, Nasal etc)] | [Amount commonly used] | [Duration] | [How often] | [Age of first use] | [Comment] |
| Methadone                             | [Last time used] | [Route (Oral, Nasal etc)] | [Amount commonly used] | [Duration] | [How often] | [Age of first use] | [Comment] |
| Barbiturates                          | [Last time used] | [Route (Oral, Nasal etc)] | [Amount commonly used] | [Duration] | [How often] | [Age of first use] | [Comment] |
| Sedatives / Hypnotics / Tranquilizers | [Last time used] | [Route (Oral, Nasal etc)] | [Amount commonly used] | [Duration] | [How often] | [Age of first use] | [Comment] |
| Amphetamines                          | [Last time used] | [Route (Oral, Nasal etc)] | [Amount commonly used] | [Duration] | [How often] | [Age of first use] | [Comment] |
| Cocaine / Crack                       | [Last time used] | [Route (Oral, Nasal etc)] | [Amount commonly used] | [Duration] | [How often] | [Age of first use] | [Comment] |
| Cannabis                              | [Last time used] | [Route (Oral, Nasal etc)] | [Amount commonly used] | [Duration] | [How often] | [Age of first use] | [Comment] |
| Hallucinogens and Synthetics          | [Last time used] | [Route (Oral, Nasal etc)] | [Amount commonly used] | [Duration] | [How often] | [Age of first use] | [Comment] |
| Inhalants                             | [Last time used] | [Route (Oral, Nasal etc)] | [Amount commonly used] | [Duration] | [How often] | [Age of first use] | [Comment] |
| Nicotine                              | [Last time used] | [Route (Oral, Nasal etc)] | [Amount commonly used] | [Duration] | [How often] | [Age of first use] | [Comment] |
| Suboxone                              | [Last time used] | [Route (Oral, Nasal etc)] | [Amount commonly used] | [Duration] | [How often] | [Age of first use] | [Comment] |

| Information | Explanation |
| ----------------------------------------------------------- | ---------- |
| History of these INTOXICATION symptoms due to substance use | [Yes / No. If yes, explain] |
| If you have experienced or witnessed an overdose-If yes describe last time, what occured (narcan? ambulance called?) how frequently, etc? | [Yes / No. If yes, describe what occured] |
| History of ACUTE withdrawal symptoms? | [Yes / No. If yes, explain] |
| Current POST acute withdrawal symptoms? | [Yes / No. If yes, explain] |
| Are you on or interested in any in MAT or other anti craving medications? | [Yes / No. If yes, explain] |

**Dimension 1 RATING: [ASAM 4th Edition rating here. State: None, Mild, Moderate, High, or Severe.]**
Client reports that their substance use includes [average amount consumed, and how many days].
In addition, Client [confirms / denies] PAWS symptoms including (shakes, irritability, sleep disturbances, fatigue, anxiety, urges, memory impairment, mood swings, difficulty concentrating, etc.).
[If PAWS is confirmed, state: The client would benefit with treatment to address their PAWS and current substance use.]
Client [confirms / denies] emergency room admissions for intoxication symptoms due to substance use due to these symptoms [list symptoms here].
Client [confirms / denies] the day after using, they [have/ have not] experienced [psychosis / hallucinations / seizures / severe shakes].
[If PAWS is confirmed, state: The client continues to experience PAWS symptoms currently including (shakes, irritability, sleep disturbances, fatigue, anxiety, urges, etc).]

| Information | Explanation |
| --------------------------------------------- | ---------- |
| Medical conditions currently being treated?   | [Yes / No. If yes, explain] |
| Any hospitalizations within the past year?    | [Yes / No. If yes, explain] |
| Any chronic pain issues?                      | [Yes / No. If yes, explain] |
| Currently pregnant?                           | [Yes / No. If yes, explain] |
| Any prescribed medications?                   | [Yes / No. If yes, list medications and prescriber] |
| Any known allergies?                          | [Yes / No. If yes, list allergies] |
| Any physical disabilities?                    | [Yes / No. If yes, explain] |
| History of seizures or other neurological issues? | [Yes / No. If yes, explain] |

**Dimension 2 RATING: [ASAM 4th Edition rating here. State: None, Mild, Moderate, High, or Severe.]**
Client [confirms/denies] current medical conditions that are being treated, including [list conditions here if any].
Client [confirms/denies] recent hospitalizations within the past year, including [list reasons if any].
Client [confirms/denies] chronic pain issues, stating [details about pain issues if any].
Client [is/is not] currently pregnant.
Client [confirms/denies] being on prescribed medications, which include [list medications and prescriber if any].
Client [confirms/denies] having known allergies, listing [specific allergies if any].
Client [confirms/denies] having physical disabilities, including [details if any].
Client [confirms/denies] history of seizures or other neurological issues, explaining [details if any].
Based on this information, it is determined that the client's biomedical conditions [do/do not] pose significant complications for treatment.

| Information | Explanation |
| ---------------------------------- | ---------- |
| Psychological/Emotional Diagnoses? | [Yes / No. If yes, explain] |
| Are you being treated for any of the above conditions? If yes, provide names of counselor, psychiatrist, recovery coach etc (be sure to sign an ROI) | [Yes / No. If yes, explain] |
| Do you have any medication needs at this time? | [Yes / No. If yes, explain] |
| Do you experience any BEHAVIORAL ADDICTIONS or COMPULSIVE behaviors(s) e.g. (gambling, technology, shopping, exercise, sex, eating) | [Yes / No. If yes, explain] |

**Dimension 3 RATING: [ASAM 4th Edition rating here. State: None, Mild, Moderate, High, or Severe.]**
Client [confirms / denies] psychological concerns that could interfere with their recovery efforts (including: [insert diagnoses here]).
It is predicted that these diagnoses may impact treatment due to their severity.
Client [is/ isn't] prescribed medications for their psychiatric issues, and [does/ does not] have a prescriber for these medications.
Client [confirmed having any current treatment providers, as these providers include x OR denied having any current treatment providers], but is open to exploring additional supports in addition to treatment.
Client would benefit from addressing their mental health concerns coincidingly with their substance abuse treatment, and will be referred for outClient care if this level of care is agreed upon.

**Does the client have Past or Current Legal Issues? [clone row below if needed for multiple legal issues]**

| [Yes / No] | Status | Comments/Details: Past or present/How many times/Name & number of parole or probation officer if applicable |
| --- | --- | --- |
| [Name of legal issue.] | [Status of legal issues.] | [Comments and details.] |

**Does the client have any Previous Treatment Episodes? [clone row if needed]**

| [Yes / No] | Level of care | Facility name | Condition treated | Date of admission | Date of discharge | Response to treatment | Discharge Type |
| ---------- | ------------- | ------------- | ----------------- | ----------------- | ----------------- | --------------------- | -------------- |
|            | [Level of care] | [Facility name] | [Condition treated] | [Date of admission] | [Date of discharge] | [Response to treatment] | [Discharge Type] |

| Information | Explanation |
| --- | --- |
| What is the motivation to receive treatment? Why now? | [The personal reason why the client wants to receive treatment here]   |
| Do you currently attend mutual support meetings       | [Yes / No] |
| Do you currently have a sponsor?                      | [Yes / No] |

**Dimension 4 RATING: [ASAM 4th Edition rating here. State: None, Mild, Moderate, High, or Severe.]**
Client appears with [minimal, moderate, high] motivation to change with fair insight to their substance abuse, as client reports seeking treatment now as they report [insert motivation here].
Due to this, client would benefit from continually addressing readiness to change within treatment.
Within the past few years, client reported receiving treatment [insert amount of times] amount of times. [These places include: insert places if mentioned].
Client is admitting to this facility after (receiving/not receiving) treatment at a higher level of care.
Client [confirms involvement within support groups, as these groups include: OR denies involvement within support groups, but is interested in learning more.]
Client [does/ does not] have a sponsor.

| Information | Explanation |
| --- | --- |
| Longest period of sobriety LIFETIME and how maintained? | [Answer] |
| Longest period of sobriety in last 6 months and how maintained? | [Answer] |
| If you have relapsed in the past, what are your main triggers for relapse? | [Answer] |
| How do you procure your substances of choice? | [Answer] |
| Which high-risk behaviors have you engaged in while using/intoxicated? | [Answer] |
| Please explain other high risk behaviors: | [Answer] |

**Dimension 5 RATING: [ASAM 4th Edition rating here. State: None, Mild, Moderate, High, or Severe.]**
Client [confirms/denies] sobriety in the past [reporting they have been sober for X months/years, and maintained this by x].
Client [confirms/denies] high risk behaviors while using. [If confirmed, state what high risk behaviors they have engaged in while using/intoxicated.]
Client's substances are procured by [procure method here.]
Client would benefit from continued education surrounding relapse triggers, in addition to ways to cope with cravings, as Client reports with fair insight within these area's sharing [a trigger of theirs includes/ has trouble identifying triggers].

| Client lives with | Number of children | Children ages |
| --- | --- | --- |
| [Who does the client live with here.] | [Number of children. 0 if none] | [Ages of the children here.] |

| Environment Information | Explanation |
| --- | --- |
| If children are minors, who is the current safe caretaker of these children? | [Answer] |
| Does your present partner or other significant people in your life use alcohol or drugs? | [Yes / No] |
| Is there drug/alcohol use in the home? If yes, explain. | [Yes / No. If yes, explain] |
| What friends/family/community supports do you have? | [Answer] |
| Which of these supports are you willing to sign a release for? They will not be contacted without your consent. | [Answer] |
| Do you have anyone close to you in recovery? | [Yes / No] |
| Do you have a family history of substance abuse? | [Yes / No] |
| Have you had any problems at work or school because of your substance use? | [Yes / No] |

**Dimension 6 RATING: [ASAM 4th Edition rating here. State: None, Mild, Moderate, High, or Severe.]**
Client reports [his/her/their] home environment [low, moderate, high] risk to [his/her/their] sobriety.
Client resides at [client residence here], and [confirms/ denies any] safety concerns within their home.
Client [does not have any significant others that use substances OR has a significant other in their life that does/does not utilize substances].
Client [has/does not have] children under the age of 18.
Client [confirms/denies] they are in a safe setting as they are residing [with X, or alone].
Client [reports concerns/no concerns regarding any abuse neglect or exploitation of their children. OR does not have children under the age of 18.]
Client [confirms/ denies] substance use at home and reports they [do/do not] have anyone close to them that is in recovery.
Client [confirms/denies] a family history of substance use.
Client [is/is not] in school, and [has/ has not] had difficulties at work or school due to their substance use.
Client [confirms/denies] legal issues [including:]

`,
  },
  {
    name: 'Treatment Plan',
    description: 'Generic treatment plan template.',
    identifier: 'treatment-plan-example',
    template: `
## Treatment Plan
**Presenting problem:**
\`\`\`ai
Presenting problem here described in detail. Include impairments that symptoms are causing.
\`\`\`
**Client's goal:**
\`\`\`ai
The client's goal here described in detail. Did the client participate in the treatment process?
\`\`\`
**Treatment goal:**
\`\`\`ai
1-4 treatment goals here described in detail. Goals need to be measurable.
\`\`\`
**What the client will do to meet this goal:**
\`\`\`ai
What the client will do to meet the goal. This needs to be behavioral and measurable
\`\`\`
**Progress measured by:**
\`\`\`ai
How will you measure progress? PHQ-9, ACES, GAD-7, BDI, Therapist observation etc.
\`\`\`
**Estimated time to achieve goal:**
\`\`\`ai
How long will it take to achieve the goal? Include time frame or make the goal session based rather than time.
\`\`\`
**Therapeutic Modalities:**
\`\`\`ai
What modalities will you use to achieve the goal? CBT, DBT, EMDR, ACT etc.
\`\`\`
**Frequency of treatment:**
\`\`\`ai
How often will you meet with the client? Weekly, Bi-weekly, Monthly, etc.
\`\`\`

`,
  },
]

export default exampleLayouts
