import { ApiError, isApiError } from '@counsel-project/client-utils'
import Cookies from 'js-cookie'
import { toast } from 'react-hot-toast'
import { setStore } from '../store/auth'
import { loginWithToken } from './login'
import logout from './logout'

const refreshToken = async (allowMissing?: boolean): Promise<void> => {
  const expiresAt = Cookies.get('expiresAt')
  if (!expiresAt) {
    if (allowMissing) return
    toast.error('You have been logged out due to inactivity')
    await logout(true)
    throw new ApiError('Logged out due to inactivity')
  }

  try {
    const { user } = await loginWithToken()

    setStore({
      user,
    })

    return
  } catch (err) {
    if (isApiError(err) && (err.status === 401 || err.status === 402 || err.status === 403)) {
      if (allowMissing) return
      toast.error('You have been logged out due to inactivity')
      await logout(true)
      throw new ApiError('Logged out due to inactivity')
    }
  }
}

export default refreshToken
