import AddExtraIcon from '@mui/icons-material/AddTaskRounded'
import AddLayoutContainer from './AddLayoutContainer'
import Typography from '@mui/material/Typography'

type AddFormLayoutBoxProps = {
  onClick: () => void
}

const AddFormLayoutBox = ({ onClick }: AddFormLayoutBoxProps) => {
  return (
    <AddLayoutContainer onClick={onClick}>
      <AddExtraIcon fontSize="large" />
      <Typography fontWeight={500} sx={{ mt: 1 }}>
        Create Field-Based Template
      </Typography>
    </AddLayoutContainer>
  )
}

export default AddFormLayoutBox
