import React, { useCallback, useState, useEffect } from 'react'
import { PermissionType, RDirectory } from '@counsel-project/counsel-auth-api'
import { authRequest } from '../../util/api/auth-api'
import checkToken from '../../util/auth/checkToken'
import OrganizationPage from './OrganizationPage'
import { useNavigate, useParams } from 'react-router-dom'
import { useTitle } from 'react-use'

const OrganizationId = () => {
  useTitle('Clinical Notes AI - Organization')
  const navigate = useNavigate()

  const { id } = useParams()

  const [directory, setDirectory] = useState<RDirectory | null>(null)
  const [permission, setPermission] = useState<PermissionType>('counselor')
  const [priority, setPriority] = useState<number>(0)

  const populateDirectory = useCallback(async () => {
    try {
      if (!id) return

      await checkToken()

      const { result, permission, priority } = await authRequest.user.directories.get({
        token: '',
        directoryId: id,
      })

      setDirectory(result)
      setPermission(permission)
      setPriority(priority)
    } catch (err) {
      console.error(err)
      navigate('/organizations/new')
    }
  }, [id, navigate])

  useEffect(() => {
    populateDirectory()
  }, [populateDirectory])

  if (!directory) return null

  return (
    <OrganizationPage
      directory={directory}
      permission={permission}
      priority={priority}
      onChangeDirectory={setDirectory}
    />
  )
}

export default OrganizationId
