import { RLayout, RPatientSession } from '@counsel-project/counsel-transcribe-api'
import log from '../../util/logging'
import { logDictation, logLiveSession } from '../../util/tracking'

export const combineSessionOptions = (sessionOptions: RLayout['config']['options'][]) => {
  const allOptions = sessionOptions.flat()
  // Remove duplicate options that have the same categoryLabel
  const uniqueOptions = allOptions.filter((option, index, self) => {
    return index === self.findIndex((t) => t.categoryLabel === option.categoryLabel)
  })
  return uniqueOptions
}

type createSearchParamsOptions = Record<string, string | number | boolean | undefined | null>

export const createSearchParams = (options: createSearchParamsOptions) => {
  const searchParams = new URLSearchParams()
  Object.entries(options).forEach(([key, value]) => {
    if (value !== undefined && value !== null && value !== '') {
      searchParams.set(key, String(value))
    }
  })
  return searchParams ? '?' + searchParams.toString() : ''
}

type NavigateSessionUrlOptions = {
  tutorial?: boolean
  session: RPatientSession
  expectedStates?: RPatientSession['state'][]
  layout?: string
  group: boolean
  mimetype?: string
}

export const navigateSessionUrl = ({
  tutorial,
  session,
  expectedStates,
  layout,
  group,
  mimetype,
}: NavigateSessionUrlOptions): string | null => {
  if (expectedStates && expectedStates.includes(session.state)) {
    return null
  }

  const queryText = createSearchParams({
    layout,
    tutorial,
    mimetype,
    sessionId: session?._id,
    group,
  })

  if (session.state === 'finished') {
    log.info('Transcribe finished')

    if (session.dictation) {
      logDictation({
        duration: session.duration || 0,
        template: layout,
      })
    } else {
      logLiveSession({
        duration: session.duration || 0,
        template: layout,
      })
    }
    if (layout) {
      return `/builder/${group ? 'group-layout' : 'layout'}/${layout}${queryText}`
    }
    return `/builder${queryText}`
  }

  if (session.state === 'recording') {
    return `/builder/transcribe/${session._id}${queryText}`
  }

  if (session.state === 'finishing') {
    return `/builder/transcribing/${session._id}${queryText}`
  }

  if (session.state === 'errored') {
    return `/builder/transcribe-errored/${session._id}${queryText}`
  }

  return null
}
