import React from 'react'
import Paper from '@mui/material/Paper'
import Box from '@mui/material/Box'
import LinearProgress from '@mui/material/LinearProgress'
import { useTheme } from '@mui/material/styles'

type LoadingPaperProps = {
  children?: React.ReactNode
  loading?: boolean
}

const LoadingPaper = ({ children, loading }: LoadingPaperProps) => {
  const theme = useTheme()

  return (
    <Paper
      sx={{
        mb: 2,
      }}
      elevation={0}
    >
      <Box
        sx={{
          position: 'relative',
          width: '100%',
          borderRadius: theme.shape.borderRadius + 'px ' + theme.shape.borderRadius + 'px 0 0',
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            width: '100%',
            height: 12,
            borderRadius: theme.shape.borderRadius + 'px ' + theme.shape.borderRadius + 'px 0 0',
            overflow: 'hidden',
          }}
        >
          <LinearProgress
            sx={{
              opacity: loading ? 1 : 0,
              transition: 'opacity 0.3s ease',
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
            }}
          />
        </Box>
      </Box>
      <Box sx={{ p: 2 }}>{children}</Box>
    </Paper>
  )
}

export default LoadingPaper
