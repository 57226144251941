import Box from '@mui/material/Box'
import { styled } from '@mui/material/styles'

const StyledBubble = styled('div')`
  align-items: center;
  display: flex;
  height: 17px;
`

interface StyledDotProps {
  delay: number
  variant: 'default' | 'draft'
}

const StyledDot = styled('div', {
  shouldForwardProp: (prop) => prop !== 'delay' && prop !== 'variant',
})<StyledDotProps>`
  animation: mercuryTypingAnimation 1.2s infinite ease-in-out;
  background-color: ${(props) =>
    props.variant === 'draft' ? props.theme.palette.divider : props.theme.palette.primary.light};
  border-radius: 50%;
  height: 7px;
  margin-right: 4px;
  vertical-align: middle;
  width: 7px;
  display: inline-block;

  animation-delay: ${(props) => props.delay}ms;

  :last-child {
    margin-right: 0;
  }

  @keyframes mercuryTypingAnimation {
    0% {
      transform: translateY(0px);
    }
    28% {
      transform: translateY(-7px);
    }
    44% {
      transform: translateY(0px);
    }
  }
`

type BubblesProps = {
  variant?: 'default' | 'draft'
}

const Bubbles = ({ variant = 'default' }: BubblesProps) => {
  return (
    <Box
      sx={(theme) => ({
        bgcolor: variant === 'draft' ? 'transparent' : theme.palette.background.default,
        border: variant === 'draft' ? `2px dashed ${theme.palette.divider}` : 'none',
        color: theme.palette.text.primary,
        p: 1,
        borderRadius: theme.spacing(2),
        paddingLeft: 2,
        paddingRight: 2,
      })}
    >
      <StyledBubble>
        <StyledDot delay={100} variant={variant} />
        <StyledDot delay={200} variant={variant} />
        <StyledDot delay={300} variant={variant} />
      </StyledBubble>
    </Box>
  )
}
export default Bubbles
