import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Cookies from 'js-cookie'
import { useEffect } from 'react'
import Confetti from 'react-confetti'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useTitle } from 'react-use'
import useWindowSize from 'react-use/lib/useWindowSize'
import PageContainer from '../../components/layout/PageContainer'
import { onBeforeUnload, onBeforeUnloadBlocked } from '../../util/onBeforeLoad'
import theme from '../../util/theme'
import { logPurchase, useViewContent } from '../../util/tracking'

const timestamp = new Date().toISOString()

const PurchaseSuccessPage = () => {
  useTitle('Clinical Notes AI - Purchase Success')
  useViewContent('Purchase Success')

  const navigate = useNavigate()

  const [searchParams] = useSearchParams()
  const m = searchParams.get('m')

  const { width, height } = useWindowSize()

  const handleContinue = () => {
    if (m) {
      window.location.href = 'exp+counsel-mobile://app/redirects'
    } else {
      navigate('/onboarding')
    }
  }

  useEffect(() => {
    const transactionId = Cookies.get('checkoutSessionId') || timestamp
    const amountTotal = parseInt(Cookies.get('checkoutAmountTotal') || '0')
    const quantity = parseInt(searchParams.get('quantity') || '0')
    // const itemId = searchParams.get('priceId') || searchParams.get('type') || 'Free'
    const itemName = searchParams.get('type') || 'Free'

    logPurchase({
      productName: itemName,
      productPrice: amountTotal,
      quantity,
      amountTotal,
      transactionId,
    })

    window.onbeforeunload = onBeforeUnloadBlocked

    return () => {
      window.onbeforeunload = onBeforeUnload
    }
  }, [searchParams])

  return (
    <>
      <Confetti
        width={width}
        height={height}
        numberOfPieces={120}
        colors={[
          theme.palette.primary.main,
          theme.palette.secondary.main,
          theme.palette.success.main,
        ]}
      />
      <PageContainer>
        <Box sx={{ width: '100%' }}>
          <Typography variant="h1" fontWeight={500} align="center" sx={{ mt: 4 }}>
            Congrats!
          </Typography>
          <Typography variant="body1" align="center" fontSize={18} fontWeight={500} sx={{ mt: 1 }}>
            You now have a {searchParams.get('type')} membership
          </Typography>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={handleContinue}
            sx={{ mt: 2 }}
          >
            Continue
          </Button>
        </Box>
      </PageContainer>
    </>
  )
}

export default PurchaseSuccessPage
