import { RIntegration } from '@counsel-project/counsel-ehr-api'
import { isApiError } from '@counsel-project/client-utils'
import LoadingButton from '@mui/lab/LoadingButton'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import Link from '@mui/material/Link'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import { useCallback, useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import ClosableDialog from '../../../components/ClosableDialog'
import { ehrRequest } from '../../../util/api/ehr-api'
import checkToken from '../../../util/auth/checkToken'
import useUser from '../../../util/auth/useUser'
import handleError from '../../../util/handleError'

type KipuCredentialsDialogProps = {
  directoryId: string
  open: boolean
  onClose: () => void
  onCreated: (integration: RIntegration) => void
}

const KipuCredentialsDialog = ({
  directoryId,
  open,
  onClose,
  onCreated,
}: KipuCredentialsDialogProps) => {
  const [user] = useUser()

  const [appId, setAppId] = useState('')
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')

  const handleCreate = useCallback(async () => {
    try {
      if (!appId) {
        toast.error('Please fill in all of the fields', { id: 'kipu-credentials-error' })
        return
      }

      setLoading(true)

      await checkToken()

      const { result } = await ehrRequest.kipu.create({
        token: '',
        directoryId,
        appId,
      })

      onCreated(result)
    } catch (err) {
      handleError(err)
      if (isApiError(err)) {
        setError(err.msg)
      }
    } finally {
      setLoading(false)
    }
  }, [appId, directoryId, onCreated])

  useEffect(() => {
    if (!open) {
      setAppId('')
      setError('')
    }
  }, [open])

  if (!user?.admin) {
    return (
      <ClosableDialog open={open} onClose={onClose} titleText="Contact Kipu">
        <DialogContent>
          <Typography variant="body1" color="text.secondary">
            In order to obtain credentials from Kipu, please fill out the Kipu API access form{' '}
            <Link href="https://www.kipuhealth.com/partners-and-integrations/api" target="_blank">
              <Typography variant="body1" fontWeight={500} component="span" color="primary.main">
                here
              </Typography>
            </Link>{' '}
            then contact our team at{' '}
            <Link href="mailto:support@clinicalnotes.ai">
              <Typography variant="body1" fontWeight={500} component="span" color="primary.main">
                support@clinicalnotes.ai
              </Typography>
            </Link>{' '}
          </Typography>
        </DialogContent>
      </ClosableDialog>
    )
  }

  return (
    <ClosableDialog open={open} onClose={onClose} titleText="Add Kipu Integration">
      <DialogContent>
        <Typography variant="body1" color="text.secondary">
          Add your Kipu credentials to connect to your Kipu account.
        </Typography>
        <Typography variant="body1" fontWeight={500} sx={{ mt: 2 }}>
          App ID
        </Typography>
        <TextField
          fullWidth
          placeholder="Type your App ID..."
          value={appId}
          onChange={(e) => setAppId(e.target.value)}
          disabled={loading}
          autoCapitalize="off"
          autoCorrect="off"
          autoComplete="off"
        />
        {error && (
          <Typography color="error" variant="body2" sx={{ mt: 2 }}>
            {error}
          </Typography>
        )}
      </DialogContent>
      <DialogActions>
        <LoadingButton loading={loading} onClick={handleCreate}>
          Connect Kipu
        </LoadingButton>
      </DialogActions>
    </ClosableDialog>
  )
}

export default KipuCredentialsDialog
