import List from '@editorjs/list'
import Header from '@editorjs/header'
import CheckList from '@editorjs/checklist'
import CheckListTable from './modules/CheckListTable'
import PageBreak from './modules/PageBreak'
import AIBox from './modules/AIBox'
import AIExportBox from './modules/AIExportBox'
import AIInline from './modules/AIInline'
import Paragraph from './modules/Paragraph'
// import InlineCheckbox from './modules/InlineCheckbox'

export type GetEditorToolsOptions = {
  onEmail: (text: string) => void
  onCreateNewNote: (text: string) => void
}

export const getTemplateEditorTools = ({
  onEmail,
  onCreateNewNote,
}: GetEditorToolsOptions): any => ({
  paragraph: {
    class: Paragraph,
    inlineToolbar: true,
    config: {
      preserveBlank: true,
    },
  },
  header: { class: Header, inlineToolbar: true },
  list: { class: List, inlineToolbar: true },
  AI: AIInline,
  // InlineCheckbox: InlineCheckbox,
  aiBox: AIBox,
  aiExportBox: {
    class: AIExportBox,
    config: {
      onEmail,
      onCreateNewNote,
    },
  },
  checklist: CheckList,
  table: {
    class: CheckListTable,
    inlineToolbar: true,
  },
  pageBreak: PageBreak,
})
