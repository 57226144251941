import React, { useCallback, useEffect, useState } from 'react'
import ClosableDialog from '../../components/ClosableDialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import { usePatientNomenclature } from '../../util'
import handleError from '../../util/handleError'
import checkToken from '../../util/auth/checkToken'
import { transcribeRequest } from '../../util/api/transcribe-api'
import toast from 'react-hot-toast'
import { useNavigate } from 'react-router-dom'
import { refreshPatientsCache, refreshSessionsCache } from '../../util/api/transcribe-api-cached'

type PatientDeleteDialogProps = {
  patientId: string
  patientLabel: string
  open: boolean
  onClose: () => void
  onDelete?: () => void
  onUnassign?: () => void
  onClear?: () => void
}

const PatientDeleteDialog = ({
  patientId,
  patientLabel,
  open,
  onClose,
  onDelete,
  onUnassign,
  onClear,
}: PatientDeleteDialogProps) => {
  const [loading, setLoading] = useState(false)
  const [canDelete, setCanDelete] = useState(false)

  const patientNomenclature = usePatientNomenclature()

  const navigate = useNavigate()

  const handleUnassignSessions = useCallback(async () => {
    try {
      setLoading(true)

      await checkToken()

      await transcribeRequest.patients.unassignSessions({
        token: '',
        patientId,
      })

      toast.success(`Documents have been unassigned from this ${patientNomenclature}`)
      setCanDelete(true)
      onUnassign?.()
    } catch (err) {
      handleError(err)
    } finally {
      setLoading(false)
    }
  }, [patientId, patientNomenclature, onUnassign])

  const handleDeleteSessions = useCallback(async () => {
    try {
      setLoading(true)

      await checkToken()

      await transcribeRequest.patients.deleteSessions({
        token: '',
        patientId,
      })
      refreshSessionsCache()

      toast.success(`${patientNomenclature}'s documents have been deleted`)
      setCanDelete(true)
      onClear?.()
    } catch (err) {
      handleError(err)
    } finally {
      setLoading(false)
    }
  }, [patientId, patientNomenclature, onClear])

  const handleDelete = useCallback(async () => {
    try {
      setLoading(true)

      await checkToken()

      await transcribeRequest.patients.delete({
        token: '',
        patientId,
      })
      refreshPatientsCache()

      onClose()
      onDelete?.()
      toast.success(`${patientNomenclature} has been deleted`)
      navigate('/sessions')
    } catch (err) {
      handleError(err)
    } finally {
      setLoading(false)
    }
  }, [onClose, onDelete, patientId, patientNomenclature, navigate])

  const populatePatientCanDelete = useCallback(async () => {
    try {
      setLoading(true)

      await checkToken()

      const { total } = await transcribeRequest.sessions.list.all({
        token: '',
        limit: 1,
        search: {
          and: [{ patientId }],
        },
      })

      setCanDelete(total === 0)
    } catch (err) {
      handleError(err)
    } finally {
      setLoading(false)
    }
  }, [patientId])

  useEffect(() => {
    const timeout = setTimeout(() => {
      populatePatientCanDelete()
    }, 10)
    return () => clearTimeout(timeout)
  }, [populatePatientCanDelete])

  return (
    <ClosableDialog open={open} onClose={onClose} titleText={`Delete ${patientNomenclature}`}>
      <DialogContent>
        {canDelete ? (
          <Typography>
            Are you sure you want to delete this {patientNomenclature}? This action cannot be
            undone.
          </Typography>
        ) : (
          <Typography>
            You still have documentation under {patientLabel}. Please unassign or clear the
            documentation before deleting this {patientNomenclature}.
          </Typography>
        )}
      </DialogContent>
      {canDelete ? (
        <DialogActions>
          <Button onClick={onClose} disabled={loading}>
            Cancel
          </Button>
          <Button color="error" disabled={loading} onClick={handleDelete}>
            Delete
          </Button>
        </DialogActions>
      ) : (
        <DialogActions>
          <Button color="error" disabled={loading} onClick={handleUnassignSessions}>
            Unassign Documentation
          </Button>
          <Button
            color="error"
            variant="contained"
            disabled={loading}
            onClick={handleDeleteSessions}
          >
            Delete Patient Documentation
          </Button>
        </DialogActions>
      )}
    </ClosableDialog>
  )
}

export default PatientDeleteDialog
