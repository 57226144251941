import { RPatientSession } from '@counsel-project/counsel-transcribe-api'
import DocumentSections from '../../../components/layout/DocumentSections'
import { useCallback, useEffect, useState } from 'react'
import Paper from '@mui/material/Paper'
import EmailNoteDialog from '../../../components/EmailNoteDialog'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOffRounded'
import VisibilityIcon from '@mui/icons-material/VisibilityRounded'
import CopyIcon from '@mui/icons-material/FileCopyRounded'
import toast from 'react-hot-toast'
import Grid from '@mui/material/Grid'
import SaveIcon from '@mui/icons-material/SaveRounded'
import { transcribeRequest } from '../../../util/api/transcribe-api'
import checkToken from '../../../util/auth/checkToken'
import handleError from '../../../util/handleError'
import ExitAppIcon from '@mui/icons-material/ExitToAppRounded'
import { useNavigate } from 'react-router-dom'
import { createSearchParams } from '../../builder/common'
import ExportSessionOptions from '../../../components/layout/ExportSessionOptions'
import useLayouts from '../../../util/auth/useLayouts'
import { useEffectOnce } from 'react-use'

type ExportOptionsProps = {
  stagedText: string
  hidden: boolean
  onHide: (hidden: boolean) => void
}

const ExportOptions = ({ stagedText, hidden, onHide }: ExportOptionsProps) => {
  const handleToggleHidden = useCallback(() => {
    onHide(!hidden)
  }, [hidden, onHide])

  return (
    <Grid container spacing={2}>
      <Grid item>
        <IconButton
          color="primary"
          onClick={() => {
            navigator.clipboard.writeText(stagedText)
            toast.success('Copied to clipboard', { id: 'copy' })
          }}
        >
          <CopyIcon />
        </IconButton>
      </Grid>
      <Grid item>
        <IconButton onClick={handleToggleHidden} color="secondary">
          {hidden ? <VisibilityOffIcon /> : <VisibilityIcon />}
        </IconButton>
      </Grid>
    </Grid>
  )
}

type MiniDocumentProps = {
  session: RPatientSession
  onChangeSession: (session: RPatientSession) => void
}

const MiniDocument = ({ session, onChangeSession }: MiniDocumentProps) => {
  const [stagedText, setStagedText] = useState(session.note || '')
  const [isSaving, setIsSaving] = useState(false)
  const [emailDialogOpen, setEmailDialogOpen] = useState(false)
  const [stagedEmail, setStagedEmail] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [hidden, setHidden] = useState(false)

  const [layouts, , populateLayouts] = useLayouts()

  useEffectOnce(() => {
    populateLayouts()
  })

  const layout = layouts.find((l) => l.identifier === session?.layout)

  const navigate = useNavigate()

  const handleToggleEmailDialog = useCallback(() => {
    setEmailDialogOpen((prev) => !prev)
  }, [])

  const handleEmailSection = useCallback((text: string) => {
    setStagedEmail(text)
    setEmailDialogOpen((prev) => !prev)
  }, [])

  useEffect(() => {
    setStagedText(session.note || '')
  }, [session.note])

  const handleSave = useCallback(async () => {
    try {
      setIsLoading(true)

      await checkToken()

      const { result } = await transcribeRequest.sessions.update({
        token: '',
        sessionId: session._id,
        note: stagedText,
      })

      onChangeSession(result)
      toast.success('Saved', { id: 'saved' })
      setHidden(true)
    } catch (err) {
      handleError(err)
    } finally {
      setIsLoading(false)
    }
  }, [onChangeSession, stagedText, session._id])

  const handleNavDocument = useCallback(() => {
    const queryText = createSearchParams({
      ref: window.location.pathname + window.location.search,
    })

    navigate(`/sessions/${session._id}${queryText}`)
  }, [navigate, session._id])

  return (
    <Paper elevation={0} sx={{ p: 2 }}>
      <Grid container>
        <Grid item xs>
          <Typography variant="h4">{session.patientLabel || session.summary}</Typography>
        </Grid>
        <Grid item>
          <ExportOptions stagedText={stagedText} hidden={hidden} onHide={setHidden} />
        </Grid>
      </Grid>
      <Divider sx={{ mt: 2 }} />
      {!hidden && (
        <DocumentSections
          id={session._id}
          text={stagedText}
          onChange={setStagedText}
          onSaving={setIsSaving}
          onEmail={handleEmailSection}
          onCreateNewNote={() => {}}
        />
      )}
      <Typography
        variant="body1"
        fontWeight={500}
        fontSize={14}
        color="text.secondary"
        fontStyle="italic"
        sx={{ mt: 2 }}
        aria-label="disclaimer"
        aria-describedby="disclaimer"
      >
        Disclaimer: This is an AI-generated document. Clinician validation and editing is necessary
        before use. Diagnostic codes such as ICD-10, DSM-5 and other suggestions are not a diagnosis
        and must be verified by the clinician.
      </Typography>
      <Grid container sx={{ mt: 2 }}>
        <Grid item xs>
          <Button
            startIcon={<ExitAppIcon />}
            disabled={isSaving || isLoading}
            onClick={handleNavDocument}
          >
            View Document
          </Button>
        </Grid>
        <Grid item>
          <Button endIcon={<SaveIcon />} disabled={isSaving || isLoading} onClick={handleSave}>
            Save
          </Button>
        </Grid>
      </Grid>
      <Grid container sx={{ mt: 2 }}>
        <Grid item xs={12}>
          <ExportSessionOptions
            note={stagedText}
            summary={session.summary}
            patientLabel={session.patientLabel}
            layout={layout}
            startedAtString={session.startedAtString || session.createdAt}
            endedAtString={session.endedAtString || session.createdAt}
          />
        </Grid>
      </Grid>
      <EmailNoteDialog
        patientLabel={session?.patientLabel ?? undefined}
        text={stagedEmail}
        open={emailDialogOpen}
        onClose={handleToggleEmailDialog}
        onSetLoading={setIsLoading}
        startedAtString={
          session?.startedAtString || session?.createdAt || new Date().toLocaleDateString()
        }
        endedAtString={
          session?.endedAtString || session?.createdAt || new Date().toLocaleDateString()
        }
      />
    </Paper>
  )
}

export default MiniDocument
