import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Paper from '@mui/material/Paper'
import NextIcon from '@mui/icons-material/NavigateNextRounded'
import { useNavigate } from 'react-router-dom'

type GenerateMedicareCardProps = {
  sessionId?: string | null
  patientLabel?: string | null
}

const GenerateMedicareCard = ({ sessionId, patientLabel }: GenerateMedicareCardProps) => {
  const navigate = useNavigate()

  const handleGoToGenerateMedicare = () => {
    navigate(
      `/documentation/select/medicare-note/?contextSessionIds=${sessionId || ''}&patientLabel=${
        patientLabel || ''
      }`
    )
  }

  return (
    <Paper elevation={0} sx={{ p: 2, mt: 2 }}>
      <Typography variant="body1" fontSize={20} fontWeight={500} textAlign="center" sx={{ mb: 2 }}>
        Turn this into a Medi-Cal/Medicaid/Medicare note
      </Typography>
      <Button
        endIcon={<NextIcon />}
        fullWidth
        onClick={handleGoToGenerateMedicare}
        color="secondary"
      >
        Generate Progress Note
      </Button>
    </Paper>
  )
}

export default GenerateMedicareCard
