import Typography from '@mui/material/Typography'
import { useEffect, useState } from 'react'
import { useTitle } from 'react-use'
import PageContainer from '../components/layout/PageContainer'
import { textToHTML } from '../util/textToHTML'
import { useViewContent } from '../util/tracking'

const ChangelogPage = () => {
  useViewContent('Changelog')
  useTitle('Clinical Notes AI - Changelog')

  const [changelog, setChangelog] = useState('')

  useEffect(() => {
    fetch('/changelog.txt')
      .then((res) => res.text())
      .then((text) => setChangelog(text))
  }, [])

  if (!changelog) return null

  return (
    <PageContainer>
      <Typography
        variant="body1"
        component="div"
        dangerouslySetInnerHTML={{ __html: textToHTML(changelog) }}
      />
    </PageContainer>
  )
}

export default ChangelogPage
