import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { useCallback, useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { useTitle } from 'react-use'
import PageContainer from '../../../components/layout/PageContainer'
import SaveForLaterDialog from '../../../components/layout/SaveForLaterDialog'
import { transcribeRequest } from '../../../util/api/transcribe-api'
import checkToken from '../../../util/auth/checkToken'
import handleError from '../../../util/handleError'
import { navigateSessionUrl } from '../common'

const BuilderTranscribingPage = () => {
  useTitle('Clinical Notes AI - Loading...')
  const params = useParams()
  const sessionId = params.id || ''

  const [searchParams] = useSearchParams()

  const tutorial = searchParams.get('tutorial') === 'true'
  const mimetype = searchParams.get('mimetype') || ''
  const layout = searchParams.get('layout') || ''
  const group = searchParams.get('group') === 'true'

  const navigate = useNavigate()

  const [saveForLaterDialogOpen, setSaveForLaterDialogOpen] = useState(false)

  const populateStatus = useCallback(async () => {
    try {
      if (!sessionId) return

      await checkToken()

      const { result } = await transcribeRequest.sessions.get({
        token: '',
        sessionId,
      })

      const navUrl = navigateSessionUrl({
        expectedStates: ['finishing'],
        tutorial,
        session: result,
        layout,
        mimetype,
        group,
      })

      if (navUrl) {
        navigate(navUrl)
      }
    } catch (err) {
      handleError(err)
    }
  }, [sessionId, tutorial, navigate, mimetype, layout, group])

  useEffect(() => {
    const interval = setInterval(populateStatus, 4000)
    return () => clearInterval(interval)
  }, [populateStatus])

  const onSaveForLater = async () => {
    toast.success('You may view this session later in the "My Clients" tab', {
      id: 'save-for-later',
      duration: 5000,
    })
    navigate('/builder')
  }

  const onClickSaveForLater = () => {
    setSaveForLaterDialogOpen(true)
  }

  const onCloseSaveForLater = () => {
    setSaveForLaterDialogOpen(false)
  }

  return (
    <PageContainer>
      <Grid container spacing={2} justifyContent="center" alignItems="center" direction="column">
        <Grid item>
          <CircularProgress size={64} />
        </Grid>
        <Grid item>
          <Typography variant="h6" textAlign="center" sx={{ mb: 2 }}>
            We are working on your request. <br />
            This may take a few moments...
          </Typography>
        </Grid>
        <Grid item>
          <Button onClick={onClickSaveForLater}>Save For Later</Button>
        </Grid>
      </Grid>
      <SaveForLaterDialog
        sessionId={sessionId}
        open={saveForLaterDialogOpen}
        onClose={onCloseSaveForLater}
        onSave={onSaveForLater}
      />
    </PageContainer>
  )
}

export default BuilderTranscribingPage
