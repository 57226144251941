export const logPixelPageView = () => {
  try {
    // @ts-ignore
    if (typeof fbq !== 'function') return
    // @ts-ignore
    fbq('track', 'PageView')
  } catch (err) {
    console.error(err)
  }
}

export const logPixelCustomEvent = (eventName: string, options?: any) => {
  try {
    // @ts-ignore
    if (typeof fbq !== 'function') return
    // @ts-ignore
    fbq('trackCustom', eventName, options)
  } catch (err) {
    console.error(err)
  }
}

export const logPixelEvent = (eventName: string, options?: any) => {
  try {
    // @ts-ignore
    if (typeof fbq !== 'function') return
    // @ts-ignore
    fbq('track', eventName, options)
  } catch (err) {
    console.error(err)
  }
}
