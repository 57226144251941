import React from 'react'
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'

const AppleLogo = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg viewBox="0 0 56 56" xmlns="http://www.w3.org/2000/svg">
        <g
          id="Black-Logo-Square"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
          transform="matrix(0.9999999999999999, 0, 0, 0.9999999999999999, 0, -1.7763568394002505e-15)"
        >
          <path
            d="M 29.05 14.815 C 31.101 14.815 33.675 13.386 35.206 11.478 C 36.594 9.75 37.605 7.337 37.605 4.924 C 37.605 4.595 37.576 4.268 37.519 4 C 35.235 4.089 32.489 5.579 30.841 7.576 C 29.54 9.095 28.355 11.478 28.355 13.921 C 28.355 14.279 28.414 14.636 28.441 14.756 C 28.586 14.787 28.819 14.815 29.05 14.815 Z M 21.824 50.867 C 24.626 50.867 25.87 48.93 29.366 48.93 C 32.924 48.93 33.704 50.807 36.825 50.807 C 39.889 50.807 41.941 47.887 43.879 45.028 C 46.046 41.75 46.941 38.532 47 38.383 C 46.799 38.324 40.93 35.85 40.93 28.908 C 40.93 22.891 45.555 20.178 45.815 19.97 C 42.75 15.441 38.096 15.322 36.825 15.322 C 33.385 15.322 30.581 17.467 28.819 17.467 C 26.91 17.467 24.395 15.441 21.419 15.441 C 15.751 15.441 10 20.268 10 29.385 C 10 35.046 12.139 41.035 14.77 44.908 C 17.025 48.185 18.99 50.867 21.824 50.867 Z"
            id=""
            fill="#ffffff"
            fillRule="nonzero"
          />
        </g>
      </svg>
    </SvgIcon>
  )
}

export default AppleLogo
