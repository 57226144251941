import { RDirectory } from '@counsel-project/counsel-auth-api'
import Button from '@mui/material/Button'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import { useState } from 'react'
import ClosableDialog from '../../components/ClosableDialog'
import DirectorySelector from '../../components/DirectorySelector'
import useUser from '../../util/auth/useUser'

type SelectDirectoryDialogProps = {
  open: boolean
  onClose: () => void
  onSelect: (directory: RDirectory) => void
}

const SelectDirectoryDialog = ({ open, onClose, onSelect }: SelectDirectoryDialogProps) => {
  const [directory, setDirectory] = useState<RDirectory | null>(null)

  const [user] = useUser()

  return (
    <ClosableDialog open={open} onClose={onClose} titleText="Select Organization">
      <DialogContent>
        <DirectorySelector
          admin={!!user?.admin}
          value={directory}
          onChange={setDirectory}
          sx={{ mt: 1 }}
        />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            if (directory) {
              onSelect(directory)
            }
          }}
          disabled={!directory}
        >
          Select
        </Button>
      </DialogActions>
    </ClosableDialog>
  )
}

export default SelectDirectoryDialog
