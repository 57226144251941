import { RPatientSession } from '@counsel-project/counsel-transcribe-api'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { wait } from '@testing-library/user-event/dist/utils'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { toast } from 'react-hot-toast'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useEffectOnce, useTitle } from 'react-use'
import SelectAudioDeviceDialog, {
  DeviceOptions,
} from '../../../components/audio/SelectAudioDeviceDialog'
import RecorderOverlay from '../../../components/RecorderOverlay'
import { transcribeRequest } from '../../../util/api/transcribe-api'
import { refreshSessionsCache } from '../../../util/api/transcribe-api-cached'
import checkToken from '../../../util/auth/checkToken'
import refreshToken from '../../../util/auth/refreshToken'
import useLayouts from '../../../util/auth/useLayouts'
import getIsMobile from '../../../util/getIsMobile'
import handleError from '../../../util/handleError'
import log from '../../../util/logging'
import { getContainerHTML, markdownToHtml, removeMaliciousHTML } from '../../../util/markdown'
import { onBeforeUnload, onBeforeUnloadBlocked } from '../../../util/onBeforeLoad'
import {
  clearRecording,
  getIsPaused,
  getIsRecording,
  pauseRecording,
  resumeRecording,
  startRecording,
  stopRecording,
} from '../../../util/record'
import { disableWakelock, enableWakelock } from '../../../util/wakelock'
import ChangeMicrophoneDialog from '../ChangeMicrophoneDialog'
import OverlayLoader from '../OverlayLoader'
import RetryDialog from './RetryDialog'
import { isLanguageCode } from '@counsel-project/counsel-generation-api'

const BuilderTranscribePage = () => {
  useTitle('Clinical Notes AI - Transcribe')

  const [searchParams] = useSearchParams()

  const layoutIdentifier = searchParams.get('layout') || ''
  const dictation = searchParams.get('dictation') === 'true'
  const language = searchParams.get('language') || 'en'
  const startedAtString = searchParams.get('startedAtString') || new Date().toLocaleString()
  const startedAt = useMemo(() => new Date(), [])

  const isMobile = useMemo(() => getIsMobile(), [])

  const navigate = useNavigate()

  const [layouts, , populateLayouts] = useLayouts()

  useEffectOnce(() => {
    populateLayouts()
  })

  const layout = layouts.find((l) => l.identifier === layoutIdentifier)

  const [finishLoading, setFinishLoading] = useState(false)
  const [isRecording, setIsRecording] = useState(getIsRecording())
  const [isPaused, setIsPaused] = useState(getIsPaused())
  const [mediaRecorder, setMediaRecorder] = useState<MediaRecorder | null>(null)
  const [device, setDevice] = useState<MediaDeviceInfo | null>(null)
  const [audioDetected, setAudioDetected] = useState(false)
  const [changeMicrophoneOpen, setChangeMicrophoneOpen] = useState(false)
  const [retryOpen, setRetryOpen] = useState(false)
  const [initialDialogOpen, setInitialDialogOpen] = useState(!isMobile && !dictation)
  const [browserAudioEnabled, setBrowserAudioEnabled] = useState(false)
  const [starting, setStarting] = useState(false)

  const handleChangeDevice = useCallback(
    async (options?: DeviceOptions) => {
      try {
        const { deviceId, browserAudio } = options || {}

        const currentDeviceId = device?.deviceId
        if (currentDeviceId === deviceId && browserAudio === browserAudioEnabled) {
          setChangeMicrophoneOpen(false)
          return
        }

        setChangeMicrophoneOpen(false)
        setMediaRecorder(null)

        await clearRecording()
        const { mediaRecorder, device: d } = await startRecording({
          onAudioDetected: () => {
            setAudioDetected(true)
          },
          deviceId,
          browserAudio,
        })
        setMediaRecorder(mediaRecorder)
        setDevice(d)
      } catch (err) {
        handleError(err)
      }
    },
    [device, browserAudioEnabled]
  )

  const handleFinishTranscribe = useCallback(async () => {
    try {
      setIsRecording(false)
      setFinishLoading(true)

      const blob = await stopRecording()

      await checkToken()

      // Convert blob to file
      const blobMimeType = blob.type
      const blobExtension = blobMimeType.split('/')[1]
      const blobName = `recording.${blobExtension}`
      const file = new File([blob], blobName, { type: blobMimeType })

      const endedAtString = new Date().toLocaleString()
      log.info(endedAtString)

      const formData = new FormData()
      log.info(file.name)
      // Log file size in MB
      log.info(file.size / 1024 / 1024 + ' MB')
      formData.append('files', file)

      let result: RPatientSession
      try {
        const res = await transcribeRequest.sessions.transcribe.sendAudio({
          token: '',
          startedAtString,
          endedAtString,
          formData,
          language: isLanguageCode(language) ? language : 'en',
          dictation,
        })
        result = res.result
      } catch (err) {
        log.error('Send audio failed')
        log.error(err)
        try {
          await wait(2000)
          const res = await transcribeRequest.sessions.transcribe.sendAudio({
            token: '',
            startedAtString,
            endedAtString,
            formData,
            language: isLanguageCode(language) ? language : 'en',
            dictation,
          })
          result = res.result
          log.info('Send audio recovered')
        } catch (err) {
          log.error('Send audio failed')
          log.error(err)
          try {
            await wait(4000)
            const res = await transcribeRequest.sessions.transcribe.sendAudio({
              token: '',
              startedAtString,
              endedAtString,
              formData,
              language: isLanguageCode(language) ? language : 'en',
              dictation,
            })
            result = res.result
            log.info('Send audio recovered')
          } catch (err) {
            log.error('Send audio failed')
            log.error(err)
            throw err
          }
        }
      }

      try {
        // Play audio
        const audio = new Audio('/micend.wav')
        audio.play().catch(log.info)
      } catch (err) {
        log.info(err)
      }

      log.info('Finished transcribe')

      const queryText = `?layout=${layoutIdentifier}`

      // Nav to next page
      navigate(`/builder/transcribing/${result._id}${queryText}`)
    } catch (err) {
      log.error('Finish failed')
      handleError(err)
      setRetryOpen(true)
    } finally {
      setFinishLoading(false)
    }
  }, [navigate, layoutIdentifier, language, dictation, startedAtString])

  useEffect(() => {
    const interval = setInterval(() => {
      const now = new Date()
      const diff = now.getTime() - startedAt.getTime()
      if (diff > 1000 * 60 * 60 * 6) {
        handleFinishTranscribe()
      }
    }, 1000 * 60)

    return () => clearInterval(interval)
  }, [startedAt, handleFinishTranscribe])

  const handleStartSession = useCallback(async (options?: DeviceOptions) => {
    try {
      if (getIsRecording()) return

      setStarting(true)
      setInitialDialogOpen(false)
      setIsRecording(true)

      const { browserAudio, deviceId } = options || {}

      await refreshToken()

      await wait(400)

      const { mediaRecorder, device } = await startRecording({
        onAudioDetected: () => {
          setAudioDetected(true)
        },
        browserAudio,
        deviceId,
      })

      setBrowserAudioEnabled(browserAudio || false)
      setMediaRecorder(mediaRecorder)
      setDevice(device)

      enableWakelock()

      // Set navigate away lock
      try {
        window.onbeforeunload = onBeforeUnloadBlocked
      } catch (err) {
        log.info(err)
      }
    } catch (err) {
      handleError(err)
    } finally {
      setStarting(false)
    }
  }, [])

  useEffect(() => {
    if (!getIsMobile() && !dictation) return
    if (getIsRecording()) return
    const timeout = setTimeout(() => {
      if (getIsRecording()) return
      if (!getIsMobile() && !dictation) return
      setInitialDialogOpen(false)
      handleStartSession()
    }, 100)

    return () => clearTimeout(timeout)
  }, [handleStartSession, dictation])

  const handleRefreshToken = useCallback(async () => {
    try {
      await checkToken()
    } catch (err) {
      handleError(err)
    }
  }, [])

  useEffect(() => {
    const interval = setInterval(() => {
      handleRefreshToken()
    }, 1000 * 60)

    return () => clearInterval(interval)
  }, [handleRefreshToken])

  const handlePause = () => {
    pauseRecording()
    setIsPaused(true)
  }

  const handleResume = () => {
    resumeRecording()
    setIsPaused(false)
  }

  const handleNavBack = useCallback(async () => {
    try {
      setFinishLoading(true)

      await clearRecording()

      await wait(1000)

      refreshSessionsCache()

      navigate(`/builder`)
    } catch (err) {
      log.error(err)
    } finally {
      setFinishLoading(false)
    }
  }, [navigate])

  useEffect(() => {
    return () => {
      // Remove navigate away lock
      try {
        window.onbeforeunload = onBeforeUnload
      } catch (err) {
        log.info(err)
      }

      // Remove wake lock
      disableWakelock()

      clearRecording().catch((err) => {
        log.error(err)
      })
    }
  }, [])

  const html = useMemo(() => {
    return layout
      ? dictation
        ? getContainerHTML(markdownToHtml(layout.dictationDescription))
        : getContainerHTML(markdownToHtml(layout.sessionDescription || layout.dictationDescription))
      : ''
  }, [layout, dictation])

  if (initialDialogOpen) {
    return (
      <SelectAudioDeviceDialog
        open={true}
        onClose={handleNavBack}
        onDeviceSelected={handleStartSession}
        disabled={starting}
      />
    )
  }

  return (
    <>
      {finishLoading && (
        <OverlayLoader>
          <Typography variant="body1" fontWeight={500} textAlign="center" sx={{ pb: 2 }}>
            Loading...
            <br />
            Please do not exit this page
          </Typography>
        </OverlayLoader>
      )}
      <RecorderOverlay
        recording={isRecording}
        paused={isPaused}
        loading={finishLoading}
        onClickPause={handlePause}
        onClickResume={handleResume}
        onClickFinish={handleFinishTranscribe}
        onClickBack={handleNavBack}
        onClickChangeMicrophone={() => setChangeMicrophoneOpen(true)}
        mediaRecorder={mediaRecorder}
        finishLabel={dictation ? 'Finish Dictation' : 'Finish Session'}
        pauseLabel={dictation ? 'Pause Dictation' : 'Pause Session'}
        resumeLabel={dictation ? 'Resume Dictation' : 'Resume Session'}
        device={device}
        audioDetected={audioDetected}
        overlayText={
          html ? (
            <Box
              sx={{
                fontSize: 16,
                textAlign: 'left',
              }}
            >
              <div
                dangerouslySetInnerHTML={{
                  __html: removeMaliciousHTML(html),
                }}
              />
            </Box>
          ) : undefined
        }
      />
      <ChangeMicrophoneDialog
        open={changeMicrophoneOpen}
        onClose={() => setChangeMicrophoneOpen(false)}
        onSelectDevice={handleChangeDevice}
        browserAudio={browserAudioEnabled}
      />
      <RetryDialog open={retryOpen} onClose={() => {}} onRetry={handleFinishTranscribe} />
    </>
  )
}

export default BuilderTranscribePage
