import { RLayout } from '@counsel-project/counsel-transcribe-api'
import ClinicalInfoDialog from './ClinicalInfoDialog'
import toast from 'react-hot-toast'

type AddObservationsDialogProps = {
  value: string
  layout: RLayout
  open: boolean
  onClose: () => void
  onSubmit: (value: string) => void
}

const AddObservationsDialog = ({
  value,
  open,
  layout,
  onClose,
  onSubmit,
}: AddObservationsDialogProps) => {
  const handleSubmit = (value: string) => {
    if (value) {
      toast.success('Observations added')
    } else {
      toast.success('Observations removed')
    }
    onSubmit(value)
    onClose()
  }

  return (
    <ClinicalInfoDialog
      value={value}
      title="Add Observations"
      buttonText="Add Observations"
      placeholder="Dictate or type your clinical observations during the session..."
      layout={layout}
      open={open}
      onClose={onClose}
      onSubmit={handleSubmit}
    />
  )
}

export default AddObservationsDialog
