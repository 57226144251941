import ClosableDialog from '../../../components/ClosableDialog'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'

type RetryDialogProps = {
  open: boolean
  loading?: boolean
  onClose: () => void
  onRetry: () => void
}

const RetryDialog = ({ open, loading, onClose, onRetry }: RetryDialogProps) => {
  return (
    <Dialog open={open}>
      <DialogTitle>Connection Error</DialogTitle>
      <DialogContent>
        <Typography variant="body1">
          It looks like there was an error connecting to the server. Do not close or refresh your
          browser window. Please click the retry button. If this continues, please contact support
          for help.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={onRetry} disabled={loading}>
          Retry
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default RetryDialog
