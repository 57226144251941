import { useState, useEffect } from 'react'
import posthog from 'posthog-js'

export const useFeatureFlag = (flag: string) => {
  const [isEnabled, setIsEnabled] = useState(!!posthog.isFeatureEnabled(flag))

  useEffect(() => {
    const callback = () => {
      setIsEnabled(!!posthog.isFeatureEnabled(flag))
    }

    posthog.onFeatureFlags(callback)

    return () => {
      posthog.featureFlags.removeFeatureFlagsHandler(callback)
    }
  }, [flag])

  return isEnabled
}

export const getFeatureFlag = (flag: string) => {
  return !!posthog.isFeatureEnabled(flag)
}
