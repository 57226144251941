import ArrowForwardIcon from '@mui/icons-material/ArrowForwardRounded'
import Button from '@mui/material/Button'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import { useNavigate } from 'react-router-dom'
import { useTitle } from 'react-use'
import PageContainer from '../components/layout/PageContainer'
import TermsOfService from '../components/legal/TermsOfService'
import { useViewContent } from '../util/tracking'

const TermsOfServicePage = () => {
  useViewContent('Terms Of Service')
  useTitle('Clinical Notes AI - Terms of Service')

  const navigate = useNavigate()

  return (
    <PageContainer>
      <Paper elevation={0} sx={{ p: 3 }}>
        <Typography variant="h4" component="h1" sx={{ mb: 4 }}>
          Terms Of Service
        </Typography>
        <TermsOfService />
        <Button
          href="/privacy-policy"
          onClick={(e) => {
            e.preventDefault()
            navigate('/privacy-policy')
          }}
          endIcon={<ArrowForwardIcon />}
        >
          See our Privacy Policy
        </Button>
      </Paper>
    </PageContainer>
  )
}

export default TermsOfServicePage
