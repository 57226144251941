import { RPatientSession } from '@counsel-project/counsel-transcribe-api'
import Grid from '@mui/material/Grid'
import { useState, useEffect, useCallback, useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'
import checkToken from '../../../util/auth/checkToken'
import { transcribeRequest } from '../../../util/api/transcribe-api'
import handleError from '../../../util/handleError'
import PageContainer from '../../../components/layout/PageContainer'
import MiniDocument from './MiniDocument'
import Breadcrumbs, { BreadcrumbPath } from '../../../components/Breadcrumbs'
import { createSearchParams } from '../../builder/common'

const MultipleSessionsPage = () => {
  const [searchParams] = useSearchParams()
  const mainSessionId = searchParams.get('sessionId')
  const sessionIds = useMemo(
    () => searchParams.get('sessionIds')?.split(',')?.filter(Boolean) || [],
    [searchParams]
  )
  const backPage = searchParams.get('ref')

  const [sessions, setSessions] = useState<RPatientSession[]>([])
  const [isLoading, setIsLoading] = useState(true)
  const [total, setTotal] = useState(0)

  const populateSessions = useCallback(async () => {
    try {
      setIsLoading(true)

      await checkToken()

      const { results, total } = await transcribeRequest.sessions.list.mine({
        token: '',
        search: {
          or: sessionIds.map((_id) => ({ _id })),
        },
        limit: 50,
      })

      setSessions(results)
      setTotal(total)
    } catch (err) {
      handleError(err)
    } finally {
      setIsLoading(false)
    }
  }, [sessionIds])

  useEffect(() => {
    populateSessions()
  }, [populateSessions])

  const changeSessionHandler = (session: RPatientSession) => (newSession: RPatientSession) => {
    setSessions((sessions) => sessions.map((s) => (s._id === session._id ? newSession : s)))
  }

  const getBuilderBackPath = useCallback(() => {
    const queryText = createSearchParams({
      sessionId: mainSessionId,
      shownSessionIds: sessionIds.join(','),
    })

    return `/builder${queryText}`
  }, [sessionIds, mainSessionId])

  const getGeneratingPath = useCallback(() => {
    const queryText = createSearchParams({
      sessionId: mainSessionId,
      shownSessionIds: sessionIds.join(','),
    })

    return `/builder/generating${queryText}`
  }, [sessionIds, mainSessionId])

  const breadcrumbs = [
    { name: 'Builder', path: getBuilderBackPath() },
    { name: 'Generating', path: getGeneratingPath() },
    { name: 'View All' },
  ]

  return (
    <PageContainer>
      <Breadcrumbs paths={breadcrumbs} />
      <Grid container spacing={2}>
        {sessions
          .filter((s) => !!s.note && s.state && s.state !== 'generating' && s.state !== 'errored')
          .map((session) => (
            <Grid item key={session._id} xs={12}>
              <MiniDocument session={session} onChangeSession={changeSessionHandler(session)} />
            </Grid>
          ))}
      </Grid>
    </PageContainer>
  )
}

export default MultipleSessionsPage
