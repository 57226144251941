import { RTranscriptLog } from '@counsel-project/counsel-transcribe-api'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { useCallback, useEffect, useState } from 'react'
import { transcribeRequest } from '../../util/api/transcribe-api'
import checkToken from '../../util/auth/checkToken'
import TranscriptDeidMapping from './TranscriptDeidMapping'

type TranscriptLogContentProps = {
  transcript: Omit<RTranscriptLog, 'transcript'>
}

const TranscriptLogContent = ({ transcript }: TranscriptLogContentProps) => {
  const [selectedTranscript, setSelectedTranscript] = useState<RTranscriptLog | null>(null)

  const populateTranscript = useCallback(async () => {
    await checkToken()

    const { result } = await transcribeRequest.logs.transcript.get({
      token: '',
      transcriptLogId: transcript._id,
    })

    setSelectedTranscript(result)
  }, [transcript._id])

  useEffect(() => {
    const timeout = setTimeout(() => {
      populateTranscript()
    }, 10)

    return () => clearTimeout(timeout)
  }, [populateTranscript])

  return (
    <>
      <Typography variant="body1" component="p">
        {new Date(transcript.createdAt).toString()}
      </Typography>
      <Typography variant="body1" component="p">
        Email: <strong>{transcript.email}</strong>
      </Typography>
      <Typography variant="body1" component="p">
        Language: <strong>{transcript.language}</strong>
      </Typography>
      <Typography variant="body1" component="p">
        ID: <strong>{transcript._id}</strong>
      </Typography>
      <Typography variant="body1" component="p">
        Request ID: <strong>{transcript.requestId}</strong>
      </Typography>
      <Typography variant="body1" component="p">
        length: <strong>{selectedTranscript?.transcript?.length}</strong>
      </Typography>
      <Typography variant="body1" component="p">
        Duration: <strong>{selectedTranscript?.duration}s</strong>
      </Typography>
      <Box sx={{ mt: 2 }}>
        <TranscriptDeidMapping transcriptLog={transcript} />
      </Box>
      <Box sx={{ mt: 2 }}>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography variant="h6" component="p">
              Transcript
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              {selectedTranscript?.transcript?.split('\n')?.map((line, i) => (
                <span key={i} className="data-hj-suppress">
                  {line}
                  <br />
                </span>
              ))}
            </Typography>
          </AccordionDetails>
        </Accordion>
      </Box>
    </>
  )
}

export default TranscriptLogContent
