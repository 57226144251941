import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import { useNavigate } from 'react-router-dom'
import PageContainer from '../components/layout/PageContainer'
import Cookies from 'js-cookie'

const TestPage = () => {
  const handleTest = () => {
    const token = Cookies.get('token')

    Cookies.remove('token')
    Cookies.remove('user')
    Cookies.remove('refreshToken')
    Cookies.remove('expiresAt')

    document.cookie = `token=${token}; path=/; secure; SameSite=Strict`
    window.location.href = `https://beta.clinicalnotesai.com/api/auto-login?cb=${encodeURIComponent(
      '/sessions'
    )}`
  }

  return (
    <PageContainer>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Button onClick={handleTest}>Test auto-login</Button>
        </Grid>
      </Grid>
    </PageContainer>
  )
}

export default TestPage
