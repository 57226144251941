import { RLayout, RPatientSession } from '@counsel-project/counsel-transcribe-api'
import CloseIcon from '@mui/icons-material/CloseRounded'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import Paper from '@mui/material/Paper'
import Skeleton from '@mui/material/Skeleton'
import Typography from '@mui/material/Typography'
import { capitalize } from '@counsel-project/client-utils'
import { getRelativeTime, usePatientNomenclature } from '../../util'

type SessionItemProps = {
  regenerate?: boolean
  session?: RPatientSession | null
  layout?: RLayout | null
  onClickClose?: () => void
}

const SessionItem = ({ session, regenerate, layout, onClickClose }: SessionItemProps) => {
  const patientNomenclature = usePatientNomenclature()

  if (!session) {
    return (
      <Paper variant="outlined">
        <Grid container spacing={2} padding={2}>
          <Grid item xs={12}>
            <Skeleton variant="text" width="100%" />
          </Grid>
          <Grid item xs={12}>
            <Skeleton variant="text" width="100%" />
          </Grid>
        </Grid>
      </Paper>
    )
  }

  const duration =
    session.duration ||
    (new Date(session?.endedAt || 0).getTime() - new Date(session?.startedAt || 0).getTime()) / 1000

  return (
    <Paper variant="outlined">
      <Grid container spacing={1} padding={2}>
        <Grid item container xs={12}>
          <Grid item xs>
            <Typography variant="body1" fontWeight={500}>
              {regenerate
                ? `Regenerate this ${layout?.type || 'document'}`
                : `Generate from this ${session.dictation ? 'dictation' : 'live session'}`}
            </Typography>
          </Grid>
          {onClickClose && (
            <Grid item>
              <IconButton onClick={onClickClose}>
                <CloseIcon />
              </IconButton>
            </Grid>
          )}
        </Grid>
        <Grid item xs>
          {session.patientLabel ? (
            <Typography variant="body2" color="text.secondary">
              {capitalize(patientNomenclature)}: {session.patientLabel}
            </Typography>
          ) : (
            <></>
          )}
          {duration && !session.dictation ? (
            <Typography variant="body2" color="text.secondary">
              Lasted {getRelativeTime(duration)}
            </Typography>
          ) : (
            <></>
          )}
          {session.dictation && (
            <Typography variant="body2" color="text.secondary">
              Dictation
            </Typography>
          )}
          <Typography variant="body2" color="text.secondary">
            {session.startedAtString || new Date(session.createdAt).toLocaleString()}
          </Typography>
          {session.layout && layout && (
            <Typography variant="body2" color="text.secondary">
              {layout.name}
            </Typography>
          )}
        </Grid>
      </Grid>
    </Paper>
  )
}

export default SessionItem
