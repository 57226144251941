import { RPatientSession } from '@counsel-project/counsel-transcribe-api'
import Box from '@mui/material/Box'
import CircularProgress from '@mui/material/CircularProgress'
import DialogContent from '@mui/material/DialogContent'
import Grid from '@mui/material/Grid'
import { useCallback, useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { useNavigate } from 'react-router-dom'
import ClosableDialog from '../../components/ClosableDialog'
import { transcribeRequest } from '../../util/api/transcribe-api'
import checkToken from '../../util/auth/checkToken'
import SessionItem from '../sessions/SessionItem'

type SessionsDialogProps = {
  open: boolean
  onClose: () => void
  transcribeId: string
}

const SessionsDialog = ({ open, onClose, transcribeId }: SessionsDialogProps) => {
  const [isLoading, setIsLoading] = useState(false)
  const [sessions, setSessions] = useState<RPatientSession[]>([])

  const navigate = useNavigate()

  const populateSessions = useCallback(async () => {
    setIsLoading(true)
    try {
      await checkToken()

      const { results } = await transcribeRequest.logs.sessions.list({
        token: '',
        search: {
          and: [{ transcriptId: transcribeId }],
        },
        limit: 20,
      })
      setSessions(results)
    } catch (err) {
      toast.error('Failed to fetch sessions')
    } finally {
      setIsLoading(false)
    }
  }, [transcribeId])

  useEffect(() => {
    const timeout = setTimeout(() => {
      populateSessions()
    }, 10)
    return () => clearTimeout(timeout)
  }, [populateSessions])

  const onClickSession = useCallback(
    (sessionId: string) => {
      navigate(`/sessions/${sessionId}?ref=${window.location.pathname}${window.location.search}`)
    },
    [navigate]
  )

  return (
    <ClosableDialog open={open} onClose={onClose} titleText="Sessions">
      <DialogContent>
        {!isLoading ? (
          <Box>
            <Grid container spacing={2}>
              {sessions.map((session) => (
                <Grid item key={session._id} xs={12}>
                  <Box>
                    <SessionItem
                      href="#"
                      session={session}
                      onClick={() => onClickSession(session._id)}
                    />
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Box>
        ) : (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <CircularProgress />
            </Grid>
          </Grid>
        )}
      </DialogContent>
    </ClosableDialog>
  )
}

export default SessionsDialog
