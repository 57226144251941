import EditorJS from '@editorjs/editorjs'
import { htmlToMarkdown, markdownToHtml } from '../../util/markdown'
import React, { useEffect, useRef, useState, useCallback } from 'react'

const hToBlock = (h: HTMLHeadingElement) => {
  const block: EditorJS.OutputData['blocks'][0] = {
    type: 'header',
    data: {
      level: parseInt(h.tagName[1]),
      text: h.innerHTML,
    },
  }
  return block
}

const pToBlock = (p: HTMLParagraphElement) => {
  const block: EditorJS.OutputData['blocks'][0] = {
    type: 'paragraph',
    data: {
      text: p.innerHTML,
    },
  }
  return block
}

export const checklistClassname = 'task-list-item'

const ulToBlock = (ul: HTMLUListElement) => {
  if (Array.from(ul.children).every((li) => li.classList.contains(checklistClassname))) {
    const block: EditorJS.OutputData['blocks'][0] = {
      type: 'checklist',
      data: {
        items: Array.from(ul.children).map((li) => {
          const input = li.querySelector('input')
          return {
            text: 'innerText' in li ? li.innerText : '',
            checked: input?.checked,
          }
        }),
      },
    }
    return block
  }

  const block: EditorJS.OutputData['blocks'][0] = {
    type: 'list',
    data: {
      style: 'unordered',
      items: Array.from(ul.children).map((li) => ('innerText' in li ? li.innerText : '')),
    },
  }
  return block
}

const olToBlock = (ol: HTMLOListElement) => {
  const block: EditorJS.OutputData['blocks'][0] = {
    type: 'list',
    data: {
      style: 'ordered',
      items: Array.from(ol.children).map((li) => ('innerText' in li ? li.innerText : '')),
    },
  }
  return block
}

const tableToBlock = (table: HTMLTableElement) => {
  const headers = table.tHead
    ? Array.from(table.tHead.rows[0].cells).map((cell) => cell.innerHTML)
    : []

  const content = Array.from(table.tBodies[0].rows).map((row) =>
    Array.from(row.cells).map((cell) => cell.innerHTML)
  )

  const block: EditorJS.OutputData['blocks'][0] = {
    type: 'table',
    data: {
      withHeadings: !!table.tHead,
      content: [headers, ...content],
    },
  }
  return block
}

const htmlToBlocks = (html: string): EditorJS.OutputData['blocks'] => {
  const div = document.createElement('div')
  div.innerHTML = html
  const blocks = Array.from(div.children)
    .map((child) => {
      if (child instanceof HTMLHeadingElement) {
        return hToBlock(child)
      } else if (child instanceof HTMLParagraphElement) {
        return pToBlock(child)
      } else if (child instanceof HTMLUListElement) {
        return ulToBlock(child)
      } else if (child instanceof HTMLOListElement) {
        return olToBlock(child)
      } else if (child instanceof HTMLTableElement) {
        return tableToBlock(child)
      }
      return null
    })
    .filter((c): c is EditorJS.OutputData['blocks'][0] => !!c)
  div.remove()
  return blocks
}

export default htmlToBlocks
