import { RLayout, RPatientSession } from '@counsel-project/counsel-transcribe-api'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Divider from '@mui/material/Divider'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { capitalize } from '@counsel-project/client-utils'
import Cookies from 'js-cookie'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useEffectOnce } from 'react-use'
import PatientConsentDialog from '../../components/PatientConsentDialog'
import SearchField from '../../components/SearchField'
import PageContainer from '../../components/layout/PageContainer'
import LicenseCard from '../../components/licensing/LicenseCard'
import { transcribeRequest } from '../../util/api/transcribe-api'
import checkToken from '../../util/auth/checkToken'
import useActiveLicense from '../../util/auth/useActiveLicense'
import useLayouts from '../../util/auth/useLayouts'
import useUser from '../../util/auth/useUser'
import log from '../../util/logging'
import HighlightTag from './HighlightTag'
import LayoutOption from './LayoutOption'
import LayoutPreviewDialog from './LayoutPreviewDialog'
import SelectGenerateTypeDialog from './SelectGenerateTypeDialog'
import SessionItem from './SessionItem'
import { createSearchParams } from './common'
import { getFeatureFlag } from '../../util/featureFlags'
import { LanguageCode } from '@counsel-project/counsel-generation-api'

const BuilderPage = () => {
  const [user] = useUser()
  const [layouts, , populateLayouts] = useLayouts()

  useEffectOnce(() => {
    populateLayouts()
  })

  const [activeLicense, , populateActiveLicense] = useActiveLicense()

  useEffectOnce(() => {
    populateActiveLicense()
  })

  const [searchParams, setSearchParams] = useSearchParams()
  const sessionId = searchParams.get('sessionId') || ''
  const sessionType = searchParams.get('sessionType') || ''
  const submittedSearch = searchParams.get('search') || ''
  const shownSessionIds = searchParams.get('shownSessionIds') || ''
  const regenerate = searchParams.get('regenerate') || ''
  const showAll = searchParams.get('showAll') === 'true'
  const generateOpen = searchParams.get('generateOpen') === 'true'
  const consentOpen = searchParams.get('consentOpen') === 'true'
  const tutorial = searchParams.get('tutorial') === 'true'
  const selectedLayoutIdentifier = searchParams.get('layout')
  const selectedGenerateType = searchParams.get('generateType') || ''
  const language = (searchParams.get('language') as LanguageCode) || 'en'

  const [openLayoutPreview, setOpenLayoutPreview] = useState(false)
  const [session, setSession] = useState<RPatientSession | null>(null)
  const selectedLayout = useMemo(
    () => layouts.find((l) => l.identifier === selectedLayoutIdentifier),
    [layouts, selectedLayoutIdentifier]
  )

  const navigate = useNavigate()

  const handleOpenBuilder = useCallback(() => {
    if (!selectedLayout) return

    const url = `/builder/${selectedLayout.config.multiplePeople ? 'group-layout' : 'layout'}/${
      selectedLayout.identifier
    }`
    const checkLicenseQuery = createSearchParams({
      limit: selectedLayout.type === 'document' ? 'documents' : 'sessions',
      cb: url,
    })

    navigate(`/check-license-limit${checkLicenseQuery}`)
  }, [navigate, selectedLayout])

  const handleToggleShowAll = useCallback(() => {
    setSearchParams((prev) => {
      prev.set('showAll', showAll ? 'false' : 'true')
      prev.delete('search')
      return prev
    })
  }, [showAll, setSearchParams])

  const shownLayouts = useMemo(() => {
    // Filter layouts based on profession
    if (!user) return []
    if (showAll) {
      return layouts
    }
    let newLayouts = layouts.filter(
      (layout) =>
        layout.name.toLowerCase().includes(submittedSearch.toLowerCase()) ||
        layout.tags.some((t: string) =>
          t.toLocaleLowerCase().includes(submittedSearch.toLowerCase())
        )
    )

    newLayouts = newLayouts.filter(
      (layout) =>
        layout.config.professions.includes(user.profession || 'therapist') ||
        layout.config.professions.length === 0
    )

    if (sessionType && sessionType !== 'Other') {
      newLayouts = newLayouts.filter((layout) => layout.sessionTypes?.includes(sessionType))
    }

    return newLayouts
  }, [layouts, user, submittedSearch, showAll, sessionType])

  const mainTags = useMemo(() => {
    const tags: {
      tag: string
      count: number
    }[] = []

    layouts
      .filter(
        (layout) =>
          layout.config.professions.includes(user?.profession || 'therapist') ||
          layout.config.professions.length === 0
      )
      .forEach((layout) => {
        const tag = layout.tags[0]
        if (!tag) return
        const existingTag = tags.find((t) => t.tag === tag)
        if (existingTag) {
          existingTag.count++
        } else {
          tags.push({ tag, count: 1 })
        }
      })

    return tags
      .map((tag) => tag.tag)
      .slice(0, 10)
      .sort((a, b) => {
        // "Custom", ""Progress Notes", "Intake Notes" first
        if (a === 'Custom') return -1
        if (b === 'Custom') return 1
        if (a === 'Progress Notes') return -1
        if (b === 'Progress Notes') return 1
        if (a === 'Intake Notes') return -1
        if (b === 'Intake Notes') return 1
        return a.localeCompare(b, undefined, { sensitivity: 'base' })
      })
  }, [user, layouts])

  const handleChangeSearch = useCallback(
    (value: string) => {
      if (!value) {
        setSearchParams((prev) => {
          prev.delete('search')
          return prev
        })
      } else {
        setSearchParams((prev) => {
          prev.set('search', value)
          return prev
        })
      }
    },
    [setSearchParams]
  )

  const tagSearchHandler = useCallback(
    (tag: string) => () => {
      if (submittedSearch.toLowerCase() === tag.toLowerCase()) {
        setSearchParams((prev) => {
          prev.delete('search')
          return prev
        })
      } else {
        setSearchParams((prev) => {
          prev.set('search', tag)
          return prev
        })
      }
    },
    [submittedSearch, setSearchParams]
  )

  const populateSession = useCallback(async () => {
    try {
      if (!sessionId && !regenerate) return
      await checkToken()
      const usedSessionId = regenerate || sessionId
      const { result } = await transcribeRequest.sessions.get({
        token: '',
        sessionId: usedSessionId,
      })
      setSession(result)
    } catch (err) {
      log.error(err)
      setSession(null)
      setSearchParams((prev) => {
        prev.delete('sessionId')
        return prev
      })
    }
  }, [sessionId, regenerate, setSearchParams])

  useEffect(() => {
    const timeout = setTimeout(() => {
      populateSession()
    }, 10)

    return () => clearTimeout(timeout)
  }, [populateSession])

  type NavRecorderPageOptions = {
    layout: RLayout
    tutorial: boolean
    dictation: boolean
    language: LanguageCode
  }

  const navRecorderPage = useCallback(
    ({ layout, dictation, tutorial, language }: NavRecorderPageOptions) => {
      const now = new Date()
      const queryText = createSearchParams({
        layout: layout.identifier,
        dictation,
        tutorial,
        language,
        group: layout.config.multiplePeople,
        startedAtString: now.toLocaleString(),
      })

      const oldRecorder = getFeatureFlag('old-recorder')
      let url = `/builder/transcribe-new${queryText}`
      if (oldRecorder) {
        url = `/builder/transcribe${queryText}`
      }
      const checkLicenseQuery = createSearchParams({
        limit: layout.type === 'document' ? 'documents' : dictation ? 'dictates' : 'sessions',
        cb: url,
      })
      navigate(`/check-license-limit${checkLicenseQuery}`)
    },
    [navigate]
  )

  type NavLayoutPageOptions = {
    layout: RLayout
    language: LanguageCode
  }

  const navLayoutPage = useCallback(
    ({ layout, language }: NavLayoutPageOptions) => {
      setOpenLayoutPreview(false)

      const queryText = createSearchParams({
        sessionId,
        shownSessionIds,
        layout: regenerate ? layout.identifier : undefined,
        language,
      })

      if (regenerate) {
        const url = `/builder/regenerate/${regenerate}${queryText}`
        const checkLicenseQuery = createSearchParams({
          limit:
            layout.type === 'document' ? 'documents' : session?.dictation ? 'dictates' : 'sessions',
          cb: url,
        })

        return navigate(`/check-license-limit${checkLicenseQuery}`)
      }

      const url = `/builder/${layout.config.multiplePeople ? 'group-layout' : 'layout'}/${
        layout.identifier
      }${queryText}`
      const checkLicenseQuery = createSearchParams({
        limit:
          layout.type === 'document' ? 'documents' : session?.dictation ? 'dictates' : 'sessions',
        cb: url,
      })

      navigate(`/check-license-limit${checkLicenseQuery}`)
    },
    [navigate, sessionId, shownSessionIds, regenerate, session]
  )

  const handleClickStartLiveSession = useCallback(() => {
    if (!selectedLayout) return

    setOpenLayoutPreview(false)

    navRecorderPage({
      layout: selectedLayout,
      dictation: false,
      tutorial,
      language,
    })
  }, [navRecorderPage, selectedLayout, tutorial, language])

  const handleRemoveSession = useCallback(() => {
    setSearchParams((prev) => {
      prev.delete('sessionId')
      prev.delete('regenerate')
      return prev
    })
  }, [setSearchParams])

  const handleClosePatientConsent = useCallback(() => {
    setSearchParams((prev) => {
      prev.set('generateOpen', 'true')
      prev.set('consentOpen', 'false')
      return prev
    })
  }, [setSearchParams])

  const handleOpenPatientConsent = useCallback(
    (language: LanguageCode) => {
      if (!selectedLayout) return

      if (Cookies.get('neverShowPatientConsent') === 'true') {
        navRecorderPage({
          layout: selectedLayout,
          dictation: false,
          tutorial,
          language,
        })
        return
      }

      setSearchParams((prev) => {
        prev.set('generateOpen', 'false')
        prev.set('consentOpen', 'true')
        prev.set('language', language)
        prev.set('layout', selectedLayout.identifier)
        return prev
      })
    },
    [navRecorderPage, setSearchParams, selectedLayout, tutorial]
  )

  const clickLayoutHandler = useCallback(
    (layout: RLayout) => () => {
      if (sessionId && !session) {
        return
      }

      if (selectedGenerateType === 'session') {
        if (Cookies.get('neverShowPatientConsent') === 'true') {
          navRecorderPage({
            layout,
            dictation: false,
            tutorial,
            language,
          })
          return
        }

        setSearchParams((prev) => {
          prev.set('generateOpen', 'false')
          prev.set('consentOpen', 'true')
          prev.set('language', language)
          prev.set('layout', layout.identifier)
          return prev
        })
        return
      }

      if (selectedGenerateType === 'dictate') {
        navRecorderPage({ layout, tutorial, dictation: true, language })
        return
      }

      if (sessionId || regenerate) {
        navLayoutPage({ layout, language })
        return
      }
      setSearchParams((prev) => {
        prev.set('generateOpen', 'true')
        prev.set('layout', layout.identifier)
        return prev
      })
      setOpenLayoutPreview(false)
    },
    [
      selectedGenerateType,
      navRecorderPage,
      setSearchParams,
      tutorial,
      language,
      sessionId,
      regenerate,
      navLayoutPage,
      session,
    ]
  )

  const handleStartDictation = useCallback(
    (language: LanguageCode) => {
      if (!selectedLayout) return

      setOpenLayoutPreview(false)

      navRecorderPage({
        layout: selectedLayout,
        dictation: true,
        tutorial,
        language,
      })
    },
    [navRecorderPage, selectedLayout, tutorial]
  )

  const handleSetNeverShowConsent = useCallback(() => {
    Cookies.set('neverShowPatientConsent', 'true', { expires: 365 })
  }, [])

  const handleCloseGenerateType = useCallback(() => {
    setSearchParams((prev) => {
      prev.set('generateOpen', 'false')
      return prev
    })
  }, [setSearchParams])

  const handleCloseLayoutPreview = useCallback(() => {
    setOpenLayoutPreview(false)
    setSearchParams((prev) => {
      prev.set('generateOpen', 'true')
      return prev
    })
  }, [setSearchParams])

  const handleOpenLayoutPreview = useCallback(() => {
    setOpenLayoutPreview(true)
    setSearchParams((prev) => {
      prev.set('generateOpen', 'false')
      return prev
    })
  }, [setSearchParams])

  return (
    <PageContainer>
      {(sessionId || regenerate) && (
        <Box sx={{ pb: 2 }}>
          <SessionItem
            regenerate={!!regenerate}
            session={session}
            onClickClose={handleRemoveSession}
            layout={layouts.find((l) => session?.layout === l.identifier)}
          />
        </Box>
      )}
      <Grid container spacing={2} sx={{ mb: 2 }}>
        <Grid item xs={12}>
          <Typography variant="h5" gutterBottom>
            Select Document / Note Type
          </Typography>
          <Typography variant="body1">
            Use our AI to build notes & documents from live sessions, dictations or other sources of
            context.
          </Typography>
        </Grid>
        <Grid item xs={12} container>
          <Grid item xs={12} sm>
            <SearchField value={submittedSearch} onChange={handleChangeSearch} />
          </Grid>
        </Grid>
        <Grid item xs container spacing={1}>
          {mainTags.map((tag) => (
            <Grid item key={tag}>
              <HighlightTag
                onClick={tagSearchHandler(tag)}
                title={capitalize(tag)}
                highlight={submittedSearch.toLowerCase() === tag.toLowerCase()}
              />
            </Grid>
          ))}
        </Grid>
        <Grid item xs="auto">
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button onClick={handleToggleShowAll} variant="outlined">
              {showAll ? 'Hide All' : 'Show All'}
            </Button>
          </Box>
        </Grid>
        <Grid item xs={12} container spacing={2} alignItems="stretch">
          {mainTags
            .filter((tag) => shownLayouts.some((l) => l.tags.includes(tag)))
            .map((tag) => (
              <Grid item key={tag} xs={12}>
                <Typography variant="h6" gutterBottom id={tag}>
                  {capitalize(tag)}
                </Typography>
                <Divider sx={{ mb: 2 }} />
                <Grid container spacing={2}>
                  {shownLayouts
                    .filter((l) => l.tags.includes(tag))
                    .sort((a, b) => b.priority - a.priority)
                    .map((layout) => (
                      <Grid item xs={12} sm={6} md={4} key={layout._id}>
                        <LayoutOption layout={layout} onClick={clickLayoutHandler(layout)} />
                      </Grid>
                    ))}
                </Grid>
              </Grid>
            ))}
        </Grid>
      </Grid>
      {selectedLayout && (
        <LayoutPreviewDialog
          open={openLayoutPreview}
          onClose={handleCloseLayoutPreview}
          layout={selectedLayout}
        />
      )}
      <PatientConsentDialog
        open={consentOpen}
        onClose={handleClosePatientConsent}
        onConfirm={() => handleClickStartLiveSession()}
        onClickNeverShow={handleSetNeverShowConsent}
      />
      {selectedLayout && (
        <SelectGenerateTypeDialog
          open={generateOpen}
          onClose={handleCloseGenerateType}
          onClickLiveSession={handleOpenPatientConsent}
          onClickDictation={handleStartDictation}
          onClickViewDetails={handleOpenLayoutPreview}
          onClickBuilder={handleOpenBuilder}
          layout={selectedLayout}
          viewDetailsDisabled={
            !selectedLayout.example && selectedLayout.config.options.length === 0
          }
        />
      )}
      <LicenseCard license={activeLicense} shownLimits={['sessions', 'dictates', 'documents']} />
    </PageContainer>
  )
}

export default BuilderPage
