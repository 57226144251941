import Button from '@mui/material/Button'
import Checkbox from '@mui/material/Checkbox'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import FormControlLabel from '@mui/material/FormControlLabel'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { useState } from 'react'
import ClosableDialog from '../../components/ClosableDialog'
import LabeledTextField from '../../components/layout/LabeledTextField'
import Box from '@mui/material/Box'

const REASON_OPTIONS: string[] = [
  "I'm not sure how to use the product",
  'Technical difficulties',
  "I signed up and didn't use it",
  "I'm not practicing anymore",
  "It's too expensive",
  "I'm using another AI product",
  'My company purchased a license',
  'Other',
]

type CancelReasonDialogProps = {
  open: boolean
  onClose: () => void
  onSubmit: (reason: string, comment: string) => void
}

const CancelReasonDialog = ({ open, onClose, onSubmit }: CancelReasonDialogProps) => {
  const [reason, setReason] = useState<string>('')
  const [comment, setComment] = useState<string>('')

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    setReason(value)
  }

  const handleSubmit = () => {
    onSubmit(reason, comment)
    onClose()
  }

  return (
    <ClosableDialog open={open} onClose={onClose} titleText="Cancel Subscription" maxWidth="xs">
      <DialogContent>
        <Typography variant="body1" sx={{ mb: 2 }}>
          We're sorry to see you go! Would you please provide us with some feedback on why Clinical
          Notes AI no longer works for you?
        </Typography>
        <Box sx={{ mx: 2 }}>
          <Grid container>
            {REASON_OPTIONS.map((option) => (
              <Grid item xs={12} key={option}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={reason === option}
                      onChange={handleChange}
                      value={option}
                      color="primary"
                    />
                  }
                  label={option}
                />
              </Grid>
            ))}
            {reason && (
              <Grid item xs={12}>
                <LabeledTextField
                  label="Would you like to leave a comment?"
                  value={comment}
                  onChange={(e) => setComment(e.target.value)}
                  fullWidth
                  autoComplete="off"
                  rows={3}
                  multiline
                  placeholder="Type your comment here..."
                />
              </Grid>
            )}
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Go Back</Button>
        <Button onClick={handleSubmit} color="secondary" disabled={!reason}>
          Cancel Subscription
        </Button>
      </DialogActions>
    </ClosableDialog>
  )
}

export default CancelReasonDialog
