import { ListLayoutsOptions, RLayout } from '@counsel-project/counsel-transcribe-api'
import { LayoutSortableFields } from '@counsel-project/counsel-transcribe-api/dist/common/database/RLayout'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { useCallback, useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import SearchField from '../../components/SearchField'
import SelectableTab from '../../components/SelectableTab'
import SimplePagination from '../../components/forms/SimplePagination'
import PageContainer from '../../components/layout/PageContainer'
import { transcribeRequest } from '../../util/api/transcribe-api'
import { visionRequest } from '../../util/api/vision-api'
import checkToken from '../../util/auth/checkToken'
import useUser from '../../util/auth/useUser'
import handleError from '../../util/handleError'
import AddLayoutBox from './AddLayoutBox'
import AddLayoutDialog from './AddLayoutDialog'
import CustomLayoutCard from './CustomLayoutCard'
import LayoutGeneratingCard from './LayoutGeneratingCard'
import UploadLayoutBox from './UploadLayoutBox'

const LayoutsPage = () => {
  const [searchParams, setSearchParams] = useSearchParams()

  const processIdsString = searchParams.get('processIds')
  const processIds = processIdsString ? processIdsString.split(',') : []

  const limit = 9
  const [layouts, setLayouts] = useState<RLayout[]>([])
  const [totalLayouts, setTotalLayouts] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const [addLayoutOpen, setAddLayoutOpen] = useState(false)
  const [search, setSearch] = useState('')
  const [offset, setOffset] = useState(0)
  const [sort, setSort] = useState<LayoutSortableFields>('createdAt')
  const [direction, setDirection] = useState<'asc' | 'desc'>('desc')
  const [viewMode, setViewMode] = useState<'created-by-me' | 'shared-with-organization' | 'all'>(
    'all'
  )

  const [user] = useUser()

  const navigate = useNavigate()

  const populateLayouts = useCallback(async () => {
    try {
      setIsLoading(true)

      await checkToken()

      const searches: ListLayoutsOptions['search'] = {}

      if (viewMode === 'created-by-me') {
        searches.and = [{ email: user?.email }]
      } else if (viewMode === 'shared-with-organization') {
        searches.and = [{ directoryId: null, $not: true }]
      }

      const { results, total } = await transcribeRequest.layouts.list({
        token: '',
        limit,
        sort,
        direction,
        offset,
        search: search
          ? {
              ...searches,
              or: [
                { name: search, $fuzzy: true },
                { directoryName: search, $fuzzy: true },
                { description: search, $fuzzy: true },
              ],
            }
          : Object.keys(searches).length !== 0
            ? searches
            : undefined,
      })

      setLayouts(results)
      setTotalLayouts(total)
    } catch (err) {
      handleError(err)
    } finally {
      setIsLoading(false)
    }
  }, [search, offset, sort, direction, viewMode, user])

  useEffect(() => {
    const timeout = setTimeout(() => {
      populateLayouts()
    }, 10)

    return () => clearTimeout(timeout)
  }, [populateLayouts])

  const onClickAdd = (identifier?: string) => {
    if (identifier) {
      navigate(`/layouts/new?identifier=${identifier}`)
    } else {
      setAddLayoutOpen(false)
      navigate('/layouts/new')
    }
  }

  const clickLayoutHandler = (layout: RLayout) => () => {
    if (layout.fields) {
      navigate(`/layouts/forms/${layout._id}`)
    } else {
      navigate(`/layouts/view/${layout._id}`)
    }
  }

  const handlePopulateLayout = useCallback(
    (layout: RLayout, processId: string) => {
      setLayouts((layouts) => [
        layout,
        ...layouts.filter((l) => {
          return l._id !== layout._id
        }),
      ])
      // Remove the processId from the list
      setSearchParams((prev) => {
        const processIdsString = prev.get('processIds')
        const processIds = processIdsString ? processIdsString.split(',') : []
        prev.set('processIds', processIds.filter((id) => id !== processId).join(','))
        return prev
      })
    },
    [setSearchParams]
  )

  const handleUploadFile = useCallback(
    async (file: File) => {
      try {
        setIsLoading(true)

        await checkToken()

        const formData = new FormData()
        formData.append('file', file)

        const { processId } = await visionRequest.layouts.generate({ token: '', formData })

        setSearchParams((prev) => {
          const processIdsString = prev.get('processIds')
          const processIds = processIdsString ? processIdsString.split(',') : []
          prev.set('processIds', [...processIds, processId].join(','))
          return prev
        })
      } catch (err) {
        handleError(err)
      } finally {
        setIsLoading(false)
      }
    },
    [setSearchParams]
  )

  return (
    <PageContainer>
      <Grid container spacing={2} alignItems="stretch" sx={{ mb: 2 }}>
        <Grid item xs={12}>
          <Typography variant="h3" sx={{ mb: 1 }}>
            Custom Templates
          </Typography>
          <Typography variant="body1">
            Personalize your templates and customize your outputs with Clinical Notes AI Template
            Builder.
          </Typography>
          <Typography variant="body1" sx={{ mt: 2 }} color="text.secondary">
            The templates you create here will show up in the custom section of the documentation
            page.
          </Typography>
          <Typography variant="body1" sx={{ mt: 2 }} color="text.secondary">
            This functionality is currently in beta and we want your feedback! It will only be
            available for pro, group and enterprise customers once it's fully released.
          </Typography>
          <Typography variant="body1" sx={{ mt: 2 }}>
            Please watch this tutorial video to learn how to create your own templates and talk to
            the AI for your personalized output.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Box sx={{ position: 'relative' }}>
            <div style={{ position: 'relative', paddingBottom: '56.25%', height: 0 }}>
              <iframe
                title="Tutorial"
                src="https://www.loom.com/embed/ce2df0f4b934472eacd3d23b78b2c896?sid=b2c1d564-c59a-4969-8638-9e0afdc1cf7f"
                frameBorder="0"
                allowFullScreen
                style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
              ></iframe>
            </div>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6" fontWeight={500}>
            Create a new template
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <AddLayoutBox onClick={() => setAddLayoutOpen(true)} />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <UploadLayoutBox onUpload={handleUploadFile} />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6" fontWeight={500}>
            Edit an existing template ({totalLayouts})
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <SearchField onChange={setSearch} loading={isLoading} />
        </Grid>
        <Grid item container xs={12} spacing={2}>
          <Grid item>
            <SelectableTab
              small
              active={sort === 'createdAt' && direction === 'desc'}
              onClick={() => {
                setSort('createdAt')
                setDirection('desc')
              }}
            >
              Newest
            </SelectableTab>
          </Grid>
          <Grid item>
            <SelectableTab
              small
              active={sort === 'createdAt' && direction === 'asc'}
              onClick={() => {
                setSort('createdAt')
                setDirection('asc')
              }}
            >
              Oldest
            </SelectableTab>
          </Grid>
        </Grid>
        <Grid item container xs={12} spacing={2}>
          <Grid item xs={12} sm="auto">
            <SelectableTab
              small
              active={viewMode === 'all'}
              onClick={() => {
                setViewMode('all')
              }}
            >
              View All
            </SelectableTab>
          </Grid>
          <Grid item xs={12} sm="auto">
            <SelectableTab
              small
              active={viewMode === 'shared-with-organization'}
              onClick={() => {
                setViewMode('shared-with-organization')
              }}
            >
              Shared with Organization
            </SelectableTab>
          </Grid>
          <Grid item xs={12} sm="auto">
            <SelectableTab
              small
              active={viewMode === 'created-by-me'}
              onClick={() => {
                setViewMode('created-by-me')
              }}
            >
              Created By Me
            </SelectableTab>
          </Grid>
        </Grid>
        {processIds.map((processId) => (
          <Grid item key={processId} xs={12} sm={6} md={4}>
            <LayoutGeneratingCard
              processId={processId}
              onFinished={(layout) => handlePopulateLayout(layout, processId)}
            />
          </Grid>
        ))}
        {layouts.map((layout) => (
          <Grid item key={layout._id} xs={12} sm={6} md={4}>
            <CustomLayoutCard
              isOwner={layout.email === user?.email}
              layout={layout}
              onClick={clickLayoutHandler(layout)}
              href={layout.fields ? `/layouts/forms/${layout._id}` : `/layouts/view/${layout._id}`}
            />
          </Grid>
        ))}
      </Grid>
      <SimplePagination
        offset={offset}
        limit={limit}
        total={totalLayouts}
        loading={isLoading}
        onClickNext={() => setOffset((prev) => prev + limit)}
        onClickPrevious={() => setOffset((prev) => prev - limit)}
      />
      {layouts.length === 0 && !isLoading && (
        <Typography variant="body1" color="text.secondary" sx={{ mt: 2 }} fontStyle="italic">
          No custom templates found. Click the button above to create a new template.
        </Typography>
      )}
      <AddLayoutDialog
        open={addLayoutOpen}
        onClose={() => setAddLayoutOpen(false)}
        onAdd={onClickAdd}
      />
    </PageContainer>
  )
}

export default LayoutsPage
