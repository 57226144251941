import ClosableDialog from '../../components/ClosableDialog'
import DialogContent from '@mui/material/DialogContent'
import Typography from '@mui/material/Typography'
import DirectionButton from '../../components/DirectionButton'
import exampleLayouts from './example-layouts'

type AddLayoutDialogProps = {
  open: boolean
  onClose: () => void
  onAdd: (identifier?: string) => void
}

const AddLayoutDialog = ({ open, onClose, onAdd }: AddLayoutDialogProps) => {
  return (
    <ClosableDialog open={open} onClose={onClose} titleText="Select Preset">
      <DialogContent>
        <Typography variant="body1" sx={{ mb: 2 }}>
          Select what preset you would like to start with, or start from scratch.
        </Typography>
        <DirectionButton
          onClick={() => {
            onAdd()
          }}
          title="Blank Template"
          subtitle="Start from scratch"
          sx={(theme) => ({
            border: `2px solid ${theme.palette.primary.main}`,
            background: 'none',
          })}
        />
        {exampleLayouts.map((layout) => (
          <DirectionButton
            key={layout.identifier}
            onClick={() => {
              onAdd(layout.identifier)
            }}
            title={layout.name}
            subtitle={layout.description}
            sx={{ mt: 2 }}
          />
        ))}
      </DialogContent>
    </ClosableDialog>
  )
}

export default AddLayoutDialog
