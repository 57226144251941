import { API, EditorConfig } from '@editorjs/editorjs'

type EditorJSData = {
  content: string
}

type ConstructorOptions = {
  data: EditorJSData
  api: API
  config: EditorConfig
  readOnly: boolean
}

class AIInline {
  button: HTMLElement | null
  _state: boolean
  api: API
  tag: string
  class: string

  static get isInline() {
    return true
  }

  static get sanitize() {
    return {
      mark: {
        class: 'cdx-ai',
      },
    }
  }

  static get shortcut() {
    return '['
  }

  get state() {
    return this._state
  }

  set state(state) {
    this._state = state

    this.button?.classList.toggle(this.api.styles.inlineToolButtonActive, state)
  }

  constructor({ api }: ConstructorOptions) {
    this.api = api
    this.button = null
    this._state = false

    this.tag = 'MARK'
    this.class = 'cdx-ai'
  }

  render() {
    this.button = document.createElement('button')
    this.button.setAttribute('type', 'button')
    this.button.innerHTML =
      '<svg fill="#757575" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="m19.46 8 .79-1.75L22 5.46c.39-.18.39-.73 0-.91l-1.75-.79L19.46 2c-.18-.39-.73-.39-.91 0l-.79 1.75-1.76.79c-.39.18-.39.73 0 .91l1.75.79.79 1.76c.18.39.74.39.92 0M11.5 9.5 9.91 6c-.35-.78-1.47-.78-1.82 0L6.5 9.5 3 11.09c-.78.36-.78 1.47 0 1.82l3.5 1.59L8.09 18c.36.78 1.47.78 1.82 0l1.59-3.5 3.5-1.59c.78-.36.78-1.47 0-1.82zm7.04 6.5-.79 1.75-1.75.79c-.39.18-.39.73 0 .91l1.75.79.79 1.76c.18.39.73.39.91 0l.79-1.75 1.76-.79c.39-.18.39-.73 0-.91l-1.75-.79-.79-1.76c-.18-.39-.74-.39-.92 0"></path></svg>'
    this.button.classList.add(this.api.styles.inlineToolButton)

    return this.button
  }

  surround(range: Range) {
    if (this.state) {
      this.unwrap(range)
      return
    }

    this.wrap(range)
  }

  wrap(range: Range) {
    const selectedText = range.extractContents()
    const mark = document.createElement(this.tag)
    mark.classList.add(this.class)
    if (selectedText.textContent) {
      selectedText.textContent = selectedText.textContent.split('[').join('').split(']').join('')
    }
    mark.appendChild(document.createTextNode('\u200B'))
    mark.appendChild(selectedText)
    range.insertNode(mark)

    // make content inside mark editable
    mark.contentEditable = 'true'

    this.api.selection.expandToTag(mark)
  }

  unwrap(range: Range) {
    const mark = this.api.selection.findParentTag(this.tag, this.class)
    const text = range.extractContents()

    mark?.remove()

    range.insertNode(text)
  }

  checkState() {
    const mark = this.api.selection.findParentTag(this.tag)

    this.state = !!mark
  }
}

export default AIInline
