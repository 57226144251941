import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { markdownToHtml, removeMaliciousHTML } from '../../util/markdown'
import CircularProgress from '@mui/material/CircularProgress'
import { useState, useEffect, useCallback } from 'react'
import Animation from '../../components/Animation'
import log from '../../util/logging'

type LayoutExampleProps = {
  example?: string
  generating?: boolean
}

const LayoutExample = ({ example, generating }: LayoutExampleProps) => {
  const exampleHtml = markdownToHtml(example || '')
  const [landingAnimation, setLandingAnimation] = useState<any>(undefined)

  const populateLandingAnimation = useCallback(async () => {
    try {
      if (!generating) return
      const animation = await fetch('/pen_notes_animation.json')
      const animationData = await animation.json()
      setLandingAnimation(animationData)
    } catch (error) {
      log.error(error)
    }
  }, [generating])

  useEffect(() => {
    populateLandingAnimation()
  }, [populateLandingAnimation])

  return (
    <Box>
      <Accordion elevation={0}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Box
            sx={{
              flex: 1,
              verticalAlign: 'center',
              height: '100%',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Typography
              variant="body1"
              fontSize={18}
              fontWeight={500}
              sx={{
                flex: 1,
              }}
            >
              Example Output
            </Typography>
            <CircularProgress
              size={24}
              sx={{ m: '14px', opacity: generating ? 1 : 0 }}
              color="success"
            />
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <div
            style={{
              // Allow text to wrap
              whiteSpace: 'pre-wrap',
              // Allow text to break
              wordWrap: 'break-word',
            }}
            dangerouslySetInnerHTML={{
              __html: removeMaliciousHTML(exampleHtml),
            }}
          />
          {generating && (
            <Box>
              <Box sx={{ height: 160 }}>
                <Animation
                  id={'ex-animation'}
                  animation={landingAnimation}
                  sx={{ height: '100%', width: '100%' }}
                  renderer="canvas"
                  autoplay
                  loop
                  speed={0.7}
                />
              </Box>
              <Typography variant="body1" textAlign="center" fontWeight={500} sx={{ mt: 2 }}>
                Generating Example...
              </Typography>
            </Box>
          )}
        </AccordionDetails>
      </Accordion>
    </Box>
  )
}

export default LayoutExample
