import { LoginUserSuccessResponse } from '@counsel-project/counsel-auth-api'
import { isApiError } from '@counsel-project/client-utils'
import BackIcon from '@mui/icons-material/ArrowBackRounded'
import { Paper } from '@mui/material'
import Alert from '@mui/material/Alert'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import Collapse from '@mui/material/Collapse'
import FormControl from '@mui/material/FormControl'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import Link from '@mui/material/Link'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import Cookies from 'js-cookie'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { toast } from 'react-hot-toast'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useTitle } from 'react-use'
import ClosableDialog from '../components/ClosableDialog'
import AppleLoginButton from '../components/auth/AppleLoginButton'
import ClinicalNotesButton from '../components/auth/ClinicalNotesButton'
import GoogleLoginButton from '../components/auth/GoogleLoginButton'
import MicrosoftLoginButton from '../components/auth/MicrosoftLoginButton'
import { redirectUri } from '../components/auth/util'
import LoginBrowserErrorDialog from '../components/layout/LoginBrowserErrorDialog'
import PageContainer from '../components/layout/PageContainer'
import { authRequest } from '../util/api/auth-api'
import useUser from '../util/auth/useUser'
import log from '../util/logging'
import { acceptPrivacyPolicy } from '../util/privacy-policy'
import { logLogin, useViewContent } from '../util/tracking'
import { setStore } from '../util/store/auth'
import {
  loginWithEmail,
  loginWithEmail2FA,
  loginWithGoogle,
  loginWithPhone2FA,
} from '../util/auth/login'

export const attemptJSONParse = (string: string): { [key: string]: any } | null => {
  try {
    return JSON.parse(string)
  } catch (err) {
    return null
  }
}

type MFADialogProps = {
  mfaMethod: 'phone' | 'email' | null
  userId: string | null
  onClose: () => void
}

const MFADialog = ({ mfaMethod, userId, onClose }: MFADialogProps) => {
  const [code, setCode] = useState('')
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')

  const handleVerify = useCallback(async () => {
    try {
      if (!code) return
      if (!userId) return
      if (!mfaMethod) return

      setLoading(true)

      if (mfaMethod === 'phone') {
        await loginWithPhone2FA({
          userId,
          code,
        })
      } else {
        await loginWithEmail2FA({
          userId,
          code,
        })
      }

      log.info('Logged in')

      logLogin('mfa')
    } catch (err) {
      if (isApiError(err)) {
        if (err.msg !== 'Invalid credentials') {
          log.error(err.msg)
        }
        setError(err.msg)
      }
      log.warn(err)
    } finally {
      setLoading(false)
    }
  }, [code, userId, mfaMethod])

  useEffect(() => {
    if (!code) return
    if (code.length !== 6) return
    const timeout = setTimeout(handleVerify, 100)
    return () => clearTimeout(timeout)
  }, [code, handleVerify])

  const mfaMethodText = useMemo(() => {
    if (mfaMethod === 'phone') {
      return 'Please enter the verification code we have sent to your phone'
    } else if (mfaMethod === 'email') {
      return 'Please enter the verification code we have sent to your email'
    }
    return ''
  }, [mfaMethod])

  return (
    <ClosableDialog open={!!mfaMethod} onClose={onClose} titleText="Verification Code">
      <Grid container padding={2} spacing={2}>
        <Grid item xs={12}>
          <Collapse in={Boolean(error)}>
            <Alert severity="error">{error}</Alert>
          </Collapse>
        </Grid>
        <Grid item xs={12}>
          <Typography>{mfaMethodText}</Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField
            placeholder="Code"
            aria-label="code"
            fullWidth
            onChange={(e) => setCode(e.target.value)}
            value={code}
            disabled={loading}
            autoFocus
          />
        </Grid>
        <Grid item xs={12}>
          <Button fullWidth onClick={handleVerify} disabled={loading}>
            Verify
          </Button>
        </Grid>
      </Grid>
    </ClosableDialog>
  )
}

const Login = () => {
  useViewContent('Login')
  useTitle('Clinical Notes AI - Login')

  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [error, setError] = useState('')
  const [loading, setLoading] = useState<boolean>(false)
  const [user] = useUser()
  const [manualLogin, setManualLogin] = useState(false)
  const [browserError, setBrowserError] = useState(false)
  const [mfaMethod, setMFAMethod] = useState<'phone' | 'email' | null>(null)
  const [mfaUserId, setMFAUserId] = useState<string | null>(null)

  const state = searchParams.get('state')
  let page = attemptJSONParse(decodeURIComponent(state || ''))?.page
  if (page === '/login' || page === '/register') {
    page = undefined
  }
  const licenseType = searchParams.get('type')

  const handleChangeEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value)
  }

  const handleChangePassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value)
  }

  const createNavHandler = (path: string) => (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault()
    navigate(path)
  }

  const handleLogin = useCallback(async () => {
    try {
      if (!email) return
      if (!password) return

      setStore({
        user: null,
      })

      Cookies.remove('expiresAt')

      setLoading(true)

      const data = await loginWithEmail({
        email,
        password,
      })

      if ('twoFactorAuth' in data) {
        setMFAMethod(data.twoFactorAuth)
        setMFAUserId(data.userId)
        return
      }

      log.info('Logged in')

      logLogin('email')
    } catch (err) {
      if (isApiError(err)) {
        if (err.msg !== 'Invalid credentials') {
          log.error(err.msg)
        }
        setError(err.msg)
      }
      log.warn(err)
    } finally {
      setLoading(false)
    }
  }, [email, password])

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    handleLogin()
  }

  const handleGoogleLogin = useCallback(async () => {
    try {
      const code = searchParams.get('code')
      if (!code) return

      setLoading(true)

      setStore({
        user: null,
      })

      Cookies.remove('expiresAt')

      logLogin('google')

      await loginWithGoogle({
        code,
        redirectUri,
      })

      acceptPrivacyPolicy()
    } catch (err) {
      log.error(err)
      toast.error('Error logging in with Google')
      if (state || licenseType) {
        navigate(`/register?state=${encodeURIComponent(state || '')}&type=${licenseType}`)
      } else {
        navigate('/register')
      }
    } finally {
      setLoading(false)
    }
  }, [navigate, searchParams, state, licenseType])

  useEffect(() => {
    const timeout = setTimeout(() => {
      handleGoogleLogin()
    }, 100)
    return () => clearTimeout(timeout)
  }, [handleGoogleLogin])

  const afterLogin = useCallback(() => {
    if (!user) return
    navigate(page || '/onboarding')
  }, [user, page, navigate])

  useEffect(() => {
    const timeout = setTimeout(() => {
      afterLogin()
    }, 100)
    return () => clearTimeout(timeout)
  }, [afterLogin])

  return (
    <PageContainer>
      <LoginBrowserErrorDialog open={browserError} onClose={() => setBrowserError(false)} />
      <MFADialog mfaMethod={mfaMethod} userId={mfaUserId} onClose={() => setMFAMethod(null)} />
      {searchParams.get('code') ? (
        <Grid container justifyContent="center" spacing={4} direction="column" alignItems="center">
          {!!error && (
            <Grid item xs={12} sm={8} md={5} lg={4}>
              <Alert severity="error">{error}</Alert>
            </Grid>
          )}
          <Grid item>
            <CircularProgress />
          </Grid>
        </Grid>
      ) : !manualLogin ? (
        <Paper
          elevation={0}
          sx={{ p: 0, borderRadius: { xs: 0, sm: 1 }, mb: 2, background: 'transparent' }}
        >
          <Grid container justifyContent="center" padding={2}>
            <Grid item>
              <Typography variant="h5">Sign into Clinical Notes AI</Typography>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <GoogleLoginButton
                fullWidth
                redirectPage={page}
                onBrowserError={() => setBrowserError(true)}
              />
            </Grid>
            <Grid item xs={12}>
              <MicrosoftLoginButton fullWidth onBrowserError={() => setBrowserError(true)} />
            </Grid>
            <Grid item xs={12}>
              <AppleLoginButton
                redirectPage={page}
                fullWidth
                onBrowserError={() => setBrowserError(true)}
              />
            </Grid>
            <Grid item xs={12}>
              <ClinicalNotesButton fullWidth onClick={() => setManualLogin(true)} />
            </Grid>
          </Grid>
        </Paper>
      ) : (
        <Paper elevation={0} sx={{ p: 2, mb: 2 }}>
          <Grid container padding={2} alignItems="center" spacing={2}>
            <Grid item>
              <IconButton onClick={() => setManualLogin(false)}>
                <BackIcon />
              </IconButton>
            </Grid>
            <Grid item>
              <Typography variant="h5">Login</Typography>
            </Grid>
          </Grid>
          <FormControl component={'form'} sx={{ width: '100%' }} onSubmit={handleSubmit}>
            <Grid container justifyContent="center" direction="column" padding={2} spacing={2}>
              <Grid item xs={12}>
                <Collapse in={Boolean(error)}>
                  <Alert severity="error">{error}</Alert>
                </Collapse>
              </Grid>
              <Grid item>
                <TextField
                  placeholder="E-mail"
                  type="email"
                  aria-label="email"
                  fullWidth
                  onChange={handleChangeEmail}
                  value={email}
                  disabled={loading}
                />
              </Grid>
              <Grid item>
                <TextField
                  placeholder="Password"
                  type="password"
                  aria-label="password"
                  fullWidth
                  onChange={handleChangePassword}
                  value={password}
                  disabled={loading}
                />
              </Grid>
              <Grid item>
                <Button fullWidth type="submit" disabled={loading}>
                  Continue
                </Button>
              </Grid>
            </Grid>

            <Grid container justifyContent="center" paddingX={2} paddingBottom={2}>
              <Grid item>
                <Typography>
                  Forgot your password? Click{' '}
                  <Link onClick={createNavHandler('/reset-password')} href="/reset-password">
                    here
                  </Link>
                </Typography>
              </Grid>
            </Grid>
          </FormControl>
        </Paper>
      )}
      <Grid container justifyContent="center" paddingX={2} paddingTop={2}>
        <Grid item>
          <Typography>
            Don&apos;t have an account? Get started{' '}
            <Link onClick={createNavHandler('/pricing-select')} href="/pricing-select">
              here
            </Link>
          </Typography>
        </Grid>
      </Grid>
    </PageContainer>
  )
}

export default Login
