import ClosableDialog from '../../components/ClosableDialog'
import DialogContent from '@mui/material/DialogContent'
import Typography from '@mui/material/Typography'
import DirectionButton from '../../components/DirectionButton'
import EXAMPLE_FORM_LAYOUTS from './example-form-layouts'

type AddFormLayoutDialogProps = {
  loading?: boolean
  open: boolean
  onClose: () => void
  onAdd: (identifier?: string) => void
}

const AddFormLayoutDialog = ({ open, onClose, onAdd, loading }: AddFormLayoutDialogProps) => {
  return (
    <ClosableDialog open={open} onClose={onClose} titleText="Select Preset">
      <DialogContent>
        <Typography variant="body1" sx={{ mb: 2 }}>
          Select what preset you would like to start with, or start from scratch.
        </Typography>
        <DirectionButton
          onClick={() => {
            onAdd()
          }}
          title="Blank Template"
          subtitle="Start from scratch"
          sx={(theme) => ({
            border: `2px solid ${theme.palette.primary.main}`,
            background: 'none',
          })}
          disabled={loading}
        />
        {EXAMPLE_FORM_LAYOUTS.map((layout) => (
          <DirectionButton
            key={layout.identifier}
            onClick={() => {
              onAdd(layout.identifier)
            }}
            title={layout.name}
            subtitle={layout.description}
            sx={{ mt: 2 }}
            disabled={loading}
          />
        ))}
      </DialogContent>
    </ClosableDialog>
  )
}

export default AddFormLayoutDialog
