import { RLicense, RUser } from '@counsel-project/counsel-auth-api'
import Box from '@mui/material/Box'
import DialogContent from '@mui/material/DialogContent'
import Typography from '@mui/material/Typography'
import { useCallback, useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { useNavigate } from 'react-router-dom'
import ClosableDialog from '../../components/ClosableDialog'
import { authRequest } from '../../util/api/auth-api'
import checkToken from '../../util/auth/checkToken'
import handleError from '../../util/handleError'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import CopyIcon from '@mui/icons-material/FileCopyRounded'
import { loginAdminUser } from '../../util/auth/login'

type UserDialogProps = {
  open: boolean
  onClose: () => void
  user: RUser
  onUpdateUser: (user: RUser) => void
}

const UserDialog = ({ open, onClose, user, onUpdateUser }: UserDialogProps) => {
  const {
    email,
    _id,
    lastLoggedIn,
    profession,
    createdAt,
    phone,
    admin,
    microsoftId,
    googleId,
    appleId,
  } = user

  const [stagedEmail, setStagedEmail] = useState(email)
  const [stagedPhone, setStagedPhone] = useState(phone || '')
  const [stagedMicrosoftId, setStagedMicrosoftId] = useState(microsoftId || '')
  const [stagedGoogleId, setStagedGoogleId] = useState(googleId || '')
  const [stagedAppleId, setStagedAppleId] = useState(appleId || '')
  const [directoryIds, setDirectoryIds] = useState<string[] | null>(null)
  const [licenses, setLicenses] = useState<RLicense[] | null>(null)
  const [deleteStage, setDeleteStage] = useState(false)

  useEffect(() => {
    setStagedEmail(email)
    setStagedPhone(phone || '')
    setStagedMicrosoftId(microsoftId || '')
    setStagedGoogleId(googleId || '')
    setStagedAppleId(appleId || '')
    setDeleteStage(false)
  }, [email, phone, microsoftId, googleId, appleId])

  const copyHandler = (text: string) => () => {
    navigator.clipboard.writeText(text)
    toast.success('Copied to clipboard')
  }

  const navigate = useNavigate()

  const handleLoginAsUser = useCallback(async () => {
    try {
      await checkToken()

      await loginAdminUser({
        userId: _id,
      })

      toast.success(`Logged in as ${email}`)

      navigate('/')
    } catch (err) {
      handleError(err)
    }
  }, [_id, navigate, email])

  const handleDeleteUser = useCallback(async () => {
    try {
      if (!deleteStage) {
        setDeleteStage(true)
        toast.error('Click again to confirm deletion')
        return
      }

      await checkToken()

      await authRequest.admin.users.delete({
        token: '',
        userId: _id,
      })

      toast.success('User deleted successfully')

      onClose()
    } catch (err) {
      handleError(err)
    }
  }, [_id, onClose, deleteStage])

  const populateRoles = useCallback(async () => {
    try {
      await checkToken()

      const res = await authRequest.user.lookup.directories.byUserId({
        token: '',
        userId: _id,
      })

      setDirectoryIds(res.directoryIds)
    } catch (err) {
      handleError(err)
    }
  }, [_id])

  useEffect(() => {
    const timeout = setTimeout(populateRoles, 10)
    return () => clearTimeout(timeout)
  }, [populateRoles])

  const populateLicenses = useCallback(async () => {
    try {
      if (directoryIds === null) return

      await checkToken()

      const { results } = await authRequest.admin.licenses.list({
        token: '',
        search: {
          or: [{ userEmail: email }, ...directoryIds.map((directoryId) => ({ directoryId }))],
        },
      })

      setLicenses(results)
    } catch (err) {
      handleError(err)
    }
  }, [directoryIds, email])

  useEffect(() => {
    const timeout = setTimeout(populateLicenses, 10)
    return () => clearTimeout(timeout)
  }, [populateLicenses])

  const handleUpdateUser = useCallback(async () => {
    try {
      await checkToken()

      const { result } = await authRequest.admin.users.update({
        token: '',
        userId: _id,
        email: stagedEmail,
        phone: stagedPhone || undefined,
        microsoftId: stagedMicrosoftId || undefined,
        googleId: stagedGoogleId || undefined,
        appleId: stagedAppleId || undefined,
      })

      toast.success('User updated successfully')

      setStagedEmail(result.email)
      setStagedPhone(result.phone || '')
      setStagedMicrosoftId(result.microsoftId || '')
      setStagedGoogleId(result.googleId || '')
      setStagedAppleId(result.appleId || '')

      onUpdateUser(result)
    } catch (err) {
      handleError(err)
    }
  }, [
    _id,
    stagedEmail,
    stagedPhone,
    stagedMicrosoftId,
    stagedGoogleId,
    stagedAppleId,
    onUpdateUser,
  ])

  return (
    <ClosableDialog titleText={email} open={open} onClose={onClose}>
      <DialogContent>
        <Typography variant="body1" fontWeight={500} fontSize={16} sx={{ mb: 1 }}>
          Email
        </Typography>
        <Grid container spacing={2} sx={{ mb: 2 }}>
          <Grid item xs>
            <TextField
              size="small"
              fullWidth
              value={stagedEmail}
              onChange={(e) => setStagedEmail(e.target.value)}
              placeholder="Enter Email..."
            />
          </Grid>
          <Grid item>
            <IconButton onClick={copyHandler(stagedEmail)}>
              <CopyIcon />
            </IconButton>
          </Grid>
        </Grid>

        <Typography variant="body1" fontWeight={500} fontSize={16} sx={{ mb: 1 }}>
          Phone
        </Typography>
        <Grid container spacing={2} sx={{ mb: 2 }}>
          <Grid item xs>
            <TextField
              size="small"
              fullWidth
              value={stagedPhone}
              onChange={(e) => setStagedPhone(e.target.value)}
              placeholder="Enter Phone Number..."
            />
          </Grid>
          <Grid item>
            <IconButton onClick={copyHandler(stagedPhone)}>
              <CopyIcon />
            </IconButton>
          </Grid>
        </Grid>

        <Typography variant="body1" fontWeight={500} fontSize={16} sx={{ mb: 1 }}>
          Microsoft ID
        </Typography>
        <Grid container spacing={2} sx={{ mb: 2 }}>
          <Grid item xs>
            <TextField
              size="small"
              fullWidth
              value={stagedMicrosoftId}
              onChange={(e) => setStagedMicrosoftId(e.target.value)}
              placeholder="Enter Microsoft ID..."
            />
          </Grid>
          <Grid item>
            <IconButton onClick={copyHandler(stagedMicrosoftId)}>
              <CopyIcon />
            </IconButton>
          </Grid>
        </Grid>

        <Typography variant="body1" fontWeight={500} fontSize={16} sx={{ mb: 1 }}>
          Google ID
        </Typography>
        <Grid container spacing={2} sx={{ mb: 2 }}>
          <Grid item xs>
            <TextField
              size="small"
              fullWidth
              value={stagedGoogleId}
              onChange={(e) => setStagedGoogleId(e.target.value)}
              placeholder="Enter Google ID..."
            />
          </Grid>
          <Grid item>
            <IconButton onClick={copyHandler(stagedGoogleId)}>
              <CopyIcon />
            </IconButton>
          </Grid>
        </Grid>

        <Typography variant="body1" fontWeight={500} fontSize={16} sx={{ mb: 1 }}>
          Apple ID
        </Typography>
        <Grid container spacing={2} sx={{ mb: 2 }}>
          <Grid item xs>
            <TextField
              size="small"
              fullWidth
              value={stagedAppleId}
              onChange={(e) => setStagedAppleId(e.target.value)}
              placeholder="Enter Apple ID..."
            />
          </Grid>
          <Grid item>
            <IconButton onClick={copyHandler(stagedAppleId)}>
              <CopyIcon />
            </IconButton>
          </Grid>
        </Grid>

        <Button onClick={handleUpdateUser}>Save User</Button>

        <Box sx={{ mt: 4 }}>
          <Typography variant="h6" component="p">
            User Information
          </Typography>
          <Typography>
            <strong>ID:</strong> {_id}
          </Typography>
          <Typography>
            <strong>Profession:</strong> {profession}
          </Typography>
          <Typography>
            <strong>Created At:</strong> {new Date(createdAt).toLocaleDateString()}
          </Typography>
          <Typography>
            <strong>Last Logged In:</strong>{' '}
            {lastLoggedIn ? new Date(lastLoggedIn).toLocaleDateString() : 'Never'}
          </Typography>
          <Typography>
            <strong>Admin:</strong> {admin ? 'Yes' : 'No'}
          </Typography>
        </Box>

        <Typography variant="h6" component="p" sx={{ mt: 4 }}>
          License Information
        </Typography>
        <Grid container spacing={1}>
          {licenses?.map((license) => (
            <Grid item key={license._id} xs={12}>
              <Typography
                component="a"
                href={`/admin/licenses?search=${
                  license.userEmail || license?.directoryName || license?._id
                }`}
              >
                <strong>{license.directoryName || license.userEmail}:</strong> {license.type}
              </Typography>
            </Grid>
          ))}
        </Grid>
        <Box sx={{ mt: 4, display: 'flex' }}>
          <Box sx={{ display: 'flex', flexGrow: 1 }}>
            <Button onClick={handleLoginAsUser}>View Platform as User</Button>
          </Box>
          <Button color={deleteStage ? 'error' : 'warning'} onClick={handleDeleteUser}>
            Delete User
          </Button>
        </Box>
      </DialogContent>
    </ClosableDialog>
  )
}

export default UserDialog
