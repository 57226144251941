import { RPatientSession } from '@counsel-project/counsel-transcribe-api'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import React, { useCallback, useEffect, useState } from 'react'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { transcribeRequest } from '../../util/api/transcribe-api'
import checkToken from '../../util/auth/checkToken'
import { createSearchParams } from '../builder/common'
import SessionItem from './SessionItem'

type ChildSessionsSectionProps = {
  session?: RPatientSession
}

const ChildSessionsSection = ({ session }: ChildSessionsSectionProps) => {
  const navigate = useNavigate()
  const location = useLocation()

  const [childSessions, setChildSessions] = useState<RPatientSession[] | null>(null)

  const [searchParams] = useSearchParams()

  // If webView query parameter is present, set webView
  const webView = searchParams.get('webView') === 'true'
  const userId = searchParams.get('userId')
  const showAll = searchParams.get('showAll') === 'true'

  const populateOriginalSession = useCallback(async () => {
    try {
      if (!session || session.createdFrom || !!session.note) {
        setChildSessions(null)
        return
      }

      await checkToken()

      const { results } = await transcribeRequest.sessions.list.all({
        token: '',
        limit: 50,
        search: {
          and: [{ createdFrom: session._id }],
        },
      })

      setChildSessions(results)
    } catch (err) {
      console.error(err)
    }
  }, [session])

  useEffect(() => {
    const timeout = setTimeout(() => {
      populateOriginalSession()
    }, 10)
    return () => clearTimeout(timeout)
  }, [populateOriginalSession])

  const navSessionHandler = (session: RPatientSession) => (e: React.MouseEvent) => {
    e.preventDefault()

    if (!session) return

    const queryText = createSearchParams({
      ref: location.pathname + location.search,
      userId: userId || undefined,
      webView,
      showAll,
    })

    navigate(`/sessions/${session._id}${queryText}`)
  }

  if (!childSessions) return null

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography variant="h6">Documentation Generated</Typography>
      </Grid>
      {childSessions.map((childSession) => (
        <Grid item xs={12} key={childSession._id}>
          <SessionItem session={childSession} onClick={navSessionHandler(childSession)} />
        </Grid>
      ))}
    </Grid>
  )
}

export default ChildSessionsSection
