import { RDirectory } from '@counsel-project/counsel-auth-api'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import Paper, { PaperProps } from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import { useCallback, useState } from 'react'
import { toast } from 'react-hot-toast'
import { authRequest } from '../../util/api/auth-api'
import checkToken from '../../util/auth/checkToken'
import handleError from '../../util/handleError'
import refreshToken from '../../util/auth/refreshToken'

type InvitationCardProps = {
  directory: RDirectory
  onDeclined?: () => void
  onAccepted?: () => void
} & PaperProps

const InvitationCard = ({ directory, onDeclined, onAccepted, ...props }: InvitationCardProps) => {
  const [isLoading, setIsLoading] = useState(false)

  const handleDecline = useCallback(async () => {
    try {
      setIsLoading(true)

      await checkToken()

      await authRequest.user.directories.invitations.decline({
        token: '',
        directoryId: directory._id,
      })

      toast.success('Invitation Declined')
      onDeclined?.()
    } catch (err) {
      handleError(err)
    } finally {
      setIsLoading(false)
    }
  }, [directory._id, onDeclined])

  const handleAccept = useCallback(async () => {
    try {
      setIsLoading(true)

      await checkToken()

      await authRequest.user.directories.invitations.accept({
        token: '',
        directoryId: directory._id,
      })

      toast.success('Invitation Accepted')
      onAccepted?.()
    } catch (err) {
      handleError(err)
    } finally {
      setIsLoading(false)
    }
  }, [directory._id, onAccepted])

  return (
    <Paper elevation={0} {...props}>
      <Grid container justifyContent="space-between" padding={2}>
        <Grid item xs={12}>
          <Typography variant="h6">{directory.name}</Typography>
          <Typography variant="body2" sx={{ mb: 2 }}>
            Owner: {directory.ownerEmail}
          </Typography>
        </Grid>
        <Grid item container xs={12}>
          <Grid item xs>
            <Button onClick={handleDecline} color="secondary" disabled={isLoading}>
              Decline
            </Button>
          </Grid>
          <Grid item>
            <Button onClick={handleAccept} disabled={isLoading}>
              Accept
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  )
}

export default InvitationCard
