import {
  LayoutOption,
  LayoutOptionSelection,
} from '@counsel-project/counsel-transcribe-api/dist/common/database/RLayout'
import React, { useState, useEffect, useCallback } from 'react'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import TextField from '@mui/material/TextField'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import Typography from '@mui/material/Typography'
import DeleteIcon from '@mui/icons-material/DeleteRounded'
import SelectableTab from '../../components/SelectableTab'
import LayoutModelSelector from './LayoutModelSelector'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import ExpandMoreIcon from '@mui/icons-material/ExpandMoreRounded'
import ClosableDialog from '../../components/ClosableDialog'
import checkToken from '../../util/auth/checkToken'
import { transcribeRequest } from '../../util/api/transcribe-api'

type CategoryPresetDialogProps = {
  open: boolean
  onClose: () => void
  onSelect: (option: LayoutOption) => void
}

const CategoryPresetDialog = ({ open, onClose, onSelect }: CategoryPresetDialogProps) => {
  const [presetOptions, setPresetOptions] = useState<LayoutOption[]>([])

  const populatePresets = useCallback(async () => {
    try {
      await checkToken()

      const { results } = await transcribeRequest.layouts.getOptionPresets({
        token: '',
      })

      setPresetOptions(results)
    } catch (err) {
      console.error(err)
    }
  }, [])

  useEffect(() => {
    if (open) {
      populatePresets()
    }
  }, [open, populatePresets])

  return (
    <ClosableDialog open={open} onClose={onClose} titleText="Category Presets">
      <Grid container spacing={2} padding={2}>
        {presetOptions.map((option, i) => (
          <Grid item key={i} xs={12}>
            <Paper variant="outlined">
              <Grid container spacing={2} padding={2}>
                <Grid item xs>
                  <Typography variant="body1" fontWeight={500} fontSize={16}>
                    {option.categoryLabel}
                  </Typography>
                  <Typography variant="body1" color="text.secondary">
                    {option.options.length} Options
                  </Typography>
                </Grid>
                <Grid item>
                  <Button onClick={() => onSelect(option)}>Select</Button>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        ))}
      </Grid>
    </ClosableDialog>
  )
}

type MatchReplacerOptionProps = {
  match: string | undefined
  replacer: string | undefined
  onChange: (match: string | undefined, replacer: string | undefined) => void
  onDelete?: () => void
}

const MatchReplacerOption = ({ match, replacer, onChange, onDelete }: MatchReplacerOptionProps) => {
  return (
    <Grid container spacing={2} padding={2}>
      <Grid item xs={6}>
        <Typography variant="body1" fontWeight={500} fontSize={16}>
          Match
        </Typography>
        <TextField
          placeholder="What string to match for the replacer"
          value={match}
          onChange={(e) => onChange(e.target.value, replacer)}
          fullWidth
          multiline
        />
      </Grid>
      <Grid item xs>
        <Typography variant="body1" fontWeight={500} fontSize={16}>
          Replacer
        </Typography>
        <TextField
          placeholder="What to replace the match with"
          value={replacer}
          onChange={(e) => onChange(match, e.target.value)}
          fullWidth
          multiline
        />
      </Grid>
      {onDelete && (
        <Grid item>
          <IconButton onClick={onDelete}>
            <DeleteIcon />
          </IconButton>
        </Grid>
      )}
    </Grid>
  )
}

type SubLayoutOptionProps = {
  option: LayoutOptionSelection
  onChange: (option: LayoutOptionSelection) => void
  onDelete: () => void
  admin?: boolean
}

const SubLayoutOption = ({ option, onChange, onDelete, admin }: SubLayoutOptionProps) => {
  const { id, label, match, replacer, professions, secondPass, secondPassModel } = option

  const [selectedType, setSelectedType] = useState<'multi-match' | 'match' | 'second-pass'>('match')

  useEffect(() => {
    if (secondPass !== undefined || secondPassModel !== undefined) {
      setSelectedType('second-pass')
    } else if (typeof match === 'string' || typeof replacer === 'string') {
      setSelectedType('match')
    } else if (Array.isArray(match) && Array.isArray(replacer)) {
      setSelectedType('multi-match')
    } else {
      setSelectedType('match')
    }
  }, [match, secondPass, secondPassModel])

  const handleChangeType = (type: 'multi-match' | 'match' | 'second-pass') => {
    setSelectedType(type)
    if (type === 'match') {
      onChange({
        ...option,
        match: '',
        replacer: '',
        secondPass: undefined,
        secondPassModel: undefined,
      })
    } else if (type === 'second-pass') {
      onChange({
        ...option,
        match: undefined,
        replacer: undefined,
        secondPass: '',
        secondPassModel: 6,
      })
    } else {
      onChange({
        ...option,
        match: [''],
        replacer: [''],
        secondPass: undefined,
        secondPassModel: undefined,
      })
    }
  }

  return (
    <Paper variant="outlined">
      <Grid container spacing={2} padding={2}>
        <Grid item container xs={12} spacing={2}>
          <Grid item xs>
            <Typography variant="body1" fontWeight={500} fontSize={16}>
              ID
            </Typography>
            <TextField
              placeholder="Id here..."
              value={id}
              onChange={(e) => onChange({ ...option, id: e.target.value })}
              fullWidth
            />
          </Grid>
          <Grid item>
            <IconButton onClick={onDelete}>
              <DeleteIcon />
            </IconButton>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1" fontWeight={500} fontSize={16}>
            Label
          </Typography>
          <TextField
            placeholder="Label here..."
            value={label}
            onChange={(e) => onChange({ ...option, label: e.target.value })}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1" fontWeight={500} fontSize={16}>
            Option Type
          </Typography>
          <Typography variant="body1" color="text.secondary" sx={{ mb: 1 }}>
            What kind of action should this option perform?
          </Typography>
          <Grid container spacing={2}>
            <Grid item>
              <SelectableTab
                active={selectedType === 'multi-match'}
                onClick={() => handleChangeType('multi-match')}
                small
              >
                Multi-Match
              </SelectableTab>
            </Grid>
            <Grid item>
              <SelectableTab
                active={selectedType === 'match'}
                onClick={() => handleChangeType('match')}
                small
              >
                Match
              </SelectableTab>
            </Grid>
            <Grid item>
              <SelectableTab
                active={selectedType === 'second-pass'}
                onClick={() => handleChangeType('second-pass')}
                small
              >
                Second Pass
              </SelectableTab>
            </Grid>
          </Grid>
        </Grid>
        {selectedType === 'multi-match' && Array.isArray(match) && Array.isArray(replacer) && (
          <Grid item xs={12}>
            <Typography variant="body1" fontWeight={500} fontSize={16}>
              Multi-Match
            </Typography>
            <Typography variant="body1" color="text.secondary" sx={{ mb: 1 }}>
              Select multiple matches and their respective replacers
            </Typography>
            {match.map((m, i) => (
              <MatchReplacerOption
                key={i}
                match={m}
                replacer={replacer[i]}
                onChange={(m, r) => {
                  const newMatch = [...match]
                  newMatch[i] = m || ''
                  const newReplacer = [...replacer]
                  newReplacer[i] = r || ''
                  onChange({ ...option, match: newMatch, replacer: newReplacer })
                }}
                onDelete={() => {
                  const newMatch = [...match]
                  newMatch.splice(i, 1)
                  const newReplacer = [...replacer]
                  newReplacer.splice(i, 1)
                  onChange({ ...option, match: newMatch, replacer: newReplacer })
                }}
              />
            ))}
            <Button
              onClick={() =>
                onChange({ ...option, match: [...match, ''], replacer: [...replacer, ''] })
              }
            >
              Add Match
            </Button>
          </Grid>
        )}
        {selectedType === 'match' && (
          <MatchReplacerOption
            match={Array.isArray(match) ? '' : match}
            replacer={Array.isArray(replacer) ? '' : replacer}
            onChange={(match, replacer) => onChange({ ...option, match, replacer })}
          />
        )}
        {selectedType === 'second-pass' && (
          <Grid item container spacing={2} xs={12}>
            <Grid item xs={admin ? 6 : 12}>
              <Typography variant="body1" fontWeight={500} fontSize={16} sx={{ mb: 1 }}>
                {admin ? 'Prompt' : 'Instructions'}
              </Typography>
              <TextField
                placeholder="Prompt to pass the document through for a second pass..."
                value={secondPass}
                onChange={(e) => onChange({ ...option, secondPass: e.target.value })}
                fullWidth
                rows={8}
                multiline
              />
            </Grid>
            {!!admin && (
              <Grid item xs={6}>
                <Typography variant="body1" fontWeight={500} fontSize={16}>
                  Second Pass Model
                </Typography>
                <LayoutModelSelector
                  model={secondPassModel}
                  onChange={(model) => onChange({ ...option, secondPassModel: model || 1 })}
                />
              </Grid>
            )}
          </Grid>
        )}
      </Grid>
    </Paper>
  )
}

type LayoutOptionItemProps = {
  option: LayoutOption
  onChange: (option: LayoutOption) => void
  onDelete: () => void
  admin?: boolean
}

const LayoutOptionItem = ({ option, onChange, onDelete, admin }: LayoutOptionItemProps) => {
  const { type, categoryLabel, options, required, allowMultiple, separator } = option

  return (
    <Paper
      elevation={0}
      sx={(theme) => ({ backgroundColor: theme.palette.background.default, mb: 2 })}
    >
      <Grid container spacing={2} padding={2}>
        <Grid item xs>
          <Typography variant="body1" fontWeight={500} fontSize={18} sx={{ mb: 1 }}>
            Category
          </Typography>
          <TextField
            placeholder="Category Label"
            value={categoryLabel}
            onChange={(e) => onChange({ ...option, categoryLabel: e.target.value })}
            fullWidth
          />
        </Grid>
        <Grid item>
          <IconButton onClick={onDelete}>
            <DeleteIcon />
          </IconButton>
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                checked={type === 'checkbox'}
                onChange={(e) =>
                  onChange({ ...option, type: e.target.checked ? 'checkbox' : 'button' })
                }
              />
            }
            label="Is Checkbox?"
          />
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                checked={required}
                onChange={(e) => onChange({ ...option, required: e.target.checked })}
              />
            }
            label="Required?"
          />
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                checked={allowMultiple}
                onChange={(e) => onChange({ ...option, allowMultiple: e.target.checked })}
              />
            }
            label="Allow multiple options to be selected?"
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1" fontWeight={500} fontSize={16} sx={{ mb: 1 }}>
            Multi-option separator (if applicable)
          </Typography>
          <TextField
            placeholder="Separator character(s)"
            value={separator}
            onChange={(e) => onChange({ ...option, separator: e.target.value })}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1" fontWeight={500} fontSize={18} sx={{ mb: 2 }}>
            Options
          </Typography>
          <Grid container spacing={2}>
            {options.map((subOption, i) => (
              <Grid item key={i} xs={12}>
                <SubLayoutOption
                  admin={admin}
                  option={subOption}
                  onChange={(newOption) => {
                    const newOptions = [...options]
                    newOptions[i] = newOption
                    onChange({ ...option, options: newOptions })
                  }}
                  onDelete={() => {
                    const newOptions = [...options]
                    newOptions.splice(i, 1)
                    onChange({ ...option, options: newOptions })
                  }}
                />
              </Grid>
            ))}
            <Grid item xs={12}>
              <Button
                onClick={() =>
                  onChange({ ...option, options: [...options, { id: '', label: '' }] })
                }
                sx={{ mt: 2 }}
              >
                Add Option
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  )
}

type LayoutOptionsEditorProps = {
  admin?: boolean
  options: LayoutOption[]
  onSave: (options: LayoutOption[]) => void
  disabled?: boolean
}

const LayoutOptionsEditor = ({ options, onSave, admin, disabled }: LayoutOptionsEditorProps) => {
  const [stagedOptions, setStagedOptions] = useState(options)
  const [presetOpen, setPresetOpen] = useState(false)

  useEffect(() => {
    setStagedOptions(options)
  }, [options])

  const handleChangeOption = (i: number) => (newOption: LayoutOption) => {
    const newOptions = [...stagedOptions]
    newOptions[i] = newOption
    setStagedOptions(newOptions)
  }

  const handleDeleteOption = (i: number) => () => {
    const newOptions = [...stagedOptions]
    newOptions.splice(i, 1)
    setStagedOptions(newOptions)
  }

  return (
    <>
      <Accordion elevation={0}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>Layout Options</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2} sx={{ mt: 2 }}>
            {stagedOptions.map((option, i) => (
              <Grid item key={i} xs={12}>
                <LayoutOptionItem
                  admin={admin}
                  option={option}
                  onChange={handleChangeOption(i)}
                  onDelete={handleDeleteOption(i)}
                />
              </Grid>
            ))}
            <Grid item>
              <Button
                onClick={() =>
                  setStagedOptions([
                    ...stagedOptions,
                    {
                      type: 'button',
                      categoryLabel: '',
                      options: [],
                      required: false,
                      allowMultiple: false,
                      separator: '',
                    },
                  ])
                }
              >
                Add Category
              </Button>
            </Grid>
            <Grid item>
              <Button onClick={() => setPresetOpen(true)}>Add Category From Preset</Button>
            </Grid>
            <Grid item>
              <Button onClick={() => onSave(stagedOptions)} disabled={disabled}>
                Save
              </Button>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
      <CategoryPresetDialog
        open={presetOpen}
        onClose={() => setPresetOpen(false)}
        onSelect={(o) => {
          setPresetOpen(false)
          setStagedOptions([...stagedOptions, o])
        }}
      />
    </>
  )
}

export default LayoutOptionsEditor
