import { useTitle } from 'react-use'
import PageContainer from '../components/layout/PageContainer'
import { useViewContent } from '../util/tracking'
import ReferralCodeSection from './profile/ReferralCodeSection'

const SupportPage = () => {
  useViewContent('Support')
  useTitle('Clinical Notes AI - Support')

  return (
    <PageContainer>
      <ReferralCodeSection />
    </PageContainer>
  )
}

export default SupportPage
