import { RPatient } from '@counsel-project/counsel-transcribe-api'
import Grid from '@mui/material/Grid'
import Skeleton from '@mui/material/Skeleton'
import Typography from '@mui/material/Typography'
import { useTheme } from '@mui/material/styles'
import React, { useCallback, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import SimplePagination from '../../components/forms/SimplePagination'
import { usePatientNomenclature } from '../../util'
import {
  listAllPatientsCached,
  listMyPatientsCached,
  listUserPatientsCached,
  refreshPatientsCache,
  refreshSessionsCache,
} from '../../util/api/transcribe-api-cached'
import checkToken from '../../util/auth/checkToken'
import handleError from '../../util/handleError'
import { createSearchParams } from '../builder/common'
import PatientItem from './PatientItem'
import PatientSortOptions from './PatientSortOptions'

type ViewPatientsProps = {
  search?: string
  userId?: string | null
  showAll?: boolean
}

const ViewPatients = ({ search, userId, showAll }: ViewPatientsProps) => {
  const navigate = useNavigate()
  const location = useLocation()

  const theme = useTheme()

  const limit = 9
  const [patients, setPatients] = useState<RPatient[]>([])
  const [total, setTotal] = useState(0)
  const [offset, setOffset] = useState(0)
  const [loading, setLoading] = useState(true)
  const [direction, setDirection] = useState<'asc' | 'desc'>('asc')
  const [sort, setSort] = useState<'label' | 'createdAt' | 'updatedAt'>('label')

  const populateInitial = useCallback(async () => {
    try {
      setLoading(true)

      await checkToken()

      const searchByUser = userId && !showAll

      let results: RPatient[] = []
      let total = 0
      if (searchByUser) {
        const data = await listUserPatientsCached({
          userId,
          token: '',
          limit,
          offset,
          sort,
          direction,
          ...(search
            ? {
                search: {
                  or: [{ label: search, $fuzzy: true }],
                },
              }
            : {}),
        })
        results = data.results
        total = data.total
      } else if (showAll) {
        const data = await listAllPatientsCached({
          token: '',
          limit,
          offset,
          sort,
          direction,
          ...(search
            ? {
                search: {
                  or: [{ label: search, $fuzzy: true }],
                },
              }
            : {}),
        })
        results = data.results
        total = data.total
      } else {
        const data = await listMyPatientsCached({
          token: '',
          limit,
          offset,
          sort,
          direction,
          ...(search
            ? {
                search: {
                  or: [{ label: search, $fuzzy: true }],
                },
              }
            : {}),
        })
        results = data.results
        total = data.total
      }

      setPatients(results)
      setTotal(total)
    } catch (err) {
      handleError(err)
    } finally {
      setLoading(false)
    }
  }, [offset, search, direction, sort, userId, showAll])

  useEffect(() => {
    const timeout = setTimeout(() => {
      populateInitial()
    }, 10)
    return () => clearTimeout(timeout)
  }, [populateInitial])

  useEffect(() => {
    setOffset(0)
  }, [search, userId, showAll])

  const createPatientClickHandler = (patient: RPatient) => (e: React.MouseEvent) => {
    e.preventDefault()

    const queryText = createSearchParams({
      ref: location.pathname + location.search,
      userId: userId || undefined,
      showAll,
    })

    navigate(`/client/${patient._id}${queryText}`)
  }

  const patientNomenclature = usePatientNomenclature()

  const handleRefresh = useCallback(() => {
    refreshPatientsCache()
    refreshSessionsCache()
    populateInitial()
  }, [populateInitial])

  return (
    <>
      <PatientSortOptions
        sort={sort}
        direction={direction}
        onChangeDirection={setDirection}
        onChangeSort={setSort}
        onRefresh={handleRefresh}
        disabled={loading}
      />
      <Grid
        container
        spacing={2}
        sx={{
          mt: 1,
          mb: 2,
        }}
        alignItems="stretch"
      >
        {patients.map((patient) => (
          <Grid item key={patient._id} xs={12} sm={6} md={4}>
            <PatientItem patient={patient} onClick={createPatientClickHandler(patient)} />
          </Grid>
        ))}
        {patients.length === 0 && loading && (
          <>
            {Array.from({ length: limit }).map((_, i) => (
              <Grid item xs={12} sm={6} md={4} key={i}>
                <Skeleton
                  variant="rectangular"
                  height={100}
                  sx={{ backgroundColor: theme.palette.background.paper }}
                />
              </Grid>
            ))}
          </>
        )}
        {patients.length === 0 && !loading && (
          <Grid item xs={12} sm={6} md={4}>
            <Typography variant="body1" color="text.secondary">
              No {patientNomenclature}s found
            </Typography>
          </Grid>
        )}
      </Grid>
      <SimplePagination
        total={total}
        offset={offset}
        limit={limit}
        loading={loading}
        onClickNext={() => setOffset((prev) => prev + limit)}
        onClickPrevious={() => setOffset((prev) => prev - limit)}
      />
    </>
  )
}

export default ViewPatients
