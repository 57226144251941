import ClosableDialog from '../../components/ClosableDialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import Typography from '@mui/material/Typography'
import React, { useCallback, useState } from 'react'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import handleError from '../../util/handleError'
import checkToken from '../../util/auth/checkToken'
import { transcribeRequest } from '../../util/api/transcribe-api'
import toast from 'react-hot-toast'
import { ExpirePatientSessionsOptions } from '@counsel-project/counsel-transcribe-api'

type ClearDataDialogProps = {
  directoryId: string
  open: boolean
  onClose: () => void
}

const ClearDataDialog = ({ directoryId, open, onClose }: ClearDataDialogProps) => {
  const [dateValue, setDateValue] = useState<Date | null>(new Date())
  const [stagedExpiryValue, setStagedExpiryValue] = useState<string>(
    new Date().toISOString().split('T')[0]
  )
  const [loading, setLoading] = useState(false)
  const [deleteAll, setDeleteAll] = useState(false)
  const [dataType, setDataType] = useState<'unassigned' | 'all' | 'sessions' | 'documentation'>(
    'all'
  )

  const handleClearSessions = useCallback(async () => {
    try {
      if (!dateValue) return

      setLoading(true)

      await checkToken()

      let search: ExpirePatientSessionsOptions['search'] = undefined
      if (dataType === 'unassigned') {
        search = {
          and: [
            {
              patientLabel: null,
            },
          ],
        }
      } else if (dataType === 'sessions') {
        search = {
          and: [
            { type: 'context', $not: true },
            { type: 'document', $not: true },
            { type: 'note', $not: true },
            { type: 'custom', $not: true },
          ],
        }
      } else if (dataType === 'documentation') {
        search = {
          or: [{ type: 'document' }, { type: 'note' }],
        }
      } else if (dataType === 'all') {
        search = undefined
      }

      const { count } = await transcribeRequest.sessions.expire({
        token: '',
        pastDate: deleteAll
          ? new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 3).toString()
          : dateValue.toString(),
        search,
        directoryId,
      })

      toast.success(`${count} items marked for deletion`)
      onClose()
    } catch (err) {
      handleError(err)
    } finally {
      setLoading(false)
    }
  }, [dateValue, deleteAll, onClose, dataType, directoryId])

  return (
    <ClosableDialog open={open} onClose={onClose} titleText="Clear Sessions & Documentation">
      <DialogContent>
        <Typography variant="body1" sx={{ mb: 1 }} fontWeight={500} fontSize={18}>
          Delete all sessions, notes and documents older than the selected date within your
          organization.
        </Typography>
        <TextField
          fullWidth
          type="date"
          value={stagedExpiryValue}
          onChange={(e) => {
            setStagedExpiryValue(e.target.value)
            // Test if the date is a valid value
            if (isNaN(new Date(e.target.value).getTime())) {
              setDateValue(null)
            } else {
              setDateValue(new Date(e.target.value))
            }
          }}
          disabled={loading || deleteAll}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={deleteAll}
              onChange={() => setDeleteAll(!deleteAll)}
              name="deleteAll"
              disabled={loading}
            />
          }
          label="Delete Everything (Regardless of date)"
        />
        <Typography variant="body1" sx={{ mt: 2 }} fontWeight={500} fontSize={18}>
          What kind of data do you want to delete?
        </Typography>
        <FormControlLabel
          control={
            <Checkbox
              checked={dataType === 'sessions'}
              onChange={() => setDataType('sessions')}
              name="sessions"
              disabled={loading}
            />
          }
          label="Sessions"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={dataType === 'documentation'}
              onChange={() => setDataType('documentation')}
              name="documentation"
              disabled={loading}
            />
          }
          label="Notes & Documents"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={dataType === 'unassigned'}
              onChange={() => setDataType('unassigned')}
              name="unassigned"
              disabled={loading}
            />
          }
          label="All Unassigned Docs & Sessions"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={dataType === 'all'}
              onChange={() => setDataType('all')}
              name="all"
              disabled={loading}
            />
          }
          label="All"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClearSessions} disabled={loading || !dateValue}>
          {deleteAll ? 'Delete All Data' : 'Delete Data Before This Date'}
        </Button>
      </DialogActions>
    </ClosableDialog>
  )
}

export default ClearDataDialog
