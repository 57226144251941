import CheckIcon from '@mui/icons-material/Check'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import GenerateIcon from '@mui/icons-material/PsychologyRounded'
import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import { useCallback, useEffect, useState } from 'react'
import BlockEditor from '../../components/markdown/BlockEditor'
import CircularProgress from '@mui/material/CircularProgress'
import log from '../../util/logging'
import Animation from '../../components/Animation'

type LayoutCaptureInstructionsProps = {
  id: string
  title: string
  placeholder?: string
  generating?: boolean
  autoGenerate?: boolean
  onToggleGenerate?: () => void
  defaultValue?: string
  readOnly?: boolean
  onChange: (text: string) => void
  onSaving: (saving: boolean) => void
}

const LayoutCaptureInstructions = ({
  id,
  title,
  placeholder,
  generating,
  autoGenerate,
  onToggleGenerate,
  defaultValue,
  readOnly,
  onChange,
  onSaving,
}: LayoutCaptureInstructionsProps) => {
  const [open, setOpen] = useState(false)
  const [landingAnimation, setLandingAnimation] = useState<any>(undefined)

  const populateLandingAnimation = useCallback(async () => {
    try {
      if (!generating) return
      const animation = await fetch('/pen_notes_animation.json')
      const animationData = await animation.json()
      setLandingAnimation(animationData)
    } catch (error) {
      log.error(error)
    }
  }, [generating])

  useEffect(() => {
    populateLandingAnimation()
  }, [populateLandingAnimation])

  const handleGenerate = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    e.stopPropagation()
    onToggleGenerate?.()
  }

  const handleChange = (text: string) => {
    if ((text && autoGenerate) || (!text && !autoGenerate)) {
      onToggleGenerate?.()
    }
    onChange(text)
  }

  return (
    <Box>
      <Accordion elevation={0} expanded={open} onChange={() => setOpen(!open)}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Box
            sx={{
              flex: 1,
              verticalAlign: 'center',
              height: '100%',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Typography
              variant="body1"
              fontSize={18}
              fontWeight={500}
              sx={{
                flex: 1,
              }}
            >
              {title}
            </Typography>
            {generating ? (
              <CircularProgress size={24} sx={{ m: '14px' }} color="success" />
            ) : (
              <Button
                onClick={handleGenerate}
                variant={autoGenerate ? 'outlined' : 'text'}
                color={autoGenerate ? 'success' : 'primary'}
                startIcon={autoGenerate ? <CheckIcon /> : <GenerateIcon />}
                sx={(theme) => ({
                  mr: 1,
                  border: `2px solid ${autoGenerate ? theme.palette.success.main : 'transparent'}`,
                  '&:hover': {
                    border: `2px solid ${
                      autoGenerate ? theme.palette.success.main : theme.palette.primary.main
                    }`,
                  },
                })}
                disabled={readOnly}
              >
                Auto Generate
              </Button>
            )}
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          {defaultValue !== undefined && !generating && (
            <Box
              sx={(theme) => ({
                border: `1px solid ${theme.palette.divider}`,
                borderRadius: 1,
                backgroundColor: autoGenerate ? theme.palette.action.selected : 'transparent',
              })}
            >
              <BlockEditor
                minimal
                readOnly={readOnly}
                id={id}
                placeholder={placeholder}
                defaultValue={defaultValue}
                onSave={handleChange}
                onSaving={onSaving}
                onEmail={() => {}}
                onCreateNewNote={() => {}}
              />
            </Box>
          )}
          {generating && (
            <Box>
              <Box sx={{ height: 160 }}>
                <Animation
                  id={id + '-animation'}
                  animation={landingAnimation}
                  sx={{ height: '100%', width: '100%' }}
                  renderer="canvas"
                  autoplay
                  loop
                  speed={0.7}
                />
              </Box>
              <Typography variant="body1" textAlign="center" fontWeight={500} sx={{ mt: 2 }}>
                Generating Capture Instructions...
              </Typography>
            </Box>
          )}
        </AccordionDetails>
      </Accordion>
    </Box>
  )
}

export default LayoutCaptureInstructions
