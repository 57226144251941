import Autocomplete, { AutocompleteProps, createFilterOptions } from '@mui/material/Autocomplete'
import CircularProgress from '@mui/material/CircularProgress'
import TextField from '@mui/material/TextField'
import React, { useCallback, useEffect, useState } from 'react'
import { transcribeRequest } from '../util/api/transcribe-api'
import checkToken from '../util/auth/checkToken'
import handleError from '../util/handleError'
import { RIntegrationTemplate } from '@counsel-project/counsel-ehr-api'
import { ehrRequest } from '../util/api/ehr-api'

const filter = createFilterOptions<RIntegrationTemplate>()

export type IntegrationTemplateSelectorProps = {
  integrationId?: string
  id?: string
  placeholder?: string
  value: RIntegrationTemplate | string | null
  onChange: (value: RIntegrationTemplate | null) => void
  onChangeInputValue?: (value: string) => void
  onChangeShownOptions?: (options: RIntegrationTemplate[]) => void
} & Omit<
  AutocompleteProps<RIntegrationTemplate, false, false, true>,
  'renderInput' | 'renderOption' | 'onChange' | 'value' | 'options'
>

export const capitalizeInput = (input: string) => {
  if (!input) return ''
  const words = input.split(' ')
  const capitalizedWords = words.map(
    (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
  )
  return capitalizedWords.join(' ')
}

const IntegrationTemplateSelector = (
  {
    integrationId,
    id,
    placeholder = 'Search...',
    value,
    onChange,
    onChangeInputValue,
    onChangeShownOptions,
    disabled,
    ...props
  }: IntegrationTemplateSelectorProps,
  ref: React.Ref<HTMLDivElement>
) => {
  const [shownOptions, setShownOptions] = useState<RIntegrationTemplate[]>([])
  const [inputValue, setInputValue] = useState('')
  const [loading, setLoading] = useState(false)
  const [submitLoading, setSubmitLoading] = useState(false)

  const populateOptions = useCallback(
    async (search: string) => {
      try {
        await checkToken()

        setLoading(true)

        if (integrationId) {
          const data = await ehrRequest.integrations.templates.listByIntegration({
            integrationId,
            token: '',
            search: !search
              ? undefined
              : {
                  or: [{ name: search, $fuzzy: true }],
                },
          })

          setShownOptions(data.results)
          onChangeShownOptions?.(data.results)
        } else {
          const data = await ehrRequest.integrations.templates.list({
            token: '',
            search: !search
              ? undefined
              : {
                  or: [{ name: search, $fuzzy: true }],
                },
          })

          setShownOptions(data.results)
          onChangeShownOptions?.(data.results)
        }
      } catch (err) {
        handleError(err)
      } finally {
        setLoading(false)
      }
    },
    [onChangeShownOptions, integrationId]
  )

  useEffect(() => {
    if (value) return

    const timeout = setTimeout(() => {
      populateOptions(inputValue)
    }, 500)

    return () => {
      clearTimeout(timeout)
    }
  }, [inputValue, populateOptions, value])

  const handleChange = useCallback(
    async (_e: unknown, value: string | RIntegrationTemplate | null) => {
      if (value === null) {
        onChange(null)
        return
      }

      if (typeof value !== 'string') {
        onChange(value)
        return
      }

      if (!integrationId) return
      try {
        setLoading(true)
        setSubmitLoading(true)

        await checkToken()

        const capitalValue = capitalizeInput(value)

        const { results } = await ehrRequest.integrations.templates.listByIntegration({
          integrationId,
          token: '',
          search: {
            and: [{ name: capitalValue }],
          },
          limit: 1,
        })

        if (results.length === 0) {
          onChange(null)
          return
        }

        if (results[0]) {
          onChange(results[0])
          return
        }
      } catch (err) {
        handleError(err)
      } finally {
        setSubmitLoading(false)
        setLoading(false)
      }
    },
    [onChange, integrationId]
  )

  const handleInputChange = useCallback(
    (e: React.SyntheticEvent, value: string) => {
      if (value.endsWith(',')) {
        const newValue = value.slice(0, -1)
        setInputValue(newValue)
        onChangeInputValue?.(newValue)
        return
      }

      setInputValue(value)
      onChangeInputValue?.(value)
    },
    [setInputValue, onChangeInputValue]
  )

  return (
    <Autocomplete
      {...props}
      ref={ref}
      id={id}
      value={value}
      onChange={handleChange}
      inputValue={inputValue}
      onInputChange={handleInputChange}
      options={shownOptions}
      loading={loading}
      getOptionLabel={(option) => {
        if (typeof option === 'string') {
          return option
        }
        return option.name
      }}
      noOptionsText="No individuals found"
      freeSolo
      selectOnFocus
      handleHomeEndKeys
      clearOnBlur
      disabled={disabled || submitLoading}
      renderOption={(props, option) => (
        <li {...props}>
          <img
            src={`/integrations/${option.integrationType}.svg`}
            alt=""
            style={{ marginRight: 12, width: 24, height: 24, objectFit: 'contain' }}
          />
          {option.name}
        </li>
      )}
      filterOptions={(options, params) => {
        const filtered = filter(options, params)
        return filtered
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder={placeholder}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  )
}

export default React.forwardRef(IntegrationTemplateSelector)
