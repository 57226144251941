import AddIcon from '@mui/icons-material/AddRounded'
import AddLayoutContainer from './AddLayoutContainer'
import Typography from '@mui/material/Typography'

type AddLayoutBoxProps = {
  onClick: () => void
}

const AddLayoutBox = ({ onClick }: AddLayoutBoxProps) => {
  return (
    <AddLayoutContainer onClick={onClick}>
      <AddIcon fontSize="large" />
      <Typography fontWeight={500} sx={{ mt: 1 }}>
        Create New Template
      </Typography>
    </AddLayoutContainer>
  )
}

export default AddLayoutBox
