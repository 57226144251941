import log from '../../../util/logging'

const attemptParse = (json: string) => {
  try {
    return JSON.parse(json)
  } catch (e) {
    log.error(`Failed to parse JSON: ${json}`)
    return null
  }
}

export const retrieveTranscripts = (): string[] => {
  try {
    const storage = localStorage.getItem('transcripts')
    const transcripts = storage ? attemptParse(storage) : null
    if (Array.isArray(transcripts) && transcripts.every((t) => typeof t === 'string')) {
      return transcripts
    } else {
      if (transcripts) {
        log.warn(`Invalid transcript storage: ${transcripts}`)
      }
      return []
    }
  } catch (err) {
    log.error(err)
    return []
  }
}

export const storeTranscripts = (transcripts: string[]) => {
  try {
    const prev = retrieveTranscripts()
    const next = [...prev, ...transcripts]
    localStorage.setItem('transcripts', JSON.stringify(next))
  } catch (err) {
    log.error(err)
  }
}

export const clearTranscripts = () => {
  try {
    localStorage.removeItem('transcripts')
  } catch (err) {
    log.error(err)
  }
}

export const removeSpeakerLabels = (str: string) => {
  // Remove speaker labels like: "Speaker 0:", "Speaker 1:", etc.
  return str.replace(/Speaker \d+: /g, '')
}
