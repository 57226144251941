import { RPatientSession } from '@counsel-project/counsel-transcribe-api'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import { useCallback, useEffect, useState } from 'react'
import { transcribeRequest } from '../../util/api/transcribe-api'
import checkToken from '../../util/auth/checkToken'
import FileUpload from '../forms/FileUpload'
import handleError from '../../util/handleError'

type UploadAudioTranscribeProps = {
  onFinishTranscribe: (session: RPatientSession) => void
  onSetLoading?: (loading: boolean) => void
}

const UploadAudioTranscribe = ({
  onFinishTranscribe,
  onSetLoading,
}: UploadAudioTranscribeProps) => {
  const [audio, setAudio] = useState<File | null>(null)
  const [loading, setLoading] = useState(false)

  const handleUploadFile = useCallback(async () => {
    try {
      if (!audio) return

      setLoading(true)
      onSetLoading?.(true)

      await checkToken()

      const formData = new FormData()
      formData.append('files', audio)

      const { result } = await transcribeRequest.sessions.transcribe.sendAudio({
        token: '',
        formData,
        startedAtString: new Date().toLocaleString(),
        endedAtString: new Date().toLocaleString(),
        language: 'en',
        dictation: false,
      })

      onFinishTranscribe(result)
    } catch (err) {
      handleError(err)
    } finally {
      setAudio(null)
      setLoading(false)
      onSetLoading?.(false)
    }
  }, [audio, onSetLoading, onFinishTranscribe])

  useEffect(() => {
    if (!audio) return
    handleUploadFile()
  }, [audio, handleUploadFile])

  return (
    <Paper sx={{ p: 2 }} elevation={0}>
      <Typography variant="body1" fontWeight={500} sx={{ mb: 1 }} textAlign="center">
        Or upload your conversation
      </Typography>
      <FileUpload
        disabled={loading}
        accept="audio/*,video/*,application/octet-stream,application/ogg,application/x-ogg,application/ogg,audio/ogg,audio/wav,audio/mpeg,audio/mp3,audio/x-wav,audio/x-mpeg,audio/x-mp3,audio/x-m4a,audio/x-aac,audio/x-aiff,audio/x-flac"
        title="Upload Audio File"
        file={audio}
        onFileChange={setAudio}
      />
    </Paper>
  )
}

export default UploadAudioTranscribe
