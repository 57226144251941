import { isApiError } from '@counsel-project/client-utils'
import { useCallback, useEffect, useState } from 'react'
import { toast } from 'react-hot-toast'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useTitle } from 'react-use'
import UpgradeLicenseDialog from '../components/licensing/UpgradeLicenseDialog'
import { getLicenseLimit } from '../components/licensing/util'
import { authRequest } from '../util/api/auth-api'
import checkToken from '../util/auth/checkToken'
import useActiveLicense from '../util/auth/useActiveLicense'
import log from '../util/logging'
import OverlayLoader from './builder/OverlayLoader'
import handleError from '../util/handleError'

const CheckLicenseLimitPage = () => {
  useTitle('Clinical Notes AI - Checking License Limits...')
  const [searchParams] = useSearchParams()

  const limit = searchParams.get('limit')
  const cb = searchParams.get('cb')

  const navigate = useNavigate()

  const [upsellDialog, setUpsellDialog] = useState(false)
  const [activeLicense, setActiveLicense] = useActiveLicense()

  const handlePopulateInit = useCallback(async () => {
    try {
      if (!cb) {
        return navigate('/builder', { replace: true })
      }

      if (!limit) {
        return navigate('/builder', { replace: true })
      }

      if (limit !== 'sessions' && limit !== 'dictates' && limit !== 'documents') {
        return navigate('/builder', { replace: true })
      }

      await checkToken()

      const { license } = await authRequest.user.licenses.getActive({
        token: '',
      })

      setActiveLicense(license)

      const l = getLicenseLimit(license, limit)
      if (l.remaining <= 0) {
        setUpsellDialog(true)
        return
      }

      navigate(cb, { replace: true })
    } catch (err) {
      handleError(err)
      navigate('/builder', { replace: true })
    }
  }, [navigate, setActiveLicense, limit, cb])

  useEffect(() => {
    const timeout = setTimeout(() => {
      handlePopulateInit()
    }, 100)

    return () => clearTimeout(timeout)
  }, [handlePopulateInit])

  const handleClose = () => {
    setUpsellDialog(false)
    navigate('/pricing', { replace: true })
  }

  if (!upsellDialog) {
    return <OverlayLoader />
  }

  return <UpgradeLicenseDialog license={activeLicense} open={upsellDialog} onClose={handleClose} />
}

export default CheckLicenseLimitPage
