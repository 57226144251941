import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import { alpha, styled } from '@mui/material/styles'

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(1),
  borderRadius: theme.spacing(8),
  boxShadow: `0px 4px 8px ${alpha(theme.palette.common.black, 0.1)}`,
}))

const StyledButton = styled(Button)(({ theme }) => ({
  borderRadius: theme.spacing(4),
  padding: theme.spacing(0.5, 2),
}))

type PricingSliderProps = {
  value: 'annually' | 'monthly'
  onChange: (value: 'annually' | 'monthly') => void
}

const PricingSlider = ({ value, onChange }: PricingSliderProps) => {
  const annual = value === 'annually'

  return (
    <Grid container justifyContent="center">
      <Grid item>
        <StyledPaper>
          <Grid container spacing={1}>
            <Grid item>
              <StyledButton
                fullWidth
                onClick={() => onChange('annually')}
                color="secondary"
                variant={annual ? 'contained' : 'outlined'}
              >
                Yearly Pricing
              </StyledButton>
            </Grid>
            <Grid item>
              <StyledButton
                fullWidth
                onClick={() => onChange('monthly')}
                color="secondary"
                variant={annual ? 'outlined' : 'contained'}
              >
                Monthly Pricing
              </StyledButton>
            </Grid>
          </Grid>
        </StyledPaper>
      </Grid>
    </Grid>
  )
}

export default PricingSlider
