import React, { useEffect, useState } from 'react'
import SearchBar, { SearchBarProps } from './SearchBar'

export type SearchFieldProps = {
  value?: string
  onChange?: (value: string) => void
} & Omit<SearchBarProps, 'onSubmit' | 'value' | 'onChange' | 'onClick'>

const SearchField = ({ value: defaultValue, onChange, ...props }: SearchFieldProps) => {
  const [value, setValue] = useState('')

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value)
    if (!e.target.value) {
      onChange?.('')
    }
  }

  const handleSubmit = () => {
    onChange?.(value)
  }

  useEffect(() => {
    setValue(defaultValue || '')
  }, [defaultValue])

  return (
    <SearchBar
      {...props}
      value={value}
      onChange={handleChange}
      onSubmit={handleSubmit}
      onClick={handleSubmit}
    />
  )
}

export default SearchField
