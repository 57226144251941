import Paper from '@mui/material/Paper'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import CircularProgress from '@mui/material/CircularProgress'
import { useCallback, useEffect, useState } from 'react'
import checkToken from '../../util/auth/checkToken'
import { visionRequest } from '../../util/api/vision-api'
import log from '../../util/logging'
import { RLayout } from '@counsel-project/counsel-transcribe-api'
import { transcribeRequest } from '../../util/api/transcribe-api'

type LayoutGeneratingCardProps = {
  processId: string
  onFinished: (layout: RLayout) => void
}

const LayoutGeneratingCard = ({ processId, onFinished }: LayoutGeneratingCardProps) => {
  const [finished, setFinished] = useState(false)

  const populateProcess = useCallback(async () => {
    try {
      await checkToken()

      const res = await visionRequest.layouts.check({ token: '', processId })

      if (res.layoutId) {
        setFinished(true)
        const { result } = await transcribeRequest.layouts.get({
          token: '',
          layoutId: res.layoutId,
        })

        onFinished(result)
      }
    } catch (err) {
      log.error(err)
    }
  }, [processId, onFinished])

  useEffect(() => {
    if (finished) return
    const interval = setInterval(() => {
      populateProcess()
    }, 6000)

    return () => clearInterval(interval)
  }, [populateProcess, finished])

  return (
    <Paper
      elevation={2}
      sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        p: 2,
        minHeight: 200,
      }}
    >
      <Typography variant="body1" fontWeight={500} textAlign="center" sx={{ mb: 2 }}>
        Generating Template from file...
      </Typography>
      <CircularProgress size={32} color="success" />
    </Paper>
  )
}

export default LayoutGeneratingCard
