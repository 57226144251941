import React, { useCallback, useState, useEffect } from 'react'
import { PermissionType, RDirectory, RRole } from '@counsel-project/counsel-auth-api'
import { authRequest } from '../../util/api/auth-api'
import checkToken from '../../util/auth/checkToken'
import OrganizationPage from './OrganizationPage'
import { useNavigate } from 'react-router-dom'
import { useTitle } from 'react-use'

const Organization = () => {
  useTitle('Clinical Notes AI - Organization')
  const navigate = useNavigate()

  const [directory, setDirectory] = useState<RDirectory | null>(null)
  const [permission, setPermission] = useState<PermissionType>('counselor')
  const [priority, setPriority] = useState<number>(0)

  const populateDirectory = useCallback(async () => {
    try {
      await checkToken()

      const { results } = await authRequest.user.directories.list({
        token: '',
      })

      if (results.length === 0) {
        return navigate('/organizations/new')
      }

      if (results.length > 1) {
        return navigate('/organizations')
      }

      const d = results[0]

      const res = await authRequest.user.directories.get({
        token: '',
        directoryId: d._id,
      })

      setDirectory(res.result ?? null)
      setPermission(res.permission)
      setPriority(res.priority)
    } catch (err) {
      console.error(err)
      navigate('/organizations/new')
    }
  }, [navigate])

  useEffect(() => {
    populateDirectory()
  }, [populateDirectory])

  if (!directory) return null

  return (
    <OrganizationPage
      directory={directory}
      permission={permission}
      priority={priority}
      onChangeDirectory={setDirectory}
    />
  )
}

export default Organization
