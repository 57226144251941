import { RRole } from '@counsel-project/counsel-auth-api'
import Autocomplete, { AutocompleteProps } from '@mui/material/Autocomplete'
import CircularProgress from '@mui/material/CircularProgress'
import TextField from '@mui/material/TextField'
import React, { useCallback, useEffect, useState } from 'react'
import { authRequest } from '../../util/api/auth-api'
import checkToken from '../../util/auth/checkToken'
import getUser from '../../util/auth/getUser'
import handleError from '../../util/handleError'

export type RoleSelectorProps = {
  value: RRole | null
  onChange: (value: RRole | null) => void
  directoryId?: string
  hideEmails?: string[]
} & Omit<
  AutocompleteProps<RRole, false, false, false>,
  'renderInput' | 'renderOption' | 'onChange' | 'value' | 'options'
>

const RoleSelector = (
  { value, onChange, directoryId, hideEmails, ...props }: RoleSelectorProps,
  ref: React.Ref<HTMLDivElement>
) => {
  const [shownOptions, setShownOptions] = useState<RRole[]>([])
  const [inputValue, setInputValue] = useState('')
  const [loading, setLoading] = useState(false)

  const populateOptions = useCallback(
    async (search: string) => {
      try {
        setLoading(true)

        const user = getUser()
        if (!user) return

        await checkToken()

        let allResults: RRole[] = []

        if (!directoryId) {
          const { directoryIds } = await authRequest.user.lookup.directories.byUserId({
            token: '',
            userId: user._id,
          })

          if (!directoryIds || directoryIds.length === 0) return

          const rolesResults = await Promise.all(
            directoryIds.map((id) =>
              authRequest.user.directories.roles.list({
                token: '',
                directoryId: id,
                limit: 10,
                search: search
                  ? {
                      and: [{ email: search, $fuzzy: true }],
                    }
                  : undefined,
              })
            )
          )

          allResults = rolesResults.map((r) => r.results).flat()

          allResults = allResults.filter(
            (role, index, self) => index === self.findIndex((r) => r.userId === role.userId)
          )
        } else {
          const rolesResults = await authRequest.user.directories.roles.list({
            token: '',
            directoryId,
            limit: 10,
            search: search
              ? {
                  and: [{ email: search, $fuzzy: true }],
                }
              : undefined,
          })

          allResults = rolesResults.results
        }

        setShownOptions(
          allResults.filter((role) => !hideEmails || !hideEmails.includes(role.email))
        )
      } catch (err) {
        handleError(err)
      } finally {
        setLoading(false)
      }
    },
    [directoryId, hideEmails]
  )

  useEffect(() => {
    const timeout = setTimeout(() => {
      populateOptions(inputValue)
    }, 300)

    return () => {
      clearTimeout(timeout)
    }
  }, [inputValue, populateOptions])

  return (
    <>
      <Autocomplete
        {...props}
        ref={ref}
        value={value}
        onChange={(e, value) => onChange(value)}
        inputValue={inputValue}
        onInputChange={(e, value) => {
          setInputValue(value)
        }}
        options={shownOptions}
        loading={loading}
        getOptionLabel={(option) => option.email}
        noOptionsText="No users found"
        id="role-selector"
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder="Search..."
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
      />
    </>
  )
}

export default React.forwardRef(RoleSelector)
