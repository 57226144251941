// editorjs parser to output to markdown

import { reverseInlineParser } from './inlineParser'

const parsePageBreak = (block: EditorJS.OutputData['blocks'][0]): string => {
  return '---\n'
}

const parseParagraph = (block: EditorJS.OutputData['blocks'][0], template?: boolean): string => {
  return reverseInlineParser(block.data.text, template) + '\n'
}

const parseHeader = (block: EditorJS.OutputData['blocks'][0], template?: boolean): string => {
  return '#'.repeat(block.data.level) + ' ' + reverseInlineParser(block.data.text, template) + '\n'
}

const parseExportBox = (block: EditorJS.OutputData['blocks'][0]): string => {
  return '```exportable\n' + block.data.content + '\n```\n'
}

const parseAIBox = (block: EditorJS.OutputData['blocks'][0]): string => {
  return '```ai\n' + block.data.content + '\n```\n'
}

const parseAIExportBox = (block: EditorJS.OutputData['blocks'][0]): string => {
  return '```ai-exportable\n' + block.data.content + '\n```\n'
}

const parseList = (block: EditorJS.OutputData['blocks'][0], template?: boolean): string => {
  return (
    block.data.items
      .map((item: string, index: number) => {
        if (block.data.style === 'ordered') {
          return `${index + 1}. ${reverseInlineParser(item, template)}`
        }
        return `- ${reverseInlineParser(item, template)}`
      })
      .join('\n') + '\n'
  )
}

const parseChecklist = (block: EditorJS.OutputData['blocks'][0], template?: boolean): string => {
  return (
    block.data.items
      .map(
        (item: any) => `- [${item.checked ? 'x' : ' '}] ${reverseInlineParser(item.text, template)}`
      )
      .join('\n') + '\n'
  )
}

// const parseTable = (block: EditorJS.OutputData['blocks'][0], template?: boolean): string => {
//   const headers = block.data.content[0].map((h: any) => reverseInlineParser(h.trim(), template))
//   const rows = block.data.content
//     .slice(1)
//     .map((row: string[]) => row.map((cell: string) => reverseInlineParser(cell.trim(), template)))

//   const headersLine = '| ' + headers.join(' | ') + ' |\n'
//   const separator = '| ' + headers.map(() => '---').join(' | ') + ' |\n'
//   const rowLines = rows.map((row: string[]) => '| ' + row.join(' | ') + ' |').join('\n')

//   return headersLine + separator + rowLines + '\n'
// }

const parseChecklistTable = (
  block: EditorJS.OutputData['blocks'][0],
  template?: boolean
): string => {
  const checkedMap = block.data.checked
  const headers = block.data.content?.[0]
    ? block.data.content[0].map((h: any) => reverseInlineParser(h.trim(), template))
    : []
  const rows =
    block.data.content.length > 1
      ? block.data.content
          .slice(1)
          .map((row: string[]) =>
            row.map((cell: string) => reverseInlineParser(cell.trim(), template))
          )
      : []

  const headersLine =
    '| ' +
    headers
      .map((h: string, i: number) => {
        const isChecked = checkedMap[0][i]
        if (isChecked === true) {
          if (!h) return '- [x] '
          return '- [x] ' + h + ' '
        } else if (isChecked === false) {
          if (!h) return '- [ ] '
          return '- [ ] ' + h + ' '
        }
        if (!h) return ''
        return h + ' '
      })
      .join('| ') +
    '|\n'

  const rowLines = rows
    .map((row: string[], i: number) => {
      return (
        '| ' +
        row
          .map((cell: string, j: number) => {
            const isChecked = checkedMap[i + 1][j]
            if (isChecked === true) {
              if (!cell) return '- [x] '
              return '- [x] ' + reverseInlineParser(cell, template) + ' '
            } else if (isChecked === false) {
              if (!cell) return '- [ ] '
              return '- [ ] ' + reverseInlineParser(cell, template) + ' '
            }
            if (!cell) return ''
            return cell + ' '
          })
          .join('| ') +
        '|'
      )
    })
    .join('\n')

  const separator = '| ' + headers.map(() => '---').join(' | ') + ' |\n'

  return headersLine + separator + rowLines + '\n'
}

const blocksToMarkdown = (blocks: EditorJS.OutputData['blocks'], template?: boolean): string => {
  let markdown = ''

  for (const blockIndex in blocks) {
    const block = blocks[blockIndex]
    const previousBlock = blocks[parseInt(blockIndex) - 1]
    if (previousBlock && previousBlock.type === 'table' && block.type === 'table') {
      // Give it more space
      markdown += '\n'
    }

    switch (block.type) {
      case 'paragraph':
        markdown += parseParagraph(block, template)
        break
      case 'header':
        markdown += parseHeader(block, template)
        break
      case 'exportBox':
        markdown += parseExportBox(block)
        break
      case 'aiBox':
        markdown += parseAIBox(block)
        break
      case 'aiExportBox':
        markdown += parseAIExportBox(block)
        break
      case 'list':
        markdown += parseList(block, template)
        break
      case 'checklist':
        markdown += parseChecklist(block, template)
        break
      case 'table':
        markdown += parseChecklistTable(block, template)
        break
      case 'pageBreak':
        markdown += parsePageBreak(block)
        break
      default:
        break
    }

    // const blockAhead = blocks[parseInt(blockIndex) + 1]
    // if (blockAhead && (blockAhead.type === 'table' || blockAhead.type === 'checklistTable')) {
    //   // Give it more space
    //   markdown += '\n'
    // }
  }

  return markdown
}

export default blocksToMarkdown
