import UploadIcon from '@mui/icons-material/UploadRounded'
import Typography from '@mui/material/Typography'
import AddLayoutContainer from './AddLayoutContainer'
import { styled } from '@mui/material/styles'

const UploadOverlay = styled('input')(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  opacity: 0,
  cursor: 'pointer',
}))

type UploadLayoutBoxProps = {
  onUpload: (file: File) => void
}

const UploadLayoutBox = ({ onUpload }: UploadLayoutBoxProps) => {
  const handleChangeFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0]
    if (!file) return

    onUpload(file)
  }

  return (
    <AddLayoutContainer sx={{ position: 'relative' }}>
      <UploadOverlay type="file" accept=".pdf,.docx,.png,.jpeg,.jpg" onChange={handleChangeFile} />
      <UploadIcon fontSize="large" />
      <Typography fontWeight={500} sx={{ mt: 1 }}>
        Create From File
      </Typography>
    </AddLayoutContainer>
  )
}

export default UploadLayoutBox
