import Box from '@mui/material/Box'
import { alpha, styled } from '@mui/material/styles'

const AddLayoutContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: 2,
  width: '100%',
  minHeight: 160,
  height: '100%',
  border: `2px solid ${theme.palette.primary.main}`,
  backgroundColor: alpha(theme.palette.primary.main, 0.025),
  borderRadius: theme.shape.borderRadius,
  cursor: 'pointer',
  color: theme.palette.primary.main,
  transition: 'background-color 0.2s',
  ':hover': {
    backgroundColor: alpha(theme.palette.primary.main, 0.1),
  },
}))

export default AddLayoutContainer
