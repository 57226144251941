import { RLicense } from '@counsel-project/counsel-auth-api'
import { Product } from '@counsel-project/counsel-auth-api'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { useCallback, useEffect, useMemo, useState } from 'react'
import toast from 'react-hot-toast'
import { useNavigate } from 'react-router-dom'
import { authRequest } from '../../util/api/auth-api'
import ProductPricingCard from '../commerce/ProductPricingCard'
import LicenseCard from './LicenseCard'
import { UserSubscriptionListResponse } from '@counsel-project/counsel-auth-api/dist/node_api/endpoints/user/subscriptions'
import checkToken from '../../util/auth/checkToken'
import log from '../../util/logging'

type UpgradeLicenseDialogProps = {
  license: RLicense | null
  open: boolean
  onClose: () => void
}

const UpgradeLicenseDialog = ({ license, open, onClose }: UpgradeLicenseDialogProps) => {
  const navigate = useNavigate()

  const [products, setProducts] = useState<Product[]>([])
  const [subscription, setSubscription] = useState<
    UserSubscriptionListResponse['subscriptions'][0] | undefined
  >(undefined)

  const populateProducts = useCallback(async () => {
    try {
      const res = await authRequest.products.list()
      setProducts(
        res.products
          .map((p) => p.product)
          .sort((a, b) => parseInt(a.metadata.order || '0') - parseInt(b.metadata.order || '0'))
      )
    } catch (e) {
      toast.error('Unable to load products')
    }
  }, [])

  useEffect(() => {
    const timeout = setTimeout(() => {
      populateProducts()
    }, 10)
    return () => clearTimeout(timeout)
  }, [populateProducts])

  const populateSubscriptions = useCallback(async () => {
    try {
      await checkToken()

      const res = await authRequest.user.subscriptions.list({
        token: '',
      })

      const activeSub = res.subscriptions.sort((a, b) => {
        if (a.status === 'active') return -1
        if (b.status === 'active') return 1
        if (a.status === 'past_due') return -1
        if (b.status === 'past_due') return 1
        return 0
      })?.[0]

      setSubscription(activeSub)
    } catch (err) {
      log.error(err)
    }
  }, [])

  useEffect(() => {
    const timeout = setTimeout(() => {
      populateSubscriptions()
    }, 10)
    return () => clearTimeout(timeout)
  }, [populateSubscriptions])

  const upsellProducts = useMemo(() => {
    if (!license) return []
    const currentPriority = license.priority || 0
    const nextProducts = products.filter(
      (p) => parseInt(p.metadata.priority || '0') > currentPriority && p.metadata.priceText
    )
    return nextProducts || []
  }, [license, products])

  if (!license) return null

  if (upsellProducts.length === 0) {
    return (
      <Dialog open={open} onClose={onClose} aria-labelledby="alert-dialog-title">
        <DialogTitle id="alert-dialog-title" textAlign="center">
          Upgrade Membership
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2} justifyContent="center" alignItems="center">
            <Grid item xs={12}>
              <Typography variant="body1" textAlign="center" fontSize={16}>
                You have reached your limit with your current membership. Please contact support for
                assistance.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <LicenseCard license={license} shownLimits={['sessions', 'dictates', 'documents']} />
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    )
  }

  return (
    <Dialog open={open} onClose={onClose} aria-labelledby="alert-dialog-title">
      <DialogTitle id="alert-dialog-title" textAlign="center">
        Upgrade Membership
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2} justifyContent="center" alignItems="center">
          <Grid item xs={12}>
            <Typography variant="body1" textAlign="center" fontSize={16}>
              You have reached your limit with your current membership. Upgrade to keep going!
            </Typography>
          </Grid>
          {upsellProducts.map((p) => (
            <Grid item xs={12} sm={6} key={p.id}>
              <ProductPricingCard
                subscription={subscription}
                product={p}
                onClick={() => {
                  navigate(`/purchase?productId=${p.id}`)
                }}
                elevation={0}
              />
            </Grid>
          ))}
          <Grid item xs={12}>
            <LicenseCard license={license} shownLimits={['sessions', 'dictates', 'documents']} />
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  )
}

export default UpgradeLicenseDialog
