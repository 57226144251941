import { RPatientSession } from '@counsel-project/counsel-transcribe-api'

const getSessionIcon = (session: RPatientSession) => {
  if (!session.note) {
    return '/icons/user-speak.svg'
  }
  if (session.type === 'note') {
    return '/icons/notes.svg'
  }
  if (session.type === 'document') {
    return '/icons/book-2.svg'
  }
  if (session.type === 'observation') {
    return '/icons/eye.svg'
  }
  return '/icons/notes.svg'
}

export default getSessionIcon
