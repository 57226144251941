import { useNavigate } from 'react-router-dom'
import { useEffect } from 'react'
import { toast } from 'react-hot-toast'
import getUser from './getUser'

const useRequireAuth = (page?: string) => {
  const navigate = useNavigate()

  useEffect(() => {
    const user = getUser()
    if (!user) {
      toast.error('You must be logged in to view this page')
      navigate(!page ? '/login' : `/login?state=${encodeURIComponent(JSON.stringify({ page }))}`)
    }
  }, [navigate, page])
}

export default useRequireAuth
