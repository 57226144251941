import React from 'react'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import IconButton from '@mui/material/IconButton'
import RefreshIcon from '@mui/icons-material/RefreshRounded'

type PatientSortOptionsProps = {
  direction: 'asc' | 'desc'
  sort: 'label' | 'createdAt' | 'updatedAt'
  onChangeSort: (sort: 'label' | 'createdAt' | 'updatedAt') => void
  onChangeDirection: (direction: 'asc' | 'desc') => void
  onRefresh?: () => void
  disabled?: boolean
}

const PatientSortOptions = ({
  direction,
  sort,
  onChangeDirection,
  onChangeSort,
  onRefresh,
  disabled,
}: PatientSortOptionsProps) => {
  const handler = (sort: 'label' | 'createdAt' | 'updatedAt', dir: 'asc' | 'desc') => () => {
    onChangeSort(sort)
    onChangeDirection(dir)
  }

  const isActive = (s: 'label' | 'createdAt' | 'updatedAt', dir: 'asc' | 'desc') => {
    return direction === dir && sort === s
  }

  return (
    <>
      <Typography variant="body1" fontWeight={500} gutterBottom>
        Sort by
      </Typography>
      <Grid container spacing={2}>
        <Grid item>
          <Button
            onClick={handler('label', 'asc')}
            variant={isActive('label', 'asc') ? 'contained' : 'text'}
            disabled={disabled}
          >
            Alphabetical
          </Button>
        </Grid>
        <Grid item>
          <Button
            onClick={handler('createdAt', 'desc')}
            variant={isActive('createdAt', 'desc') ? 'contained' : 'text'}
            disabled={disabled}
          >
            Newest
          </Button>
        </Grid>
        <Grid item>
          <Button
            onClick={handler('createdAt', 'asc')}
            variant={isActive('createdAt', 'asc') ? 'contained' : 'text'}
            disabled={disabled}
          >
            Oldest
          </Button>
        </Grid>
        <Grid item>
          <IconButton
            onClick={onRefresh}
            color="primary"
            sx={{ height: 44, width: 44 }}
            disabled={disabled}
          >
            <RefreshIcon />
          </IconButton>
        </Grid>
      </Grid>
    </>
  )
}

export default PatientSortOptions
