import { InputField } from '@counsel-project/counsel-generation-api'
import Autocomplete from '@mui/material/Autocomplete'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import { useEffect, useMemo, useState } from 'react'
import CopyButton from './CopyButton'
import Tooltip from '@mui/material/Tooltip'
import InfoIcon from '@mui/icons-material/Info'
import IconButton from '@mui/material/IconButton'

type MultiChoiceTemplateFieldProps = {
  inputField: InputField
  value: string[]
  onChange?: ({ id, value }: { id: string; value: string[] }) => void
}

const MultiChoiceTemplateField = ({
  inputField,
  value,
  onChange,
}: MultiChoiceTemplateFieldProps) => {
  const { id, name, type, instructions, options: inputFieldOptions, condition } = inputField

  const [answer, setAnswer] = useState<string[]>([])
  const [options, setOptions] = useState<string[]>([])

  useEffect(() => {
    if (type === 'multiple_choice' && inputFieldOptions.length > 0) {
      setOptions(inputFieldOptions)
    }
  }, [type, inputFieldOptions])

  useEffect(() => {
    if (value && Array.isArray(value)) {
      setAnswer(value)
    }
  }, [value])

  const conditionText = useMemo(() => {
    if (condition?.contains) return `Only present if ${condition.id} contains ${condition.contains}`
    if (condition?.equals) return `Only present if ${condition.id} equals ${condition.equals}`
    return null
  }, [condition])

  return (
    <>
      <Grid container sx={{ mb: 1 }}>
        <Grid item xs>
          <Typography variant="body1" fontWeight={500}>
            {name}
          </Typography>
        </Grid>
        {condition && (
          <Grid item>
            <Tooltip title={conditionText}>
              <IconButton size="small" color="secondary">
                <InfoIcon />
              </IconButton>
            </Tooltip>
          </Grid>
        )}
        <Grid item>
          <CopyButton text={answer.join('\n')} />
        </Grid>
      </Grid>
      <Autocomplete
        placeholder={instructions?.[0] || name}
        multiple
        options={options}
        value={answer}
        onChange={(_, newValue) => {
          setAnswer(newValue)
          onChange?.({ id, value: newValue })
        }}
        renderInput={(params) => <TextField {...params} />}
      />
    </>
  )
}

export default MultiChoiceTemplateField
