import { ListNoteLogsOptions, RNoteLog } from '@counsel-project/counsel-transcribe-api'
import BackIcon from '@mui/icons-material/ArrowBackRounded'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Divider from '@mui/material/Divider'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import { useCallback, useEffect, useState } from 'react'
import { useEffectOnce, useTitle } from 'react-use'
import SearchField from '../../components/SearchField'
import PageContainer from '../../components/layout/PageContainer'
import { transcribeRequest } from '../../util/api/transcribe-api'
import checkToken from '../../util/auth/checkToken'
import useLayouts from '../../util/auth/useLayouts'
import useRequireAdmin from '../../util/auth/useRequireAdmin'
import useUser from '../../util/auth/useUser'
import NoteDetailsDialog from './NoteDetailsDialog'

const AdminNotes = () => {
  useTitle('Clinical Notes AI - Admin Notes')
  useRequireAdmin('/')

  const [user] = useUser()

  const [notes, setNotes] = useState<Omit<RNoteLog, 'note'>[]>([])
  const [selectedNote, setSelectedNote] = useState<RNoteLog | null>(null)

  const [limit] = useState(10)
  const [offset, setOffset] = useState(0)
  const [emailSearch, setEmailSearch] = useState('')
  const [loading, setLoading] = useState(false)
  const [total, setTotal] = useState(0)

  const [layouts, , populateLayouts] = useLayouts()

  useEffectOnce(() => {
    populateLayouts()
  })

  const populateData = useCallback(async () => {
    try {
      setLoading(true)

      await checkToken()

      let search: ListNoteLogsOptions['search'] = undefined

      if (emailSearch) {
        search = {
          or: [
            { email: emailSearch, $fuzzy: true },
            { label: emailSearch, $fuzzy: true },
          ],
        }
      }

      const notesData = await transcribeRequest.logs.note.list({
        token: '',
        limit,
        offset: offset,
        search,
        sort: 'createdAt',
        direction: 'desc',
      })

      setNotes(notesData.results)
      setTotal(notesData.total)

      // Scroll to top of page
      window.scrollTo(0, 0)
    } catch (err) {
      console.error(err)
    } finally {
      setLoading(false)
    }
  }, [limit, offset, emailSearch])

  useEffect(() => {
    const timeout = setTimeout(() => {
      populateData()
    }, 10)
    return () => clearTimeout(timeout)
  }, [populateData])

  const getTimeSince = (date: string) => {
    const now = new Date()
    const then = new Date(date)
    const diff = now.getTime() - then.getTime()
    const seconds = Math.floor(diff / 1000)
    const minutes = Math.floor(seconds / 60)
    const hours = Math.floor(minutes / 60)
    const days = Math.floor(hours / 24)

    if (days > 0) {
      return `${days} days ago`
    }

    if (hours > 0) {
      return `${hours} hours ago`
    }

    if (minutes > 0) {
      return `${minutes} minutes ago`
    }

    if (seconds > 0) {
      return `${seconds} seconds ago`
    }

    return 'just now'
  }

  const handleSelectNote = useCallback(async (noteId: string) => {
    try {
      await checkToken()

      const data = await transcribeRequest.logs.note.get({
        token: '',
        noteLogId: noteId,
      })

      setSelectedNote(data.result)
    } catch (err) {
      console.error(err)
    }
  }, [])

  return (
    <PageContainer>
      <Grid container justifyContent="center" spacing={2}>
        <Grid item xs={12}>
          <Paper elevation={0} sx={{ p: 2 }}>
            <Grid container spacing={1} sx={{ mb: 1 }}>
              <Grid item>
                <Typography variant="h5" component="h1">
                  Admin Note Logs
                </Typography>
              </Grid>
              <Grid item>{loading && <CircularProgress size={24} />}</Grid>
            </Grid>
            <Grid container justifyContent="end" alignItems="center" spacing={2}>
              <Grid item xs={12}>
                <SearchField
                  placeholder="Search..."
                  value={emailSearch}
                  onChange={setEmailSearch}
                />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          {notes.map((note: Omit<RNoteLog, 'note'>, i) => (
            <Paper
              elevation={0}
              sx={(theme) => ({
                p: 2,
                my: 1,
                cursor: 'pointer',
              })}
              key={note._id}
              onClick={() => handleSelectNote(note._id)}
            >
              <Typography variant="body2" component="p">
                {getTimeSince(note.createdAt)}
              </Typography>
              <Typography variant="body2" component="p">
                {note.email}
              </Typography>
              <Typography variant="body1" component="p">
                {note.feedbackStars && note.feedbackStars !== 0 ? (
                  <span style={{ color: note.feedbackStars > 3 ? 'green' : 'red' }}>
                    {note.feedbackStars} Star{note.feedbackStars !== 1 ? 's' : null}
                  </span>
                ) : note.feedbackPositive ? (
                  <span style={{ color: 'green' }}>Positive Feedback</span>
                ) : note.feedbackPositive === false ? (
                  <span style={{ color: 'red' }}>Negative Feedback</span>
                ) : null}
              </Typography>
              <Typography variant="body2" component="p" color="text.secondary">
                {layouts.find((l) => l.identifier === note?.layout)?.name}
                {' - '}
                {note.label}
              </Typography>
              <Typography variant="body2" component="p" color="text.secondary">
                {note.instructions?.join(', ')}
              </Typography>
            </Paper>
          ))}
        </Grid>
        <Grid item xs={12} container spacing={2}>
          <Grid item xs={6}>
            <Button
              fullWidth
              disabled={offset === 0 || loading}
              onClick={() => {
                setOffset(offset - limit)
              }}
            >
              Previous
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              fullWidth
              disabled={offset + limit >= total || loading}
              onClick={() => {
                setOffset(offset + limit)
              }}
            >
              Next
            </Button>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Typography
            variant="body1"
            component="p"
            sx={{ textAlign: 'center' }}
            color="text.secondary"
          >
            {total} Total
          </Typography>
        </Grid>
      </Grid>
      {!!selectedNote && (
        <NoteDetailsDialog
          open={!!selectedNote}
          onClose={() => setSelectedNote(null)}
          note={selectedNote}
        />
      )}
    </PageContainer>
  )
}

export default AdminNotes
