import SaveIcon from '@mui/icons-material/SaveRounded'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { useCallback, useEffect, useState } from 'react'
import { toast } from 'react-hot-toast'
import { useNavigate, useParams } from 'react-router-dom'
import { useEffectOnce, useTitle } from 'react-use'
import Animation from '../../../components/Animation'
import PageContainer from '../../../components/layout/PageContainer'
import { transcribeRequest } from '../../../util/api/transcribe-api'
import checkToken from '../../../util/auth/checkToken'
import log from '../../../util/logging'
import { isApiError } from '@counsel-project/client-utils'
import handleError from '../../../util/handleError'

const DocumentationGeneratingPage = () => {
  useTitle('Clinical Notes AI - Generating Document...')
  const params = useParams()
  const sessionId = params.sessionId

  const navigate = useNavigate()

  const populateStatus = useCallback(async () => {
    try {
      if (!sessionId) return

      await checkToken()

      const { result } = await transcribeRequest.sessions.get({
        token: '',
        sessionId,
      })

      if (result.state === 'errored') {
        return navigate(`/sessions/${sessionId}`)
      }

      if (result.state === 'generated') {
        return navigate(`/sessions/${sessionId}`)
      }

      if (result.state === 'canceled') {
        return navigate(`/sessions/${sessionId}`)
      }
    } catch (err) {
      handleError(err)
      if (isApiError(err)) {
        if (err.status === 400) {
          navigate('/notes')
        }
      }
    }
  }, [sessionId, navigate])

  useEffectOnce(() => {
    const interval = setInterval(populateStatus, 4000)
    return () => clearInterval(interval)
  })

  const [landingAnimation, setLandingAnimation] = useState<any>(undefined)

  const populateLandingAnimation = useCallback(async () => {
    try {
      const animation = await fetch('/document_gen_animation.json')
      const animationData = await animation.json()
      setLandingAnimation(animationData)
    } catch (error) {
      log.error(error)
    }
  }, [])

  useEffect(() => {
    populateLandingAnimation()
  }, [populateLandingAnimation])

  const handleSaveLater = () => {
    toast.success('Session saved successfully.', {
      id: 'session-saved',
      duration: 4000,
      position: 'bottom-center',
    })
    navigate('/sessions')
  }

  return (
    <PageContainer>
      <Grid container spacing={2} justifyContent="center" alignItems="center" direction="column">
        <Grid item>
          <Typography variant="h6" textAlign="center" sx={{ mb: 2 }}>
            We are working on your document
            <br />
            This may take a minute...
          </Typography>
        </Grid>
        <Grid item>
          <Box sx={{ height: 200 }}>
            <Animation
              id="landing-animation-1"
              animation={landingAnimation}
              sx={{ height: '100%', width: '100%' }}
              renderer="canvas"
              autoplay
              loop
              speed={0.7}
            />
          </Box>
        </Grid>
        <Grid item>
          <Button endIcon={<SaveIcon />} onClick={handleSaveLater} variant="outlined">
            Save For Later
          </Button>
        </Grid>
      </Grid>
    </PageContainer>
  )
}

export default DocumentationGeneratingPage
