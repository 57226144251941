import { RTranscript } from '@counsel-project/counsel-deidentifier-api/dist/common/database/Transcript'
import BackIcon from '@mui/icons-material/ArrowBack'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import IconButton from '@mui/material/IconButton'
import TranscriptContent from './TranscriptContent'

type TranscriptDialogProps = {
  open: boolean
  onClose: () => void
  transcript: RTranscript
}

const TranscriptDialog = ({ open, onClose, transcript }: TranscriptDialogProps) => {
  return (
    <>
      <Dialog open={open} onClose={onClose} fullScreen>
        <DialogTitle>
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              left: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <BackIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <TranscriptContent transcript={transcript} />
        </DialogContent>
      </Dialog>
    </>
  )
}

export default TranscriptDialog
