import Paper from '@mui/material/Paper'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import { useCallback } from 'react'

type LayoutInfoEditorProps = {
  value: string
  onChange: (text: string) => void
  disabled?: boolean
}

const LayoutInfoEditor = ({ value, onChange, disabled }: LayoutInfoEditorProps) => {
  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      onChange(e.target.value)
    },
    [onChange]
  )

  return (
    <Paper elevation={0} sx={{ p: 2 }}>
      <Typography variant="body1" fontWeight={500} fontSize={18} gutterBottom>
        Template Instructions{' '}
        <Typography component="span" variant="body2" color="text.secondary">
          (Optional)
        </Typography>
      </Typography>
      <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
        Include a short description of the function of the document and any special instructions to
        give the AI.
      </Typography>
      <TextField
        fullWidth
        multiline
        rows={4}
        value={value}
        onChange={handleChange}
        disabled={disabled}
        placeholder="Eg. This document is used to record the patient's vitals and symptoms..."
      />
    </Paper>
  )
}

export default LayoutInfoEditor
