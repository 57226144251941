import ClosableDialog from '../../components/ClosableDialog'
import DialogContent from '@mui/material/DialogContent'
import Grid from '@mui/material/Grid'
import SelectableTab from '../../components/SelectableTab'
import Typography from '@mui/material/Typography'
import { useCallback, useEffect, useState } from 'react'
import AdminUserSelector from '../../components/selectors/AdminUserSelector'
import {
  CreateAdminLicenseOptions,
  RDirectory,
  RLicense,
  RUser,
} from '@counsel-project/counsel-auth-api'
import DirectorySelector from '../../components/DirectorySelector'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import DatePeriodSelector from '../../components/DatePeriodSelector'
import Box from '@mui/material/Box'
import checkToken from '../../util/auth/checkToken'
import { authRequest } from '../../util/api/auth-api'
import Divider from '@mui/material/Divider'

export type UpdateLicenseResult = Omit<CreateAdminLicenseOptions, 'token'>

type LicenseDialogProps = {
  license?: RLicense | null
  loading?: boolean
  open: boolean
  onClose: () => void
  onConfirm: (dir: UpdateLicenseResult) => void
  onDelete?: () => void
}

const LicenseDialog = ({
  license,
  loading,
  open,
  onClose,
  onConfirm,
  onDelete,
}: LicenseDialogProps) => {
  const [disabled, setDisabled] = useState(false)
  const [selectedUser, setSelectedUser] = useState<RUser | null>(null)
  const [selectedDirectory, setSelectedDirectory] = useState<RDirectory | null>(null)
  const [mode, setMode] = useState<'user' | 'directory'>('user')
  const [stagedExpiryPeriod, setStagedExpiryPeriod] = useState<Date | null>(new Date())
  const [stagedExpiryValue, setStagedExpiryValue] = useState(new Date().toISOString().split('T')[0])
  const [stagedType, setStagedType] = useState('Dictation')
  const [stagedMaxUsers, setStagedMaxUsers] = useState(1)
  const [stagedMaxSessions, setStagedMaxSessions] = useState(-1)
  const [stagedSessionInterval, setStagedSessionInterval] = useState<number | null>(null)
  const [stagedMaxDictates, setStagedMaxDictates] = useState(-1)
  const [stagedMaxDictateInterval, setStagedMaxDictateInterval] = useState<number | null>(null)
  const [stagedMaxDocuments, setStagedMaxDocuments] = useState(-1)
  const [stagedMaxDocumentInterval, setStagedMaxDocumentInterval] = useState<number | null>(null)
  const [stagedPriority, setStagedPriority] = useState(9)
  const [stagedSubscriptionId, setStagedSubscriptionId] = useState('')
  const [stagedEnabled, setStagedEnabled] = useState(true)
  const [stagedCurrentSessions, setStagedCurrentSessions] = useState(0)
  const [stagedCurrentDictates, setStagedCurrentDictates] = useState(0)
  const [stagedCurrentDocuments, setStagedCurrentDocuments] = useState(0)

  const handlePopulateSelectedDirectory = useCallback(async (directoryId: string) => {
    try {
      setDisabled(true)

      await checkToken()

      const { result } = await authRequest.user.directories.get({
        token: '',
        directoryId,
      })

      setSelectedDirectory(result)
    } catch (e) {
      console.error(e)
    } finally {
      setDisabled(false)
    }
  }, [])

  const handlePopulateSelectedUser = useCallback(async (userId: string) => {
    try {
      setDisabled(true)

      await checkToken()

      const { result } = await authRequest.admin.users.get({
        token: '',
        userId,
      })

      setSelectedUser(result)
    } catch (e) {
      console.error(e)
    } finally {
      setDisabled(false)
    }
  }, [])

  const handleConfirmLicense = useCallback(() => {
    const userId = mode === 'user' ? selectedUser?._id : undefined
    const directoryId = mode === 'directory' ? selectedDirectory?._id : undefined

    if (!userId && !directoryId) return
    if (!stagedExpiryPeriod) return
    if (!stagedMaxUsers) return

    const options: UpdateLicenseResult = userId
      ? {
          type: stagedType,
          expiresAt: stagedExpiryPeriod?.toString(),
          userId,
          directoryId: directoryId,
          maxUsers: stagedMaxUsers,
          maxSessions: stagedMaxSessions !== -1 ? stagedMaxSessions : undefined,
          sessionsIncrement:
            stagedMaxSessions !== -1 ? stagedSessionInterval ?? 1000 * 60 * 60 * 24 : undefined,
          maxDictates: stagedMaxDictates !== -1 ? stagedMaxDictates : undefined,
          dictatesIncrement:
            stagedMaxDictates !== -1 ? stagedMaxDictateInterval ?? 1000 * 60 * 60 * 24 : undefined,
          maxDocuments: stagedMaxDocuments !== -1 ? stagedMaxDocuments : undefined,
          documentsIncrement:
            stagedMaxDocuments !== -1
              ? stagedMaxDocumentInterval ?? 1000 * 60 * 60 * 24
              : undefined,
          priority: stagedPriority,
          subscriptionId: !stagedSubscriptionId ? undefined : stagedSubscriptionId,
          enabled: stagedEnabled,
          currentSessions: stagedCurrentSessions,
          currentDictates: stagedCurrentDictates,
          currentDocuments: stagedCurrentDocuments,
        }
      : {
          type: stagedType,
          expiresAt: stagedExpiryPeriod?.toString(),
          directoryId: directoryId,
          maxUsers: stagedMaxUsers,
          maxSessions: stagedMaxSessions !== -1 ? stagedMaxSessions : undefined,
          sessionsIncrement:
            stagedMaxSessions !== -1 ? stagedSessionInterval ?? 1000 * 60 * 60 * 24 : undefined,
          maxDictates: stagedMaxDictates !== -1 ? stagedMaxDictates : undefined,
          dictatesIncrement:
            stagedMaxDictates !== -1 ? stagedMaxDictateInterval ?? 1000 * 60 * 60 * 24 : undefined,
          maxDocuments: stagedMaxDocuments !== -1 ? stagedMaxDocuments : undefined,
          documentsIncrement:
            stagedMaxDocuments !== -1
              ? stagedMaxDocumentInterval ?? 1000 * 60 * 60 * 24
              : undefined,
          priority: stagedPriority,
          subscriptionId: !stagedSubscriptionId ? undefined : stagedSubscriptionId,
          enabled: stagedEnabled,
          currentSessions: stagedCurrentSessions,
          currentDictates: stagedCurrentDictates,
          currentDocuments: stagedCurrentDocuments,
        }

    onConfirm(options)
  }, [
    mode,
    selectedUser,
    selectedDirectory,
    stagedType,
    stagedExpiryPeriod,
    stagedMaxUsers,
    stagedMaxSessions,
    stagedSessionInterval,
    stagedMaxDictates,
    stagedMaxDictateInterval,
    stagedMaxDocuments,
    stagedMaxDocumentInterval,
    stagedPriority,
    stagedSubscriptionId,
    stagedEnabled,
    onConfirm,
    stagedCurrentSessions,
    stagedCurrentDictates,
    stagedCurrentDocuments,
  ])

  useEffect(() => {
    if (license) {
      setStagedEnabled(license.enabled)
      setStagedType(license.type)
      setStagedExpiryPeriod(new Date(license.expiresAt))
      setStagedExpiryValue(new Date(license.expiresAt).toISOString().split('T')[0])
      setStagedMaxUsers(license.maxUsers)
      setStagedMaxSessions(license.maxSessions || -1)
      setStagedSessionInterval(license.sessionsIncrement || -1)
      setStagedMaxDictates(license.maxDictates || -1)
      setStagedMaxDictateInterval(license.dictatesIncrement || -1)
      setStagedMaxDocuments(license.maxDocuments || -1)
      setStagedMaxDocumentInterval(license.documentsIncrement || -1)
      setStagedPriority(license.priority || 1)
      setStagedSubscriptionId(license.subscriptionId || '')
      if (license.directoryId) {
        setMode('directory')
        handlePopulateSelectedDirectory(license.directoryId)
      } else if (license.userId) {
        setMode('user')
        handlePopulateSelectedUser(license.userId)
      }
      setStagedCurrentSessions(license.currentSessions || 0)
      setStagedCurrentDictates(license.currentDictates || 0)
      setStagedCurrentDocuments(license.currentDocuments || 0)
    } else {
      setStagedEnabled(true)
      setStagedType('Professional')
      setStagedExpiryPeriod(new Date())
      setStagedExpiryValue(new Date().toISOString().split('T')[0])
      setStagedMaxUsers(1)
      setStagedMaxSessions(-1)
      setStagedSessionInterval(null)
      setStagedMaxDictates(-1)
      setStagedMaxDictateInterval(null)
      setStagedMaxDocuments(-1)
      setStagedMaxDocumentInterval(null)
      setStagedPriority(9)
      setStagedSubscriptionId('')
      setSelectedUser(null)
      setSelectedDirectory(null)
      setStagedCurrentSessions(0)
      setStagedCurrentDictates(0)
      setStagedCurrentDocuments(0)
    }
  }, [license, handlePopulateSelectedDirectory, handlePopulateSelectedUser])

  const handleSelectPreset = useCallback((preset: string) => {
    if (preset === 'Dictation') {
      setStagedType('Dictation')
      setStagedMaxUsers(1)
      setStagedMaxSessions(20)
      // 1 month
      setStagedSessionInterval(1000 * 60 * 60 * 24 * 30)
      setStagedMaxDictates(-1)
      setStagedMaxDictateInterval(null)
      setStagedMaxDocuments(2)
      // 1 month
      setStagedMaxDocumentInterval(1000 * 60 * 60 * 24 * 30)
      setStagedPriority(2)
    } else if (preset === 'Plus') {
      setStagedType('Plus')
      setStagedMaxUsers(1)
      setStagedMaxSessions(40)
      // 1 month
      setStagedSessionInterval(1000 * 60 * 60 * 24 * 30)
      setStagedMaxDictates(-1)
      setStagedMaxDictateInterval(null)
      setStagedMaxDocuments(5)
      // 1 month
      setStagedMaxDocumentInterval(1000 * 60 * 60 * 24 * 30)
      setStagedPriority(3)
    } else if (preset === 'Professional') {
      setStagedType('Professional')
      setStagedMaxUsers(1)
      setStagedMaxSessions(-1)
      setStagedSessionInterval(null)
      setStagedMaxDictates(-1)
      setStagedMaxDictateInterval(null)
      setStagedMaxDocuments(-1)
      setStagedMaxDocumentInterval(null)
      setStagedPriority(4)
    }
  }, [])

  return (
    <ClosableDialog open={open} onClose={onClose} titleText="License">
      <DialogContent>
        <Grid container spacing={2} sx={{ mt: 1 }}>
          <Grid item xs={6}>
            <SelectableTab
              active={mode === 'user'}
              onClick={() => {
                setMode('user')
              }}
            >
              User
            </SelectableTab>
          </Grid>
          <Grid item xs={6}>
            <SelectableTab
              active={mode === 'directory'}
              onClick={() => {
                setMode('directory')
              }}
            >
              Organization
            </SelectableTab>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1" sx={{ mt: 1 }} color="text.primary" fontWeight={500}>
              License Information
            </Typography>
            {!license && (
              <Typography variant="body2" sx={{ mt: 1 }} color="text.secondary">
                Select a preset to populate the license with default values
              </Typography>
            )}
            <Divider />
            {!license && (
              <Grid container spacing={2} sx={{ py: 2 }}>
                <Grid item xs={4}>
                  <Button onClick={() => handleSelectPreset('Dictation')} fullWidth>
                    Dictation
                  </Button>
                </Grid>
                <Grid item xs={4}>
                  <Button onClick={() => handleSelectPreset('Plus')} fullWidth>
                    Plus
                  </Button>
                </Grid>
                <Grid item xs={4}>
                  <Button onClick={() => handleSelectPreset('Professional')} fullWidth>
                    Professional
                  </Button>
                </Grid>
              </Grid>
            )}
            {license && (
              <Typography variant="body2" color="text.secondary" sx={{ mt: 1, mx: 2 }}>
                License ID: {license._id}
              </Typography>
            )}
            {license?.billingCycle && (
              <Typography variant="body2" color="text.secondary" sx={{ mt: 1, mx: 2 }}>
                Billing Cycle: {license.billingCycle}
              </Typography>
            )}
            {license?.trialEndsAt && (
              <Typography variant="body2" color="text.secondary" sx={{ mt: 1, mx: 2 }}>
                Trial Ends At: {new Date(license.trialEndsAt).toLocaleString()}
              </Typography>
            )}
            {license?.createdAt && (
              <Typography variant="body2" color="text.secondary" sx={{ mt: 1, mx: 2 }}>
                Created At: {new Date(license.createdAt).toLocaleString()}
              </Typography>
            )}
            {license?.lastPaidAmount && license?.lastPaidAt && (
              <Typography variant="body2" color="text.secondary" sx={{ mt: 1, mx: 2 }}>
                Last Paid Amount: {license.lastPaidAmount / 100}
                <br />
                Last Paid Date: {new Date(license.lastPaidAt || '').toLocaleString()}
              </Typography>
            )}
            {license?.userEmail && (
              <Typography variant="body2" color="text.secondary" sx={{ mt: 1, mx: 2 }}>
                Belongs to: {license.userEmail}
              </Typography>
            )}
          </Grid>
          {mode === 'user' && (
            <Grid item xs={12}>
              <Typography variant="body2" sx={{ mb: 1 }} color="text.secondary" fontWeight={500}>
                Select a User
              </Typography>
              <AdminUserSelector
                value={selectedUser}
                onChange={setSelectedUser}
                fullWidth
                disabled={loading || disabled}
              />
              {selectedUser && (
                <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
                  User ID: {selectedUser._id}
                </Typography>
              )}
            </Grid>
          )}
          {mode === 'directory' && (
            <Grid item xs={12}>
              <Typography variant="body2" sx={{ mb: 1 }} color="text.secondary" fontWeight={500}>
                Select an Organization
              </Typography>
              <DirectorySelector
                value={selectedDirectory}
                onChange={setSelectedDirectory}
                fullWidth
                disabled={loading || disabled}
                admin
              />
              {selectedDirectory && (
                <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
                  Directory ID: {selectedDirectory._id}
                </Typography>
              )}
            </Grid>
          )}
          <Grid item xs={12}>
            <Typography variant="body2" sx={{ mb: 1 }} color="text.secondary" fontWeight={500}>
              License Name
            </Typography>
            <TextField
              fullWidth
              type="text"
              value={stagedType}
              onChange={(e) => {
                setStagedType(e.target.value)
              }}
              disabled={loading}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2" sx={{ mb: 1 }} color="text.secondary" fontWeight={500}>
              Expires at
              {!stagedExpiryPeriod && (
                <Typography sx={{ mb: 1 }} color="error" component="span">
                  {' '}
                  Invalid Date Value
                </Typography>
              )}
            </Typography>
            <TextField
              fullWidth
              type="date"
              value={stagedExpiryValue}
              onChange={(e) => {
                setStagedExpiryValue(e.target.value)
                // Test if the date is a valid value
                if (isNaN(new Date(e.target.value).getTime())) {
                  setStagedExpiryPeriod(null)
                } else {
                  setStagedExpiryPeriod(new Date(e.target.value))
                }
              }}
              disabled={loading}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2" sx={{ mb: 1 }} color="text.secondary" fontWeight={500}>
              Priority ( Free license is 0, higher priorities will take precedence )
            </Typography>
            <TextField
              fullWidth
              type="number"
              value={stagedPriority}
              onChange={(e) => {
                setStagedPriority(parseInt(e.target.value))
              }}
              disabled={loading}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2" sx={{ mb: 1 }} color="text.secondary" fontWeight={500}>
              Subscription ID ( optional - allows stripe subscription to set license expire date )
            </Typography>
            <TextField
              fullWidth
              type="text"
              value={stagedSubscriptionId}
              onChange={(e) => {
                setStagedSubscriptionId(e.target.value)
              }}
              disabled={loading}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1" sx={{ mt: 1 }} color="text.primary" fontWeight={500}>
              License limits
            </Typography>
            <Divider />
          </Grid>
          {mode === 'directory' && (
            <Grid item xs={12}>
              <Typography variant="body2" sx={{ mb: 1 }} color="primary" fontWeight={500}>
                Org User Count
              </Typography>
              <TextField
                fullWidth
                type="number"
                value={stagedMaxUsers}
                onChange={(e) => {
                  setStagedMaxUsers(parseInt(e.target.value))
                }}
                disabled={loading}
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <Typography variant="body2" sx={{ mb: 1 }} color="text.secondary" fontWeight={500}>
              Max Sessions ( -1 for unlimited )
            </Typography>
            <TextField
              fullWidth
              type="number"
              value={stagedMaxSessions}
              onChange={(e) => {
                const value = parseInt(e.target.value)
                if (value <= -1) {
                  setStagedMaxSessions(-1)
                } else {
                  setStagedMaxSessions(parseInt(e.target.value))
                }
              }}
              disabled={loading}
            />
            {stagedMaxSessions !== -1 && (
              <Box sx={{ ml: 2 }}>
                <Typography variant="body2" sx={{ my: 1 }} color="text.secondary" fontWeight={500}>
                  Session Reset Interval
                </Typography>
                <DatePeriodSelector
                  placeholder="Select a reset interval"
                  value={stagedSessionInterval}
                  onChange={setStagedSessionInterval}
                />
              </Box>
            )}
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2" sx={{ mb: 1 }} color="text.secondary" fontWeight={500}>
              Max Dictates ( -1 for unlimited )
            </Typography>
            <TextField
              fullWidth
              type="number"
              value={stagedMaxDictates}
              onChange={(e) => {
                const value = parseInt(e.target.value)
                if (value <= -1) {
                  setStagedMaxDictates(-1)
                } else {
                  setStagedMaxDictates(parseInt(e.target.value))
                }
              }}
              disabled={loading}
            />
            {stagedMaxDictates !== -1 && (
              <Box sx={{ ml: 2 }}>
                <Typography variant="body2" sx={{ my: 1 }} color="text.secondary" fontWeight={500}>
                  Dictate Reset Interval
                </Typography>
                <DatePeriodSelector
                  placeholder="Select a reset interval"
                  value={stagedMaxDictateInterval}
                  onChange={setStagedMaxDictateInterval}
                />
              </Box>
            )}
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2" sx={{ mb: 1 }} color="text.secondary" fontWeight={500}>
              Max Documents ( -1 for unlimited )
            </Typography>
            <TextField
              fullWidth
              type="number"
              value={stagedMaxDocuments}
              onChange={(e) => {
                const value = parseInt(e.target.value)
                if (value <= -1) {
                  setStagedMaxDocuments(-1)
                } else {
                  setStagedMaxDocuments(parseInt(e.target.value))
                }
              }}
              disabled={loading}
            />
            {stagedMaxDocuments !== -1 && (
              <Box sx={{ ml: 2 }}>
                <Typography variant="body2" sx={{ my: 1 }} color="text.secondary" fontWeight={500}>
                  Document Reset Interval
                </Typography>
                <DatePeriodSelector
                  placeholder="Select a reset interval"
                  value={stagedMaxDocumentInterval}
                  onChange={setStagedMaxDocumentInterval}
                />
              </Box>
            )}
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1" sx={{ mt: 1 }} color="text.primary" fontWeight={500}>
              Current usage of the license
            </Typography>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2" sx={{ mb: 1 }} color="text.secondary" fontWeight={500}>
              Current Sessions
            </Typography>
            <TextField
              fullWidth
              type="number"
              value={stagedCurrentSessions}
              onChange={(e) => {
                setStagedCurrentSessions(parseInt(e.target.value))
              }}
              disabled={loading}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2" sx={{ mb: 1 }} color="text.secondary" fontWeight={500}>
              Current Dictates
            </Typography>
            <TextField
              fullWidth
              type="number"
              value={stagedCurrentDictates}
              onChange={(e) => {
                setStagedCurrentDictates(parseInt(e.target.value))
              }}
              disabled={loading}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body2" sx={{ mb: 1 }} color="text.secondary" fontWeight={500}>
              Current Documents
            </Typography>
            <TextField
              fullWidth
              type="number"
              value={stagedCurrentDocuments}
              onChange={(e) => {
                setStagedCurrentDocuments(parseInt(e.target.value))
              }}
              disabled={loading}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="body1" sx={{ mt: 1 }} color="text.primary" fontWeight={500}>
              Whether the license is enabled
            </Typography>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <Button
              onClick={() => {
                setStagedEnabled(!stagedEnabled)
              }}
              disabled={loading}
              color={stagedEnabled ? 'success' : 'error'}
              variant="contained"
            >
              {stagedEnabled ? 'Enabled' : 'Disabled'}
            </Button>
            <Typography variant="body2" sx={{ mt: 1 }} color="text.primary" fontWeight={500}>
              {license?.error}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Button fullWidth onClick={handleConfirmLicense} disabled={loading}>
              {license ? 'Update License' : 'Create License'}
            </Button>
          </Grid>
          {license && (
            <Grid item xs={12}>
              <Button fullWidth onClick={onDelete} disabled={loading} color="error">
                Delete License
              </Button>
            </Grid>
          )}
        </Grid>
      </DialogContent>
    </ClosableDialog>
  )
}

export default LicenseDialog
