import React, { useState, useEffect, useCallback } from 'react'

import ClosableDialog from '../../components/ClosableDialog'
import DialogContent from '@mui/material/DialogContent'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import getIsMobile from '../../util/getIsMobile'
import { DeviceOptions } from '../../components/audio/SelectAudioDeviceDialog'
import handleError from '../../util/handleError'

type ChangeMicrophoneDialogProps = {
  browserAudio?: boolean
  open: boolean
  onClose: () => void
  onSelectDevice: (options?: DeviceOptions) => void
}

const ChangeMicrophoneDialog = ({
  browserAudio,
  open,
  onClose,
  onSelectDevice,
}: ChangeMicrophoneDialogProps) => {
  const [devices, setDevices] = useState<MediaDeviceInfo[]>([])
  const [shareDesktopAudio, setShareDesktopAudio] = useState(browserAudio || false)

  useEffect(() => {
    if (browserAudio === undefined) return
    setShareDesktopAudio(browserAudio)
  }, [browserAudio])

  const populateDevices = useCallback(async () => {
    try {
      const newDevices = (await navigator.mediaDevices.enumerateDevices()).filter(
        (device) => device.kind === 'audioinput'
      )
      setDevices(newDevices)
    } catch (err) {
      handleError(err)
    }
  }, [])

  useEffect(() => {
    if (!open) return
    populateDevices()
  }, [open, populateDevices])

  return (
    <ClosableDialog open={open} onClose={onClose} titleText="Change Microphone">
      <DialogContent>
        <Grid container spacing={2}>
          {devices.map((device) => (
            <Grid item xs={12} key={device.deviceId}>
              <Button
                fullWidth
                key={device.deviceId}
                onClick={() =>
                  onSelectDevice({
                    deviceId: device.deviceId,
                    label: device.label,
                    browserAudio: shareDesktopAudio,
                  })
                }
              >
                {device.label || 'Unknown Device'}
              </Button>
            </Grid>
          ))}
          {browserAudio !== undefined && !getIsMobile() && (
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={shareDesktopAudio}
                    onChange={() => setShareDesktopAudio(!shareDesktopAudio)}
                  />
                }
                label="Prompt to share desktop audio"
              />
            </Grid>
          )}
        </Grid>
      </DialogContent>
    </ClosableDialog>
  )
}

export default ChangeMicrophoneDialog
