import { RLayout } from '@counsel-project/counsel-transcribe-api'
import useUser from '../../util/auth/useUser'
import { useCallback, useEffect, useState } from 'react'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import { LayoutSaveOptions } from './LayoutControls'
import SelectDirectoryDialog from './SelectDirectoryDialog'
import DocumentTypeSelector from './DocumentTypeSelector'
import Checkbox from '@mui/material/Checkbox'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import ExpandMoreIcon from '@mui/icons-material/ExpandMoreRounded'
import Button from '@mui/material/Button'
import log from '../../util/logging'
import toast from 'react-hot-toast'
import handleError from '../../util/handleError'
import checkToken from '../../util/auth/checkToken'
import { transcribeRequest } from '../../util/api/transcribe-api'
import SelectableTab from '../../components/SelectableTab'
import Grid from '@mui/material/Grid'
import BlockEditor from '../../components/markdown/BlockEditor'

type PromptControlsProps = {
  layout: RLayout
  onChangeLayout: (session: RLayout) => void
}

const PromptControls = ({ layout, onChangeLayout }: PromptControlsProps) => {
  const [render, setRender] = useState(false)
  const [open, setOpen] = useState(false)
  const [stagedPrompt, setStagedPrompt] = useState(layout.prompt || '')
  const [isLoading, setIsLoading] = useState(false)
  const [tab, setTab] = useState<'raw' | 'markdown'>('raw')

  useEffect(() => {
    if (!layout.prompt) {
      return
    }
    setStagedPrompt(layout.prompt)
  }, [layout.prompt])

  const handleChangeStagedPrompt = (e: React.ChangeEvent<HTMLInputElement>) => {
    setStagedPrompt(e.target.value)
  }

  const handleSave = useCallback(async () => {
    try {
      setIsLoading(true)

      await checkToken()

      const { result } = await transcribeRequest.layouts.setPrompt({
        token: '',
        layoutId: layout._id,
        prompt: stagedPrompt,
      })

      onChangeLayout(result)

      toast.success('Prompt saved!')
    } catch (err) {
      handleError(err)
    } finally {
      setIsLoading(false)
    }
  }, [layout, stagedPrompt, onChangeLayout])

  const handleToggleOpen = () => {
    setOpen(!open)
    if (!open) {
      setRender(true)
    }
  }

  return (
    <Accordion elevation={0} expanded={open}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} onClick={handleToggleOpen}>
        <Typography>Admin Details</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={2} sx={{ mb: 2 }}>
          <Grid item xs={6}>
            <SelectableTab active={tab === 'raw'} onClick={() => setTab('raw')} small>
              Raw View
            </SelectableTab>
          </Grid>
          <Grid item xs={6}>
            <SelectableTab active={tab === 'markdown'} onClick={() => setTab('markdown')} small>
              Formatted View
            </SelectableTab>
          </Grid>
        </Grid>
        {render && tab === 'raw' && (
          <TextField
            fullWidth
            multiline
            rows={24}
            value={stagedPrompt}
            onChange={handleChangeStagedPrompt}
            sx={{ mb: 2 }}
            disabled={isLoading}
          />
        )}
        {render && tab === 'markdown' && (
          <BlockEditor
            id="prompt-block-editor"
            defaultValue={layout?.prompt || ''}
            onSave={(value) => setStagedPrompt(value)}
            includeTemplateOptions
            onEmail={() => {}}
            onCreateNewNote={() => {}}
            loading={isLoading}
          />
        )}

        <Button onClick={handleSave} disabled={isLoading}>
          Save
        </Button>
        <Button onClick={() => setOpen(false)} disabled={isLoading} sx={{ ml: 2 }}>
          Close
        </Button>
      </AccordionDetails>
    </Accordion>
  )
}

export default PromptControls
