import { RPatientSession } from '@counsel-project/counsel-transcribe-api'
import BackIcon from '@mui/icons-material/ArrowBackIosNewRounded'
import RefreshIcon from '@mui/icons-material/Refresh'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Fade from '@mui/material/Fade'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import { useCallback, useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { useTitle } from 'react-use'
import PageContainer from '../../../components/layout/PageContainer'
import { transcribeRequest } from '../../../util/api/transcribe-api'
import checkToken from '../../../util/auth/checkToken'
import log from '../../../util/logging'

const DocumentationGenerateErrored = () => {
  useTitle('Clinical Notes AI - Generation Errored')
  const navigate = useNavigate()
  const { id } = useParams()
  const [searchParams] = useSearchParams()

  const [loading, setLoading] = useState(false)
  const [session, setSession] = useState<RPatientSession | null>(null)

  const handlePopulateDocument = useCallback(async () => {
    try {
      if (!id) return

      setLoading(true)

      await checkToken()

      const data = await transcribeRequest.sessions.get({
        token: '',
        sessionId: id,
      })

      if (data.result.state === 'errored') {
        setSession(data.result)
      } else {
        navigate(`/documentation/generate-complete?${searchParams}`)
      }
    } catch (err) {
      log.error(err)
      toast.error('Failed to check document state')
    } finally {
      setLoading(false)
    }
  }, [id, navigate, searchParams])

  useEffect(() => {
    handlePopulateDocument()
  }, [handlePopulateDocument])

  const handleRegenerate = () => {
    if (!session) return

    navigate(
      `/documentation/select/${session?.layout}&sessionId=${session?._id}&contextSessionIds=${session?.contextSessionIds?.join(
        ','
      )}&patientLabel=${session?.patientLabel}`
    )
  }

  return (
    <PageContainer>
      <Grid container spacing={2} alignItems="center">
        {session && (
          <Grid item xs={12}>
            <Fade in>
              <Box>
                <Paper sx={{ p: 3, mt: 2 }} elevation={0}>
                  <Typography variant="h5" gutterBottom>
                    It looks like there was an issue generating your document
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    If the error persists, please contact support
                  </Typography>
                </Paper>
                <Paper sx={{ p: 3, mt: 2 }} elevation={0}>
                  <Grid container spacing={2} alignItems="center">
                    <Grid item xs={12}>
                      <Button
                        fullWidth
                        color="secondary"
                        disabled={loading}
                        onClick={handleRegenerate}
                        startIcon={<RefreshIcon />}
                      >
                        Try Again
                      </Button>
                    </Grid>
                    <Grid item xs={12}>
                      <Button
                        fullWidth
                        color="primary"
                        disabled={loading}
                        onClick={() => navigate('/documentation')}
                        startIcon={<BackIcon />}
                      >
                        Back to Documentation
                      </Button>
                    </Grid>
                  </Grid>
                </Paper>
              </Box>
            </Fade>
          </Grid>
        )}
      </Grid>
    </PageContainer>
  )
}

export default DocumentationGenerateErrored
