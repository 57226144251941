import { Subscription } from '@counsel-project/counsel-auth-api'
import { capitalize } from '@counsel-project/client-utils'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import DialogContent from '@mui/material/DialogContent'
import Grid from '@mui/material/Grid'
import Link from '@mui/material/Link'
import Typography from '@mui/material/Typography'
import { useCallback, useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { useNavigate, useParams } from 'react-router-dom'
import { useTitle } from 'react-use'
import { SubscriptionCard } from '.'
import Breadcrumbs from '../../components/Breadcrumbs'
import ClosableDialog from '../../components/ClosableDialog'
import ChangeStripePaymentDialog from '../../components/layout/ChangeStripePaymentDialog'
import PageContainer from '../../components/layout/PageContainer'
import { authRequest } from '../../util/api/auth-api'
import checkToken from '../../util/auth/checkToken'
import log from '../../util/logging'
import { logCancelFreeTrial, logCancelSubscription } from '../../util/tracking'
import CancelReasonDialog from './CancelReasonDialog'

type PageBreadcrumbsProps = {
  id: string
}

const PageBreadcrumbs = ({ id }: PageBreadcrumbsProps) => {
  return (
    <Breadcrumbs
      paths={[
        {
          name: 'Profile',
          path: '/profile',
        },
        {
          name: 'Subscriptions',
          path: '/subscriptions',
        },
        {
          name: 'Manage',
          path: `/subscriptions/${id}`,
        },
      ]}
    />
  )
}

const SubscriptionPage = () => {
  useTitle('Clinical Notes AI - Subscription')
  const navigate = useNavigate()

  const { id } = useParams()

  const [subscription, setSubscription] = useState<Subscription | null>(null)
  const [cancelDialog, setCancelDialog] = useState(false)
  const [loading, setLoading] = useState(false)
  const [additionalHelpDialog, setAdditionalHelpDialog] = useState(false)
  const [changePaymentDialog, setChangePaymentDialog] = useState(false)

  const handleOpenCancelDialog = () => setCancelDialog(true)
  const handleOpenChangePaymentDialog = () => setChangePaymentDialog(true)

  const handleOpenAdditionalHelpDialog = () => setAdditionalHelpDialog(true)

  const renewsOn = subscription ? new Date(subscription.current_period_end * 1000) : undefined
  const cancelAtPeriodEnd = subscription?.cancel_at_period_end
  const refunded = subscription?.metadata?.refunded === 'true'
  const status = subscription?.status

  const populateSubscription = useCallback(async () => {
    try {
      if (!id) return

      await checkToken()

      const res = await authRequest.user.subscriptions.get({
        token: '',
        subscriptionId: id,
      })

      setSubscription(res.subscription)
    } catch (err) {
      log.error(err)
      toast.error('Failed to load subscription')
      navigate('/subscriptions')
    }
  }, [id, navigate])

  useEffect(() => {
    populateSubscription()
  }, [populateSubscription])

  const handleConfirmCancel = useCallback(
    async (reason: string, comment: string) => {
      try {
        if (!id) return

        setLoading(true)

        await checkToken()

        if (status === 'trialing') {
          logCancelFreeTrial({
            productName: subscription?.metadata?.type as string,
            productPrice: 0,
            amountTotal: 0,
            quantity: 1,
          })
        } else {
          logCancelSubscription({
            productName: subscription?.metadata?.type as string,
            productPrice: 0,
            amountTotal: 0,
            quantity: 1,
          })
        }

        await authRequest.user.subscriptions.cancel({
          token: '',
          subscriptionId: id,
          cancelReason: reason,
          cancelReasonComment: comment,
        })

        toast.success('Successfully cancelled subscription')

        setCancelDialog(false)
        populateSubscription()
      } catch (err) {
        log.error(err)
        toast.error('Failed to cancel subscription')
      } finally {
        setLoading(false)
      }
    },
    [id, populateSubscription, status, subscription?.metadata?.type]
  )

  if (!subscription) {
    return (
      <PageContainer>
        <PageBreadcrumbs id={id || ''} />
      </PageContainer>
    )
  }

  return (
    <>
      <PageContainer>
        <PageBreadcrumbs id={id || ''} />
        <Box sx={{ height: 40 }} />
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h4">Subscription</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6">
              {capitalize(subscription.metadata.type as string)} Subscription Details
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <SubscriptionCard subscription={subscription} />
          </Grid>
          <Grid item xs={12}>
            <Grid container justifyContent="end" spacing={2}>
              {!cancelAtPeriodEnd && !refunded && status !== 'canceled' && (
                <>
                  <Grid item>
                    <Button
                      variant="text"
                      color="primary"
                      onClick={handleOpenChangePaymentDialog}
                      disabled={loading}
                    >
                      Change Payment Method
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      variant="text"
                      color="secondary"
                      onClick={handleOpenCancelDialog}
                      disabled={loading}
                    >
                      Cancel Membership
                    </Button>
                  </Grid>
                </>
              )}
              {cancelAtPeriodEnd && (
                <>
                  <Grid item>
                    <Typography color="text.secondary">This subscription is cancelled</Typography>
                  </Grid>
                  <Grid item>
                    <Button
                      variant="text"
                      color="primary"
                      onClick={handleOpenAdditionalHelpDialog}
                      disabled={loading}
                    >
                      Need additional help?
                    </Button>
                  </Grid>
                </>
              )}
            </Grid>
          </Grid>
        </Grid>
      </PageContainer>
      <CancelReasonDialog
        open={cancelDialog}
        onClose={() => setCancelDialog(false)}
        onSubmit={handleConfirmCancel}
      />
      <ClosableDialog
        open={additionalHelpDialog}
        onClose={() => setAdditionalHelpDialog(false)}
        titleText="Additional Help"
      >
        <DialogContent>
          {cancelAtPeriodEnd && (
            <Typography variant="body1" sx={{ mb: 2 }}>
              Your membership has been cancelled.
            </Typography>
          )}
          <Typography variant="body1">
            If you need more help, please contact us{' '}
            <Link href={'https://share.hsforms.com/1iyg6_jCETOmsTFny8Y299wqkiqd'} target="_blank">
              here
            </Link>
          </Typography>
        </DialogContent>
      </ClosableDialog>
      <ChangeStripePaymentDialog
        open={changePaymentDialog}
        onClose={() => setChangePaymentDialog(false)}
        subscriptionId={subscription.id}
        onChangeSubscription={setSubscription}
      />
    </>
  )
}

export default SubscriptionPage
