import Box, { BoxProps } from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { styled } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import React from 'react'

type BlockyOptionProps = {
  titleColor?:
    | 'primary'
    | 'secondary'
    | 'error'
    | 'warning'
    | 'info'
    | 'success'
    | 'text.secondary'
    | 'text.primary'
  subtitleColor?:
    | 'primary'
    | 'secondary'
    | 'error'
    | 'warning'
    | 'info'
    | 'success'
    | 'text.secondary'
    | 'text.primary'
  title: string
  subtitle: string
  disabled?: boolean
  src: string
  onClick: () => void
}

export type BlockOptionProps = {
  disabled?: boolean
}

const BlockOption = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'disabled',
})<BlockOptionProps>(({ theme, disabled }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: theme.spacing(2),
  backgroundColor: theme.palette.background.default,
  borderRadius: theme.spacing(1),
  cursor: 'pointer',
  transition: 'background-color 0.3s',
  '&:hover': {
    backgroundColor: theme.palette.action.selected,
  },
  height: '100%',
  pointerEvents: disabled ? 'none' : 'all',
  opacity: disabled ? 0.5 : 1,
}))

const BlockyOption = ({
  disabled,
  title,
  subtitle,
  src,
  titleColor = 'text.primary',
  subtitleColor = 'text.secondary',
  onClick,
  ...props
}: BlockyOptionProps & BoxProps) => {
  const xs = useMediaQuery('(max-width: 600px)')

  return (
    <BlockOption onClick={onClick} disabled={disabled} {...props}>
      <Box sx={{ mb: 2, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <img src={src} alt="icon" width={xs ? 38 : 50} />
      </Box>
      <Typography variant="body1" fontWeight={500} color={titleColor} textAlign="center">
        {title}
      </Typography>
      <Typography variant="body2" fontWeight={500} color={subtitleColor} textAlign="center">
        {subtitle}
      </Typography>
    </BlockOption>
  )
}

export default BlockyOption
