import IconButton, { IconButtonProps } from '@mui/material/IconButton'
import CopyIcon from '@mui/icons-material/ContentCopy'
import { toast } from 'react-hot-toast'
import { removeMarkdown } from '../../../util/copy'

export type CopyButtonProps = {
  text: string
} & IconButtonProps

const CopyButton = ({ text, ...props }: CopyButtonProps) => {
  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    e.stopPropagation()
    navigator.clipboard.writeText(removeMarkdown(text))
    toast.success('Copied to clipboard', { id: 'copy-button' })
  }

  return (
    <IconButton onClick={handleClick} size="small" color="primary" {...props}>
      <CopyIcon />
    </IconButton>
  )
}

export default CopyButton
