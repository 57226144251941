import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import BlockEditor from '../../components/markdown/BlockEditor'

type LayoutEditorProps = {
  id: string
  defaultValue: string
  readOnly?: boolean
  onChange: (text: string) => void
  onSaving: (saving: boolean) => void
}

const LayoutEditor = ({ id, defaultValue, readOnly, onChange, onSaving }: LayoutEditorProps) => {
  return (
    <Paper
      elevation={0}
      sx={{
        p: 0,
        borderRadius: 0,
        background: 'transparent',
      }}
    >
      <Box
        sx={{
          backgroundColor: 'background.paper',
          borderRadius: 1,
          border: '1px solid',
          borderColor: 'divider',
        }}
      >
        <BlockEditor
          includeTemplateOptions
          readOnly={readOnly}
          id={id}
          defaultValue={defaultValue}
          onSave={onChange}
          onSaving={onSaving}
          onEmail={() => {}}
          onCreateNewNote={() => {}}
        />
      </Box>
    </Paper>
  )
}

export default LayoutEditor
