import { TableInputField } from '@counsel-project/counsel-generation-api'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import { useEffect, useMemo, useState } from 'react'
import CopyButton from './CopyButton'
import Tooltip from '@mui/material/Tooltip'
import InfoIcon from '@mui/icons-material/Info'
import IconButton from '@mui/material/IconButton'

type TableTemplateFieldProps = {
  inputField: TableInputField
  value: string[]
  onChange?: ({ id, value }: { id: string; value: string[] }) => void
}

const TableTemplateField = ({ inputField, value, onChange }: TableTemplateFieldProps) => {
  const { id, name, type, headers, rows, condition } = inputField

  const [answer, setAnswer] = useState<string[]>([])

  useEffect(() => {
    if (value) {
      setAnswer(value.map((v) => (v === '[Fill value here]' ? '' : v)))
    }
  }, [value, type])

  const conditionText = useMemo(() => {
    if (condition?.contains) return `Only present if ${condition.id} contains ${condition.contains}`
    if (condition?.equals) return `Only present if ${condition.id} equals ${condition.equals}`
    return null
  }, [condition])

  return (
    <>
      <Grid container sx={{ mb: 1 }}>
        <Grid item xs>
          <Typography variant="body1" fontWeight={500}>
            {name}
          </Typography>
        </Grid>
        {condition && (
          <Grid item>
            <Tooltip title={conditionText}>
              <IconButton size="small" color="secondary">
                <InfoIcon />
              </IconButton>
            </Tooltip>
          </Grid>
        )}
        <Grid item>
          <CopyButton text={answer.join('\n')} />
        </Grid>
      </Grid>
      <Grid container>
        {headers?.map((header, headerIndex) => (
          <Grid item xs key={`${id}-${headerIndex}`}>
            <Typography variant="body1" fontWeight={500} sx={{ mb: 1 }}>
              {header}
            </Typography>
          </Grid>
        ))}
        <Grid item container spacing={1}>
          {rows?.map((row, rowIndex) => (
            <Grid item xs={12} container spacing={1} key={`${id}-${rowIndex}`}>
              {row.map((cell, cellIndex) => (
                <Grid item xs key={`${id}-${rowIndex}-${cellIndex}`}>
                  <TextField
                    value={answer?.[headers.length * rowIndex + cellIndex]}
                    fullWidth
                    type="text"
                    autoComplete="off"
                    onChange={(e) => {
                      const newAnswer = [...answer]
                      newAnswer[headers.length * rowIndex + cellIndex] = e.target.value
                      setAnswer(newAnswer)
                      onChange?.({ id, value: newAnswer })
                    }}
                  />
                </Grid>
              ))}
            </Grid>
          ))}
        </Grid>
      </Grid>
    </>
  )
}

export default TableTemplateField
