import { authRequest } from '../util/api/auth-api'
import checkToken from '../util/auth/checkToken'
import { RDirectory } from '@counsel-project/counsel-auth-api'
import Autocomplete, { AutocompleteProps } from '@mui/material/Autocomplete'
import CircularProgress from '@mui/material/CircularProgress'
import TextField from '@mui/material/TextField'
import { ApiError } from '@counsel-project/client-utils'
import React, { useCallback, useEffect, useState } from 'react'
import { toast } from 'react-hot-toast'
import handleError from '../util/handleError'

export type DirectorySelectorProps = {
  value: RDirectory | null
  onChange: (value: RDirectory | null) => void
  admin?: boolean
} & Omit<
  AutocompleteProps<RDirectory, false, false, false>,
  'renderInput' | 'renderOption' | 'onChange' | 'value' | 'options'
>

const DirectorySelector = (
  { value, onChange, admin, ...props }: DirectorySelectorProps,
  ref: React.Ref<HTMLDivElement>
) => {
  const [shownOptions, setShownOptions] = useState<RDirectory[]>([])
  const [inputValue, setInputValue] = useState('')
  const [loading, setLoading] = useState(false)

  const populateOptions = useCallback(
    async (search?: string) => {
      try {
        await checkToken()

        setLoading(true)

        if (admin) {
          const data = await authRequest.admin.directories.list({
            token: '',
            search: search
              ? {
                  and: [{ name: search, $fuzzy: true }],
                }
              : undefined,
          })

          setShownOptions(data.results)
        } else {
          const data = await authRequest.user.directories.list({
            token: '',
            search: search
              ? {
                  and: [{ name: search, $fuzzy: true }],
                }
              : undefined,
          })

          setShownOptions(data.results)
        }
      } catch (err) {
        handleError(err)
      } finally {
        setLoading(false)
      }
    },
    [admin]
  )

  useEffect(() => {
    if (value) return

    const timeout = setTimeout(() => {
      populateOptions(inputValue)
    }, 300)

    return () => {
      clearTimeout(timeout)
    }
  }, [inputValue, populateOptions, value])

  return (
    <>
      <Autocomplete
        {...props}
        ref={ref}
        value={value}
        onChange={(e, value) => onChange(value)}
        inputValue={inputValue}
        onInputChange={(e, value) => {
          setInputValue(value)
        }}
        options={shownOptions}
        loading={loading}
        getOptionLabel={(option) => `${option.name} (${option.ownerEmail})`}
        noOptionsText="No organizations found"
        id="directory-selector"
        isOptionEqualToValue={(option, value) => option._id === value._id}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder="Search..."
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
      />
    </>
  )
}

export default React.forwardRef(DirectorySelector)
