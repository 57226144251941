import { RPatient } from '@counsel-project/counsel-transcribe-api'
import { AutocompleteProps } from '@mui/material/Autocomplete'
import Box from '@mui/material/Box'
import Chip from '@mui/material/Chip'
import Fade from '@mui/material/Fade'
import Typography from '@mui/material/Typography'
import React, { useState } from 'react'
import PatientSelectorBase, { ehrs } from './PatientSelectorBase'

export type PatientSelectorProps = {
  id?: string
  placeholder?: string
  hiddenOptions?: string[]
  value: RPatient | string | null
  onChange: (value: RPatient | null) => void
} & Omit<
  AutocompleteProps<RPatient, false, false, true>,
  'renderInput' | 'renderOption' | 'onChange' | 'value' | 'options'
>

const PatientSelector = (
  {
    id,
    placeholder = 'Search or Create New...',
    hiddenOptions,
    value,
    onChange,
    ...props
  }: PatientSelectorProps,
  ref: React.Ref<HTMLDivElement>
) => {
  const [inputValue, setInputValue] = useState('')
  const [shownOptions, setShownOptions] = useState<RPatient[]>([])

  return (
    <>
      <PatientSelectorBase
        id={id}
        onChangeInputValue={setInputValue}
        onChangeShownOptions={setShownOptions}
        onChange={onChange}
        value={value}
        hiddenOptions={hiddenOptions}
        placeholder={placeholder}
        {...props}
        ref={ref}
      />
      {typeof value !== 'string' && !value?._id && value && !ehrs.includes(value.userId) && (
        <Fade in>
          <Box>
            <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }} fontStyle="italic">
              Creating a new patient "{inputValue}".{' '}
              {shownOptions.length !== 0 && 'Did you mean...'}
            </Typography>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'flex-start',
                mt: 1,
                overflowX: 'auto',
              }}
            >
              {shownOptions
                .filter(
                  (option) =>
                    !hiddenOptions?.includes(option.label) && !ehrs.includes(option.userId)
                )
                .map((option) => (
                  <Chip
                    key={option._id}
                    label={option.label}
                    onClick={() => {
                      onChange(option)
                    }}
                    sx={{ mr: 1, mb: 1, cursor: 'pointer', fontWeight: 500, fontSize: '0.875rem' }}
                    size="medium"
                  />
                ))}
            </Box>
          </Box>
        </Fade>
      )}
    </>
  )
}

export default React.forwardRef(PatientSelector)
