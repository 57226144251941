import { RUser } from '@counsel-project/counsel-auth-api'
import Typography from '@mui/material/Typography'
import StyledTableCell from './StyledTableCell'
import StyledTableRow from './StyledTableRow'

export type UserRowProps = {
  isMobile?: boolean
  data: RUser
  onClick?: (data: RUser) => void
}

const UserRow = ({ isMobile, data, onClick }: UserRowProps) => {
  const { email, _id, lastLoggedIn } = data

  return (
    <StyledTableRow onClick={() => onClick?.(data)}>
      <StyledTableCell
        sx={{
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          maxWidth: 400,
        }}
      >
        <Typography
          variant="subtitle2"
          textOverflow="ellipsis"
          overflow="hidden"
          whiteSpace="nowrap"
        >
          {email}
        </Typography>
      </StyledTableCell>
      {!isMobile && (
        <StyledTableCell
          align="right"
          sx={{
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            maxWidth: 400,
          }}
        >
          <Typography
            variant="subtitle2"
            textOverflow="ellipsis"
            overflow="hidden"
            whiteSpace="nowrap"
          >
            {lastLoggedIn ? new Date(lastLoggedIn).toLocaleDateString() : 'Never'}
          </Typography>
        </StyledTableCell>
      )}
      {!isMobile && (
        <StyledTableCell
          align="right"
          sx={{
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            maxWidth: 400,
          }}
        >
          <Typography
            variant="subtitle2"
            textOverflow="ellipsis"
            overflow="hidden"
            whiteSpace="nowrap"
          >
            {_id}
          </Typography>
        </StyledTableCell>
      )}
    </StyledTableRow>
  )
}

export default UserRow
