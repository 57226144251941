import * as msal from '@azure/msal-browser'
import CircularProgress from '@mui/material/CircularProgress'
import Grid from '@mui/material/Grid'
import { useCallback, useEffect } from 'react'
import toast from 'react-hot-toast'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { authRequest } from '../util/api/auth-api'
import useUser from '../util/auth/useUser'
import log from '../util/logging'
import { attemptJSONParse } from './login'
import { loginWithMicrosoft } from '../util/auth/login'

const MSLoginPage = () => {
  const [searchParams] = useSearchParams()
  const state = searchParams.get('state')
  let page = attemptJSONParse(decodeURIComponent(state || ''))?.page
  if (page === '/login' || page === '/register') {
    page = undefined
  }
  const [user] = useUser()
  const navigate = useNavigate()

  const populateMicrosoftLogin = useCallback(async () => {
    try {
      const { clientId } = await authRequest.user.microsoft.getClientId()

      const msalInstance = new msal.PublicClientApplication({
        auth: {
          clientId,
          redirectUri: `${window.location.origin}/mslogin`,
          authority: 'https://login.microsoftonline.com/common',
        },
        cache: {
          cacheLocation: 'localStorage', // This configures where your cache will be stored
          storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
        },
      })

      await msalInstance.initialize()

      const accounts = msalInstance.getAllAccounts()
      if (accounts.length === 0) {
        try {
          await msalInstance.acquireTokenRedirect({
            scopes: ['user.read'],
          })
        } catch (err) {
          await msalInstance.handleRedirectPromise()
          window.location.reload()
        }
      } else {
        const account = accounts?.[0]

        let accessToken
        try {
          const res = await msalInstance.acquireTokenSilent({
            scopes: ['user.read'],
            account,
          })
          accessToken = res.accessToken
        } catch (err) {
          if (err instanceof msal.InteractionRequiredAuthError) {
            await msalInstance.acquireTokenRedirect({
              scopes: ['user.read'],
            })
            return
          }

          const tokenResponse = await msalInstance.handleRedirectPromise()

          if (!tokenResponse) {
            return
          }

          accessToken = tokenResponse.accessToken
        }

        await loginWithMicrosoft({
          accessToken,
        })

        // log out of microsoft
        localStorage.clear()
      }
    } catch (err) {
      log.error(err)
      toast.error('An error occurred while logging in with Microsoft. Please try again later.')
    }
  }, [])

  useEffect(() => {
    const timeout = setTimeout(() => {
      populateMicrosoftLogin()
    }, 100)
    return () => clearTimeout(timeout)
  }, [populateMicrosoftLogin])

  const handleAfterLogin = useCallback(() => {
    if (!user) return
    navigate(page || '/onboarding')
  }, [user, navigate, page])

  useEffect(() => {
    handleAfterLogin()
  }, [handleAfterLogin])

  return (
    <Grid container justifyContent="center" alignItems="center" style={{ height: '100vh' }}>
      <CircularProgress />
    </Grid>
  )
}

export default MSLoginPage
