import { RLayout } from '@counsel-project/counsel-transcribe-api'
import Button from '@mui/material/Button'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import { useCallback, useEffect, useMemo, useState } from 'react'
import toast from 'react-hot-toast'
import ClosableDialog from '../../components/ClosableDialog'
import { transcribeRequest } from '../../util/api/transcribe-api'
import checkToken from '../../util/auth/checkToken'
import handleError from '../../util/handleError'

type ChangeOwnerDialogProps = {
  open: boolean
  onClose: () => void
  layout: RLayout
  onChangeLayout: (layout: RLayout) => void
}

const ChangeOwnerDialog = ({ open, onClose, layout, onChangeLayout }: ChangeOwnerDialogProps) => {
  const [staged, setStaged] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const isSavable = useMemo(() => {
    return staged !== layout.email && !!staged
  }, [staged, layout])

  useEffect(() => {
    if (!open) return
    setStaged('')
  }, [open])

  const handleSave = useCallback(async () => {
    try {
      setIsLoading(true)

      await checkToken()

      const { result } = await transcribeRequest.layouts.changeOwner({
        token: '',
        layoutId: layout._id,
        email: staged,
      })

      toast.success('Owner updated')

      onChangeLayout(result)
      onClose()
    } catch (err) {
      handleError(err)
    } finally {
      setIsLoading(false)
    }
  }, [onChangeLayout, onClose, layout, staged])

  return (
    <ClosableDialog open={open} onClose={onClose} titleText="Transfer Ownership">
      <DialogContent>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <TextField
              placeholder="Type an email..."
              value={staged}
              onChange={(e) => setStaged(e.target.value)}
              fullWidth
            />
          </Grid>
          <Grid item>
            <Typography variant="body1" color="text.secondary">
              You will not longer have access to this template
            </Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleSave}
          disabled={!isSavable || isLoading}
          color="error"
          variant="contained"
        >
          Transfer Ownership
        </Button>
      </DialogActions>
    </ClosableDialog>
  )
}

export default ChangeOwnerDialog
