import {
  PatientSessionSearchableFields,
  RPatientSession,
} from '@counsel-project/counsel-transcribe-api'
import { LayoutType } from '@counsel-project/counsel-transcribe-api/dist/common/database/RLayout'
import { SearchQuery } from '@counsel-project/counsel-transcribe-api/dist/common/query'
import RefreshIcon from '@mui/icons-material/RefreshRounded'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import Skeleton from '@mui/material/Skeleton'
import Typography from '@mui/material/Typography'
import { useTheme } from '@mui/material/styles'
import React, { useCallback, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import SimplePagination from '../../components/forms/SimplePagination'
import {
  listAllSessionsCached,
  listMySessionsCached,
  listUserSessionsCached,
  refreshPatientsCache,
  refreshSessionsCache,
} from '../../util/api/transcribe-api-cached'
import checkToken from '../../util/auth/checkToken'
import handleError from '../../util/handleError'
import { createSearchParams } from '../builder/common'
import SessionItem from './SessionItem'

type ViewUnassignedSessionsProps = {
  search?: string
  userId?: string | null
  showAll?: boolean
}

const ViewUnassignedSessions = ({ search, userId, showAll }: ViewUnassignedSessionsProps) => {
  const navigate = useNavigate()
  const location = useLocation()

  const theme = useTheme()

  const limit = 9
  const [sessions, setSessions] = useState<Omit<RPatientSession, 'transcript' | 'note'>[]>([])
  const [total, setTotal] = useState(0)
  const [offset, setOffset] = useState(0)
  const [loading, setLoading] = useState(true)
  const [shownSessionType, setShownSessionType] = useState<LayoutType | null>(null)
  const [direction, setDirection] = useState<'asc' | 'desc'>('desc')

  const populateInitial = useCallback(async () => {
    try {
      setLoading(true)

      await checkToken()

      const searchQuery: SearchQuery<PatientSessionSearchableFields> = {
        and: [{ patientLabel: null }, ...(shownSessionType ? [{ type: shownSessionType }] : [])],
        or: [
          ...(search
            ? [
                { summary: search, $fuzzy: true },
                { documentType: search, $fuzzy: true },
              ]
            : [
                {
                  type: 'note',
                },
                {
                  type: 'document',
                },
                {
                  type: 'custom',
                },
              ]),
        ],
      }

      let results: RPatientSession[] = []
      let total = 0

      const searchByUser = userId && !showAll

      if (searchByUser) {
        const data = await listUserSessionsCached({
          userId,
          token: '',
          limit,
          offset,
          sort: 'createdAt',
          direction,
          search: searchQuery,
        })

        results = data.results
        total = data.total
      } else if (showAll) {
        const data = await listAllSessionsCached({
          token: '',
          limit,
          offset,
          sort: 'createdAt',
          direction,
          search: searchQuery,
        })

        results = data.results
        total = data.total
      } else {
        const data = await listMySessionsCached({
          token: '',
          limit,
          offset,
          sort: 'createdAt',
          direction,
          search: searchQuery,
        })

        results = data.results
        total = data.total
      }

      setSessions(results)
      setTotal(total)
    } catch (err) {
      handleError(err)
    } finally {
      setLoading(false)
    }
  }, [offset, search, shownSessionType, direction, userId, showAll])

  useEffect(() => {
    const timeout = setTimeout(() => {
      populateInitial()
    }, 10)
    return () => clearTimeout(timeout)
  }, [populateInitial])

  const createSessionClickHandler = (session: RPatientSession) => (e: React.MouseEvent) => {
    e.preventDefault()

    const queryText = createSearchParams({
      ref: location.pathname + location.search,
      userId: userId || undefined,
      showAll,
    })

    navigate(`/sessions/${session._id}${queryText}`)
  }

  const shownSessionHandler = (type: LayoutType | null) => () => {
    setShownSessionType((prevType) => (prevType === type ? null : type))
    setOffset(0)
  }

  const directionHandler = (dir: 'asc' | 'desc') => () => {
    setDirection(dir)
    setOffset(0)
  }

  useEffect(() => {
    setOffset(0)
  }, [search, userId, showAll])

  const handleRefresh = useCallback(() => {
    refreshPatientsCache()
    refreshSessionsCache()
    populateInitial()
  }, [populateInitial])

  return (
    <>
      <Grid container sx={{ mb: 2 }} spacing={2}>
        <Grid item>
          <Button
            onClick={shownSessionHandler(null)}
            variant={shownSessionType === null ? 'contained' : 'text'}
            disabled={loading}
          >
            Show All
          </Button>
        </Grid>
        <Grid item>
          <Button
            onClick={shownSessionHandler('note')}
            variant={shownSessionType === 'note' ? 'contained' : 'text'}
            disabled={loading}
          >
            Show Notes
          </Button>
        </Grid>
        <Grid item>
          <Button
            onClick={shownSessionHandler('document')}
            variant={shownSessionType === 'document' ? 'contained' : 'text'}
            disabled={loading}
          >
            Show Documents
          </Button>
        </Grid>
        <Grid item>
          <IconButton
            onClick={handleRefresh}
            color="primary"
            sx={{ height: 44, width: 44 }}
            disabled={loading}
          >
            <RefreshIcon />
          </IconButton>
        </Grid>
      </Grid>
      <Typography variant="body1" fontWeight={500} gutterBottom>
        Sort by
      </Typography>
      <Grid container sx={{ mb: 2 }} spacing={2}>
        <Grid item>
          <Button
            onClick={directionHandler('desc')}
            variant={direction === 'desc' ? 'contained' : 'text'}
            disabled={loading}
          >
            Newest
          </Button>
        </Grid>
        <Grid item>
          <Button
            onClick={directionHandler('asc')}
            variant={direction === 'asc' ? 'contained' : 'text'}
            disabled={loading}
          >
            Oldest
          </Button>
        </Grid>
      </Grid>
      <Grid
        container
        spacing={2}
        sx={{
          mt: 2,
        }}
        alignItems="stretch"
      >
        {sessions.map((session) => (
          <Grid item key={session._id} xs={12} sm={6} md={4}>
            <SessionItem
              key={session._id}
              session={session}
              onClick={createSessionClickHandler(session)}
            />
          </Grid>
        ))}
        {sessions.length === 0 && loading && (
          <>
            {Array.from({ length: limit }).map((_, i) => (
              <Grid item xs={12} sm={6} md={4} key={i}>
                <Skeleton
                  variant="rectangular"
                  height={128}
                  sx={{ backgroundColor: theme.palette.background.paper }}
                />
              </Grid>
            ))}
          </>
        )}
        {sessions.length === 0 && !loading && (
          <Grid item xs={12} sm={6} md={4}>
            <Typography variant="body1" color="text.secondary">
              No files found
            </Typography>
          </Grid>
        )}
      </Grid>
      <SimplePagination
        loading={loading}
        limit={limit}
        offset={offset}
        total={total}
        onClickNext={() => setOffset((prev) => prev + limit)}
        onClickPrevious={() => setOffset((prev) => prev - limit)}
      />
    </>
  )
}

export default ViewUnassignedSessions
