import { UserPreview } from '@counsel-project/counsel-auth-api/dist/common/database/User'
import Autocomplete from '@mui/material/Autocomplete'
import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { authRequest } from '../../util/api/auth-api'
import checkToken from '../../util/auth/checkToken'
import useUser from '../../util/auth/useUser'
import handleError from '../../util/handleError'

type SelectSuperviseeSectionProps = {
  value: string | null
  onChange: (value: string | null) => void
  disabled?: boolean
}

const SelectUserSection = ({ value, onChange, disabled }: SelectSuperviseeSectionProps) => {
  const [user] = useUser()

  const [inputValue, setInputValue] = useState('')
  const [options, setOptions] = useState<UserPreview[]>([])
  const [showOptions, setShowOptions] = useState<UserPreview[]>([])

  const selectedValue = useMemo(() => {
    return options.find((option) => option._id === value) || null
  }, [options, value])

  const populateUsers = useCallback(async () => {
    try {
      if (!user) return

      await checkToken()

      const { results } = await authRequest.user.account.getViewableUsers({
        token: '',
      })

      setOptions([{ _id: 'show-all', email: 'Show All' }, ...results])
    } catch (err) {
      handleError(err)
    }
  }, [user])

  useEffect(() => {
    const timeout = setTimeout(() => {
      populateUsers()
    }, 10)
    return () => clearTimeout(timeout)
  }, [populateUsers])

  useEffect(() => {
    if (!inputValue) {
      setShowOptions(options)
      return
    }

    const filteredOptions = options.filter((option) => {
      return (
        option.email.toLowerCase().includes(inputValue.toLowerCase()) ||
        (option.firstName && option.firstName.toLowerCase().includes(inputValue.toLowerCase())) ||
        (option.lastName && option.lastName.toLowerCase().includes(inputValue.toLowerCase()))
      )
    })

    setShowOptions(filteredOptions)
  }, [inputValue, options])

  if (options.length === 1 || options.length === 0) {
    return null
  }

  return (
    <Box sx={{ mb: 2 }}>
      <Autocomplete
        disabled={disabled}
        options={showOptions}
        value={selectedValue}
        onChange={(_, newValue) => onChange(newValue?._id || null)}
        onInputChange={(_, newInputValue) => {
          setInputValue(newInputValue)
        }}
        renderInput={(params) => (
          <TextField {...params} placeholder={`Clinician: ${user?.email}`} />
        )}
        getOptionLabel={(option) => {
          return option.firstName
            ? `${option.firstName}${option.lastName ? ' ' + option.lastName : ''}`
            : option.email
        }}
      />
    </Box>
  )
}

export default SelectUserSection
