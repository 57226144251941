import Button from '@mui/material/Button'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import Typography from '@mui/material/Typography'
import ClosableDialog from '../../../components/ClosableDialog'
import ViewTranscriptButton from '../../sessions/ViewTranscriptButton'
import { removeSpeakerLabels } from './lib'

type RecoverSessionDialogProps = {
  open: boolean
  loading?: boolean
  transcript: string
  onClose: () => void
  onConfirm: () => void
  onDiscard: () => void
}

const RecoverSessionDialog = ({
  open,
  loading,
  transcript,
  onClose,
  onConfirm,
  onDiscard,
}: RecoverSessionDialogProps) => {
  return (
    <ClosableDialog open={open} onClose={onClose} titleText="Recover Session" maxWidth="sm">
      <DialogContent>
        <Typography variant="body1">
          We found a previous session that was not completed. Would you like to recover it?
        </Typography>
        <ViewTranscriptButton transcript={removeSpeakerLabels(transcript)} sx={{ mt: 2 }} />
      </DialogContent>
      <DialogActions>
        <Button onClick={onConfirm} color="primary" disabled={loading}>
          Recover Session
        </Button>
        <Button onClick={onDiscard} color="secondary" disabled={loading}>
          Discard
        </Button>
      </DialogActions>
    </ClosableDialog>
  )
}

export default RecoverSessionDialog
