import Paper from '@mui/material/Paper'
import Grid from '@mui/material/Grid'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import { styled } from '@mui/material/styles'

const StyledImg = styled('img')({
  height: 40,
  width: 120,
  objectFit: 'contain',
})

export const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  '&:hover': {
    cursor: 'pointer',
    boxShadow: theme.shadows[4],
  },
}))

type IntegrationCardProps = {
  img: string
  title: string
  description: string
  onClick: () => void
}

const IntegrationCard = ({ img, title, description, onClick }: IntegrationCardProps) => {
  return (
    <StyledPaper elevation={0} variant="outlined" onClick={onClick}>
      <Grid container spacing={2} alignItems="center">
        <Grid item>
          <StyledImg src={img} alt={title} />
        </Grid>
        <Grid item xs>
          <Typography variant="body1" fontWeight={500}>
            {title}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {description}
          </Typography>
        </Grid>
      </Grid>
    </StyledPaper>
  )
}

export default IntegrationCard
