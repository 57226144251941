import { RDirectory } from '@counsel-project/counsel-auth-api'
import Paper from '@mui/material/Paper'
import { useCallback } from 'react'
import { toast } from 'react-hot-toast'
import { useNavigate } from 'react-router-dom'
import { useTitle } from 'react-use'
import CreateDirectoryForm from '../../components/forms/CreateDirectoryForm'
import PageContainer from '../../components/layout/PageContainer'

const OrganizationsPage = () => {
  useTitle('Clinical Notes AI - Organizations')
  const navigate = useNavigate()

  const handleCreateDirectory = useCallback(
    (directory: RDirectory) => {
      navigate(`/organization/${directory._id}`)
      toast.success('Organization created!')
    },
    [navigate]
  )

  return (
    <PageContainer>
      <Paper elevation={0} sx={{ p: 2 }}>
        <CreateDirectoryForm onSubmit={handleCreateDirectory} />
      </Paper>
    </PageContainer>
  )
}

export default OrganizationsPage
