import ClosableDialog from '../../components/ClosableDialog'
import LabeledTextField from '../../components/layout/LabeledTextField'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import Button from '@mui/material/Button'
import { RDirectory } from '@counsel-project/counsel-auth-api'
import { useCallback, useState } from 'react'
import handleError from '../../util/handleError'
import checkToken from '../../util/auth/checkToken'
import { authRequest } from '../../util/api/auth-api'
import toast from 'react-hot-toast'

type EditOrganizationDialogProps = {
  open: boolean
  onClose: () => void
  directory: RDirectory
  onChangeDirectory: (directory: RDirectory) => void
}

const EditOrganizationDialog = ({
  open,
  onClose,
  directory,
  onChangeDirectory,
}: EditOrganizationDialogProps) => {
  const [stagedName, setStagedName] = useState(directory.name)
  const [stagedDescription, setStagedDescription] = useState(directory.description)

  const [loading, setLoading] = useState(false)

  const handleSave = useCallback(async () => {
    try {
      setLoading(true)

      await checkToken()

      const { result } = await authRequest.user.directories.update({
        token: '',
        directoryId: directory._id,
        name: stagedName,
        description: stagedDescription,
      })

      onChangeDirectory(result)
      onClose()
      toast.success('Organization updated')
    } catch (err) {
      handleError(err)
    } finally {
      setLoading(false)
    }
  }, [directory, stagedName, stagedDescription, onChangeDirectory, onClose])

  return (
    <ClosableDialog titleText="Edit Organization" onClose={onClose} open={open}>
      <DialogContent>
        <LabeledTextField
          label="Name"
          value={stagedName}
          onChange={(e) => setStagedName(e.target.value)}
          sx={{ mb: 2 }}
        />
        <LabeledTextField
          label="Description"
          value={stagedDescription}
          onChange={(e) => setStagedDescription(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleSave} disabled={loading}>
          Save
        </Button>
      </DialogActions>
    </ClosableDialog>
  )
}

export default EditOrganizationDialog
