import { RDirectory } from '@counsel-project/counsel-auth-api'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTitle } from 'react-use'
import PageContainer from '../../components/layout/PageContainer'
import { authRequest } from '../../util/api/auth-api'
import checkToken from '../../util/auth/checkToken'
import handleError from '../../util/handleError'

export type OrganizationsPageProps = {
  initialResults?: RDirectory[]
}

const OrganizationsPage = ({ initialResults }: OrganizationsPageProps) => {
  useTitle('Clinical Notes AI - Organizations')
  const navigate = useNavigate()

  const [results, setResults] = useState<RDirectory[]>(initialResults || [])

  const populateDirectoryResults = useCallback(async () => {
    try {
      await checkToken()

      const data = await authRequest.user.directories.list({
        token: '',
      })

      setResults(data.results)
    } catch (err) {
      handleError(err)
    }
  }, [])

  useEffect(() => {
    if (initialResults) return
    populateDirectoryResults()
  }, [populateDirectoryResults, initialResults])

  return (
    <PageContainer>
      <Paper elevation={0} sx={{ p: 2 }}>
        <Typography variant="h4" textAlign="center" sx={{ mb: 1 }}>
          Organizations
        </Typography>
        <Typography variant="body1" textAlign="center">
          Choose an organization to view or create a new one.
        </Typography>
      </Paper>
      {results.map((result) => (
        <Paper
          elevation={4}
          key={result._id}
          sx={{ p: 2, mt: 2, cursor: 'pointer' }}
          onClick={() => {
            navigate(`/organization/${result._id}`)
          }}
        >
          <Typography variant="h6">{result.name}</Typography>
          <Typography variant="body1">{result.description}</Typography>
        </Paper>
      ))}
    </PageContainer>
  )
}

export default OrganizationsPage
