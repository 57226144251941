import { RRole } from '@counsel-project/counsel-auth-api'
import Typography from '@mui/material/Typography'
import { useMemo } from 'react'
import PermissionChip from '../chips/PermissionChip'
import StyledTableCell from './StyledTableCell'
import StyledTableRow from './StyledTableRow'

export type RoleRowProps = {
  isMobile?: boolean
  data: RRole
  showPermission?: boolean
  onClick?: (data: RRole) => void
}

const RoleRow = ({ isMobile, data, showPermission, onClick }: RoleRowProps) => {
  const { permission, priority, email, groupName } = data

  const displayName = useMemo(() => {
    if (email) return email
    return groupName + ' (Group)'
  }, [groupName, email])

  return (
    <StyledTableRow onClick={() => onClick?.(data)}>
      <StyledTableCell
        sx={{
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          maxWidth: 400,
        }}
      >
        <Typography
          variant="subtitle2"
          textOverflow="ellipsis"
          overflow="hidden"
          whiteSpace="nowrap"
        >
          {displayName}
          {data.invited ? ' (Invited)' : ''}
        </Typography>
      </StyledTableCell>
      {showPermission && (
        <StyledTableCell align="right">
          <PermissionChip permission={permission} priority={priority} />
        </StyledTableCell>
      )}
    </StyledTableRow>
  )
}

export default RoleRow
