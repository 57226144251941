import { RPatientSession } from '@counsel-project/counsel-transcribe-api'
import React, { useCallback, useEffect, useState } from 'react'
import Paper from '@mui/material/Paper'
import Grid from '@mui/material/Grid'
import LinearProgress from '@mui/material/LinearProgress'
import { transcribeRequest } from '../../util/api/transcribe-api'
import checkToken from '../../util/auth/checkToken'
import log from '../../util/logging'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import getSessionType from '../forms/func/getSessionType'
import handleError from '../../util/handleError'

export type SessionGenerationCheckerProps = {
  session: RPatientSession
  onChange: (session: RPatientSession) => void
}

const SessionGenerationChecker = ({ session, onChange }: SessionGenerationCheckerProps) => {
  const [loading, setLoading] = useState(false)

  const checkSessionStatus = useCallback(async () => {
    try {
      await checkToken()

      const { result } = await transcribeRequest.sessions.get({
        token: '',
        sessionId: session._id,
      })

      if (result.state === 'generating') {
        return
      }

      if (result.state === 'generated') {
        onChange(result)
        return
      }

      if (result.state === 'errored') {
        log.error(result.error)
        onChange(result)
        return
      }
    } catch (err) {
      console.error(err)
    }
  }, [session, onChange])

  useEffect(() => {
    if (session.state !== 'generating') return
    const interval = setInterval(checkSessionStatus, 6000)
    return () => clearInterval(interval)
  }, [checkSessionStatus, session])

  const handleCancelGeneration = useCallback(async () => {
    try {
      setLoading(true)

      await checkToken()

      const { result } = await transcribeRequest.sessions.generate.cancel({
        token: '',
        sessionId: session._id,
      })

      onChange(result)
    } catch (err) {
      handleError(err)
    } finally {
      setLoading(false)
    }
  }, [session, onChange])

  if (!session.state || session.state === 'generated') return null

  const sessionType = getSessionType(session)

  const isTranscribeSession = sessionType === 'live-session' || sessionType === 'dictation'

  return (
    <Paper elevation={0} sx={{ mb: 2 }}>
      <Box
        sx={(theme) => ({
          position: 'relative',
          width: '100%',
          borderRadius: theme.shape.borderRadius + 'px ' + theme.shape.borderRadius + 'px 0 0',
        })}
      >
        <Box
          sx={(theme) => ({
            position: 'absolute',
            width: '100%',
            height: 12,
            borderRadius: theme.shape.borderRadius + 'px ' + theme.shape.borderRadius + 'px 0 0',
            overflow: 'hidden',
          })}
        >
          <LinearProgress
            sx={{
              opacity: session.state === 'generating' || session.state === 'errored' ? 1 : 0,
              transition: 'opacity 0.3s ease',
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
            }}
            color={session.state === 'generating' ? 'primary' : 'error'}
            variant={session.state === 'generating' ? 'indeterminate' : 'determinate'}
          />
        </Box>
      </Box>
      {session.state === 'generating' ? (
        <Grid container padding={2} paddingTop={1} spacing={2} justifyContent="space-between">
          <Grid item>
            <Typography variant="body1" fontWeight={500}>
              {session.type === 'note' || !session.type
                ? 'Note is currently generating...'
                : 'Document is currently generating...'}
            </Typography>
            <Typography variant="body1" fontWeight={500} color="text.secondary">
              Come back in a few minutes to see the results
            </Typography>
          </Grid>
          <Grid item xs={12} sm="auto">
            <Button disabled={loading} onClick={handleCancelGeneration} fullWidth>
              Cancel
            </Button>
          </Grid>
        </Grid>
      ) : session.state === 'errored' ? (
        <Grid container padding={2} paddingTop={1} spacing={2} justifyContent="space-between">
          <Grid item>
            <Typography variant="body1" fontWeight={500}>
              {isTranscribeSession ? 'Transcription failed' : 'Generation failed'}
            </Typography>
            <Typography variant="body1" fontWeight={500} color="text.secondary">
              {session?.error || 'Server error occurred. Please try again later.'}
            </Typography>
          </Grid>
        </Grid>
      ) : session.state === 'finishing' ? (
        <Grid container padding={2} paddingTop={1} spacing={2} justifyContent="space-between">
          <Grid item>
            <Typography variant="body1" fontWeight={500}>
              Transcription is still working...
            </Typography>
            <Typography variant="body1" fontWeight={500} color="text.secondary">
              Come back in a few minutes to see the results
            </Typography>
          </Grid>
        </Grid>
      ) : null}
    </Paper>
  )
}

export default SessionGenerationChecker
