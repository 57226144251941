import React, { useCallback, useEffect, useState } from 'react'
import checkToken from '../../util/auth/checkToken'
import { ehrRequest } from '../../util/api/ehr-api'
import Checkbox from '@mui/material/Checkbox'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import FormControlLabel from '@mui/material/FormControlLabel'
import { authRequest } from '../../util/api/auth-api'
import getUser from '../../util/auth/getUser'

type EHRPatientContextToggleProps = {
  patientLabel: string
  onChangeEhrPatientId: (ehrPatientId: string | null) => void
}

const EHRPatientContextToggle = ({
  patientLabel,
  onChangeEhrPatientId,
}: EHRPatientContextToggleProps) => {
  const [checked, setChecked] = useState(false)
  const [ehrPatientId, setEHRPatientId] = useState<string | null>(null)

  const populateEhrPatient = useCallback(async () => {
    try {
      if (!patientLabel) {
        setChecked(false)
        setEHRPatientId(null)
        onChangeEhrPatientId(null)
        return
      }

      const user = getUser()
      if (!user) return

      await checkToken()

      const { directoryIds } = await authRequest.user.lookup.directories.byUserId({
        token: '',
        userId: user._id,
      })

      if (!directoryIds) return
      if (directoryIds.length === 0) return

      const { results, total } = await ehrRequest.integrations.patients.list({
        token: '',
        limit: 1,
        search: {
          and: [{ name: patientLabel }],
        },
      })

      if (total !== 1 || !results[0]) {
        setChecked(false)
        setEHRPatientId(null)
        onChangeEhrPatientId(null)
        return
      }

      setEHRPatientId(results[0]._id)
      onChangeEhrPatientId(results[0]._id)
      setChecked(true)
    } catch (err) {
      console.error(err)
    }
  }, [patientLabel, onChangeEhrPatientId])

  useEffect(() => {
    const timeout = setTimeout(populateEhrPatient, 10)
    return () => clearTimeout(timeout)
  }, [populateEhrPatient])

  const toggleHandler = () => () => {
    setChecked(!checked)
    onChangeEhrPatientId(checked ? null : ehrPatientId)
  }

  if (!ehrPatientId) return null

  return (
    <Paper sx={{ px: 2, pb: 2, pt: 1, mb: 1 }} variant="outlined">
      <FormControlLabel
        control={<Checkbox checked={checked} onChange={toggleHandler()} />}
        label="Include context from EHR"
      />
      <Typography variant="body1" color="text.secondary" sx={{ mt: 1 }}>
        Include treatment plan data & previous notes from your EHR as additional context
      </Typography>
    </Paper>
  )
}

export default EHRPatientContextToggle
