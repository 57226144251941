import { RPatientSession } from '@counsel-project/counsel-transcribe-api'
import { RLayout } from '@counsel-project/counsel-transcribe-api/dist/common/database/RLayout'
import getSessionType from './getSessionType'

const getSessionLabel = (session: RPatientSession, layout?: RLayout) => {
  const type = getSessionType(session)
  switch (type) {
    case 'dictation':
      return 'Dictation'
    case 'live-session':
      return 'Live Session'
    default:
      break
  }

  if (session.layout && layout) {
    return layout.name
  }

  if (session.documentType) {
    return session.documentType
  }

  switch (type) {
    case 'note':
      return 'Note'
    case 'document':
      return 'Document'
    case 'observation':
      return 'Observation'
    default:
      return 'Note'
  }
}

export default getSessionLabel
