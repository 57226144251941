import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import { formatDuration } from '../../util'

export type StatCardProps = {
  title: string
  count: number
  aggregate: number
}

const StatCard = ({ title, count, aggregate }: StatCardProps) => {
  return (
    <Paper
      elevation={0}
      sx={{
        p: 2,
        height: '100%',
      }}
    >
      <Grid
        container
        alignItems="space-between"
        justifyContent="space-between"
        direction="column"
        height="100%"
      >
        <Grid xs>
          <Typography variant="h6" textAlign="center" sx={{ mb: 2 }}>
            {title}
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="h4" textAlign="center">
            {count}
          </Typography>
        </Grid>
        {aggregate !== count && (
          <Grid item>
            <Typography variant="h5" textAlign="center" sx={{ mt: 2 }}>
              {formatDuration(aggregate)} Total
            </Typography>
          </Grid>
        )}
      </Grid>
    </Paper>
  )
}

export default StatCard
