import Button from '@mui/material/Button'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import Typography from '@mui/material/Typography'
import ClosableDialog from '../../../components/ClosableDialog'

type ResumeSessionDialogProps = {
  open: boolean
  loading?: boolean
  onClose: () => void
  onResume: () => void
}

const ResumeSessionDialog = ({ open, loading, onClose, onResume }: ResumeSessionDialogProps) => {
  return (
    <ClosableDialog open={open} onClose={onClose} titleText="Resume Session" maxWidth="sm">
      <DialogContent>
        <Typography variant="body1">
          We haven't heard any audio for a while, so we've paused this session. Would you like to
          resume?
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onResume} color="primary" disabled={loading}>
          Resume
        </Button>
      </DialogActions>
    </ClosableDialog>
  )
}

export default ResumeSessionDialog
