import Cookies from 'js-cookie'
import { setStore } from '../store/auth'
import { request } from '@counsel-project/client-utils'

const url = process.env.NODE_ENV === 'development' ? 'http://localhost:3001/api' : '/api'

const logout = async (storePageState?: boolean) => {
  Cookies.remove('token')
  Cookies.remove('expiresAt')
  Cookies.remove('refreshToken')
  setStore({
    user: null,
  })
  await request({
    method: 'POST',
    url: url + '/logout',
    withCredentials: true,
  })

  if (window.location.pathname === '/login') return
  if (!storePageState) {
    window.location.href = '/login'
    return
  }
  window.location.href = `/login?state=${encodeURIComponent(
    JSON.stringify({
      page: window.location.pathname + window.location.search,
    })
  )}`
}

export default logout
