import { RoleDirectoryMap } from '@counsel-project/counsel-auth-api'
import DialogContent from '@mui/material/DialogContent'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { useCallback, useEffect, useState } from 'react'
import ClosableDialog from '../../components/ClosableDialog'
import { authRequest } from '../../util/api/auth-api'
import checkToken from '../../util/auth/checkToken'
import useUser from '../../util/auth/useUser'
import handleError from '../../util/handleError'
import InvitationCard from './InvitationCard'

const InvitationsDialog = () => {
  const [user] = useUser()

  const [invitations, setInvitations] = useState<RoleDirectoryMap[]>([])

  const populateInvitations = useCallback(async () => {
    try {
      const loggedIn = await checkToken(true)
      if (!loggedIn) return

      const { results } = await authRequest.user.directories.invitations.list({ token: '' })

      setInvitations(results)
    } catch (err) {
      handleError(err)
    }
  }, [])

  useEffect(() => {
    const timeout = setTimeout(() => {
      populateInvitations()
    }, 100)
    return () => clearTimeout(timeout)
  }, [populateInvitations, user])

  return (
    <ClosableDialog
      open={invitations.length !== 0}
      onClose={() => setInvitations([])}
      titleText="Organization Invites"
    >
      <DialogContent>
        <Typography variant="body1" sx={{ mb: 2 }}>
          You have {invitations.length} pending invitation{invitations.length > 1 ? 's' : ''}.
        </Typography>
        <Grid container justifyContent="center">
          {invitations.map((invitation) => (
            <Grid item xs={12} key={invitation.directory._id}>
              <InvitationCard
                directory={invitation.directory}
                onDeclined={() => setInvitations([])}
                onAccepted={() => setInvitations([])}
                variant="outlined"
              />
            </Grid>
          ))}
        </Grid>
      </DialogContent>
    </ClosableDialog>
  )
}

export default InvitationsDialog
