import { RoleDirectoryMap } from '@counsel-project/counsel-auth-api'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTitle } from 'react-use'
import NoDataDisplay from '../../components/layout/NoDataDisplay'
import PageContainer from '../../components/layout/PageContainer'
import { authRequest } from '../../util/api/auth-api'
import checkToken from '../../util/auth/checkToken'
import handleError from '../../util/handleError'
import InvitationCard from './InvitationCard'

const InvitationsPage = () => {
  useTitle('Clinical Notes AI - Invitations')
  const navigate = useNavigate()

  const [invitations, setInvitations] = useState<RoleDirectoryMap[]>([])
  const [isLoading, setIsLoading] = useState(true)

  const populateInvitations = useCallback(async () => {
    try {
      setIsLoading(true)

      await checkToken()

      const { results } = await authRequest.user.directories.invitations.list({ token: '' })

      setInvitations(results)
    } catch (err) {
      handleError(err)
      navigate('/')
    } finally {
      setIsLoading(false)
    }
  }, [navigate])

  useEffect(() => {
    const timeout = setTimeout(() => {
      populateInvitations()
    }, 100)
    return () => clearTimeout(timeout)
  }, [populateInvitations])

  const handleBack = () => {
    navigate('/organizations')
  }

  return (
    <PageContainer>
      <Grid container justifyContent="center" alignItems="center" direction="column">
        <Grid item xs={12}>
          <Typography variant="h4" sx={{ mb: 2 }}>
            Organization Invitations
          </Typography>
        </Grid>
        {invitations.map((invitation) => (
          <Grid item xs={12} key={invitation.directory._id}>
            <InvitationCard directory={invitation.directory} onDeclined={populateInvitations} />
          </Grid>
        ))}
        {!isLoading && invitations.length === 0 && (
          <Grid item>
            <NoDataDisplay message="No invitations found" />
            <Button onClick={handleBack} fullWidth sx={{ mt: 2 }}>
              Back to Organizations
            </Button>
          </Grid>
        )}
      </Grid>
    </PageContainer>
  )
}

export default InvitationsPage
