import React, { useCallback, useEffect, useState } from 'react'
import checkToken from '../../util/auth/checkToken'
import { ehrRequest } from '../../util/api/ehr-api'
import Checkbox from '@mui/material/Checkbox'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import FormControlLabel from '@mui/material/FormControlLabel'
import { RIntegrationPatient } from '@counsel-project/counsel-ehr-api'
import { authRequest } from '../../util/api/auth-api'
import getUser from '../../util/auth/getUser'

type EHRMultiPatientContextToggleProps = {
  patientLabels: string[] | null
  onChangeEhrPatients: (ehrPatients: RIntegrationPatient[] | null) => void
}

const EHRMultiPatientContextToggle = ({
  patientLabels,
  onChangeEhrPatients,
}: EHRMultiPatientContextToggleProps) => {
  const [checked, setChecked] = useState(false)
  const [ehrPatients, setEHRPatients] = useState<RIntegrationPatient[] | null>(null)

  const populateEhrPatients = useCallback(async () => {
    try {
      if (!patientLabels || patientLabels.length === 0) {
        setChecked(false)
        setEHRPatients(null)
        onChangeEhrPatients(null)
        return
      }

      const user = getUser()
      if (!user) return

      await checkToken()

      const { directoryIds } = await authRequest.user.lookup.directories.byUserId({
        token: '',
        userId: user._id,
      })

      if (!directoryIds) return
      if (directoryIds.length === 0) return

      const { results } = await ehrRequest.integrations.patients.list({
        token: '',
        limit: patientLabels.length,
        search: {
          or: patientLabels.map((label) => ({ name: label })),
        },
      })

      if (results.length === 0) {
        setChecked(false)
        setEHRPatients(null)
        onChangeEhrPatients(null)
        return
      }

      setEHRPatients(results)
      onChangeEhrPatients(results)
      setChecked(true)
    } catch (err) {
      console.error(err)
    }
  }, [patientLabels, onChangeEhrPatients])

  useEffect(() => {
    const timeout = setTimeout(populateEhrPatients, 10)
    return () => clearTimeout(timeout)
  }, [populateEhrPatients])

  const toggleHandler = () => () => {
    setChecked(!checked)
    onChangeEhrPatients(checked ? null : ehrPatients)
  }

  if (!ehrPatients) return null

  return (
    <Paper sx={{ px: 2, pb: 2, pt: 1, mb: 1 }} variant="outlined">
      <FormControlLabel
        control={<Checkbox checked={checked} onChange={toggleHandler()} />}
        label="Include context from EHR"
      />
      <Typography variant="body1" color="text.secondary" sx={{ mt: 1 }}>
        Include treatment plan data & previous notes from your EHR as additional context
      </Typography>
    </Paper>
  )
}

export default EHRMultiPatientContextToggle
