import React from 'react'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'

type LayoutModelSelectorProps = {
  model: number | undefined
  onChange: (model: number | undefined) => void
  disabled?: boolean
}

const LayoutModelSelector = ({ model, disabled, onChange }: LayoutModelSelectorProps) => {
  return (
    <>
      <FormControlLabel
        control={
          <Checkbox
            checked={model === 0}
            onChange={(e) => onChange(e.target.checked ? 0 : undefined)}
            disabled={disabled}
          />
        }
        label="Model 0 (Default)"
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={model === 1}
            onChange={(e) => onChange(e.target.checked ? 1 : undefined)}
            disabled={disabled}
          />
        }
        label="Model 1 C-O"
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={model === 2}
            onChange={(e) => onChange(e.target.checked ? 2 : undefined)}
            disabled={disabled}
          />
        }
        label="Model 2 C-S"
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={model === 3}
            onChange={(e) => onChange(e.target.checked ? 3 : undefined)}
            disabled={disabled}
          />
        }
        label="Model 3 C-H"
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={model === 5}
            onChange={(e) => onChange(e.target.checked ? 5 : undefined)}
            disabled={disabled}
          />
        }
        label="Model 5 G-O-M"
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={model === 6}
            onChange={(e) => onChange(e.target.checked ? 6 : undefined)}
            disabled={disabled}
          />
        }
        label="Model 6 G-O"
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={model === 7}
            onChange={(e) => onChange(e.target.checked ? 7 : undefined)}
            disabled={disabled}
          />
        }
        label="Model 7 V-G-F"
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={model === 8}
            onChange={(e) => onChange(e.target.checked ? 8 : undefined)}
            disabled={disabled}
          />
        }
        label="Model 8 V-G-P"
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={model === 9}
            onChange={(e) => onChange(e.target.checked ? 9 : undefined)}
            disabled={disabled}
          />
        }
        label="Model 9 M-L-M-M"
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={model === 10}
            onChange={(e) => onChange(e.target.checked ? 10 : undefined)}
            disabled={disabled}
          />
        }
        label="Model 10 M-L-M-L"
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={model === 11}
            onChange={(e) => onChange(e.target.checked ? 11 : undefined)}
            disabled={disabled}
          />
        }
        label="Model 11 GO1"
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={model === 12}
            onChange={(e) => onChange(e.target.checked ? 12 : undefined)}
            disabled={disabled}
          />
        }
        label="Model 12 GO1-M"
      />
    </>
  )
}

export default LayoutModelSelector
