import Button, { ButtonProps } from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import MicrosoftLogo from '../logos/MicrosoftLogo'
import GoogleLogo from '../logos/GoogleLogo'

type GoogleButtonProps = ButtonProps

const GoogleButton = ({ ...props }: GoogleButtonProps) => {
  return (
    <Button
      startIcon={<GoogleLogo />}
      variant="contained"
      color="info"
      sx={{
        display: 'flex',
        justifyContent: 'start',
        fontWeight: 500,
        backgroundColor: 'white',
        color: 'text.secondary',
        '&:hover': {
          backgroundColor: 'white',
        },
        p: 2,
        px: 3,
      }}
      {...props}
    >
      {props.children || 'Sign in with Google'}
    </Button>
  )
}

export default GoogleButton
