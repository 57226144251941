import Button from '@mui/material/Button'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import Typography from '@mui/material/Typography'
import ClosableDialog from '../../../components/ClosableDialog'

type NetworkIssuesDialogProps = {
  open: boolean
  loading?: boolean
  onClose: () => void
  onRetry: () => void
}

const NetworkIssuesDialog = ({ open, loading, onClose, onRetry }: NetworkIssuesDialogProps) => {
  return (
    <ClosableDialog open={open} onClose={onClose} titleText="Internet Offline" maxWidth="sm">
      <DialogContent>
        <Typography variant="body1">
          It looks like your internet connection is offline or unstable. Please check your
          connection and try again.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onRetry} color="primary" disabled={loading}>
          Try Again
        </Button>
      </DialogActions>
    </ClosableDialog>
  )
}

export default NetworkIssuesDialog
