import { RDirectory, RRole } from '@counsel-project/counsel-auth-api'
import { UserAction } from '@counsel-project/counsel-transcribe-api'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import { useCallback, useEffect, useState } from 'react'
import { transcribeRequest } from '../../util/api/transcribe-api'
import checkToken from '../../util/auth/checkToken'
import handleError from '../../util/handleError'
import StatCard from '../../components/metrics/StatCard'
import { authRequest } from '../../util/api/auth-api'
import { useNavigate } from 'react-router-dom'
import Paper from '@mui/material/Paper'
import PageContainer from '../../components/layout/PageContainer'
import { METRIC_NAME_MAPPINGS } from '../../components/metrics/lib'
import { MetricAction } from '@counsel-project/counsel-transcribe-api/dist/common/database/RMetric'
import MetricActionSelector from '../../components/metrics/MetricActionSelector'
import ActivityLog from '../../components/metrics/ActivityLog'
import OrganizationMetricCard from '../../components/metrics/OrganizationMetricCard'
import DropdownSection from '../../components/layout/DropdownSection'
import MetricOptions from '../../components/metrics/MetricOptions'
import MetricsSavingsCalculator from '../../components/metrics/MetricsSavingsCalculator'
import getUser from '../../util/auth/getUser'

export type DirectoryRoleMap = {
  directory: RDirectory
  role: RRole
}

const StatsPage = () => {
  const [userStats, setUserStats] = useState<UserAction[]>([])
  const [loading, setLoading] = useState(false)
  const [startDate, setStartDate] = useState<Date | null>(null)
  const [endDate, setEndDate] = useState<Date | null>(null)
  const [directories, setDirectories] = useState<DirectoryRoleMap[]>([])
  const [actions, setActions] = useState<MetricAction[]>([
    'live-session',
    'dictation',
    'generate-document',
    'any-edit-document',
  ])

  const navigate = useNavigate()

  const populateStats = useCallback(async () => {
    try {
      setLoading(true)

      await checkToken()

      const user = getUser()

      if (!user) return

      const { results } = await transcribeRequest.metrics.user.count({
        token: '',
        startDate: startDate?.toISOString(),
        endDate: endDate?.toISOString(),
        actions,
      })

      setUserStats(results)

      const { results: dirs } = await authRequest.user.directories.list({
        token: '',
      })

      const mappings: DirectoryRoleMap[] = await Promise.all(
        dirs.map(async (directory) => {
          const { results } = await authRequest.user.directories.roles.list({
            token: '',
            directoryId: directory._id,
            search: {
              and: [{ userId: user._id }],
            },
          })
          return { directory, role: results[0] }
        })
      )

      setDirectories(mappings.filter((m) => m.role.permission === 'administrator'))
    } catch (err) {
      handleError(err)
    } finally {
      setLoading(false)
    }
  }, [actions, startDate, endDate])

  useEffect(() => {
    const timeout = setTimeout(() => {
      populateStats()
    }, 10)
    return () => clearTimeout(timeout)
  }, [populateStats])

  const handleClickDirectory = (dir: RDirectory) => {
    navigate(`/organization/${dir._id}?tab=4`)
  }

  const handleOptionsChange = useCallback(
    (actions: MetricAction[], startDate: Date | null, endDate: Date | null) => {
      setActions(actions)
      setStartDate(startDate)
      setEndDate(endDate)
    },
    []
  )
  if (userStats.length === 0) {
    return <Typography>Loading...</Typography>
  }

  return (
    <PageContainer>
      <Typography variant="h5" fontWeight={600} textAlign="center">
        My Metrics
      </Typography>
      <Typography
        variant="body1"
        fontStyle="italic"
        color="text.secondary"
        textAlign="center"
        sx={{ mt: 1, mb: 1 }}
      >
        Disclaimer: Data before Oct 26, 2024 is not available
      </Typography>

      <DropdownSection title="Date/Search Filters" subtitle="Filter and sort the data">
        <MetricOptions onOptionsChange={handleOptionsChange} />
      </DropdownSection>
      <DropdownSection
        title="Savings Calculator"
        subtitle="Calculate the savings for the organization"
        sx={{ mt: 2, mb: 2 }}
        defaultOpen={true}
      >
        <MetricsSavingsCalculator startDate={startDate} endDate={endDate} />
      </DropdownSection>
      <Grid container spacing={2} alignItems="stretch" justifyContent="center">
        {userStats.map((stat) => (
          <Grid item xs={6} sm={4} md={3} key={stat.action}>
            <StatCard
              title={'Total ' + METRIC_NAME_MAPPINGS[stat.action]}
              count={stat.count}
              aggregate={stat.aggregate}
            />
          </Grid>
        ))}
      </Grid>
      {directories.length > 0 && (
        <>
          <Typography variant="h5" fontWeight={600} textAlign="center" sx={{ mt: 3, mb: 2 }}>
            Organization Metrics
          </Typography>
          <Grid container xs={12} spacing={2} alignItems="stretch" justifyContent="center">
            {directories.map((dir) => (
              <Grid item xs={6} sm={4} key={dir.directory._id}>
                <OrganizationMetricCard
                  directory={dir.directory}
                  onViewMetrics={handleClickDirectory}
                />
              </Grid>
            ))}
          </Grid>
        </>
      )}
      <Typography variant="h5" fontWeight={600} textAlign="center" sx={{ mt: 3, mb: 2 }}>
        Activity Log
      </Typography>
      <Paper elevation={0} sx={{ p: 2 }}>
        <ActivityLog />
      </Paper>
    </PageContainer>
  )
}

export default StatsPage
