import { DocumentType } from '@counsel-project/counsel-transcribe-api/dist/common/database/RLayout'
import Autocomplete, { AutocompleteProps } from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import React from 'react'

const documentTypeOptions: (DocumentType | 'Other')[] = [
  'Intake',
  'Progress Note',
  'Treatment Plan',
  'Case Note',
  'Discharge Summary',
  'Referral / Consultation',
  'Insurance / Billing',
  'Peer Consultation Note',
  'Supervision Note',
  'Release of Information',
  'Mental Status Exam',
  'Assessment',
  'Screening',
  'Relapse Prevention Plan',
  'Aftercare Plan',
  'Group Therapy Note',
  'Family / Collateral Contacts',
  'Mandated Reporting Form',
  'Referral / Consultation',
  'Group Dynamics Analysis',
  'Mental Status Exam',
  'Medical History / Physical Exam',
  'Prescription Records',
  'Diagnostic Test Results',
  'Preventive Care Plan',
  'Surgical Note',
  'Procedure Note',
  'Follow-up / Recall Systems',
  'Nursing Assessment',
  'Vital Signs Record',
  'Medication Administration Record (MAR)',
  'Patient Monitoring',
  'Incident Report',
  'Handover Note',
  'Wound Care Progress Note',
  'Infection Control',
  'Patient / Family Feedback',
  'Other',
]

export type DocumentTypeSelectorProps = {
  id?: string
  placeholder?: string
  hiddenOptions?: string[]
  value: string | null
  onChange: (value: string | null) => void
} & Omit<
  AutocompleteProps<string, false, false, true>,
  'renderInput' | 'renderOption' | 'onChange' | 'value' | 'options'
>

const DocumentTypeSelectorBase = (
  {
    id,
    placeholder = 'Search or Create New...',
    hiddenOptions,
    value,
    onChange,
    ...props
  }: DocumentTypeSelectorProps,
  ref: React.Ref<HTMLDivElement>
) => {
  return (
    <Autocomplete
      {...props}
      ref={ref}
      id={id}
      value={value}
      onChange={(e, value) => {
        onChange(value)
      }}
      options={documentTypeOptions}
      noOptionsText="No options found"
      freeSolo
      selectOnFocus
      handleHomeEndKeys
      clearOnBlur
      renderInput={(params) => <TextField {...params} placeholder={placeholder} />}
    />
  )
}

export default React.forwardRef(DocumentTypeSelectorBase)
