import { RPatientSession } from '@counsel-project/counsel-transcribe-api'

const getSessionType = (session: RPatientSession) => {
  if (session.dictation && !session.note && !session.layout && !session.type) {
    return 'dictation'
  }
  if (!session.note && !session.layout && !session.type) {
    return 'live-session'
  }

  switch (session?.type) {
    case 'note':
      return 'note'
    case 'document':
      return 'document'
    case 'observation':
      return 'observation'
    default:
      return 'note'
  }
}

export default getSessionType
