import React from 'react'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Typography from '@mui/material/Typography'

const tags = [
  'Custom',
  'Intake Notes',
  'Progress Notes',
  'Documents',
  'Other',
  'Reports',
  'Integrations',
  'EHR Integrations',
]

type LayoutCategorySelectorProps = {
  value: string
  onChange: (value: string) => void
  disabled?: boolean
}

const LayoutCategorySelector = ({ value, onChange, disabled }: LayoutCategorySelectorProps) => {
  return (
    <FormControl sx={{ width: '100%' }}>
      <Typography variant="body1" fontWeight={500} fontSize={18} sx={{ mb: 1 }}>
        Category
      </Typography>
      <Select
        value={value}
        onChange={(event) => onChange(event.target.value)}
        disabled={disabled}
        fullWidth
      >
        {tags.map((tag) => (
          <MenuItem key={tag} value={tag}>
            {tag}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default LayoutCategorySelector
