import { LicenseSortableFields, RLicense } from '@counsel-project/counsel-auth-api'
import Button from '@mui/material/Button'
import Paper from '@mui/material/Paper'
import { useCallback, useEffect, useState } from 'react'
import { useTitle } from 'react-use'
import ConfirmDialog from '../../components/ConfirmDialog'
import PageContainer from '../../components/layout/PageContainer'
import LicenseTable from '../../components/tables/LicenseTable'
import { authRequest } from '../../util/api/auth-api'
import checkToken from '../../util/auth/checkToken'
import LicenseDialog, { UpdateLicenseResult } from './LicenseDialog'
import useRequireAdmin from '../../util/auth/useRequireAdmin'
import { useSearchParams } from 'react-router-dom'

const Licenses = () => {
  useTitle('Clinical Notes AI - Admin Licenses')
  useRequireAdmin('/')

  const [searchParams, setSearchParams] = useSearchParams()

  const [loading, setLoading] = useState(true)
  const [licenses, setLicenses] = useState<RLicense[]>([])
  const [total, setTotal] = useState(0)
  const [limit, setLimit] = useState(10)
  const [page, setPage] = useState(0)
  const [sort, setSort] = useState<LicenseSortableFields>('userEmail')
  const [direction, setDirection] = useState<'asc' | 'desc'>('asc')

  const [createOpen, setCreateOpen] = useState(false)
  const [activeLicense, setActiveLicense] = useState<RLicense | null>(null)
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false)

  const handleToggleConfirmDelete = () => {
    setConfirmDeleteOpen((prev) => !prev)
  }

  const handleToggleCreateOpen = () => {
    setActiveLicense(null)
    setCreateOpen((prev) => !prev)
  }

  const handleChangePage = (newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (newRowsPerPage: number) => {
    setLimit(newRowsPerPage)
  }

  const handleChangeSort = (newSort: LicenseSortableFields, newDirection: 'asc' | 'desc') => {
    setSort(newSort)
    setDirection(newDirection)
  }

  const populateLicenses = useCallback(async () => {
    try {
      await checkToken()

      setLoading(true)

      const data = await authRequest.admin.licenses.list({
        token: '',
        limit,
        offset: page * limit,
        sort,
        direction,
        search: searchParams.get('search')
          ? {
              or: [
                { type: searchParams.get('search'), $fuzzy: true },
                { userEmail: searchParams.get('search'), $fuzzy: true },
                { directoryName: searchParams.get('search'), $fuzzy: true },
              ],
            }
          : undefined,
      })

      setLicenses(data.results)
      setTotal(data.total)
    } catch (err) {
      console.error(err)
    } finally {
      setLoading(false)
    }
  }, [limit, page, sort, direction, searchParams])

  useEffect(() => {
    populateLicenses()
  }, [populateLicenses])

  const handleCreateLicense = useCallback(
    async (options: UpdateLicenseResult) => {
      try {
        await checkToken()

        if (!activeLicense) {
          await authRequest.admin.licenses.create({ ...options, token: '' })
        } else {
          await authRequest.admin.licenses.update({
            licenseId: activeLicense._id,
            ...options,
            token: '',
          })
        }

        populateLicenses()
        setCreateOpen(false)
      } catch (err) {
        console.error(err)
      } finally {
        setLoading(false)
      }
    },
    [populateLicenses, activeLicense]
  )

  const handleClickRow = (row: RLicense) => {
    setActiveLicense(row)
    setCreateOpen(true)
  }

  const handleDeleteLicense = useCallback(async () => {
    try {
      if (!activeLicense) return

      await checkToken()
      setLoading(true)

      await authRequest.admin.licenses.delete({
        token: '',
        licenseId: activeLicense._id,
      })

      populateLicenses()
      setCreateOpen(false)
      setConfirmDeleteOpen(false)
    } catch (err) {
      console.error(err)
    } finally {
      setLoading(false)
    }
  }, [activeLicense, populateLicenses])

  const handleSubmitSearch = (search: string) => {
    setSearchParams((prev) => {
      prev.set('search', search)
      return prev
    })
    setPage(0)
  }

  return (
    <PageContainer>
      <Paper sx={{ p: 2 }} elevation={0}>
        <Button onClick={handleToggleCreateOpen} sx={{ mb: 2 }}>
          Add License
        </Button>
        <LicenseTable
          loaded={!loading}
          rows={licenses}
          total={total}
          page={page}
          onSubmitSearch={handleSubmitSearch}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          onChangeSort={handleChangeSort}
          onClickRow={handleClickRow}
          rowsPerPage={limit}
          rowsPerPageOptions={[10, 25, 50]}
          sort={sort}
          direction={direction}
          showEdit
        />
      </Paper>
      <LicenseDialog
        license={activeLicense}
        open={createOpen}
        onClose={handleToggleCreateOpen}
        onConfirm={handleCreateLicense}
        onDelete={handleToggleConfirmDelete}
      />
      <ConfirmDialog
        open={confirmDeleteOpen}
        onClose={handleToggleConfirmDelete}
        onConfirm={handleDeleteLicense}
        titleText="Delete License"
        text="Are you sure you want to delete this license?"
      />
    </PageContainer>
  )
}

export default Licenses
