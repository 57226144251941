import useStore from '../store/auth'

const useActiveLicense = () => {
  const activeLicense = useStore((state) => state.activeLicense)
  const populateActiveLicense = useStore((state) => state.populateActiveLicense)
  const setActiveLicense = useStore((state) => state.setActiveLicense)

  return [activeLicense, setActiveLicense, populateActiveLicense] as const
}

export default useActiveLicense
