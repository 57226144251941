import { InputField } from '@counsel-project/counsel-generation-api'
import InfoIcon from '@mui/icons-material/Info'
import FormControlLabel from '@mui/material/FormControlLabel'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import { useEffect, useMemo, useState } from 'react'
import CopyButton from './CopyButton'

type BooleanTemplateFieldProps = {
  inputField: InputField
  value: string[]
  onChange?: ({ id, value }: { id: string; value: string[] }) => void
}

const BooleanTemplateField = ({ inputField, value, onChange }: BooleanTemplateFieldProps) => {
  const { name, condition } = inputField

  const [answer, setAnswer] = useState<string>('')

  useEffect(() => {
    if (value[0]) {
      setAnswer(value[0])
    }
  }, [value])

  const conditionText = useMemo(() => {
    if (condition?.contains) return `Only present if ${condition.id} contains ${condition.contains}`
    if (condition?.equals) return `Only present if ${condition.id} equals ${condition.equals}`
    return null
  }, [condition])

  return (
    <>
      <Grid container>
        <Grid item xs>
          <Typography variant="body1" fontWeight={500}>
            {name}
          </Typography>
        </Grid>
        {condition && (
          <Grid item>
            <Tooltip title={conditionText}>
              <IconButton size="small" color="secondary">
                <InfoIcon />
              </IconButton>
            </Tooltip>
          </Grid>
        )}
        <Grid item>
          <CopyButton text={answer} />
        </Grid>
      </Grid>
      <RadioGroup value={answer} onChange={(e) => setAnswer(e.target.value)}>
        <FormControlLabel value="true" control={<Radio />} label="Yes" />
        <FormControlLabel value="false" control={<Radio />} label="No" />
      </RadioGroup>
    </>
  )
}

export default BooleanTemplateField
