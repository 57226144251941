import { MetricAction } from '@counsel-project/counsel-transcribe-api/dist/common/database/RMetric'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import MetricActionSelector from './MetricActionSelector'
import { useEffect, useState } from 'react'
import SearchField from '../SearchField'

export type OnOptionsChange = (
  actions: MetricAction[],
  startDate: Date | null,
  endDate: Date | null,
  search: string
) => void

type MetricOptionsProps = {
  onOptionsChange: OnOptionsChange
  search?: boolean
  disabled?: boolean
}

export default function MetricOptions({
  onOptionsChange,
  search: searchEnabled = false,
  disabled = false,
}: MetricOptionsProps) {
  const [actions, setActions] = useState<MetricAction[]>([
    'live-session',
    'dictation',
    'generate-document',
    'any-edit-document',
  ])
  const [startDate, setStartDate] = useState<Date | null>(null)
  const [endDate, setEndDate] = useState<Date | null>(null)
  const [search, setSearch] = useState<string>('')

  useEffect(() => {
    onOptionsChange(actions, startDate, endDate, search)
  }, [actions, startDate, endDate, search, onOptionsChange])

  return (
    <>
      <Grid container spacing={2} alignItems="end">
        {searchEnabled && (
          <Grid item xs={12}>
            <Typography variant="body1" gutterBottom fontWeight={500}>
              User
            </Typography>
            <SearchField
              placeholder="Search by email"
              disabled={disabled}
              value={search}
              onChange={setSearch}
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <Typography variant="body1" gutterBottom fontWeight={500}>
            Data Points
          </Typography>
          <MetricActionSelector value={actions} onChange={setActions} />
        </Grid>
        <Grid item xs>
          <Typography variant="body1" gutterBottom fontWeight={500}>
            From Date
          </Typography>
          <TextField
            type="date"
            value={startDate?.toISOString().split('T')[0] ?? ''}
            onChange={(e) => setStartDate(new Date(e.target.value))}
            InputLabelProps={{
              shrink: true,
            }}
            fullWidth
            disabled={disabled}
          />
        </Grid>
        <Grid item xs>
          <Typography variant="body1" gutterBottom fontWeight={500}>
            To Date
          </Typography>
          <TextField
            type="date"
            value={endDate?.toISOString().split('T')[0] ?? ''}
            onChange={(e) => setEndDate(new Date(e.target.value))}
            InputLabelProps={{
              shrink: true,
            }}
            fullWidth
            disabled={disabled}
          />
        </Grid>
        <Grid item>
          <Button
            onClick={() => onOptionsChange(actions, startDate, endDate, search)}
            disabled={disabled}
            sx={{ py: 2 }}
          >
            Update
          </Button>
        </Grid>
      </Grid>
      <Typography variant="body1" fontWeight={500} sx={{ mt: 2 }} gutterBottom>
        Quick Date Lookups
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={4}>
          <Button
            color="primary"
            fullWidth
            onClick={() => {
              const start = new Date(Date.now() - 7 * 24 * 60 * 60 * 1000)
              const end = new Date()
              setStartDate(start)
              setEndDate(end)
            }}
          >
            Last 7 Days
          </Button>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Button
            color="primary"
            fullWidth
            onClick={() => {
              const start = new Date(Date.now() - 30 * 24 * 60 * 60 * 1000)
              const end = new Date()
              setStartDate(start)
              setEndDate(end)
            }}
          >
            Last 30 Days
          </Button>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Button
            color="primary"
            fullWidth
            onClick={() => {
              setStartDate(null)
              setEndDate(null)
            }}
          >
            All Time
          </Button>
        </Grid>
      </Grid>
    </>
  )
}
