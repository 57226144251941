import BackIcon from '@mui/icons-material/ArrowBackRounded'
import LockIcon from '@mui/icons-material/LockRounded'
import PauseRoundedIcon from '@mui/icons-material/PauseRounded'
import PlayArrowRoundedIcon from '@mui/icons-material/PlayArrowRounded'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import LinearProgress from '@mui/material/LinearProgress'
import Typography from '@mui/material/Typography'
import { alpha, styled, useTheme } from '@mui/material/styles'
import React, { useState } from 'react'
import { AudioVisualizer } from '@counsel-project/components'
import ChangeAudioIcon from '@mui/icons-material/ChangeCircleOutlined'
import IconButton from '@mui/material/IconButton'

const StyledDialog = styled(Dialog)(({ theme }) => ({
  // When active, show overlay
  transition: 'background-color 1s ease-in-out, visibility 1s ease-in-out',

  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
}))

type NewRecorderOverlayProps = {
  finishLabel?: string
  pauseLabel?: string
  resumeLabel?: string
  overlayText?: string | React.ReactNode
  recording: boolean
  paused: boolean
  loading: boolean
  transcript: string | null
  analyser: AnalyserNode | null
  device: MediaDeviceInfo | null
  onClickFinish?: () => void
  onClickPause?: () => void
  onClickResume?: () => void
  onClickBack?: () => void
  onClickChangeMicrophone?: () => void
}

const NewRecorderOverlay = ({
  finishLabel = 'Finish Session',
  pauseLabel = 'Pause Session',
  resumeLabel = 'Resume Session',
  overlayText = 'Your session is currently being transcribed\n\nUpon completion, you will be able to review and edit your session notes.',
  recording,
  paused,
  loading,
  transcript,
  analyser,
  device,
  onClickFinish,
  onClickPause,
  onClickResume,
  onClickBack,
  onClickChangeMicrophone,
}: NewRecorderOverlayProps) => {
  const [stagedDeleteTime, setStagedDeleteTime] = useState<Date | null>(null)

  const theme = useTheme()

  const handleClickFinish = () => {
    onClickFinish?.()
  }

  const handleBack = () => {
    setStagedDeleteTime(new Date())
    if (stagedDeleteTime && new Date().getTime() - stagedDeleteTime.getTime() < 6000) {
      if (onClickBack) onClickBack()
    }
  }

  return (
    <>
      <StyledDialog
        open={recording || paused}
        fullScreen
        PaperProps={{
          sx: (theme) => ({
            background: alpha(theme.palette.primary.dark, 0.8),
          }),
        }}
      >
        <LinearProgress
          color="secondary"
          sx={{
            backgroundColor: 'transparent',
            opacity: loading ? 1 : 0,
          }}
          variant={loading ? 'indeterminate' : 'determinate'}
          value={0}
        />
        {paused && (
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              backgroundColor: 'black',
              opacity: 0.8,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
            }}
          >
            <Typography
              variant="h4"
              color="white"
              textAlign="center"
              fontWeight={600}
              fontFamily="Mukta"
              sx={{ mb: 2 }}
            >
              Session is paused
            </Typography>
            <Typography
              variant="h5"
              color="white"
              textAlign="center"
              fontWeight={600}
              fontFamily="Mukta"
            >
              No audio is being captured
            </Typography>
          </Box>
        )}
        <DialogContent>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'start',
              alignItems: 'center',
            }}
          >
            <Button
              variant="contained"
              color="primary"
              startIcon={<BackIcon />}
              fullWidth
              disabled={loading}
              onClick={handleBack}
            >
              {stagedDeleteTime && new Date().getTime() - stagedDeleteTime.getTime() < 6000
                ? 'Are you sure?'
                : 'Cancel and Go Back'}
            </Button>
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
              mt: 2,
            }}
          >
            <Box sx={{ color: 'white' }}>
              {typeof overlayText === 'string'
                ? overlayText.split('\n').map((text, index) => (
                    <React.Fragment key={index}>
                      {text}
                      <br />
                    </React.Fragment>
                  ))
                : overlayText}
            </Box>
          </Box>
        </DialogContent>
        <DialogActions>
          <Box sx={{ width: '100%' }}>
            <Box
              sx={{
                height: 30,
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
                mb: 3,
              }}
            >
              {!!device && (
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <Typography
                    variant="h6"
                    fontFamily="Mukta"
                    color="white"
                    sx={{ px: 2 }}
                    textAlign="center"
                  >
                    Using "{device.label.replace('Microphone', '')?.trim()}" Microphone
                  </Typography>
                  <IconButton
                    color="primary"
                    size="small"
                    onClick={onClickChangeMicrophone}
                    sx={{ backgroundColor: 'primary.main', color: 'white' }}
                  >
                    <ChangeAudioIcon />
                  </IconButton>
                </Box>
              )}
            </Box>
            <Box
              sx={{
                height: 30,
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                pb: 4,
              }}
            >
              <AudioVisualizer
                backgroundColor={theme.palette.primary.dark}
                barColor={theme.palette.primary.main}
                filledColor="#fff"
                analyser={analyser}
                height={40}
                width={160}
              />
            </Box>
            <Box sx={{ width: '100%' }}>
              <Button
                id="mic-pause-button"
                sx={{ p: 3 }}
                startIcon={recording && !paused ? <PauseRoundedIcon /> : <PlayArrowRoundedIcon />}
                color={paused ? 'secondary' : 'primary'}
                onClick={recording && !paused ? onClickPause : onClickResume}
                disabled={loading}
                fullWidth
                variant="contained"
              >
                {recording && !paused ? pauseLabel : resumeLabel}
              </Button>
              <Button
                id="mic-finish-button"
                fullWidth
                sx={{
                  mt: 2,
                  p: 3,
                  '&.Mui-disabled': {
                    color: '#FB5086',
                  },
                }}
                color="secondary"
                disabled={loading || (!recording && !paused) || !transcript}
                onClick={handleClickFinish}
                startIcon={<LockIcon />}
                variant="contained"
              >
                {!transcript ? 'No words detected yet' : finishLabel}
              </Button>
            </Box>
          </Box>
        </DialogActions>
      </StyledDialog>
    </>
  )
}

export default NewRecorderOverlay
