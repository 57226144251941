import { capitalize } from '@counsel-project/client-utils'
import { RPatient } from '@counsel-project/counsel-transcribe-api'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import React, { useCallback, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useTitle } from 'react-use'
import SearchBar from '../../components/SearchBar'
import SelectableTab from '../../components/SelectableTab'
import CreatePatientDialog from '../../components/layout/CreatePatientDialog'
import PageContainer from '../../components/layout/PageContainer'
import { usePatientNomenclature } from '../../util'
import SelectUserSection from './SelectUserSection'
import ViewAllSessions from './ViewAllSessions'
import ViewPatients from './ViewPatients'
import ViewUnassignedSessions from './ViewUnassignedSessions'

const SessionsPage = () => {
  useTitle('Clinical Notes AI - Sessions')
  const [searchParams, setSearchParams] = useSearchParams()

  const userId = searchParams.get('userId')

  const listType = (searchParams.get('listType') as 'sessions' | 'patients' | 'all') || 'patients'
  const handleChangeListType = useCallback(
    (type: 'sessions' | 'patients' | 'all') => {
      setSearchParams((prev) => {
        prev.set('listType', type)
        return prev
      })
    },
    [setSearchParams]
  )

  const [search, setSearch] = useState('')
  const [submittedSearch, setSubmittedSearch] = useState('')
  const [createPatientOpen, setCreatePatientOpen] = useState(false)

  const handleChangeSearch = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value)
    if (e.target.value === '') {
      setSubmittedSearch('')
    }
  }, [])

  const handleSubmitSearch = useCallback(() => {
    setSubmittedSearch(search)
  }, [search])

  const patient = usePatientNomenclature()

  const handleOpenCreatePatient = () => {
    setCreatePatientOpen(true)
  }

  const handleCloseCreatePatient = () => {
    setCreatePatientOpen(false)
  }

  const handleRefresh = (patient: RPatient) => {
    setSubmittedSearch(patient.label)
    setSearch(patient.label)
  }

  const handleChangeSelectedUser = useCallback(
    (userId: string | null) => {
      setSearchParams((prev) => {
        if (userId) {
          prev.set('userId', userId)
        } else {
          prev.delete('userId')
        }
        return prev
      })
    },
    [setSearchParams]
  )

  return (
    <PageContainer>
      <Box>
        <SelectUserSection value={userId} onChange={handleChangeSelectedUser} />
        <Grid container spacing={2}>
          <Grid item xs>
            <SelectableTab
              active={listType === 'patients'}
              onClick={() => handleChangeListType('patients')}
            >
              {capitalize(patient)}s
            </SelectableTab>
          </Grid>
          <Grid item xs>
            <SelectableTab
              active={listType === 'sessions'}
              onClick={() => handleChangeListType('sessions')}
            >
              Unassigned
            </SelectableTab>
          </Grid>
          <Grid item xs>
            <SelectableTab active={listType === 'all'} onClick={() => handleChangeListType('all')}>
              Session History
            </SelectableTab>
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ mb: 2, mt: 2 }}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs>
            <SearchBar
              onChange={handleChangeSearch}
              value={search}
              onSubmit={handleSubmitSearch}
              placeholder={
                listType === 'sessions' ? 'Search for file title...' : `Search for ${patient}...`
              }
            />
          </Grid>
          {listType === 'patients' && (
            <Grid item>
              <Button onClick={handleOpenCreatePatient} sx={{ py: 0.75 }}>
                Create New
              </Button>
            </Grid>
          )}
        </Grid>
      </Box>
      {listType === 'patients' && (
        <ViewPatients search={submittedSearch} userId={userId} showAll={userId === 'show-all'} />
      )}
      {listType === 'all' && (
        <ViewAllSessions search={submittedSearch} userId={userId} showAll={userId === 'show-all'} />
      )}
      {listType === 'sessions' && (
        <ViewUnassignedSessions
          search={submittedSearch}
          userId={userId}
          showAll={userId === 'show-all'}
        />
      )}
      <CreatePatientDialog
        open={createPatientOpen}
        onClose={handleCloseCreatePatient}
        onCreated={handleRefresh}
      />
    </PageContainer>
  )
}

export default SessionsPage
