import React from 'react'
import Box from '@mui/material/Box'

type BlobBackgroundProps = {
  children: React.ReactNode
  height?: number
}

const BlobBackground = ({ children, height = 1600 }: BlobBackgroundProps) => {
  return (
    <Box
      sx={{
        backgroundImage: 'url(/blob-background-3.svg)',
        backgroundSize: 'cover',
        minHeight: `${height}px`,
        backgroundRepeat: 'no-repeat',
      }}
    >
      {children}
    </Box>
  )
}

export default BlobBackground
