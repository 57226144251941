import Box from '@mui/material/Box'
import { useRouteError } from 'react-router-dom'
import { useEffectOnce, useTitle } from 'react-use'
import PageContainer from '../components/layout/PageContainer'
import log from '../util/logging'
import { useViewContent } from '../util/tracking'

const TestErrorPage = () => {
  useViewContent('Error')
  useTitle('Clinical Notes AI - Error')

  const error = useRouteError()

  useEffectOnce(() => {
    log.error('ErrorPage', error)
    throw new Error('Test error')
  })

  return (
    <PageContainer>
      <Box sx={{ mt: 4, p: 2 }}>Existing data</Box>
    </PageContainer>
  )
}

export default TestErrorPage
