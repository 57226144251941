import HelpIcon from '@mui/icons-material/HelpOutlineRounded'
import React from 'react'
import IconButton from '@mui/material/IconButton'
import { useNavigate } from 'react-router-dom'
import Tooltip from '@mui/material/Tooltip'

type HelpButtonProps = {
  params: string
}

const HelpButton = ({ params }: HelpButtonProps) => {
  const navigate = useNavigate()

  const handleNav = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault()
    navigate(`/help${params}`)
  }

  return (
    <Tooltip title="Learn how to use this feature">
      <IconButton onClick={handleNav} href={`/help${params}`} LinkComponent="a" size="small">
        <HelpIcon />
      </IconButton>
    </Tooltip>
  )
}

export default HelpButton
