import { RPatient } from '@counsel-project/counsel-transcribe-api'
import { isApiError } from '@counsel-project/client-utils'
import { capitalize } from '@counsel-project/client-utils'
import Button from '@mui/material/Button'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import React, { useCallback, useEffect, useState } from 'react'
import { usePatientNomenclature } from '../../util'
import { transcribeRequest } from '../../util/api/transcribe-api'
import { refreshPatientsCache } from '../../util/api/transcribe-api-cached'
import checkToken from '../../util/auth/checkToken'
import log from '../../util/logging'
import ClosableDialog from '../ClosableDialog'
import handleError from '../../util/handleError'

type CreatePatientDialogProps = {
  open: boolean
  onClose: () => void
  onCreated: (patient: RPatient) => void
}

const CreatePatientDialog = ({ open, onClose, onCreated }: CreatePatientDialogProps) => {
  const [label, setLabel] = useState('')
  const [loading, setLoading] = useState(false)
  const patientNomenclature = usePatientNomenclature()
  const [error, setError] = useState<string | null>(null)

  const handleChangeLabel = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setLabel(capitalize(e.target.value))
  }, [])

  const handleCreatePatient = useCallback(async () => {
    try {
      setLoading(true)

      await checkToken()

      const { result } = await transcribeRequest.patients.create({ label, token: '' })
      refreshPatientsCache()

      onCreated(result)
      onClose()
    } catch (err) {
      log.error(err)
      if (isApiError(err)) {
        setError(err.msg)
      }
    } finally {
      setLoading(false)
    }
  }, [label, onCreated, onClose])

  const checkIfPatientExists = useCallback(async () => {
    try {
      if (!label) return

      await checkToken()

      const { results } = await transcribeRequest.patients.list.mine({
        token: '',
        limit: 1,
        search: {
          and: [{ label }],
        },
      })

      if (results?.length > 0) {
        setError(`A ${patientNomenclature} with this label already exists`)
      } else {
        setError(null)
      }
    } catch (err) {
      handleError(err)
    }
  }, [label, patientNomenclature])

  useEffect(() => {
    if (open) {
      setLabel('')
    }
  }, [open])

  useEffect(() => {
    if (!open) return
    if (!label) return
    const timeout = setTimeout(() => {
      checkIfPatientExists().catch(console.error)
    }, 400)
    return () => {
      clearTimeout(timeout)
    }
  }, [checkIfPatientExists, open, label])

  return (
    <ClosableDialog
      open={open}
      onClose={onClose}
      titleText={`Create New ${capitalize(patientNomenclature)}`}
    >
      <DialogContent>
        <TextField
          placeholder={`${capitalize(patientNomenclature)} Name...`}
          value={label}
          onChange={handleChangeLabel}
          fullWidth
          sx={{ mt: 1 }}
        />
        {error && (
          <Typography variant="body2" color="error" sx={{ mt: 1 }}>
            {error}
          </Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          variant="text"
          color="primary"
          onClick={handleCreatePatient}
          disabled={loading || !label}
        >
          Create New {capitalize(patientNomenclature)}
        </Button>
      </DialogActions>
    </ClosableDialog>
  )
}

export default CreatePatientDialog
