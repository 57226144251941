// Inline markdown parser to convert simple inline markdown to html
// Includes bold, italic, strikethrough, code, and links

const checked = ['✅', '⊗', '✓', '✔', '☑', '◉', '⭘']

const unchecked = ['☐', '🗸', '⍻', '🗹', '𐄂', '◯', '⬜']

const removeInvisibleCharacters = (input: string): string => {
  // Regular expression to match invisible characters
  const invisibleCharacters = /[\u200B-\u200D\uFEFF\u2060-\u206F]/g
  return input.replace(invisibleCharacters, '')
}

// Markdown to HTML
const inlineParser = (markdown: string, template?: boolean): string => {
  let result = markdown

  // Replace invisible characters
  result = removeInvisibleCharacters(result)

  result = result.replace(/\*\*(.*?)\*\*/g, '<b>$1</b>')
  result = result.replace(/\*(.*?)\*/g, '<em>$1</em>')
  result = result.replace(/~~(.*?)~~/g, '<del>$1</del>')
  result = result.replace(/`(.*?)`/g, '<code>$1</code>')
  result = result.replace(/\[(.*?)\]\((.*?)\)/g, '<a href="$2">$1</a>')
  // Parse italic
  result = result.replace(/__(.*?)__/g, '<em class="ci-italic">$1</em>')
  if (template) {
    result = result.split('[x]').join('☑')
    result = result.split('[‍‍‍x]').join('☑')
    result = result.split('[ ]').join('☐')
    result = result.split('[‍‍‍ ]').join('☐')
    result = result.split('[]').join('🗸')
    result = result.split('[‍‍‍]').join('🗸')
    result = result.replace(
      /\[(.*?)\]/g,
      '<mark class="cdx-ai" contenteditable="true">&zwj;$1</mark>'
    )
    result = result.split('☑').join('[x]')
    result = result.split('☐').join('[ ]')
    result = result.split('🗸').join('[]')
  }
  result = result.replace(/&nbsp;/g, ' ')
  checked.forEach((check) => {
    // result = result.split(check).join('<input class="cdx-inline-checkbox" type="checkbox" checked>')
    result = result.split(check).join('[x]')
  })
  unchecked.forEach((check) => {
    // result = result.split(check).join('<input class="cdx-inline-checkbox" type="checkbox">')
    result = result.split(check).join('[ ]')
  })

  return result
}

// HTML to markdown
export const reverseInlineParser = (html: string, template?: boolean): string => {
  let result = html

  result = result.replace(/\*\*\*\*/g, '')
  result = result.replace(/<b>(.*?)<\/b>/g, '**$1**')
  result = result.replace(/<i>(.*?)<\/i>/g, '**$1**')
  result = result.replace(/<em>(.*?)<\/em>/g, '*$1*')
  result = result.replace(/<del>(.*?)<\/del>/g, '~~$1~~')
  result = result.replace(/<code>(.*?)<\/code>/g, '`$1`')
  result = result.replace(/<a href="(.*?)">(.*?)<\/a>/g, '[$2]($1)')
  // Replace special html characters
  result = result.replace(/&zwj;/g, '')
  result = result.replace(/&nbsp;/g, ' ')
  result = result.replace(/&amp;/g, '&')
  result = result.replace(/&lt;/g, '<')
  result = result.replace(/&gt;/g, '>')
  result = result.replace(/&quot;/g, '"')
  result = result.replace(/&apos;/g, "'")

  // Remove invisible characters
  result = removeInvisibleCharacters(result)
  // Parse italic
  result = result.replace(/<em class="ci-italic">(.*?)<\/em>/g, '__$1__')
  if (template) {
    result = result.replace(
      /<mark class="cdx-ai" contenteditable="true">&zwj;(.*?)<\/mark>/g,
      '[$1]'
    )
    result = result.replace(/<mark class="cdx-ai">&zwj;(.*?)<\/mark>/g, '[$1]')
    result = result.replace(/<mark class="cdx-ai">(.*?)<\/mark>/g, '[$1]')
  }
  // Replace <br> with \n
  result = result.replace(/<br>/g, '')
  // Replace invisible characters
  result = result.replace(/​/g, '')
  // Replace inline checkboxes
  result = result.replace(/<input class="cdx-inline-checkbox" type="checkbox" checked>/g, '[x]')
  result = result.replace(/<input class="cdx-inline-checkbox" type="checkbox">/g, '[ ]')
  result = result.replace(/<input class="cdx-inline-checkbox">/g, '[ ]')
  // Replace any resulting html with empty string
  result = result.replace(/<[^>]*>/g, '')

  return result
}

export default inlineParser
