export const logGooglePageView = (path: string) => {
  try {
    // @ts-ignore
    gtag('event', 'page_view', {
      page_path: path,
    })
  } catch (err) {
    console.error(err)
  }
}

export const logGoogleEvent = (name: string, params?: any) => {
  try {
    // @ts-ignore
    gtag('event', name, params)
  } catch (err) {
    console.error(err)
  }
}

type LogGoogleSetUserDataOptions = {
  email: string
  phone_number?: string
}

export const googleSetUserData = ({ email, phone_number }: LogGoogleSetUserDataOptions) => {
  try {
    // @ts-ignore
    gtag('set', 'user_data', {
      email,
      phone_number,
    })
  } catch (err) {
    console.error(err)
  }
}
