import { isApiError } from '@counsel-project/client-utils'
import { ButtonProps } from '@mui/material/Button'
import React, { useCallback, useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { authRequest } from '../../util/api/auth-api'
import detectInAppBrowser from '../../util/detectInAppBrowser'
import log from '../../util/logging'
import GoogleButton from './GoogleButton'
import { redirectUri } from './util'

type GoogleLoginButtonProps = Omit<ButtonProps, 'onError'> & {
  redirectPage: string
  onError?: (err: string) => void
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void
  onBrowserError: () => void
}

const GoogleLoginButton = ({
  redirectPage,
  onError,
  onClick,
  onBrowserError,
  ...props
}: GoogleLoginButtonProps) => {
  const [authUrl, setAuthUrl] = useState('')
  const [searchParams] = useSearchParams()

  const autoLogin = searchParams.get('autoLogin') === 'google'

  const populateAuthUrl = useCallback(async () => {
    try {
      const data = await authRequest.user.google.getAuthUrl({
        redirectUri: encodeURIComponent(redirectUri),
        state: encodeURIComponent(JSON.stringify({ page: redirectPage || '/onboarding' })),
      })

      setAuthUrl(data.url)

      if (autoLogin) {
        window.location.href = data.url
      }
    } catch (err) {
      log.error(err)
      if (isApiError(err)) {
        onError?.(err.msg || err.message)
      }
    }
  }, [redirectPage, onError, autoLogin])

  useEffect(() => {
    populateAuthUrl()
  }, [populateAuthUrl])

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (detectInAppBrowser() !== 'other') {
      e.preventDefault()
      onBrowserError()
      return
    }

    onClick?.(e)
  }

  return (
    <GoogleButton
      href={authUrl}
      disabled={!authUrl || autoLogin}
      onClick={handleClick}
      {...props}
    />
  )
}

export default GoogleLoginButton
