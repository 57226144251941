import React from 'react'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import PreviousIcon from '@mui/icons-material/ArrowBackIosNewRounded'
import NextIcon from '@mui/icons-material/ArrowForwardIosRounded'
import CircularProgress from '@mui/material/CircularProgress'
import Box from '@mui/material/Box'

type SimplePaginationProps = {
  loading?: boolean
  disabled?: boolean
  total: number
  offset: number
  limit: number
  onClickNext: () => void
  onClickPrevious: () => void
}

const SimplePagination = ({
  loading,
  disabled,
  total,
  offset,
  limit,
  onClickNext,
  onClickPrevious,
}: SimplePaginationProps) => {
  return (
    <Grid container spacing={2} alignItems="center">
      <Grid item>
        <IconButton
          color="primary"
          onClick={onClickPrevious}
          disabled={offset === 0 || disabled || loading}
          sx={{ px: 4 }}
        >
          <PreviousIcon />
        </IconButton>
      </Grid>
      <Grid item flexGrow={1}>
        {loading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <CircularProgress size={24} />
          </Box>
        ) : (
          <Typography variant="body1" color="text.secondary" textAlign="center">
            {offset + 1} - {offset + limit} of {total}
          </Typography>
        )}
      </Grid>
      <Grid item>
        <IconButton
          color="primary"
          onClick={onClickNext}
          disabled={offset + limit >= total || disabled || loading}
          sx={{ px: 4 }}
        >
          <NextIcon />
        </IconButton>
      </Grid>
    </Grid>
  )
}

export default SimplePagination
