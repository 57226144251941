import { Subscription } from '@counsel-project/counsel-auth-api'
import CreditCardIcon from '@mui/icons-material/CreditCard'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import Link from '@mui/material/Link'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import { capitalize } from '@counsel-project/client-utils'
import React, { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTitle } from 'react-use'
import Breadcrumbs from '../../components/Breadcrumbs'
import PageContainer from '../../components/layout/PageContainer'
import { authRequest } from '../../util/api/auth-api'
import checkToken from '../../util/auth/checkToken'
import log from '../../util/logging'
import { getProductPrice } from '../purchase'

type SubscriptionCardProps = {
  subscription: Subscription
  onClickManage?: () => void
}

export const SubscriptionCard = ({ subscription, onClickManage }: SubscriptionCardProps) => {
  const navigate = useNavigate()

  const cardType =
    typeof subscription.default_payment_method !== 'string'
      ? subscription.default_payment_method?.card?.brand ??
        subscription.default_payment_method?.type ??
        'No Payment method on file'
      : 'No Payment method on file'
  const cardLast4 =
    typeof subscription.default_payment_method !== 'string'
      ? subscription.default_payment_method?.card?.last4 ?? null
      : null

  const subscriptionType = subscription.metadata.type as string

  const { status } = subscription

  const renewsOn = new Date(subscription.current_period_end * 1000)
  const cancelAtPeriodEnd = subscription.cancel_at_period_end
  const canceled = status === 'canceled' || cancelAtPeriodEnd
  const directoryId = subscription.metadata.directoryId as string
  const refunded = subscription.metadata.refunded === 'true'
  const quantity = subscription.items.data[0].quantity || 1
  const productId = subscription.items.data[0].price.product as string

  const handleNavOrg = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault()
    navigate(`/organization/${directoryId}`)
  }

  const annual = subscription.items.data[0].price.recurring?.interval === 'year'

  const totalPrice = subscription.items.data
    .map((i) => getProductPrice(i.price, i.quantity || 1).actualPrice)
    .reduce((a, b) => a + b, 0)

  const priceText = totalPrice
    ? totalPrice.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
      }) + (annual ? ' annually' : ' monthly')
    : 'Click "Manage" to view price'

  const onClickAddMembers = () => {
    navigate(`/subscriptions/update/${subscription.id}`)
  }

  return (
    <Paper variant="outlined" sx={{ p: 2 }}>
      <Grid container spacing={2} justifyContent="space-between">
        {cardType && (
          <Grid item xs={12} sm="auto">
            <Grid container spacing={2} alignItems="center">
              <Grid item>
                <CreditCardIcon />
              </Grid>
              <Grid item>
                <Typography variant="body1" fontWeight={500} fontSize={18}>
                  {capitalize(cardType)}
                </Typography>
                {cardLast4 && (
                  <Typography variant="body1" color="text.secondary">
                    Ending in {cardLast4}
                  </Typography>
                )}
              </Grid>
            </Grid>
            <Typography variant="body1" fontWeight={500} color="text.secondary">
              {priceText}
            </Typography>
            <Typography variant="body1" fontWeight={500} color="text.secondary">
              {quantity} {quantity > 1 ? 'members' : 'member'}
            </Typography>
            {onClickManage && (
              <Button
                color="secondary"
                variant="text"
                onClick={onClickManage}
                sx={{ mt: 1, mr: 2 }}
              >
                Manage
              </Button>
            )}
            {!refunded && !canceled && (
              <Button
                color="primary"
                variant="contained"
                onClick={onClickAddMembers}
                sx={{ mt: 1 }}
              >
                Upgrade Membership
              </Button>
            )}
          </Grid>
        )}
        <Grid item xs={12} sm="auto">
          <Typography variant="body1" fontWeight={500} fontSize={18} textAlign="end">
            {capitalize(subscriptionType)} Membership
          </Typography>
          {(subscription.status === 'active' || subscription.status === 'trialing') && !canceled ? (
            <Typography variant="body1" color="secondary.main" fontWeight={500} textAlign="end">
              Active
            </Typography>
          ) : canceled && cancelAtPeriodEnd ? (
            <Typography variant="body1" color="secondary.main" fontWeight={500} textAlign="end">
              Canceled. Ends on {renewsOn.toLocaleDateString()}
            </Typography>
          ) : (
            <Typography variant="body1" color="secondary.main" fontWeight={500} textAlign="end">
              {capitalize(subscription.status)}
            </Typography>
          )}
          {status === 'trialing' && (
            <Typography variant="body1" color="text.secondary" textAlign="end">
              Currently Trialing
            </Typography>
          )}
          {!canceled ? (
            <Typography variant="body1" color="text.secondary" textAlign="end">
              Renews on {renewsOn.toLocaleDateString()}
            </Typography>
          ) : cancelAtPeriodEnd ? (
            <Typography variant="body1" color="text.secondary" textAlign="end">
              Ends on {renewsOn.toLocaleDateString()}
            </Typography>
          ) : (
            <></>
          )}
          {directoryId && (
            <Typography variant="body1" color="text.secondary" textAlign="end">
              Applies to{' '}
              <Link href={`/organization/${directoryId}`} onClick={handleNavOrg}>
                this
              </Link>{' '}
              organization
            </Typography>
          )}
          <Typography variant="body1" color="text.secondary" textAlign="end">
            Billed {annual ? 'annually' : 'monthly'}
          </Typography>
          {refunded && (
            <Typography variant="body1" color="text.secondary" textAlign="end">
              Refunded
            </Typography>
          )}
          <Typography variant="body1" color="text.secondary" textAlign="end">
            ID: {subscription.id}
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  )
}

const SubscriptionsPage = () => {
  useTitle('Clinical Notes AI - Subscriptions')
  const navigate = useNavigate()

  const [subscriptions, setSubscriptions] = useState<Subscription[]>([])

  const handlePopulateSubscriptions = useCallback(async () => {
    try {
      await checkToken()

      const res = await authRequest.user.subscriptions.list({
        token: '',
      })

      setSubscriptions(res.subscriptions)
    } catch (err) {
      log.error(err)
    }
  }, [])

  useEffect(() => {
    handlePopulateSubscriptions()
  }, [handlePopulateSubscriptions])

  return (
    <>
      <PageContainer>
        <Breadcrumbs
          paths={[
            {
              name: 'Profile',
              path: '/profile',
            },
            {
              name: 'Subscriptions',
              path: '/subscriptions',
            },
          ]}
        />
        <Paper elevation={0} sx={{ p: 2 }}>
          <Typography variant="h5" sx={{ mb: 2 }}>
            Subscriptions
          </Typography>

          <Grid container spacing={2}>
            {subscriptions.map((subscription) => (
              <Grid item xs={12} key={subscription.id}>
                <SubscriptionCard
                  subscription={subscription}
                  onClickManage={() => navigate(`/subscriptions/${subscription.id}`)}
                />
              </Grid>
            ))}
          </Grid>
        </Paper>
      </PageContainer>
    </>
  )
}

export default SubscriptionsPage
