import { RPatientSession } from '@counsel-project/counsel-transcribe-api'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import { useState } from 'react'
import getShortSessionLabel from '../../components/forms/func/getShortSessionLabel'
import { usePatientNomenclature } from '../../util'
import { capitalize } from '@counsel-project/client-utils'
import BackIcon from '@mui/icons-material/ArrowBackRounded'
import MoveIcon from '@mui/icons-material/ForwardRounded'
import CloseIcon from '@mui/icons-material/CloseRounded'

type SessionHeadersProps = {
  patientSession: RPatientSession
  disabled?: boolean
  onClickBack: () => void
  onClickAssign: () => void
}

const SessionHeaders = ({
  patientSession,
  disabled,
  onClickBack,
  onClickAssign,
}: SessionHeadersProps) => {
  const [showAssignTooltip, setShowAssignTooltip] = useState(true)

  const shortSessionLabel = getShortSessionLabel(patientSession)

  const patientNomenclature = usePatientNomenclature()

  const handleClickBack = (e: React.MouseEvent) => {
    e.preventDefault()
    onClickBack()
  }

  return (
    <Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 4 }}>
      <Button
        href="/sessions"
        onClick={handleClickBack}
        startIcon={<BackIcon />}
        variant="outlined"
      >
        Back
      </Button>
      <Tooltip
        title={
          <Box sx={{ display: 'flex' }}>
            <Typography
              variant="body1"
              sx={{ px: 1 }}
            >{`This ${shortSessionLabel} is currently unassigned. Assign this to a ${patientNomenclature} to save it.`}</Typography>
            <IconButton
              sx={{ pointerEvents: 'all', color: 'white' }}
              onClick={() => setShowAssignTooltip(false)}
              size="small"
              disabled={disabled}
            >
              <CloseIcon />
            </IconButton>
          </Box>
        }
        arrow
        enterDelay={400}
        disableInteractive
        open={!patientSession?.patientLabel && showAssignTooltip && !disabled}
        color="secondary"
        onClose={() => setShowAssignTooltip(false)}
      >
        <Button
          onClick={onClickAssign}
          color="primary"
          disabled={disabled}
          endIcon={<MoveIcon />}
          variant={!patientSession?.patientLabel ? 'contained' : 'outlined'}
        >
          {!patientSession?.patientLabel
            ? `Assign To A ${capitalize(patientNomenclature)}`
            : `Move To ${patientNomenclature}`}
        </Button>
      </Tooltip>
    </Box>
  )
}

export default SessionHeaders
