import { RPatientSession } from '@counsel-project/counsel-transcribe-api'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import React from 'react'
import getSessionIcon from '../../components/forms/func/getSessionIcon'
import { getRelativeTime, usePatientNomenclature } from '../../util'
import IconImg from './IconImage'
import LiveSessionItem from './LiveSessionItem'
import SessionContainer from './SessionContainer'
import useUser from '../../util/auth/useUser'

export type SessionItemProps = {
  hidePatientLabel?: boolean
  session: RPatientSession
  href?: string
  onClick: (e: React.MouseEvent) => void
}

const SessionItem = ({ hidePatientLabel, session, href, onClick }: SessionItemProps) => {
  const [user] = useUser()

  const createdAt = new Date(session.startedAtString || session.createdAt)

  const timeSinceCreated = getRelativeTime((Date.now() - createdAt.getTime()) / 1000)

  const patientNomenclature = usePatientNomenclature()

  if (!session.note && !session.type) {
    return (
      <LiveSessionItem
        hidePatientLabel={hidePatientLabel}
        session={session}
        href={href}
        onClick={onClick}
      />
    )
  }

  return (
    <SessionContainer
      session={session}
      onClick={onClick}
      href={href}
      generating={session.state === 'generating'}
    >
      <Grid container spacing={2} alignItems="center" paddingX={2} marginBottom={4}>
        <Grid item xs={12} zeroMinWidth>
          <Grid container spacing={1} alignItems="center" sx={{ mb: 1 }} wrap="nowrap">
            <Grid item>
              <IconImg src={getSessionIcon(session)} alt="File" />
            </Grid>
            <Grid item zeroMinWidth>
              <Typography
                variant="body1"
                textOverflow="ellipsis"
                overflow="hidden"
                fontWeight={500}
                noWrap
              >
                {session.summary || 'Untitled'}
              </Typography>
              <Typography
                variant="body1"
                textOverflow="ellipsis"
                overflow="hidden"
                color="text.secondary"
                noWrap
              >
                {session.documentType || 'Document'}
              </Typography>
            </Grid>
          </Grid>
          <Typography
            variant="body2"
            color="text.secondary"
            textOverflow="ellipsis"
            overflow="hidden"
          >
            {createdAt.toLocaleDateString()} {createdAt.toLocaleTimeString()} ({timeSinceCreated}{' '}
            ago)
          </Typography>
          <Typography variant="body2" color="text.secondary" textOverflow="ellipsis" noWrap>
            {user?.email !== session.email ? <strong>{session.email}</strong> : 'Assigned to me'}
          </Typography>
          {!!session.patientLabel && !hidePatientLabel ? (
            <Typography
              variant="body2"
              color="text.secondary"
              textOverflow="ellipsis"
              overflow="hidden"
            >
              For{' '}
              <Typography
                variant="body2"
                component="span"
                textOverflow="ellipsis"
                overflow="hidden"
              >
                {session.patientLabel}
              </Typography>
            </Typography>
          ) : !hidePatientLabel ? (
            <Typography
              variant="body2"
              color="text.secondary"
              textOverflow="ellipsis"
              overflow="hidden"
            >
              No {patientNomenclature} assigned
            </Typography>
          ) : (
            <></>
          )}
        </Grid>
      </Grid>
    </SessionContainer>
  )
}

export default SessionItem
