import { RPatientSession } from '@counsel-project/counsel-transcribe-api'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import React, { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import getSessionIcon from '../../components/forms/func/getSessionIcon'
import { getRelativeTime, usePatientNomenclature } from '../../util'
import useUser from '../../util/auth/useUser'
import IconImg from './IconImage'
import SessionContainer from './SessionContainer'

export type SessionItemProps = {
  hidePatientLabel?: boolean
  session: RPatientSession
  href?: string
  onClick: (e: React.MouseEvent) => void
}

const LiveSessionItem = ({ hidePatientLabel, session, href, onClick }: SessionItemProps) => {
  const [user] = useUser()

  const navigate = useNavigate()

  const createdAt = new Date(session.startedAtString || session.createdAt)

  const timeSinceCreated = getRelativeTime((Date.now() - createdAt.getTime()) / 1000)

  const patientNomenclature = usePatientNomenclature()

  const duration = useMemo(() => {
    const { createdAt, startedAtString, startedAt, endedAtString, endedAt, duration, note } =
      session
    if (note) {
      return null
    }
    if (duration) {
      return getRelativeTime(duration)
    }
    const relTime = getRelativeTime(
      (new Date(endedAtString || endedAt || createdAt).getTime() -
        new Date(startedAtString || startedAt || createdAt).getTime()) /
        1000
    )
    if (relTime === 'now') {
      return 'No duration'
    }
    return relTime
  }, [session])

  const handleClickAssignedPatient = (e: React.MouseEvent) => {
    e.preventDefault()
    e.stopPropagation()
    navigate(`/client/${session.patientId}`)
  }

  return (
    <SessionContainer
      onClick={onClick}
      href={href}
      generating={session.state === 'generating'}
      session={session}
    >
      <Grid container spacing={2} alignItems="center" paddingX={2} marginBottom={4}>
        <Grid item xs={12} zeroMinWidth>
          <Grid container spacing={1} sx={{ mb: 1 }} wrap="nowrap">
            <Grid item>
              <IconImg src={getSessionIcon(session)} alt="File" />
            </Grid>
            <Grid item zeroMinWidth>
              <Typography
                variant="body1"
                textOverflow="ellipsis"
                overflow="hidden"
                fontWeight={500}
                noWrap
              >
                {session.dictation ? 'Dictation' : 'Live Session'}
              </Typography>
              {duration && (
                <Typography variant="body1" color="text.secondary">
                  {duration}
                </Typography>
              )}
            </Grid>
          </Grid>
          <Typography variant="body2" color="text.secondary">
            {createdAt.toLocaleDateString()} {createdAt.toLocaleTimeString()} ({timeSinceCreated}{' '}
            ago)
          </Typography>
          <Typography
            variant="body2"
            textOverflow="ellipsis"
            noWrap
            color={hidePatientLabel || session.email === user?.email ? 'text.secondary' : 'primary'}
          >
            {user?.email !== session.email ? <strong>{session.email}</strong> : 'Assigned to me'}
          </Typography>
          {!!session.patientLabel && !hidePatientLabel ? (
            <Typography variant="body2" color="text.secondary">
              For{' '}
              <Typography
                variant="body2"
                component="a"
                href="#"
                onClick={handleClickAssignedPatient}
                color="primary"
                sx={{
                  textDecoration: 'none',
                  ':hover': {
                    textDecoration: 'underline',
                  },
                }}
              >
                <strong>{session.patientLabel}</strong>
              </Typography>
            </Typography>
          ) : !hidePatientLabel ? (
            <Typography variant="body2" color="text.secondary">
              No {patientNomenclature} assigned
            </Typography>
          ) : (
            <></>
          )}
        </Grid>
      </Grid>
    </SessionContainer>
  )
}

export default LiveSessionItem
