import { ListLayoutsOptions, RLayout } from '@counsel-project/counsel-transcribe-api'
import { LayoutSortableFields } from '@counsel-project/counsel-transcribe-api/dist/common/database/RLayout'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { useCallback, useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import SearchField from '../../../components/SearchField'
import SelectableTab from '../../../components/SelectableTab'
import SimplePagination from '../../../components/forms/SimplePagination'
import PageContainer from '../../../components/layout/PageContainer'
import { transcribeRequest } from '../../../util/api/transcribe-api'
import { visionRequest } from '../../../util/api/vision-api'
import checkToken from '../../../util/auth/checkToken'
import useRequireAdmin from '../../../util/auth/useRequireAdmin'
import useUser from '../../../util/auth/useUser'
import handleError from '../../../util/handleError'
import AddLayoutBox from '../AddLayoutBox'
import AddLayoutDialog from '../AddLayoutDialog'
import CustomLayoutCard from '../CustomLayoutCard'
import LayoutGeneratingCard from '../LayoutGeneratingCard'
import UploadLayoutBox from '../UploadLayoutBox'
import AddFormLayoutBox from '../AddFormLayoutBox'
import AddFormLayoutDialog from '../AddFormLayoutDialog'
import EXAMPLE_FORM_LAYOUTS from '../example-form-layouts'

const AdminLayoutsPage = () => {
  const [searchParams, setSearchParams] = useSearchParams()

  const processIdsString = searchParams.get('processIds')
  const processIds = processIdsString ? processIdsString.split(',') : []

  const limit = 12
  const [layouts, setLayouts] = useState<RLayout[]>([])
  const [totalLayouts, setTotalLayouts] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const [addLayoutOpen, setAddLayoutOpen] = useState(false)
  const [addFormLayoutOpen, setAddFormLayoutOpen] = useState(false)
  const [search, setSearch] = useState('')
  const [offset, setOffset] = useState(0)
  const [sort, setSort] = useState<LayoutSortableFields>('createdAt')
  const [direction, setDirection] = useState<'asc' | 'desc'>('desc')
  const [viewMode, setViewMode] = useState<'public' | 'private'>('public')
  const [loading, setLoading] = useState(false)

  const [user] = useUser()

  const navigate = useNavigate()

  useRequireAdmin('/layouts')

  const onClickAddForm = useCallback(
    async (identifier?: string) => {
      try {
        const example = identifier
          ? EXAMPLE_FORM_LAYOUTS.find((i) => i.identifier === identifier)
          : undefined

        setLoading(true)

        await checkToken()

        const { result } = await transcribeRequest.layouts.create({
          token: '',
          name: example ? example.name : 'New Template',
          description: example ? example.description : 'Example Description',
          multiplePeople: false,
          requirePatientLabel: false,
          fields: example ? example.fields : [],
          maxDocuments: 3,
          maxNotes: 3,
          maxObservations: 3,
          tags: ['Custom'],
          allowDictation: true,
          allowTranscript: true,
          priority: 999,
          type: 'document',
          includeDictations: true,
          includeNotes: true,
          includeTranscripts: false,
          transferTranscripts: false,
          requiredLayouts: [],
          professions: [],
          dictationDescription: undefined,
          sessionDescription: undefined,
          documentInfo: '',
          source: 'user',
        })

        navigate(`/layouts/forms/${result._id}`)
      } catch (err) {
        handleError(err)
      } finally {
        setLoading(false)
      }
    },
    [navigate]
  )

  const populateLayouts = useCallback(async () => {
    try {
      setIsLoading(true)

      await checkToken()

      const searches: ListLayoutsOptions['search'] = {}

      if (viewMode === 'public') {
        searches.and = [{ email: null }]
      } else {
        searches.and = [{ email: null, $not: true }]
      }

      const { results, total } = await transcribeRequest.layouts.list({
        token: '',
        limit,
        sort,
        direction,
        offset,
        showAll: true,
        search: search
          ? {
              ...searches,
              or: [
                { name: search, $fuzzy: true },
                { directoryName: search, $fuzzy: true },
                { description: search, $fuzzy: true },
                { email: search, $fuzzy: true },
              ],
            }
          : searches,
      })

      setLayouts(results)
      setTotalLayouts(total)
    } catch (err) {
      handleError(err)
    } finally {
      setIsLoading(false)
    }
  }, [search, offset, sort, direction, viewMode])

  useEffect(() => {
    const timeout = setTimeout(() => {
      populateLayouts()
    }, 10)

    return () => clearTimeout(timeout)
  }, [populateLayouts])

  const onClickAdd = (identifier?: string) => {
    if (identifier) {
      navigate(`/layouts/new?identifier=${identifier}&admin=true`)
    } else {
      setAddLayoutOpen(false)
      navigate('/layouts/new?admin=true')
    }
  }

  const clickLayoutHandler = (layout: RLayout) => () => {
    navigate(layout.fields ? `/layouts/forms/${layout._id}` : `/layouts/admin/view/${layout._id}`)
  }

  const handlePopulateLayout = useCallback(
    (layout: RLayout, processId: string) => {
      setLayouts((layouts) => [
        layout,
        ...layouts.filter((l) => {
          return l._id !== layout._id
        }),
      ])
      // Remove the processId from the list
      setSearchParams((prev) => {
        const processIdsString = prev.get('processIds')
        const processIds = processIdsString ? processIdsString.split(',') : []
        prev.set('processIds', processIds.filter((id) => id !== processId).join(','))
        return prev
      })
    },
    [setSearchParams]
  )

  const handleUploadFile = useCallback(
    async (file: File) => {
      try {
        setIsLoading(true)

        await checkToken()

        const formData = new FormData()
        formData.append('file', file)

        const { processId } = await visionRequest.layouts.generate({ token: '', formData })

        setSearchParams((prev) => {
          const processIdsString = prev.get('processIds')
          const processIds = processIdsString ? processIdsString.split(',') : []
          prev.set('processIds', [...processIds, processId].join(','))
          return prev
        })
      } catch (err) {
        handleError(err)
      } finally {
        setIsLoading(false)
      }
    },
    [setSearchParams]
  )

  return (
    <PageContainer>
      <Grid container spacing={2} alignItems="stretch" sx={{ mb: 2 }}>
        <Grid item xs={12}>
          <Typography variant="h3" sx={{ mb: 1 }}>
            Admin Templates
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6" fontWeight={500}>
            Create a new template
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <AddLayoutBox onClick={() => setAddLayoutOpen(true)} />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <UploadLayoutBox onUpload={handleUploadFile} />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <AddFormLayoutBox onClick={() => setAddFormLayoutOpen(true)} />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6" fontWeight={500}>
            Edit an existing template ({totalLayouts})
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <SearchField onChange={setSearch} loading={isLoading} />
        </Grid>
        <Grid item container xs={12} spacing={2}>
          <Grid item>
            <SelectableTab
              small
              active={viewMode === 'public'}
              onClick={() => {
                setViewMode('public')
              }}
            >
              View Public
            </SelectableTab>
          </Grid>
          <Grid item>
            <SelectableTab
              small
              active={viewMode === 'private'}
              onClick={() => {
                setViewMode('private')
              }}
            >
              View Private
            </SelectableTab>
          </Grid>
        </Grid>
        <Grid item container xs={12} spacing={2}>
          <Grid item>
            <SelectableTab
              small
              active={sort === 'createdAt' && direction === 'desc'}
              onClick={() => {
                setSort('createdAt')
                setDirection('desc')
              }}
            >
              Newest
            </SelectableTab>
          </Grid>
          <Grid item>
            <SelectableTab
              small
              active={sort === 'createdAt' && direction === 'asc'}
              onClick={() => {
                setSort('createdAt')
                setDirection('asc')
              }}
            >
              Oldest
            </SelectableTab>
          </Grid>
        </Grid>
        {processIds.map((processId) => (
          <Grid item key={processId} xs={12} sm={6} md={4}>
            <LayoutGeneratingCard
              processId={processId}
              onFinished={(layout) => handlePopulateLayout(layout, processId)}
            />
          </Grid>
        ))}
        {layouts.map((layout) => (
          <Grid item key={layout._id} xs={12} sm={6} md={4}>
            <CustomLayoutCard
              isOwner={layout.email === user?.email}
              layout={layout}
              onClick={clickLayoutHandler(layout)}
              href={
                layout.fields ? `/layouts/forms/${layout._id}` : `/layouts/admin/view/${layout._id}`
              }
            />
          </Grid>
        ))}
      </Grid>
      <SimplePagination
        offset={offset}
        limit={limit}
        total={totalLayouts}
        loading={isLoading}
        onClickNext={() => setOffset((prev) => prev + limit)}
        onClickPrevious={() => setOffset((prev) => prev - limit)}
      />
      {layouts.length === 0 && !isLoading && (
        <Typography variant="body1" color="text.secondary" sx={{ mt: 2 }} fontStyle="italic">
          No custom templates found. Click the button above to create a new template.
        </Typography>
      )}
      <AddLayoutDialog
        open={addLayoutOpen}
        onClose={() => setAddLayoutOpen(false)}
        onAdd={onClickAdd}
      />
      <AddFormLayoutDialog
        open={addFormLayoutOpen}
        onClose={() => setAddFormLayoutOpen(false)}
        onAdd={onClickAddForm}
        loading={loading}
      />
    </PageContainer>
  )
}

export default AdminLayoutsPage
