import { RPatientSession } from '@counsel-project/counsel-transcribe-api'
import getSessionType from './getSessionType'

const getShortSessionLabel = (session: RPatientSession) => {
  const type = getSessionType(session)
  switch (type) {
    case 'document':
      return 'Document'
    case 'note':
      return 'Note'
    case 'observation':
      return 'Observation'
    case 'dictation':
      return 'Dictation'
    case 'live-session':
      return 'Live Session'
    default:
      return session.documentType || 'Note'
  }
}

export default getShortSessionLabel
