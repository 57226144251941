import { ApiError, request } from '@counsel-project/client-utils'
import {
  UserVerifyUpdateEmailOptions,
  UserVerifyUpdateEmailResponse,
  VerifyUserOptions,
  VerifyUserResponse,
} from '@counsel-project/counsel-auth-api'
import { setStore } from '../store/auth'
import { posthogIdentify } from '../tracking/posthog'

const url = process.env.NODE_ENV === 'development' ? 'http://localhost:3001/api' : '/api'

type VerifyResponse = Omit<VerifyUserResponse, 'token' | 'refreshToken'>

const registerVerify = async (options: VerifyUserOptions): Promise<VerifyResponse> => {
  const response = await request({
    method: 'POST',
    url: url + '/verify',
    body: options,
    withCredentials: true,
  })

  if (response.status !== 200) {
    throw new ApiError('Invalid Credentials')
  }

  const { user } = response.body

  if (user) {
    setStore({
      user,
    })

    posthogIdentify(
      user._id,
      user.email || '',
      user.firstName && user.lastName ? `${user.firstName} ${user.lastName}` : user.firstName || ''
    )
  }

  return response.body
}

type VerifyUpdateEmailResponse = Omit<UserVerifyUpdateEmailResponse, 'token' | 'refreshToken'>

const verifyUpdateEmail = async (
  options: Omit<UserVerifyUpdateEmailOptions, 'token' | 'refreshToken'>
): Promise<VerifyUpdateEmailResponse> => {
  const response = await request<
    Omit<UserVerifyUpdateEmailOptions, 'token' | 'refreshToken'>,
    VerifyUpdateEmailResponse
  >({
    method: 'POST',
    url: url + '/account/verify-update-email',
    body: options,
    withCredentials: true,
  })

  if (response.status !== 200) {
    throw new ApiError('Invalid Credentials')
  }

  const { user } = response.body

  if (user) {
    setStore({
      user,
    })

    posthogIdentify(
      user._id,
      user.email || '',
      user.firstName && user.lastName ? `${user.firstName} ${user.lastName}` : user.firstName || ''
    )
  }

  return response.body
}

export { registerVerify, verifyUpdateEmail }
