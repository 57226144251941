import { LabeledTextField } from '@counsel-project/components'
import { UserAction } from '@counsel-project/counsel-transcribe-api'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { formatDuration } from '../../util'
import { transcribeRequest } from '../../util/api/transcribe-api'
import checkToken from '../../util/auth/checkToken'
import handleError from '../../util/handleError'

type MetricsSavingsCalculatorProps = {
  directoryId?: string
  startDate?: Date | null
  endDate?: Date | null
}

const MetricsSavingsCalculator = ({
  directoryId,
  startDate,
  endDate,
}: MetricsSavingsCalculatorProps) => {
  const [hourlyStaffCost, setHourlyStaffCost] = useState(150)
  const [secondsPerDocument, setSecondsPerDocument] = useState(20 * 60)
  const [secondsPerEdit, setSecondsPerEdit] = useState(5 * 60)

  const [actions, setActions] = useState<UserAction[]>([])

  const populateActions = useCallback(async () => {
    try {
      await checkToken()

      if (directoryId) {
        const { results } = await transcribeRequest.metrics.directory.count({
          token: '',
          directoryId,
          actions: ['generate-document', 'edit-document-with-ai'],
          startDate: startDate?.toISOString(),
          endDate: endDate?.toISOString(),
        })
        setActions(results.flatMap((r) => r.actions))
      } else {
        const { results } = await transcribeRequest.metrics.user.count({
          token: '',
          actions: ['generate-document', 'edit-document-with-ai'],
          startDate: startDate?.toISOString(),
          endDate: endDate?.toISOString(),
        })
        setActions(results)
      }
    } catch (err) {
      handleError(err)
    }
  }, [directoryId, startDate, endDate])

  useEffect(() => {
    const timeout = setTimeout(populateActions, 10)
    return () => clearTimeout(timeout)
  }, [populateActions])

  const documentSeconds = useMemo(() => {
    const filtered = actions.filter((a) => a.action === 'generate-document')
    const secondsSaved = filtered.reduce((acc, a) => acc + a.count * secondsPerDocument, 0)
    return secondsSaved
  }, [actions, secondsPerDocument])

  const editSeconds = useMemo(() => {
    const filtered = actions.filter((a) => a.action === 'edit-document-with-ai')
    const secondsSaved = filtered.reduce((acc, a) => acc + a.count * secondsPerEdit, 0)
    return secondsSaved
  }, [actions, secondsPerEdit])

  const editCost = useMemo(() => {
    return editSeconds * (hourlyStaffCost / 3600)
  }, [editSeconds, hourlyStaffCost])

  const documentCost = useMemo(() => {
    return documentSeconds * (hourlyStaffCost / 3600)
  }, [documentSeconds, hourlyStaffCost])

  const totalSavings = useMemo(() => {
    return documentCost + editCost
  }, [documentCost, editCost])

  return (
    <Grid container spacing={2} alignItems="end">
      <Grid item xs={12}>
        <Typography variant="body1" fontStyle="italic" color="text.secondary">
          Disclaimer: Data before Oct 26, 2024 is not available
        </Typography>
      </Grid>
      {startDate && endDate && (
        <Grid item xs={12}>
          <Typography variant="body1">
            From {startDate?.toLocaleDateString()} to {endDate?.toLocaleDateString()}
          </Typography>
        </Grid>
      )}
      <Grid item xs={12} sm={6} md={4}>
        <LabeledTextField
          sx={{ mt: 2 }}
          label={directoryId ? 'Staff Cost Per Hour' : 'Cost Per Hour'}
          description="Average hourly rate of you or your staff"
          value={hourlyStaffCost}
          onChange={(e) => setHourlyStaffCost(Number(e.target.value))}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <LabeledTextField
          label="Average Minutes Spent Charting"
          description="Average minutes it takes you to normally write a note/document"
          value={secondsPerDocument / 60}
          onChange={(e) => setSecondsPerDocument(Number(e.target.value) * 60)}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4}>
        <LabeledTextField
          label="Average Minutes Spent Editing"
          description="Average minutes it takes you to normally edit a document or note"
          value={secondsPerEdit / 60}
          onChange={(e) => setSecondsPerEdit(Number(e.target.value) * 60)}
        />
      </Grid>
      <Grid item xs={6}>
        <Paper sx={{ p: 2, backgroundColor: 'background.default' }}>
          <Typography variant="h6">Charting Time Saved</Typography>
          <Typography variant="h6" color="text.secondary">
            {formatDuration(documentSeconds)}
          </Typography>
        </Paper>
      </Grid>
      <Grid item xs={6}>
        <Paper sx={{ p: 2, backgroundColor: 'background.default' }}>
          <Typography variant="h6">Editing Time Saved</Typography>
          <Typography variant="h6" color="text.secondary">
            {formatDuration(editSeconds)}
          </Typography>
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Paper sx={{ p: 2, backgroundColor: 'background.default' }}>
          <Typography variant="h6">Total Savings</Typography>
          <Typography variant="h6" color="primary">
            ${totalSavings.toFixed(2)}
          </Typography>
        </Paper>
      </Grid>
    </Grid>
  )
}

export default MetricsSavingsCalculator
