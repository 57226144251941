import { RIntegrationTemplate } from '@counsel-project/counsel-ehr-api'
import Typography from '@mui/material/Typography'
import StyledTableCell from './StyledTableCell'
import StyledTableRow from './StyledTableRow'
import Switch from '@mui/material/Switch'
import IconButton from '@mui/material/IconButton'
import EditIcon from '@mui/icons-material/Edit'

export type IntegrationTemplateRowProps = {
  isMobile?: boolean
  data: RIntegrationTemplate
  onClick?: (data: RIntegrationTemplate) => void
  onClickNavigate?: (data: RIntegrationTemplate) => void
  onToggleEnabled?: (data: RIntegrationTemplate) => void
}

const IntegrationTemplateRow = ({
  isMobile,
  data,
  onClick,
  onClickNavigate,
  onToggleEnabled,
}: IntegrationTemplateRowProps) => {
  const { name, enabled } = data

  const handleToggleEnabled = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault()
    e.stopPropagation()
    onToggleEnabled?.(data)
  }

  const handleClickNavigate = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    e.stopPropagation()
    onClickNavigate?.(data)
  }

  return (
    <StyledTableRow onClick={() => onClick?.(data)}>
      <StyledTableCell
        sx={{
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          maxWidth: 400,
        }}
      >
        <Typography
          variant="subtitle2"
          textOverflow="ellipsis"
          overflow="hidden"
          whiteSpace="nowrap"
        >
          {name}
        </Typography>
      </StyledTableCell>
      {!isMobile && (
        <StyledTableCell align="right">
          <IconButton onClick={handleClickNavigate} size="small" sx={{ mr: 1 }} disabled={!enabled}>
            <EditIcon />
          </IconButton>
          <Switch checked={enabled || false} onChange={handleToggleEnabled} />
        </StyledTableCell>
      )}
    </StyledTableRow>
  )
}

export default IntegrationTemplateRow
