import { create } from 'zustand'
import { persist, createJSONStorage } from 'zustand/middleware'
import localStateStorage from './localStorage'
import { PreferencesStore } from './slices/preferences'
import createPreferencesSlice from './slices/preferences'

const usePreferencesStore = create<PreferencesStore>()(
  persist(
    (...a) => ({
      ...createPreferencesSlice(...a),
    }),
    {
      name: 'app-storage',
      storage: createJSONStorage(() => localStateStorage),
      partialize: (state: PreferencesStore) => ({
        options: state.options,
      }),
    }
  )
)

export const getPreferencesStore = () => usePreferencesStore.getState()

export const setPreferencesStore = (state: Partial<PreferencesStore>) =>
  usePreferencesStore.setState(state)

export default usePreferencesStore
