import { RUser, UserSortableFields } from '@counsel-project/counsel-auth-api'
import Paper from '@mui/material/Paper'
import { useCallback, useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useTitle } from 'react-use'
import PageContainer from '../../components/layout/PageContainer'
import UserTable from '../../components/tables/UserTable'
import { authRequest } from '../../util/api/auth-api'
import checkToken from '../../util/auth/checkToken'
import useRequireAdmin from '../../util/auth/useRequireAdmin'
import UserDialog from './UserDialog'
import AddBannerDialog from './AddBannerDialog'
import Button from '@mui/material/Button'

const AdminUsersPage = () => {
  useTitle('Clinical Notes AI - Admin Transcripts')
  useRequireAdmin('/')

  const [searchParams, setSearchParams] = useSearchParams()

  const [loading, setLoading] = useState(true)
  const [users, setUsers] = useState<RUser[]>([])
  const [limit, setLimit] = useState(10)
  const [page, setPage] = useState(0)
  const [total, setTotal] = useState(0)
  const [userOpen, setUserOpen] = useState(false)
  const [selectedUser, setSelectedUser] = useState<RUser | null>(null)
  const [sort, setSort] = useState<UserSortableFields>('email')
  const [direction, setDirection] = useState<'asc' | 'desc'>('asc')
  const [bannerOpen, setBannerOpen] = useState(false)

  const handleChangePage = (newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (newRowsPerPage: number) => {
    setLimit(newRowsPerPage)
  }

  const populateUsers = useCallback(async () => {
    try {
      await checkToken()

      setLoading(true)

      const { results, total } = await authRequest.admin.users.list({
        token: '',
        limit,
        offset: page * limit,
        search: searchParams.get('search')
          ? {
              and: [{ email: searchParams.get('search'), $fuzzy: true }],
            }
          : undefined,
        sort,
        direction,
      })

      setUsers(results)
      setTotal(total)
    } catch (err) {
      console.error(err)
    } finally {
      setLoading(false)
    }
  }, [limit, page, searchParams, sort, direction])

  useEffect(() => {
    const timeout = setTimeout(() => {
      populateUsers()
    }, 10)
    return () => clearTimeout(timeout)
  }, [populateUsers])

  const handleSubmitSearch = (search: string) => {
    setSearchParams((prev) => {
      prev.set('search', search)
      return prev
    })
    setPage(0)
  }

  const handleClickRow = (row: RUser) => {
    setSelectedUser(row)
    setUserOpen(true)
  }

  const handleCloseUser = () => {
    setUserOpen(false)
  }

  const handleUpdateUser = (newUser: RUser) => {
    setUsers((prev) => prev.map((u) => (u._id === newUser._id ? newUser : u)))
  }

  return (
    <PageContainer>
      <Paper sx={{ p: 2 }} elevation={0}>
        <UserTable
          onChangeSort={(s, d) => {
            setSort(s)
            setDirection(d)
          }}
          sort={sort}
          direction={direction}
          loaded={!loading}
          rows={users}
          page={page}
          onSubmitSearch={handleSubmitSearch}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          onClickRow={handleClickRow}
          rowsPerPage={limit}
          rowsPerPageOptions={[10, 25, 50]}
          total={total}
        />
        <Button onClick={() => setBannerOpen(true)}>Add/View Site Banner</Button>
      </Paper>
      {selectedUser && (
        <UserDialog
          onUpdateUser={handleUpdateUser}
          open={userOpen}
          onClose={handleCloseUser}
          user={selectedUser}
        />
      )}
      <AddBannerDialog open={bannerOpen} onClose={() => setBannerOpen(false)} onAdded={() => {}} />
    </PageContainer>
  )
}

export default AdminUsersPage
