import { DirectorySortableFields, RDirectory, RUser } from '@counsel-project/counsel-auth-api'
import Button from '@mui/material/Button'
import DialogContent from '@mui/material/DialogContent'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTitle } from 'react-use'
import ClosableDialog from '../../components/ClosableDialog'
import ConfirmDialog from '../../components/ConfirmDialog'
import AdminUserSelector from '../../components/selectors/AdminUserSelector'
import PageContainer from '../../components/layout/PageContainer'
import DirectoryTable from '../../components/tables/DirectoryTable'
import { authRequest } from '../../util/api/auth-api'
import checkToken from '../../util/auth/checkToken'
import useRequireAdmin from '../../util/auth/useRequireAdmin'

const Organizations = () => {
  useTitle('Clinical Notes AI - Admin Organizations')
  useRequireAdmin('/')

  const navigate = useNavigate()

  const [loading, setLoading] = useState(true)
  const [directories, setDirectories] = useState<RDirectory[]>([])
  const [total, setTotal] = useState(0)
  const [limit, setLimit] = useState(10)
  const [page, setPage] = useState(0)
  const [sort, setSort] = useState<DirectorySortableFields>('ownerEmail')
  const [direction, setDirection] = useState<'asc' | 'desc'>('asc')
  const [search, setSearch] = useState('')

  const [createOpen, setCreateOpen] = useState(false)
  const [stagedName, setStagedName] = useState('')
  const [stagedDescription, setStagedDescription] = useState('')
  const [stagedUser, setStagedUser] = useState<RUser | null>(null)

  const [editOpen, setEditOpen] = useState(false)
  const [activeDirectory, setActiveDirectory] = useState<RDirectory | null>(null)

  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false)

  const handleToggleDeleteOpen = () => {
    setConfirmDeleteOpen((prev) => !prev)
  }

  const handleToggleCreateOpen = () => {
    setStagedName('')
    setStagedDescription('')
    setStagedUser(null)
    setCreateOpen((prev) => !prev)
  }

  const handleChangePage = (newPage: number) => {
    setPage(newPage)
  }

  const handleChangeRowsPerPage = (newRowsPerPage: number) => {
    setLimit(newRowsPerPage)
  }

  const handleChangeSort = (newSort: DirectorySortableFields, newDirection: 'asc' | 'desc') => {
    setSort(newSort)
    setDirection(newDirection)
  }

  const populateDirectories = useCallback(async () => {
    try {
      await checkToken()

      setLoading(true)

      const data = await authRequest.admin.directories.list({
        token: '',
        limit,
        offset: page * limit,
        sort,
        direction,
        search: search
          ? {
              or: [
                { name: search, $fuzzy: true },
                { ownerEmail: search, $fuzzy: true },
              ],
            }
          : undefined,
      })

      setDirectories(data.results)
      setTotal(data.total)
    } catch (err) {
      console.error(err)
    } finally {
      setLoading(false)
    }
  }, [limit, page, sort, direction, search])

  useEffect(() => {
    populateDirectories()
  }, [populateDirectories])

  const handleCreateDirectory = useCallback(async () => {
    try {
      await checkToken()
      if (!stagedUser) return

      setLoading(true)

      await authRequest.admin.directories.create({
        token: '',
        name: stagedName,
        description: !stagedDescription ? undefined : stagedDescription,
        ownerId: stagedUser?._id,
      })

      populateDirectories()
      setCreateOpen(false)
    } catch (err) {
      console.error(err)
    } finally {
      setLoading(false)
    }
  }, [stagedName, stagedDescription, stagedUser, populateDirectories])

  const handleUpdateDirectory = useCallback(async () => {
    try {
      await checkToken()
      if (!activeDirectory) return

      setLoading(true)

      await authRequest.admin.directories.update({
        token: '',
        directoryId: activeDirectory?._id,
        name: stagedName,
        description: stagedDescription,
        ownerId: stagedUser?._id,
      })

      populateDirectories()
      setEditOpen(false)
    } catch (err) {
      console.error(err)
    } finally {
      setLoading(false)
    }
  }, [activeDirectory, stagedName, stagedDescription, stagedUser, populateDirectories])

  const handleClickRow = (row: RDirectory) => {
    setActiveDirectory(row)
    setEditOpen(true)
    setStagedName(row.name)
    setStagedDescription(row.description ?? '')
    if (row.ownerId) {
      setStagedUser({
        _id: row.ownerId,
        email: row.ownerEmail,
        password: true,
        enabled: true,
        createdAt: row.createdAt,
      })
    }
  }

  const handleNavControlPanel = () => {
    if (!activeDirectory) return
    navigate(`/organization/${activeDirectory._id}`)
  }

  const handleDeleteDirectory = useCallback(async () => {
    try {
      await checkToken()
      if (!activeDirectory) return

      setLoading(true)

      await authRequest.admin.directories.delete({
        token: '',
        directoryId: activeDirectory?._id,
      })

      populateDirectories()
      setEditOpen(false)
      setConfirmDeleteOpen(false)
    } catch (err) {
      console.error(err)
    } finally {
      setLoading(false)
    }
  }, [activeDirectory, populateDirectories])

  return (
    <PageContainer>
      <Paper sx={{ p: 2 }} elevation={0}>
        <Button onClick={handleToggleCreateOpen} sx={{ mb: 2 }}>
          Add Org
        </Button>
        <DirectoryTable
          loaded={!loading}
          rows={directories}
          total={total}
          page={page}
          onSubmitSearch={setSearch}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          onChangeSort={handleChangeSort}
          onClickRow={handleClickRow}
          rowsPerPage={limit}
          rowsPerPageOptions={[10, 25, 50]}
          sort={sort}
          direction={direction}
        />
      </Paper>
      <ClosableDialog
        open={createOpen}
        onClose={handleToggleCreateOpen}
        titleText="Create Organization"
      >
        <DialogContent>
          <Grid container spacing={2} sx={{ mt: 1 }}>
            <Grid item xs={12}>
              <Typography variant="body2" sx={{ mb: 1 }} color="text.secondary" fontWeight={500}>
                Select an Owner User
              </Typography>
              <AdminUserSelector
                value={stagedUser}
                onChange={setStagedUser}
                fullWidth
                disabled={loading}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body2" sx={{ mb: 1 }} color="text.secondary" fontWeight={500}>
                Org Name
              </Typography>
              <TextField
                fullWidth
                value={stagedName}
                onChange={(e) => {
                  setStagedName(e.target.value)
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body2" sx={{ mb: 1 }} color="text.secondary" fontWeight={500}>
                Org Description
              </Typography>
              <TextField
                fullWidth
                value={stagedDescription}
                onChange={(e) => {
                  setStagedDescription(e.target.value)
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Button fullWidth onClick={handleCreateDirectory} disabled={loading}>
                Create
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </ClosableDialog>
      <ClosableDialog
        open={editOpen}
        onClose={() => {
          setEditOpen(false)
        }}
        titleText="Edit Org"
        fullScreenOnMobile
      >
        <DialogContent>
          <Grid container spacing={2} sx={{ mt: 1 }}>
            <Grid item xs={12}>
              <Typography variant="body2" sx={{ mb: 1 }} color="text.secondary" fontWeight={500}>
                Name
              </Typography>
              <TextField
                fullWidth
                value={stagedName}
                onChange={(e) => {
                  setStagedName(e.target.value)
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body2" sx={{ mb: 1 }} color="text.secondary" fontWeight={500}>
                Description
              </Typography>
              <TextField
                fullWidth
                value={stagedDescription}
                onChange={(e) => {
                  setStagedDescription(e.target.value)
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <Typography variant="body2" sx={{ mb: 1 }} color="text.secondary" fontWeight={500}>
                Owner
              </Typography>
              <AdminUserSelector
                value={stagedUser}
                onChange={setStagedUser}
                fullWidth
                disabled={loading}
              />
            </Grid>

            <Grid item xs={12}>
              <Button fullWidth onClick={handleUpdateDirectory} disabled={loading}>
                Update
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                fullWidth
                onClick={handleNavControlPanel}
                disabled={loading}
                color="secondary"
              >
                Control Panel
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button fullWidth onClick={handleToggleDeleteOpen} disabled={loading} color="error">
                Delete
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </ClosableDialog>
      <ConfirmDialog
        open={confirmDeleteOpen}
        onClose={handleToggleDeleteOpen}
        onConfirm={handleDeleteDirectory}
        titleText="Delete Org"
        text="Delete organization?"
      />
    </PageContainer>
  )
}

export default Organizations
