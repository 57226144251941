import { RLicense } from '@counsel-project/counsel-auth-api'
import Typography from '@mui/material/Typography'
import { useMemo } from 'react'
import StyledTableCell from './StyledTableCell'
import StyledTableRow from './StyledTableRow'

export type LicenseRowProps = {
  isMobile?: boolean
  data: RLicense
  onClick?: (data: RLicense) => void
}

const LicenseRow = ({ isMobile, data, onClick }: LicenseRowProps) => {
  const { type, userEmail, directoryName, directoryId } = data

  const displayName = useMemo(() => {
    return `${directoryName ?? directoryId ?? userEmail} (${type})`
  }, [userEmail, directoryName, type, directoryId])

  const now = new Date()
  const expiresAt = new Date(data.expiresAt)
  const createdAt = new Date(data.createdAt)
  const { enabled } = data

  return (
    <StyledTableRow onClick={() => onClick?.(data)}>
      <StyledTableCell
        sx={{
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          maxWidth: 400,
        }}
      >
        <Typography
          variant="subtitle2"
          textOverflow="ellipsis"
          overflow="hidden"
          whiteSpace="nowrap"
        >
          {displayName}
        </Typography>
      </StyledTableCell>
      {!isMobile && (
        <StyledTableCell align="right">
          <Typography
            variant="subtitle2"
            textOverflow="ellipsis"
            overflow="hidden"
            whiteSpace="nowrap"
            color="inherit"
          >
            {createdAt.toLocaleDateString()}
          </Typography>
        </StyledTableCell>
      )}
      {!isMobile && (
        <StyledTableCell align="right">
          <Typography
            variant="subtitle2"
            textOverflow="ellipsis"
            overflow="hidden"
            whiteSpace="nowrap"
            color={expiresAt < now ? 'error.main' : 'inherit'}
          >
            {expiresAt.toLocaleDateString()}
          </Typography>
        </StyledTableCell>
      )}
      {!isMobile && (
        <StyledTableCell align="right">
          <Typography
            variant="subtitle2"
            textOverflow="ellipsis"
            overflow="hidden"
            whiteSpace="nowrap"
            color={!enabled ? 'error.main' : 'inherit'}
          >
            {enabled ? 'Yes' : 'No'}
          </Typography>
        </StyledTableCell>
      )}
    </StyledTableRow>
  )
}

export default LicenseRow
