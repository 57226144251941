import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import getUser from './getUser'

const useRequireAdmin = (altPage: string) => {
  const navigate = useNavigate()

  useEffect(() => {
    const user = getUser()

    if (!user) {
      navigate(altPage)
      return
    }

    if (!user.admin) {
      navigate(altPage)
    }
  }, [navigate, altPage])
}

export default useRequireAdmin
