import Cookies from 'js-cookie'

export const privacyPolicyUpdatedVersion = '01/07/2024'

export const getIsPrivacyPolicyAccepted = () => {
  const acceptedPrivacyPolicyVersion = Cookies.get('acceptedPrivacyPolicyVersion')
  return acceptedPrivacyPolicyVersion === privacyPolicyUpdatedVersion
}

export const acceptPrivacyPolicy = () => {
  Cookies.set('acceptedPrivacyPolicyVersion', privacyPolicyUpdatedVersion)
}
