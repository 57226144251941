import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import { useTitle } from 'react-use'
import PageContainer from '../components/layout/PageContainer'
import { useViewContent } from '../util/tracking'
import Box from '@mui/material/Box'
import List from '@mui/material/List'
import ListItemText from '@mui/material/ListItemText'
import ListItemButton from '@mui/material/ListItemButton'
import Link from '@mui/material/Link'
import { useEffect } from 'react'

const HelpPage = () => {
  useViewContent('Help')
  useTitle('Clinical Notes AI - Help')

  useEffect(() => {
    // Scroll to the hash in the URL
    const hash = window.location.hash
    if (hash) {
      const element = document.querySelector(hash)
      if (element) {
        element.scrollIntoView && element.scrollIntoView()
      }
    }
  }, [])

  return (
    <PageContainer>
      <Paper elevation={0} sx={{ mb: 2, p: 2 }}>
        <Grid container alignItems="center" justifyContent="center" spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h4" textAlign="center">
              Support
            </Typography>
          </Grid>
          <Grid item>
            <Typography
              variant="body1"
              textAlign="center"
              component="a"
              href="https://share.hsforms.com/1iyg6_jCETOmsTFny8Y299wqkiqd"
              target="_blank"
              rel="noopener noreferrer"
            >
              You can reach us here with any questions or concerns.
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="body1" textAlign="center">
              Cant find what you're looking for? Email us at{' '}
              <Link component="a" href={'mailto:support@clinicalnotes.ai'}>
                support@clinicalnotes.ai
              </Link>{' '}
            </Typography>
            <Typography variant="body1" textAlign="center">
              Thank you for using Clinical Notes AI! Your feedback is greatly appreciated!
            </Typography>
          </Grid>
        </Grid>
      </Paper>
      <Paper elevation={0}>
        <Grid container p={3} alignItems="center" justifyContent="center">
          <Typography variant="h2" textAlign="center">
            Tutorials
          </Typography>
          <Grid item xs={12}>
            <List>
              <ListItemButton href="#menuOptions" style={{ textDecoration: 'underline' }}>
                Menu Options
              </ListItemButton>
              <ListItemButton href="#telehealthCapture" style={{ textDecoration: 'underline' }}>
                Telehealth Note Capture
              </ListItemButton>
              <ListItemButton href="#capturingSessions" style={{ textDecoration: 'underline' }}>
                Progress Note Creation
              </ListItemButton>
              <ListItemButton href="#progressNote" style={{ textDecoration: 'underline' }}>
                Creating a Group Note
              </ListItemButton>
              <ListItemButton href="#exportNote" style={{ textDecoration: 'underline' }}>
                Exporting a Note or Document
              </ListItemButton>
              <ListItemButton href="#documentContext" style={{ textDecoration: 'underline' }}>
                Creating a Document with Context
              </ListItemButton>
              <ListItemButton href="#multipleGenerate" style={{ textDecoration: 'underline' }}>
                Generating Multiple Documents Simultaneously
              </ListItemButton>
              <ListItemButton href="#accountMembers" style={{ textDecoration: 'underline' }}>
                Associating Members with your Account
              </ListItemButton>
              <ListItemButton href="#talkToAi" style={{ textDecoration: 'underline' }}>
                Talk To AI Feature
              </ListItemButton>
            </List>
          </Grid>

          <Grid item xs={12}>
            <Typography id="menuOptions" variant="h4" textAlign="center" sx={{ mb: 1 }}>
              Menu Options
            </Typography>
            <Box
              sx={{ position: 'relative', paddingBottom: '56.25%', height: 0, overflow: 'hidden' }}
            >
              <iframe
                title="Menu Options"
                src="https://www.loom.com/embed/129a861f63f94939bbc7c535f8c2e6bb?sid=a39fb1a1-1bb1-48dd-b908-f682e831e5bd"
                allowFullScreen
                style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
              ></iframe>
            </Box>
            <List>
              <ListItemText primary="1. The homepage is where you can begin generating a note or a document. You can access the homepage anywhere on the website by clicking on the logo text." />
              <ListItemText primary="2. The Documents tab will show you the options for each document type." />
              <ListItemText primary="3. The My Clients tab will show each of your clients and the unassigned notes/documents you have created." />
              <ListItemText primary="4. The Support tab will allow you to email our support for any questions or concerns." />
              <ListItemText primary="5. The Pricing tab will allow you to upgrade or change the plan you have." />
              <ListItemText primary="6. At the very right of the navigation bar, you can see your icon. If you click on it, a dropdown menu will appear." />
              <ListItemText primary="7. The dropdown menu includes your Profile, Billing, your Organization, the Changelog, a download for the patient Consent Document, and a download for the BAA." />
              <ListItemText primary="8. On your profile, you can view or change your information and add alternative emails." />
              <ListItemText primary="9. The billing section allows you to upgrade or look at the existing subscription you have." />
              <ListItemText primary="10. The My Organization page will show you your organization. If you have an Enterprise license, this is where you can add new members to your organization." />
            </List>
          </Grid>
          <Grid item xs={12}>
            <Typography id="telehealthCapture" variant="h4" textAlign="center" sx={{ mb: 1 }}>
              How to Get Clinical Notes AI to capture Telehealth Conversations
            </Typography>
            <Box
              sx={{ position: 'relative', paddingBottom: '56.25%', height: 0, overflow: 'hidden' }}
            >
              <iframe
                title="Telehealth Capture"
                src="https://www.loom.com/embed/e13aeff443d54032b19c66bd8f4173e9?sid=2b1136ba-9bc0-4443-96a2-0988ba334670"
                allowFullScreen
                style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
              ></iframe>
            </Box>
            <Typography>
              Note: Only Chrome browser telehealth applications can be recorded. Currently,
              recording on an external application outside of Chrome is unavailable.
            </Typography>
            <List>
              <ListItemText primary="1. Select Generate Note or Generate Document." />
              <ListItemText primary="2. Select the Live Session option for your recording." />
              <ListItemText primary="3. Select your microphone." />
              <ListItemText primary="4. Check the box which says Use browser audio (For telehealth)." />
              <ListItemText primary="5. Select the Chrome Tab which has the meeting. You cannot capture audio by recording a Window or your Entire Screen." />
              <ListItemText primary="6. At the bottom of the popup, be sure to toggle the “Also share tab audio” in order to record the session." />
              <ListItemText primary="7. Record your session. Press Finish when you are done." />
              <ListItemText primary="8. Allow desktop audio recording on telehealth application of choice. This will vary depending on which telehealth software you are using." />
              <ListItemText primary="9. Clinical Notes AI will now pick up the audio in the selected tab for your Live Session." />
            </List>
          </Grid>

          <Grid item xs={12}>
            <Typography id="capturingSessions" variant="h4" textAlign="center" sx={{ mb: 1 }}>
              Capturing your Sessions
            </Typography>
            <Box
              sx={{ position: 'relative', paddingBottom: '56.25%', height: 0, overflow: 'hidden' }}
            >
              <iframe
                title="Capturing Sessions"
                src="https://www.loom.com/embed/63df1df4806541b2bac065d25033bb9d?sid=61445490-4fc6-496c-b4c3-c73db1d71709"
                allowFullScreen
                style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
              ></iframe>
            </Box>
            <List>
              <ListItemText primary="1. Select Generate Note or Generate Document." />
              <ListItemText primary="2. Select which type of Note or Document you would like to generate." />
              <ListItemText primary="3. You can either record a Live Session with your patient, record yourself dictating the session, or you can upload a recorded audio file." />
              <ListItemText primary="4. Confirm your client's consent to use Clinical Notes AI." />
              <ListItemText primary="5. Choose the audio input/microphone type." />
              <ListItemText primary="6. You also have the option to record your computer’s audio. This is used to capture audio from telehealth meetings. If you select this option, you must select the Chrome Tab which has the meeting. You cannot capture audio by recording a Window or your Entire Screen. At the bottom of the popup, be sure to toggle the “Also share tab audio” in order to record the session." />
              <ListItemText primary="7. Record your session. Press Finish when you are done." />
            </List>
          </Grid>

          <Grid item xs={12}>
            <Typography id="progressNote" variant="h4" textAlign="center" sx={{ mb: 1 }}>
              Progress Note Creation
            </Typography>
            <Box
              sx={{ position: 'relative', paddingBottom: '56.25%', height: 0, overflow: 'hidden' }}
            >
              <iframe
                title="Progress Note Creation"
                src="https://www.loom.com/embed/5f45bcbf9c2343299d9931a745cc72d5?sid=0e6b38b3-563b-4d54-8ca7-731738a87ce4"
                allowFullScreen
                style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
              ></iframe>
            </Box>
            <List>
              <ListItemText primary="1. On the homepage, select Generate Progress Note." />
              <ListItemText primary="2. You can choose which kind of Progress Note you want to generate." />
              <ListItemText primary="3. You can select between a Live Session or a Dictation." />
              <ListItemText primary="4. Confirm your client's consent to use Clinical Notes AI." />
              <ListItemText primary="5. Choose the audio input/microphone type." />
              <ListItemText primary="6. Record your session. There will be an outline you can follow provided based on which note type you select. Press Finish when you are done." />
              <ListItemText primary="7. Select the client you are constructing a document for. You can also enter a new unique name to create a new client." />
              <ListItemText primary="8. Write the document title." />
              <ListItemText primary="9. You have the option to add clinical context. This step is not required." />
              <ListItemText primary="10. Select the Note Type and the additional information you would like to create. Each of these will be sectioned off in your generated note." />
              <ListItemText primary="11. Press Confirm and Generate." />
              <ListItemText primary="12. While your Progress Note is generating, you can generate additional documentation for the same session you recorded." />
              <ListItemText primary="13. Once your note has finished generating, a purple check will appear next to the view button." />
              <ListItemText primary="14. Once your note is generated, you have the option to edit and review it." />
              <ListItemText primary="15. You can now save or export your note." />
            </List>
          </Grid>

          <Grid item xs={12}>
            <Typography id="groupNote" variant="h4" textAlign="center" sx={{ mb: 1 }}>
              Create a Group Note
            </Typography>
            <Box
              sx={{ position: 'relative', paddingBottom: '56.25%', height: 0, overflow: 'hidden' }}
            >
              <iframe
                title="Create a Group Note"
                src="https://www.loom.com/embed/5f45bcbf9c2343299d9931a745cc72d5?sid=0e6b38b3-563b-4d54-8ca7-731738a87ce4"
                allowFullScreen
                style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
              ></iframe>
            </Box>
            <List>
              <ListItemText primary="1. Select the Generate Note button on the homepage.." />
              <ListItemText primary="2. Select the Group Note option." />
              <ListItemText primary="3. Choose between a Live Session or a Dictation." />
              <ListItemText primary="4. Choose the audio input/microphone type." />
              <ListItemText primary="5. Record the session or dictation. Press Finish once you are done." />
              <ListItemText primary="6. Enter Client Names separated by commas. Example: (Ross, John, Sally)" />
              <ListItemText primary="7. If you like, you can add additional context." />
              <ListItemText primary="8. Specify your note type in the required field." />
              <ListItemText primary="9. Press “Confirm & Generate” to create your note." />
              <ListItemText primary="10. Once your note is generated, you have the option to edit and review it." />
              <ListItemText primary="11. You can now save or export your note." />
            </List>
          </Grid>

          <Grid item xs={12}>
            <Typography id="exportNote" variant="h4" textAlign="center" sx={{ mb: 1 }}>
              Exporting a Note or Document
            </Typography>
            <Box
              sx={{ position: 'relative', paddingBottom: '56.25%', height: 0, overflow: 'hidden' }}
            >
              <iframe
                title="Exporting a Note or Document"
                src="https://www.loom.com/embed/ada9ec2775e94c6c891791b047c25d4f?sid=cca58a34-3989-4b16-a771-fd26f30ec509"
                allowFullScreen
                style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
              ></iframe>
            </Box>
            <List>
              <ListItemText primary="1. Once you generate your note, you are able to edit each section." />
              <ListItemText primary="2. You can email, copy, or delete any of the sections in your note." />
              <ListItemText primary="3. As you scroll to the bottom of the page, you will have the option to save your note to your account." />
              <ListItemText primary="4. Press Copy All to copy all sections of your note." />
              <ListItemText primary="5. Email all will create an email containing all sections of your note." />
              <ListItemText primary="6. Print / PDF Export will turn your note into a PDF, which you can then print." />
              <ListItemText primary="7. Export to DOC will download your note as a Microsoft Word document." />
            </List>
          </Grid>

          <Grid item xs={12}>
            <Typography id="documentContext" variant="h4" textAlign="center" sx={{ mb: 1 }}>
              Creating a Document with Context
            </Typography>
            <Box
              sx={{ position: 'relative', paddingBottom: '56.25%', height: 0, overflow: 'hidden' }}
            >
              <iframe
                title="Creating a Document with Context"
                src="https://www.loom.com/embed/b49d38936090492ca2470d1d106697a6?sid=694fe53d-75a4-4cb3-afae-d727497ef5fd"
                allowFullScreen
                style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
              ></iframe>
            </Box>
            <List>
              <ListItemText primary="1. Select the Generate Document button on the homepage." />
              <ListItemText primary="2. Scroll down the Documents category of the note builder." />
              <ListItemText primary="3. Choose which kind of document you would like to generate." />
              <ListItemText primary="4. Select which Generation Type you want to use." />
              <ListItemText primary="5. Confirm your client's consent to use Clinical Notes AI." />
              <ListItemText primary="6. Choose the audio input/microphone type." />
              <ListItemText primary="7. Record the session or dictation. Press Finish once you are done." />
              <ListItemText primary="8. When building your document, give the AI as much context as possible." />
              <ListItemText primary="9. Select the client you are constructing a document for. You can also enter a new unique name to create a new client." />
              <ListItemText primary="10. Write the document title." />
              <ListItemText primary="11. Press Confirm and Generate." />
              <ListItemText primary="12. While the document is generating, you have the option to create additional documents." />
              <ListItemText primary="13. Once your document has finished generating, a purple check will appear next to the view button." />
              <ListItemText primary="14. Once your document is generated, you have the option to edit and review it." />
              <ListItemText primary="15. You can now save or export your document." />
            </List>
          </Grid>

          <Grid item xs={12}>
            <Typography id="multipleGenerate" variant="h4" textAlign="center" sx={{ mb: 1 }}>
              Generating Multiple Documents Simultaneosly
            </Typography>
            <Box
              sx={{ position: 'relative', paddingBottom: '56.25%', height: 0, overflow: 'hidden' }}
            >
              <iframe
                title="Generating Multiple Documents Simultaneously"
                src="https://www.loom.com/embed/466f95599061451f92f603cb2b6373ea?sid=b1adee94-2d24-4a6b-b977-4f2bafe95711"
                allowFullScreen
                style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
              ></iframe>
            </Box>
            <List>
              <ListItemText primary="1. Select Generate Note or Generate Document." />
              <ListItemText primary="2. Select which kind of note or document  you want to generate." />
              <ListItemText primary="3. Select between a Live Session or a Dictation." />
              <ListItemText primary="4. Confirm your client's consent to use Clinical Notes AI." />
              <ListItemText primary="5. Choose the audio input/microphone type." />
              <ListItemText primary="6. Record your session. There will be an outline you can follow provided based on which note type you select. Press Finish when you are done." />
              <ListItemText primary="7. Select the client you are constructing a document for. You can also enter a new unique name to create a new client." />
              <ListItemText primary="8. Write the document title." />
              <ListItemText primary="9. Select the Note Type and the additional information you would like to create. Each of these will be sectioned off in your generated note." />
              <ListItemText primary="10. Press Confirm and Generate." />
              <ListItemText primary="11. While your Progress Note is generating, you can generate additional documentation for the same session you recorded. Each of these notes or documents will be generated at the same time." />
              <ListItemText primary="12. Once each of your notes have finished generating, a purple check will appear next to their view buttons." />
            </List>
          </Grid>

          <Grid item xs={12}>
            <Typography id="accountMembers" variant="h4" textAlign="center" sx={{ mb: 1 }}>
              Associating Members with your account
            </Typography>
            <Box
              sx={{ position: 'relative', paddingBottom: '56.25%', height: 0, overflow: 'hidden' }}
            >
              <iframe
                title="Associating Members with your account"
                src="https://www.loom.com/embed/0ca3e50f0739443eadba1985b5e9935b?sid=eb4ce83c-868a-4fe2-b137-fa34d8e57cbb"
                allowFullScreen
                style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
              ></iframe>
            </Box>
            <List>
              <ListItemText primary="1. Click on your icon at the very top right of the Clinical Notes AI page." />
              <ListItemText primary="2. Click on “My Organization”" />
              <ListItemText primary="3. Press add member. Enter the email address of the member you wish to add." />
              <ListItemText primary="4. Instruct the member to click on the invite they receive in their email." />
              <ListItemText primary="5. Your member will not be associated with your organization." />
            </List>
          </Grid>
          <Grid item xs={12}>
            <Typography id="talkToAi" variant="h4" textAlign="center" sx={{ mb: 1 }}>
              Talk To AI Feature
            </Typography>
            <Box
              sx={{ position: 'relative', paddingBottom: '56.25%', height: 0, overflow: 'hidden' }}
            >
              <iframe
                title="Associating Members with your account"
                src="https://www.loom.com/embed/3d7e98dd21bf474e90c5f1ef927f3b9a?sid=cc172a64-8ead-46a4-bfa2-115a6e7f4587"
                allowFullScreen
                style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
              ></iframe>
            </Box>
            <List>
              <ListItemText primary="1. Type or dictate the changes you would like made to your document." />
              <ListItemText primary="2. Click “Make Changes”" />
              <ListItemText primary="3. AI will make the requested changes to your document." />
              <ListItemText primary="4. You can use the undo/redo buttons to toggle between document versions." />
            </List>
          </Grid>
        </Grid>
      </Paper>
    </PageContainer>
  )
}

export default HelpPage
