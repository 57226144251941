import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import { styled } from '@mui/material/styles'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useEffectOnce, useTitle } from 'react-use'
import PageContainer from '../../components/layout/PageContainer'
import LicenseCard from '../../components/licensing/LicenseCard'
import UpgradeLicenseDialog from '../../components/licensing/UpgradeLicenseDialog'
import { getLicenseLimit } from '../../components/licensing/util'
import useActiveLicense from '../../util/auth/useActiveLicense'
import useLayouts from '../../util/auth/useLayouts'

export type FileProps = {
  active?: boolean
}

export const File = styled(Paper, { shouldForwardProp: (prop) => prop !== 'active' })<FileProps>(
  ({ theme, active }) => ({
    padding: theme.spacing(2),
    // Flip the top right edge of the paper to look like a file
    borderRadius: '1rem 3rem 1rem 1rem',
    minHeight: '10rem',
    height: '100%',
    position: 'relative',
    outline: active ? `2px solid ${theme.palette.primary.main}` : undefined,
    transition: 'box-shadow 0.2s ease-in-out',
    boxShadow: theme.shadows[4],
    '&::after': {
      content: '""',
      position: 'absolute',
      top: 0,
      right: 0,
      height: '3rem',
      width: '3rem',
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[4],
      borderRadius: '0 3rem 0 1rem',
      zIndex: 1,
    },
    cursor: 'pointer',
    userSelect: 'none',
    ':hover': {
      boxShadow: theme.shadows[8],
      '&::after': {
        boxShadow: theme.shadows[4],
      },
    },
  })
)

const Documentation = () => {
  useTitle('Clinical Notes AI - Generate Documents')
  const [upsellDialog, setUpsellDialog] = useState(false)

  const navigate = useNavigate()

  const [activeLicense, , populateActiveLicense] = useActiveLicense()
  const [layouts, , populateLayouts] = useLayouts()

  useEffectOnce(() => {
    populateLayouts()
  })

  const createNavigateHandler = (path: string) => () => {
    if (!activeLicense) return

    const docLimit = getLicenseLimit(activeLicense, 'documents')

    if (docLimit.remaining <= 0) {
      setUpsellDialog(true)
      return
    }

    navigate(`/documentation/select/${path}`)
  }

  useEffect(() => {
    populateActiveLicense()
  }, [populateActiveLicense])

  return (
    <>
      <PageContainer>
        <Typography variant="h4" sx={{ mb: 1 }}>
          Documentation
        </Typography>
        <Typography variant="body1" sx={{ mb: 3 }}>
          Select what kind of document you would like to create
        </Typography>
        <Grid container spacing={3} alignItems="stretch">
          {layouts
            .filter((l) => l.type === 'document')
            .sort((a, b) => b.priority - a.priority)
            .map((layout) => (
              <Grid item xs={12} sm={6} md={4} key={layout.identifier}>
                <File onClick={createNavigateHandler(layout.identifier)}>
                  <Typography variant="h6" fontWeight={500} sx={{ mb: 1, mr: 4 }}>
                    {layout.name}
                  </Typography>
                  <Typography variant="subtitle2" color="text.secondary">
                    {layout.description}
                  </Typography>
                </File>
              </Grid>
            ))}
        </Grid>
        <Box sx={{ mt: 4 }}>
          <LicenseCard
            license={activeLicense}
            hideButtons
            shownLimits={['dictates', 'sessions', 'documents']}
          />
        </Box>
      </PageContainer>
      <UpgradeLicenseDialog
        license={activeLicense}
        open={upsellDialog}
        onClose={() => setUpsellDialog(false)}
      />
    </>
  )
}

export default Documentation
