import React from 'react'
import Autocomplete, { AutocompleteProps } from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'

export type MentalHealthProfession =
  | 'therapist'
  | 'psychologist'
  | 'school counselor'
  | 'social worker'
  | 'substance abuse counselor'
  | 'psychiatrist'
  | 'case manager'
  | 'peer recovery coach'
  | 'board certified behavior analyst'
  | 'speech pathologist'
  | 'registered behavior technician'

export type MedicalProfession =
  | 'occupational therapist'
  | 'primary care physician'
  | 'doctor specialist'
  | 'physicians assistant'
  | 'nurse'
  | 'nurse practitioner'
  | 'respiratory therapist'
  | 'physical therapist'
  | 'medical assistant'
  | 'dentist'
  | 'dental assistant'
  | 'medical billing'
  | 'wellness coach'
  | 'chiropractor'
  | 'veterinarian'

export const mentalHealthCategories: Record<MentalHealthProfession, string> = {
  therapist: 'Therapist',
  psychologist: 'Psychologist',
  'school counselor': 'School Counselor',
  'social worker': 'Social Worker',
  'substance abuse counselor': 'Substance Use Disorder Counselor',
  psychiatrist: 'Psychiatrist',
  'case manager': 'Case Manager',
  'peer recovery coach': 'Peer Recovery Coach',
  'board certified behavior analyst': 'Board Certified Behavior Analyst (BCBA)',
  'speech pathologist': 'Speech Pathologist',
  'registered behavior technician': 'Registered Behavior Technician (RBT)',
}

export const medicalCategories: Record<MedicalProfession, string> = {
  'occupational therapist': 'Occupational Therapist',
  'primary care physician': 'Primary Care Physician',
  'doctor specialist': 'Doctor (MD) Specialist',
  'physicians assistant': "Physician's Assistant",
  nurse: 'Nurse',
  'respiratory therapist': 'Respiratory Therapist',
  'physical therapist': 'Physical Therapist',
  'medical assistant': 'Medical Assistant',
  dentist: 'Dentist',
  'dental assistant': 'Dental Assistant',
  'medical billing': 'Medical Billing',
  'wellness coach': 'Wellness Coach',
  chiropractor: 'Chiropractor',
  veterinarian: 'Veterinarian',
  'nurse practitioner': 'Nurse Practitioner (NP)',
}

export const categories: Record<MentalHealthProfession | MedicalProfession, string> = {
  ...mentalHealthCategories,
  ...medicalCategories,
}

export const options = Object.keys(categories) as (MentalHealthProfession | MedicalProfession)[]

export type ProfessionSelectorProps = {
  value: MentalHealthProfession | MedicalProfession | null
  onChange: (value: MentalHealthProfession | MedicalProfession | null) => void
} & Omit<
  AutocompleteProps<MentalHealthProfession | MedicalProfession, false, false, false>,
  'renderInput' | 'renderOption' | 'onChange' | 'value' | 'options'
>

const UserSelector = (
  { value, onChange, ...props }: ProfessionSelectorProps,
  ref: React.Ref<HTMLDivElement>
) => {
  return (
    <Autocomplete
      {...props}
      ref={ref}
      value={value}
      onChange={(e, value) => onChange(value)}
      options={options}
      groupBy={(option) => (option in mentalHealthCategories ? 'Behavioral Health' : 'Medical')}
      getOptionLabel={(option) => categories[option]}
      noOptionsText="No professions found"
      id="profession-selector"
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder="Search..."
          InputProps={{
            ...params.InputProps,
          }}
        />
      )}
    />
  )
}

export default React.forwardRef(UserSelector)
