import { useState, useCallback } from 'react'
import log from '../logging'

// Use permission state value and state.onchange to update the state when it changes
const usePermissionState = () => {
  const [permissionState, setPermissionState] = useState<PermissionState>('prompt')

  const requestPermission = useCallback(async () => {
    try {
      log.info('Requesting permission state...')
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true })
      stream.getTracks().forEach((track) => {
        track.stop()
      })
      log.info('Permission state granted...')
      setPermissionState('granted')
      return 'granted'
    } catch (err) {
      console.log(err)
      setPermissionState('denied')
      return 'denied'
    }
  }, [setPermissionState])

  return { permissionState, requestPermission }
}

export default usePermissionState
