import { Product, RLicense } from '@counsel-project/counsel-auth-api'
import PricingCard from '../../components/commerce/PricingCard'
import { getDisplayPrice } from './util'
import { useNavigate } from 'react-router-dom'
import Button from '@mui/material/Button'
import { useMemo } from 'react'
import { UserSubscriptionListResponse } from '@counsel-project/counsel-auth-api/dist/node_api/endpoints/user/subscriptions'

export type ProductPricingCardProps = {
  showMonthlyPrice?: boolean
  product: Product
  onClick?: () => void
  elevation?: number
  subscription?: UserSubscriptionListResponse['subscriptions'][0]
}

const ProductPricingCard = ({
  showMonthlyPrice,
  product,
  onClick,
  elevation,
  subscription,
}: ProductPricingCardProps) => {
  const navigate = useNavigate()

  const { monthlyPrice, annualPrice } = getDisplayPrice(product)

  const handleUpgrade = () => {
    navigate(`/subscriptions/update/${subscription?.id}`)
  }

  const subscriptionIsActive = Boolean(
    subscription &&
      subscription?.metadata.type === product.metadata.type &&
      subscription.status !== 'canceled'
  )
  const hasSubscription = Boolean(subscription)

  const price = useMemo(() => {
    if (subscriptionIsActive && subscription) {
      if (subscription.items.data?.[0]?.price?.recurring?.interval === 'year') {
        return annualPrice * 12
      }
      if (subscription.items.data?.[0]?.price?.recurring?.interval === 'month') {
        return monthlyPrice
      }
    }
    if (product.metadata.annualPrice) {
      return showMonthlyPrice ? monthlyPrice : annualPrice
    }
    return undefined
  }, [annualPrice, monthlyPrice, product, showMonthlyPrice, subscriptionIsActive, subscription])

  const priceText = useMemo(() => {
    if (subscriptionIsActive) {
      return ' Subscription Active'
    }
    if (!product.metadata.priceText) {
      return 'Reduced price upon request'
    }
    if (!showMonthlyPrice) {
      return ` Charged $${annualPrice * 12} yearly`
    }
    return undefined
  }, [annualPrice, product.metadata.priceText, showMonthlyPrice, subscriptionIsActive])

  const pricePeriod = useMemo(() => {
    if (subscriptionIsActive && subscription) {
      if (subscription.items.data?.[0]?.price?.recurring?.interval === 'year') {
        return 'yr'
      }
      if (subscription.items.data?.[0]?.price?.recurring?.interval === 'month') {
        return 'mo'
      }
    }
    return 'mo'
  }, [subscriptionIsActive, subscription])

  return (
    <PricingCard
      elevation={elevation}
      headerText={
        product.name === 'PRO'
          ? 'Most Popular'
          : product.name === 'Enterprise'
            ? 'Best Value'
            : undefined
      }
      title={product.name}
      price={price}
      period={pricePeriod}
      priceText={priceText}
      description={product.description ?? ''}
      features={product.features?.map((f) => f.name || '')?.filter((f) => f) ?? []}
      buttonText={
        subscriptionIsActive
          ? 'Manage Billing'
          : product.metadata?.priceText
            ? hasSubscription
              ? 'Purchase'
              : 'Start 14 Day Free Trial'
            : 'Schedule a Consultation'
      }
      buttonColor={subscriptionIsActive ? 'secondary' : 'primary'}
      buttonVariant={product.metadata?.priceText ? 'contained' : 'text'}
      onClick={onClick}
      active={subscriptionIsActive}
    >
      {subscriptionIsActive ? (
        <Button onClick={handleUpgrade} fullWidth sx={{ mt: 1 }}>
          Change Subscription
        </Button>
      ) : null}
    </PricingCard>
  )
}

export default ProductPricingCard
