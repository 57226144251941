import { PatientSearchableFields, RPatientSession } from '@counsel-project/counsel-transcribe-api'
import { SearchQuery } from '@counsel-project/counsel-transcribe-api/dist/common/query'
import CloseIcon from '@mui/icons-material/Close'
import LoadingButton from '@mui/lab/LoadingButton'
import Button from '@mui/material/Button'
import Checkbox from '@mui/material/Checkbox'
import CircularProgress from '@mui/material/CircularProgress'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Divider from '@mui/material/Divider'
import FormControlLabel from '@mui/material/FormControlLabel'
import Grid from '@mui/material/Grid'
import IconButton from '@mui/material/IconButton'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import React, { useCallback, useState } from 'react'
import SearchBar from '../../components/SearchBar'
import getSessionType from '../../components/forms/func/getSessionType'
import PageContainer from '../../components/layout/PageContainer'
import { getRelativeTime } from '../../util'
import { transcribeRequest } from '../../util/api/transcribe-api'
import checkToken from '../../util/auth/checkToken'
import useRequireAdmin from '../../util/auth/useRequireAdmin'
import handleError from '../../util/handleError'
import OverlayLoader from '../builder/OverlayLoader'

type AdminSessionDetailsDialogProps = {
  open: boolean
  onClose: () => void
  session: RPatientSession
}

const AdminSessionDetailsDialog = ({ open, onClose, session }: AdminSessionDetailsDialogProps) => {
  const [audioLoading, setAudioLoading] = useState(false)

  const handleDownloadAudio = useCallback(async () => {
    try {
      setAudioLoading(true)

      await checkToken()

      const { uri } = await transcribeRequest.sessions.transcribe.getAudio({
        token: '',
        sessionId: session._id,
      })

      console.log(uri)

      const mimetype = uri.split(':')[1].split(';')[0]

      const ext = mimetype.split('/')[1]

      const link = document.createElement('a')
      link.href = uri
      link.download = `${session._id}.${ext}`
      link.click()
    } catch (err) {
      handleError(err)
    } finally {
      setAudioLoading(false)
    }
  }, [session])

  if (audioLoading) {
    return <OverlayLoader>Loading audio... This may take a bit...</OverlayLoader>
  }

  return (
    <Dialog open={open} onClose={onClose} fullScreen>
      <DialogTitle>
        {session.email || session.userId}
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Typography variant="h5" fontWeight={600} component="p" sx={{ mt: 2 }}>
          Output
        </Typography>
        <Typography variant="body1" component="p" sx={{ mt: 2 }}>
          {session.note?.split('\n')?.map((line, i) => (
            <span key={i} className="data-hj-suppress">
              {line}
              <br />
            </span>
          ))}
        </Typography>
        <Divider sx={{ mt: 2 }} />
        <Typography variant="h5" fontWeight={600} component="p" sx={{ mt: 2 }}>
          Transcript
        </Typography>
        <Typography variant="body1" component="p">
          {session.transcript?.split('\n')?.map((line, i) => (
            <span key={i} className="data-hj-suppress">
              {line}
              <br />
            </span>
          ))}
        </Typography>
        <Divider sx={{ mt: 2 }} />
        <Typography variant="body1" component="p" sx={{ mt: 2 }}>
          {session.patientLabel ? (
            <span>
              Patient Label: {session.patientLabel}
              <br />
            </span>
          ) : (
            <span>
              No patient label
              <br />
            </span>
          )}
        </Typography>
        <Typography variant="body1" component="p" sx={{ mt: 2 }}>
          Created at: {new Date(session.createdAt).toLocaleString()}
          <br />
          {session.startedAt && `Started at: ${new Date(session.startedAt).toLocaleString()}`}
          <br />
          {session.expiresAt && `Expires at: ${new Date(session.expiresAt).toLocaleString()}`}
          <br />
          Layout: {session.layout}
          <br />
          Type: {session.type}
          <br />
          ID: {session._id}
          <br />
          File ID: {session.audioFileId}
        </Typography>
        {session.audioFileId && (
          <Button variant="contained" onClick={handleDownloadAudio} sx={{ mt: 2 }}>
            Download Audio
          </Button>
        )}
      </DialogContent>
    </Dialog>
  )
}

type AdminSessionItemProps = {
  session: RPatientSession
  onClick: () => void
}

const AdminSessionItem = ({ session, onClick }: AdminSessionItemProps) => {
  const createDate = new Date(session.createdAt)
  const now = Date.now()

  const sessionType = getSessionType(session)

  return (
    <Paper elevation={0} sx={{ p: 2, cursor: 'pointer', mt: 2 }} onClick={onClick}>
      {session.error && (
        <Typography variant="body1" component="p" color={session.error ? 'red' : 'text.primary'}>
          {session.error}
        </Typography>
      )}
      <Typography variant="body1" component="p">
        {sessionType}
      </Typography>
      <Typography variant="body1" component="p">
        {session.email ?? session.userId}
      </Typography>
      <Typography variant="body1" component="p">
        {createDate.toLocaleString()} ({getRelativeTime((now - createDate.getTime()) / 1000)} Ago)
      </Typography>
      <Typography variant="body1" component="p">
        {session.expiresAt
          ? `Temporary - expires at ${new Date(session.expiresAt).toLocaleDateString()}`
          : 'Does not expire'}
      </Typography>
    </Paper>
  )
}

const Sessions = () => {
  useRequireAdmin('/')

  const [sessions, setSessions] = useState<Omit<RPatientSession, 'transcript' | 'note'>[]>([])
  const [sessionsLoading, setSessionsLoading] = useState(false)
  const [sessionsOffset, setSessionsOffset] = useState(0)

  const [sessionsEmailSearch, setSessionsEmailSearch] = useState('')
  const [sessionsPatientSearch, setSessionsPatientSearch] = useState('')
  const [sessionsIdSearch, setSessionsIdSearch] = useState('')

  const [sessionsTotal, setSessionsTotal] = useState(0)
  const [selectedSession, setSelectedSession] = useState<Omit<
    RPatientSession,
    'transcript' | 'note'
  > | null>(null)
  const [sessionDialog, setSessionDialog] = useState(false)
  const [loadedSession, setLoadedSession] = useState<RPatientSession | null>(null)
  const [showOnlyErrored, setShowOnlyErrored] = useState(false)

  const populateSession = useCallback(async (sessionId: string) => {
    try {
      await checkToken()

      const data = await transcribeRequest.logs.sessions.get({
        token: '',
        sessionId,
      })

      setLoadedSession(data.result)
    } catch (err) {
      handleError(err)
    }
  }, [])

  const populateSessions = useCallback(async () => {
    try {
      setSessionsLoading(true)

      await checkToken()

      const searches: { [key: string]: string | boolean }[] = []
      if (sessionsIdSearch) {
        searches.push({ _id: sessionsIdSearch })
      }
      if (sessionsEmailSearch) {
        searches.push({ email: sessionsEmailSearch, $fuzzy: true })
      }
      if (sessionsPatientSearch) {
        searches.push({ patientLabel: sessionsPatientSearch, $fuzzy: true })
      }
      if (showOnlyErrored) {
        searches.push({ state: 'errored' })
      }

      const data = await transcribeRequest.logs.sessions.list({
        token: '',
        offset: sessionsOffset,
        limit: 10,
        sort: 'createdAt',
        direction: 'desc',
        search:
          searches.length !== 0
            ? ({
                and: searches,
              } as SearchQuery<PatientSearchableFields>)
            : undefined,
      })

      setSessions(data.results)
      setSessionsTotal(data.total)
    } catch (err) {
      handleError(err)
    } finally {
      setSessionsLoading(false)
    }
  }, [
    sessionsOffset,
    sessionsEmailSearch,
    sessionsPatientSearch,
    showOnlyErrored,
    sessionsIdSearch,
  ])

  const handleChangeEmailSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSessionsEmailSearch(e.target.value)
  }

  const handleChangePatientSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSessionsPatientSearch(e.target.value)
  }

  const handleChangeIdSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSessionsIdSearch(e.target.value)
  }

  const createSessionSelectHandler =
    (session: Omit<RPatientSession, 'transcript' | 'note'>) => () => {
      setSelectedSession(session)
      setSessionDialog(true)
      setLoadedSession(null)
      populateSession(session._id)
    }

  const handlePrevious = () => {
    setSessionsOffset((prev) => Math.max(0, prev - 10))
    populateSessions()
  }

  const handleNext = () => {
    setSessionsOffset((prev) => prev + 10)
    populateSessions()
  }

  return (
    <PageContainer>
      <Grid container justifyContent="center" spacing={2}>
        <Grid item xs={12}>
          <Paper elevation={0} sx={{ p: 2 }}>
            <Grid container>
              <Grid item>
                <Typography variant="h5" component="h1" sx={{ mb: 1 }}>
                  Saved Sessions Admin Panel
                </Typography>
              </Grid>
              <Grid item>{sessionsLoading && <CircularProgress size={24} />}</Grid>
            </Grid>
            <Grid container justifyContent="end" alignItems="center" spacing={2}>
              <Grid item xs={12} sm>
                <SearchBar
                  placeholder="Search by email..."
                  onChange={handleChangeEmailSearch}
                  value={sessionsEmailSearch}
                  disabled={sessionsLoading}
                />
              </Grid>
              <Grid item xs={12} sm>
                <SearchBar
                  placeholder="Search by patient..."
                  onChange={handleChangePatientSearch}
                  value={sessionsPatientSearch}
                  disabled={sessionsLoading}
                />
              </Grid>
              <Grid item xs={12} sm>
                <SearchBar
                  placeholder="Search by ID..."
                  onChange={handleChangeIdSearch}
                  value={sessionsIdSearch}
                  disabled={sessionsLoading}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={showOnlyErrored}
                      onChange={(e) => setShowOnlyErrored(e.target.checked)}
                      disabled={sessionsLoading}
                    />
                  }
                  label="Show only errored sessions"
                />
              </Grid>
              <Grid item xs={12}>
                <LoadingButton onClick={populateSessions} loading={sessionsLoading}>
                  Search
                </LoadingButton>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          {sessions.map((session) => (
            <AdminSessionItem
              key={session._id}
              session={session}
              onClick={createSessionSelectHandler(session)}
            />
          ))}
        </Grid>
        <Grid item xs={12} container spacing={2}>
          <Grid item xs={6}>
            <Button
              fullWidth
              disabled={sessionsOffset === 0 || sessionsLoading}
              onClick={handlePrevious}
            >
              Previous
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              fullWidth
              disabled={sessionsOffset + 10 >= sessionsTotal || sessionsLoading}
              onClick={handleNext}
            >
              Next
            </Button>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Typography
            variant="body1"
            component="p"
            sx={{ textAlign: 'center' }}
            color="text.secondary"
          >
            {sessionsTotal} Total
          </Typography>
        </Grid>
      </Grid>
      {selectedSession && (
        <AdminSessionDetailsDialog
          open={sessionDialog}
          onClose={() => setSessionDialog(false)}
          session={loadedSession || selectedSession}
        />
      )}
    </PageContainer>
  )
}

export default Sessions
