import { styled } from '@mui/material/styles'

const IconImg = styled('img')(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    width: 24,
    height: 24,
  },
  [theme.breakpoints.up('sm')]: {
    width: 32,
    height: 32,
  },
  marginRight: theme.spacing(1),
}))

export default IconImg
