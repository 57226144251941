import { RPatient, RPatientSession } from '@counsel-project/counsel-transcribe-api'
import { isApiError } from '@counsel-project/client-utils'
import { capitalize } from '@counsel-project/client-utils'
import PsychologyRoundedIcon from '@mui/icons-material/PsychologyRounded'
import SaveIcon from '@mui/icons-material/SaveRounded'
import LoadingButton from '@mui/lab/LoadingButton'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { toast } from 'react-hot-toast'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { useEffectOnce, useTitle } from 'react-use'
import Breadcrumbs from '../../../components/Breadcrumbs'
import ContextOptions from '../../../components/forms/ContextOptions'
import LayoutOptionsSelector from '../../../components/forms/LayoutOptionsSelector'
import PageContainer from '../../../components/layout/PageContainer'
import SaveForLaterDialog from '../../../components/layout/SaveForLaterDialog'
import LicenseCard from '../../../components/licensing/LicenseCard'
import { getLicenseLimit } from '../../../components/licensing/util'
import PatientSelector from '../../../components/PatientSelector'
import TextAccordion from '../../../components/TextAccordion'
import { usePatientNomenclature } from '../../../util'
import { transcribeRequest } from '../../../util/api/transcribe-api'
import { refreshPatientsCache, refreshSessionsCache } from '../../../util/api/transcribe-api-cached'
import checkToken from '../../../util/auth/checkToken'
import useActiveLicense from '../../../util/auth/useActiveLicense'
import useLayouts from '../../../util/auth/useLayouts'
import handleError from '../../../util/handleError'
import log from '../../../util/logging'
import { logDocument } from '../../../util/tracking'
import PatientInfoEditor from '../../client/PatientInfoEditor'
import { createSearchParams } from '../common'
import EHRPatientContextToggle from '../EHRPatientContextToggle'
import SessionItem from '../SessionItem'

const BuilderLayoutPage = () => {
  useTitle('Clinical Notes AI - Generate')
  const params = useParams()
  const layoutId = params.id

  const [searchParams] = useSearchParams()

  const tutorial = searchParams.get('tutorial') || ''
  const sessionId = searchParams.get('sessionId') || ''
  const shownSessionIds = searchParams.get('shownSessionIds') || ''

  const navigate = useNavigate()

  const [layouts, , populateLayouts] = useLayouts()

  useEffectOnce(() => {
    populateLayouts()
  })

  const layout = layouts.find((l) => l.identifier === layoutId)

  const [activeLicense, , populateActiveLicense] = useActiveLicense()

  useEffectOnce(() => {
    populateActiveLicense()
  })

  const [loading, setLoading] = useState(true)
  const [selectedSessions, setSelectedSessions] = useState<RPatientSession[]>([])
  const [session, setSession] = useState<RPatientSession | null>(null)
  const [selectedPatient, setSelectedPatient] = useState<RPatient | null>(null)
  const [stagedSummary, setStagedSummary] = useState('')
  const [layoutOptions, setLayoutOptions] = useState<string[]>([])
  const [saveForLaterDialogOpen, setSaveForLaterDialogOpen] = useState(false)
  const [context, setContext] = useState('')
  const [patientEditorOpen, setPatientEditorOpen] = useState(false)
  const [ehrPatientId, setEHRPatientId] = useState<string | null>(null)

  const handleChangeSummary = (e: React.ChangeEvent<HTMLInputElement>) => {
    setStagedSummary(e.target.value)
  }

  // populate session ids if they are in the search query once

  const populateContextSessionIds = useCallback(async () => {
    try {
      if (!session?.contextSessionIds) return
      if (session?.contextSessionIds.length === 0) return
      await checkToken()

      const { results } = await transcribeRequest.sessions.list.all({
        token: '',
        search: {
          or: session?.contextSessionIds.map((id) => ({ _id: id })),
        },
      })
      setSelectedSessions(results)
    } catch (err) {
      log.error(err)
    }
  }, [session?.contextSessionIds])

  useEffect(() => {
    if (!session?.contextSessionIds || session?.contextSessionIds.length === 0) return
    populateContextSessionIds()
  }, [session?.contextSessionIds, populateContextSessionIds])

  const handleChangeSelectedPatient = useCallback((patient: RPatient | null) => {
    setSelectedPatient(patient)
  }, [])

  const populateInitial = useCallback(async () => {
    try {
      if (!sessionId) return

      setLoading(true)

      await checkToken()

      const { result } = await transcribeRequest.sessions.get({
        token: '',
        sessionId,
      })

      const queryText = createSearchParams({
        sessionId,
        shownSessionIds,
        tutorial,
      })

      if (result.state === 'generating') {
        return navigate(`/builder/generating${queryText}`)
      }
      if (result.state === 'errored' || result.state === 'generated') {
        if (result.transcript) {
          return navigate(`/builder/regenerate/${result._id}${queryText}`)
        }
      }

      if (result.patientId) {
        try {
          // Populate patient
          const { result: foundPatient } = await transcribeRequest.patients.get({
            patientId: result.patientId,
            token: '',
          })

          if (foundPatient) {
            setSelectedPatient(foundPatient)
          }
        } catch (err) {
          log.warn('Failed to get patient for session in builder page')
          log.warn(err)
        }
      }

      setSession(result)
      setStagedSummary(result.summary || '')

      // Place user at the top of the screen
      window.scrollTo(0, 0)
    } catch (err) {
      handleError(err)
      if (isApiError(err)) {
        if (err.status === 400) {
          navigate('/notes')
        }
      }
    } finally {
      setLoading(false)
    }
  }, [sessionId, navigate, tutorial, shownSessionIds])

  useEffectOnce(() => {
    const timeout = setTimeout(() => {
      populateInitial()
    }, 10)
    return () => clearTimeout(timeout)
  })

  const handleGenerateNote = useCallback(async () => {
    try {
      if (!layout) {
        toast.error('Note type is unavailable', { id: 'no-note-type' })
        return
      }

      setLoading(true)

      await checkToken()

      let newPatient: RPatient | undefined
      if (selectedPatient && !selectedPatient._id) {
        try {
          const { result } = await transcribeRequest.patients.create({
            token: '',
            label: selectedPatient.label,
          })
          newPatient = result
        } catch (err) {
          log.warn(
            'Failed to create patient on builder page',
            selectedPatient._id,
            selectedPatient.userId
          )
          log.warn(err)

          // Search for the patient label, because it seems it might already exist
          const { results } = await transcribeRequest.patients.list.all({
            token: '',
            search: {
              and: [{ label: selectedPatient.label }],
            },
            limit: 1,
          })
          if (results.length > 0) {
            newPatient = results[0]
          }
        }

        refreshPatientsCache()
      } else if (selectedPatient?._id) {
        newPatient = selectedPatient
      }

      let usedSession = session

      if (!usedSession) {
        // Create session
        const res = await transcribeRequest.sessions.create({
          layout: layout.identifier,
          token: '',
          patientId: newPatient?._id,
          summary: stagedSummary || undefined,
        })

        usedSession = res.result
      } else if (newPatient || stagedSummary) {
        await transcribeRequest.sessions.update({
          token: '',
          sessionId: usedSession._id,
          patientId: newPatient?._id,
          summary: stagedSummary || undefined,
          expiresAt: usedSession.expiresAt,
        })
      }

      if (session) {
        const { result: cloned } = await transcribeRequest.sessions.clone({
          token: '',
          sessionId: session._id,
          layout: layout.identifier,
          type: layout.type,
          expiresAt: session.expiresAt,
        })

        usedSession = cloned
      }

      await transcribeRequest.sessions.generate.start({
        token: '',
        sessionId: usedSession._id,
        identifier: layout.identifier,
        options: layoutOptions,
        contextSessionIds: selectedSessions.map((s) => s._id),
        context: context || undefined,
        ehrPatientId: ehrPatientId || undefined,
      })

      logDocument({
        template: layout.identifier,
        name: layout.name,
      })

      const queryText = createSearchParams({
        sessionId,
        shownSessionIds: shownSessionIds
          ? [...shownSessionIds.split(',').filter(Boolean), usedSession._id].join(',')
          : usedSession._id,
        tutorial,
      })

      refreshSessionsCache()

      return navigate(`/builder/generating${queryText}`)
    } catch (err) {
      handleError(err)
    } finally {
      setLoading(false)
    }
  }, [
    selectedPatient,
    stagedSummary,
    selectedSessions,
    tutorial,
    navigate,
    layout,
    layoutOptions,
    session,
    shownSessionIds,
    context,
    sessionId,
    ehrPatientId,
  ])

  const patientNomenclature = usePatientNomenclature()

  const getBuilderBackPath = useCallback(() => {
    const queryText = createSearchParams({
      sessionId,
      shownSessionIds,
      tutorial,
    })

    return `/builder${queryText}`
  }, [sessionId, shownSessionIds, tutorial])

  const breadcrumbs = [{ name: 'Builder', path: getBuilderBackPath() }, { name: 'Generate' }]

  const handleBack = useCallback(() => {
    navigate('/builder')
  }, [navigate])

  const onClickSaveForLater = useCallback(() => {
    setSaveForLaterDialogOpen(true)
  }, [])

  const onCloseSaveForLater = useCallback(() => {
    setSaveForLaterDialogOpen(false)
  }, [])

  const onSaveForLater = () => {
    toast.success('You may view this session later in the "My Clients" tab', {
      id: 'save-for-later',
      duration: 5000,
    })
    navigate('/builder')
  }

  const populateTreatmentPlan = useCallback(async () => {
    try {
      if (!selectedPatient) return
      if (!selectedPatient._id) return
      if (!layout) return
      if (layout.documentType === 'Treatment Plan') return

      await checkToken()

      const { results } = await transcribeRequest.sessions.list.patient({
        patientId: selectedPatient._id,
        token: '',
        limit: 1,
        sort: 'createdAt',
        direction: 'desc',
        search: {
          and: [{ documentType: 'Treatment Plan' }],
        },
      })

      if (results.length === 0) return

      const treatmentPlan = results[0]

      setSelectedSessions((prev) => [treatmentPlan])
    } catch (err) {
      handleError(err)
    }
  }, [selectedPatient, layout])

  useEffect(() => {
    if (!selectedPatient) {
      setSelectedSessions([])
      return
    }
    if (!selectedPatient._id) return
    const timeout = setTimeout(() => {
      populateTreatmentPlan()
    }, 100)
    return () => clearTimeout(timeout)
  }, [selectedPatient, populateTreatmentPlan])

  useEffect(() => {
    setPatientEditorOpen(false)
  }, [selectedPatient])

  useEffect(() => {
    if (!layout) return
    if (!layout.config.multiplePeople) return
    navigate(`/builder/group-layout/${layout.identifier}?${searchParams.toString()}`)
  }, [layout, navigate, searchParams])

  const sessionType = useMemo(() => {
    if (!layout) return 'dictation'
    return layout.type === 'note' && session?.dictation
      ? 'dictation'
      : layout.type === 'note'
        ? 'session'
        : 'document'
  }, [layout, session])

  const licenseLimit = useMemo(() => {
    if (!activeLicense) return { max: 0, remaining: 0 }
    return getLicenseLimit(
      activeLicense,
      sessionType === 'dictation'
        ? 'dictates'
        : sessionType === 'session'
          ? 'sessions'
          : 'documents'
    )
  }, [activeLicense, sessionType])

  const canGenerate = useMemo(() => {
    if (!activeLicense) return false
    const { remaining, max } = licenseLimit
    if (max === -1) return true

    return remaining > 0
  }, [activeLicense, licenseLimit])

  if (!layout) return null

  return (
    <PageContainer>
      <Breadcrumbs paths={breadcrumbs} />
      {sessionId && (
        <Box sx={{ pb: 2 }}>
          <SessionItem
            regenerate={false}
            session={session}
            onClickClose={handleBack}
            layout={layout}
          />
          <Button endIcon={<SaveIcon />} onClick={onClickSaveForLater} sx={{ mt: 2 }} fullWidth>
            Save For Later
          </Button>
        </Box>
      )}
      <Typography variant="h6" fontWeight={500} sx={{ mb: 2 }}>
        Generate {layout.name}
      </Typography>
      <Paper elevation={0} sx={{ p: 2, mb: 2 }}>
        <Box>
          <Typography variant="body1" fontWeight={500} sx={{ mb: 1 }} fontSize={18}>
            {capitalize(patientNomenclature)} Name
          </Typography>
          <PatientSelector
            value={selectedPatient}
            onChange={handleChangeSelectedPatient}
            sx={{ mb: 1 }}
          />
          {selectedPatient && (
            <Box>
              <EHRPatientContextToggle
                patientLabel={selectedPatient.label}
                onChangeEhrPatientId={setEHRPatientId}
              />
            </Box>
          )}
          <Box>
            <TextAccordion
              id="patient-info-accordion"
              title={`${capitalize(patientNomenclature)} Info`}
              disabled={!selectedPatient}
              onClose={() => setPatientEditorOpen(false)}
              onOpen={() => setPatientEditorOpen(true)}
              open={patientEditorOpen}
            >
              {!!selectedPatient && (
                <PatientInfoEditor
                  hidden={['auto-generate', 'export', 'summary']}
                  patient={selectedPatient}
                  onSaved={setSelectedPatient}
                />
              )}
            </TextAccordion>
          </Box>
          <Typography variant="body1" fontWeight={500} sx={{ mb: 1 }} fontSize={18}>
            {capitalize(layout.type)} Title
          </Typography>
          <TextField
            fullWidth
            value={stagedSummary}
            onChange={handleChangeSummary}
            placeholder={`Enter a title for this ${layout.type}...`}
          />
        </Box>
      </Paper>
      <ContextOptions
        title="Add Clinical Context"
        patient={selectedPatient}
        contextSessions={selectedSessions}
        onChangeContextSessions={setSelectedSessions}
        mainSession={session}
        onChangeMainSession={setSession}
        layouts={layouts}
        layout={layout}
        context={context}
        onChangeContext={setContext}
      />
      {layout.config.options.length > 0 && (
        <Paper elevation={0} sx={{ p: 2, mt: 2 }}>
          <Box>
            <LayoutOptionsSelector
              options={layout.config.options}
              value={layoutOptions}
              onChange={setLayoutOptions}
            />
          </Box>
        </Paper>
      )}
      <Grid container spacing={2} justifyContent="center" alignItems="center" sx={{ my: 2 }}>
        <Grid item xs={12}>
          {!session && selectedSessions.length === 0 && (
            <Typography variant="body1" color="secondary" fontWeight={500} sx={{ mb: 2 }}>
              Please dictate the information required to generate this {layout.type} or select
              additional documentation
            </Typography>
          )}
          <LoadingButton
            fullWidth
            loading={loading}
            onClick={handleGenerateNote}
            sx={{
              px: 4,
              py: 3,
            }}
            startIcon={<PsychologyRoundedIcon />}
            id="generate-notes-button"
            aria-hidden={loading}
            disabled={(!session && selectedSessions.length === 0) || !canGenerate}
          >
            Confirm & Generate
          </LoadingButton>
          {!canGenerate && (
            <Typography variant="body1" fontWeight={500} color="secondary" sx={{ mt: 2 }}>
              You do not have enough credits
            </Typography>
          )}
          {licenseLimit.max !== -1 && (
            <Typography variant="body1" fontWeight={500} color="primary" sx={{ mt: 2 }}>
              {sessionType === 'dictation'
                ? 'Generating this note will use 1 dictation credit'
                : sessionType === 'session'
                  ? 'Generating this note will use 1 live session credit'
                  : 'Generating this document will use 1 document credit'}
            </Typography>
          )}
        </Grid>
      </Grid>
      <LicenseCard
        license={activeLicense}
        hideButtons
        shownLimits={['sessions', 'dictates', 'documents']}
        bufferValues={{
          sessions: layout.type === 'note' && !session?.dictation ? 1 : 0,
          dictates: layout.type === 'note' && session?.dictation ? 1 : 0,
          documents: layout.type === 'note' ? 0 : 1,
        }}
      />
      <SaveForLaterDialog
        sessionId={sessionId}
        open={saveForLaterDialogOpen}
        onClose={onCloseSaveForLater}
        onSave={onSaveForLater}
      />
    </PageContainer>
  )
}

export default BuilderLayoutPage
