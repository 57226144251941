import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import checkToken from '../../../util/auth/checkToken'
import { authRequest } from '../../../util/api/auth-api'
import { UserSubscriptionListResponse } from '@counsel-project/counsel-auth-api/dist/node_api/endpoints/user/subscriptions'
import handleError from '../../../util/handleError'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import { useTitle } from 'react-use'
import { Price, Product, RDirectory } from '@counsel-project/counsel-auth-api'
import PageContainer from '../../../components/layout/PageContainer'
import SelectableTab from '../../../components/SelectableTab'
import Paper from '@mui/material/Paper'
import DirectorySelector from '../../../components/DirectorySelector'
import PagePaperLoader from '../../../components/loaders/PagePaperLoader'
import TextField from '@mui/material/TextField'
import { PlanBox } from '../../purchase'
import PricingCard from '../../../components/commerce/PricingCard'
import ConfirmUpdateSubscriptionDialog from '../../../components/layout/ConfirmUpdateSubscriptionDialog'

const UpdateSubscriptionPage = () => {
  useTitle('Update Subscription')

  const navigate = useNavigate()
  const { subscriptionId } = useParams()
  const [subscription, setSubscription] = useState<
    UserSubscriptionListResponse['subscriptions'][0] | undefined
  >(undefined)
  const [products, setProducts] = useState<{ product: Product; prices: Price[] }[]>([])
  const [stagedProductId, setStagedProductId] = useState<string | undefined>(undefined)
  const [stagedPriceId, setStagedPriceId] = useState<string | undefined>(undefined)
  const [stagedQuantity, setStagedQuantity] = useState<string>('1')
  const [stagedType, setStagedType] = useState<'user' | 'directory'>('user')
  const [stagedDirectory, setStagedDirectory] = useState<RDirectory | null>(null)
  const [loading, setLoading] = useState(false)
  const [updateSubscriptionOpen, setUpdateSubscriptionOpen] = useState(false)

  const handleToggleUpdateSubscription = useCallback(() => {
    setUpdateSubscriptionOpen((prev) => !prev)
  }, [])

  const handleChangeQuantity = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (loading) return
      const n = parseInt(e.target.value)
      if (!n) {
        setStagedQuantity('')
        return
      }
      setStagedQuantity(`${n}`)
    },
    [loading]
  )

  const populateSubscription = useCallback(async () => {
    try {
      if (!subscriptionId) return

      setLoading(true)

      await checkToken()

      const { subscription } = await authRequest.user.subscriptions.get({
        token: '',
        subscriptionId,
      })

      setSubscription(subscription)

      if (typeof subscription?.items.data?.[0]?.price?.product === 'string') {
        setStagedProductId(subscription.items.data[0].price.product)
      }
      if (typeof subscription?.items.data?.[0]?.price?.id === 'string') {
        setStagedPriceId(subscription.items.data[0].price.id)
      }
      if (typeof subscription?.items.data?.[0]?.quantity === 'number') {
        setStagedQuantity(subscription.items.data[0].quantity.toString())
      }
      if (typeof subscription?.metadata.directoryId === 'string') {
        setStagedType('directory')
        try {
          const { result } = await authRequest.user.directories.get({
            token: '',
            directoryId: subscription.metadata.directoryId,
          })

          setStagedDirectory(result)
        } catch (err) {
          handleError(err)
        }
      } else {
        setStagedType('user')
        setStagedDirectory(null)
      }
    } catch (err) {
      handleError(err)
    } finally {
      setLoading(false)
    }
  }, [subscriptionId])

  useEffect(() => {
    const timeout = setTimeout(() => {
      populateSubscription()
    }, 10)
    return () => clearTimeout(timeout)
  }, [populateSubscription])

  const populateProducts = useCallback(async () => {
    try {
      const res = await authRequest.products.list()

      setProducts(
        res.products
          .filter((p) => p.prices.length !== 0 && p.product.name !== 'Enterprise')
          .sort(
            (a, b) =>
              parseInt(a.product.metadata.order || '0') - parseInt(b.product.metadata.order || '0')
          )
      )
      window.scrollTo(0, 0)
    } catch (err) {
      handleError(err)
    }
  }, [])

  useEffect(() => {
    const timeout = setTimeout(() => {
      populateProducts()
    }, 10)
    return () => clearTimeout(timeout)
  }, [populateProducts])

  const prices = useMemo(() => {
    if (!stagedProductId) return []
    const product = products.find(({ product }) => product.id === stagedProductId)
    return product?.prices || []
  }, [stagedProductId, products])

  const currentPrice = useMemo(() => {
    if (!subscription) return 0
    return (
      ((subscription?.items.data?.[0]?.price?.unit_amount || 100) / 100) *
      (subscription?.items.data?.[0]?.quantity || 1)
    )
  }, [subscription])

  const currentBillingPeriod = useMemo(() => {
    if (!subscription) return 'month'
    return subscription?.items.data?.[0]?.price?.recurring?.interval || 'month'
  }, [subscription])

  const stagedPrice = useMemo(() => {
    if (!stagedPriceId) return 0
    const price = prices.find((p) => p.id === stagedPriceId)
    return ((price?.unit_amount || 0) / 100) * parseInt(stagedQuantity)
  }, [prices, stagedPriceId, stagedQuantity])

  const stagedBillingPeriod = useMemo(() => {
    if (!stagedPriceId) return 'month'
    const price = prices.find((p) => p.id === stagedPriceId)
    return price?.recurring?.interval || 'month'
  }, [prices, stagedPriceId])

  const hasUpdated = useMemo(() => {
    if (stagedType === 'directory' && !stagedDirectory) return false
    if (!subscription) return false
    const stagedPriceObj = prices.find((p) => p.id === stagedPriceId)
    if (!stagedPriceObj) return false
    if (parseInt(stagedQuantity) !== subscription.items.data?.[0]?.quantity) return true
    if (stagedPriceId !== subscription.items.data?.[0]?.price?.id) return true
    if (stagedProductId !== subscription.items.data?.[0]?.price?.product) return true
    if (stagedType !== (subscription.metadata.directoryId ? 'directory' : 'user')) return true
    if (stagedDirectory && stagedDirectory._id !== subscription.metadata.directoryId) return true
    if (!stagedDirectory && subscription.metadata.directoryId) return true
    if (subscription.cancel_at_period_end) return true

    return false
  }, [
    stagedDirectory,
    stagedPriceId,
    stagedProductId,
    stagedQuantity,
    stagedType,
    subscription,
    prices,
  ])

  const activeProduct = useMemo(() => {
    return products.find((p) => p.product.id === stagedProductId)?.product
  }, [products, stagedProductId])

  const activePrice = useMemo(() => {
    return prices.find((p) => p.id === stagedPriceId)
  }, [prices, stagedPriceId])

  if (loading) {
    return <PagePaperLoader sections={[40, 40, 40]} />
  }

  return (
    <PageContainer>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h4">Update Subscription</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1">Subscription Status: {subscription?.status}</Typography>
        </Grid>
        {subscription?.cancel_at_period_end && (
          <Grid item xs={12}>
            <Typography variant="body1" color="error">
              Subscription is canceled and will end on{' '}
              {new Date(subscription.current_period_end * 1000).toLocaleDateString()}
            </Typography>
          </Grid>
        )}
        <Grid item xs={12}>
          <Typography variant="h4">Membership Type</Typography>
        </Grid>
        <Grid item xs={6}>
          <SelectableTab small active={stagedType === 'user'} onClick={() => setStagedType('user')}>
            Individual
          </SelectableTab>
        </Grid>
        <Grid item xs={6}>
          <SelectableTab
            small
            active={stagedType === 'directory'}
            onClick={() => setStagedType('directory')}
          >
            Organization
          </SelectableTab>
        </Grid>
        {stagedType === 'directory' && (
          <Grid item xs={12}>
            <Paper sx={{ p: 2 }} elevation={0}>
              <Typography variant="body1" sx={{ mb: 1 }} fontWeight={500}>
                This subscription applies to...
              </Typography>
              <DirectorySelector value={stagedDirectory} onChange={setStagedDirectory} />
              <Typography variant="body1" sx={{ mb: 1, mt: 1 }} fontWeight={500}>
                Number of clinicians
              </Typography>
              <TextField
                fullWidth
                value={stagedQuantity}
                onChange={handleChangeQuantity}
                variant="outlined"
                aria-label="users"
                type="number"
              />
              <Typography
                variant="subtitle2"
                color="text.secondary"
                sx={{ mt: 1 }}
                fontStyle="italic"
              >
                How many clinicians are on your team?
              </Typography>
            </Paper>
          </Grid>
        )}
        {products.map(({ product, prices }) => (
          <Grid item xs={6} sm={4} key={product.id}>
            <PricingCard
              headerText={
                product.name === 'PRO'
                  ? 'Most Popular'
                  : product.name === 'Enterprise'
                    ? 'Best Value'
                    : undefined
              }
              title={product.name}
              description={product.description ?? ''}
              features={product.features?.map((f) => f.name || '')?.filter((f) => f) ?? []}
              onClick={() => setStagedProductId(product.id)}
              active={stagedProductId === product.id}
            />
          </Grid>
        ))}
        {prices
          ?.sort((a, b) => (a.recurring?.interval === 'year' ? 1 : -1))
          .map((price, index) => (
            <Grid item xs={12} md={6} key={index + '-plan'}>
              <PlanBox
                quantity={parseInt(stagedQuantity)}
                prices={prices}
                price={price}
                selected={stagedPriceId === price.id}
                onClick={() => setStagedPriceId(price.id)}
              />
            </Grid>
          ))}
        <Grid item xs={12}>
          <Paper sx={{ p: 2 }} elevation={0}>
            <Typography variant="h4" sx={{ mb: 1 }} fontWeight={500}>
              Checkout
            </Typography>
            <Typography variant="body1" sx={{ mb: 1 }} fontWeight={500}>
              Current subscription: ${currentPrice} per {currentBillingPeriod}
            </Typography>
            {stagedPrice !== 0 && (
              <Typography variant="body1" sx={{ mb: 1 }} fontWeight={500}>
                New subscription update: ${stagedPrice} per {stagedBillingPeriod}
              </Typography>
            )}
            <Grid container justifyContent="end">
              <Grid item>
                <Button disabled={!hasUpdated} onClick={handleToggleUpdateSubscription}>
                  Confirm Update
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
      {subscription && activeProduct && activePrice && (
        <ConfirmUpdateSubscriptionDialog
          open={updateSubscriptionOpen}
          onClose={handleToggleUpdateSubscription}
          product={activeProduct}
          price={activePrice}
          activeSubscription={subscription}
          quantity={parseInt(stagedQuantity)}
          directoryId={stagedDirectory?._id}
        />
      )}
    </PageContainer>
  )
}

export default UpdateSubscriptionPage
