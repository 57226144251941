import React, { useState } from 'react'
import { Theme, useTheme } from '@mui/material/styles'
import Box from '@mui/material/Box'
import OutlinedInput from '@mui/material/OutlinedInput'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import Chip from '@mui/material/Chip'
import Typography from '@mui/material/Typography'
import { medicalCategories, mentalHealthCategories } from '../../components/ProfessionSelector'

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 8.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}

const allProfessions = [
  ...Object.entries(mentalHealthCategories),
  ...Object.entries(medicalCategories),
]

function getStyles(name: string, value: readonly string[], theme: Theme) {
  return {
    fontWeight:
      value.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  }
}

type LayoutProfessionSelectorProps = {
  value: string[]
  onChange: (value: string[]) => void
  disabled?: boolean
}

const LayoutProfessionSelector = ({ value, onChange, disabled }: LayoutProfessionSelectorProps) => {
  const theme = useTheme()

  const handleChange = (event: SelectChangeEvent<typeof value>) => {
    const {
      target: { value: newVal },
    } = event
    onChange(
      // On autofill we get a stringified value.
      typeof newVal === 'string' ? newVal.split(',') : newVal
    )
  }

  return (
    <FormControl sx={{ width: '100%' }}>
      <Typography variant="body1" fontWeight={500} fontSize={18} sx={{ mb: 1 }}>
        Professions
      </Typography>
      <Select
        labelId="layout-profession-selector-label"
        id="layout-profession-selector"
        multiple
        value={value}
        onChange={handleChange}
        input={<OutlinedInput id="layout-profession-selector-input" />}
        renderValue={(selected) => (
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
            {selected.map((value) => (
              <Chip key={value} label={value} />
            ))}
          </Box>
        )}
        MenuProps={MenuProps}
        disabled={disabled}
      >
        {allProfessions.map((profession) => (
          <MenuItem
            key={profession[0]}
            value={profession[0]}
            style={getStyles(profession[1], value, theme)}
          >
            {profession[1]}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default LayoutProfessionSelector
