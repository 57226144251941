import React from 'react'
import Button, { ButtonProps } from '@mui/material/Button'

type ExportDocxButtonProps = {
  html: string
  filename: string
} & ButtonProps

const ExportDocxButton: React.FC<ExportDocxButtonProps> = ({ html, filename, ...props }) => {
  const handleExportDocx = () => {
    const header =
      "<html xmlns:o='urn:schemas-microsoft-com:office:office' " +
      "xmlns:w='urn:schemas-microsoft-com:office:word' " +
      "xmlns='http://www.w3.org/TR/REC-html40'>" +
      "<head><meta charset='utf-8'><title>Clinical Notes AI</title></head><body>"
    const footer = '</body></html>'
    const sourceHTML = header + html + footer

    const source = 'data:application/vnd.ms-word;charset=utf-8,' + encodeURIComponent(sourceHTML)
    const fileDownload = document.createElement('a')
    document.body.appendChild(fileDownload)
    fileDownload.href = source
    fileDownload.download = filename + '.doc'
    fileDownload.click()
    document.body.removeChild(fileDownload)
  }

  return (
    <Button {...props} onClick={handleExportDocx}>
      Export to DOC
    </Button>
  )
}
export default ExportDocxButton
