import TextField from '@mui/material/TextField'
import { styled } from '@mui/material/styles'

const AddItemTextField = styled(TextField)(({ theme }) => ({
  border: 'none',
  outline: 'none',
  '& .MuiInputBase-root': {
    border: 'none',
    outline: 'none',
  },
  '& .MuiInputBase-input': {
    border: 'none',
    outline: 'none',
    padding: theme.spacing(1),
    paddingLeft: theme.spacing(2),
  },
  '& .MuiOutlinedInput-root': {
    border: 'none',
    outline: 'none',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
    outline: 'none',
  },
}))

export default AddItemTextField
