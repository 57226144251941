import Paper from '@mui/material/Paper'
import Grid from '@mui/material/Grid'
import { IntegrationType } from '@counsel-project/counsel-ehr-api/dist/common/database/RIntegration'
import { useMemo } from 'react'
import Typography from '@mui/material/Typography'

type IntegrationBoxProps = {
  integrationType: IntegrationType
}

const IntegrationBox = ({ integrationType }: IntegrationBoxProps) => {
  const imgSrc = useMemo(() => {
    if (integrationType === 'kipu') {
      return '/integrations/kipu.svg'
    }
    if (integrationType === 'advancedmd') {
      return '/integrations/advancedmd.svg'
    }
    return ''
  }, [integrationType])

  const integrationName = useMemo(() => {
    if (integrationType === 'kipu') {
      return 'Kipu'
    }
    if (integrationType === 'advancedmd') {
      return 'AdvancedMD'
    }
    return ''
  }, [integrationType])

  return (
    <Paper variant="outlined">
      <Grid container padding={1.5} paddingX={1.5} spacing={1} alignItems="center">
        <Grid item>
          <img
            src={imgSrc}
            alt=""
            style={{
              width: 20,
              height: 20,
              objectFit: 'contain',
              marginTop: 4,
            }}
          />
        </Grid>
        <Grid item xs>
          <Typography variant="body1" fontWeight={500} sx={{ mb: 0.5 }}>
            {integrationName}
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  )
}

export default IntegrationBox
