import { RLayout } from '@counsel-project/counsel-transcribe-api'
import LoadingButton from '@mui/lab/LoadingButton'
import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import { useCallback, useMemo, useState } from 'react'
import toast from 'react-hot-toast'
import { useSearchParams } from 'react-router-dom'
import DictationTextField from '../../../components/forms/DictationTextField'
import { transcribeRequest } from '../../../util/api/transcribe-api'
import { refreshSessionsCache } from '../../../util/api/transcribe-api-cached'
import checkToken from '../../../util/auth/checkToken'
import handleError from '../../../util/handleError'
import { markdownToHtml, removeMaliciousHTML } from '../../../util/markdown'
import LayoutPageContainer from './LayoutPageContainer'

type Step1SectionProps = {
  layout: RLayout
}

const Step1Section = ({ layout }: Step1SectionProps) => {
  const [, setSearchParams] = useSearchParams()
  const [value, setValue] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const html = useMemo(() => {
    return markdownToHtml(layout.dictationDescription)
  }, [layout.dictationDescription])

  const handleSaveToSession = useCallback(async () => {
    try {
      if (!value) {
        toast.error('Please provide information regarding your session')
        return
      }

      setIsLoading(true)

      await checkToken()

      const { result } = await transcribeRequest.sessions.create({
        token: '',
        dictation: true,
        transcript: value,
        layout: layout.identifier,
      })

      setSearchParams((prev) => {
        prev.set('sessionId', result._id)
        return prev
      })

      refreshSessionsCache()
    } catch (err) {
      handleError(err)
    } finally {
      setIsLoading(false)
    }
  }, [value, layout, setSearchParams])

  return (
    <LayoutPageContainer title="Step 1: Provide Session Information">
      <Paper elevation={0} sx={{ px: 2, py: 1, mb: 2 }}>
        <Box
          sx={{
            fontSize: 16,
            textAlign: 'left',
          }}
        >
          <div
            dangerouslySetInnerHTML={{
              __html: removeMaliciousHTML(html),
            }}
          />
        </Box>
      </Paper>
      <Paper elevation={0} sx={{ p: 2 }}>
        <Typography variant="body1" fontWeight={500} sx={{ mb: 2 }}>
          Dictate or type information about your group session below
        </Typography>
        <DictationTextField
          value={value}
          onChange={setValue}
          placeholder="Provide information regarding your session..."
        />
        <LoadingButton loading={isLoading} sx={{ mt: 2 }} onClick={handleSaveToSession}>
          Continue
        </LoadingButton>
      </Paper>
    </LayoutPageContainer>
  )
}

export default Step1Section
