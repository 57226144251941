import { IconBrackets } from '@codexteam/icons'
import { EditorConfig, BlockAPI } from '@editorjs/editorjs'
import toast from 'react-hot-toast'

const textStyles = `
font-size: 16px;
font-weight: bold;
padding-left: 8px;
padding-right: 8px;
color: #ccc;
`

// Dotted line
const lineStyles = `
border-bottom: 1px dotted #ccc;
width: 100%;
flex: 1;
`

const containerStyles = `
display: flex;
justify-content: stretch;
align-items: center;
`
  .trim()
  .split('\n')
  .join(' ')

class PageBreak {
  static get toolbox() {
    return {
      title: 'Page Break',
      icon: IconBrackets,
    }
  }

  static get isReadOnlySupported() {
    return true
  }

  save(blockContent: HTMLElement) {
    return {}
  }

  render() {
    const text = document.createElement('div')
    const line = document.createElement('div')
    const line2 = document.createElement('div')
    const container = document.createElement('div')

    text.style.cssText = textStyles
    text.innerText = 'Page Break'

    line.style.cssText = lineStyles
    line2.style.cssText = lineStyles

    container.style.cssText = containerStyles

    container.appendChild(line)
    container.appendChild(text)
    container.appendChild(line2)

    return container
  }
}

export default PageBreak
