import React from 'react'
import Box from '@mui/material/Box'
import BlockEditor from '../markdown/BlockEditor'

export type NoteSectionsProps = {
  disabled?: boolean
  loading?: boolean
  id: string
  text: string
  onSaving: (saving: boolean) => void
  onChange: (text: string) => void
  onEmail: (text: string) => void
  onCreateNewNote: (text: string) => void
}

const DocumentSections = ({
  disabled,
  id,
  text,
  loading,
  onChange,
  onEmail,
  onCreateNewNote,
  onSaving,
}: NoteSectionsProps) => {
  return (
    <Box>
      <BlockEditor
        loading={loading}
        id={id}
        defaultValue={text}
        onSave={onChange}
        onEmail={onEmail}
        onCreateNewNote={onCreateNewNote}
        onSaving={onSaving}
        readOnly={disabled}
      />
    </Box>
  )
}

export default DocumentSections
