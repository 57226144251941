import { RLayout } from '@counsel-project/counsel-transcribe-api'
import NextIcon from '@mui/icons-material/NavigateNextRounded'
import Button from '@mui/material/Button'
import FormControl from '@mui/material/FormControl'
import Grid from '@mui/material/Grid'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import Select from '@mui/material/Select'
import TextField from '@mui/material/TextField'
import { useCallback, useState } from 'react'
import toast from 'react-hot-toast'
import { useNavigate } from 'react-router-dom'
import TextAccordion from '../../components/TextAccordion'
import { transcribeRequest } from '../../util/api/transcribe-api'
import checkToken from '../../util/auth/checkToken'
import handleError from '../../util/handleError'

type AdditionalOptionsAccordionProps = {
  layout: RLayout
  language: string
}

const AdditionalOptionsAccordion = ({ layout, language }: AdditionalOptionsAccordionProps) => {
  const navigate = useNavigate()
  const [transcript, setTranscript] = useState('')
  const [dictation, setDictation] = useState(true)
  // Duration in minutes
  const [duration, setDuration] = useState(15)
  const [isLoading, setIsLoading] = useState(false)

  const handleSubmit = useCallback(async () => {
    try {
      if (!transcript) {
        toast.error('Please include text to analyze')
        return
      }

      setIsLoading(true)

      await checkToken()

      const now = new Date()

      const { result } = await transcribeRequest.sessions.transcribe.sendTranscript({
        token: '',
        transcript,
        endedAtString: new Date(new Date().getTime() + duration * 60 * 1000).toLocaleString(),
        startedAtString: now.toLocaleString(),
        duration: duration * 60,
        dictation,
        language: 'en',
      })

      navigate(
        `/builder/${layout.config.multiplePeople ? 'group-layout' : 'layout'}/${
          layout.identifier
        }?sessionId=${result._id}&language=${language}`
      )
    } catch (err) {
      handleError(err)
    } finally {
      setIsLoading(false)
    }
  }, [transcript, dictation, duration, navigate, layout, language])

  return (
    <TextAccordion title="Advanced Options">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            placeholder={dictation ? 'Type dictated information here' : 'Paste transcript here...'}
            value={transcript}
            onChange={(e) => setTranscript(e.target.value)}
            fullWidth
            minRows={3}
            maxRows={5}
            disabled={isLoading}
            type="text"
            multiline
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          {/* Dictation */}
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Dictation/Live Session</InputLabel>
            <Select
              label="Dictation/Live Session"
              value={dictation ? 'dictation' : 'live session'}
              onChange={(e) => setDictation(e.target.value === 'dictation')}
              fullWidth
              disabled={isLoading}
            >
              <MenuItem value={'live session'}>Live Session</MenuItem>
              <MenuItem value={'dictation'}>Dictation</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          {/* Duration selector */}
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Session Duration</InputLabel>
            <Select
              label="Session Duration"
              placeholder="Session Duration"
              value={duration}
              onChange={(e) => setDuration(Number(e.target.value))}
              fullWidth
              disabled={isLoading}
            >
              <MenuItem value={15}>15 Minutes</MenuItem>
              <MenuItem value={30}>30 Minutes</MenuItem>
              <MenuItem value={45}>45 Minutes</MenuItem>
              <MenuItem value={60}>1 Hour</MenuItem>
              <MenuItem value={120}>2 Hours</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs></Grid>
        <Grid item>
          <Button
            variant="contained"
            onClick={handleSubmit}
            disabled={isLoading}
            endIcon={<NextIcon />}
          >
            Continue
          </Button>
        </Grid>
      </Grid>
    </TextAccordion>
  )
}

export default AdditionalOptionsAccordion
