import { RPatientSession } from '@counsel-project/counsel-transcribe-api'
import Button from '@mui/material/Button'
import Divider from '@mui/material/Divider'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { useCallback, useState } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { useEffectOnce, useTitle } from 'react-use'
import PageContainer from '../../../components/layout/PageContainer'
import { transcribeRequest } from '../../../util/api/transcribe-api'
import checkToken from '../../../util/auth/checkToken'
import log from '../../../util/logging'
import { createSearchParams, navigateSessionUrl } from '../common'
import handleError from '../../../util/handleError'
import { isApiError } from '@counsel-project/client-utils'

const BuilderTranscribeErroredPage = () => {
  useTitle('Clinical Notes AI - Errored')
  const params = useParams()
  const sessionId = params.id

  const [searchParams] = useSearchParams()

  const tutorial = searchParams.get('tutorial') === 'true'
  const layoutIdentifier = searchParams.get('layout') || ''
  const group = searchParams.get('group') === 'true'

  const navigate = useNavigate()

  const [loading, setLoading] = useState(true)
  const [session, setSession] = useState<RPatientSession | null>(null)

  const populateInitial = useCallback(async () => {
    try {
      if (!sessionId) return

      await checkToken()

      const { result } = await transcribeRequest.sessions.get({
        token: '',
        sessionId,
      })

      const navUrl = navigateSessionUrl({
        tutorial,
        session: result,
        expectedStates: ['errored'],
        layout: layoutIdentifier,
        group,
      })

      if (navUrl) {
        navigate(navUrl)
      }

      setLoading(false)

      setSession(result)
    } catch (err) {
      handleError(err)
      if (isApiError(err)) {
        if (err.status === 400) {
          navigate('/notes')
        }
      }
    }
  }, [sessionId, tutorial, navigate, layoutIdentifier, group])

  useEffectOnce(() => {
    const timeout = setTimeout(populateInitial, 100)
    return () => clearTimeout(timeout)
  })

  const handleTryAgain = useCallback(async () => {
    try {
      if (!sessionId) return

      setLoading(true)

      await checkToken()

      const res = await transcribeRequest.sessions.transcribe.retry({
        token: '',
        sessionId,
      })

      setLoading(false)

      const queryText = createSearchParams({
        layout: layoutIdentifier,
        tutorial: tutorial ? 'true' : undefined,
      })

      return navigate(`/builder/transcribing/${res.result._id}?${queryText}`)
    } catch (err) {
      log.error(err)
    }
  }, [sessionId, tutorial, navigate, layoutIdentifier])

  const handleDictate = useCallback(() => {
    const layoutId = layoutIdentifier || session?.layout
    const queryText = createSearchParams({
      tutorial: tutorial ? 'true' : undefined,
    })
    if (layoutId) {
      return navigate(`/builder/layout/${layoutId}${queryText}`)
    }
    navigate(`/builder`)
  }, [layoutIdentifier, session, tutorial, navigate])

  const handleClose = useCallback(() => {
    navigate('/notes')
  }, [navigate])

  return (
    <PageContainer>
      <Grid container spacing={2} justifyContent="center" alignItems="center">
        <Grid item xs={12}>
          <Typography variant="h5" textAlign="center" sx={{ mb: 1 }}>
            It looks like we could not process your audio
          </Typography>
          <Typography variant="body1" textAlign="center" sx={{ mb: 1 }}>
            This error has been sent to our staff and we will look into it.
          </Typography>
          <Typography variant="body1" textAlign="center" sx={{ mb: 2 }}>
            Request ID: {sessionId}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item>
          <Typography variant="body1" textAlign="center">
            This can happen due to the following reasons:
          </Typography>
          <Typography variant="body1" component="ul">
            <li>
              <Typography variant="body1" component="span" textAlign="start">
                We're hitting our rate limit ( A lot of people are using the system right now )
              </Typography>
            </li>
            <li>
              <Typography variant="body1" component="span" textAlign="start">
                Audio quality is too low or too quiet
              </Typography>
            </li>
            <li>
              <Typography variant="body1" component="span" textAlign="start">
                Audio streaming was cut off due to the website being in the background
              </Typography>
            </li>
          </Typography>
        </Grid>
        <Grid item xs={12}></Grid>
        <Grid item>
          <Typography variant="body1" textAlign="center">
            If the issue persists, please check the following:
          </Typography>
          <Typography variant="body1" component="ul">
            <li>
              <Typography variant="body1" component="span" textAlign="start">
                Ensure this web page stays open at all times during your session
              </Typography>
            </li>
            <li>
              <Typography variant="body1" component="span" textAlign="start">
                Ensure your device does not fall asleep during the session
              </Typography>
            </li>
            <li>
              <Typography variant="body1" component="span" textAlign="start">
                Try again without any bluetooth devices
              </Typography>
            </li>
          </Typography>
        </Grid>
        <Grid item xs={12}></Grid>
        {!!session?.audioFileId && (
          <Grid item>
            <Button onClick={handleTryAgain} color="secondary" disabled={loading}>
              Try Again
            </Button>
          </Grid>
        )}
        <Grid item>
          <Button onClick={handleDictate} color="secondary" disabled={loading}>
            Use Dictation Instead
          </Button>
        </Grid>
        <Grid item>
          <Button onClick={handleClose} disabled={loading}>
            Close
          </Button>
        </Grid>
      </Grid>
    </PageContainer>
  )
}

export default BuilderTranscribeErroredPage
