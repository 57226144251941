import {
  IntegrationTemplateSortableFields,
  ListIntegrationTemplatesByIntegrationOptions,
  RIntegrationTemplate,
} from '@counsel-project/counsel-ehr-api'
import { useCallback, useEffect, useState } from 'react'
import IntegrationTemplateTable from '../../../components/tables/IntegrationTemplateTable'
import { ehrRequest } from '../../../util/api/ehr-api'
import checkToken from '../../../util/auth/checkToken'
import handleError from '../../../util/handleError'
import Box from '@mui/material/Box'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import { useNavigate } from 'react-router-dom'
import toast from 'react-hot-toast'
import { transcribeRequest } from '../../../util/api/transcribe-api'
import log from '../../../util/logging'
import useLayouts from '../../../util/auth/useLayouts'
import { isInputField } from '@counsel-project/counsel-generation-api'

type IntegrationTemplatesDisplayProps = {
  integrationId: string
}

const IntegrationTemplatesDisplay = ({ integrationId }: IntegrationTemplatesDisplayProps) => {
  const [page, setPage] = useState(0)
  const [limit, setLimit] = useState(10)
  const [search, setSearch] = useState('')
  const [sort, setSort] = useState<IntegrationTemplateSortableFields>('name')
  const [direction, setDirection] = useState<'asc' | 'desc'>('asc')
  const [loading, setLoading] = useState(false)
  const [total, setTotal] = useState(0)
  const [templates, setTemplates] = useState<RIntegrationTemplate[]>([])
  const [showOnlyEnabled, setShowOnlyEnabled] = useState(false)

  const [, setLayouts] = useLayouts()

  const navigate = useNavigate()

  const handleNavigateTemplatePage = useCallback(
    async (template: RIntegrationTemplate) => {
      if (!template.enabled) {
        toast.error('Please enable this template to view it', { id: 'template-not-enabled' })
        return
      }

      try {
        const { results } = await transcribeRequest.layouts.list({
          token: '',
          search: {
            and: [{ integrationTemplateId: template._id }],
          },
        })

        if (results.length > 0) {
          navigate(`/layouts/forms/${results[0]._id}`)
        }
      } catch (err) {
        handleError(err)
      }
    },
    [navigate]
  )

  const populateTemplates = useCallback(async () => {
    try {
      setLoading(true)

      await checkToken()

      let searchQuery: ListIntegrationTemplatesByIntegrationOptions['search']

      if (search && showOnlyEnabled) {
        searchQuery = {
          and: [{ name: search, $fuzzy: true }, { enabled: true }],
        }
      } else if (search) {
        searchQuery = {
          and: [{ name: search, $fuzzy: true }],
        }
      } else if (showOnlyEnabled) {
        searchQuery = {
          and: [{ enabled: true }],
        }
      }

      const { results, total } = await ehrRequest.integrations.templates.listByIntegration({
        token: '',
        integrationId,
        offset: page * limit,
        limit,
        search: searchQuery,
        sort,
        direction,
      })

      setTemplates(results)
      setTotal(total)
    } catch (err) {
      handleError(err)
    } finally {
      setLoading(false)
    }
  }, [integrationId, page, limit, search, sort, direction, showOnlyEnabled])

  useEffect(() => {
    const timeout = setTimeout(() => {
      populateTemplates()
    }, 10)
    return () => clearTimeout(timeout)
  }, [populateTemplates])

  const handleToggleEnabled = useCallback(
    async (template: RIntegrationTemplate) => {
      try {
        setLoading(true)

        await checkToken()

        const { result } = await ehrRequest.integrations.templates.update({
          token: '',
          templateId: template._id,
          enabled: !template.enabled,
        })

        const integrationType =
          result.integrationType === 'kipu'
            ? 'Kipu'
            : result.integrationType === 'advancedmd'
              ? 'AdvancedMD'
              : 'API'

        for (const field of result.fields || []) {
          if (!isInputField(field)) {
            console.log('not input field', field)
          }
        }

        try {
          if (result.enabled) {
            // Create a new layout with this template mapped to it

            await transcribeRequest.layouts.create({
              token: '',
              name: result.name,
              directoryId: result.directoryId,
              integrationTemplateId: result._id,
              fields: result.fields || [],
              maxDocuments: 10,
              maxNotes: 10,
              maxObservations: 10,
              tags: ['EHR Integrations'],
              allowDictation: true,
              allowTranscript: true,
              priority: 999,
              type: 'note',
              includeDictations: true,
              includeNotes: true,
              includeTranscripts: false,
              transferTranscripts: false,
              requiredLayouts: [],
              professions: [],
              dictationDescription: '',
              sessionDescription: '',
              documentInfo: result.instructions,
              description: `From ${integrationType}`,
              multiplePeople: false,
              requirePatientLabel: false,
              source: result.integrationType,
            })
          } else {
            // Delete the layout

            const { results } = await transcribeRequest.layouts.list({
              token: '',
              search: {
                and: [{ integrationTemplateId: result._id }],
              },
            })

            if (results.length > 0) {
              await transcribeRequest.layouts.delete({
                token: '',
                layoutId: results[0]._id,
              })
            }
          }

          setLayouts([])
        } catch (err) {
          log.error(err)
        }

        setTemplates((prev) => prev.map((t) => (t._id === result._id ? result : t)))
      } catch (err) {
        handleError(err)
      } finally {
        setLoading(false)
      }
    },
    [setLayouts]
  )

  return (
    <Box>
      <FormControlLabel
        sx={{ mb: 2 }}
        control={
          <Checkbox
            checked={showOnlyEnabled}
            onChange={(e) => setShowOnlyEnabled(e.target.checked)}
          />
        }
        label="Only show enabled"
      />
      <IntegrationTemplateTable
        rows={templates}
        page={page}
        rowsPerPage={limit}
        total={total}
        loaded={!loading}
        sort={sort}
        direction={direction}
        onChangeSort={(sort, dir) => {
          setSort(sort)
          setDirection(dir)
        }}
        onChangePage={setPage}
        onChangeRowsPerPage={setLimit}
        onSubmitSearch={setSearch}
        onClickNavigate={handleNavigateTemplatePage}
        rowsPerPageOptions={[]}
        onToggleEnabled={handleToggleEnabled}
      />
    </Box>
  )
}

export default IntegrationTemplatesDisplay
