import React, { useCallback, useEffect, useState } from 'react'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import StarEmptyIcon from '@mui/icons-material/StarOutline'
import StarFilledIcon from '@mui/icons-material/Star'
import IconButton from '@mui/material/IconButton'
import toast from 'react-hot-toast'

export type FeedbackCardProps = {
  onSaveFeedback: (stars: number, comment?: string) => void
  onClickSubmit?: () => void
  loading?: boolean
}

const FeedbackCard = ({ onSaveFeedback, onClickSubmit, loading }: FeedbackCardProps) => {
  const [stars, setStars] = useState<number | null>(null)
  const [comment, setComment] = useState<string>('')

  const handleSubmitComment = useCallback(() => {
    if (stars === null) return;
    
    if (stars < 4 && !comment) {
      toast.error('Feedback comment is required for ratings of 3 or lower.');
    } else {
      onSaveFeedback(stars, comment);
      onClickSubmit?.();
    }
  }, [stars, comment, onSaveFeedback, onClickSubmit]);

  const handleCommentChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const value = e.target.value;
    setComment(value);
  };

  useEffect(() => {
    if (stars === null) return
    onSaveFeedback(stars, '')
  }, [stars, onSaveFeedback])

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography variant="h6" textAlign="center" fontFamily="'Mukta', sans-serif">
          Was this helpful?
        </Typography>
        <Typography
          variant="body1"
          textAlign="center"
          sx={{ mb: 2 }}
          fontFamily="'Mukta', sans-serif"
          color="text.secondary"
        >
          Please select a star rating and provide us with written feedback
        </Typography>
        {stars !== null && stars <= 3 && !comment.trim() && (
          <Typography variant="body2" color="error" textAlign="center">
            Feedback comment is required for ratings of 3 or lower.
          </Typography>
        )}
        </Grid>
      <Grid item xs={12}>
        <Grid container justifyContent="center" spacing={2}>
          <Grid item xs={12}>
            <Grid container justifyContent="center">
              {[1, 2, 3, 4, 5].map((star) => (
                <Grid item key={star}>
                  <IconButton
                    onClick={() => setStars(star)}
                    color={stars && stars >= star ? 'primary' : 'default'}
                    sx={{
                      backgroundColor: 'transparent',
                    }}
                  >
                    {stars && stars >= star ? <StarFilledIcon /> : <StarEmptyIcon />}
                  </IconButton>
                </Grid>
              ))}
            </Grid>
          </Grid>
          {stars !== null && (
            <Grid item xs={12}>
              <TextField
                fullWidth
                multiline
                rows={4}
                placeholder="Please provide us with feedback"
                required
                value={comment}
                onChange={handleCommentChange}
              />
              <Button sx={{ mt: 2 }} fullWidth onClick={handleSubmitComment} disabled={loading}>
                Send Feedback
              </Button>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  )
}

export default FeedbackCard
