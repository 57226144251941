import { RLicense } from '@counsel-project/counsel-auth-api'
import { Product } from '@counsel-project/counsel-auth-api'
import Divider from '@mui/material/Divider'
import Fade from '@mui/material/Fade'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Cookies from 'js-cookie'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useTitle } from 'react-use'
import BlobBackground from '../components/BlobBackground'
import TestimonialCarousel from '../components/ReviewCarousel'
import PricingCard from '../components/commerce/PricingCard'
import ProductPricingCard from '../components/commerce/ProductPricingCard'
import { authRequest } from '../util/api/auth-api'
import checkToken from '../util/auth/checkToken'
import log from '../util/logging'
import { logAddToCart, logScheduleDemo, useViewContent } from '../util/tracking'
import Container from '@mui/material/Container'
import PricingSlider from '../components/commerce/PricingSlider'
import { UserSubscriptionListResponse } from '@counsel-project/counsel-auth-api/dist/node_api/endpoints/user/subscriptions'

const PricingSelect = () => {
  useTitle('Clinical Notes AI - Pricing Select')
  useViewContent('Pricing Page')

  const [searchParams] = useSearchParams()
  const m = searchParams.get('m')

  const navigate = useNavigate()

  const [products, setProducts] = useState<Product[]>([])
  const [subscription, setSubscription] = useState<
    UserSubscriptionListResponse['subscriptions'][0] | undefined
  >(undefined)
  const [payPeriod, setPayPeriod] = useState<'monthly' | 'annually'>('annually')

  const licenseType = useMemo(() => {
    if (!subscription) return 'free'
    return subscription?.metadata.type || 'free'
  }, [subscription])

  const populateProducts = useCallback(async () => {
    try {
      const res = await authRequest.products.list()

      setProducts(
        res.products
          .map((p) => p.product)
          .sort((a, b) => parseInt(a.metadata.order || '0') - parseInt(b.metadata.order || '0'))
      )
      window.scrollTo(0, 0)
    } catch (err) {
      log.error(err)
    }
  }, [])

  useEffect(() => {
    const timeout = setTimeout(() => {
      populateProducts()
    }, 10)
    return () => clearTimeout(timeout)
  }, [populateProducts])

  const populateSubscriptions = useCallback(async () => {
    try {
      const loggedIn = await checkToken(true)
      console.log('loggedIn', loggedIn)
      if (!loggedIn) return

      const res = await authRequest.user.subscriptions.list({
        token: '',
      })

      const currentSub = res.subscriptions.sort((a, b) => {
        if (a.status === 'active') return -1
        if (b.status === 'active') return 1
        if (a.status === 'past_due') return -1
        if (b.status === 'past_due') return 1
        return 0
      })?.[0]

      setSubscription(currentSub)
    } catch (err) {
      log.error(err)
    }
  }, [])

  useEffect(() => {
    const timeout = setTimeout(() => {
      populateSubscriptions()
    }, 10)
    return () => clearTimeout(timeout)
  }, [populateSubscriptions])

  const handleClickFree = () => {
    if (Cookies.get('expiresAt')) {
      navigate('/builder')
    } else {
      navigate('/register?type=Free')
    }
  }

  const createStartTrialHandler = (product: Product) => () => {
    const price = parseInt(product.metadata?.priceText?.split('/')?.[0].replace('$', '') || '0')

    if (!product.metadata.priceText) {
      logScheduleDemo()
      window.open('https://calendly.com/clinicalnotesai/30min', '_blank')
      return
    }

    let productPage = `/purchase?productId=${product.id}`
    if (m) {
      productPage += `&m=${m}`
    }

    if (licenseType === product.metadata.type) {
      return navigate('/subscriptions')
    }

    logAddToCart({
      productName: product.metadata.type || '',
      productPrice: price,
      quantity: 1,
      amountTotal: price,
    })
    if (Cookies.get('expiresAt')) {
      navigate(productPage)
    } else {
      navigate(
        `/register?type=${product.metadata.type || ''}&state=${encodeURIComponent(
          JSON.stringify({ page: productPage })
        )}`
      )
    }
  }

  return (
    <>
      <BlobBackground>
        <Fade in={products.length !== 0}>
          <Container maxWidth="xl" sx={{ p: 2 }}>
            <Typography
              variant="h5"
              component="h1"
              fontWeight={600}
              gutterBottom
              textAlign="center"
              sx={{ mt: 1, mb: 2 }}
            >
              Start your 14 day free trial by choosing a membership option below
            </Typography>
            <Grid container justifyContent="center" spacing={4} alignItems="stretch" sx={{ mb: 4 }}>
              <Grid item xs={12}>
                <PricingSlider value={payPeriod} onChange={setPayPeriod} />
              </Grid>
              {products.map((product) => (
                <Grid item key={product.id} xs={12} sm={6} md={4} lg={3} xl>
                  <ProductPricingCard
                    showMonthlyPrice={payPeriod === 'monthly'}
                    subscription={subscription}
                    product={product}
                    onClick={createStartTrialHandler(product)}
                  />
                </Grid>
              ))}
              <Grid item xs={12} sm={6} md={4} lg={3} xl>
                <PricingCard
                  title="Free Membership"
                  price={0}
                  period="mo"
                  description={'Free forever\nNo credit card required'}
                  features={[
                    '10 Dictation Credits / Week',
                    '10 Live Session Credits',
                    '2 Document Credits',
                  ]}
                  buttonText={'Use Free Membership'}
                  buttonVariant="contained"
                  onClick={handleClickFree}
                />
              </Grid>
            </Grid>
            <TestimonialCarousel />
          </Container>
        </Fade>
      </BlobBackground>
    </>
  )
}

export default PricingSelect
