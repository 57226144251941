import { StateStorage } from 'zustand/middleware'

const localStoragePrefix = '_state_'

const localStateStorage: StateStorage = {
  getItem: (key: string): string | null => {
    if (typeof window === 'undefined') {
      return null
    }
    const value = localStorage.getItem(localStoragePrefix + key)
    if (value) {
      return value
    }
    return null
  },
  setItem: (key: string, value: string): void => {
    if (typeof window === 'undefined') {
      return
    }
    localStorage.setItem(localStoragePrefix + key, value)
  },
  removeItem: (key: string): void => {
    if (typeof window === 'undefined') {
      return
    }
    localStorage.removeItem(localStoragePrefix + key)
  },
}

export default localStateStorage
