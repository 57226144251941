import AddIcon from '@mui/icons-material/AddRounded'
import { BoxProps } from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import React, { useEffect, useState } from 'react'
import AddItemOutlinedBox from './AddItemOutlinedBox'
import AddItemTextField from './AddItemTextField'
import AddItemAutocomplete from './AddItemAutocomplete'
import CircularProgress from '@mui/material/CircularProgress'

type AddAutocompleteProps = {
  freeSolo?: boolean
  selectOnFocus?: boolean
  handleHomeEndKeys?: boolean
  clearOnBlur?: boolean
  noOptionsText?: string
  loading?: boolean
  options: string[]
  disabled?: boolean
  placeholder?: string
  value?: string | null
  inputValue?: string
  onChangeInputValue?: (value: string) => void
  onSubmit?: (value: string) => void
  onDoneTyping?: (value: string) => void
} & Omit<BoxProps, 'onChange' | 'onSubmit'>

const AddAutocomplete = ({
  freeSolo,
  selectOnFocus,
  handleHomeEndKeys,
  clearOnBlur,
  noOptionsText,
  loading,
  options,
  disabled,
  placeholder = 'Add Item...',
  inputValue: value = '',
  onChangeInputValue: onChange,
  value: autocompleteValue = null,
  onSubmit,
  onDoneTyping,
  ...props
}: AddAutocompleteProps) => {
  const [submittedValue, setSubmittedValue] = useState<string | null>(autocompleteValue)
  const [inputValue, setInputValue] = useState(value)
  const [active, setActive] = useState(false)

  const handleChange = (v: string) => {
    setInputValue(v)
    onChange?.(v)
  }

  useEffect(() => {
    setInputValue(value)
  }, [value])

  const handleSubmit = () => {
    if (!submittedValue) return
    onSubmit?.(submittedValue)
    setInputValue('')
    setSubmittedValue(null)
    onChange?.('')
  }

  useEffect(() => {
    if (onDoneTyping) {
      const timeout = setTimeout(() => {
        onDoneTyping(inputValue)
      }, 500)
      return () => clearTimeout(timeout)
    }
  }, [inputValue, onDoneTyping])

  return (
    <AddItemOutlinedBox active={active} {...props}>
      <AddItemAutocomplete
        value={submittedValue}
        onChange={(e, v) => setSubmittedValue(v as string | null)}
        options={options}
        freeSolo={freeSolo}
        selectOnFocus={selectOnFocus}
        handleHomeEndKeys={handleHomeEndKeys}
        clearOnBlur={clearOnBlur}
        noOptionsText={noOptionsText}
        loading={loading}
        inputValue={inputValue}
        onInputChange={(e, v) => handleChange(v)}
        disabled={disabled}
        placeholder={placeholder}
        fullWidth
        onFocus={() => setActive(true)}
        onBlur={() => setActive(false)}
        aria-label="add item textfield"
        renderInput={(params) => (
          <AddItemTextField
            {...params}
            placeholder={placeholder}
            InputProps={{
              ...params.InputProps,
              autoComplete: 'off',
              autoCorrect: 'off',
              autoCapitalize: 'none',
              endAdornment: (
                <>
                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
      />
      <IconButton
        onClick={handleSubmit}
        color="primary"
        sx={{ m: 1 }}
        aria-label="add item"
        disabled={disabled}
      >
        <AddIcon />
      </IconButton>
    </AddItemOutlinedBox>
  )
}

export default AddAutocomplete
