import React, { useCallback, useState } from 'react'
import AddAutocomplete from './AddAutocomplete'
import { authRequest } from '../../util/api/auth-api'
import checkToken from '../../util/auth/checkToken'
import { RRole } from '@counsel-project/counsel-auth-api'

type AddSuperviseeFieldProps = {
  loading?: boolean
  disabled?: boolean
  directoryId: string
  role: RRole
  onSubmit: (email: string) => void
}

const AddSuperviseeField = ({
  loading: parentLoading,
  disabled,
  directoryId,
  role,
  onSubmit,
}: AddSuperviseeFieldProps) => {
  const [options, setOptions] = useState<string[]>([])
  const [loading, setLoading] = useState(false)

  const handleSearch = useCallback(
    async (search: string) => {
      try {
        setLoading(true)

        await checkToken()

        const { results } = await authRequest.user.directories.roles.list({
          token: '',
          directoryId,
          search: search
            ? {
                and: [{ email: search.toLowerCase(), $fuzzy: true }],
              }
            : undefined,
          limit: 10,
          direction: 'asc',
          sort: 'email',
        })

        setOptions(
          results
            .map((r) => r.email)
            .filter((e): e is string => !!e && e !== role.email && !role?.children?.includes(e))
        )
      } catch (error) {
        console.error(error)
      } finally {
        setLoading(false)
      }
    },
    [directoryId, role]
  )

  return (
    <AddAutocomplete
      freeSolo
      disabled={disabled}
      loading={loading || parentLoading}
      options={options}
      placeholder="Add Supervisee..."
      onDoneTyping={handleSearch}
      onSubmit={onSubmit}
    />
  )
}

export default AddSuperviseeField
