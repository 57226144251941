import { RIntegration } from '@counsel-project/counsel-ehr-api'
import { isApiError } from '@counsel-project/client-utils'
import LoadingButton from '@mui/lab/LoadingButton'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import Link from '@mui/material/Link'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import { useCallback, useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import ClosableDialog from '../../../components/ClosableDialog'
import { ehrRequest } from '../../../util/api/ehr-api'
import checkToken from '../../../util/auth/checkToken'
import useUser from '../../../util/auth/useUser'
import handleError from '../../../util/handleError'
import { LabeledTextField } from '@counsel-project/components'

type AdvancedMDCredentialsDialogProps = {
  directoryId: string
  open: boolean
  onClose: () => void
  onCreated: (integration: RIntegration) => void
}

const AdvancedMDCredentialsDialog = ({
  directoryId,
  open,
  onClose,
  onCreated,
}: AdvancedMDCredentialsDialogProps) => {
  const [user] = useUser()

  const [officeKey, setOfficeKey] = useState('')
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('')

  const handleCreate = useCallback(async () => {
    try {
      if (!officeKey || !username || !password) {
        toast.error('Please fill in all of the fields', { id: 'advancedmd-credentials-error' })
        return
      }

      setLoading(true)

      await checkToken()

      const { result } = await ehrRequest.advancedmd.create({
        token: '',
        directoryId,
        officeKey,
        username,
        password,
      })

      onCreated(result)
    } catch (err) {
      handleError(err)
      if (isApiError(err)) {
        setError(err.msg)
      }
    } finally {
      setLoading(false)
    }
  }, [officeKey, username, password, directoryId, onCreated])

  useEffect(() => {
    if (!open) {
      setOfficeKey('')
      setUsername('')
      setPassword('')
      setError('')
    }
  }, [open])

  if (!user?.admin) {
    return (
      <ClosableDialog open={open} onClose={onClose} titleText="Contact AdvancedMD">
        <DialogContent>
          <Typography variant="body1" color="text.secondary">
            In order to obtain credentials from AdvancedMD, please contact support at{' '}
            <Link href="mailto:support@clinicalnotes.ai">
              <Typography variant="body1" fontWeight={500} component="span" color="primary.main">
                support@clinicalnotes.ai
              </Typography>
            </Link>{' '}
          </Typography>
        </DialogContent>
      </ClosableDialog>
    )
  }

  return (
    <ClosableDialog open={open} onClose={onClose} titleText="Add AdvancedMD Integration">
      <DialogContent>
        <Typography variant="body1" color="text.secondary" sx={{ mb: 2 }}>
          Add your AdvancedMD credentials to connect to your AdvancedMD account.
        </Typography>
        <LabeledTextField
          label="Office Key"
          value={officeKey}
          onChange={(e) => setOfficeKey(e.target.value)}
          disabled={loading}
          sx={{ mb: 1 }}
        />
        <LabeledTextField
          label="Username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          disabled={loading}
          sx={{ mb: 1 }}
        />
        <LabeledTextField
          label="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          disabled={loading}
        />
        {error && (
          <Typography color="error" variant="body2" sx={{ mt: 2 }}>
            {error}
          </Typography>
        )}
      </DialogContent>
      <DialogActions>
        <LoadingButton loading={loading} onClick={handleCreate}>
          Connect AdvancedMD
        </LoadingButton>
      </DialogActions>
    </ClosableDialog>
  )
}

export default AdvancedMDCredentialsDialog
