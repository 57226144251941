import { RLayout, RPatientSession } from '@counsel-project/counsel-transcribe-api'
import { useCallback, useState } from 'react'
import toast from 'react-hot-toast'
import { transcribeRequest } from '../../util/api/transcribe-api'
import checkToken from '../../util/auth/checkToken'
import ClinicalInfoDialog from './ClinicalInfoDialog'
import { refreshSessionsCache } from '../../util/api/transcribe-api-cached'
import handleError from '../../util/handleError'

type DictateDocumentDialogProps = {
  mainSession?: RPatientSession | null
  open: boolean
  onClose: () => void
  layout: RLayout
  onFinish: (session: RPatientSession | null) => void
}

const DictateDocumentDialog = ({
  mainSession,
  open,
  onClose,
  layout,
  onFinish,
}: DictateDocumentDialogProps) => {
  const [isLoading, setIsLoading] = useState(false)

  const handleSubmit = useCallback(
    async (value: string) => {
      try {
        setIsLoading(true)

        await checkToken()

        if (!value) {
          if (mainSession && !mainSession.note) {
            await transcribeRequest.sessions.delete({ token: '', sessionId: mainSession._id })
            refreshSessionsCache()
            toast.success('Cleared context information')
            onFinish(null)
            return
          }
          toast.error('Cannot clear this context', { id: 'cannot-delete' })
          onClose()
          return
        }

        if (mainSession) {
          const { result } = await transcribeRequest.sessions.update({
            token: '',
            sessionId: mainSession._id,
            transcript: value,
            endedAt: new Date().toLocaleDateString() + ' ' + new Date().toLocaleTimeString(),
          })
          onFinish(result)
        } else {
          const { result } = await transcribeRequest.sessions.create({
            token: '',
            transcript: value,
            endedAt: new Date().toLocaleDateString() + ' ' + new Date().toLocaleTimeString(),
            dictation: true,
          })
          onFinish(result)
        }

        refreshSessionsCache()
        onClose()
      } catch (err) {
        handleError(err)
      } finally {
        setIsLoading(false)
      }
    },
    [onFinish, onClose, mainSession]
  )

  return (
    <ClinicalInfoDialog
      value={mainSession?.transcript}
      loading={isLoading}
      title="Provide Context Information"
      buttonText="Add Information"
      placeholder="Dictate or type information..."
      layout={layout}
      open={open}
      onClose={onClose}
      onSubmit={handleSubmit}
    />
  )
}

export default DictateDocumentDialog
