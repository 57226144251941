import { visionRequest } from './api/vision-api'
import checkToken from './auth/checkToken'

type ProcessPromiseOptions = {
  maxTries?: number
  processId: string
}

export const visionSessionsPromise = ({
  maxTries = 20,
  processId,
}: ProcessPromiseOptions): Promise<string> => {
  return new Promise((resolve, reject) => {
    let tries = 0
    const interval = setInterval(async () => {
      try {
        await checkToken()

        const { sessionId, state } = await visionRequest.sessions.check({ token: '', processId })

        if (state === 'errored') {
          clearInterval(interval)
          reject('Failed to process document')
        } else if (sessionId) {
          clearInterval(interval)
          resolve(sessionId)
        }
      } catch (err) {
        clearInterval(interval)
        reject(err)
      } finally {
        tries++
        if (tries > maxTries) {
          clearInterval(interval)
          reject('Max tries reached')
        }
      }
    }, 6000)
  })
}
