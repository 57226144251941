import { RPatient, RPatientSession } from '@counsel-project/counsel-transcribe-api'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import Typography from '@mui/material/Typography'
import { useCallback, useEffect, useMemo, useState } from 'react'
import toast from 'react-hot-toast'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { useEffectOnce } from 'react-use'
import PatientSelector from '../../../components/PatientSelector'
import PageContainer from '../../../components/layout/PageContainer'
import LicenseCard from '../../../components/licensing/LicenseCard'
import UpgradeLicenseDialog from '../../../components/licensing/UpgradeLicenseDialog'
import { getLicenseLimit } from '../../../components/licensing/util'
import { transcribeRequest } from '../../../util/api/transcribe-api'
import checkToken from '../../../util/auth/checkToken'
import useActiveLicense from '../../../util/auth/useActiveLicense'
import useLayouts from '../../../util/auth/useLayouts'
import log from '../../../util/logging'
import LayoutOptionsSelector from '../../../components/forms/LayoutOptionsSelector'
import Box from '@mui/material/Box'
import { ClientLayoutSelections } from '@counsel-project/counsel-transcribe-api/dist/common/database/RLayout'
import ContextOptions from '../../../components/forms/ContextOptions'
import { usePatientNomenclature } from '../../../util'
import PsychologyRoundedIcon from '@mui/icons-material/PsychologyRounded'
import { refreshPatientsCache, refreshSessionsCache } from '../../../util/api/transcribe-api-cached'

const DocumentGenerateSelectPage = () => {
  const { layoutId } = useParams()
  const [searchParams] = useSearchParams()
  const sessionId = searchParams.get('sessionId')
  const contextSessionIds = useMemo(
    () => searchParams.get('contextSessionIds')?.split(',') || [],
    [searchParams]
  )
  const patientLabel = searchParams.get('patientLabel') || undefined

  const [layouts, , populateLayouts] = useLayouts()
  const layout = useMemo(() => layouts.find((l) => l.identifier === layoutId), [layouts, layoutId])

  const [contextSessions, setContextSessions] = useState<RPatientSession[]>([])
  const [mainSession, setMainSession] = useState<RPatientSession | null>(null)
  const [selectedPatient, setSelectedPatient] = useState<RPatient | null>(null)
  const [loading, setLoading] = useState(false)
  const [upsellDialog, setUpsellDialog] = useState(false)
  const [layoutSelections, setLayoutSelections] = useState<ClientLayoutSelections | null>({
    identifier: layoutId || '',
    options: [],
  })
  const [context, setContext] = useState('')

  const isRegenerate = !!mainSession?.note

  useEffect(() => {
    if (layout) {
      setLayoutSelections({
        identifier: layout.identifier,
        options: [],
      })
    }
  }, [layout])

  const navigate = useNavigate()

  const [activeLicense, , populateActiveLicense] = useActiveLicense()

  useEffectOnce(() => {
    populateActiveLicense()
  })

  // Populate initial data if the query parameters are provided

  const populateMainSession = useCallback(async () => {
    try {
      if (!sessionId) return
      await checkToken()

      const { result } = await transcribeRequest.sessions.get({ token: '', sessionId })

      setMainSession(result)
    } catch (err) {
      log.error(err)
    }
  }, [sessionId])

  useEffectOnce(() => {
    if (!sessionId) return
    populateMainSession()
  })

  const populateContextSessionIds = useCallback(async () => {
    try {
      if (contextSessionIds.length === 0) return
      await checkToken()

      const { results } = await transcribeRequest.sessions.list.all({
        token: '',
        search: {
          or: contextSessionIds.map((id) => ({ _id: id })),
        },
      })
      setContextSessions(results)
    } catch (err) {
      log.error(err)
    }
  }, [contextSessionIds])

  useEffectOnce(() => {
    if (contextSessionIds.length === 0) return
    populateContextSessionIds()
  })

  useEffectOnce(() => {
    populateLayouts()
  })

  const handleContinue = useCallback(async () => {
    try {
      if (!layoutId) return
      if (!layoutSelections) return
      if (!mainSession && contextSessions.length === 0) {
        toast.error('Please add a method to generate this document')
        return
      }
      if (!activeLicense) return
      const docLimit = getLicenseLimit(activeLicense, 'documents')

      if (docLimit.remaining <= 0 && !mainSession?.note) {
        setUpsellDialog(true)
        return
      }

      setLoading(true)

      await checkToken()

      let patient = selectedPatient
      if (selectedPatient && !selectedPatient._id) {
        const { result } = await transcribeRequest.patients.create({
          token: '',
          label: selectedPatient.label,
        })
        patient = result
        refreshPatientsCache()
      }

      let currentSession = mainSession
      if (!currentSession) {
        const { result } = await transcribeRequest.sessions.create({
          token: '',
          patientId: patient?._id,
          layout: layoutId,
        })
        currentSession = result
      } else {
        if (selectedPatient) {
          await transcribeRequest.sessions.update({
            token: '',
            sessionId: currentSession._id,
            patientId: patient?._id,
          })
        }
      }

      const res = await transcribeRequest.sessions.generate.start({
        token: '',
        sessionId: currentSession._id,
        identifier: layoutSelections?.identifier,
        options: layoutSelections?.options || [],
        contextSessionIds: contextSessions.map((s) => s._id),
        context: context || undefined,
      })

      refreshSessionsCache()

      navigate(`/documentation/generating/${res.result._id}`)
    } catch (err) {
      log.error(err)
    } finally {
      setLoading(false)
    }
  }, [
    layoutId,
    mainSession,
    selectedPatient,
    navigate,
    contextSessions,
    activeLicense,
    layoutSelections,
    context,
  ])

  const disableContinue = !layout || (!mainSession && contextSessions.length === 0) || loading

  const patientNomenclature = usePatientNomenclature()

  if (!layout) {
    return (
      <PageContainer>
        <Grid container alignItems="center" justifyContent="center">
          <Grid item>
            <CircularProgress />
          </Grid>
        </Grid>
      </PageContainer>
    )
  }

  return (
    <PageContainer>
      <Typography variant="h6" fontWeight={500} sx={{ mb: 2 }}>
        {layout.name}
      </Typography>
      <Grid container spacing={2} sx={{ mb: 2 }}>
        <Grid item xs={12}>
          <Paper sx={{ p: 2 }} elevation={0}>
            <Typography variant="body1" fontWeight={500} fontSize={18} sx={{ mb: 1 }}>
              What {patientNomenclature} is this document for?
            </Typography>
            <PatientSelector
              onChange={setSelectedPatient}
              value={selectedPatient}
              disabled={!!patientLabel}
            />
            <Box sx={{ mt: 2, mb: 2 }}>
              {layoutSelections && (
                <LayoutOptionsSelector
                  options={layout.config.options}
                  value={layoutSelections.options}
                  onChange={(options) => setLayoutSelections({ ...layoutSelections, options })}
                />
              )}
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <ContextOptions
            title={`How would you like to create this ${layout.name}?`}
            patient={selectedPatient}
            layout={layout}
            layouts={layouts}
            contextSessions={contextSessions}
            onChangeContextSessions={setContextSessions}
            mainSession={mainSession}
            onChangeMainSession={setMainSession}
            context={context}
            onChangeContext={setContext}
          />
        </Grid>
        <Grid item container xs={12} justifyContent="end">
          <Grid item xs={12}>
            <Button
              disabled={disableContinue}
              startIcon={<PsychologyRoundedIcon />}
              onClick={handleContinue}
              fullWidth
              sx={{ px: 4, py: 3 }}
            >
              Confirm & {isRegenerate ? 'Regenerate' : 'Generate'}
            </Button>
            <Typography variant="body2" fontWeight={500} sx={{ mt: 2 }} color="primary">
              {isRegenerate
                ? 'Regenerating documents will not use any document credits'
                : 'Generating this document will use 1 document credit'}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <LicenseCard
        license={activeLicense}
        hideButtons
        shownLimits={['sessions', 'dictates', 'documents']}
      />
      <UpgradeLicenseDialog
        license={activeLicense}
        open={upsellDialog}
        onClose={() => setUpsellDialog(false)}
      />
    </PageContainer>
  )
}

export default DocumentGenerateSelectPage
