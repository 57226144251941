import { RTranscript } from '@counsel-project/counsel-deidentifier-api/dist/common/database/Transcript'
import useMediaQuery from '@mui/material/useMediaQuery'
import TableBase from './TableBase'
import TranscriptRow from './TranscriptRow'

export type TranscriptTableProps = {
  rows: RTranscript[]
  page: number
  rowsPerPage: number
  rowsPerPageOptions: number[]
  total: number
  loaded: boolean
  onChangePage?: (page: number) => void
  onChangeRowsPerPage?: (rowsPerPage: number) => void
  onClickRow?: (row: RTranscript) => void
}

const TranscriptTable = ({
  rows,
  page,
  rowsPerPage,
  rowsPerPageOptions,
  total,
  loaded,
  onChangePage,
  onChangeRowsPerPage,
  onClickRow,
}: TranscriptTableProps) => {
  const isMobile = useMediaQuery('(max-width: 600px)')

  return (
    <TableBase
      searchDisabled
      columns={[
        { field: 'user', label: 'User', sortable: false, align: 'left' },
        {
          field: 'dictation',
          label: 'Type',
          sortable: false,
          align: 'right',
          hidden: isMobile,
        },
        {
          field: 'profession',
          label: 'Profession',
          sortable: false,
          align: 'right',
          hidden: isMobile,
        },
      ]}
      rows={rows}
      renderRow={(row: RTranscript) => (
        <TranscriptRow key={row._id} isMobile={isMobile} data={row} onClick={onClickRow} />
      )}
      page={page}
      rowsPerPage={rowsPerPage}
      rowsPerPageOptions={rowsPerPageOptions}
      total={total}
      loaded={loaded}
      onChangePage={onChangePage}
      onChangeRowsPerPage={onChangeRowsPerPage}
    />
  )
}

export default TranscriptTable
